<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Routes - {{empData?.employee_name}} ({{empData?.employee_code}})</h5>
    <button class="btn-reset text-light-600" (click)="closePanel()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar sidebar-collapse  p-0" [ngClass]="{'active': sidebarCollapse }">
    <button class=" sidebar-collapse-btn link-primary1 btn-reset position-absolute end-0  p-16" (click)="sidebarCollapse = !sidebarCollapse">
      <i class="icon-chevron-right-circle fs-20" [ngClass]="{'icon-chevron-left-circle': sidebarCollapse }"></i>
    </button>
    <div class="text-uppercase fs-12 fw-500 px-24 pb-8 pt-16"> LIVE TRACK DETAILS </div>
    <div *ngIf="!childloader && !prepareloader" class="position-sticky top-0 bg-white p-16 z-1">
      <div class="slider-input-container position-relative">
        <input (click)="datePicker1.open()" class="form-control w-100 position-absolute" placeholder="{{this.messageServ.selectplaceholddisplay('date')}} " matInput [matDatepicker]="datePicker1" [(ngModel)]="today" style="visibility : hidden;" (dateInput)="fromDates($event)">
        <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
        <button class="slider-btn-left" (click)="changeWeek('left')"><i class="icon-chevron-left-lg"></i></button>
        <div class="slider-input">
          <div class="align-items-center d-flex justify-content-around" (click)="datePicker1.open()">
            <span class="fs-14 fw-500"> {{today|date:this.appservice.getdateformat()}} </span>
          </div>
        </div>
        <button class="slider-btn-right" (click)="changeWeek('right')"><i class="icon-chevron-right-lg"></i></button>
      </div>
    </div>
    <app-side-menu-loader  *ngIf="childloader || prepareloader"></app-side-menu-loader>
    <!-- <div *ngIf="childloader || prepareloader">
      <ngx-skeleton-loader count="5" [theme]="{ 'border-radius': '0', height: '50px' }" ></ngx-skeleton-loader>
    </div> -->
    <ul *ngIf="!childloader && !prepareloader" class="timeline-c3">

      <li class="timeline-li-1" *ngIf="data?.tracking_datas[0]?.realtime_status == 'Check-in' && !timeCompare(today+' '+data?.shift_details?.shift_start_time,data?.tracking_datas[0].timestamp)">
        <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
        <span class="text-nowrap">{{data?.tracking_datas[0]?.timestamp|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Check-in</span>
      </li>

      <li class="timeline-li-1">
        <i class="icon-clock-fill text-accent2 fs-16"></i>
        <span class="text-nowrap">{{today+'
          '+data?.shift_details?.shift_start_time|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Shift start time</span>
      </li>

      <ng-container *ngFor="let dir of dirs">

        <li class="timeline-li-1" *ngIf="dir?.isDirection && dir?.originVal?.realtime_status == 'Check-in'  &&  !timeCompare(dir?.originVal?.timestamp,today+' '+data?.shift_details?.shift_start_time) && dir?.originVal?.mobility_status != 'Stationary' ">
          <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
          <span class="text-nowrap">{{dir?.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>

        <li class="timeline-li-1" *ngIf="dir?.isDirection && dir?.DestVal?.realtime_status == 'Check-in'  &&  !timeCompare(dir?.DestVal?.timestamp,today+' '+data?.shift_details?.shift_start_time)&& dir?.DestVal?.mobility_status != 'Stationary'">
          <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
          <span class="text-nowrap">{{dir?.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>

        <li class="timeline-li-1" *ngIf="!dir?.isDirection && dir?.markerVal?.realtime_status == 'Check-in'  &&  !timeCompare(dir?.markerVal?.timestamp,today+' '+data?.shift_details?.shift_start_time)">
          <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
          <span class="text-nowrap">{{dir?.markerVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>

        <li *ngIf="dir?.isDirection &&   (dir?.distance !='0.00 Km' || (dir?.distance =='0.00 Km' && dirs.length==1))" class="timeline-li-2 li-card " [class.active]="dir?.key == click"
          (click)="clicklist(dir?.key,showbredcrumb)">
          <div class="card-c8 px-8 ">
            <div class="fs-12 hstack gap-12 py-8 fw-600 align-items-start">
              <span class="text-nowrap">{{dir?.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>

              <app-find-address (saveLocation)="saveLocation($event,dir.key,'originVal',dir?.originVal?.employee_id)" [from]='"sidebar"' [id]="dir?.originVal?.id" [lat]="dir?.originVal?.latitude" [lng]="dir?.originVal?.longitude" [location]="dir?.originVal?.location" class="'text-trim-2'"></app-find-address>
            </div>
            <div class="divider-c1">
              <span> <i class="icon-map-route "></i>
                {{( dir?.distance )}}
              </span>
            </div>
            <div class="fs-12 hstack gap-12 py-8 fw-600 align-items-start">
              <span class="text-nowrap">{{dir?.DestVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
              <app-find-address (saveLocation)="saveLocation($event,dir.key,'DestVal',dir?.DestVal?.employee_id)" [from]='"sidebar"' [id]="dir?.DestVal?.id" class="text-trim-2" [lat]="dir?.DestVal?.latitude" [lng]="dir?.DestVal?.longitude" [location]="dir?.DestVal?.location"></app-find-address>
            </div>
          </div>
        </li>

        <li *ngIf="!dir?.isDirection " class="timeline-li-2" [class.active]="dir?.key == clickedMarkerKey"
          (click)="clickMarker(dir?.key)">
          <div class="card-c8 px-8  ">
            <div class=" hstack fw-600 align-items-stretch fs-12 gap-8 py-2">
              <i class="icon-stopwatch1-fill text-warning fs-16 my-auto"></i>
              <app-find-address (saveLocation)="saveLocation($event,dir.key,'markerVal',dir?.markerVal?.employee_id)" [from]='"sidebar"' [id]="dir?.markerVal?.id"  class="text-trim-3" [lat]="dir?.markerVal?.latitude" [lng]="dir?.markerVal?.longitude" [location]="dir?.markerVal?.location"></app-find-address>
              <ng-container *ngIf="dir?.markerVal?.plugin_data?.stationary_interval != null"><span class="text-nowrap ms-auto">{{this.timeFormatConvert(dir?.markerVal?.plugin_data?.stationary_interval)}}
              </span></ng-container>
            </div>
          </div>
        </li>

        <li class="timeline-li-1"
          *ngIf="!dir?.isDirection && dir?.markerVal?.realtime_status == 'Check-out' &&  timeCompare(dir?.markerVal?.timestamp,today+' '+data?.shift_details?.shfit_endtime)">
          <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
          <span class="text-nowrap">{{dir?.markerVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>

        <li class="timeline-li-1"
          *ngIf="dir?.isDirection && dir?.originVal?.realtime_status == 'Check-out' && timeCompare(dir?.originVal?.timestamp,today+' '+data?.shift_details?.shfit_endtime)">
          <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
          <span class="text-nowrap">{{dir?.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>

        <li class="timeline-li-1"
          *ngIf="dir?.isDirection && dir?.DestVal?.realtime_status == 'Check-out' && timeCompare(dir?.DestVal?.timestamp,today+' '+data?.shift_details?.shfit_endtime)">
          <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
          <span class="text-nowrap">{{dir?.DestVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>

      </ng-container>
      <li class="timeline-li-1">
        <i class="icon-clock-fill text-accent2 fs-16"></i>
        <span class="text-nowrap">{{today+'
          '+data?.shift_details?.shfit_endtime|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Shift end time</span>
      </li>

      <li class="timeline-li-1"
        *ngIf="data?.tracking_datas[data?.tracking_datas?.length-1]?.realtime_status == 'Check-out' && timeCompare(today+' '+data?.shift_details?.shfit_endtime,data?.tracking_datas[data?.tracking_datas?.length-1]?.timestamp)">
        <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
        <span
          class="text-nowrap">{{data?.tracking_datas[data?.tracking_datas?.length-1]?.timestamp|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Check-Out</span>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div class="container-fluid  p-0 form-container">
      <div class="row" style="height:calc(100vh - 4rem)" *ngIf="dirs?.length==0 &&  !childloader && !prepareloader">
        <app-nodata-view class="vstack" [noDataImg]="'bg3.png'" [noDataText]="'Oops, no results found!'"
          [noDataPara]="'It seems no results match your search criteria. Please adjust or apply different filters.'"
          [hasPermission]="false">
        </app-nodata-view>
      </div>
      <div *ngIf="childloader || prepareloader || polypointsloader">
        <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: 'calc(100vh - 4rem)' }" ></ngx-skeleton-loader>
      </div>
      <ng-container *ngIf="dirs.length>0 &&  !childloader && !prepareloader && !polypointsloader">
        <div class="card card-c2 radius-8">
          <div #gmapContainer id="map">
            <agm-map [mapTypeControl]='true' [latitude]="centre?.latitude" [styles]="styles" [fitBounds]="true"
              [longitude]="centre?.longitude" [zoom]="zoom" style="height: calc(100vh - 4rem);"
              (mapReady)="mapReady($event)">
              <button id="Settings" class="toggle-button px-16 controls button mt-16 btn-map btn-breadcrumb"
                (click)="clicklist(click,!showbredcrumb)">
                <i class=" me-8 fs-16 {{!showbredcrumb ? 'icon-square':'icon-check-square-fill text-accent1'}}"></i>
                Show Breadcrumbs
              </button>
              <button id="Zoom" class="mt-16 sq-40 radius-6 ms-16 btn-map btn-zoom flex-center fs-20"
                (click)="sidebarCollapse = !sidebarCollapse">
                <i class="icon-min-fullscreen" [ngClass]="{'icon-fullscreen': sidebarCollapse }"></i>
              </button>
              <ng-container *ngIf="click>=0 && showbredcrumb">
                <ng-container *ngFor="let m of ways; let i = index">
                  <agm-overlay [latitude]="m?.latitude" [longitude]="m?.longitude" (markerClick)="openWindow(i)"
                    [title]="'map-circle'">
                    <img style="transform: translate(2px, 18px);" src="assets/images/helper/map-img/map-dot-18.png">
                    <agm-info-window [isOpen]="isInfoWindowOpen(i)" *ngIf="isInfoWindowOpen(i)">
                      <p class=" hstack mb-0 align-items-start gap-16  me-16 fw-400 overflow-hidden">
                        {{this.appservice.dateFormatDisplay(m?.val?.timestamp)+"
                        "+this.appservice.formatTimeDate(m?.val?.timestamp)}}
                      </p>
                    </agm-info-window>
                  </agm-overlay>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="click<0 && showbredcrumb">
                <ng-container *ngFor="let m of fullbreadcrumb; let i = index">
                  <agm-overlay [latitude]="m?.lat" [longitude]="m?.lng" (markerClick)="openWindow(i)"
                    [title]="'map-circle'">
                    <img style="transform: translate(2px, 18px);" src="assets/images/helper/map-img/map-dot-18.png">
                    <agm-info-window [isOpen]="isInfoWindowOpen(i)" *ngIf="isInfoWindowOpen(i)">
                      <p class=" hstack align-items-start gap-16 me-24 mb-0 fw-400 overflow-hidden">
                        {{this.appservice.dateFormatDisplay(m?.val?.timestamp)+"
                        "+this.appservice.formatTimeDate(m?.val?.timestamp)}}
                      </p>
                    </agm-info-window>
                  </agm-overlay>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="clickedMarkerKey>=0">
                <agm-overlay [latitude]="clickedMarker?.latitude" [longitude]="clickedMarker?.longitude"
                  (markerClick)="openMarkerWindow(clickedMarkerKey)" [title]="'map-circle'">
                  <img src="assets/images/helper/map-img/map-pin2-45.png">
                  <agm-info-window [isOpen]="isInfoMWindowOpen(clickedMarkerKey)"
                    *ngIf="isInfoMWindowOpen(clickedMarkerKey)">
                    <div class="card card-c2 border-0" style="max-width: 16rem;">
                      <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                        <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                          <app-find-address  class="fw-500 text-trim-3 pe-16" [lat]="clickedMarker?.latitude" [lng]="clickedMarker?.longitude" [location]="clickedMarker?.location"></app-find-address>
                      </div>
                      <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Mobility Status</span>
                          <span
                            class="badge px-16 py-1 {{this.badges[clickedMarker?.mobility_status]}}">{{clickedMarker?.mobility_status}} </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Time</span>
                          <span
                            class=" text-end fw-500">{{this.appservice.formatTimeDate(clickedMarker?.timestamp)}}
                            <ng-container *ngIf="clickedMarker?.plugin_data?.stationary_interval != null">
                            ({{timeFormatConvert(clickedMarker?.plugin_data?.stationary_interval)}})
                          </ng-container>
                          </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Date</span>
                          <span
                            class="text-end fw-500">{{this.appservice.dateFormatDisplay(clickedMarker?.timestamp)}}</span>
                        </div>
                      </div>
                    </div>
                  </agm-info-window>
                </agm-overlay>
              </ng-container>

              <ng-container *ngIf="clickLine==1">
                <!-- <agm-overlay [latitude]="clickedLinePoints?.lat" [longitude]="clickedLinePoints?.lng" > -->

                  <agm-info-window [latitude]="clickedLinePoints?.lat" [longitude]="clickedLinePoints?.lng" [isOpen]="true">
                    <div class="card card-c2 border-0" style="max-width: 16rem;">
                      <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Mobility Status</span>
                          <span
                            class="badge px-16 py-1 {{this.badges[clickedLine?.originVal?.mobility_status]}}">{{clickedLine?.originVal?.mobility_status}} </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Distance</span>
                          <span class="text-end fw-500 ">{{clickedLine?.distance}} </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Time</span>
                          <span class=" text-end fw-500">{{this.appservice.formatTimeDate(clickedLine?.originVal?.timestamp)}} - {{this.appservice.formatTimeDate(clickedLine?.DestVal?.timestamp)}}</span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Date</span>
                          <span class="text-end fw-500">{{this.appservice.dateFormatDisplay(clickedLine?.originVal?.timestamp)}}</span>
                        </div>
                      </div>
                    </div>
                  </agm-info-window>
                <!-- </agm-overlay> -->
                </ng-container>

             <ng-container *ngFor="let dir of dirs,let ind = index">
              <ng-container *ngIf="dir?.isDirection">

                <agm-overlay *ngIf="dir?.fbms?.origin?.icon && click == -1" [latitude]="dir.originVal?.latitude" [longitude]="dir.originVal?.longitude"
                  (markerClick)="openPWindow(dir.originKey)" [title]="'map-circle'">
                  <img src="assets/images/helper/map-img/map-pin1-45.png">
                  <agm-info-window [isOpen]="isInfoPWindowOpen(dir.originKey)" *ngIf="isInfoPWindowOpen(dir.originKey)">
                    <div class="card card-c2 border-0" style="max-width: 16rem;">
                      <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                        <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                          <app-find-address  class="fw-500 text-trim-3 pe-16" [lat]="dir.originVal?.latitude" [lng]="dir.originVal?.longitude" [location]="dir.originVal?.location"></app-find-address>
                      </div>
                      <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Mobility Status</span>
                          <span
                            class="badge px-16 py-1 {{this.badges[dir.originVal?.mobility_status]}} ">{{dir.originVal?.mobility_status}} </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Time</span>
                          <span
                            class=" text-end fw-500">{{this.appservice.formatTimeDate(dir.originVal?.timestamp)}}
                            <ng-container *ngIf="dir.originVal?.plugin_data?.stationary_interval != null">
                            ({{timeFormatConvert(dir.originVal?.plugin_data?.stationary_interval)}})
                          </ng-container>
                          </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Date</span>
                          <span
                            class="text-end fw-500">{{this.appservice.dateFormatDisplay(dir.originVal?.timestamp)}}</span>
                        </div>
                      </div>
                    </div>
                  </agm-info-window>
                </agm-overlay>
                <agm-overlay *ngIf="(dir?.fbms?.destination?.icon || ind ==lastdirKey) && click == -1 " [latitude]="dir.DestVal?.latitude" [longitude]="dir.DestVal?.longitude"
                  (markerClick)="openPWindow(dir.DestKey)" [title]="'map-circle'">
                  <img src="assets/images/helper/map-img/map-pin1-45.png">
                  <agm-info-window [isOpen]="isInfoPWindowOpen(dir.DestKey)" *ngIf="isInfoPWindowOpen(dir.DestKey)">
                    <div class="card card-c2 border-0" style="max-width: 16rem;">
                      <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                        <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                          <app-find-address  class="fw-500 text-trim-3 pe-16" [lat]="dir.DestVal?.latitude" [lng]="dir.DestVal?.longitude" [location]="dir.DestVal?.location"></app-find-address>
                      </div>
                      <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Mobility Status</span>
                          <span
                            class="badge px-16 py-1 {{this.badges[dir.DestVal?.mobility_status]}} ">{{dir.DestVal?.mobility_status}} </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Time</span>
                          <span
                            class=" text-end fw-500">{{this.appservice.formatTimeDate(dir.DestVal?.timestamp)}}
                            <ng-container *ngIf="dir.DestVal?.plugin_data?.stationary_interval != null">
                            ({{timeFormatConvert(dir.DestVal?.plugin_data?.stationary_interval)}})
                          </ng-container>
                          </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Date</span>
                          <span
                            class="text-end fw-500">{{this.appservice.dateFormatDisplay(dir.DestVal?.timestamp)}}</span>
                        </div>
                      </div>
                    </div>
                  </agm-info-window>
                </agm-overlay>

                <ng-container *ngIf="dir?.key == click">
                  <agm-overlay [latitude]="dir.originVal?.latitude" [longitude]="dir.originVal?.longitude"
                    (markerClick)="openPWindow(dir.originKey)" [title]="'map-circle'">
                    <img src="assets/images/helper/map-img/map-pin2-45.png">
                    <agm-info-window [isOpen]="isInfoPWindowOpen(dir.originKey)" *ngIf="isInfoPWindowOpen(dir.originKey)">
                      <div class="card card-c2 border-0" style="max-width: 16rem;">
                        <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                          <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                            <app-find-address  class="fw-500 text-trim-3 pe-16" [lat]="dir.originVal?.latitude" [lng]="dir.originVal?.longitude" [location]="dir.originVal?.location"></app-find-address>
                        </div>
                        <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                          <div class="hstack gap-16 justify-content-between">
                            <span class="text-light-500 ">Mobility Status</span>
                            <span
                              class="badge px-16 py-1 {{this.badges[dir.originVal?.mobility_status]}}  ">{{dir.originVal?.mobility_status}} </span>
                          </div>
                          <div class="hstack gap-16 justify-content-between">
                            <span class="text-light-500 ">Time</span>
                            <span
                              class=" text-end fw-500">{{this.appservice.formatTimeDate(dir.originVal?.timestamp)}}
                              <ng-container *ngIf="dir.originVal?.plugin_data?.stationary_interval != null">
                              ({{timeFormatConvert(dir.originVal?.plugin_data?.stationary_interval)}})
                            </ng-container>
                            </span>
                          </div>
                          <div class="hstack gap-16 justify-content-between">
                            <span class="text-light-500 ">Date</span>
                            <span
                              class="text-end fw-500">{{this.appservice.dateFormatDisplay(dir.originVal?.timestamp)}}</span>
                          </div>
                        </div>
                      </div>
                    </agm-info-window>
                  </agm-overlay>
                  <agm-overlay [latitude]="dir.DestVal?.latitude" [longitude]="dir.DestVal?.longitude"
                    (markerClick)="openPWindow(dir.DestKey)" [title]="'map-circle'">
                    <img src="assets/images/helper/map-img/map-pin2-45.png">
                    <agm-info-window [isOpen]="isInfoPWindowOpen(dir.DestKey)" *ngIf="isInfoPWindowOpen(dir.DestKey)">
                      <div class="card card-c2 border-0" style="max-width: 16rem;">
                        <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                          <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                            <app-find-address  class="fw-500 text-trim-3 pe-16" [lat]="dir.DestVal?.latitude" [lng]="dir.DestVal?.longitude" [location]="dir.DestVal?.location"></app-find-address>
                        </div>
                        <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                          <div class="hstack gap-16 justify-content-between">
                            <span class="text-light-500 ">Mobility Status</span>
                            <span
                              class="badge px-16 py-1  {{this.badges[dir.DestVal?.mobility_status]}}">{{dir.DestVal?.mobility_status}} </span>
                          </div>
                          <div class="hstack gap-16 justify-content-between">
                            <span class="text-light-500 ">Time</span>
                            <span
                              class=" text-end fw-500">{{this.appservice.formatTimeDate(dir.DestVal?.timestamp)}}
                              <ng-container *ngIf="dir.DestVal?.plugin_data?.stationary_interval != null">
                              ({{timeFormatConvert(dir.DestVal?.plugin_data?.stationary_interval)}})
                            </ng-container>
                            </span>
                          </div>
                          <div class="hstack gap-16 justify-content-between">
                            <span class="text-light-500 ">Date</span>
                            <span
                              class="text-end fw-500">{{this.appservice.dateFormatDisplay(dir.DestVal?.timestamp)}}</span>
                          </div>
                        </div>
                      </div>
                    </agm-info-window>
                  </agm-overlay>
                </ng-container>
                <!-- *ngIf="dir?.isDirection && (dir?.distance !='0.00 Km' || (dir?.distance =='0.00 Km' && dirs.length==1))" -->
                <agm-polyline [strokeColor]="dir?.strokeColor && this.click !=-1 ? dir?.strokeColor:'#0066DD'" [strokeWeight]="5" [zIndex]="dir?.zIndex" (lineMouseOver)="lineClick($event,dir)" (lineMouseOut)="closeLineClick()">
                  <agm-polyline-point  [latitude]="dir.originVal?.latitude" [longitude]="dir.originVal?.longitude" [agmFitBounds]="true"></agm-polyline-point>
                  <ng-container *ngFor="let i of dir?.polypoints">
                    <agm-polyline-point  [latitude]="i.lat" [longitude]="i.lng" [agmFitBounds]="true">
                    </agm-polyline-point>
                  </ng-container>
                <agm-polyline-point  [latitude]="dir.DestVal?.latitude" [longitude]="dir.DestVal?.longitude" [agmFitBounds]="true"></agm-polyline-point>

              </agm-polyline>
            </ng-container>
            </ng-container>
            </agm-map>
          </div>
        </div>
      </ng-container>
    </div>
  </main>
</div>
