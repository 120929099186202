import { Component, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
//import * as Rollbar from 'rollbar';
import { AuthService } from './@core/auth/auth.service';
import { WebsocketService } from './@core/services/websocket.service';
// import { RollbarService } from './@shared/rollbar/rollbar';
import { Subject } from 'rxjs';
import { LeaveYearEndService } from './@core/services/leave-year-end.service';
import { filter, pairwise } from 'rxjs/operators';
import { AppService } from './app.global';

import { SalaryProcessService } from 'src/app/@core/services/salary-process.service';
import { AttendanceProcessService } from 'src/app/@core/services/attendance-process.service';
import { MiscellaneousService } from './@core/services/miscellaneous.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateFormat } from './@shared/constants/matDateicker';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [  {
    provide: MAT_DATE_FORMATS,
    useClass: CustomDateFormat
  }
]
})
export class AppComponent {
  title = 'skizzlehr-angular';

  constructor (
    private webSocketService: WebsocketService,
    public authService: AuthService,
    private leaveService : LeaveYearEndService,
    public router: Router, private activatedRoute: ActivatedRoute,
    private appservice:AppService,
    private sps:SalaryProcessService,
    private aps:AttendanceProcessService,
       // @Inject(RollbarService) private rollbar: Rollbar
    private miscellaneousservice: MiscellaneousService,@Inject(MAT_DATE_FORMATS) public matDateFormat: CustomDateFormat
    ) {
      this.getScreenSize();
      window.addEventListener('storage', (event) => {
        if (event.key == 'userData' && event.newValue != null){
          this.authService.menuList().subscribe((res: any) => {
            this.authService.MENULIST = res.page_access;
            this.appservice.setPermissions(res.page_access)
            this.authService.MENUCREATED = true
          });
          this.router.navigate(['home'])
        } else if (event.key == 'userData' && event.newValue == null){
          this.logout()
        }
     });
  }

  @HostListener("window:beforeunload",["$event"])
  clearLocalStorage(event: any | null){
    localStorage.removeItem('last_visited_url'+this.authService.isUserId);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    const lastVisitedUrl: any = localStorage.getItem('last_visited_url'+this.authService.isUserId);
    if(window.innerWidth < 850)
      this.router.navigate(['mobile-view']);

  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.getScreenSize();
  }

  userActivity:any;
   userInactive: Subject<any> = new Subject();
   sessActivity:any;
   sessInactive: Subject<any> = new Subject();

  showHeader = false;
  showSidebar = false;
  showFooter = false;
  changeThemesidePanel = false;

  bannerData :  any
  showNotification = false
  addProfileModal = false
  nouser=false
  logInDataReq = false
  ngOnInit(): void {
    this.getScreenSize();
    if(this.authService.isLoggedIn == true) {
      this.logInDataReq = true
      this.authService.loginDetails().subscribe((res: any) => {
        this.authService.USERDATA =JSON.stringify(res?.data)
        localStorage.setItem('userData', JSON.stringify({first_name:res.data.first_name, last_name:res.data.last_name ,company_name:res.data.company_name}));
        this.logInDataReq = false
      });
      this.authService.menuList().subscribe((res: any) => {
        this.authService.MENULIST = res.page_access;
        this.appservice.setPermissions(res.page_access)
        this.authService.MENUCREATED = true
      });
      
      const helper = new JwtHelperService();
      const rToken:any = this.authService.getRefreshToken();
      const expires:any  = helper.getTokenExpirationDate(rToken)

      const timeout = expires.getTime() - Date.now();
      // setTimeout(() =>   this.logout(), timeout);
      this.webSocketService.NotificationSocket();

    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(!event.url.includes('login') && !event.url.includes('get-otp') && !event.url.includes('verify-otp')  && !event.url .includes('reset_password') && !event.url .includes('newpassword') && !event.url.includes('one-time-password') && !event.url.includes('session-timeout')&& !event.url.includes('mobile-view') && !event.url.includes('otp')&& !event.url.includes('forgot-password')){
          localStorage.setItem('last_visited_url'+this.authService.isUserId, event.url);
        } else {
          this.nouser = true
        }
        this.showHeader = this.activatedRoute.firstChild?.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild?.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute.firstChild?.snapshot.data.showFooter !== false;
        if(!event.url.includes('login') && !event.url.includes('get-otp') && !event.url.includes('verify-otp')  && !event.url .includes('reset_password') && !event.url .includes('newpassword') && !event.url.includes('one-time-password') && !event.url.includes('session-timeout')&& !event.url.includes('mobile-view') && !event.url.includes('otp')&& !event.url.includes('forgot-password')){
          localStorage.setItem('last_visited_url'+this.authService.isUserId, event.url);
          if(localStorage.getItem('leaveYearEndNotification')){
            this.checkFirstLogin()
          }
        }
		else {
          this.nouser = true
        }
      }
    });
    this.router.events .pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise()  ).subscribe((e: any) => {
        if(this.authService.NEEDPROFILEPIC &&  e[0].urlAfterRedirects == '/login' ){
          this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              if(this.authService.NEEDPROFILEPIC &&  e[0].urlAfterRedirects == '/login' && !event.url.includes('login') && !event.url.includes('get-otp') && !event.url.includes('verify-otp')  && !event.url .includes('reset_password') && !event.url .includes('newpassword') && !event.url.includes('one-time-password') && !event.url.includes('session-timeout')&& !event.url.includes('mobile-view') && !event.url.includes('otp')&& !event.url.includes('forgot-password'))
                this.addProfileModal = true
            }
          })
        }
    });

    this.setTimeoutCheck();
    this.userInactive.subscribe(() => {
      this.logout()
    });

    this.sessInactive.subscribe(() => {
      this.doSesstionOut()
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event:any) {
    localStorage.removeItem('leaveYearEndNotification')
    delete event['returnValue'];
  }

  checkFirstLogin(){
    if(this.authService.isEmployeeId !=0){
      this.leaveService.getBannerNotification().subscribe((res:any)=>{
        this.bannerData = res.body.data
        this.showNotification = Object.keys(this.bannerData).length !=0  ?  true :  false
      })
    }
    this.showNotification = false
  }

  ngOnDestroy(){
    this.webSocketService.closeSocket();
    this.sps.closeSocket();
    this.aps.closeSocket();
  }

  logout(){
    this.appservice.resetPermissions()
    this.authService.doLogout()
    localStorage.removeItem('leaveYearEndNotification')
    this.webSocketService.closeSocket();
    this.sps.closeSocket();
    this.aps.closeSocket();
  }
  doSesstionOut(){
    this.authService.doSesstionOut()
  }
  setTimeoutCheck() {
    localStorage.removeItem('leaveYearEndNotification')
    this.userActivity = setTimeout(() => {
      if (this.authService.isLoggedIn) {
        this.userInactive.next(undefined);
        this.showNotification = false
      }
    }, 3600000);
    this.sessActivity = setTimeout(() => {
      if (this.authService.isLoggedIn) {
        this.sessInactive.next(undefined);
        this.showNotification = false
      }
    }, 1800000);

  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    clearTimeout(this.sessActivity);
    this.setTimeoutCheck();
  }
themefunction(value:any){
  this.changeThemesidePanel = value;
  // this.changeDetectorRef.detectChanges();
}

  checkSidebarExpanded(selectedIndex: any) {
    if (selectedIndex != -1) {
      this.authService.SIDEBARCLASS = 'expand';
    }
    else {
      this.authService.SIDEBARCLASS = '';
    }
  }


}
