import { Component, OnInit, ViewChild,Output,EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { PageAccessService } from '../../../../../@core/services/page-access.service'
import { FormBuilder, FormGroup , Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-pa-rights',
  templateUrl: './pa-rights.component.html',
  styleUrls: ['./pa-rights.component.scss']
})
export class PaRightsComponent implements OnInit {
  is_active: any = true;
  selected_flag = false;
  @ViewChild('ngSelectComponent') ngSelectComponent: any;
  groupForm: any;
  temp_list: any = [];
  submitted = false;
  constructor(
    private pageAccessService:PageAccessService,
    public formBuilder: FormBuilder,
    public router: Router,
    public messageService : MessageService,
    public fb:FormBuilder,
    public existDataService :  ExistDatasService,
    public route :  ActivatedRoute,
    public appService:AppService,
    private cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.id = this.route.snapshot.params['id']
    this.initiateErrorStatus();
  }
  // tab start
  @Input()formGp : FormGroup = this.fb.group({
    name: '',
    employee: [''],
  });
  @Input()employeeDropdown:any=[];
  @Input() disabled=false;
  @Input() editflag:any;

  @Output()
  submitmethod = new EventEmitter();
  validateConfigureFlow() {
    this.submitmethod.emit();
  }
  // unamePattern = "^(?! )+[A-Za-z0-9 -._@/#&+]*(?<! )$";
  // tab end
  get f() { return this.formGp.controls; }

  paUnit : any = []
  validated : boolean = false
  value:any
  private subjectKeyUp = new Subject<any>();
  id: any

  clear(item:any,index:number){
    if(item == "employee"){
      let daata = this.formGp.value.employee;
      daata.splice(index, 1);
      this.formGp.patchValue({
        employee: daata
      });
    }
    this.initiateErrorStatus()
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":"pageAccess",
        "field":"group__name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"pageAccess",
        "field":"group__name",
        "value": this.f.name.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.paUnit = res.body
      if(this.paUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  // Inactive Employee Setup
  ngChange(e:any){
    if(this.hasSpecificStatus(e) && !this.editflag){
      this.formGp?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    return employees.every((employee:any)=> !employee?.active_state_employee) && employees?.length != 0;
  }

  initiateErrorStatus(){
    if(this.f.employee.value != null && this.f.employee.value != ''){
      const filteredData = this.employeeDropdown.filter((item:any) => this.f.employee.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData) && !this.editflag){
          this.formGp?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('employee')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeDropdown.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
