import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-view-expense-report-request',
  templateUrl: './view-expense-report-request.component.html',
  styleUrls: ['./view-expense-report-request.component.scss']
})
export class ViewExpenseReportRequestComponent implements OnInit {

  @Input() viewData:any;
  @Input() loader:any;
  @Input() currency:any;
  @Input() from:any;

  page1              = 1;
  pageSize1          = 20;
  searchString1: any = "";

  page2              = 1;
  pageSize2          = 20;
  searchString2: any = "";

  viewAttchments          = false;
  uploadedAttachments:any = [];
  viewAttachmentIndex     = 0;
  receiptId               = 0;

  viewReciept          = false;
  viewRecieptLoader    = false;
  viewRecieptData:any  = [];

  viewAdvance   = false;
  advanceid     = 0;
  viewLoader    = false;
  viewdata:any  = [];


  constructor(
    public appService: AppService,
    private service:ExpenseReportRequestService,
    private activityService:ManageActivityService
  ) { }

  ngOnInit(): void {

  }

  viewAttachmentFunction(index:any,id:any){
    this.viewAttchments      = true;
    this.receiptId           = id;
    this.viewAttachmentIndex = index;
    this.uploadedAttachments = this.viewData?.expense_receipts[this.viewAttachmentIndex].attachments;
  }

  closeViewAttachment(){
    this.viewAttchments = false;
  }

  saveViewAttachment(data:any){
    this.viewAttchments = false;
  }

  // Receipt detailed view
  getSigleReceiptView(id:any){
    this.viewRecieptLoader = true;
    this.viewReciept       = true;
    this.viewRecieptData   = [];
    if(this.from=='behalf'){
      this.activityService.getSingleReceiptData(id).subscribe((res:any)=>{
        this.viewRecieptData    = res;
        this.viewRecieptLoader  = false;
      })
    }
    else{
      this.service.getSingleReceiptData(id).subscribe((res:any)=>{
        this.viewRecieptData    = res;
        this.viewRecieptLoader  = false;
      })
    }


  }

  // Advance detailed view
  viewAdvanceFunction(id:any){
    this.viewLoader  = true;
    this.viewAdvance = true;
    this.advanceid   = id;
    this.viewdata    = [];
    this.service.getAdvanceDetailedView(this.advanceid).subscribe((res: any) => {
      this.viewdata   = res;
      this.viewLoader = false;
   });
  }

  closefnview(data:any){
    this.viewAdvance = data;
  }


  // test end

}
