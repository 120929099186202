<div class="container-fluid p-24 " *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Provident Fund</h3>
            <p class="text-helper mb-md-0">Create and configure PF challan group and map it to relevant OU.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class=" " style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='pfsearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchPfGroup($event)" [isLoading]="isLoading"></app-searchbar>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" (click)="filterPanel=true">
                      <i class="icon-filter-left fs-13"></i>
                  </button>
                </div>
                <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/challan-settings/challan-settings-setup/pf"><i
                        class="icon-plus"></i>Add CHALLAN GROUP</button>
            </div>
        </div>
    </div>

    <div class="row row-16 " *ngIf="loader">
      <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <div *ngIf="nodata && loader==false;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg41.png'" [noDataText]="'Oops, PF challan groups unavailable!'"
        [noDataPara]="'It seems no PF challan groups have been created yet. To create one, click the \'Add Challan\' button, and it will appear here once completed.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>

    <ng-template #all_data>
      <div class="row row-16" infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [fromRoot]="true"
      (scrolled)="onScrollDown()">
          <ng-container  *ngIf="loader==false" >
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let pf of pfList let i = index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex view-section" (click)="viewPf(pf?.id)">
                            <div class="logo-img  {{pf?.color_code}}">{{this.appService.getFirstChar(pf?.name,2)}}</div>
                            <div class="overflow-hidden">
                                <h6 class="mb-1 tips">{{pf?.name}} </h6>
                                <span class="tips-helper">{{pf?.name}} </span>
                                <div class="fs-10 text-uppercase fw-500  {{(pf?.is_active == true) ? 'text-success' : 'text-danger'}}">{{(pf?.is_active
                                  == true) ? 'Active' : 'Deleted'}}</div>
                            </div>
                        </div>
                        <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="pf?.is_active && (permissions.e || permissions.d)">
                            <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                                <i class="icon-three-dots-vertical "></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
                                <button *ngIf="permissions.e" ngbDropdownItem routerLink="/challan-settings/challan-settings-setup/pf/{{pf?.id}}/">
                                    <i class="icon-pencil-square me-1"></i> Edit
                                </button>
                                <button  *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deleteModal(pf?.id)">
                                    <i class="icon-trash  me-1" ></i> Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </ng-container>

          <!-- Loader updations -->
          <ng-container *ngIf="infinityloader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
          </ng-container>
      </div>
    </ng-template>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='challansettingsView === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>View </h5>
            <a class="toggle-panel" (click)="challansettingsView = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <div class="row row-16" *ngIf="!loader1">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0"></p>
                        <a  *ngIf="(permissions.e && pfView?.is_active)" class="link-primary fs-14 ms-auto text-nowrap" routerLink="/challan-settings/challan-settings-setup/pf/{{pfView?.id}}/"><i
                                class="icon-pencil-square me-1"></i>
                            Edit</a>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">Challan group details </p>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Group name</div>
                                    <div class="detail-desc">{{pfView?.name}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Status</div>
                                    <div class="detail-desc">
                                        <span class="{{(pfView?.is_active == true) ? 'text-success' : 'text-danger'}}">{{(pfView?.is_active
                                          == true) ? 'Active' : 'Deleted'}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Responsible person</div>

                                    <div class="d-flex align-items-center">
                                      <img
                                        *ngIf="(pfView?.responsible_persone !=null && (pfView?.responsible_persone?.profile_image !=  '' && pfView?.responsible_persone?.profile_image !=  null))"
                                        class="user-img img-fluid rounded-circle sq-32" src="{{pfView?.responsible_persone?.profile_image}}">
                                      <div
                                        *ngIf="(pfView?.responsible_persone !=null && (pfView?.responsible_persone?.profile_image ==  '' || pfView?.responsible_persone?.profile_image ==  null))"
                                        class="avatar-circle fs-14 fw-500 {{pfView?.responsible_persone?.color_code}} sq-32 rounded-circle">
                                        <span>{{this.appService.getFirstChar(pfView?.responsible_persone?.first_name+" "+pfView?.responsible_persone?.last_name,1)}}</span>
                                      </div>
                                      <div class="detail-desc p-2">
                                        {{pfView?.responsible_persone?.first_name+' '+(pfView?.responsible_persone?.middle_name!=null?pfView?.responsible_persone?.middle_name:' ')+' '+pfView?.responsible_persone?.last_name+" ("+pfView?.responsible_persone?.employee_code+")"}}
                                      </div>
                                    </div>

                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Group owner name</div>

                                    <div class="d-flex align-items-center">
                                      <img
                                        *ngIf="(pfView?.group_owner !=null && (pfView?.group_owner?.profile_image !=  '' && pfView?.group_owner?.profile_image !=  null))"
                                        class="user-img img-fluid rounded-circle sq-32" src="{{pfView?.group_owner?.profile_image}}">
                                      <div
                                        *ngIf="(pfView?.group_owner !=null && (pfView?.group_owner?.profile_image ==  '' || pfView?.group_owner?.profile_image ==  null))"
                                        class="avatar-circle fs-14 fw-500 {{pfView?.group_owner?.color_code}} sq-32 rounded-circle">
                                        <span>{{this.appService.getFirstChar(pfView?.group_owner?.first_name+" "+pfView?.group_owner?.last_name,1)}}</span>
                                      </div>
                                      <div class="detail-desc p-2">
                                        {{pfView?.group_owner?.first_name+' '+(pfView?.group_owner?.middle_name!=null?pfView?.group_owner?.middle_name:' ')+' '+pfView?.group_owner?.last_name+" ("+pfView?.group_owner?.employee_code+")"}}
                                      </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Group provident fund number</div>
                                    <div class="detail-desc"> {{pfView?.pf_number}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Registered address</div>
                                    <div class="detail-desc">{{pfView?.address}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">APPLICABILITY</p>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Applicable to</div>
                                    <div class="detail-desc">{{pfView?.applicability?.applicable}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">{{pfView?.applicability?.applicable}}(s) </div>
                                    <div class="detail-desc">
                                      <div class="selected-values mt-1" >
                                        <ng-container *ngIf="pfView?.applicability?.applicable == 'Company'">
                                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                            <div class="ng-value-label" title="{{rights.company_name}}">
                                              <span class="d-inline-flex align-items-center">
                                                <img class="img-fluid rounded-circle sq-24"  src="{{rights.logo}}" *ngIf="rights.logo !=  '' && rights.logo !=  null">
                                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"  *ngIf="rights.logo ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                                  <span> {{rights.company_name}}</span>
                                               </span>
                                            </div>
                                          </li>
                                         </ng-container>

                                         <ng-container *ngIf="(pfView?.applicability?.applicable != 'Company')">
                                            <div class="d-flex flex-wrap mt-8 gap-8" >
                                              <ng-container *ngFor="let rights of assignees, let i=index;">
                                                <span class="badge3"  title="{{rights?.name}}"> {{ (rights?.length>25)? (rights?.name | slice:0:25)+'..':(rights?.name)  }}
                                                </span>
                                              </ng-container>

                                            </div>
                                          </ng-container>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Delete -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16 ">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Challan?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Challan')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deletePf()">Delete</button>
      </div>
    </div>
  </div>
</div>

<!-- Filter -->
<app-salary-dashboard-filter [filter]="filterPanel" [isCompanyNeeded]='true' (applymethod)="submitForm($event)" [from]="'groupEsi'"  (resetmethod)="resetFilter()" (closemethod)="closeFilter()">
</app-salary-dashboard-filter>
