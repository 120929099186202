import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private offset: number = 0;
  private limit: number = 20;
  header_filter : any = [];
  data_filter   : any = {};

  constructor(
    private http: HttpClient,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('payroll-report-configure')){
          this.setDataFilter({})
          this.setHeaderFilter([])
        }
      }
    })
  }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  reportTypeList(type:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?report_type=${type}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  reportAPI(url:any,limit:any,offset:any,ordering:any,formData:any){
    if(formData == ''){
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  pfReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/pf-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/pf-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  investmentReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/investment-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/investment-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  esiReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/esi-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/esi-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  ptReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/pt-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/pt-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  lwfReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/lwf-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/lwf-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  salaryregisterReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-salary-register/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-salary-register/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  salaryregisterYtdReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-salary-register-ytd/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-salary-register-ytd/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

 releaseSalaryReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-release-salary/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-release-salary/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  salaryHoldReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-salary-hold/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-salary-hold/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  ctcSalaryStructureReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-ctc-salary-structure/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-ctc-salary-structure/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  // Monthly TDS
  monthlyTDSReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-monthly-tds/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-monthly-tds/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  // Employee Statutory
  employeeStatutoryReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-employee-statutory-details/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-employee-statutory-details/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

 // CTC Revision
  ctcRevisionReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-ctc-salary-revision/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-ctc-salary-revision/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  // Reimbursement Request
  reimbursementRequest(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/report-reimbursement-request/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/report-reimbursement-request/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  finalSettlementReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/final-settlement-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/final-settlement-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  investmentNotSubmittedReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/investment-not-submitted-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/investment-not-submitted-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  variablePaidReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/variable-paid-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/variable-paid-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  overtimePaymentReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/overtime-paid-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/overtime-paid-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  gratitudePaidReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/gratuity-paid-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/gratuity-paid-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  npsEmployerReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/nps-employer-contribution-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/nps-employer-contribution-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  tdsComputationReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/tds-computation-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/tds-computation-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  shiftallowanceReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/shiftallowance-paid-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/shiftallowance-paid-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  getSinglePayrollReport(id: number) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?id=${id}`, this.httpOption)
  }
  getCountryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption);
  }
  getStateList(countryid: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/region-list/?country=${countryid}`, this.httpOption);
  }
  // getBranchList(stateid: number){
  //   return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&ordering=name&state=${stateid}`, this.httpOption);
  // }
  getBranchList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }
  getCityList(stateid: number){
    return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=true&ordering=name&state=${stateid}`, this.httpOption);
  }
  getempList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name&employment_status=["Probation","Confirmed","Resigned"]`, this.httpOption);
  }
  payrollReportDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list-dropdown/?ordering=name&report_type=Payroll`, this.httpOption)
  }
  getPayGroupList() {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-group-dropdown/?is_active=true`, this.httpOption)
  }

  getVariableComponent(){
    return this.http.get(`${this.API_PATH}hr/api/v1/paycomponent-dropdown/`, this.httpOption)
  }

  scheduleReportCreate(body: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/schedule-report-create/`, body, this.httpOption)
  }

  scheduleReportList(status:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/schedule-report-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }
  scheduleReportDropDown(status:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/schedule-report-list-dropdown/?is_active=${status}&ordering=scheduler_name`, this.httpOption)
  }
  scheduleReportGet(id: number) {
    return this.http.get(`${this.API_PATH}hr/api/v1/schedule-report-detail/${id}/`, this.httpOption)
  }
  scheduleReportUpdate(id: number, body:any) {
    return this.http.put(`${this.API_PATH}hr/api/v1/schedule-report-detail/${id}/`, body, this.httpOption)
  }
  inactivateScheduleReport(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}hr/api/v1/schedule-report-inactive/${id}/`, body, this.httpOption)
  }
  reactivateScheduleReport(id: number, body:any) {
    return this.http.put(`${this.API_PATH}hr/api/v1/schedule-report-inactive/${id}/`, body, this.httpOption)
  }

  getBuList(compid:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name&company_id=${compid}`, this.httpOption);
  }

  filterMemorize(body: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/report-filter-save/`, body, this.httpOption)
  }

  getOffset(): number {
    return this.offset;
  }

  calculateOffset(pageNumber: number): number {
    this.offset = pageNumber * this.limit;
    return this.offset;
  }

  setDataFilter(val:any){
    this.data_filter = val
  }

  setHeaderFilter(val:any){
    this.header_filter = val
  }
}
