<div class="row row-16" *ngIf="loader==false && permissions">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="mb-0">Snapshot</h5>
      <ng-container *ngIf="addEditPermission">
        <a *ngIf="permissions?.e" class="link-primary fs-14 d-flex align-items-center" (click)="editSnapshot()"><i
            class="icon-pencil-square me-8"></i> Edit</a>
      </ng-container>
    </div>
  </div>
  <div class="col-12">
    <div class="card details-card shadow-0 px-24 py-16">
      <div class="row row-24 ">
        <div class="col-12 "><span class="title">Basic Information</span></div>
        <div *ngIf="getFieldPermission('prefix')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Prefix</div>
          <div class="detail-desc">{{employeeData.prefix}}</div>
        </div>
        <div *ngIf="getFieldPermission('user')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Name</div>
          <div class="detail-desc" *ngIf="employeeData.user">{{employeeData.user.first_name+"
            "+(employeeData.middle_name!=null?employeeData.middle_name+" ":" ")+employeeData.user.last_name}}</div>
        </div>
        <div *ngIf="getFieldPermission('employee_code')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Employee code</div>
          <div class="detail-desc">{{employeeData.employee_code}}</div>
        </div>
        <div *ngIf="getFieldPermission('date_of_birth')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Date of birth</div>
          <!-- <div class="detail-desc">{{this.appservice.dateFormatDisplay(this.employeeData.date_of_birth)}}</div> -->
          <div class="detail-desc">{{(employeeData.date_of_birth!='' && employeeData.date_of_birth!=null &&
            employeeData.date_of_birth!='None' &&
            employeeData.date_of_birth!='none')?this.appservice.dateFormatDisplay(this.employeeData.date_of_birth):"-"}}
          </div>
        </div>
        <div *ngIf="getFieldPermission('gender')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Gender</div>
          <div class="detail-desc">{{employeeData.gender}}</div>
        </div>
        <div *ngIf="getFieldPermission('blood_group')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Blood group</div>
          <div class="detail-desc">{{(employeeData.blood_group == null || employeeData.blood_group == '') ? '-' : employeeData.blood_group}}</div>
        </div>
        <div *ngIf="getFieldPermission('nationality')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Nationality</div>
          <div class="detail-desc">{{employeeData.nationality | titlecase}}</div>
        </div>
        <div *ngIf="getFieldPermission('work_email')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Work email</div>
          <div class="detail-desc">{{employeeData.work_email}}</div>
        </div>
        <div *ngIf="getFieldPermission('mobile_number')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Mobile number</div>
          <!-- <div class="detail-desc" *ngIf="employeeData.phone_code">{{"+"+employeeData.phone_code+" "+employeeData.mobile_number}}</div> -->
          <div class="detail-desc" *ngIf="employeeData.mobile_number">{{"+"+(employeeData.phone_code == null ? '91' : employeeData.phone_code)+" "+employeeData.mobile_number}}</div>
        </div>
        <div *ngIf="getFieldPermission('date_of_joined')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Date of joining</div>
          <!-- <div class="detail-desc">{{this.appservice.dateFormatDisplay(this.employeeData.date_of_joined)}}</div> -->
          <div class="detail-desc">{{(employeeData.date_of_joined!='' && employeeData.date_of_joined!=null &&
            employeeData.date_of_joined!=='None' &&
            employeeData.date_of_joined!=='none')?this.appservice.dateFormatDisplay(this.employeeData.date_of_joined):"-"}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card details-card shadow-0 px-24 py-16">
      <div class="row row-24 ">
        <div class="col-12"><span class="title">Employment Details</span></div>
        <div *ngIf="getFieldPermission('employment_type')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Employment type</div>
          <div class="detail-desc">{{employeeData.employment_type}}</div>
        </div>
        <div *ngIf="getFieldPermission('employment_status')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Employment status</div>
          <div class="detail-desc">{{employeeData.employment_status}}</div>
        </div>
        <div *ngIf="getFieldPermission('date_of_confirmation')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Date of confirmation</div>
          <div class="detail-desc">{{(employeeData.date_of_confirmation!='' && employeeData.date_of_confirmation!=null
            && employeeData.date_of_confirmation!='None' &&
            employeeData.date_of_confirmation!='none')?this.appservice.dateFormatDisplay(this.employeeData.date_of_confirmation):"-"}}
          </div>
        </div>
        <div class="col-lg-3  col-sm-6 col-12"
          *ngIf="employeeData.resigned_employee !=null && employeeData.resigned_employee.resignation_date!=null && employeeData.employment_status!='Retired' && employeeData.employment_status!='Confirmed' && employeeData.employment_status!='Probation'">
          <div class="detail-title">Resignation date</div>
          <div class="detail-desc">
            {{this.appservice.dateFormatDisplay(this.employeeData.resigned_employee.resignation_date)}}</div>
        </div>
        <div class="col-lg-3  col-sm-6 col-12"
          *ngIf="employeeData.resigned_employee !=null && employeeData.resigned_employee.notice_period!=null && employeeData.employment_status!='Retired' && employeeData.employment_status!='Confirmed' && employeeData.employment_status!='Probation'">
          <div class="detail-title">Notice Period</div>
          <div class="detail-desc">{{employeeData.resigned_employee.notice_period}}</div>

        </div>
        <div class="col-lg-3  col-sm-6 col-12"
          *ngIf="employeeData.resigned_employee !=null && employeeData.resigned_employee.leaving_date!=null && employeeData.employment_status!='Confirmed' && employeeData.employment_status!='Probation'">
          <div class="detail-title">Leaving date</div>
          <div class="detail-desc">
            {{this.appservice.dateFormatDisplay(this.employeeData.resigned_employee.leaving_date)}}</div>

        </div>
        <div class="col-lg-3  col-sm-6 col-12"
          *ngIf="employeeData.resigned_employee !=null && employeeData.resigned_employee.reason_for_leaving!=null && employeeData.employment_status!='Retired' && employeeData.employment_status!='Confirmed' && employeeData.employment_status!='Probation'">
          <div class="detail-title">Reason for leaving</div>
          <div class="detail-desc">{{employeeData.resigned_employee.reason_for_leaving}}</div>
        </div>
        <div class="col-lg-3  col-sm-6 col-12"
          *ngIf="employeeData.resigned_employee !=null && employeeData.resigned_employee.final_settlement_date!=null && employeeData.employment_status!='Retired' && employeeData.employment_status!='Settled' && employeeData.employment_status!='Confirmed' && employeeData.employment_status!='Probation'">
          <div class="detail-title">Final settlement date</div>
          <div class="detail-desc">
            {{this.appservice.dateFormatDisplay(employeeData.resigned_employee.final_settlement_date)}}</div>
        </div>

        <div class="col-12 "
          *ngIf="employeeData?.resigned_employee !=null && employeeData?.resigned_employee?.employment_status !='Retired' && employeeData.employment_status !='Confirmed' && employeeData.employment_status !='Probation' && loginAs!='Employee'">
          <div class="form-check">
            <input *ngIf="employeeData?.resigned_employee?.good_to_hire" type="checkbox" name="" id="good-hire"
              class="form-check-input opacity-75" checked disabled="">
            <input *ngIf="!employeeData?.resigned_employee?.good_to_hire" type="checkbox" name="" id="good-hire"
              class="form-check-input opacity-75" disabled="">
            <label for="good-hire" class="detail-desc">Is good to rehire</label>
          </div>
        </div>

        <div class="col-12 "
        *ngIf="employeeData?.resigned_employee !=null && employeeData.employment_status !='Confirmed' && employeeData.employment_status !='Probation' && loginAs!='Employee'">
        <div class="form-check">
          <input *ngIf="employeeData?.resigned_employee?.is_exclude_from_fnf" type="checkbox" name="" id="good-hire"
            class="form-check-input opacity-75" checked disabled="">
          <input *ngIf="!employeeData?.resigned_employee?.is_exclude_from_fnf" type="checkbox" name="" id="good-hire"
            class="form-check-input opacity-75" disabled="">
          <label for="good-hire" class="detail-desc">Exclude from final settlement</label>
        </div>
      </div>

      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card details-card shadow-0 px-24 py-16">
      <div class="row row-24 ">
        <div class="col-12"><span class="title">Position Details</span></div>

        <div *ngIf="getFieldPermission('reporting_manager')" class=" col-sm-6 col-12">
          <div class="detail-title">Reporting manager</div>


          <div class="d-flex align-items-center">
            <img
              *ngIf="(employeeData.reporting_manager!=null && (employeeData.reporting_manager.profile_image !=  '' && employeeData.reporting_manager.profile_image !=  null))"
              class="user-img img-fluid rounded-circle sq-32" src="{{employeeData.reporting_manager.profile_image}}">
            <div
              *ngIf="(employeeData.reporting_manager!=null && (employeeData.reporting_manager.profile_image ==  '' || employeeData.reporting_manager.profile_image ==  null))"
              class="avatar-circle fs-14 fw-500 {{employeeData.reporting_manager.color_code}} sq-32 rounded-circle">
              <span>{{this.appservice.getFirstChar(employeeData.reporting_manager.first_name+'
                '+employeeData.reporting_manager.last_name,2)}}</span>
            </div>
            <div class="detail-desc p-2">
              {{employeeData.reporting_manager!=null?(employeeData.reporting_manager.first_name+"
              "+(employeeData.reporting_manager.middle_name!=null?employeeData.reporting_manager.middle_name:'')+"
              "+employeeData.reporting_manager.last_name+" ("+employeeData.reporting_manager.employee_code+")"):'-'}}
            </div>
          </div>
        </div>
        <div *ngIf="getFieldPermission('functional_manager')" class="col-sm-6 col-12">
          <div class="detail-title">Functional manager</div>


          <div class="d-flex align-items-center">
            <img
              *ngIf="(employeeData.functional_manager!=null && (employeeData.functional_manager.profile_image !=  '' && employeeData.functional_manager.profile_image !=  null))"
              class="user-img img-fluid rounded-circle sq-32" src="{{employeeData.functional_manager.profile_image}}">
            <div
              *ngIf="(employeeData.functional_manager!=null && (employeeData.functional_manager.profile_image == '' || employeeData.functional_manager.profile_image ==  null))"
              class="avatar-circle  fs-14 fw-500 {{employeeData.functional_manager.color_code}} sq-32 rounded-circle">
              <span>{{this.appservice.getFirstChar(employeeData.functional_manager.first_name+'
                '+employeeData.functional_manager.last_name,2)}}</span>
            </div>
            <div class="detail-desc p-2">
              {{employeeData.functional_manager!=null?(employeeData.functional_manager.first_name+"
              "+(employeeData.functional_manager.middle_name!=null?employeeData.functional_manager.middle_name:'')+"
              "+employeeData.functional_manager.last_name+" ("+employeeData.functional_manager.employee_code+")"):'-'}}
            </div>
          </div>

        </div>
        <div *ngIf="getFieldPermission('company')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Company</div>

          <div class="detail-desc" *ngIf="employeeData.company">
            <!-- {{employeeData.company.company_name}} -->
            <div class="selected-values mt-1">
              <li class="ng-value">
                <div class="ng-value-label" title="{{employeeData.company.company_name}}">
                  <span class="d-inline-flex align-items-center">
                    <img class="img-fluid rounded-circle sq-24" src="{{employeeData.company.logo}}"
                      *ngIf="employeeData.company.logo !=  '' && employeeData.company.logo !=  null">
                    <span class="avatar sq-24 avatar-circle bg1"
                      *ngIf="employeeData.company.logo ==  null || employeeData.company.logo ==  ''">{{this.appservice.getFirstChar(employeeData.company.company_name,1)}}</span>
                    <span> {{employeeData.company.company_name}}</span>
                  </span>
                </div>
              </li>

            </div>
          </div>
        </div>
        <div *ngIf="getFieldPermission('businessunit')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Business unit</div>
          <div class="detail-desc" *ngIf="employeeData.businessunit">{{employeeData.businessunit.name}}</div>
        </div>
        <div *ngIf="getFieldPermission('department')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Department</div>
          <div class="detail-desc" *ngIf="employeeData.department">{{employeeData.department.name}}</div>
        </div>
        <div *ngIf="getFieldPermission('designation')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Designation</div>
          <div class="detail-desc" *ngIf="employeeData.designation">{{employeeData.designation.name}}</div>
        </div>
        <div *ngIf="getFieldPermission('branch')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Branch</div>
          <div class="detail-desc" *ngIf="employeeData.branch">{{employeeData.branch.name}}</div>
        </div>
        <div *ngIf="getFieldPermission('grade')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Grade</div>
          <div class="detail-desc" *ngIf="employeeData.grade">{{employeeData.grade.name}}</div>
        </div>
        <div *ngIf="getFieldPermission('skill_type')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Skill type</div>
          <div class="detail-desc">{{employeeData.skill_type}}</div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='(showSidePanel == true && (showSidePanel1==true))'>

  <form class="side-panel-container" [formGroup]="snapshotForm" (ngSubmit)="onSubmit()">
    <header class="side-panel-head">
      <h5> Edit Basic Information</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- Edit loader start -->
        <app-edit-loader *ngIf="loader1"></app-edit-loader>
        <!-- Edit loader end -->
        <ng-container *ngIf="!loader1">
          <p class="text-uppercase fw-500 fs-16">BASIC INFORMATION</p>
          <div class="row row-24 ">
            <div class="col-sm-6 form-row ">
              <label for="prefix" class="form-label required ">Prefix </label>

              <app-master-select [ngClass]="{ 'is-invalid': (submitted|| f.prefix.dirty) && f.prefix.errors }" [submitted]="submitted"
                [isrequired]='"true"' [placeholder]="prefixkey" [selectedItem]="f.prefix.value" id="prefix"
                formControlName="prefix" [type]='"salutation"' [selectError]='"Prefix"'
                [isdisabled]='formDisable'></app-master-select>
              <div *ngIf="(submitted || f.prefix.touched|| f.prefix.dirty) && f.prefix.errors" class="invalid-feedback">
                <div *ngIf="f.prefix.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="first_name" class="form-label required ">First name </label>
              <input type="text" class="form-control" name="" id="first_name"
                placeholder="{{this.messageService.placeholderdisp('first name')}}" formControlName="first_name"
                [ngClass]="{ 'is-invalid': (submitted || f.first_name.dirty || f.first_name.touched) &&  f.first_name.errors}">
              <div *ngIf="(submitted || f.first_name.dirty || f.first_name.touched) && f.first_name.errors"
                class="invalid-feedback">
                <div *ngIf="f.first_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="f.first_name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!(f.first_name.errors.pattern) && f.first_name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="middle_name" class="form-label">Middle name </label>
              <input type="text" class="form-control" name="" id="middle_name" formControlName="middle_name"
                placeholder="{{this.messageService.placeholderdisp('middle name')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.middle_name.dirty || f.middle_name.touched) &&  f.middle_name.errors}">
              <div *ngIf="(submitted || f.middle_name.dirty || f.middle_name.touched) && f.middle_name.errors"
                class="invalid-feedback">
                <div *ngIf="f.middle_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.middle_name.errors.pattern) && f.middle_name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="last_name" class="form-label  ">Last name </label>
              <input type="text" class="form-control" name="" id="last_name"
                placeholder="{{this.messageService.placeholderdisp('last name')}}" formControlName="last_name"
                [ngClass]="{ 'is-invalid': (submitted || f.last_name.dirty || f.last_name.touched) &&  f.last_name.errors}">
              <div *ngIf="(submitted || f.last_name.dirty || f.last_name.touched) && f.last_name.errors"
                class="invalid-feedback">
                <div *ngIf="f.last_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="f.last_name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!(f.last_name.errors.pattern) && f.last_name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="employee_code" class="form-label required ">Employee code</label>
              <input type="text" class="form-control" name="" id="employee_code" formControlName="employee_code"
                placeholder="{{this.messageService.placeholderdisp('Employee Code')}}">
            </div>
            <div class="col-sm-6 form-row ">
              <label for="date_of_birth" class="form-label required ">Date of birth </label>
              <div class="form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.date_of_birth.touched|| f.date_of_birth.dirty) && f.date_of_birth.errors}">
                <input (click)="datePicker.open()" class="form-control"
                  placeholder="{{this.messageService.placeholderdisp('date of birth')}}" matInput
                  formControlName="date_of_birth" [max]="datedob" [matDatepicker]="datePicker"
                  [matDatepickerFilter]="dobFilter"><i class="icon-calendar pointer" (click)="datePicker.open()"></i>
                <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.date_of_birth.touched|| f.date_of_birth.dirty) && f.date_of_birth.errors" class="invalid-feedback">
                <div *ngIf="f.date_of_birth.errors.matDatepickerMax">
                  {{this.messageService.dobValidation(this.appservice.dateFormatDisplay(datedob))}}</div>
                <div *ngIf="f.date_of_birth.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="gender" class="form-label required ">Gender</label>
              <app-master-select [ngClass]="{ 'is-invalid': (submitted|| f.gender.dirty) && f.gender.errors }" [submitted]="submitted"
                [isrequired]='"true"' [placeholder]="genderkey" id="gender" [submitted]="submitted"
                [selectedItem]="f.gender.value" formControlName="gender" [type]="'gender'" [selectError]='"Gender"'
                [isdisabled]='formDisable'></app-master-select>
                <div *ngIf="(submitted || f.gender.dirty) && f.gender.errors" class="invalid-feedback">
                  <div *ngIf="f.gender.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>

            <div class="col-sm-6 form-row">
              <label for="blood_group" class="form-label">Blood group</label>
              <app-master-select
                [ngClass]="{ 'is-invalid': (submitted || f.blood_group.touched|| f.blood_group.dirty) &&  f.blood_group.errors}"
                [placeholder]="bloodGroupPlaceHolder" id="blood_group" [submitted]="submitted"
                [selectedItem]="f.blood_group.value" formControlName="blood_group" [type]="bloodGroup"
                [isdisabled]='false'></app-master-select>
                <!-- <div *ngIf="(submitted || f.blood_group.dirty) && f.blood_group.errors" class="invalid-feedback">
                  <div *ngIf="f.blood_group.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div> -->
            </div>
            <div class="col-sm-6 form-row ">
              <label for="nationality" class="form-label required ">Nationality</label>
              <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.nationality.dirty) && f.nationality.errors }" [isrequired]='"true"'
                [placeholder]="natkey" id="nationality" [submitted]="submitted" [selectedItem]="f.nationality.value"
                formControlName="nationality" [type]='"nationality"' [selectError]='"Nationality"'
                [isdisabled]='formDisable'></app-master-select>
                <div *ngIf="(submitted || f.nationality.dirty) && f.nationality.errors" class="invalid-feedback">
                  <div *ngIf="f.nationality.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="work_email" class="form-label required ">Work email</label>
              <input type="text" class="form-control" name="" id="work_email"
                placeholder="{{this.messageService.placeholderdisp('Work Email')}}" formControlName="work_email"
                [ngClass]="{ 'is-invalid': (submitted || f.work_email.dirty || f.work_email.touched) &&  (f.work_email.errors || mailError!='')}"
                (keyup)="keyUp('user__email',f.work_email.value)">
              <div
                *ngIf="(submitted || f.work_email.dirty || f.work_email.touched) && (f.work_email.errors || mailError!='')"
                class="invalid-feedback">
                <div *ngIf="f.work_email.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="f.work_email.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!(f.work_email.errors?.pattern) && mailError!=''">{{mailError}}</div>
                <div *ngIf="f.work_email.hasError('exist')">
                  {{this.messageService.validationDisplay(f.work_email.value)}}</div>


              </div>
            </div>

            <div class="col-sm-6 form-row ">
              <label for="mobile_number" class="form-label required ">Mobile number</label>

              <div class="d-flex form-ngselect-input"
                [ngClass]="{ 'is-invalid':  hasEmergencyContactError() || phnError!='' }">
                <app-master-select
                [ngClass]="{ 'is-invalid': hasEmergencyContactError() || phnError!='' || f.mobile_number.errors?.exist }"
                [invalidClass]="{ 'is-invalid': hasEmergencyContactError() }"
                [isrequired]='"true"' [placeholder]="''" id="phone_code" [submitted]="submitted"  (selectedValue)="phnNumSelected($event)"
                [selectedItem]="f.phone_code.value" formControlName="phone_code" [type]='"telephone_code"'></app-master-select>

                <input type="text" class="form-control" name="" id=""
                  formControlName="mobile_number" placeholder="{{this.messageService.placeholderdisp('mobile number')}}"
                  [ngClass]="{ 'is-invalid': hasEmergencyContactError() || phnError!='' || f.mobile_number.errors?.exist}"
                 >
              </div>
              <div
                *ngIf="hasEmergencyContactError() || phnError!='' || f.mobile_number.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.mobile_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!f.mobile_number.errors.pattern && f.mobile_number.errors?.maxlength">{{this.messageService.validationdigits('Contact number','15')}}</div>
                <div *ngIf="!f.mobile_number.errors.pattern && f.mobile_number.errors?.minlength">{{this.messageService.validationdigits('Contact number','5')}}</div>
                <div *ngIf="f.mobile_number.errors?.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="phnError!='' && !(f.mobile_number.errors) ">{{phnError}}</div>
                <div *ngIf="f.mobile_number.hasError('exist')">
                  {{this.messageService.validationDisplay(f.mobile_number.value)}}</div>
              </div>
            </div>

            <div class="col-sm-6 form-row ">
              <label for="date_of_joined" class="form-label required ">Date of joining </label>
              <div class="form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.date_of_joined.touched|| f.employment_status.dirty) && f.date_of_joined.errors}">
                <input (click)="datePicker1.open()"
                  placeholder="{{this.messageService.placeholderdisp('date of joining')}}" class="form-control" matInput
                  formControlName="date_of_joined" [max]="maxdoj" [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker1"
                  (dateChange)="getDOJ(f.employment_status.value)"><i class="icon-calendar pointer"
                  (click)="datePicker1.open()"></i>
                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.date_of_joined.touched|| f.employment_status.dirty) && f.date_of_joined.errors" class="invalid-feedback">
                <div *ngIf="f.date_of_joined.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
          </div>
          <p class="text-uppercase fw-500 fs-16 mt-32">EMPLOYMENT DETAILS</p>
          <div class="row row-24 ">
            <div class="col-sm-6 form-row ">
              <label for="employment_type" class="form-label required ">Employment type </label>
              <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.employment_type.touched|| f.employment_status.dirty) && f.employment_type.errors }"
                [isrequired]='"true"' [placeholder]="emptypekey" id="employment_type" [submitted]="submitted"
                [selectedItem]="f.employment_type.value" formControlName="employment_type" [type]='"employment_Type"'
                [selectError]='"Employment Type"' [isdisabled]='formDisable'></app-master-select>
              <div *ngIf="(submitted || f.employment_type.touched|| f.employment_status.dirty) && f.employment_type.errors"
                class="invalid-feedback">
                <div *ngIf="f.employment_type.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="employment_status" class="form-label required ">Employment status </label>
              <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.employment_status.touched || f.employment_status.dirty) && f.employment_status.errors }"
                [isrequired]='"true"' [placeholder]="empstakey" id="employment_status" [submitted]="submitted"
                [selectedItem]="f.employment_status.value" formControlName="employment_status"
                [type]='"profile_section_emp_status"' [selectError]='"Employment Status"' [isdisabled]='employmentDisable'
                (ngModelChange)="empStatChangeFunction(f.employment_status.value)"></app-master-select>
              <div *ngIf="(submitted || f.employment_status.touched || f.employment_status.dirty) && f.employment_status.errors"
                class="invalid-feedback">
                <div *ngIf="f.employment_status.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="f.employment_status.hasError('errstatus')">Employment status cannot be changed to probation.
                </div>
                <div *ngIf="f.employment_status.hasError('confirmerr')">Employment status cannot be changed, as confirmation request is already initiated.
                </div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="date_of_confirmation" class="form-label">Date of confirmation</label>
              <div class="form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.date_of_confirmation.touched || f.date_of_confirmation.dirty) && f.date_of_confirmation.errors }">

                <input (click)="datePicker2.open()"
                  placeholder="{{this.messageService.placeholderdisp('date of confirmation')}}" class="form-control"
                  matInput formControlName="date_of_confirmation" [min]="f.date_of_joined.value"
                  [matDatepicker]="datePicker2" (dateChange)="getDOC(f.employment_status.value)">
                <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
              </div>
              <div
                *ngIf="( (submitted || f.date_of_confirmation.dirty) || f.date_of_confirmation.touched) && f.date_of_confirmation.errors"
                class="invalid-feedback">
                <div *ngIf="f.date_of_confirmation.errors && !f.date_of_confirmation.errors.matDatepickerMin">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="f.date_of_confirmation.errors.matDatepickerMin">Date of confirmation should be greater than date of joining</div>
              </div>
            </div>

            <!-- Exit procedure -->
            <ng-container
              *ngIf="f.employment_status.value=='Resigned'  || f.employment_status.value=='Terminated' || f.employment_status.value=='Settled' || f.employment_status.value=='Relieved'">
              <div class="col-sm-6 form-row ">
                <label for="resig_date" class="form-label {{f.employment_status.value!='Terminated'?' required':''}}  ">Resignation date</label>

                <div class="form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (submitted ||f.resignation_date.dirty || f.resignation_date.touched) && f.resignation_date.errors}">

                  <input (click)="datePicker2.open()"
                    placeholder="{{this.messageService.placeholderdisp('resignation date')}}" class="form-control"
                    matInput formControlName="resignation_date" [matDatepicker]="datePicker2" [min]="resigMinDate"
                    [max]="resigMaxDate"
                    [ngClass]="{ 'is-invalid': (submitted ||f.resignation_date.dirty || f.resignation_date.touched) && f.resignation_date.errors}"
                    (dateChange)="getLeavingdate()"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                </div>
                <div
                  *ngIf="(submitted || f.resignation_date.dirty || f.resignation_date.touched) && f.resignation_date.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.resignation_date.errors.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 form-row ">
                <label for="not-period" class="form-label required ">Notice period </label>
                <div class="form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (submitted || f.notice_period.dirty || f.notice_period.touched) &&  f.notice_period.errors}">
                  <input type="text" autocomplete="off" class="form-control" name="" id="noticeperiod"
                    placeholder="{{this.messageService.placeholderdisp('notice period')}}"
                    formControlName="notice_period"
                    [ngClass]="{ 'is-invalid': (submitted || f.notice_period.dirty || f.notice_period.touched) &&  f.notice_period.errors}"
                    (keyup)="getLeavingdate()">
                  <span class="text-light-400 fs-14 ">days</span>
                </div>
                <div *ngIf="(submitted || f.notice_period.dirty || f.notice_period.touched) && f.notice_period.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.notice_period.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="f.notice_period.errors.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                  <div *ngIf="!(f.notice_period.errors.pattern) && f.notice_period.errors.maxlength">
                    {{this.messageService.fieldlengthvalidation('num','3')}}</div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="f.employment_status.value!='Probation' && f.employment_status.value!='Confirmed'|| f.employment_status.value =='Retired' || f.employment_status.value=='Resigned' || f.employment_status.value=='Relieved' || f.employment_status.value=='Terminated' || f.employment_status.value=='Settled'">
              <div class="col-sm-6 form-row ">
                <label for="leaving_date" class="form-label required ">Leaving date</label>
                <div class="form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (submitted ||f.leaving_date.dirty || f.leaving_date.touched) && (f.leaving_date.errors || f.leaving_date?.errors?.attendanceProcessed)}">

                  <input (click)="datePicker2.open()" readonly
                    placeholder="{{this.messageService.placeholderdisp('Leaving date')}}" class="form-control" matInput
                    [min]="leaveMinDate" [max]="leaveMaxDate" formControlName="leaving_date"
                    [matDatepicker]="datePicker2"
                    [ngClass]="{ 'is-invalid': (submitted ||f.leaving_date.dirty || f.leaving_date.touched) && (f.leaving_date.errors || f.leaving_date?.errors?.attendanceProcessed)}" (dateChange)="getSalaryProcessedData()"><i
                    class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.leaving_date.touched) && (f.leaving_date.errors || f.leaving_date?.errors?.attendanceProcessed)" class="invalid-feedback">
                  <div *ngIf="f.leaving_date.errors.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                  <div *ngIf="f.leaving_date.errors?.attendanceProcessed">Attendance is already processed for the selected month.</div>
                  <div *ngIf="f.leaving_date.errors?.salaryProcessed">Salary is already processed for the selected month.</div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="f.employment_status.value=='Resigned' || f.employment_status.value=='Relieved' || f.employment_status.value=='Terminated' || f.employment_status.value=='Settled'">
              <div class="col-sm-6 form-row ">
                <div *ngIf="f.employment_status.value!='Terminated';else all_data">
                  <label for="reason_for_leaving" class="form-label required">Reason for leaving</label>
                </div>
                <ng-template #all_data>
                  <label for="reason_for_leaving" class="form-label">Reason for leaving</label></ng-template>

                <ng-select
                  [ngClass]="{ 'is-invalid': (submitted || f.reason_for_leaving.dirty || f.reason_for_leaving.touched) && f.reason_for_leaving.errors }"
                  class="form-ngselect" formControlName="reason_for_leaving"
                  placeholder="{{this.messageService.selectplaceholddisplay('reason for leaving')}}">
                  <ng-option *ngFor="let item of reason" [value]="item.name">{{ item.name }}</ng-option>
                </ng-select>
                <div
                  *ngIf="(submitted || f.reason_for_leaving.dirty || f.reason_for_leaving.touched) && f.reason_for_leaving.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.reason_for_leaving.errors.required">
                    {{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="f.employment_status.value=='Settled'">

              <div class="col-sm-6 form-row ">
                <label for="final_settlement_date" class="form-label required ">Final settlement date</label>
                <div class="form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (submitted || f.final_settlement_date.touched) && f.final_settlement_date.errors}">

                  <input (click)="datePicker2.open()"
                    placeholder="{{this.messageService.placeholderdisp('final settlement date')}}" class="form-control"
                    matInput formControlName="final_settlement_date" [matDatepicker]="datePicker2"
                    [ngClass]="{ 'is-invalid': (submitted ||f.final_settlement_date.dirty || f.final_settlement_date.touched) && f.final_settlement_date.errors}"><i
                    class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.final_settlement_date.touched) && f.final_settlement_date.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.final_settlement_date.errors.required">
                    {{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="(f.employment_status.value=='Resigned' || f.employment_status.value=='Relieved' || f.employment_status.value=='Terminated' || f.employment_status.value=='Settled') && loginAs!='Employee'">
              <div class="form-check-inline">
                <input type="checkbox" id="rehire" class="form-check-input" formControlName="good_to_hire"
                  (change)="CheckedOrNot($event)">
                <label for="rehire" class="form-check-label"> Is good to rehire </label>

              </div>
            </ng-container>
            <ng-container *ngIf="(f.employment_status.value=='Resigned' || f.employment_status.value=='Relieved' || f.employment_status.value=='Retired' || f.employment_status.value=='Terminated') && loginAs!='Employee'">
              <div class="form-check-inline">
                <input type="checkbox" id="fnf" class="form-check-input" formControlName="is_exclude_from_fnf"
                  >
                <label for="fnf" class="form-check-label">Exclude from final settlement</label>
              </div>
            </ng-container>
            <!-- end Eit procedure -->

          </div>
          <p class="text-uppercase fw-500 fs-16 mt-32">POSITION DETAILS </p>
          <div class="row row-24 ">
            <div class="col-sm-6 form-row ">
              <label for="company" class="form-label required ">Company </label>
              <ng-select (change)="getBUListFunction()" [ngClass]="{ 'is-invalid': (submitted|| f.company.dirty) && f.company.errors }"
                class="form-ngselect" formControlName="company"
                placeholder="{{this.messageService.selectplaceholddisplay('company')}}" bindValue="id"
                bindLabel="company_name" [items]="companyDD">
                <ng-template ng-label-tmp let-item="item" let-index="index">
                  <div class="d-inline-flex align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                    <span *ngIf="item.logo ==  ''|| item.logo == null"
                      class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                      <span>
                        {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                    </span>
                    {{item.company_name}}
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                    <span *ngIf="item.logo ==  ''|| item.logo == null"
                      class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                      <span>
                        {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                    </span>
                    {{item.company_name}}
                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="(submitted|| f.company.dirty) && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>

            </div>
            <div class="col-sm-6 form-row ">
              <label for="businessunit" class="form-label required ">Business unit</label>
              <ng-select [ngClass]="{ 'is-invalid': (submitted|| f.businessunit.dirty) && f.businessunit.errors }" class="form-ngselect"
                formControlName="businessunit"
                placeholder="{{this.messageService.selectplaceholddisplay('Business unit')}}">
                <ng-option *ngFor="let item of buDD" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
              <div *ngIf="(submitted|| f.businessunit.dirty) && f.businessunit.errors" class="invalid-feedback">
                <div *ngIf="f.businessunit.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>

            </div>
            <div class="col-sm-6 form-row ">
              <label for="department" class="form-label required ">Department </label>
              <ng-select [ngClass]="{ 'is-invalid': (submitted|| f.department.dirty) && f.department.errors }" class="form-ngselect"
                formControlName="department" placeholder="{{this.messageService.selectplaceholddisplay('department')}}">
                <ng-option *ngFor="let item of DepDD" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
              <div *ngIf="(submitted|| f.department.dirty) && f.department.errors" class="invalid-feedback">
                <div *ngIf="f.department.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>

            </div>
            <div class="col-sm-6 form-row ">
              <label for="designation" class="form-label required ">Designation </label>
              <ng-select class="" [ngClass]="{ 'is-invalid': (submitted|| f.designation.dirty) && f.designation.errors }" class="form-ngselect"
                formControlName="designation"
                placeholder="{{this.messageService.selectplaceholddisplay('designation')}}">
                <ng-option *ngFor="let item of DesignationDD" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
              <div *ngIf="(submitted|| f.designation.dirty) && f.designation.errors" class="invalid-feedback">
                <div *ngIf="f.designation.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>

            </div>
            <div class="col-sm-6 form-row ">
              <label for="branch" class="form-label required ">Branch </label>
              <ng-select [ngClass]="{ 'is-invalid': (submitted|| f.branch.dirty) && f.branch.errors }" class="form-ngselect"
                formControlName="branch" placeholder="{{this.messageService.selectplaceholddisplay('branch')}}">
                <ng-option *ngFor="let item of BranchDD" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
              <div *ngIf="(submitted|| f.branch.dirty) && f.branch.errors" class="invalid-feedback">
                <div *ngIf="f.branch.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>

            </div>
            <div class="col-sm-6 form-row ">
              <label for="grade" class="form-label required ">Grade </label>
              <ng-select [ngClass]="{ 'is-invalid': (submitted|| f.grade.dirty) && f.grade.errors }" class="form-ngselect"
                formControlName="grade" placeholder="{{this.messageService.selectplaceholddisplay('grade')}}">
                <ng-option *ngFor="let item of gradeDD" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
              <div *ngIf="(submitted|| f.grade.dirty) && f.grade.errors" class="invalid-feedback">
                <div *ngIf="f.grade.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>

            </div>
            <div class="col-sm-6 form-row ">
              <label for="reporting_manager" class="form-label">Reporting manager</label>
              <ng-select class="form-ngselect" formControlName="reporting_manager" [ngClass]="{ 'is-invalid': f.reporting_manager.errors?.relievedError}" (change)="assignedToChangeFunction($event,'reporting_manager')"
                placeholder="{{this.messageService.selectplaceholddisplay('reporting manager')}}" [items]="empDD"
                bindValue="id" bindLabel="fullname">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.fullname,2)}}</span>
                    </span>
                    {{item.fullname}}
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.fullname,2)}}</span>
                    </span>
                    {{item.fullname}}
                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="f.reporting_manager.errors?.relievedError" class="invalid-feedback">
                <div *ngIf="f.reporting_manager.errors?.relievedError">Employment status : {{empReportStatus}}.</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="functional_manager" class="form-label">Functional manager </label>
              <ng-select class="form-ngselect" formControlName="functional_manager"
                placeholder="{{this.messageService.selectplaceholddisplay('functional manager')}}" [items]="empDD" [ngClass]="{ 'is-invalid': f.functional_manager.errors?.relievedError}" (change)="assignedToChangeFunction($event,'functional_manager')"
                bindValue="id" bindLabel="fullname">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.fullname,2)}}</span>
                    </span>
                    {{item.fullname}}
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.fullname,2)}}</span>
                    </span>
                    {{item.fullname}}
                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="f.functional_manager.errors?.relievedError" class="invalid-feedback">
                <div *ngIf="f.functional_manager.errors?.relievedError">Employment status : {{empFunctionStatus}}.</div>
              </div>
            </div>
            <div class="col-sm-6 form-row ">
              <label for="skill_type" class="form-label required ">Skill type </label>
              <app-master-select [dropdownPosition]='"top"'
                [ngClass]="{ 'is-invalid': (submitted|| f.skill_type.dirty) && f.skill_type.errors }" [isrequired]='"true"'
                [placeholder]="skillkey" id="emp_status" [submitted]="submitted" [selectedItem]="f.skill_type.value"
                formControlName="skill_type" [type]='"skill_type"' [selectError]='"Skill Type"'
                [isdisabled]='formDisable'></app-master-select>
              <div *ngIf="((submitted|| f.skill_type.dirty) || f.skill_type.touched) && f.skill_type.errors" class="invalid-feedback">
                <div *ngIf="f.skill_type.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32" *ngIf="!loader1">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="saveClicked">Save</button>
    </footer>
  </form>
</div>
<!-- slider section end -->

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
