<div *ngIf="issalsumm" class="row row-16 ">
    <div class="col-12">
        <div class="card card-c2 overflow-hidden">
            <div class="table-responsive">
                <table class="table table-striped form-table  table-sm ">
                    <thead>
                        <tr>
                            <th class="fw-600" scope="col">Component</th>
                            <th class="fw-600" scope="col">Amount({{this.month}})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="salarysummaryList.length<=0">
                            <td colspan="4">
                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngFor="let item of $any(salarysummaryList |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">

                            <td>{{item.name}} </td>
                            <td>{{item.current_month| currency:item.currency}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="col-12">
        <ng-container *ngIf="this.appservice.filteredcount(salarysummaryList,searchString)>pageSize">
            <ngb-pagination class="d-flex justify-content-end"
                [collectionSize]="this.appservice.filteredcount(salarysummaryList,searchString)" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true"
                [boundaryLinks]="true"></ngb-pagination>
        </ng-container>
    </div>
</div>


<div class="row" *ngIf="isempsalsumm" style="padding-bottom: 4.5rem;">
    <div class="col-12">
        <div class="card card-c2 overflow-hidden">
            <div class="table-responsive">
                <table class="table table-striped form-table  table-sm ">
                    <thead>
                        <tr>
                            <th *ngIf="isRunParoll" scope="col" class="form-cell">
                                <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                                    [checked]="checkallSelected()">
                            </th>
                            <th class="fw-600" scope="col">Employee Code</th>
                            <th class="fw-600" scope="col">Employee Name</th>
                            <th class="fw-600" scope="col">Gross({{this.month}})</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="empsalarysummaryList.length<=0">
                            <td colspan="5">
                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngFor="let item of $any(empsalarysummaryList |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                            <td class="form-cell" *ngIf="isRunParoll">
                                <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">
                            </td>
                            <td>{{item.employee_code}} </td>
                            <td>{{item.employee_name}} </td>
                            <td>{{item.current_gross| currency:item.currency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="col-12">
        <ng-container *ngIf="filteredcount()>pageSize">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="filteredcount()" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true"
                [boundaryLinks]="true"></ngb-pagination>
        </ng-container>
    </div>
    <footer class="submit-footer gap-16 justify-content-end position-absolute shadow-0 border-0 border-top" *ngIf="
        isRunParoll" style="left:0rem">
        <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="empsalarysummaryList.length>0">
            <span class="">{{countSelected()}} of {{empsalarysummaryList?.length}} selected </span>
            <span *ngIf="empsalarysummaryList.length != countSelected()" class="link-primary fw-500"
                (click)="selectAll();">Select all {{empsalarysummaryList?.length}} employees</span>
            <span *ngIf="empsalarysummaryList.length == countSelected()" class="link-primary fw-500"
                (click)="unselectAll();">Unselect all {{empsalarysummaryList?.length}} employees</span>
        </p>
        <button [disabled]="countSelected()==0" (click)="createSelectedLIst();"
            class="btn btn-danger btn-sm text-uppercase">Delete</button>
    </footer>
</div>

<div class="modal  modal-alert {{isDelete == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16 ">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Delete Prior Payroll</h4>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{this.messageService.Deletemsgdisplay('the selected employee(s) prior payroll')}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="isDelete = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
                    (click)="confirmDelete()">Delete</button>
            </div>
        </div>
    </div>
</div>
<app-error-download [fileName]="'Prior Payroll Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Priro Payroll'" (closePanel)="downloadMsg = false;" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>
