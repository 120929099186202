import { Component, Input, OnInit, ViewChild,Output,EventEmitter, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { FormBuilder,Validators  } from '@angular/forms';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MessageService } from 'src/app/message.global';
import { PhonenoValidator, phoneNumberValidator } from 'src/app/@shared/validators/phoneno.validators';
import { EmailinputValidator } from 'src/app/@shared/validators/emailinput.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { debounceTime } from 'rxjs/operators'
import { Subject } from 'rxjs';
import { noDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';



@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnapshotComponent implements OnInit {
  loader                      = true;
  submitted:boolean           = false;
  isrequired                  = true;
  phoneCode:any[]             = [];
  bloodGroup:string           = "blood_Group";
  bloodGroupPlaceHolder       = this.messageService.selectplaceholddisplay('blood group');
  currentYear:number          = 0;
  loader1                     = false;
  dob:any                     = '';
  showSidePanel               = false;
  permissions:any             = [];
  formDisable:any;
  employmentDisable:any;

  resigMinDate                = new Date();
  leaveMinDate                = new Date();
  resigMaxDate                = new Date(new Date().getFullYear() + 5, 11, 31);
  leaveMaxDate                = new Date(new Date().getFullYear() + 5, 11, 31);

  validated : boolean = false
  snapshotUnit : any = []
  clearfield : any
  field : any
  validationfield : any
  edit : boolean = false;
  value:any;
  showInfo       = false;
  infoMsg:any;
  leavingdate : any
  reason : any;
  resig_date : any;
  notperiod :any
  noticPeriod : any;
  empdataArr : any
  mergedData : any
  goodCheck : any
  // settleCheck : boolean = false
  maxdoj = new Date(new Date().getFullYear() + 2, 11, 31)
  private subjectKeyUp = new Subject<any>();

  @Input() showSidePanel1:any;
  @Input() codeError:any;
  @Input() phnError:any ;
  @Input() mailError:any;
  @Input() employeeData:any;
  @Input() id:any;
  @Input() loginAs :any;
  @Input() gradeDD :any;
  @Input() BranchDD :any;
  @Input() DesignationDD :any;
  @Input() DepDD  :any;
  @Input() buDD  :any;
  @Input() companyDD :any;
  @Input() empDD:any;
  @Input() isSuccess:any;
  @Input() routingUrl:any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  @Output() submitSnapshotData = new EventEmitter();
  @Output() SnapshotData       = new EventEmitter();
  prefixkey = this.messageService.selectplaceholddisplay('prefix');
  genderkey = this.messageService.selectplaceholddisplay('gender type');
  emptypekey = this.messageService.selectplaceholddisplay('employment type');
  empstakey = this.messageService.selectplaceholddisplay('employment status');
  skillkey = this.messageService.selectplaceholddisplay('skill type');
  natkey = this.messageService.selectplaceholddisplay('nationality');

  snapshotForm:any = ''
   datedob:any = new Date();
   saveClicked = false

  private empStatusChange = new Subject<any>();

  constructor(@Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,public appservice:AppService,private snapshotService:SnapshotService,private formBuilder: FormBuilder,private authService: AuthService,public messageService : MessageService,private datePipe:DatePipe, private existDataService : ExistDatasService,
  private changeDetectorRef: ChangeDetectorRef) {
  }
  ngOnChanges(){
    let len = this.empDD.length
          for (let i = 0; i < len; i++) {
          this.empDD[i]['fullname']  = "";
          if(this.empDD[i]['first_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
          if(this.empDD[i]['middle_name'] != null && this.empDD[i]['middle_name'] != '')
           this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
          if(this.empDD[i]['last_name'] != null && this.empDD[i]['last_name'] != '')
           this.empDD[i]['fullname'] += this.empDD[i]['last_name'];
           this.empDD[i]['fullname']   +=" ("+this.empDD[i]['employee_code']+")";

          }
    if(this.mailError != ""){

        this.saveClicked = false;
      }
    }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d.fieldName,d.fieldValue)
    })

    this.datedob.setDate( this.datedob.getDate() - 6 );

    this.datedob.setFullYear( this.datedob.getFullYear() - 14 );
    this.reasonlist()

    this.getPermission();
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.loader = false;
    this.snapshotForm                = this.formBuilder.group({
      prefix                :['',Validators.required],
      first_name            :['',[Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
      last_name             :['',[NamefieldValidator(),Validators.maxLength(100)]],
      middle_name           :['',[NamefieldValidator(),Validators.maxLength(100)]],
      employee_code         :'',
      date_of_birth         :[null,Validators.required],
      gender                :['',Validators.required],
      blood_group           :[''],
      nationality           :['',Validators.required],
      work_email            :['',[Validators.required,EmailinputValidator()]],
      phone_code            :[],
      mobile_number         :['',[Validators.required,Validators.minLength(5),Validators.maxLength(15), NumberpatternValidator()]],
      date_of_joined        :[null,Validators.required],
      employment_type       :['',Validators.required],
      employment_status     :['',Validators.required],
      date_of_confirmation  :[null],
      company               :['',Validators.required],
      businessunit          :['',Validators.required],
      department            :['',Validators.required],
      designation           :['',Validators.required],
      branch                :['',Validators.required],
      grade                 :['',Validators.required],
      reporting_manager     :[null],
      functional_manager    :[null],
      skill_type            :['',Validators.required],
      resignation_date      : '',
      notice_period         : '',
      leaving_date          : '',
      reason_for_leaving     : '',
      final_settlement_date   : '',
      good_to_hire          : false,
      is_exclude_from_fnf   : false
    }, {
      validators: phoneNumberValidator('phone_code', 'mobile_number')
    });
    this.getEmployeedata();

    // this.employeeData.date_of_joined = 'None'

    this.empStatusChange.pipe(
      debounceTime(300),
    ).subscribe((value: any) => {
      this.selectdata(this.snapshotForm?.value?.employment_status);
    });
  }
  cleardata = ['resignation_date','notice_period','leaving_date','reason_for_leaving','final_settlement_date']

  empStatChangeFunction(status:any){
    if(this.snapshotForm?.get('employment_status').valid){
    if(status == 'Relieved'){
      this.showInfo=true;
      this.infoMsg ='Once you change an employee status to "Relieved", this action is permanent and cannot be undone. Please ensure that all details are correct before proceeding.'
    }
    if(this.edit == true && this.employeeData.employment_status == 'Probation' && this.employeeData?.is_confirmation_requested && status != 'Probation'){
      this.snapshotForm?.get('employment_status')?.setErrors({
        'confirmerr' : true
      })
    }else if(status == 'Probation' && this.edit == true && this.employeeData.employment_status == 'Confirmed'){
      this.snapshotForm?.get('employment_status')?.setErrors({
        'errstatus' : true
      })
    }else{
      this.snapshotForm?.get('employment_status')?.setErrors(null)
    }
    this.empStatusChange.next(status);
  }
  }

  selectdata(status:any){

    if(status == 'Resigned' || status == 'Relieved' || status == 'Settled' || status == 'Terminated' || status == 'Retired'){

      let doj = this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_joined);
      let doc = this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_confirmation);

      // if(doc == null){
      //   this.resigMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());
      //   this.leaveMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());
      // }else{
      //   this.resigMinDate = new Date(moment(doc,"YYYY-MM-DD").year(), moment(doc,"YYYY-MM-DD").month(), moment(doc,"YYYY-MM-DD").date());
      //   this.leaveMinDate = new Date(moment(doc,"YYYY-MM-DD").year(), moment(doc,"YYYY-MM-DD").month(), moment(doc,"YYYY-MM-DD").date());
      // }
      this.resigMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());
      this.leaveMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());

    }


    if(status == 'Resigned' || status == 'Relieved' || status == 'Settled' || status == 'Terminated'  ){

      // this.snapshotForm.get('resignation_date')?.setValidators([Validators.required])
      this.snapshotForm.get('notice_period')?.setValidators([Validators.required,Validators.maxLength(3),noDecimalValidator()])
       this.snapshotForm.get('leaving_date')?.setValidators([Validators.required])

      if(status == 'Terminated'){
        this.snapshotForm.get('reason_for_leaving')?.clearValidators();
        this.snapshotForm.get('resignation_date')?.clearValidators();
      }else{
        this.snapshotForm.get('reason_for_leaving')?.setValidators([Validators.required]);
        this.snapshotForm.get('resignation_date')?.setValidators([Validators.required]);
      }

      if(this.employeeData?.employment_status == 'Confirmed' || this.employeeData?.employment_status == 'Probation' || this.employeeData?.employment_status == 'Retired'){
        this.resetfields();
        this.getnoticePeriodData()
      }else if(this.employeeData?.employment_status == 'Resigned' && (status == 'Relieved' || status == 'Terminated')){
        this.resetfields();
        this.getnoticePeriodData();
      }else if(this.employeeData?.employment_status == 'Terminated' && (status == 'Relieved' || status == 'Resigned' )){
        this.resetfields();
        this.getnoticePeriodData();
      }else if(this.employeeData?.employment_status == 'Relieved' && (status == 'Terminated' || status == 'Resigned' )){
        this.resetfields();
      }

      // this.snapshotService.getnoticePeriod(this.id).subscribe((res:any)=>{
      //   // if(this.employeeData?.resigned_employee == null || this.employeeData?.resigned_employee == ''){
      //     this.snapshotForm.get('notice_period').setValue(res.notice_period)
      //   // }
      //   this.getLeavingdate()
      // })
      // this.resetfields()

    }
    else if(status == 'Retired'){
      this.snapshotForm.get('leaving_date')?.setValidators([Validators.required]);

      let  cleardata = ['resignation_date','notice_period','reason_for_leaving','final_settlement_date']
      cleardata.forEach((controlKey) => {
        this.clearfield = this.snapshotForm.get(controlKey);
        this.clearfield.clearValidators();
        this.clearfield.updateValueAndValidity();
      });

      this.resetfields()

    }
    // else if(status === 'Settled'){
    //   this.snapshotForm.get('final_settlement_date')?.setValidators([Validators.required])
    //   this.resetfields()
    //  }
     else if(status == 'Probation'){
      this.snapshotForm.get('date_of_confirmation')?.reset('')
      let  cleardata = ['resignation_date','notice_period','reason_for_leaving','final_settlement_date','leaving_date']
      cleardata.forEach((controlKey) => {
        this.clearfield = this.snapshotForm.get(controlKey);
        this.clearfield.clearValidators();
        this.clearfield.updateValueAndValidity();
      });
     }
    else{
      this.cleardata.forEach((controlKey) => {
        this.clearfield = this.snapshotForm.get(controlKey);
        this.clearfield.clearValidators();
        this.clearfield.updateValueAndValidity();
      });

    }
    this.cleardata.forEach((controlKey) => {
      this.validationfield = this.snapshotForm.get(controlKey);
      this.snapshotForm.get(controlKey).updateValueAndValidity();
    });

}

getnoticePeriodData(){
  this.snapshotService.getnoticePeriod(this.id).subscribe((res:any)=>{
      this.snapshotForm.get('notice_period').setValue(res.notice_period)
    this.getLeavingdate()
  })
}

getDOJ(status:any){
  if(status == 'Resigned' || status == 'Relieved' || status == 'Settled' || status == 'Terminated' || status == 'Retired' || status == 'Settled'  ){

    this.snapshotForm.get('resignation_date')?.setValue('');
    this.snapshotForm?.get('leaving_date')?.setValue('');

    let doj = this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_joined);

    this.resigMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());

    this.leaveMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());
  }
}

resetfields(){
  this.snapshotForm.get('resignation_date')?.reset('')
  this.snapshotForm.get('notice_period')?.reset('')
  this.snapshotForm.get('leaving_date')?.reset('')
  this.snapshotForm.get('reason_for_leaving')?.reset(null)
  this.snapshotForm.get('good_to_hire')?.reset('')
  this.snapshotForm.get('is_exclude_from_fnf')?.reset(false)
}

getLeavingdate(){


  if(this.snapshotForm.value.resignation_date!='' && this.snapshotForm.value.resignation_date!= null){

    let resignation_date = this.appservice.dateFormatConvert(this.snapshotForm.value.resignation_date);

    this.leaveMinDate = new Date(moment(resignation_date,"YYYY-MM-DD").year(), moment(resignation_date,"YYYY-MM-DD").month(), moment(resignation_date,"YYYY-MM-DD").date());
  }


  // if(this.snapshotForm.value.notice_period=='' || this.snapshotForm.value.resignation_date ==''){
  //   this.snapshotForm.get('leaving_date')?.setValue(null);
  // }

  if(this.snapshotForm.value.employment_status != 'Terminated' && this.snapshotForm.value.employment_status != 'Retired' && this.snapshotForm.value.employment_status != 'Resigned'){
    this.snapshotForm.get('leaving_date')?.setValue(null);
  }

  if(this.snapshotForm.value.resignation_date!='' && this.snapshotForm.value.notice_period!='' ){
  this.leavingdate = this.appservice.dateFormatConvert(this.snapshotForm.value.resignation_date)

  this.noticPeriod = Number(this.snapshotForm.value.notice_period)
  if(  this.snapshotForm.get('notice_period').valid)
    this.snapshotForm.get('notice_period').setValue(this.noticPeriod)
  if(this.noticPeriod !=0 ){
  const addDays = (date: Date, days: number): Date => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;


   };
  const date: Date = new Date(this.leavingdate);

  const result: Date = addDays(date,this.noticPeriod);

  if(!this.f.notice_period.errors &&!this.f.resignation_date.errors)
    this.snapshotForm.get('leaving_date')?.setValue(result);
}
else{

  this.snapshotForm.get('leaving_date')?.setValue(this.snapshotForm.value.resignation_date);
}
  }

  if(this.snapshotForm.get('leaving_date')?.value != null && this.snapshotForm.get('leaving_date')?.value != '')
    this.getSalaryProcessedData();
}

getDOC(status:any){
  if(status == 'Resigned' || status == 'Relieved' || status == 'Settled' || status == 'Terminated' || status == 'Retired' || status == 'Settled'  ){
    this.snapshotForm.get('leaving_date')?.setValue('');
    this.snapshotForm.get('resignation_date')?.setValue('');

    let doc = this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_confirmation);
    if(doc !=null){
      this.leaveMinDate = new Date(moment(doc,"YYYY-MM-DD").year(), moment(doc,"YYYY-MM-DD").month(), moment(doc,"YYYY-MM-DD").date());
      this.resigMinDate = new Date(moment(doc,"YYYY-MM-DD").year(), moment(doc,"YYYY-MM-DD").month(), moment(doc,"YYYY-MM-DD").date());
    }else{
      if(this.snapshotForm.value.resignation_date != null){
        let resig = this.snapshotForm.value.resignation_date
        this.leaveMinDate = new Date(moment(resig,"YYYY-MM-DD").year(), moment(resig,"YYYY-MM-DD").month(), moment(resig,"YYYY-MM-DD").date());
      }else{
        let doj = this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_joined);
        this.leaveMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());
        this.resigMinDate = new Date(moment(doj,"YYYY-MM-DD").year(), moment(doj,"YYYY-MM-DD").month(), moment(doj,"YYYY-MM-DD").date());
      }
    }

  }
}

reasonlist(){
  this.snapshotService.getReason().subscribe((res:any)=>{
    this.reason  = res;
  })
}
CheckedOrNot(e:any){
  this.goodCheck = e.target.checked
}

// settleChecked(e:any){
//   this.settleCheck = e.target.checked
// }

  getPermission(){

    this.permissions = this.appservice.getPermissions(this.routingUrl);
     if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }

  }

  editSnapshot(){

    if(this.employeeData?.requested_flags.is_snapshot_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo = false;
      this.loader1        = true;
      this.resetLoginUserForm(this.loginAs);
      this.SnapshotData.emit();
      this.getEmployeedata();
      this.saveClicked=false
      // this.getPhonecode();
      this.showSidePanel  = true;
      this.loader1        = false;
      this.submitted      = false;
      this.edit = true;
      this.initiateErrorStatus('reporting_manager')
      this.initiateErrorStatus('functional_manager')
    }

  }
  getEmployeedata(){
    if(this.empDD.length==0){
      if(this.employeeData.functional_manager!=null)
        this.empDD.push(this.employeeData.functional_manager);
      if(this.employeeData.reporting_manager!=null)
      this.empDD.push(this.employeeData.reporting_manager);

    }

    // this.snapshotService.getEmployeeData(id).subscribe((res:any)=>{
      let res =this.employeeData ;
      let doc:any = new Date(res.date_of_confirmation);

      this.snapshotForm.reset({
        'prefix'                    :res.prefix,
        'first_name'                :res?.user?.first_name,
        'last_name'                 :res?.user?.last_name,
        'middle_name'               :res.middle_name,
        'employee_code'             :res.employee_code,
        'date_of_birth'             :res.date_of_birth,
        'gender'                    :res.gender,
        'blood_group'               :(res.blood_group == '') ? null : res.blood_group,
        'nationality'               :res.nationality,
        'work_email'                :res.work_email,
        'mobile_number'             :res.mobile_number,
        'phone_code'                :res.phone_code == null ? '91' : res.phone_code,
        'date_of_joined'            :res.date_of_joined,
        'employment_type'           :res.employment_type,
        'employment_status'         :res.employment_status,
        'date_of_confirmation'      :res.date_of_confirmation!=null && doc != "Invalid Date"?res.date_of_confirmation:null,
        'company'                   :res.company!=null?res.company.id:null,
        'businessunit'              :res.businessunit!=null?res.businessunit.id:null,
        'department'                :res.department!=null?res.department.id:null,
        'designation'               :res.designation!=null?res.designation.id:null,
        'branch'                    :res.branch!=null?res.branch.id:null,
        'grade'                     :res.grade!=null?res.grade.id:null,
        'skill_type'                :res.skill_type,
        'functional_manager'        :res.functional_manager!=null?res.functional_manager.id:null,
        'reporting_manager'         :res.reporting_manager!=null?res.reporting_manager.id:null,
        "resignation_date"          :res.resigned_employee!=null && res.resigned_employee.resignation_date!=null?res.resigned_employee.resignation_date:null,
        "notice_period"             :res.resigned_employee!=null &&res.resigned_employee.notice_period!=null?res.resigned_employee.notice_period:null,
        "leaving_date"              :res.resigned_employee!=null &&res.resigned_employee.leaving_date!=null?res.resigned_employee.leaving_date:null,
        "reason_for_leaving"        :res.resigned_employee!=null &&res.resigned_employee.reason_for_leaving!=null?res.resigned_employee.reason_for_leaving:null,
        "final_settlement_date"     : res.resigned_employee!=null &&res.resigned_employee.final_settlement_date!=null?res.resigned_employee.final_settlement_date:null,


      })
      if(res.resigned_employee!=null &&res.resigned_employee.good_to_hire!=null){
        this.snapshotForm.get('good_to_hire').setValue(res.resigned_employee.good_to_hire)
      }
      if(res.resigned_employee!=null && res.resigned_employee.is_exclude_from_fnf!=null){
        this.snapshotForm.get('is_exclude_from_fnf').setValue(res.resigned_employee.is_exclude_from_fnf)
      }

    // });
  }
  getPhonecode(){
    this.snapshotService.getCountryCode().subscribe((res:any)=>{
      this.phoneCode  = res;

    })
  }


onSubmit(){
    this.submitted = true;
    if(this.snapshotForm.invalid)

      return;
    this.submitted = false;
    this.saveClicked = true;
    if(this.loginAs=='Employee'){
      var data =  {
                    'mobile_number' : this.snapshotForm.value.mobile_number,
                    'blood_group'   : this.snapshotForm.value.blood_group,
                    'phone_code'    : this.snapshotForm.value.phone_code,

                  }
    this.submitSnapshotData.emit(data);
  }
    else{
     var dataArray = {
        "prefix"                  : this.snapshotForm.value.prefix,
        "user"                    : {"first_name":this.snapshotForm.value.first_name,"last_name":this.snapshotForm.value.last_name},
        "middle_name"             : this.snapshotForm.value.middle_name,
        "date_of_birth"           : this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_birth),
        "gender"                  : this.snapshotForm.value.gender,
        "blood_group"             : this.snapshotForm.value.blood_group,
        "nationality"             : this.snapshotForm.value.nationality,
        "work_email"              : this.snapshotForm.value.work_email,
        "phone_code"              : this.snapshotForm.value.phone_code,
        "mobile_number"           : this.snapshotForm.value.mobile_number,
        "date_of_joined"          : this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_joined),
        "employment_type"         : this.snapshotForm.value.employment_type,
        "employment_status"       : this.snapshotForm.value.employment_status,
        "date_of_confirmation"    : (this.snapshotForm.value.date_of_confirmation!=null && this.snapshotForm.value.date_of_confirmation != '') ? this.appservice.dateFormatConvert(this.snapshotForm.value.date_of_confirmation):null,
        "skill_type"              : this.snapshotForm.value.skill_type,
        "company"                 : this.snapshotForm.value.company,
        "businessunit"            : this.snapshotForm.value.businessunit,
        "department"              : this.snapshotForm.value.department,
        "designation"             : this.snapshotForm.value.designation,
        "branch"                  : this.snapshotForm.value.branch,
        "grade"                   : this.snapshotForm.value.grade,
        "reporting_manager"       : this.snapshotForm.value.reporting_manager,
        "functional_manager"      : this.snapshotForm.value.functional_manager,
        // "is_active"               : ((this.snapshotForm.value.employment_status=='Relieved' || this.snapshotForm.value.employment_status=='Settled')?false:true),
        "is_active"               : true,
        "id"                      : this.id
      }

    if(this.snapshotForm.value.employment_status =='Resigned' || this.snapshotForm.value.employment_status =='Retired' || this.snapshotForm.value.employment_status =='Terminated' || this.snapshotForm.value.employment_status =='Settled' || this.snapshotForm.value.employment_status =='Relieved'){

      this.empdataArr = {
        resignation_data : {
            // "resignation_date" : this.snapshotForm.value.employment_status == 'Retired'|| this.snapshotForm.value.employment_status == 'Terminated'? null : this.appservice.dateFormatConvert(this.snapshotForm.value.resignation_date),
            "resignation_date" : this.snapshotForm.value.employment_status == 'Retired' ? null : (this.snapshotForm.value.employment_status == 'Terminated' && (this.snapshotForm.value.resignation_date == null || this.snapshotForm.value.resignation_date == '') ) ? null : this.appservice.dateFormatConvert(this.snapshotForm.value.resignation_date),
            "notice_period"    : this.snapshotForm.value.employment_status == 'Retired' ? 0 : this.snapshotForm.value.notice_period,
            "employment_status" :this.snapshotForm.value.employment_status,
            "leaving_date"     : this.appservice.dateFormatConvert(this.snapshotForm.value.leaving_date),
            "reason_for_leaving" :this.snapshotForm.value.reason_for_leaving,
            "final_settlement_date" : this.appservice.dateFormatConvert(this.snapshotForm.value.final_settlement_date),
            "good_to_hire"      : this.goodCheck,
            ...(this.snapshotForm.value.employment_status !== 'Settled' && { 'is_exclude_from_fnf': (this.snapshotForm.value.is_exclude_from_fnf == '' || this.snapshotForm.value.is_exclude_from_fnf == null) ? false :  this.snapshotForm.value.is_exclude_from_fnf})
      }
      }
    }
    this.mergedData = { ...dataArray ,...this.empdataArr};
    this.submitSnapshotData.emit(this.mergedData);

    }

  }

  get f() { return this.snapshotForm.controls; }
  resetLoginUserForm(loginAs:any){
    if(loginAs=='Employee'){
      this.snapshotForm.disable();
      this.snapshotForm.get('mobile_number')?.enable();
      this.snapshotForm.get('phone_code')?.enable();
      this.snapshotForm.get('blood_group')?.enable();
      this.formDisable       = true;
      this.employmentDisable = true
    }
    else{
          this.formDisable = false;
          this.employmentDisable = false

          this.snapshotForm.enable();
          this.snapshotForm.get('employee_code')?.disable();
          this.snapshotForm.get('company')?.disable();
          if(this.employeeData.employment_status == 'Relieved' ){
              this.employmentDisable = true
          }

    }
  }
  getBUListFunction(){
    this.snapshotForm.get('businessunit')?.reset();
    this.buDD = [];
    this.getBUList();
  }

  getBUList(){
    // this.snapshotForm.get('businessunit').setValue(null);
    this.snapshotService.getBuList(this.snapshotForm.value.company).subscribe((res: any) => {
          this.buDD = res;
    });
  }
  // disableDate(){
  //   return false;
  // }

  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  docFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  dobFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  dojFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // Simplified function to use for validation
  existService(fieldName :any, fieldValue:any){

    if(fieldName === 'user__email'){
      this.field = 'work_email'
    }else{
      this.field = fieldName
    }
    const fc = this.snapshotForm.get(this.field);

    if(!this.edit){
      this.value = {
        "page":"employee",
        "field":`${fieldName}`,
        "value": `${fieldValue}`
      }
    }else{
      this.value = {
        "id": this.id,
        "page":"employee",
        "field":`${fieldName}`,
        "value": `${fieldValue}`
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.snapshotUnit = res.body

      this.loader = false

        if(this.snapshotUnit.data ==='invalid'){
          fc?.setErrors({
            'exist' : true
          })
          this.saveClicked = true

        }else{
          this.saveClicked = false
        }
      this.changeDetectorRef.detectChanges();

    })
  }

  // For validation of keyup(name)

  keyUp(fieldName :any, fieldValue:any){

    this.subjectKeyUp.next({fieldName,fieldValue});

  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }


  getSalaryProcessedData(){

    this.snapshotService.getAttendaceProcessData({
      "employee": this.employeeData?.id,"relieving_date": this.appservice.dateFormatConvert(this.snapshotForm?.value?.leaving_date)}).subscribe((res: any) => {

        if(!res?.status){
          if(res?.error_type == "attendance_processed")
            this.snapshotForm?.get('leaving_date')?.setErrors({'attendanceProcessed' : true})
          else if(res?.error_type == "salary_processed")
            this.snapshotForm?.get('leaving_date')?.setErrors({'salaryProcessed' : true})
          this.snapshotForm?.get('leaving_date')?.markAsTouched();
        }
        this.changeDetectorRef.detectChanges();

    });
  }

  empReportStatus : any
  empFunctionStatus : any
  // Inactive Status
  assignedToChangeFunction(e:any,control:any){
    let arr: any = [];
    arr.push(e)
    if(this.hasSpecificStatus(arr)){
      control == 'reporting_manager' ? this.empReportStatus = 'Inactive' : this.empFunctionStatus = 'Inactive'
      this.snapshotForm?.get(control)?.setErrors({
        'relievedError' : true
      })
    }
  }

  initiateErrorStatus(control:any){
    if(this.snapshotForm.get(control)?.value != null && this.snapshotForm.get(control)?.value != ''){
      const filteredData = this.empDD.filter((item:any) => this.snapshotForm.get(control)?.value == item.id)
      setTimeout(()=>{
        if(filteredData?.employment_status == 'Resigned' || filteredData?.employment_status == 'Terminated' || filteredData?.employment_status == 'Relieved' || filteredData?.employment_status == 'Retired'){
          control == 'reporting_manager' ? this.empReportStatus = 'Inactive' : this.empFunctionStatus = 'Inactive'
          this.snapshotForm?.get(control)?.setErrors({
            'relievedError' : true
          })
          this.snapshotForm.get(control)?.markAsTouched();
          this.changeDetectorRef.detectChanges();
        }
      },0)
    }
  }

  hasSpecificStatus(employees:any){
    return employees.every((employee:any)=> !employee?.active_state_employee || employee?.is_resigned) && employees?.length != 0;
  }

  phnNumSelected(val: any) {
    this.snapshotForm.get('mobile_number').markAsTouched()
    this.snapshotForm.get('mobile_number')?.updateValueAndValidity()
    this.changeDetectorRef.detectChanges();
  }

  hasEmergencyContactError(): boolean {
    const emergencyContactControl = this.snapshotForm.get('mobile_number');
    return !!(emergencyContactControl && (emergencyContactControl.dirty || emergencyContactControl.touched) && emergencyContactControl.errors);
  }
}
