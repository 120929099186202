import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { OverrideService } from 'src/app/@core/services/override.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-override-pay-component',
  templateUrl: './override-pay-component.component.html',
  styleUrls: ['./override-pay-component.component.scss']
})
export class OverridePayComponentComponent implements OnInit {

  @Input() month:any
  @Input() year:any
  @Input() company:any
  @Input() permissions:any = [];
  employeeList:any = [];
  empCodeList:any = [];

  status = "Pending";
  paygroupDD:any=[]
  selectedPG:any =[]
  searchString:any = "";
  page = 1
  data:any = [];
  pageSize = 20;
  selctedData:any = [];
  loader = false
  confirmMsg = false
  alertToggle = false
  confirmMsgText = ''
  filter = false
//progress bar
  failedData:any = [];
  isProgressStart = false
  chunkLen = 1
  iscompleted = false;
  isDataUpload = 0;
  body = ''
  percentage:any = 0;
  successDataCount = 0
  downloadMsg = false
  successMsg = ""
  errorMsg = ""
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  gradeList:any = [];
  filterOpened = false
  constructor(private os:OverrideService,public messageService : MessageService,public appService: AppService,private changeDetection: ChangeDetectorRef, private notificationService:NotificationService) { }

  ngOnInit(): void {
    this.getPayGroupList()
  }
  getEmployeeList(){
    this.selctedData = []
    this.os.employeeList(this.company,this.selectedPG.id ).subscribe((res: any) => {
        this.employeeList = res;
        let len = this.employeeList.length
        for (let i = 0; i < len; i++) {
          this.empCodeList[this.employeeList[i].id] = this.employeeList[i].employee_code
          this.employeeList[i]['fullname']  = "";
          if(this.employeeList[i]['first_name'] != null)
           this.employeeList[i]['fullname'] += this.employeeList[i]['first_name']+" ";
          if(this.employeeList[i]['last_name'] != null)
           this.employeeList[i]['fullname'] += this.employeeList[i]['last_name'];

          }
    });
  }
  getPayGroupList(){
    this.loader = true
    this.os.payGroupList(this.month,this.year,this.status,this.company).subscribe((res: any) => {
      this.paygroupDD = res
      this.loader = false
      this.changeDetection.detectChanges();

    })
  }

  colspan(){
    return this.selectedPG?.paycomponent_list ? this.selectedPG?.paycomponent_list?.length + this.selectedPG?.paycomponent_list?.length + 7 : 6
  }

  addEmployee(){
    let newRow  = {
      'employee_code' : '',
      'month': this.month,
      'year': this.year,
      'comment':''
    }
    this.data.push(newRow)

  }
  deleteaRow(index:any){
    this.data.splice(index, 1);
    this.selctedData.splice(index, 1);
  }


  bulkDelete(){
    var delArray:any = [];
    if(this.status == 'Pending'){
      this.data.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
      var delArrayRev = delArray.reverse()
      delArrayRev.forEach((item:any, value:any) =>{
        this.deleteaRow(item);
      })

    }

  }

  setEmpCode(data:any,i:any){

    this.selctedData[i] =data
    this.data[i].employee = data
    this.data[i].employee_code = this.empCodeList[data];
    let validateData = {month: this.month,year: parseInt(this.year),pay_group: this.selectedPG.id,status: "Pending", save_data:false,data:[{employee: data}]}
    this.os.payComponentOverrideSave(validateData).subscribe((res: any) => {
      this.data[i] = res.data[0]
      this.changeDetection.detectChanges();
    });
  }

  validateComment(index:any){

    let currentValue            = this.data[index]['comment']
    const unamePattern = /^(?! )+[A-Za-z0-9 @#&_+\-\;':"\\,\/]*[A-Za-z0-9@#&_+\-\;':"\\,\/]$/.test(currentValue);
    if(this.data[index]['inputerror'] == undefined)
      this.data[index]['inputerror'] = []
    if(this.data[index]['inputerror']['comment'] == undefined)
      this.data[index]['inputerror']['comment'] = []

    this.data[index]['inputerror']['comment'].is_valid  = true;
    this.data[index]['inputerror']['comment'].error     = null;
    if(unamePattern){
      if(currentValue?.length>100){

        this.data[index]['inputerror']['comment'].is_valid = false;
        this.data[index]['inputerror']['comment'].error    = this.messageService.fieldlengthvalidation('word',500);

      }  else if(currentValue=='' || currentValue==null){
        this.data[index]['inputerror']['comment'].is_valid = false;
        this.data[index]['inputerror']['comment'].error    = this.messageService.validationDisplay('required');
      }
       else
       this.data[index]['inputerror']['comment'].is_valid = true;
    }
    else{
      this.data[index]['inputerror']['comment'].is_valid = false;
      this.data[index]['inputerror']['comment'].error = this.messageService.validationDisplay('pattern');;

    }
  }

  checkAll(event:any){
    var newList = this.appService.filterIt(this.data,this.searchString);
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  checkAllSelected(){
      var newList = this.appService.filterIt(this.data,this.searchString);
      var begin = (this.page-1) * this.pageSize ;
      var end = this.page* this.pageSize;
      var subArray = newList.slice(begin,end);
      if(subArray.length){
        return subArray.every((item: { checked: any; }) => {
          return item.checked;
        });
      } else {
        return false;
      }

  }
  selectAll(){
    this.appService.selectAll(this.data)
  }
  unselectAll(){
    this.appService.unselectAll(this.data)
  }
  validate(index:any,PCid:any,event:any){

    if(this.data[index]['inputerror'] == undefined)
      this.data[index]['inputerror'] = []
    if(this.data[index]['inputerror'].component_list == undefined)
      this.data[index]['inputerror'].component_list = []
    if(this.data[index]['inputerror'].component_list[PCid] == undefined)
      this.data[index]['inputerror'].component_list[PCid] = []
    this.data[index]['inputerror'].component_list[PCid].is_valid  = true;
    this.data[index]['inputerror'].component_list[PCid].error     = null;
    let currentValue            = event.target.value
    const digitdecimalpattern   = /^[0-9]\d*(\.\d{1,2})?$/.test(currentValue);
    if(currentValue=='' || currentValue==null){

    }
    else if(!digitdecimalpattern){
      this.data[index]['inputerror'].component_list[PCid].is_valid  = false;
      this.data[index]['inputerror'].component_list[PCid].error     = this.messageService.validationDisplay('pattern');
    } else if(currentValue.length>10){
      this.data[index]['inputerror'].component_list[PCid].is_valid  = false;
      this.data[index]['inputerror'].component_list[PCid].error     = this.messageService.fieldlengthvalidation('value','10');
    } else {
      if(this.data[index].component_list[PCid])
        this.data[index].component_list[PCid].amount = (event.target.value)?parseFloat(event.target.value):0
      // if(event.target.value != this.data[index].component_list[PCid].original_amount)
        this.data[index].component_list[PCid].override_status = true
    }
  }


  focusOutfield(index:any,PCid:any,event:any){
    if(this.data[index]['inputerror'].component_list[PCid].is_valid)
      this.data[index].component_list[PCid].amount = (event.target.value)?parseFloat(event.target.value):0
    // if(event.target.value != this.data[index].component_list[PCid].original_amount)
      this.data[index].component_list[PCid].override_status = true
  }


  confirmPop(){
    this.confirmMsg = true
    this.confirmMsgText ="Click the Save button, If you want to add the Paycomponent data for the selected employees.";
  }

  getDataList(){
    this.data=[]

    if(this.status == 'Completed' && this.selectedPG?.paycomponent_list?.length > 0){
      this.loader = true
      let validateData = {company:this.company,businessunit:this.buList,department:this.DepList,designation:this.DesignationList,branch:this.BranchList,grade:this.gradeList,month: this.month,year: parseInt(this.year),pay_group: this.selectedPG.id,status: "Completed", save_data:false,data:[]}
      this.os.payComponentOverrideSave(validateData).subscribe((res: any) => {
        this.data = res.data
        this.loader = false
        this.changeDetection.detectChanges();
      });
    } else if(this.status == 'Pending' && this.selectedPG.id)
      this.getEmployeeList()
  }
  showinfo(){
    this.confirmMsg=true
    this.confirmMsgText = "Click the Remove button, If you want to unmap or modify the overrided Paycomponent for the selected employee(s)."
    this.alertToggle = false;
    this.confirmMsg = false;

    this.data.forEach((item:any, value:any) => {
      if(item.checked){
        if(item.salary_status == 'Freeze' || item.salary_status == 'Hold' ||  item.salary_status == 'Publish'){
          this.alertToggle = true;
        } else {
          this.confirmMsg=true
        }
      }
    })

  }
  closeInfo(bool:any){
    this.alertToggle = bool;
  }
  moveToPending(){
    let arrayData:any = []
    this.data.forEach((item:any, value:any) => {
      if(item?.checked == true)
        arrayData.push({employee:item.employee_id})
    });

    let validateData = {month: this.month,year: parseInt(this.year),pay_group: this.selectedPG.id,status: "Pending", save_data:true,data:arrayData}
    this.os.payComponentOverrideSave(validateData).subscribe((res: any) => {
      this.getDataList()
      this.notificationService.handleSuccessNotification("Selected override data removed successfully.","PayComponent Data");
    })
  }
  selectedArray(){
    var delArray:any = [];
      this.data.forEach((item:any, value:any) => {
        if(item?.checked == true && item?.employee_id)
          delArray.push(this.data[value])
      });
      return delArray
  }
  countSelected(){
    var delArray:any = [];
    this.data.forEach((item:any, value:any) => {
      if(item?.checked == true)
        delArray.push(value)
    });
    return delArray.length
  }

  selectedValidPendingData(type:boolean=true){
    var delArray:any = [];
    if(this.status == 'Pending'){
      this.data.forEach((item:any, value:any) => {
        var rowvalid = true
        if(item.inputerror!=undefined){
          if(item.inputerror?.comment != undefined && !item.inputerror?.comment?.is_valid)
              rowvalid = false
          this.selectedPG?.paycomponent_list.forEach((elem: any) => {
            if(item.inputerror?.component_list[elem.id] != undefined && !item.inputerror?.component_list[elem.id]?.is_valid)
              rowvalid = false
          })
        }
        this.data[value].rowvalid = rowvalid
        if(item?.checked == true && item?.error == false && rowvalid){
          if(type)
            delArray.push(item)
          else
            delArray.push(value)

        }
      });
    }
      return delArray
  }
  countSelectedWithoutError(){
    return this.selectedValidPendingData().length
  }
  uploadSaveData(){
    this.confirmMsg = false
    this.isProgressStart = true
    this.iscompleted = false
    this.failedData = []
    this.isDataUpload = 1
    this.successDataCount = 0
    var l = this.countSelected();
    var cLoop = Math.ceil(l/this.chunkLen)
    var p = 100/cLoop
    this.processUpload(0,p);
}
processUpload(k:any,p:any){
  var l = this.countSelected();
  var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
  var saveDataArray:any = [];
  this.percentage +=(p/4);
  this.body = x+' out of '+l+" Paycomponent data uploading"
  var uploadDataSelected =  this.selectedArray()
  for(var i= k*this.chunkLen; i<x; i++){
    var item = uploadDataSelected[i]
    if(item?.checked == true && item?.error == false && item?.rowvalid == true ){
      let cpList:any = []
      this.selectedPG?.paycomponent_list.forEach((elem: any) => {
        item.component_list[elem.id].override_status = true
        cpList.push(item?.component_list[elem.id])
      });
      saveDataArray.push({employee:item.employee_id,comment:item.comment,component_list:cpList})
    } else if(item){
      this.failedData.push(this.createfailedData(item))
    } else {
      this.failedData.push({"Error":"Employee not found"})
    }
  }
  this.os.payComponentOverrideSave( {month: this.month,year: parseInt(this.year),pay_group: this.selectedPG.id,status: "Completed", save_data:true,data:saveDataArray}).subscribe((res: any) => {

    this.successDataCount += res.data.length;

    this.percentage +=((p/4)*3);

    if(x<l)
    this.processUpload(k+1,p);
    else{
      this.percentage = 0;
      this.iscompleted = true
      this.isProgressStart = false
      if(this.failedData.length > 0){
        this.downloadMsg =true

        this.successMsg = this.successDataCount+" out of "+this.countSelected()+" Paycomponent data uploaded successfully "
        this.errorMsg = this.failedData.length+' employee(s) have error data'
      } else {
        this.notificationService.handleSuccessNotification(this.successDataCount+" Paycomponent data uploaded successfully.","Success");
      }
      this.bulkDelete();
    }


    this.changeDetection.detectChanges();
  },
  (error:any)=>{
    this.isProgressStart = false
    this.notificationService.handleErrorNotification('Something went wrong','Error')

    }

  );
}

createfailedData(item:any){
  let cpList:any = {'Employee code':item.employee_code,'Employee name':item.employee_name,'Salary month':item.month,'Salary Year':item.year}
  this.selectedPG?.paycomponent_list.forEach((elem: any) => {
    // cpList.push(item?.component_list[elem.id])
    cpList["Override "+elem.name] = item?.component_list[elem.id]?.amount?item?.component_list[elem.id]?.amount:"-"
  });
  cpList.Comment = item.comment
  cpList.Error = item.error_description
  return cpList
}

  resetFilter(){
    this.searchString=''
    this.filter  = false
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.getDataList();
  }

  closeFilter(){
    this.filter  = false
  }

  submitForm(data:any){
    this.searchString=''
    this.filterOpened = true
    this.filter  = false
    this.buList = data.buList;
    this.DepList = data.DepList;
    this.DesignationList = data.DesignationList;
    this.gradeList = data.gradeList;
    this.BranchList = data.BranchList;
    this.getDataList();
  }
   changeStatus(){
    this.selectedPG=[];this.getPayGroupList();this.getDataList();this.searchString=''
   }
}
