import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-employee-data-inbox',
  templateUrl: './employee-data-inbox.component.html',
  styleUrls: ['./employee-data-inbox.component.scss']
})
export class EmployeeDataInboxComponent implements OnInit {

  @Input() requestitem: any;
  @Input() from:any = 'inbox';


   // View Status
   billLoader                      = false;
   viewstatusPanel                 = false;
   viewStatusData:any              = [];
   viewStatusLevelCnt:any;
   viewStatusmaxLevel:any;
   fromViewStatus                  = '';

  form = this.fb.group({
    read_status: [true, Validators.required],
    star: [false, Validators.required],
    approved_status: [false, Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter();


  modalToggle = false;
  currentDocName = "";
  currentExtension = "";
  allowedExtension = "";
  fileName: any;
  alertToggle = false;
  empalertMsg: any = '';
  loader = true;
  sendReminder:any


  constructor(
    private fb: FormBuilder,
    public inboxservice: InboxService,
    public messageService: MessageService,
    private notificationService: NotificationService,
    private investmentService:InvestmentDeclerationService,
    public activityService:ManageActivityService) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  get f() { return this.form.controls; }

  confirm(status: any) {
    var flag = true;

    if(this.requestitem?.employee_profile?.category=='document'){
      for(let i = 0;i<this.requestitem?.employee_profile?.document_edit_data?.length;i++){
        if(this.requestitem?.employee_profile?.document_edit_data[i].status == 'Pending'){
          this.notificationService.handleErrorNotification("Documents cannot be submitted in pending state.","Pending Documents");
          return;
        }

        if(flag == true && this.requestitem?.employee_profile?.document_edit_data[i].status == 'Approved'){
          status = 'Approved';
          flag = false;
        }
        if(flag == true && this.requestitem?.employee_profile?.document_edit_data[i].status == 'Rejected'){
          status = 'Rejected';
        }
      }
      for(let i = 0;i<this.requestitem?.employee_profile?.document_new_data?.length;i++){

        if(this.requestitem?.employee_profile?.document_new_data[i].status == 'Pending'){
          this.notificationService.handleErrorNotification("Documents cannot be submitted in pending state.","Pending Documents");
          return;
        }
        if(flag == true && this.requestitem?.employee_profile?.document_new_data[i].status == 'Approved'){
          status = 'Approved';
          flag = false;
        }
        if(flag == true && this.requestitem?.employee_profile?.document_new_data[i].status == 'Rejected'){
          status = 'Rejected';
        }
      }
    }

    if (status == 'Approved') {
      this.form.controls['approved_status'].setValue('Approved');
    } else {
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if (this.form['controls'].comment.valid) {
      if(this.from=='behalf'){
      this.activityService.SaveClick = true

        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);
        this.form.get('comment')?.reset('')

      }
      else{
        this.inboxservice.updateInboxItem(this.requestitem.id, this.form.value).subscribe((res) => {
          if (res) {
            this.successEmitter.emit(true);
          }
        })
      }
      }
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.fromViewStatus     = ( res?.request_type == 'Employee Profile' && res?.category == 'document') ? 'employee-inbox' : '';
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
    }
    refreshInbox(event: boolean){
      if(event){
        this.successEmitter.emit(event);
      }
    }
    clearComment(){
      this.form.get('comment')?.reset('')

    }

}
