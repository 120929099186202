import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
// import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MenuTabComponent } from 'src/app/@shared/components/menu-tab/menu-tab.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { OrganizatinStructureComponent } from './organizatin-structure/organizatin-structure.component';
import { CompanyComponent } from './organizatin-structure/company/company.component';
import { BusinessUnitComponent } from './organizatin-structure/business-unit/business-unit.component';
import { DepartmentComponent } from './organizatin-structure/department/department.component';
import { DesignationComponent } from './organizatin-structure/designation/designation.component';
import { GradeComponent } from './organizatin-structure/grade/grade.component';
import { CityComponent } from './organizatin-structure/city/city.component';
import { BranchComponent } from './organizatin-structure/branch/branch.component';
import { RoleComponent } from './role/role/role.component';
import { RoleSetupComponent } from './role/role-setup/role-setup.component';
import { RoleAssignRightsComponent } from './role/role-setup/role-assign-rights/role-assign-rights.component';
import { RoleDefineComponent } from './role/role-setup/role-define/role-define.component';
import { PageAccessComponent } from './page-access/page-access/page-access.component';
import { PageAccessSetupComponent } from './page-access/page-access-setup/page-access-setup.component';
import { PaRightsComponent } from './page-access/page-access-setup/pa-rights/pa-rights.component';
import { PaVisiblityComponent } from './page-access/page-access-setup/pa-visiblity/pa-visiblity.component';
import { PaAssignRightsComponent } from './page-access/page-access-setup/pa-assign-rights/pa-assign-rights.component';
import { RandomColorPipe } from './random-color.pip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DataAccessComponent } from './data-access/data-access/data-access.component';
import { DataAccessSetupComponent } from './data-access/data-access-setup/data-access-setup.component';
import { DaDefineComponent } from './data-access/data-access-setup/da-define/da-define.component';
import { DaRightsComponent } from './data-access/data-access-setup/da-rights/da-rights.component';
import { ApprovalMatrixComponent } from './approval-matrix/approval-matrix/approval-matrix.component';
import { ApprovalMatrixSetupComponent } from './approval-matrix/approval-matrix-setup/approval-matrix-setup.component';
import { AmConfigureFlowComponent } from './approval-matrix/approval-matrix-setup/am-configure-flow/am-configure-flow.component';
import { AmAllocateFlowComponent } from './approval-matrix/approval-matrix-setup/am-allocate-flow/am-allocate-flow.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoaderComponent } from 'src/app/@shared/components/loader/loader.component';
import { DocumentsComponent } from './documents/documents.component';
import { PayComponentComponent } from './pay-component/pay-component/pay-component.component';
import { PayComponentSetupComponent } from './pay-component/pay-component-setup/pay-component-setup.component';
import { PcDefineComponent } from './pay-component/pay-component-setup/pc-define/pc-define.component';
import { PcStatuatoryDetailsComponent } from './pay-component/pay-component-setup/pc-statuatory-details/pc-statuatory-details.component';
import { PcTaxDetailsComponent } from './pay-component/pay-component-setup/pc-tax-details/pc-tax-details.component';
import { FileUploadComponent } from 'src/app/@shared/components/file-upload/file-upload.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
/** Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LY_THEME_GLOBAL_VARIABLES,
  LyHammerGestureConfig
} from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { color } from '@alyle/ui/color';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';
//import { CropperDialog } from './organizatin-structure/company/cropper.dialog';
import { CropperDialog } from '../../@shared/components/cropping/cropper-dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
import { StatutoryComponent } from './statutory/statutory.component';
import { LevelTransformPipe } from './level-transform.pip';
import { TaskOwnerComponent } from './task-owner/task-owner.component';
import { PayGroupComponent } from './pay-group/pay-group/pay-group.component';
import { PayGroupSetupComponent } from './pay-group/pay-group-setup/pay-group-setup.component';
import { PgDefineComponent } from './pay-group/pay-group-setup/pg-define/pg-define.component';
import { PgCreateFormulaComponent } from './pay-group/pay-group-setup/pg-create-formula/pg-create-formula.component';
import { PgMapPayComponent } from './pay-group/pay-group-setup/pg-map-pay/pg-map-pay.component';
import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AttendanceRuleComponent } from './attendance-rule/attendance-rule/attendance-rule.component';
import { AttendanceRuleSetupComponent } from './attendance-rule/attendance-rule-setup/attendance-rule-setup.component';
import { ArShiftmappingComponent } from './attendance-rule/attendance-rule-setup/ar-shiftmapping/ar-shiftmapping.component';
import { ArAttendanceDetailsComponent } from './attendance-rule/attendance-rule-setup/ar-attendance-details/ar-attendance-details.component';
import { ArCompensatoryOffComponent } from './attendance-rule/attendance-rule-setup/ar-compensatory-off/ar-compensatory-off.component';
import { ArLateComingPenaltyComponent } from './attendance-rule/attendance-rule-setup/ar-late-coming-penalty/ar-late-coming-penalty.component';
import { ArOvertimeComponent } from './attendance-rule/attendance-rule-setup/ar-overtime/ar-overtime.component';
import { ArPunchDetailsComponent } from './attendance-rule/attendance-rule-setup/ar-punch-details/ar-punch-details.component';

import { HolidayComponent } from './holiday/holiday.component';
import { HolidayPolicyComponent } from './holiday/holiday-policy/holiday-policy/holiday-policy.component';
import { HolidayPolicySetupComponent } from './holiday/holiday-policy/holiday-policy-setup/holiday-policy-setup.component';
import { HpMappingComponent } from './holiday/holiday-policy/holiday-policy-setup/hp-mapping/hp-mapping.component';
import { HpDetailsComponent } from './holiday/holiday-policy/holiday-policy-setup/hp-details/hp-details.component';
import { HolidayTypeComponent } from './holiday/holiday-type/holiday-type.component';
import { ShiftComponent } from './shift-detail/shift/shift.component';
import { ShiftDetailComponent } from './shift-detail/shift-detail.component';
import { LeaveComponent } from './leave/leave.component';
import { LeaveTypeComponent } from './leave/leave-type/leave-type.component';
import { LeavePolicyComponent } from './leave/leave-policy/leave-policy.component';
import { LeavePolicySetupComponent } from './leave/leave-policy-setup/leave-policy-setup.component';
// import { LpDetailsComponent } from './leave/leave-policy-setup/lp-details/lp-details.component';
// import { LpRequestCriteriaComponent } from './leave/leave-policy-setup/lp-request-criteria/lp-request-criteria.component';
// import { LpCreditDetailsComponent } from './leave/leave-policy-setup/lp-credit-details/lp-credit-details.component';
// import { LpRequestLimitsComponent } from './leave/leave-policy-setup/lp-request-limits/lp-request-limits.component';
// import { LpMappingLeaveTypeComponent } from './leave/leave-policy-setup/lp-mapping-leave-type/lp-mapping-leave-type.component';
// import { LpAssignRightsComponent } from './leave/leave-policy-setup/lp-assign-rights/lp-assign-rights.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IgxTimePickerModule } from 'igniteui-angular';
import { CompanyStatutoryComponent } from './statutory/company-statutory/company-statutory.component';
import { ConfigurationComponent } from './configuration/configuration/configuration.component';
import { ConfigurationSetupComponent } from './configuration/configuration-setup/configuration-setup.component';
import { ConfigurationSelectCompanyComponent } from './configuration/configuration-setup/configuration-select-company/configuration-select-company.component';
import { ConfigurationConfigCompanyComponent } from './configuration/configuration-setup/configuration-config-company/configuration-config-company.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoginPageCustomizationComponent } from './login-page-customization/login-page-customization.component';
import { OrganizationComponent } from './organizatin-structure/organization/organization.component';
import { TaxDeductorComponent } from './tax-deductor/tax-deductor.component';
import { ShiftSetupComponent } from './shift-detail/shift-setup/shift-setup.component';
import { ShiftDetailsComponent } from './shift-detail/shift-setup/shift-details/shift-details.component';
import { ShiftTimingComponent } from './shift-detail/shift-setup/shift-timing/shift-timing.component';
import { ShiftBreakScheduleComponent } from './shift-detail/shift-setup/shift-break-schedule/shift-break-schedule.component';
import { ShiftWeekOffComponent } from './shift-detail/shift-setup/shift-week-off/shift-week-off.component';
import { ShiftRotationSetupComponent } from './shift-detail/shift-rotation-setup/shift-rotation-setup.component';
import { ShiftRotationDetailsComponent } from './shift-detail/shift-rotation-setup/shift-rotation-details/shift-rotation-details.component';
import { ShiftRotationConfigureComponent } from './shift-detail/shift-rotation-setup/shift-rotation-configure/shift-rotation-configure.component';
import { ShiftRotationComponent } from './shift-detail/shift-rotation/shift-rotation.component';
import { WeeklyOffComponent } from './weekly-off/weekly-off/weekly-off.component';
import { WeeklyOffSetupComponent } from './weekly-off/weekly-off-setup/weekly-off-setup.component';
import { WeeklyOffDetailsComponent } from './weekly-off/weekly-off-setup/weekly-off-details/weekly-off-details.component';
import { AttendanceCapturePolicyComponent } from './attendance-capture-policy/attendance-capture-policy/attendance-capture-policy.component';
import { AttendanceCapturePolicySetupComponent } from './attendance-capture-policy/attendance-capture-policy-setup/attendance-capture-policy-setup.component';
import { AttendanceCapturePolicyDetailsComponent } from './attendance-capture-policy/attendance-capture-policy-setup/attendance-capture-policy-details/attendance-capture-policy-details.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { EmailHeaderComponent } from './email-header/email-header.component';
import { SeperationComponent } from './seperation/seperation.component';
import { SeperationPolicyComponent } from './seperation/seperation-policy/seperation-policy.component';
import { SeperationPolicySetupComponent } from './seperation/seperation-policy-setup/seperation-policy-setup.component';
import { ReasonListComponent } from './seperation/reason-list/reason-list.component';
import { SeperationPolicySettingsComponent } from './seperation/seperation-policy-setup/seperation-policy-settings/seperation-policy-settings.component';
import { ArAlertComponent } from './attendance-rule/attendance-rule-setup/ar-alert/ar-alert.component';
// import { LpEligibilityComponent } from './leave/leave-policy-setup/lp-eligibility/lp-eligibility.component';
import { SnapshotComponent } from './permissions/snapshot/snapshot.component';
// import { LeaveTypeSetupComponent } from './leave/leave-type-setup/leave-type-setup.component';
// import { LeaveTypeCreditDetailsComponent } from './leave/leave-type-setup/leave-type-credit-details/leave-type-credit-details.component';
// import { LeaveTypeEligibilityComponent } from './leave/leave-type-setup/leave-type-eligibility/leave-type-eligibility.component';
// import { LeaveTypeRequestCriteriaComponent } from './leave/leave-type-setup/leave-type-request-criteria/leave-type-request-criteria.component';
// import { LeaveTypeRequestLimitsComponent } from './leave/leave-type-setup/leave-type-request-limits/leave-type-request-limits.component';
import { LeavePolicyDetailsComponent } from './leave/leave-policy-setup/leave-policy-details/leave-policy-details.component';
import { LeavePolicyMapLeaveTypeComponent } from './leave/leave-policy-setup/leave-policy-map-leave-type/leave-policy-map-leave-type.component';
import { LeavePolicyConfigureLeaveTypeComponent } from './leave/leave-policy-setup/leave-policy-configure-leave-type/leave-policy-configure-leave-type.component';
import { LeaveCreditDetailsComponent } from './leave/leave-policy-setup/leave-policy-configure-leave-type/leave-credit-details/leave-credit-details.component';
import { LeaveEligibilityComponent } from './leave/leave-policy-setup/leave-policy-configure-leave-type/leave-eligibility/leave-eligibility.component';
import { LeaveRequestCriteriaComponent } from './leave/leave-policy-setup/leave-policy-configure-leave-type/leave-request-criteria/leave-request-criteria.component';
import { LeaveRequesetLimitComponent } from './leave/leave-policy-setup/leave-policy-configure-leave-type/leave-requeset-limit/leave-requeset-limit.component';
import { LeaveTypeDetailsComponent } from './leave/leave-policy-setup/leave-policy-configure-leave-type/leave-type-details/leave-type-details.component';
import { TimeAttendanceSettingsComponent } from './time-attendance-settings/time-attendance-settings.component';
import { TimeAttendanceSettingsSetupComponent } from './time-attendance-settings/time-attendance-settings-setup/time-attendance-settings-setup.component';
import { TasSelectCompaniesComponent } from './time-attendance-settings/time-attendance-settings-setup/tas-select-companies/tas-select-companies.component';
import { TasSetupConfigurationComponent } from './time-attendance-settings/time-attendance-settings-setup/tas-setup-configuration/tas-setup-configuration.component';
import { LeaveYearEndSettingsComponent } from './leave/leave-policy-setup/leave-year-end-settings/leave-year-end-settings.component';
import { TabsPermissionsComponent } from './permissions/tabs-permissions/tabs-permissions.component';
import { FieldsPermissionsComponent } from './permissions/fields-permissions/fields-permissions.component';
import { PaChildrenMenuComponent } from './page-access/page-access-setup/pa-assign-rights/pa-children-menu/pa-children-menu.component';
import { ShortLeaveTypeComponent } from './short-leave/short-leave-policy-setup/short-leave-type/short-leave-type.component';
import { ShortLeavePolicyComponent } from './short-leave/short-leave-policy/short-leave-policy.component';
import { ShortLeavePolicySetupComponent } from './short-leave/short-leave-policy-setup/short-leave-policy-setup.component';
import { ChallanSettingsComponent } from './challan/challan-settings/challan-settings.component';
import { ChallanSettingsSetupComponent } from './challan/challan-settings-setup/challan-settings-setup.component';
import { ChallanSettingsGroupDetailsComponent } from './challan/challan-settings-setup/challan-settings-group-details/challan-settings-group-details.component';
import { ChallanProvidentFundComponent } from './challan/challan-settings/challan-provident-fund/challan-provident-fund.component';
import { ChallanEmployeeStateInsuranceComponent } from './challan/challan-settings/challan-employee-state-insurance/challan-employee-state-insurance.component';
import { AssetPolicyComponent } from './asset-policy/asset-policy.component';
import { AssetCategoryComponent } from './asset-policy/asset-category/asset-category.component';
import { AssetSubCategoryComponent } from './asset-policy/asset-sub-category/asset-sub-category.component';
import { AssetSettingsComponent } from './asset-settings/asset-settings.component';
import { AssetSettingsSetupComponent } from './asset-settings/asset-settings-setup/asset-settings-setup.component';
import { AssetSettingsSelectCompaniesComponent } from './asset-settings/asset-settings-setup/asset-settings-select-companies/asset-settings-select-companies.component';
import { AssetSettingsSetupConfigurationComponent } from './asset-settings/asset-settings-setup/asset-settings-setup-configuration/asset-settings-setup-configuration.component';
import { ExpenseCategoryComponent } from './expense/expense-category/expense-category.component';
import { ExpenseComponent } from './expense/expense.component';
import { ExpensePolicyComponent } from './expense/expense-policy/expense-policy.component';
import { ExpensePolicySetupComponent } from './expense/expense-policy-setup/expense-policy-setup.component';
import { AddExpensePolicyComponent } from './expense/expense-policy-setup/add-expense-policy/add-expense-policy.component';
import { ExpensePolicyMapCategoryComponent } from './expense/expense-policy-setup/expense-policy-map-category/expense-policy-map-category.component';
import { LocationGroupComponent } from './expenses/location-group/location-group.component';
import { LocationGroupSetupComponent } from './expenses/location-group-setup/location-group-setup.component';
import { LocationGroupAddComponent } from './expenses/location-group-setup/location-group-add/location-group-add.component';
import { LocationGroupReviewComponent } from './expenses/location-group-setup/location-group-review/location-group-review.component';
import { AdvancePolicyComponent } from './expense-advance/advance-policy/advance-policy.component';
import { AdvancePolicyAddComponent } from './expense-advance/advance-policy-setup/advance-policy-add/advance-policy-add.component';
import { AdvancePolicyReviewComponent } from './expense-advance/advance-policy-setup/advance-policy-review/advance-policy-review.component';
import { AdvancePolicySetupComponent } from './expense-advance/advance-policy-setup/advance-policy-setup.component';
import { ExpenseSettingsComponent } from './expense-settings/expense-settings/expense-settings.component';
import { ExpenseSettingsSetupComponent } from './expense-settings/expense-settings-setup/expense-settings-setup.component';
import { ExpenseSettingsSelectCompaniesComponent } from './expense-settings/expense-settings-setup/expense-settings-select-companies/expense-settings-select-companies.component';
import { ExpenseSettingsConfigurationComponent } from './expense-settings/expense-settings-setup/expense-settings-configuration/expense-settings-configuration.component';
import { LetterTemplateDetailsComponent } from './letter-template/letter-template-setup/letter-template-details/letter-template-details.component';
import { LetterTemplateEditTemplateComponent } from './letter-template/letter-template-setup/letter-template-edit-template/letter-template-edit-template.component';
import { LetterTemplatePreviewComponent } from './letter-template/letter-template-setup/letter-template-preview/letter-template-preview.component';
import { LetterTemplateSelectTemplateComponent } from './letter-template/letter-template-setup/letter-template-select-template/letter-template-select-template.component';
import { LetterTemplateSetupComponent } from './letter-template/letter-template-setup/letter-template-setup.component';
import { LetterTemplateComponent } from './letter-template/letter-template/letter-template.component';
// import { ConfirmationPolicyApplicabilityComponent } from './confirmation-policy/confirmation-policy-setup/confirmation-policy-applicability/confirmation-policy-applicability.component';
// import { ConfirmationPolicySettingsComponent } from './confirmation-policy/confirmation-policy-setup/confirmation-policy-settings/confirmation-policy-settings.component';
// import { ConfirmationPolicySetupComponent } from './confirmation-policy/confirmation-policy-setup/confirmation-policy-setup.component';
// import { ConfirmationPolicyComponent } from './confirmation-policy/confirmation-policy/confirmation-policy.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NotificationsComponent } from './notifications/notifications.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { ConfirmationPolicyComponent } from './confirmation-policy/confirmation-policy/confirmation-policy.component';
import { ConfirmationPolicySetupComponent } from './confirmation-policy/confirmation-policy-setup/confirmation-policy-setup.component';
import { ConfirmationPolicySettingsComponent } from './confirmation-policy/confirmation-policy-setup/confirmation-policy-settings/confirmation-policy-settings.component';
import { ShiftAllowancePolicyComponent } from './shift-allowance-policy/shift-allowance-policy/shift-allowance-policy.component';
import { ShiftAllowancePolicySetupComponent } from './shift-allowance-policy/shift-allowance-policy-setup/shift-allowance-policy-setup.component';
import { ShiftAllowancePolicyDetailsComponent } from './shift-allowance-policy/shift-allowance-policy-setup/shift-allowance-policy-details/shift-allowance-policy-details.component';
import { ShiftAllowancePolicyConfigureComponent } from './shift-allowance-policy/shift-allowance-policy-setup/shift-allowance-policy-configure/shift-allowance-policy-configure.component';
// import { LocationPolicySetupComponent } from './location-policy/location-policy-setup/location-policy-setup.component';
// import { LocationPolicyComponent } from './location-policy/location-policy/location-policy.component';
// import { LocationPolicyDetailsComponent } from './location-policy/location-policy-setup/location-policy-details/location-policy-details.component';
// import { LocationPolicyApplicabilityComponent } from './location-policy/location-policy-setup/location-policy-applicability/location-policy-applicability.component';

@NgModule({
  declarations: [
    // MenuTabComponent,
    OrganizatinStructureComponent,
    CompanyComponent,
    BusinessUnitComponent,
    DepartmentComponent,
    DesignationComponent,
    GradeComponent,
    CityComponent,
    BranchComponent,
    // SearchbarComponent,
    RoleComponent,
    RoleSetupComponent,
    RoleAssignRightsComponent,
    RoleDefineComponent,
    PageAccessComponent,
    PageAccessSetupComponent,
    PaRightsComponent,
    PaVisiblityComponent,
    PaAssignRightsComponent,
    RandomColorPipe,
    LevelTransformPipe,
    //LoaderComponent,
    DataAccessComponent,
    DataAccessSetupComponent,
    DaDefineComponent,
    DaRightsComponent,
    ApprovalMatrixComponent,
    ApprovalMatrixSetupComponent,
    AmConfigureFlowComponent,
    AmAllocateFlowComponent,
    DocumentsComponent,
    PayComponentComponent,
    PayComponentSetupComponent,
    PcDefineComponent,
    PcStatuatoryDetailsComponent,
    PcTaxDetailsComponent,
    CropperDialog,
    StatutoryComponent,
    TaskOwnerComponent,
    PayGroupComponent,
    PayGroupSetupComponent,
    PgDefineComponent,
    PgCreateFormulaComponent,
    PgMapPayComponent,
    MiscellaneousComponent,

    AttendanceRuleComponent,
    AttendanceRuleSetupComponent,
    ArShiftmappingComponent,
    ArAttendanceDetailsComponent,
    ArCompensatoryOffComponent,
    ArLateComingPenaltyComponent,
    ArOvertimeComponent,
    ArPunchDetailsComponent,
    HolidayComponent,
    HolidayPolicyComponent,
    HolidayPolicySetupComponent,
    HpMappingComponent,
    HpDetailsComponent,
    HolidayTypeComponent,
    ShiftComponent,
    ShiftDetailComponent,
    LeaveComponent,
    LeaveTypeComponent,
    LeavePolicyComponent,
    LeavePolicySetupComponent,
    // LpDetailsComponent,
    // LpRequestCriteriaComponent,
    // LpCreditDetailsComponent,
    // LpRequestLimitsComponent,
    // LpMappingLeaveTypeComponent,
    // LpAssignRightsComponent,
    CompanyStatutoryComponent,
    ConfigurationComponent,
    ConfigurationSetupComponent,
    ConfigurationSelectCompanyComponent,
    ConfigurationConfigCompanyComponent,
    LoginPageCustomizationComponent,
    OrganizationComponent,
    TaxDeductorComponent,
    ShiftSetupComponent,
    ShiftDetailsComponent,
    ShiftTimingComponent,
    ShiftBreakScheduleComponent,
    ShiftWeekOffComponent,
    ShiftRotationSetupComponent,
    ShiftRotationDetailsComponent,
    ShiftRotationConfigureComponent,
    ShiftRotationComponent,
    WeeklyOffComponent,
    WeeklyOffSetupComponent,
    WeeklyOffDetailsComponent,
    AttendanceCapturePolicyComponent,
    AttendanceCapturePolicySetupComponent,
    AttendanceCapturePolicyDetailsComponent,
    PermissionsComponent,
    EmailHeaderComponent,
    SeperationComponent,
    SeperationPolicyComponent,
    SeperationPolicySetupComponent,
    ReasonListComponent,
    SeperationPolicySettingsComponent,
    ArAlertComponent,
    // LpEligibilityComponent,
    SnapshotComponent,
    //  LeaveTypeSetupComponent,
    //  LeaveTypeCreditDetailsComponent,
    //  LeaveTypeEligibilityComponent,
    //  LeaveTypeRequestCriteriaComponent,
    //  LeaveTypeRequestLimitsComponent,
    LeavePolicyDetailsComponent,
    LeavePolicyMapLeaveTypeComponent,
    LeavePolicyConfigureLeaveTypeComponent,
    LeaveTypeDetailsComponent,
    LeaveCreditDetailsComponent,
    LeaveEligibilityComponent,
    LeaveRequestCriteriaComponent,
    LeaveRequesetLimitComponent,
    TimeAttendanceSettingsComponent,
    TimeAttendanceSettingsSetupComponent,
    TasSelectCompaniesComponent,
    TasSetupConfigurationComponent,
    LeaveYearEndSettingsComponent,
    TabsPermissionsComponent,
    FieldsPermissionsComponent,
    // LocationPolicySetupComponent,
    // LocationPolicyComponent,
    // LocationPolicyDetailsComponent,
    // LocationPolicyApplicabilityComponent,
    PaChildrenMenuComponent,
    ShortLeaveTypeComponent,
    ShortLeavePolicyComponent,
    ShortLeavePolicySetupComponent,
    PaChildrenMenuComponent,
    ChallanSettingsComponent,
    ChallanSettingsSetupComponent,
    ChallanSettingsGroupDetailsComponent,
    ChallanProvidentFundComponent,
    ChallanEmployeeStateInsuranceComponent,
    AssetPolicyComponent,
    AssetCategoryComponent,
    AssetSubCategoryComponent,
    AssetSettingsComponent,
    AssetSettingsSetupComponent,
    AssetSettingsSelectCompaniesComponent,
    AssetSettingsSetupConfigurationComponent,
    ExpenseCategoryComponent,
    ExpensePolicyComponent,
    ExpensePolicySetupComponent,
    AddExpensePolicyComponent,
    ExpensePolicyMapCategoryComponent,
    LocationGroupComponent,
    LocationGroupSetupComponent,
    LocationGroupAddComponent,
    LocationGroupReviewComponent,
    AdvancePolicyComponent,
    AdvancePolicyAddComponent,
    AdvancePolicyReviewComponent,
    AdvancePolicySetupComponent,
    ExpenseComponent,
    ExpenseSettingsComponent,
    ExpenseSettingsSetupComponent,
    ExpenseSettingsSelectCompaniesComponent,
    ExpenseSettingsConfigurationComponent,
    LetterTemplateComponent,
    LetterTemplateSetupComponent,
    LetterTemplateDetailsComponent,
    LetterTemplateSelectTemplateComponent,
    LetterTemplateEditTemplateComponent,
    LetterTemplatePreviewComponent,
    ConfirmationPolicyComponent,
    ConfirmationPolicySetupComponent,
    ConfirmationPolicySettingsComponent,
    // ConfirmationPolicyApplicabilityComponent,
    NotificationsComponent,
    QuickLinksComponent,
    ShiftAllowancePolicyComponent,
    ShiftAllowancePolicySetupComponent,
    ShiftAllowancePolicyDetailsComponent,
    ShiftAllowancePolicyConfigureComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    InfiniteScrollModule,
    LayoutModule,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    NgbModule,
    MasterSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    DpDatePickerModule,
    MatDatepickerModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    IgxTimePickerModule,
    PdfViewerModule,
    NgxSummernoteModule
  ]
})
export class SettingsModule { }
