<!-- Card1 -> BU -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card1'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex view-section">
      <div class="logo-img  sq-42 bg-transparent">
        <ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
      </div>
      <div class="overflow-hidden">
        <!-- <h6 class="mb-0 tips">{{bussinessUnit.name}}</h6>
          <span class="tips-helper">{{bussinessUnit.name}}</span>
          <div class="fs-12  tips fw-500 text-light-400">
              {{bussinessUnit.company.company_name}}
          </div>
          <span class="tips-helper ">{{bussinessUnit.company.company_name}}</span> -->
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
    <!-- <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="bussinessUnit.is_active && (permissions.e || permissions.d)">
        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
            ngbDropdownToggle>
            <i class="icon-three-dots-vertical "></i>
        </button>
        <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
            <button *ngIf="permissions.e" ngbDropdownItem (click)="editBuForm(bussinessUnit.id)" >
                <i   class="icon-pencil-square me-1"></i> Edit
            </button>
            <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletebu(bussinessUnit.id)" >
                <i class="icon-trash  me-1"></i> Delete
            </button>
        </div>
      </div> -->
  </div>
  <div class="card-body pt-8 justify-content-between">
    <span class="fs-10 text-uppercase fw-500 "><ngx-skeleton-loader appearance="line"
        [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
  </div>
</div>
<!-- Card2 -> Company, Grade, Department, etc..... -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card2'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex view-section">
      <div class="logo-img sq-42 bg-transparent"> <ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF' }"></ngx-skeleton-loader>
      </div>
      <div class="overflow-hidden">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<!-- Card3 -> Role, Page access, Data access, etc...... -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card3'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex  view-section">
      <div class="logo-img sq-42 bg-transparent"> <ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
      </div>
      <div class="overflow-hidden ">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body py-8 justify-content-between">
    <!-- <div class="d-flex align-items-center overlap-avatar overlap-left"
        container="main-content">
          <div class="avatar-sm sq-32 rounded-circle">
            <span>
              <ngx-skeleton-loader appearance="circle"  [theme]="{height: '1.5rem',width: '1.5rem','background-color': '#EAEDEF', 'border-radius': '64px','margin-right':'0.5rem'}"></ngx-skeleton-loader>
            </span>
          </div>
        </div> -->
    <!-- <span> -->
    <ngx-skeleton-loader appearance="circle"
      [theme]="{height: '2rem',width: '2rem','background-color': '#EAEDEF', 'border-radius': '64px','margin':'0'}"></ngx-skeleton-loader>
    <!-- </span> -->
    <div class="fs-12  fw-500 text-light-400">
      <span class="text-nowrap"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
  </div>
</div>
<!-- Card4 -> Company Statutory -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card4'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex view-section">
      <div class="logo-img  sq-42 bg-transparent"><ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF' }"></ngx-skeleton-loader>
      </div>
      <div class="overflow-hidden me-auto">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body p-16 justify-content-between overflow-hidden ">
    <div class="d-flex  gap-8 fs-12">
      <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
        .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
    <div class="d-flex gap-8 fs-12">
      <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
            .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
  </div>
</div>
<!-- Card5 -> Statutory -->
<div class="card card-custom2 border radius-4 h-100" *ngIf="cardType=='card5'">
  <div class="card-body status  d-flex flex-column align-items-start">
    <h6> <ngx-skeleton-loader appearance="line"
        [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '81px','margin-bottom':'2px'}"></ngx-skeleton-loader>
    </h6>
    <p class="fs-13 text-light-400  mb-auto">
      <ngx-skeleton-loader appearance="line"
        [theme]="{'background-color':'#EAEDEF','height': '0.75rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
    </p>
    <div class="mt-16 h-auto radius-2">
      <!-- <ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '81px','margin-bottom':'2px'}"></ngx-skeleton-loader> -->
    </div>
  </div>
</div>
<!-- Card6 -> Templates -->
<div class="card  card-c2 card-hover" *ngIf="cardType=='card6'">
  <div class="card-body p-12">
    <ngx-skeleton-loader appearance="line"
      [theme]="{'background-color':'#EAEDEF','height': '8.875rem','width': '100%','margin-bottom':'2px'}"></ngx-skeleton-loader>
  </div>
  <div class="card-body indicater1 p-16">
    <div class="fw-500 fs-14 text-trim"> <ngx-skeleton-loader appearance="line"
        [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
    </div>
  </div>
</div>
<!-- Card7 -> Holiday type -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card7'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex view-section">
      <div class="logo-img  sq-42 bg-transparent"><ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF' }"></ngx-skeleton-loader>
      </div>
      <div class="overflow-hidden">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body px-16 pt-16 pb-8">
    <div class="row  row-16 fs-12 w-100">
      <div class="col-lg-6 ">
        <div class=""><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '0.9rem','width': '81px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
        <div class=""><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '0.188rem','width': '20px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-lg-6 overflow-hidden">
        <div class="">
          <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '0.9rem','width': '81px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
        <div class=""><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '0.188rem','width': '20px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Card8 -> Employee -->
<div class="card card-c2 card-hover h-100" *ngIf="cardType=='card8'">
  <div class="card-body p-16 text-center">
    <div class="sq-57 mt-6 mx-auto "><ngx-skeleton-loader appearance="circle"
        [theme]="{ height: '3.563rem',width: '3.563rem','background-color': '#EAEDEF','margin':'0'}"></ngx-skeleton-loader>
    </div>
    <div class="mt-2 mb-8">
      <ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' }"></ngx-skeleton-loader>
    </div>
    <h6 class="fw-500 tips text-trim mb-1 fs-14"><ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '8.75rem' }"></ngx-skeleton-loader></h6>
    <div class="mb-12"><ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '5.0625rem' }"></ngx-skeleton-loader></div>
    <div class="text-light-400 fw-500 fs-10 d-flex flex-column gap-1">
      <div>
        <span class="text-uppercase"><ngx-skeleton-loader appearance="line" *ngIf="loader"
            [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '8.125rem' }"></ngx-skeleton-loader></span>
      </div>
      <div>
        <span class="text-uppercase"><ngx-skeleton-loader appearance="line" *ngIf="loader"
            [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '5.0625rem' }"></ngx-skeleton-loader>
        </span>
      </div>
    </div>
  </div>
</div>
<!-- Card9 -> Salary structure, Salary revision, F & F -->
<div class="card card-c2 px-24 card-hover h-100" *ngIf="cardType=='card9'">
  <div class="card-body py-16 px-0 text-center">
    <div class="sq-57 mt-6 mx-auto "><ngx-skeleton-loader appearance="circle"
        [theme]="{height: '3.563rem',width: '3.563rem','background-color': '#EAEDEF','margin':'0'}"></ngx-skeleton-loader>
    </div>
    <div class="mt-2 mb-8">
      <ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' }"></ngx-skeleton-loader>
    </div>
    <h6 class="fw-500 tips text-trim  fs-14"><ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '8.125rem' }"></ngx-skeleton-loader></h6>
    <div class="mb-0"></div>
    <div class="d-flex mt-1 gap-1 justify-content-center">
      <span class="text-uppercase"><ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '81px' }"></ngx-skeleton-loader></span>
    </div>
  </div>
  <div class="card-body px-0 pt-8 pb-16 d-flex fs-12  align-items-center">
    <div class="me-8">
      <div><ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '81px' }"></ngx-skeleton-loader></div>
    </div>
    <div class="ms-auto text-end">
      <div><ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '81px' }"></ngx-skeleton-loader></div>
    </div>
  </div>
</div>
<!-- Card10 -> Form 16 -->
<div class="card card-c2 px-24 py-8" *ngIf="cardType=='card10'">
  <div class="card-body px-0 py-16">
    <div class="d-flex">
      <p class=" mb-24 fw-500"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '11.25rem','margin-bottom':'2px'}"></ngx-skeleton-loader>
      </p>
      <p class=" fs-12 ms-auto text-accent1"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#eaedef8c','height': '.8125rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
      </p>
    </div>
    <div class="row  row-16 fs-12">
      <div class="col-lg-6 ">
        <div class="text-light-400"><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#eaedef8c','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
        <div class="fw-500 "><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '8.75rem' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-light-400"><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#eaedef8c','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
        <div class="fw-500 "><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '8.75rem' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body px-0 py-16">
    <div class="row row-16  ">
      <div class="col-lg-6 fs-12">
        <div class="text-light-400"><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#eaedef8c','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
        <div class="fw-500 "><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '8.75rem' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ms-auto"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#eaedef8c','height': '2.5rem','width': '18.5rem' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
      </div>
    </div>
  </div>
</div>
<!-- Card11 -> Request -->
<div class="card card-c2 h-100 px-16  card-hover" *ngIf="cardType=='card11'">
  <div class="card-body h-100 pb-8 px-0">
    <span class="fw-500 text-trim-2 w-75"><ngx-skeleton-loader appearance="line"
        [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader></span>
    <div class="d-flex justify-content-between align-items-center">
      <div class="fs-10">
        <span class="text-muted text-uppercase"><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader></span>
      </div>
      <div class=" fs-14 px-2 fw-500">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '27px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>

  </div>
  <!-- test start -->
  <!-- <ng-container *ngIf="req.status == 'Invalid'; else active"> -->
  <div class="card-body d-flex flex-column gap-8  py-16 px-0">
    <div class="d-flex  align-items-center justify-content-between overflow-hidden">
      <div class="text-muted fs-12 me-8 text-nowrap"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
      </div>
      <div class="d-flex  align-items-center overflow-hidden">
        <div class="fs-12 fw-500 "><ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="d-flex  align-items-center justify-content-between ">
      <div class="text-muted fs-12 me-8 text-nowrap"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
      </div>
      <div class="d-flex align-items-center overlap-right text-muted fs-12 fw-500 text-nowrap">
        <ngx-skeleton-loader appearance="circle"
          [theme]="{height: '1.5rem',width: '1.5rem','background-color': '#EAEDEF', 'border-radius': '64px','margin-right':'0.5rem'}"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="d-flex  align-items-center justify-content-between overflow-hidden">
      <div class="text-muted fs-12 me-8 text-nowrap"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
      </div>
      <div class="d-flex  align-items-center overflow-hidden">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="d-flex  align-items-center justify-content-between overflow-hidden">
      <div class="text-muted fs-12 me-8 text-nowrap"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
      </div>
      <div class="d-flex  align-items-center overflow-hidden">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <!-- </ng-container> -->
  <!-- test end -->
</div>
<!-- Card12 -> Employee Directory -->
<div class="card card-c2 card-hover p-16 indicater1" *ngIf="cardType=='card12'">
  <div class="d-flex gap-12">
    <span class=" sq-46 me-8 rounded-circle"><span><ngx-skeleton-loader appearance="circle"
          [theme]="{ height: '2.875rem',width: '2.875rem','background-color': '#EAEDEF','margin':'0'}"></ngx-skeleton-loader></span></span>
    <!-- <div class="avatar-circle sq-57 mt-6 mx-auto fw-500 bg-white avatar-border" >SM</div> -->
    <div class="d-flex flex-column gap-1 overflow-hidden">
      <div class="fw-500 fs-14 text-trim mb-1"><ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.125rem','width': '8.75rem','margin-bottom':'2px'}"></ngx-skeleton-loader>
      </div>
      <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim mb-1">
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '.75rem','width': '6.25rem' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
      <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '.75rem','width': '5.0625rem' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<div class="card card-c5" style="height: 6.3125rem;" *ngIf="cardType=='card13'">
  <div class="card-body flex-center position-relative"><ngx-skeleton-loader appearance="circle"
      [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '3.75rem','background-color': '#eaedef8c','margin':'0','position':'absolute','bottom':'-4px',
      'left':'0'}"></ngx-skeleton-loader>
  </div>
  <div class="card-body p-16">
    <div class="fs-12 text-light-400"> <ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '61px' ,'margin-bottom':'2px'}"></ngx-skeleton-loader>
    </div>
    <div class="fs-14 mb-3"> <ngx-skeleton-loader appearance="line" *ngIf="loader"
        [theme]="{'background-color': '#EAEDEF','height': '1.125rem','width': '10rem' ,'display':'block'}"></ngx-skeleton-loader>
    </div>
    <div class="d-flex  gap-8 mt-8">
      <a class="d-flex align-items-center fs-12 fw-500 text-accent1">
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '4.375rem' ,'display':'block'}"></ngx-skeleton-loader></a>
      <a class="d-flex align-items-center fs-12 fw-500 text-accent1">
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#eaedef8c','height': '0.813rem','width': '1.875rem' ,'display':'block'}"></ngx-skeleton-loader></a>
    </div>
  </div>
</div>
<!-- Card14 -> leave balance -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card14'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex view-section">
      <div class="overflow-hidden me-auto">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
          <div class="d-flex  gap-8 fs-12">
            <span class=""><ngx-skeleton-loader appearance="line" *ngIf=""
              [theme]="{'background-color': '#EAEDEF','heightloader': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader></span>
          </div>
          <div class="d-flex  gap-8 fs-12">
            <span class=""><ngx-skeleton-loader appearance="line" *ngIf=""
              [theme]="{'background-color': '#EAEDEF','heightloader': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader></span>
          </div>
      </div>
    </div>
  </div>
  <div class="card-body p-16 justify-content-between overflow-hidden ">
    <div class="d-flex  gap-8 fs-12">
      <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
        .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
    <div class="d-flex gap-8 fs-12">
      <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
            .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
  </div>
</div>
<!-- Card15 -> My team dash board -> Employees At work, Workforce stats -->
<div class="row row-16" *ngIf="cardType=='card15'">
  <div class="col-12 d-flex">
      <p class="fw-500 mb-0"></p>
  </div>
  <ng-container *ngFor="let item of [1,2,3,4]; let i = index">
  <div class="col">
      <div class="card card-c2 p-16 bg-secondary card-hover">
          <div class="d-flex align-items-center">
            <ngx-skeleton-loader appearance="circle"
            [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
          </div>
      </div>
  </div>
</ng-container>
</div>
<!-- Card16 -> My team dash board -> Team updates -->
<ng-container *ngIf="cardType=='card16'">
<div class="col-6" *ngFor="let item of [1,2]; let i = index">
  <div class="card card-c2 p-16">
      <span class="fs-12 fw-600 text-uppercase"></span>
      <div class="avatar-overlap-left mt-8">
        <ngx-skeleton-loader appearance="circle"
        [theme]="{ 'border-radius': '3rem',height: '3rem',width: '3rem','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
      </div>
  </div>
</div>
</ng-container>

<!-- Year End -->
<div class="card card-custom1 card-hover" *ngIf="cardType=='card17'">
  <div class="card-body p-0 d-flex align-items-start">
    <div class="d-flex view-section">
      <div class="logo-img  sq-42 bg-transparent"><ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF' }"></ngx-skeleton-loader>
      </div>
      <div class="overflow-hidden me-auto">
        <ngx-skeleton-loader appearance="line"
          [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" *ngIf="loader"
          [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body p-16 gap-12 flex-column ">
    <div class="d-flex justify-content-between w-100">
      <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
        .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
        <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
          .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
    <div class="d-flex justify-content-between w-100">
      <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
        .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
        <span class=""><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '
          .9844rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader></span>
    </div>
  </div>
</div>
