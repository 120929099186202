
<div class="container-fluid p-32 form-container"  [formGroup]="formGp">
  <div class="row ">
      <p class="text-uppercase fw-500">LATE COMING PENALTY</p>
  </div>
  <div class="row row-16">
    <div class="col-12 form-row">
      <label for="" class="form-label">Do you want to enable late coming penalty? </label>
      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enable this option if you want to impose penalty for late coming" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
    </div>
    <div class="col-lg-12 form-row mt-0" >
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input formControlName="late_coming_penalty" class="form-check-input" type="radio"  [value]=true  [attr.disabled]="this.detailsForm.controls.keep_time.value!='Strict'? true : null" (click)="setRequired(true,disData);setCycle()">
                Yes
            </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input formControlName="late_coming_penalty" class="form-check-input" type="radio" [value]=false (click)="setRequired(false,disNoData);setDisabled(false)">
                No
            </label>
        </div>
    </div>

     <ng-container *ngIf="f.late_coming_penalty.value == true">
      <div class=" col-lg-6 form-row">
        <label for="" class="form-label required">Grace days given to come late as well as to go early</label>
        <!-- <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the grace days to come late  well as to go early" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span> -->
        <input formControlName="grace_day_come_late_go_early" [ngClass]="{ 'is-invalid': (submitted || f.grace_day_come_late_go_early.touched|| f.grace_day_come_late_go_early.dirty) &&  f.grace_day_come_late_go_early.errors}"   pattern="^(?:[0-9]|1\d|2[0-9]|30)$" type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Days')}}">
        <div *ngIf="(submitted || f.grace_day_come_late_go_early.touched|| f.grace_day_come_late_go_early.dirty) && f.grace_day_come_late_go_early.errors" class="invalid-feedback">
            <div *ngIf="f.grace_day_come_late_go_early.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="f.grace_day_come_late_go_early.errors.pattern && f.grace_day_come_late_go_early.errors.pattern.actualValue>30">{{this.messageService.fieldlengthvalidation('days','30')}}
            </div>
            <div *ngIf="f.grace_day_come_late_go_early.errors.pattern && (f.grace_day_come_late_go_early.errors.pattern.actualValue<=30 || notanumber(f.grace_day_come_late_go_early.errors.pattern.actualValue))">{{this.messageService.validationDisplay('pattern')}}</div>
        </div>
    </div>

    <div class="col-12 form-row">
        <label for="" class="form-label">Late coming and early going deduction is to be done from </label>
    </div>
    <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input formControlName="late_come_go_early_deduction" class="form-check-input" type="radio"  [value]=false (click)="setDisabled(false)">
                Attendance
            </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="In case of Attendance, late coming deduction is done from paid days ie. Paid Days of the month = Paid days as per calendar - Late coming Deduction" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input formControlName="late_come_go_early_deduction" class="form-check-input" type="radio" [value]=true (click)="setDisabled(true)">
                Leave
            </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="In case of Leave, late coming deduction is done from leave balance. You have to define the sequence of leave types from which balance will be deducted" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
    </div>
      <div class="col-12 form-row">
          <label for="" class="form-label required ">Deduction type </label>
      </div>
      <div class="col-lg-12 form-row mt-0">
          <div class="form-check form-check-inline">
              <label class="form-check-label" >
                  <input formControlName="deduction_type"  class="form-check-input" type="radio"  [value]=false>
                  Half day
              </label>
          </div>
          <div class="form-check form-check-inline">
              <label class="form-check-label" >
                  <input formControlName="deduction_type" class="form-check-input" type="radio" [value]=true>
                  Full day
              </label>
          </div>

      </div>
      <ng-container *ngIf="f.late_come_go_early_deduction.value==true" formArrayName="prior_deduct_attendancerule">
        <div class="col">
          <label for="" class="form-label required">Specify the priority to be deducted from the leave balance</label>
        </div>
        <ng-container *ngFor="let data of leave_prio().controls; let i=index;"
        [formGroupName]="i">
        <!-- <div style="width:2rem" *ngIf="i!=0">&nbsp;</div> -->
        <div class="col-12" >
            <div class="align-items-start d-flex flex-nowrap row row-16 {{i>0 ? 'mt-10':''}}">
                <div class="col form-row mt-0">
                  <ng-select appendTo="body"  class="form-ngselect" #ngSelector
                    formControlName="leave_type"
                    placeholder="{{this.messageService.selectplaceholddisplay('leave type')}}"
                    [ngClass]="{ 'is-invalid': (submitted || leave_prio().controls[i].get('leave_type')?.touched || leave_prio().controls[i].get('leave_type')?.dirty) &&   leave_prio().controls[i].get('leave_type')?.errors}"
                    (change)="duplicateLeaveType(i)"
                    >
                      <ng-option *ngFor="let leave of leaveTypes"   [value]="leave.id">{{leave.leave_name}}</ng-option>
                  </ng-select>
                  <div *ngIf="(submitted || leave_prio().controls[i].get('leave_type')?.touched || leave_prio().controls[i].get('leave_type')?.dirty) &&  leave_prio().controls[i].get('leave_type')?.errors" class="invalid-feedback">
                    <div *ngIf=" leave_prio().controls[i].get('leave_type')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf=" leave_prio().controls[i].get('leave_type')?.errors?.duplicate">The selected Leave Type is already selected</div>
                  </div>
                </div>
                <a class="flex-center sq-40 text-danger mt-0" (click)="deletePriority(i)" *ngIf="i!=0">
                    <i class="icon-trash "></i>
                </a>
            </div>
        </div>
        </ng-container>
    </ng-container>
    <div class="link-primary1 fs-14 fw-500" *ngIf="f.late_come_go_early_deduction.value==true" (click)="addLeaves();">
        <i class="icon-plus-square-fill me-8"></i>Add priority
    </div>

     </ng-container>

  </div>
</div>
<footer class="submit-footer">
  <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateForm()">NEXT</button>
  <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateForm()">NEXT</button>
</footer>
