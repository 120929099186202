<form action="" class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16 ">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">Employee</p>
            </div>
            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Search Employees</label>
                <ng-select [readonly]="disabled" [ngClass]="{ 'is-invalid': (f.employee.touched || f.employee.errors?.relievedError) && f.employee.errors}" (change)="ngChange($event)"
                    placeholder="{{this.messageService.selectplaceholddisplay('employees')}}" formControlName="employee"
                    [items]="employeeDropdown" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"
                    class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                            [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="formGp.value.employee.length">
                            <span class="ng-value-label">{{formGp.value.employee.length}} Employee selected.</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex align-items-center">
                            <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                                [ngModelOptions]="{ standalone : true }" />
                            <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                src="{{item.profile_image}}">
                            <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.first_name+'
                                    '+item.last_name,2)}}</span></span>
                            <span class="">{{ (item.name.length>30)? (item.name | slice:0:30)+'..':(item.name) }}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <div *ngIf="(f.employee.touched || f.employee.errors?.relievedError) && f.employee.errors" class="invalid-feedback">
                    <div *ngIf="f.employee.errors.required">Please select atleast one employee.</div>
                    <div *ngIf="f.employee.errors?.relievedError">{{this.messageService.inactiveStatus()}}</div>
                </div>
            </div>
            <div class="col-12">
                <ul class="selected-values " *ngIf="formGp.value.employee.length !=0">
                    <li class="ng-value" [ngClass]="{'ng-value-danger': isInsideItemIdFive(item)}" *ngFor="let item of formGp.value.employee, let m =index">
                        <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item" title="{{insideitem.name}}">
                                <div class="d-inline-flex align-items-center">
                                    <img class=" img-fluid sq-24 rounded-circle"
                                        *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                        src="{{insideitem.profile_image}}">
                                    <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                        class="  sq-24 avatar-circle rounded-circle me-1 {{insideitem.color_code}}">
                                        <span>
                                            {{this.appService.getFirstChar(insideitem.first_name+'
                                            '+insideitem.last_name,2)}}</span>
                                    </span>
                                    <span class=""> {{ (insideitem['name'].length>25)? (insideitem['name'] |
                                        slice:0:25)+'..':(insideitem['name']) }}</span>
                                    <span *ngIf="!insideitem?.active_state_employee" class="lh-1 ms-8"><i class="bi bi-info-circle fs-14 text-danger tooltip-icon" ngbPopover="Employee status : Inactive" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                            </span>
                        </ng-container>
                        <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true"
                            (click)="clear('employee',m)"><i class="icon-close"></i></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" [disabled]="!formGp.valid" (click)="validateConfigureFlow()"
            class="btn btn-primary btn-sm ms-auto">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"
            (click)="validateConfigureFlow()">NEXT</button>
    </footer>
</form>
