import { Component, OnInit, Output,EventEmitter, Input, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { GstnumValidator } from 'src/app/@shared/validators/gstnum.validators';
import { AmountValidator } from 'src/app/@shared/validators/amount.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-confirmed-deductions',
  templateUrl: './confirmed-deductions.component.html',
  styleUrls: ['./confirmed-deductions.component.scss']
})

export class ConfirmedDeductionsComponent implements OnInit {

  @Input()name:any;
  @Input()from:any;
  @Input()empId:any;
  @Input()currency:any;
  @Input()limitData:any;
  @Input()submitted:boolean                             = false;

  @Output() onDeductionValidation                       = new EventEmitter<any>();
  @Output() submitmethod                                = new EventEmitter();

  deduction: boolean                                    = true;
  isChecked: boolean                                    = true;
  issueDropdownPlaceHolder                              = this.messageService.selectplaceholddisplay('issue')
  issues:string                                         = "issues";
  paymentDropdownPlaceHolder                            = this.messageService.selectplaceholddisplay('payment')
  nocash_payment_mode:string                            = "nocash_payment_mode";
  fund_type:string                                      = "fund_type"
  fundDropdownPlaceHolder                               = this.messageService.selectplaceholddisplay('fund')
  isrequired                                            = true;
  experianceValidator1                                  = false;
  amountMsg1:string                                     = '';
  experianceValidator2                                  = false;
  amountMsg2:string                                     = '';
  experianceValidator3                                  = false;
  amountMsg3:string                                     = '';
  experianceValidator5                                  = false;
  amountMsg5:string                                     = '';
  experianceValidator4                                  = false;
  amountMsg4:string                                     = '';

  currentDocId:any                                      = "";
  document80D_loader                                    = false;
  employee_contribution_document_loader                 = false;
  employer_contribution_document_loader                 = false;
  tta_tta_document_loader                               = false;
  interest_education_document_loader                    = false;
  handicapped_dependent_document_loader                 = false;
  medical_expenditure_document_loader                   = false;
  self_suffering_document_loader                        = false;
  transaction_document100_loader                        = false;
  royalty_patent_document_loader                        = false;
  contribution_policalparties_document_loader           = false;
  transaction_80gga_document_loader                     = false;
  interest_home_loan_document80ee_loader                = false;
  health_insurance_selfspouse80d_document_loader        = false;
  health_insurance_parents80d_document_loader           = false;
  health_checkup_selfspouse80d_document_loader          = false;
  health_checkup_parents80d_document_loader             = false;
  medicalbill_senior80d_document_loader                 = false;
  interest_home_loan_document80eea_loader               = false;
  interest_electrical_vehicle_loan_document_loader      = false;
  transaction_document50_loader                         = false;

  document80D: any                                      = "";
  employee_contribution_document: any                   = "";
  employer_contribution_document: any                   = "";
  tta_tta_document: any                                 = "";
  interest_education_document: any                      = "";
  handicapped_dependent_document: any                   = "";
  medical_expenditure_document: any                     = "";
  self_suffering_document: any                          = "";
  transaction_document100: any                          = "";
  royalty_patent_document: any                          = "";
  contribution_policalparties_document: any             = "";
  transaction_80gga_document: any                       = "";
  interest_home_loan_document80ee: any                  = "";
  health_insurance_selfspouse80d_document:any           = "";
  health_insurance_parents80d_document: any             = "";
  health_checkup_selfspouse80d_document: any            = "";
  health_checkup_parents80d_document: any               = "";
  medicalbill_senior80d_document: any                   = "";
  interest_home_loan_document80eea: any                 = "";
  interest_electrical_vehicle_loan_document: any        = "";
  transaction_document50: any                           = "";
  currentYear:number                                    = 0;
  fileType: string                                      = ".png,.jpeg,.jpg,.zip,.docx,.xlsx,.doc,.dotx,.pdf";
  employeeAge:number                                    = 0;
  filesizeError :any                                    = [
                                                            {
                                                                'document80D':false,
                                                                'employee_contribution_document':false,
                                                                'employer_contribution_document':false,
                                                                'tta_tta_document': false,
                                                                'interest_education_document':false,
                                                                'handicapped_dependent_document':false,
                                                                'medical_expenditure_document': false,
                                                                'self_suffering_document':false,
                                                                'transaction_document100':false,
                                                                'royalty_patent_document': false,
                                                                'contribution_policalparties_document':false,
                                                                'transaction_80gga_document':false,
                                                                'interest_home_loan_document80ee': false,

                                                                'health_insurance_selfspouse80d_document': false,
                                                                'health_insurance_parents80d_document': false,
                                                                'health_checkup_selfspouse80d_document': false,
                                                                'health_checkup_parents80d_document': false,
                                                                'medicalbill_senior80d_document': false,
                                                                'interest_home_loan_document80eea': false,
                                                                'interest_electrical_vehicle_loan_document': false,
                                                                'transaction_document50': false,
                                                              }
                                                            ]

  fileError:any                                           = "";
  totalDeclaredAmount:number                              = 0;
  alertShow                                               = 'show';
  empSalary:any                                           = 0;
  donation100DropDownData:any                             = [];
  donation50DropDownData:any                              = [];

  // File Upload
  modalToggle                                             = false;
  allowedExtension                                        = "";
  alertMsg:any                                            = '';
  alertToggle                                             = false;
  fileName                                                = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public messageService : MessageService,
    public fb:FormBuilder,
    public appservice:AppService,
    private datePipe:DatePipe,
    private investmentService:InvestmentDeclerationService,
    private activityService:ManageActivityService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);

    this.currentYear   = this.appservice.getFinancialYear();

    this.getDonation100DropDownData();
    this.getDonation50DropDownData();

    this.formGp.controls.employer_contribution.disable();
    this.formGp.controls.employer_contribution_amount.disable();

    this.document80D                            = window.sessionStorage.getItem('this.document80D');
    this.employee_contribution_document         = window.sessionStorage.getItem('this.employee_contribution_document');
    this.employer_contribution_document         = window.sessionStorage.getItem('this.employer_contribution_document');
    this.tta_tta_document                       = window.sessionStorage.getItem('this.tta_tta_document');
    this.interest_education_document            = window.sessionStorage.getItem('this.interest_education_document');
    this.handicapped_dependent_document         = window.sessionStorage.getItem('this.handicapped_dependent_document');
    this.medical_expenditure_document           = window.sessionStorage.getItem('this.medical_expenditure_document');
    this.self_suffering_document                = window.sessionStorage.getItem('this.self_suffering_document');
    this.transaction_document100                = window.sessionStorage.getItem('this.transaction_document100');
    this.royalty_patent_document                = window.sessionStorage.getItem('this.royalty_patent_document');
    this.contribution_policalparties_document   = window.sessionStorage.getItem('this.contribution_policalparties_document');
    this.transaction_80gga_document             = window.sessionStorage.getItem('this.transaction_80gga_document');
    this.interest_home_loan_document80ee        = window.sessionStorage.getItem('this.interest_home_loan_document80ee');

    this.health_insurance_selfspouse80d_document = window.sessionStorage.getItem('this.health_insurance_selfspouse80d_document');
    this.health_insurance_parents80d_document    = window.sessionStorage.getItem('this.health_insurance_parents80d_document');
    this.health_checkup_selfspouse80d_document   = window.sessionStorage.getItem('this.health_checkup_selfspouse80d_document');
    this.health_checkup_parents80d_document      = window.sessionStorage.getItem('this.health_checkup_parents80d_document');

    this.medicalbill_senior80d_document          = window.sessionStorage.getItem('this.medicalbill_senior80d_document');
    this.interest_home_loan_document80eea        = window.sessionStorage.getItem('this.interest_home_loan_document80eea');
    this.interest_electrical_vehicle_loan_document = window.sessionStorage.getItem('this.interest_electrical_vehicle_loan_document');
    this.transaction_document50                    = window.sessionStorage.getItem('this.transaction_document50');

    this.setValue('employer_contribution','employer_contribution_amount');
    if(this.from=='behalf'){
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
        this.setFormValue(res)
      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
          this.setFormValue(res);
      });
    }
  }
  setFormValue(res:any){
    if(res.count_info.hasOwnProperty('assigned_salary_data')){
      let npsPercentage = Number(this.limitData.nps_employer_percentage);
        if((res.count_info.proposed_regime == null && res.count_info.assigned_salary_data.tax_regime == true) || (res.count_info.proposed_regime == 'new')){
            npsPercentage = Number(this.limitData.newregima_nps_employer_percentage);
        }      
      this.empSalary    = res.count_info.assigned_salary_data.salary;
      this.empSalary    = Number(this.empSalary)*(npsPercentage/100);
      this.empSalary    = parseFloat(this.empSalary).toFixed(2);
      if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){
        // if(res.count_info.assigned_salary_data['80Dmedical'] != 0){
        //   this.formGp.get('premium80D')?.setValue(true);
        //   this.formGp.get('amount80D')?.setValue(res.count_info.assigned_salary_data['80Dmedical']);
        //   this.formGp.controls.premium80D.disable();
        //   this.formGp.controls.amount80D.disable();
        // }
          
          let nps_amount_total = Number(res.count_info.assigned_salary_data.nps_amount + res.count_info.assigned_salary_data.prev_employer_nps_deduction)
          if(nps_amount_total>0){
            // condition added for previous employer nps scenario
            this.formGp.get('employer_contribution')?.setValue(true);
          }else{
        this.formGp.get('employer_contribution')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
        this.formGp.get('employer_contribution_amount')?.setValue(res.count_info.assigned_salary_data.nps_amount);
          }
          this.formGp.get('employer_contribution_amount')?.setValue(nps_amount_total);
        }
    } if(res.count_info.hasOwnProperty('employee_data')){
      this.employeeAge = res.count_info.employee_data.age;
    }
  }

  @Input()formGp : FormGroup = this.fb.group({

    employee_additional_contribution    : [''],
    employee_contribution_amount        : [''],
    employee_contribution_document      : [''],
    employer_contribution               : [''],
    employer_contribution_amount        : [''],
    employer_contribution_document      : [''],
    tta_ttb                             : [''],
    tta_ttb_amount                      : [''],
    tta_tta_document                    : [''],
    interest_education_loan             : [''],
    interest_education_amount           : [''],
    interest_education_document         : [''],
    interest_home_loan                  : [''],
    homeloan_option1                    : [''],
    homeloan_option2                    : [''],
    homeloan_option3                    : [''],
    homeloan_option4                    : [''],
    interest_home_loan_amount           :  [''],
    interest_home_loan_document         : [''],
    medical_treatment_handicapped       : [''],
    disabled_severely                   : [''],
    handicapped_dependent_amount        : [''],
    handicapped_dependent_document      : [''],
    medical_expenditure                 : [''],
    year_60_more                        : [''],
    medical_expenditure_amount          : [''],
    medical_expenditure_document        : [''],
    issue                               : [''],
    self_suffering_disability           : [''],
    self_disabled_severely              : [''],
    self_suffering_amount               : [''],
    self_suffering_document             : [''],
    donate_social_cause                 : [''],
    donate_social_cause_amount          : [''],
    cash_other                          : [''],
    donate_80g                          : [],
    fund_type                           : [''],
    transaction_document                : [''],
    royalty_patent                      : [''],
    royalty_patent_amount               : [''],
    royalty_patent_document             : [''],
    contribution_individual_policalparties  : [''],
    contribution_policalparties_amount      : [''],
    contribution_policalparties_document    : [''],
    gga80                                   : [''],
    cash_other_80gga                        : [''],
    deduction_80gga                         : [],
    total_amount                            : [''],
    transaction_80gga_document              : [''],


    health_insurance_selfspouse80d          : [''],
    health_insurance_selfspouse80d_age      : [''],
    health_insurance_selfspouse80d_amount   : [''],
    health_insurance_selfspouse80d_document : [''],
    health_insurance_parents80d             : [''],
    health_insurance_parents80d_age         : [''],
    health_insurance_parents80d_amount      : [''],
    health_insurance_parents80d_document    : [''],
    health_checkup_selfspouse80d            : [''],
    health_checkup_selfspouse80d_amount     : [''],
    health_checkup_selfspouse80d_document   : [''],
    health_checkup_parents80d               : [''],
    health_checkup_parents80d_amount        : [''],
    health_checkup_parents80d_document      : [''],
    medicalbill_senior80d                   : [''],
    medicalbill_senior80d_amount            : [''],
    medicalbill_senior80d_document          : [''],
    interest_home_loan_80eea                   : [''],
    homeloan80eea_option1                     : [''],
    homeloan80eea_option2                     : [''],
    homeloan80eea_option3                     : [''],
    interest_home_loan_amount80eea            : [''],
    interest_home_loan_document80eea          : [''],
    interest_electrical_vehicle_loan_80eeb    : [''],
    interest_electrical_vehicle_loan_amount   : [''],
    interest_electrical_vehicle_loan_document : [''],
    interest_home_loan_80ee                   : [''],
    homeloan80ee_option1                      : [''],
    homeloan80ee_option2                      : [''],
    homeloan80ee_option3                      : [''],
    homeloan80ee_option4                      : [''],
    interest_home_loan_amount80ee             : [''],
    interest_home_loan_document80ee           : [''],
    donate_social_cause100                    : [''],
    donate_social_cause100_amount             : [''],
    cash_other100                             : [''],
    fund_type100                              : [''],
    transaction_document100                   : [''],
    donate_social_cause_50                    : [''],
    donate_social_cause50_amount              : [''],
    cash_other50                              : [''],
    fund_type50                               : [''],
    transaction_document50                    : [''],
    donate_80g100                             : [],
    donate_80g50                              : [],
  });

  /********* FILE UPLOAD FUNCTIONS ****************/
  getvalidsize(event:any){
    this.fileError = event;
    if(this.currentDocId == 1){
      this.errorMsgGenerator('document80D');
      this.document80D_loader = false;
    }
    if(this.currentDocId == 2){
      this.errorMsgGenerator('employee_contribution_document');
      this.employee_contribution_document_loader = false;
    }
    if(this.currentDocId == 3){
      this.errorMsgGenerator('employer_contribution_document');
      this.employer_contribution_document_loader = false;
    }
    if(this.currentDocId == 4){
      this.errorMsgGenerator('tta_tta_document');
      this.tta_tta_document_loader = false;
    }
    if(this.currentDocId == 5){
      this.errorMsgGenerator('interest_education_document');
      this.interest_education_document_loader = false;
    }
    if(this.currentDocId == 6){
      this.errorMsgGenerator('handicapped_dependent_document');
      this.handicapped_dependent_document_loader = false;
    }
    if(this.currentDocId == 7){
      this.errorMsgGenerator('medical_expenditure_document');
      this.medical_expenditure_document_loader = false;
    }
    if(this.currentDocId == 8){
      this.errorMsgGenerator('self_suffering_document');
      this.self_suffering_document_loader = false;
    }
    if(this.currentDocId == 9){
      this.errorMsgGenerator('transaction_document100');
      this.transaction_document100_loader = false;
    }
    if(this.currentDocId == 10){
      this.errorMsgGenerator('royalty_patent_document');
      this.royalty_patent_document_loader = false;
    }
    if(this.currentDocId == 11){
      this.errorMsgGenerator('contribution_policalparties_document');
      this.contribution_policalparties_document_loader = false;
    }
    if(this.currentDocId == 12){
      this.errorMsgGenerator('transaction_80gga_document');
      this.transaction_80gga_document_loader = false;
    }
    if(this.currentDocId == 13){
      this.errorMsgGenerator('interest_home_loan_document80ee');
      this.interest_home_loan_document80ee_loader = false;
    }

    if(this.currentDocId == 14){
      this.errorMsgGenerator('health_insurance_selfspouse80d_document');
      this.health_insurance_selfspouse80d_document_loader = false;
    }

    if(this.currentDocId == 15){
      this.errorMsgGenerator('health_insurance_parents80d_document');
      this.health_insurance_parents80d_document_loader = false;
    }
    if(this.currentDocId == 16){
      this.errorMsgGenerator('health_checkup_selfspouse80d_document');
      this.health_checkup_selfspouse80d_document_loader = false;
    }
    if(this.currentDocId == 17){
      this.errorMsgGenerator('health_checkup_parents80d_document');
      this.health_checkup_parents80d_document_loader = false;
    }

    if(this.currentDocId == 18){
      this.errorMsgGenerator('medicalbill_senior80d_document');
      this.medicalbill_senior80d_document_loader = false;
    }
    if(this.currentDocId == 19){
      this.errorMsgGenerator('interest_home_loan_document80eea');
      this.interest_home_loan_document80eea_loader = false;
    }
    if(this.currentDocId == 20){
      this.errorMsgGenerator('interest_electrical_vehicle_loan_document');
      this.interest_electrical_vehicle_loan_document_loader = false;
    }
    if(this.currentDocId == 21){
      this.errorMsgGenerator('transaction_document50');
      this.transaction_document50_loader = false;
    }

  }

  getURL(event: any) {
    this.fileError = "";

    if(this.currentDocId == 1){

      this.removeErrorMsg('document80D')
      this.formGp.get('document80D')?.setValue(event);
      this.document80D_loader = false;
    }
    if(this.currentDocId == 2){

      this.removeErrorMsg('employee_contribution_document')
      this.formGp.get('employee_contribution_document')?.setValue(event);
      this.employee_contribution_document_loader = false;
    }
    if(this.currentDocId == 3){

      this.removeErrorMsg('employer_contribution_document')
      this.formGp.get('employer_contribution_document')?.setValue(event);
      this.employer_contribution_document_loader = false;
    }
    if(this.currentDocId == 4){

      this.removeErrorMsg('tta_tta_document')
      this.formGp.get('tta_tta_document')?.setValue(event);
      this.tta_tta_document_loader = false;
    }
    if(this.currentDocId == 5){

      this.removeErrorMsg('interest_education_document')
      this.formGp.get('interest_education_document')?.setValue(event);
      this.interest_education_document_loader = false;
    }
    if(this.currentDocId == 6){

      this.removeErrorMsg('handicapped_dependent_document')
      this.formGp.get('handicapped_dependent_document')?.setValue(event);
      this.handicapped_dependent_document_loader = false;
    }
    if(this.currentDocId == 7){

      this.removeErrorMsg('medical_expenditure_document')
      this.formGp.get('medical_expenditure_document')?.setValue(event);
      this.medical_expenditure_document_loader = false;
    }
    if(this.currentDocId == 8){

      this.removeErrorMsg('self_suffering_document')
      this.formGp.get('self_suffering_document')?.setValue(event);
      this.self_suffering_document_loader = false;
    }
    if(this.currentDocId == 9){

      this.removeErrorMsg('transaction_document100')
      this.formGp.get('transaction_document100')?.setValue(event);
      this.transaction_document100_loader = false;
    }
    if(this.currentDocId == 10){

      this.removeErrorMsg('royalty_patent_document')
      this.formGp.get('royalty_patent_document')?.setValue(event);
      this.royalty_patent_document_loader = false;
    }
    if(this.currentDocId == 11){

      this.removeErrorMsg('contribution_policalparties_document')
      this.formGp.get('contribution_policalparties_document')?.setValue(event);
      this.contribution_policalparties_document_loader = false;
    }
    if(this.currentDocId == 12){

      this.removeErrorMsg('transaction_80gga_document')
      this.formGp.get('transaction_80gga_document')?.setValue(event);
      this.transaction_80gga_document_loader = false;
    }
    if(this.currentDocId == 13){

      this.removeErrorMsg('interest_home_loan_document80ee')
      this.formGp.get('interest_home_loan_document80ee')?.setValue(event);
      this.interest_home_loan_document80ee_loader = false;
    }


    if(this.currentDocId == 14){

      this.removeErrorMsg('health_insurance_selfspouse80d_document')
      this.formGp.get('health_insurance_selfspouse80d_document')?.setValue(event);
      this.health_insurance_selfspouse80d_document_loader = false;
    }
    if(this.currentDocId == 15){

      this.removeErrorMsg('health_insurance_parents80d_document')
      this.formGp.get('health_insurance_parents80d_document')?.setValue(event);
      this.health_insurance_parents80d_document_loader = false;
    }
    if(this.currentDocId == 16){

      this.removeErrorMsg('health_checkup_selfspouse80d_document')
      this.formGp.get('health_checkup_selfspouse80d_document')?.setValue(event);
      this.health_checkup_selfspouse80d_document_loader = false;
    }
    if(this.currentDocId == 17){

      this.removeErrorMsg('health_checkup_parents80d_document')
      this.formGp.get('health_checkup_parents80d_document')?.setValue(event);
      this.health_checkup_parents80d_document_loader = false;
    }

    if(this.currentDocId == 18){

      this.removeErrorMsg('medicalbill_senior80d_document')
      this.formGp.get('medicalbill_senior80d_document')?.setValue(event);
      this.medicalbill_senior80d_document_loader = false;
    }
    if(this.currentDocId == 19){

      this.removeErrorMsg('interest_home_loan_document80eea')
      this.formGp.get('interest_home_loan_document80eea')?.setValue(event);
      this.interest_home_loan_document80eea_loader = false;
    }
    if(this.currentDocId == 20){

      this.removeErrorMsg('interest_electrical_vehicle_loan_document')
      this.formGp.get('interest_electrical_vehicle_loan_document')?.setValue(event);
      this.interest_electrical_vehicle_loan_document_loader = false;
    }
    if(this.currentDocId == 21){

      this.removeErrorMsg('transaction_document50')
      this.formGp.get('transaction_document50')?.setValue(event);
      this.transaction_document50_loader = false;
    }
  }

  deleteDoc(id: any) {
    if(id == 1)
      this.formGp.get('document80D')?.setValue('');
    if(id == 2)
      this.formGp.get('employee_contribution_document')?.setValue('');
    if(id == 3)
      this.formGp.get('employer_contribution_document')?.setValue('');
    if(id == 4)
      this.formGp.get('tta_tta_document')?.setValue('');
    if(id == 5)
      this.formGp.get('interest_education_document')?.setValue('');
    if(id == 6)
      this.formGp.get('handicapped_dependent_document')?.setValue('');
    if(id == 7)
      this.formGp.get('medical_expenditure_document')?.setValue('');
    if(id == 8)
      this.formGp.get('self_suffering_document')?.setValue('');
    if(id == 9)
      this.formGp.get('transaction_document100')?.setValue('');
    if(id == 10)
      this.formGp.get('royalty_patent_document')?.setValue('');
    if(id == 11)
      this.formGp.get('contribution_policalparties_document')?.setValue('');
    if(id == 12)
      this.formGp.get('transaction_80gga_document')?.setValue('');
    if(id == 13)
      this.formGp.get('interest_home_loan_document80ee')?.setValue('');

    if(id == 14)
      this.formGp.get('health_insurance_selfspouse80d_document')?.setValue('');
    if(id == 15)
      this.formGp.get('health_insurance_parents80d_document')?.setValue('');
    if(id == 16)
      this.formGp.get('health_checkup_selfspouse80d_document')?.setValue('');
    if(id == 17)
      this.formGp.get('health_checkup_parents80d_document')?.setValue('');
    if(id == 18)
      this.formGp.get('medicalbill_senior80d_document')?.setValue('');
    if(id == 19)
      this.formGp.get('interest_home_loan_document80eea')?.setValue('');
    if(id == 20)
      this.formGp.get('interest_electrical_vehicle_loan_document')?.setValue('');
    if(id == 21)
      this.formGp.get('transaction_document50')?.setValue('');
  }

  currentId(id:any){
    this.currentDocId = id;
  }

  getloader(event:any){
    if(this.currentDocId == 1)
      this.document80D_loader = event;
    if(this.currentDocId == 2)
      this.employee_contribution_document_loader = event;
    if(this.currentDocId == 3)
      this.employer_contribution_document_loader = event;
    if(this.currentDocId == 4)
      this.tta_tta_document_loader = event;
    if(this.currentDocId == 5)
      this.interest_education_document_loader = event;
    if(this.currentDocId == 6)
      this.handicapped_dependent_document_loader = event;
    if(this.currentDocId == 7)
      this.medical_expenditure_document_loader = event;
      if(this.currentDocId == 8)
      this.self_suffering_document_loader = event;
    if(this.currentDocId == 9)
      this.transaction_document100_loader = event;
    if(this.currentDocId == 10)
      this.royalty_patent_document_loader = event;
    if(this.currentDocId == 11)
      this.contribution_policalparties_document_loader = event;
    if(this.currentDocId == 12)
      this.transaction_80gga_document_loader = event;
    if(this.currentDocId == 13)
      this.interest_home_loan_document80ee_loader = event;

    if(this.currentDocId == 14)
      this.health_insurance_selfspouse80d_document_loader = event;
    if(this.currentDocId == 15)
      this.health_insurance_parents80d_document_loader = event;
    if(this.currentDocId == 16)
      this.health_checkup_selfspouse80d_document_loader = event;
    if(this.currentDocId == 17)
      this.health_checkup_parents80d_document_loader = event;
    if(this.currentDocId == 18)
      this.medicalbill_senior80d_document_loader = event;
    if(this.currentDocId == 19)
      this.interest_home_loan_document80eea_loader = event;
    if(this.currentDocId == 20)
      this.interest_electrical_vehicle_loan_document_loader = event;
    if(this.currentDocId == 21)
      this.transaction_document50_loader = event;
  }

  getFilename(event:any){
    if(this.currentDocId == 1){
      this.document80D = event.name;
      window.sessionStorage.setItem('this.document80D',event.name );}
    if(this.currentDocId == 2){
      this.employee_contribution_document = event.name;
      window.sessionStorage.setItem('this.employee_contribution_document',event.name );}
    if(this.currentDocId == 3){
      this.employer_contribution_document = event.name;
      window.sessionStorage.setItem('this.employer_contribution_document',event.name );}
    if(this.currentDocId == 4){
      this.tta_tta_document = event.name;
      window.sessionStorage.setItem('this.tta_tta_document',event.name );}
    if(this.currentDocId == 5){
      this.interest_education_document = event.name;
      window.sessionStorage.setItem('this.interest_education_document',event.name );}
    if(this.currentDocId == 6){
      this.handicapped_dependent_document = event.name;
      window.sessionStorage.setItem('this.handicapped_dependent_document',event.name );}
    if(this.currentDocId == 7){
      this.medical_expenditure_document = event.name;
      window.sessionStorage.setItem('this.medical_expenditure_document',event.name );}
    if(this.currentDocId == 8){
      this.self_suffering_document = event.name;
      window.sessionStorage.setItem('this.self_suffering_document',event.name );}
    if(this.currentDocId == 9){
      this.transaction_document100 = event.name;
      window.sessionStorage.setItem('this.transaction_document100',event.name );}
    if(this.currentDocId == 10){
      this.royalty_patent_document = event.name;
      window.sessionStorage.setItem('this.royalty_patent_document',event.name );}
    if(this.currentDocId == 11){
      this.contribution_policalparties_document = event.name;
      window.sessionStorage.setItem('this.contribution_policalparties_document',event.name );}
    if(this.currentDocId == 12){
      this.transaction_80gga_document = event.name;
      window.sessionStorage.setItem('this.transaction_80gga_document',event.name );}
    if(this.currentDocId == 13){
      this.interest_home_loan_document80ee = event.name;
      window.sessionStorage.setItem('this.interest_home_loan_document80ee',event.name );}

    if(this.currentDocId == 14){
      this.health_insurance_selfspouse80d_document = event.name;
      window.sessionStorage.setItem('this.health_insurance_selfspouse80d_document',event.name );}
    if(this.currentDocId == 15){
      this.health_insurance_parents80d_document = event.name;
      window.sessionStorage.setItem('this.health_insurance_parents80d_document',event.name );}
    if(this.currentDocId == 16){
      this.health_checkup_selfspouse80d_document = event.name;
      window.sessionStorage.setItem('this.health_checkup_selfspouse80d_document',event.name );}
    if(this.currentDocId == 17){
      this.health_checkup_parents80d_document = event.name;
      window.sessionStorage.setItem('this.health_checkup_parents80d_document',event.name );}
    if(this.currentDocId == 18){
      this.medicalbill_senior80d_document = event.name;
      window.sessionStorage.setItem('this.medicalbill_senior80d_document',event.name );}
    if(this.currentDocId == 19){
      this.interest_home_loan_document80eea = event.name;
      window.sessionStorage.setItem('this.interest_home_loan_document80eea',event.name );}
    if(this.currentDocId == 20){
      this.interest_electrical_vehicle_loan_document = event.name;
      window.sessionStorage.setItem('this.interest_electrical_vehicle_loan_document',event.name );}
    if(this.currentDocId == 21){
      this.transaction_document50 = event.name;
      window.sessionStorage.setItem('this.transaction_document50',event.name );}
  }

  get f() {
    return this.formGp.controls;
  }

 /********* RADIO BUTTON CHANGE FUNCTION ****************/
 radioBtnChange(e:any,check:any, amount: any,doc:any){
    this.formGp.get(amount)?.setValue('');
    this.amountMsg1 = '';
    this.amountMsg2 = '';
    this.amountMsg3 = '';
    this.amountMsg4 = '';
    this.amountMsg5 = '';

     // New start
    if(amount == 'donate_social_cause100_amount' && e.target.id == 'deduction1'){
      this.removeAllDonate_80gs100();
      this.formGp.get('fund_type100')?.setValue(null);
      this.formGp.get('fund_type100')?.clearValidators();
      this.formGp.get('fund_type100')?.updateValueAndValidity();
    }
    if(amount == 'donate_social_cause100_amount' && e.target.id == 'deduction2'){
      this.addDonate_80g100();
    }
    if(amount == 'donate_social_cause50_amount' && e.target.id == 'deduction1'){
        this.removeAllDonate_80gs50();
        this.formGp.get('fund_type50')?.setValue(null);
        this.formGp.get('fund_type50')?.clearValidators();
        this.formGp.get('fund_type50')?.updateValueAndValidity();
      }
      if(amount == 'donate_social_cause50_amount' && e.target.id == 'deduction2'){
        this.addDonate_80g50();
      }
    // New end
    this.onDeductionValidation.emit({check:true,checkCn:check,amountCn:amount,docCn:doc});
  }

 /********* VALIDATION FOR 80G FUNCTION ****************/
 validateDeductionAmt(){
    // if((this.formGp.value.cash_other == true) && (this.formGp.value.donate_social_cause_amount > 2000)){
    //   this.experianceValidator5 = true;
    //   this.amountMsg5 = this.messageService.fieldlengthvalidation('amount','2,000');;
    // }else{
    //   this.experianceValidator5 = false;
    //   this.amountMsg5 = '';
    // }
    // return this.experianceValidator5;
  }
  /********* VALIDATION FOR 80U FUNCTION ****************/
  validSelfsuffering() {
  // if((this.formGp.value.self_disabled_severely == true) && (this.formGp.value.self_suffering_amount > 75000)){
  //     this.experianceValidator3 = true;
  //     this.amountMsg3 = this.messageService.fieldlengthvalidation('amount','75,000');;
  //   }else if((this.formGp.value.self_disabled_severely == false) && (this.formGp.value.self_suffering_amount > 125000)){
  //     this.experianceValidator3 = true;
  //     this.amountMsg3 = this.messageService.fieldlengthvalidation('amount','1,25,000');;
  //   }else{
  //     this.experianceValidator3 = false;
  //     this.amountMsg3 = '';
  //   }
  //   return this.experianceValidator1;
  }

  /********* VALIDATION FOR 80 DD FUNCTION ****************/
  validHandicappedAmt(){
    // this.experianceValidator1 = false;
    // this.amountMsg1 = '';
    // if((this.formGp.value.disabled_severely == true) && (this.formGp.value.handicapped_dependent_amount > 75000) ){
    //   this.experianceValidator1 = true;
    //   this.amountMsg1 = this.messageService.fieldlengthvalidation('amount','75,000');;
    // }else if((this.formGp.value.disabled_severely == false) && (this.formGp.value.handicapped_dependent_amount > 125000)){
    //   this.experianceValidator1 = true;
    //   this.amountMsg1 = this.messageService.fieldlengthvalidation('amount','1,25,000');;
    // }else{
    //   this.experianceValidator1 = false;
    //   this.amountMsg1 = '';
    // }
    // return this.experianceValidator1;
  }

  /********* VALIDATION FOR 80DDB FUNCTION ****************/
  validExpenditure(){
    // this.experianceValidator2 = false;
    // this.amountMsg2 = '';
    // if((this.formGp.value.year_60_more == true) && (this.formGp.value.medical_expenditure_amount > 40000) ){
    //   this.experianceValidator2 = true;
    //   this.amountMsg2 = this.messageService.fieldlengthvalidation('amount','40,000');;
    // }else if((this.formGp.value.year_60_more == false) && (this.formGp.value.medical_expenditure_amount > 100000) ){
    //   this.experianceValidator2 = true;
    //   this.amountMsg2 = this.messageService.fieldlengthvalidation('amount','1,00,000');
    // }else{
    //   this.experianceValidator2 = false;
    //   this.amountMsg2 = '';
    // }
    // return this.experianceValidator2;
  }

  /********* VALIDATION FOR 80GGA FUNCTION ****************/
  validDonation() {
    // if((this.formGp.value.cash_other_80gga == true) && (this.formGp.value.total_amount > 10000)){
    //     this.experianceValidator4 = true;
    //     this.amountMsg4 = this.messageService.fieldlengthvalidation('amount','10,000');;
    //   }else{
    //     this.experianceValidator4 = false;
    //     this.amountMsg4 = '';
    //   }
    //   return this.experianceValidator4;
  }

  /********* CHECKBOX VALIDATION FUNCTION ****************/
  checkBoxFunctionTest(e:any, amountName:any , docName:any,radioName: any, dropDown:any,loaderName:any) {
    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    var docControlName    = docName;
    if(e.target.checked){

        this.formGp.get(checkControlName)?.setValue(true);
        this.onDeductionValidation.emit({check:true,checkCn:checkControlName,amountCn:amountControlName,docCn:docControlName});

        if(checkControlName == 'gga80' && this.formGp.value.deduction_80gga.length == 0){
          this.addDeduction_80gga();
        }

        if(checkControlName == 'donate_social_cause100' && this.formGp.value.donate_80g100.length == 0){
          this.addDonate_80g100();
        }
        if(checkControlName == 'donate_social_cause_50' && this.formGp.value.donate_80g50.length == 0){
          this.addDonate_80g50();
        }

    }else{

    if(checkControlName == 'donate_social_cause100'){
      this.removeAllDonate_80gs100();
      this.formGp.get('fund_type100')?.clearValidators();
      this.formGp.get('fund_type100')?.updateValueAndValidity();
    }
    if(checkControlName == 'donate_social_cause_50'){
      this.removeAllDonate_80gs50();
      this.formGp.get('fund_type50')?.clearValidators();
      this.formGp.get('fund_type50')?.updateValueAndValidity();
    }
      if(checkControlName == 'gga80')
        this.removeAllDeduction_80ggas();

      if(checkControlName == 'interest_home_loan_80ee'){
        this.formGp.get('homeloan80ee_option1')?.setValue(false);
        this.formGp.get('homeloan80ee_option2')?.setValue(false);
        this.formGp.get('homeloan80ee_option3')?.setValue(false);
        this.formGp.get('homeloan80ee_option4')?.setValue(false);
      }
      if(checkControlName == 'interest_home_loan_80eea'){
        this.formGp.get('homeloan80eea_option1')?.setValue(false);
        this.formGp.get('homeloan80eea_option2')?.setValue(false);
        this.formGp.get('homeloan80eea_option3')?.setValue(false);
      }

      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(docControlName)?.setValue('');
      this.formGp.get(radioName)?.setValue(true);
      this.formGp.get(dropDown)?.setValue(null);
      this.formGp.get(amountControlName)?.setValue('');

      if(loaderName == 'employee_contribution_document_loader')
        this.employee_contribution_document_loader=false;
      if(loaderName == 'employer_contribution_document_loader')
        this.employer_contribution_document_loader=false;
      if(loaderName == 'tta_tta_document_loader')
        this.tta_tta_document_loader=false;
      if(loaderName == 'interest_education_document_loader')
        this.interest_education_document_loader=false;
      if(loaderName == 'handicapped_dependent_document_loader')
        this.handicapped_dependent_document_loader=false;
      if(loaderName == 'medical_expenditure_document_loader')
        this.medical_expenditure_document_loader=false;
      if(loaderName == 'self_suffering_document_loader')
        this.self_suffering_document_loader=false;
        if(loaderName == 'transaction_document100')
        this.transaction_document100=false;
      if(loaderName == 'royalty_patent_document_loader')
        this.royalty_patent_document_loader=false;
      if(loaderName == 'contribution_policalparties_document_loader')
        this.contribution_policalparties_document_loader=false;
      if(loaderName == 'transaction_80gga_document_loader')
        this.transaction_80gga_document_loader=false;
      if(loaderName == 'interest_home_loan_document80ee_loader')
        this.interest_home_loan_document80ee_loader=false;

      if(loaderName == 'health_insurance_selfspouse80d_document_loader')
        this.health_insurance_selfspouse80d_document_loader=false;
      if(loaderName == 'health_insurance_parents80d_document_loader')
        this.health_insurance_parents80d_document_loader=false;
      if(loaderName == 'health_checkup_selfspouse80d_document_loader')
        this.health_checkup_selfspouse80d_document_loader=false;
      if(loaderName == 'health_checkup_parents80d_document_loader')
        this.health_checkup_parents80d_document_loader=false;
      if(loaderName == 'medicalbill_senior80d_document_loader')
        this.medicalbill_senior80d_document_loader=false;
      if(loaderName == 'interest_home_loan_document80eea_loader')
        this.interest_home_loan_document80eea_loader=false;
      if(loaderName == 'interest_electrical_vehicle_loan_document_loader')
        this.interest_electrical_vehicle_loan_document_loader=false;
      if(loaderName == 'transaction_document50_loader')
        this.transaction_document50_loader=false;

      this.onDeductionValidation.emit({check:false,checkCn:checkControlName,amountCn:amountControlName,docCn:docControlName});

    }
  }

  /********* RADIO BUTTON CHANGE FUNCTION ****************/
  dedutionRadio(e:any) {
    var checkValue  = e.target.value;

    this.removeAllDeduction_80ggas();

      if(this.formGp.value.deduction_80gga.length == 0)
        this.addDeduction_80gga();

    if(checkValue == 'on'){
      this.formGp.get('total_amount')?.setValue('');

       this.onDeductionValidation.emit({check:true,checkCn:'cash_other_80gga',amountCn:'total_amount'});
    }
  }

  /********* VALIDATION FOR FORM FUNCTION ****************/
  validateDeductions(btnText:any){
    if(btnText == 'skip'){
      this.skipDeductionFunction();
    }else{
      this.submitted = true;

      this.onDeductionValidation.emit();

      if (this.formGp.invalid) {
        return;
      }
    }
    this.submitmethod.emit();
  }

  /********* SET VALUE FUNCTION ****************/
  setValue(chekBox: any, amount: any){
    if( this.formGp.get(chekBox)?.value == false){
      this.formGp.get(amount)?.setValue(0);
    }else{
      this.formGp.get(amount)?.setValue(this.formGp.get(amount)?.value);
    }

  }

  /********* donate_80g FORM ARRAY FUNCTIONs ****************/
  donate_80gs() : FormArray {
    return this.formGp.get("donate_80g") as FormArray
  }

  newDonate_80g(): FormGroup {
    if(this.formGp.value.donate_social_cause == true && this.formGp.value.cash_other == false){
      this.formGp.get('fund_type')?.setValidators([Validators.required]);
      this.formGp.get('fund_type')?.updateValueAndValidity();
      return this.fb.group({
        "name"                    : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"                     : [null,[Validators.required,PanfieldValidator()]],
        "address"                 : [null,[Validators.required,Validators.maxLength(500)]],
        "payment_mode"            : [null,[Validators.required]],
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator(),Validators.maxLength(18)]],
        "payment_date"            : [null,[Validators.required]]
      })
    }else{
      this.formGp.get('fund_type')?.clearValidators();
      this.formGp.get('fund_type')?.updateValueAndValidity();
      return this.fb.group({
        "name"                    : [null],
        "pan"                     : [null],
        "address"                 : [null],
        "payment_mode"            : null,
        "check_no_transaction_id" : null,
        "payment_date"            : null
      })
    }
  }

  addDonate_80g() {
    this.donate_80gs().push(this.newDonate_80g());
  }

  removeDonate_80gs(i:number) {
    this.donate_80gs().removeAt(i);
  }

  removeAllDonate_80gs(){
    if(this.formGp.value.donate_80g.length > 0){
      while(this.formGp.value.donate_80g.length !== 0){
        this.donate_80g().removeAt(0);
      }
    }
  }

  /********* deduction_80gga FORM ARRAY FUNCTIONs ****************/
  deduction_80ggas() : FormArray {
    return this.formGp.get("deduction_80gga") as FormArray
  }

  newDeduction_80gga(): FormGroup {
    if(this.formGp.value.gga80 == true && this.formGp.value.cash_other_80gga == false){
      return this.fb.group({
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator(),Validators.maxLength(18)]],
        "date"                    : [null,Validators.required],
      })
    }else{
      return this.fb.group({
        "check_no_transaction_id" : [null],
        "date"                    : null,
      })
    }
  }

  addDeduction_80gga() {
    this.deduction_80ggas().push(this.newDeduction_80gga());
  }

  removeDeduction_80ggas(i:number) {
    this.deduction_80ggas().removeAt(i);
  }

  removeAllDeduction_80ggas(){
    if(this.formGp.value.deduction_80gga.length > 0){
      for(let i=0; i< this.formGp.value.deduction_80gga.length; i++){
        this.removeDeduction_80ggas(i);
      }
    }
  }

  /********* ERROR GENERATE FUNCTION ****************/
  errorMsgGenerator(control:any){
    Object.entries(this.filesizeError[0]).forEach(([key, value]) => {
      if(key == control){
      this.formGp.get(control)?.setValue('');
      this.filesizeError[key] = true;}
    })
  }

  /********* ERROR REMOVE FUNCTION ****************/
  removeErrorMsg(control: any){
    Object.entries(this.filesizeError[0]).forEach(([key, value]) => {
      if(key == control){
      this.filesizeError[key] = false;}
    })
  }

  /********* DATA CLEAR FUNCTION ****************/
  dataClearFunction(e:any,amountControlName:any){
    this.formGp.get(amountControlName)?.setValue('');
    if(e.target.id == 'deduction1')
      this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(100000),Validators.maxLength(15)]);
    else
      this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(5000),Validators.maxLength(15)]);
    this.formGp.get(amountControlName)?.updateValueAndValidity();
  }

  /********* AMOUND VALIDATION FUNCTION ****************/
  amountValidation(checkControlName:any,amountControlName:any ){
    if(this.formGp.get(checkControlName)?.value == true)
      this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(100000),Validators.maxLength(15)]);
      else
        this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(5000),Validators.maxLength(15)]);
      this.formGp.get(amountControlName)?.updateValueAndValidity();
  }

  /********* DATE FILTER FUNCTION ****************/
  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  /********* TOTAL DECLARED AMOUNT FUNCTION ****************/
  totalDeclaredFunction(){

    this.totalDeclaredAmount = Number(this.formGp.value.employee_contribution_amount) + Number(this.formGp.getRawValue().employer_contribution_amount) + Number(this.formGp.value.tta_ttb_amount) + Number(this.formGp.value.interest_education_amount) + Number(this.formGp.value.handicapped_dependent_amount) + Number(this.formGp.value.medical_expenditure_amount) + Number(this.formGp.value.self_suffering_amount) + Number(this.formGp.value.royalty_patent_amount) + Number(this.formGp.value.contribution_policalparties_amount) + Number(this.formGp.value.total_amount) + Number(this.formGp.value.health_insurance_selfspouse80d_amount) + Number(this.formGp.value.health_insurance_parents80d_amount) + Number(this.formGp.value.health_checkup_selfspouse80d_amount) + Number(this.formGp.value.health_checkup_parents80d_amount) + Number(this.formGp.value.medicalbill_senior80d_amount) + Number(this.formGp.value.interest_home_loan_amount80eea) + Number(this.formGp.value.interest_electrical_vehicle_loan_amount) + Number(this.formGp.value.interest_home_loan_amount80ee) + Number(this.formGp.value.donate_social_cause100_amount) + Number(this.formGp.value.donate_social_cause50_amount);

    return this.totalDeclaredAmount;
  }

  /********* SKIP FUNCTIONS ****************/
  skipDeductionFunction(){
    Object.keys(this.formGp.controls).forEach(key => {
      if(key != 'employer_contribution' && key != 'employer_contribution_amount' && key != 'donate_80g' && key != 'deduction_80gga' && key != 'donate_80g100' && key != 'donate_80g50'){

      //   if(key == 'employee_additional_contribution' || key == 'employer_contribution' || key == 'tta_ttb' || key == 'interest_education_loan' || key == 'medical_treatment_handicapped' || key == 'disabled_severely' || key == 'medical_expenditure' || key == 'year_60_more' || key == 'self_suffering_disability' || key == 'self_disabled_severely' || key == 'royalty_patent' || key == 'contribution_individual_policalparties' || key == 'gga80' || key == 'cash_other_80gga' || 'health_insurance_selfspouse80d' || key == 'health_insurance_selfspouse80d_age' || key == 'health_insurance_parents80d' || key == 'health_insurance_parents80d_age' || key == 'health_checkup_selfspouse80d' || key == 'health_checkup_parents80d' || key == 'medicalbill_senior80d' || key == 'interest_home_loan_80eea' || key == 'homeloan80eea_option1' || key == 'homeloan80eea_option2' || key == 'homeloan80eea_option3' || key == 'interest_electrical_vehicle_loan_80eeb' || key == 'interest_home_loan_80ee' || key == 'homeloan80ee_option1' || 'homeloan80ee_option2' || key == 'homeloan80ee_option3' || key == 'homeloan80ee_option4' || key == 'donate_social_cause100' || key == 'cash_other100' || key == 'donate_social_cause_50' || key == 'cash_other50' ){
        if(this.formGp.controls[key].value == true){

          this.formGp.controls[key].setValue(false);
          this.formGp.get('issue')?.setValue(null);

        }
        else if((this.formGp.controls[key].value != true) && (this.formGp.controls[key].value != false)){
          this.formGp.controls[key].reset('');
        }
        if(key == 'health_insurance_selfspouse80d_age'){
          this.formGp.get('health_insurance_selfspouse80d_age')?.setValue(true);
        }
        if( key == 'health_insurance_parents80d_age'){
          this.formGp.get('health_insurance_parents80d_age')?.setValue(true);
        }
        if( key == 'disabled_severely'){
          this.formGp.get('disabled_severely')?.setValue(true);
        }
        if( key == 'year_60_more'){
          this.formGp.get('year_60_more')?.setValue(true);
        }
        if(key == 'self_disabled_severely'){
          this.formGp.get('self_disabled_severely')?.setValue(true);
        }

      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'deduction_80gga'){
        this.removeAllDeduction_80ggas();
        this.formGp.get('cash_other_80gga')?.setValue(false);
      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'donate_80g100'){
        this.removeAllDonate_80gs100();
        this.formGp.get('cash_other100')?.setValue(true);
        this.formGp.get('fund_type100')?.setValue(null);
      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'donate_80g50'){
        this.removeAllDonate_80gs50();
        this.formGp.get('cash_other50')?.setValue(true);
        this.formGp.get('fund_type50')?.setValue(null);
      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'donate_80g'){
        this.removeAllDonate_80gs();
      }

      else if(key != 'employer_contribution' && key != 'employer_contribution_amount' && key != 'donate_80g' && key != 'deduction_80gga' && key != 'donate_80g100' && key != 'donate_80g50' && (this.formGp.controls[key].value != true) && (this.formGp.controls[key].value != false)){
        this.formGp.controls[key].reset('');
      }
      this.clearValidationFunction(key)
      this.invetData();
    });
  }

  clearValidationFunction(key:any){
    this.formGp.get(key)?.clearValidators();
    this.formGp.get(key)?.updateValueAndValidity();
  }

  /********* INVESTMENT DETAILS FUNCTION ****************/
  invetData(){
    this.formGp.controls.employer_contribution.disable();
    this.formGp.controls.employer_contribution_amount.disable();

    this.setValue('employer_contribution','employer_contribution_amount');
    if(this.from=='behalf'){
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
        this.setInvestmentDetails(res)
      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
        this.setInvestmentDetails(res)
      });
    }
  }
  setInvestmentDetails(res:any){
    if(res.count_info.hasOwnProperty('assigned_salary_data')){
      if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){

          let nps_amount_total = Number(res.count_info.assigned_salary_data.nps_amount + res.count_info.assigned_salary_data.prev_employer_nps_deduction)
          if(nps_amount_total>0){
            // condition added for previous employer nps scenario
            this.formGp.get('employer_contribution')?.setValue(true);
            console.log("ss",nps_amount_total)
          }else{
            this.formGp.get('employer_contribution')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
          }
          this.formGp.get('employer_contribution_amount')?.setValue(nps_amount_total);
          console.log("h",nps_amount_total)
        }
      }
    if(res.count_info.hasOwnProperty('employee_data')){
      this.employeeAge = res.count_info.employee_data.age;
    }
  }
  donate_80g() : FormArray {
    return this.formGp.get("donate_80g") as FormArray
  }
  /********* donate_80g FORM ARRAY FUNCTIONs ****************/
  donate_80gs100() : FormArray {
    return this.formGp.get("donate_80g100") as FormArray
  }

  newDonate_80g100(): FormGroup {
    if(this.formGp.value.donate_social_cause100 == true && this.formGp.value.cash_other100 == true){
      this.formGp.get('fund_type100')?.setValidators([Validators.required]);
      this.formGp.get('fund_type100')?.updateValueAndValidity();
      return this.fb.group({
        "exemption"               : true,
        "name"                    : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"                     : [null,[Validators.required,PanfieldValidator()]],
        "address"                 : [null,[Validators.required,Validators.maxLength(500),UnamepatternValidator()]],
        "payment_mode"            : [null,[Validators.required]],
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator,Validators.maxLength(18)]],
        "payment_date"            : [null,[Validators.required]]
      })
    }else{
      this.formGp.get('fund_type100')?.clearValidators();
      this.formGp.get('fund_type100')?.updateValueAndValidity();
      return this.fb.group({
        "exemption"               : null,
        "name"                    : [null],
        "pan"                     : [null],
        "address"                 : [null],
        "payment_mode"            : null,
        "check_no_transaction_id" : null,
        "payment_date"            : null
      })
    }
  }

  addDonate_80g100() {
    this.donate_80gs100().push(this.newDonate_80g100());
  }

  removeDonate_80gs100(i:number) {
    this.donate_80gs100().removeAt(i);
  }

  removeAllDonate_80gs100(){

    if(this.formGp.value.donate_80g100.length > 0){
      while(this.formGp.value.donate_80g100.length !== 0){
        this.donate_80gs100().removeAt(0);
      }
    }
  }

  /********* donate_80g FORM ARRAY FUNCTIONS ****************/
  donate_80gs50() : FormArray {
    return this.formGp.get("donate_80g50") as FormArray
  }

  newDonate_80g50(): FormGroup {
    if(this.formGp.value.donate_social_cause_50 == true && this.formGp.value.cash_other50 == true){
      this.formGp.get('fund_type50')?.setValidators([Validators.required]);
      this.formGp.get('fund_type50')?.updateValueAndValidity();
      return this.fb.group({
        "exemption" : false,
        "name"                    : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"                     : [null,[Validators.required,PanfieldValidator()]],
        "address"                 : [null,[Validators.required,Validators.maxLength(500)]],
        "payment_mode"            : [null,[Validators.required]],
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator,Validators.maxLength(18)]],
        "payment_date"            : [null,[Validators.required]]
      })
    }else{
      this.formGp.get('fund_type50')?.clearValidators();
      this.formGp.get('fund_type50')?.updateValueAndValidity();
      return this.fb.group({
        "exemption" : null,
        "name"                    : [null],
        "pan"                     : [null],
        "address"                 : [null],
        "payment_mode"            : null,
        "check_no_transaction_id" : null,
        "payment_date"            : null
      })
    }
  }

  addDonate_80g50() {
    this.donate_80gs50().push(this.newDonate_80g50());
  }

  removeDonate_80gs50(i:number) {
    this.donate_80gs50().removeAt(i);
  }

  removeAllDonate_80gs50(){
    if(this.formGp.value.donate_80g50.length > 0){
      while(this.formGp.value.donate_80g50.length !== 0){
        this.donate_80gs50().removeAt(0);
      }
    }
  }

 /********* FUND TYPE 100 && 50 DROPDOWN FUNCTIONS ****************/
  getDonation100DropDownData(){
    this.investmentService.donation100DropDown(this.currentYear).subscribe((res:any) =>{
      if(res)
       this.donation100DropDownData = res;
    })
  }
  getDonation50DropDownData(){
    this.investmentService.donation50DropDown(this.currentYear).subscribe((res:any) =>{
      if(res)
       this.donation50DropDownData = res;
    })
  }

   /********* VIEW DOCUMENT FUNCTION ****************/
   viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

}
