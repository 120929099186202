import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleRoadService {

axios = require('axios');

snapToRoads(waysVal:any,originVal: any,DestVal: any): any {
  let unsnappedWaypoints:any = []

  waysVal.forEach((waypoint: any, key: any) => {
    unsnappedWaypoints.push(waypoint.latitude + ',' + waypoint.longitude);
  })

    const url1 =`https://roads.googleapis.com/v1/snapToRoads?interpolate=true&key=${environment.locKey}&path=${unsnappedWaypoints.join('|')}`;
    const config = {
    method: "get",
    url: url1,
    headers: {},
    };
    return new Observable<any>((observer) => {
    this.axios(config)
        .then(function (response: any): void {
        observer.next(response.data);
        // return response.data;
        })
        .catch(function (error: any): void {
        observer.next(error);
        });
    });

}
}