<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewAttchments === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>View</h5>
            <a class="toggle-panel" (click)="closeModal()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16 ">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0">ATTACHMENTS</p>
                        <a *ngIf="from != 'expenseReportRequestView' && from != 'expenseReportRequestSummary'" class="link-primary1 ms-auto" (click)="addAttachmentFunction()">Add New Document</a>
                    </div>
                    <!-- <ng-container *ngFor="let attach of uploadedAttachments;let i=index;">
                    <div class="card card-c2 p-24">
                      <div class="row row-16">
                        <div class="col-lg-6 ">

                          <div class="card card-c2  bg-secondary">
                            <div class="card-body  ">
                              <div class="d-flex align-items-start mb-8 gap-16">
                                <i class="icon-file-earmark-text-fill fs-32 mt-1 text-accent2"></i>
                                <div>
                                  <h6 class="mb-1">{{this.appservice.getDocName(attach?.attachment)}}</h6>

                                </div>
                                <div class="ms-auto ">
                                  <i class="icon-check-circle-bold text-success fs-20"></i>
                                </div>
                              </div>
                              <div class="d-flex gap-24 fs-12 fw-500">
                                <div class="link-primary" (click)="this.appservice.downloadFile(attach?.attachment,this.appservice.getDocName(attach?.attachment))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                                <div class="link-primary" (click)="viewDocument(attach?.attachment)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="from != 'expenseReportRequestView' && from != 'expenseReportRequestSummary'" class="col-lg-6 d-flex">
                          <a class="link-danger ms-auto" (click)="removeAttachment(i)"><i class="icon-trash"></i> </a>
                        </div>
                    </div>
                   </div>
                   </ng-container> -->

                   <div *ngIf="uploadedAttachments?.length > 0" class="card card-c2 p-24">
                      <div class="row row-16">
                        <div class="col-lg-6 " *ngFor="let attach of uploadedAttachments;let i=index;">
                          <div class="card card-c2  bg-secondary">
                            <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                              <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                              <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(attach?.attachment)}}</span>
                                <span class="tips-helper ">{{this.appservice.getDocName(attach?.attachment)}}</span>
                                <div class="d-flex gap-8">
                                  <div class="link-primary" (click)="viewDocument(attach?.attachment)">
                                    <i class="bi bi-eye me-1"></i>View
                                  </div>
                                  <div class="link-primary" (click)="this.appservice.downloadFile(attach?.attachment,this.appservice.getDocName(attach?.attachment))">
                                    <i class="bi bi-download me-1"></i>Download
                                  </div>
                                </div>
                              </div>
                              <i *ngIf="from != 'expenseReportRequestView' && from != 'expenseReportRequestSummary'" class="icon-trash  ms-auto flex-center link-danger" (click)="removeAttachment(i)"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                   <div *ngIf="uploadedAttachments?.length == 0" class="row pt-4">
                    <app-nodata-view [noDataText]="'No Data'" [hasPermission]="false"> </app-nodata-view>
                  </div>

                </div>
            </div>
        </div>
        <!-- <footer *ngIf="from != 'expenseReportRequestView' && from != 'expenseReportRequestSummary'" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="uploadedAttachments?.length == 0"  (click)="saveUpdatedAttachments()">
                Save
            </button>
        </footer> -->
    </div>
</div>

<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
 [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>

 <app-upload-multiple-attachments *ngIf="uploadeAttachments" [from]="from" [uploadeAttachments]="uploadeAttachments" [fileType]="'.pdf,.doc'" [receiptId]="receiptId" [finalReceiptAttachment]="finalReceiptAttachment" (closeAttachment)="closeAttachment()" (saveAttachment)="saveAttachment($event)"></app-upload-multiple-attachments>

  <app-info-popup *ngIf="showVaidation == true" (modal)="closeValidationPopup($event)" [infoToggle]="showVaidation" [infoMsg]="investalertMsg"
  ></app-info-popup>

  <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16 ">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Attachment?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('attachment')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteAttachment()">Delete</button>
        </div>
      </div>
    </div>
  </div>

