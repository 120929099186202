import { Component, EventEmitter, Input, OnInit, Output,ViewChild} from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { ReimbursementInboxDetailsComponent } from '../../reimbursement-details/reimbursement-details.component';

@Component({
  selector: 'app-reimbursement-inbox',
  templateUrl: './reimbursement-inbox.component.html',
  styleUrls: ['./reimbursement-inbox.component.scss']
})
export class ReimbursementInboxComponent implements OnInit {


  alertShow                       = 'show';

  // View Status
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  requestCancel:any
  sendReminder = false
  constructor(
    public messageService : MessageService,
    public inboxservice: InboxService,
    private investmentService:InvestmentDeclerationService,
   ) { }

  @Input() requestitem: any;
  @Input() from: any = 'inbox';
  @Input() cancelStatus:any
  @Output() cancelEmitter: EventEmitter<boolean> =  new EventEmitter();
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  @ViewChild(ReimbursementInboxDetailsComponent) reim:any;

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }


  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
    }
    cancelBill(data:any){
      this.cancelEmitter.emit(data);

    }
    clearComment(){
      this.reim.clearComment()
    }

}
