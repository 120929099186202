
    <div class="container-fluid p-32 form-container" *ngIf="permissions">
        <div  class="row row-24 ">
            <div class="col-12 d-flex flex-wrap align-items-start">
                <div class="mb-0 fw-500 text-uppercase">Attendance - {{month}} {{year}}</div>
                <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">
                    <!-- <a *ngIf="countSelected()>=1 && permissions?.a"  class="link-danger text-nowrap" (click)="bulkDelete()"><i class="icon-trash me-8 "></i>Delete</a> -->
                    <div style="min-width:22.688rem;">
                        <div class="form-icon2">
                          <i class="icon-search icon-left fs-14"></i>
                          <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                          <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                        </div>
                    </div>
                    <!-- <div  class="d-inline-block" >
                        <button class="btn-square btn btn-secondary">
                            <i class="icon-filter-left fs-13"></i>
                        </button>
                     </div> -->
                    <button *ngIf="permissions?.a && status=='PENDING'" class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 " (click)="deleteFile();showSidePanel=true">IMPORT</button>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [checked]='status=="PENDING"' (click)='status="PENDING"' [disabled]="loader">
                        <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">PENDING</label>
                        <input type="radio" class="btn-check"  name="btnradio" id="btnradio2" autocomplete="off" [checked]='status=="COMPLETED"' (click)='status="COMPLETED"' [disabled]="loader">
                        <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">COMPLETED</label>
                      </div>
                </div>
            </div>
            <!-- <div class="col-12">
                <div role="alert" class="alert alert-warning mb-0 show">
                    <p class="mb-0 fs-14">Click on the editable fields to update the data</p>
                    <div class="btn-close pointer"></div>
                </div>
            </div> -->
            <div class="col-12">
                <div class="card card-c2 p-0">
                    <app-table-loader *ngIf="loader"></app-table-loader>
                    <div *ngIf="!loader" class="table-responsive radius-4">
                        <table  *ngIf="status=='PENDING'" class="table sticky-header td-white-space td-12 th-12 td-fs-14 table-striped tr-fs-14 table-sm form-table sticky-first-col sticky-last-col">
                            <thead>
                                <tr>
                                    <th class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                                    </th>
                                    <th class="fw-600" scope="col">EMP CODE</th>
                                    <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                    <th class="fw-600" scope="col">SALARY STATUS</th>
                                    <th class="fw-600" scope="col">TOTAL DAYS</th>
                                    <!-- <th class="fw-600" scope="col">ACTUAL PAID DAYS</th> -->
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            PRESENT DAYS <span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            WEEKLY OFF’S<span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            LEAVE <span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            holiday<span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            absent<span class="badge badge-danger ms-8 py-1 fs-10 radius-4">unpaid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            leave<span class="badge badge-danger ms-8 py-1 fs-10 radius-4">unpaid</span>
                                        </div>
                                    </th>
                                    <th *ngIf="permissions?.a"></th>
                                </tr>
                            </thead>
                            <tbody class="height-full">
                                    <ng-container *ngIf="inputData.length==0 || showSidePanel == true">
                                    <tr>
                                      <td colspan="12">

                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                          <span class="fw-500 fs-14">No data found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  </ng-container>
                                  <ng-container *ngIf="showSidePanel == false">
                                    <tr *ngFor="let item of $any(inputData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                        <td class="form-cell">
                                            <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked" >
                                        </td>
                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.employee.is_valid">
                                              <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.employee}}</div>
                                              <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)]?.employee.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.employee.is_valid">{{inputData[inputData.indexOf(item)]?.employee}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{(errorArray[inputData.indexOf(item)]?.employee.is_valid) ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.employee}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.employee.is_valid" [errorData]="errorArray[inputData.indexOf(item)]?.employee.error"></app-table-input-popover>
                                            </div>
                                        </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.emp_name.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.employeeName}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].emp_name.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.emp_name.is_valid">{{inputData[inputData.indexOf(item)]?.employeeName}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.emp_name.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.employeeName}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.emp_name.is_valid" [errorData]="errorArray[inputData.indexOf(item)].emp_name.error"></app-table-input-popover>
                                            </div>
                                        </td>
                                        <td >
                                            <span *ngIf="errorArray[inputData.indexOf(item)]?.payroll_status=='Publish'"  class="badge badge-success py-1 px-3">Published</span>
                                            <span *ngIf="errorArray[inputData.indexOf(item)]?.payroll_status=='Unprocessed'"  class="badge badge-danger py-1 px-3">{{errorArray[inputData.indexOf(item)]?.payroll_status}}</span>
                                            <span *ngIf="errorArray[inputData.indexOf(item)]?.payroll_status=='Processed'"  class="badge badge-warning py-1 px-3">{{errorArray[inputData.indexOf(item)]?.payroll_status}}</span>
                                            <span *ngIf="errorArray[inputData.indexOf(item)]?.payroll_status=='Hold'"  class="badge badge-primary py-1 px-3">Held</span>
                                            <span *ngIf="errorArray[inputData.indexOf(item)]?.payroll_status=='Freeze'"  class="badge badge-holiday py-1 px-3">Freezed</span>
                                        </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.total_days.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.total_days}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].total_days.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.total_days.is_valid">{{inputData[inputData.indexOf(item)]?.total_days}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.total_days.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.total_days}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.total_days.is_valid" [errorData]="errorArray[inputData.indexOf(item)].total_days.error"></app-table-input-popover>
                                            </div>
                                        </td>

                                        <!-- <td >
                                          25
                                        </td> -->

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.prasent_days.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.prasent_days}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].prasent_days.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.prasent_days.is_valid">{{inputData[inputData.indexOf(item)]?.prasent_days}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.prasent_days.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.prasent_days}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.prasent_days.is_valid" [errorData]="errorArray[inputData.indexOf(item)].prasent_days.error"></app-table-input-popover>
                                            </div>
                                        </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.weekly_offs.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.weekly_offs}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].weekly_offs.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.weekly_offs.is_valid">{{inputData[inputData.indexOf(item)]?.weekly_offs}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.weekly_offs.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.weekly_offs}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.weekly_offs.is_valid" [errorData]="errorArray[inputData.indexOf(item)].weekly_offs.error"></app-table-input-popover>
                                            </div>
                                          </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.leave_paid.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.leave_paid}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].leave_paid.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.leave_paid.is_valid">{{inputData[inputData.indexOf(item)]?.leave_paid}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.leave_paid.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.leave_paid}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.leave_paid.is_valid" [errorData]="errorArray[inputData.indexOf(item)].leave_paid.error"></app-table-input-popover>
                                            </div>
                                          </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.holiday_paid.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.holiday_paid}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].holiday_paid.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.holiday_paid.is_valid">{{inputData[inputData.indexOf(item)]?.holiday_paid}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.holiday_paid.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.holiday_paid}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.holiday_paid.is_valid" [errorData]="errorArray[inputData.indexOf(item)].holiday_paid.error"></app-table-input-popover>
                                            </div>
                                        </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.absent_unpaid.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.absent_unpaid}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].absent_unpaid.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.absent_unpaid.is_valid">{{inputData[inputData.indexOf(item)]?.absent_unpaid}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.absent_unpaid.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.absent_unpaid}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.absent_unpaid.is_valid" [errorData]="errorArray[inputData.indexOf(item)].absent_unpaid.error"></app-table-input-popover>
                                            </div>
                                        </td>

                                        <td >
                                            <!-- <ng-container *ngIf="errorArray[inputData.indexOf(item)]?.leave_unpaid.is_valid">
                                                <div class="cell-error is-invalid">{{inputData[inputData.indexOf(item)]?.leave_unpaid}}</div>
                                                <div class="invalid-feedback">{{errorArray[inputData.indexOf(item)].leave_unpaid.error}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!errorArray[inputData.indexOf(item)]?.leave_unpaid.is_valid">{{inputData[inputData.indexOf(item)]?.leave_unpaid}}</ng-container> -->
                                            <div class="d-flex align-items-center gap-8 {{errorArray[inputData.indexOf(item)]?.leave_unpaid.is_valid ? 'cell-error':''}}">
                                              <p class="fs-14 mb-0 text-trim w-15ch">{{inputData[inputData.indexOf(item)]?.leave_unpaid}}</p>
                                              <app-table-input-popover [errorCheck]="errorArray[inputData.indexOf(item)]?.leave_unpaid.is_valid" [errorData]="errorArray[inputData.indexOf(item)].leave_unpaid.error"></app-table-input-popover>
                                            </div>
                                        </td>
                                        <td *ngIf="permissions?.a"><i class="icon-trash pointer" (click)="deleteRow(inputData.indexOf(item))"></i></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <table  *ngIf="status=='COMPLETED'" class="table sticky-header  td-12 th-12 td-white-space table-striped table-sm form-table sticky-first-col sticky-last-col">
                            <thead>
                                <tr>
                                    <th class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                                    </th>
                                    <th class="fw-600" scope="col">EMP CODE</th>
                                    <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                    <th class="fw-600" scope="col">SALARY STATUS</th>
                                    <th class="fw-600" scope="col">TOTAL DAYS</th>
                                    <!-- <th class="fw-600" scope="col">ACTUAL PAID DAYS</th> -->
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            PRESENT DAYS <span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            WEEKLY OFF’S<span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            LEAVE <span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            holiday<span class="badge badge-success ms-8 py-1 fs-10 radius-4">paid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            absent<span class="badge badge-danger ms-8 py-1 fs-10 radius-4">unpaid</span>
                                        </div>
                                    </th>
                                    <th class="fw-600" scope="col">
                                        <div class="d-flex align-items-center">
                                            leave<span class="badge badge-danger ms-8 py-1 fs-10 radius-4">unpaid</span>
                                        </div>
                                    </th>
                                    <th *ngIf="permissions?.a"></th>
                                </tr>
                            </thead>
                            <tbody class="cell-16">
                                <ng-container *ngIf="historyData.length==0 ">
                                <tr>
                                  <td colspan="13">

                                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                                      <span class="fw-500 fs-14">No data found</span>
                                    </div>
                                  </td>
                                </tr>
                              </ng-container>
                                <tr *ngFor="let item of $any(historyData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                    <td class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked" >
                                    </td>
                                    <td >{{item.employee_code}}</td>
                                    <td >{{item.employee_name}}</td>
                                    <td >
                                        <span *ngIf="item?.payroll_status=='Publish'"  class="badge  badge-success py-1 px-3">Published</span>
                                        <span *ngIf="item?.payroll_status=='Unprocessed'"  class="badge badge-danger py-1 px-3">{{item?.payroll_status}}</span>
                                        <span *ngIf="item?.payroll_status=='Processed'"  class="badge badge-warning py-1 px-3">{{item?.payroll_status}}</span>
                                        <span *ngIf="item?.payroll_status=='Hold'"  class="badge badge-primary py-1 px-3">Held</span>
                                        <span *ngIf="item?.payroll_status=='Freeze'"  class="badge badge-holiday py-1 px-3">Freezed</span>
                                    </td>
                                    <td >{{item.total_days}}</td>
                                    <!-- <td >{{item.actual_paid_days}}</td> -->
                                    <td >{{item.prasent_days}}</td>
                                    <td >{{item.weekly_offs}}</td>
                                    <td >{{item.leave_paid}}</td>
                                    <td >{{item.holiday_paid}}</td>
                                    <td >{{item.absent_unpaid}}</td>
                                    <td >{{item.leave_unpaid}}</td>


                                    <td *ngIf="permissions?.a"><i class="icon-trash pointer" (click)="deleteRow(historyData.indexOf(item))"></i></td>
                                </tr>
                        </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-12" *ngIf="filteredcount()>pageSize">
                <ngb-pagination class="d-flex justify-content-end"  [collectionSize]="filteredcount()" [(page)]="page"  [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
            </div>
        </div>

    </div>
    <footer class="submit-footer gap-16" *ngIf="status=='PENDING'" >
      <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="bulkDelete()" *ngIf="countSelected()>=1 && permissions?.a">Delete</button>
        <p class="fs-14 ms-auto d-flex gap-8 mb-0"  *ngIf="inputData.length>0">
            <span class="">{{countSelected()}} of {{inputData.length}} selected </span>
            <span *ngIf="inputData.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{inputData?.length}} employees</span>
            <span *ngIf="inputData.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{inputData?.length}} employees</span>
        </p>
        <button class="btn btn-primary btn-sm text-uppercase" [disabled]="fileName == '' || submiited|| countSelected()==0" type="button" (click)="confirmation()">Save</button>
    </footer>


<!-- Slider -->
<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Import Attendance - {{month}} {{year}}</h5>
            <a class="toggle-panel" (click)="deleteFile();showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <div class="row row-24 mb-24">
                <div class="col-12">
                    <div class="card card-c2 p-24">
                        <div class="title mb-24">New Attendance - {{month}} {{year}}</div>
                        <div class="row" *ngIf="fileName">
                            <div class="col-md-6">
                              <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                                <div class="d-flex p-24 ">
                                    <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                    <span class="text-trim fw-500">{{fileName}}</span>
                                    <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                    <span class="link-danger fw-500 flex-shrink-0" (click)="deleteFile()" ><i class="bi bi-trash ms-8 "></i></span>
                                </div>
                              </div>
                            </div>
                        </div>
                        <label for="upload"  [class.disabled]="fileName != ''" style="max-width: 18.375rem;" class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                            <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null" (change)="onFileChange($event)" id="upload" hidden>Add New Attendance

                        </label>
                        <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card card-c2 p-24">
                        <div class="title mb-24">Instructions </div>
                        <ol class="fs-14 ps-16 mb-0 li-24">
                            <li>Download the <a class="link-primary" href="assets/docs/sample-attendance-import.xlsx"> <i class="icon-download me-1"></i> Sample Excel
                                template</a></li>
                        <li>To import the attendance details of the employees, click on the Add New Attendance
                            button.</li>
                        <li>Choose the file to be imported.</li>
                        <li>The file chosen should be in (.xls/.csv)format only.</li>
                        </ol>
                    </div>
                </div>
            </div>

            </div>
        </div>
        <footer class="side-panel-footer gap-32 pt-16">
          <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || submiited" type="button" (click)="showSidePanel = false;status='PENDING'">Upload</button>
        </footer>
    </div>

</div>


<!-- <div  class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
            <h4 class="modal-title" *ngIf="successDataCount>0">{{successDataCount}} attendance data uploaded successfully.</h4>
            <h4 class="modal-title" *ngIf="successDataCount==0">No attendance data uploaded</h4>
            </div>
            <div *ngIf="failedData.length>0" class="modal-body">
                    <p class="mb-0">{{failedData.length}} error data found.</p>
                    <p class="mb-0">Click on download to view the error details</p>
            </div>
            <div  class="modal-footer">
                <button *ngIf="failedData.length>0" type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="bulkDelete();downloadMsg = false;">Close</button>
            </div>
        </div>
    </div>
</div> -->

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
            <i class="bi bi-exclamation-triangle text-danger"></i>
            <h4 class="modal-title">Error Occured</h4>
            </div>
            <div class="modal-body">
                    <p class="mb-0">{{deleteMsg}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
                <h4 class="modal-title">{{saveData.length}} Employees selected</h4>
                <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{confirmMsgText}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="confirmMsg = false;">Cancel</button>
                <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase"
                    (click)="uploadData();confirmMsg = false;">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal  modal-alert {{isDelete == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16 ">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Delete Attendance?</h4>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{this.messageService.Deletemsgdisplay('Attendance')}}</p>
            </div>
            <div class="modal-footer"  *ngIf="permissions">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="isDelete = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteAttenance()">Delete</button>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="alertToggle == true">
    <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg" ></app-info-popup>
</ng-container>
<app-upload-progress-bar  *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'" [body]="body" ></app-upload-progress-bar>

<app-error-download [fileName]="'Attendance Import Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Attendance Import'" (closePanel)="bulkDelete();downloadMsg = false;" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>
