<div class="row row-16 ">
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let dummy of ' '.repeat(10).split(''), let x = index">
    <div class="card card-custom1">
      <div class="card-body d-flex pb-8 ">
        <ngx-skeleton-loader appearance="circle"
          [theme]="{ 'border-radius': '0.5rem',height: '3rem',width: '3rem','background-color': '#EAEDEF','margin-right':'1rem'}"></ngx-skeleton-loader>
        <div>
          <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '1.188rem','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" *ngIf="loader"
            [theme]="{'background-color': '#EAEDEF','height': '0.813rem','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="card-body  justify-content-between py-8 ">
        <div class="d-flex align-items-center">
          <ngx-skeleton-loader appearance="circle"
            [theme]="{height: '1.5rem',width: '1.5rem','background-color': '#EAEDEF', 'border-radius': '64px','margin-right':'0.5rem'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '0.75rem','width': '67px' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
        <div class="fs-12  fw-500 text-light-400">

        </div>
      </div>
    </div>
  </div>
</div>
