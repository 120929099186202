import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import * as moment from 'moment';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { Subject } from 'rxjs';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ShiftAllowanceService } from 'src/app/@core/services/shift-allowance.service';
import { exit } from 'process';
@Component({
  selector: 'app-shift-allowance-policy-details',
  templateUrl: './shift-allowance-policy-details.component.html',
  styleUrls: ['./shift-allowance-policy-details.component.scss']
})
export class ShiftAllowancePolicyDetailsComponent implements OnInit {
  @Input()formGp : FormGroup    = this.fb.group({
      name               : ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      description        : ['', [Validators.required,Validators.maxLength(500)]],
      policy_valid_from  : ['', [Validators.required]],
      allowance_shift    : this.fb.array([])
    });
   value:any;
   id:any;
   validated : boolean = false;
   @Input() editflag:any
   @Output() submitmethod        = new EventEmitter();
   @Input()disabled              = false;
   @Input()submitted:boolean=false;
   @Input()shiftslist:any=[];
   shiftIdsFromList:any=[];
   private subjectKeyUp = new Subject<any>();
   fromDateFilter = (date: Date | null): boolean => { return true; }

  constructor(
    public messageService : MessageService,
    public appservice:AppService,
    private fb:FormBuilder,
    public notificationservice:NotificationService,
    public existDataService :  ExistDatasService,
    public ShiftAllowanceService :  ShiftAllowanceService,
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d);
    })
      this.shiftIdsFromList = this.formGp.value?.allowance_shift.map((shift: any) => shift.id);
    }

    existService(type:any){
      let value = ""

      if(type == "name")
        value =this.f.name.value
      else
        value =this.f.shift_code.value
      if(!this.editflag){
        this.value ={
          "page": "shiftallowance",
          "field": type,
          "value": value
        }
      }else{
         this.value ={
          "id": this.id,
          "page": "shiftallowance",
          "field": type,
          "value": value
        }
      }

      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        if(res.body.data ==='invalid'&& type == "name"){
          this.f.name.setErrors({
            'exist' : true
          })
        }
      })
    } 

  validateshiftdetail() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  keyUp(){
    this.subjectKeyUp.next("name");    
  }

  disableDate(){
    return false;
  }

  onCheckChange(event: any, data: any) {
    const formArray: FormArray = this.formGp.get('allowance_shift') as FormArray;
  
    if (event.target.checked) {
      if (formArray.length === 0) {
        formArray.push(this.fb.group(data));
      } else {
        const shiftIdsFromList = formArray.controls.map((shift: any) => shift.value.id);
        if (!shiftIdsFromList.includes(data.id)) {
          formArray.push(this.fb.group(data));
        }
      }
    } else {
      let i: number = 0;
      formArray.controls.forEach((ctrl: any) => {
        if (ctrl.id == event.target.value.id) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
}

