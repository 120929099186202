import { Component, OnInit, Output,EventEmitter, Input, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { GstnumValidator } from 'src/app/@shared/validators/gstnum.validators';
import { AmountValidator } from 'src/app/@shared/validators/amount.validators';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-proposed-deductions',
  templateUrl: './proposed-deductions.component.html',
  styleUrls: ['./proposed-deductions.component.scss']
})

export class ProposedDeductionsComponent implements OnInit {

  deduction: boolean          = true;
  issueDropdownPlaceHolder    = this.messageService.selectplaceholddisplay('issue')
  issues:string               = "issues";
  paymentDropdownPlaceHolder  = this.messageService.selectplaceholddisplay('payment')
  nocash_payment_mode:string  = "nocash_payment_mode";
  fund_type:string            = "fund_type"
  fundDropdownPlaceHolder     = this.messageService.selectplaceholddisplay('fund')
  currentYear:number          = 0;
  isrequired                  = true;
  experianceValidator1        = false;
  amountMsg1:string           = '';
  experianceValidator2        = false;
  amountMsg2:string           = '';
  experianceValidator3        = false;
  amountMsg3:string           = '';
  experianceValidator4        = false;
  amountMsg4:string           = '';
  experianceValidator5        = false;
  amountMsg5:string           = '';
  employeeAge:number          = 0;
  totalDeclaredAmount:number  = 0;
  alertShow                   = 'show';
  empSalary:any               = 0;
  donation100DropDownData:any = [];
  donation50DropDownData:any  = [];

  @Input()name:any;
  @Input()from:any;
  @Input()empId:any;
  @Input()currency:any;
  @Input()submitted:boolean       = false;
  @Input()limitData:any;

  @Output()submitmethod           = new EventEmitter();
  @Output()onDeductionValidation  = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public messageService : MessageService,
    public fb:FormBuilder,
    private appservice:AppService,
    private datePipe:DatePipe,
    private investmentService:InvestmentDeclerationService,
    private activityService:ManageActivityService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.currentYear = this.appservice.getFinancialYear();

    this.invetData();
    this.getDonation100DropDownData();
    this.getDonation50DropDownData();
  }

  @Input()formGp : FormGroup = this.fb.group({

    employee_additional_contribution    : [''],
    employee_contribution_amount        : [''],
    employee_contribution_document      : [''],
    employer_contribution               : [''],
    employer_contribution_amount        : [''],
    employer_contribution_document      : [''],
    tta_ttb                             : [''],
    tta_ttb_amount                      : [''],
    tta_tta_document                    : [''],
    interest_education_loan             : [''],
    interest_education_amount           : [''],
    interest_education_document         : [''],

    medical_treatment_handicapped       : [''],
    disabled_severely                   : [''],
    handicapped_dependent_amount        : [''],
    handicapped_dependent_document      : [''],
    medical_expenditure                 : [''],
    year_60_more                        : [''],
    medical_expenditure_amount          : [''],
    medical_expenditure_document        : [''],
    issue                               : [''],
    self_suffering_disability           : [''],
    self_disabled_severely              : [''],
    self_suffering_amount               : [''],
    self_suffering_document             : [''],

    fund_type                           : [''],
    donate_80g                          : [],
    royalty_patent                      : [''],
    royalty_patent_amount               : [''],
    royalty_patent_document             : [''],
    contribution_individual_policalparties: [''],
    contribution_policalparties_amount    : [''],
    contribution_policalparties_document  : [''],
    gga80                                 : [''],
    cash_other_80gga                      : [''],
    deduction_80gga                       : [],
    total_amount                          : [''],
    transaction_80gga_document            : [''],

    health_insurance_selfspouse80d          : [''],
    health_insurance_selfspouse80d_age      : [''],
    health_insurance_selfspouse80d_amount   : [''],
    health_insurance_selfspouse80d_document : [''],
    health_insurance_parents80d             : [''],
    health_insurance_parents80d_age         : [''],
    health_insurance_parents80d_amount      : [''],
    health_insurance_parents80d_document    : [''],
    health_checkup_selfspouse80d            : [''],
    health_checkup_selfspouse80d_amount     : [''],
    health_checkup_selfspouse80d_document   : [''],
    health_checkup_parents80d               : [''],
    health_checkup_parents80d_amount        : [''],
    health_checkup_parents80d_document      : [''],
    medicalbill_senior80d                   : [''],
    medicalbill_senior80d_amount            : [''],
    medicalbill_senior80d_document          : [''],
    interest_home_loan_80eea                : [''],
    homeloan80eea_option1                   : [''],
    homeloan80eea_option2                   : [''],
    homeloan80eea_option3                   : [''],
    interest_home_loan_amount80eea          : [''],
    interest_home_loan_document80eea        : [''],
    interest_electrical_vehicle_loan_80eeb  : [''],
    interest_electrical_vehicle_loan_amount : [''],
    interest_electrical_vehicle_loan_document : [''],
    interest_home_loan_80ee                 : [''],
    homeloan80ee_option1                    : [''],
    homeloan80ee_option2                    : [''],
    homeloan80ee_option3                    : [''],
    homeloan80ee_option4                    : [''],
    interest_home_loan_amount80ee           : [''],
    interest_home_loan_document80ee         : [''],
    donate_social_cause100                  : [''],
    donate_social_cause100_amount           : [''],
    cash_other100                           : [''],
    fund_type100                            : [''],
    transaction_document100                 : [''],
    donate_social_cause_50                  : [''],
    donate_social_cause50_amount            : [''],
    cash_other50                            : [''],
    fund_type50                             : [''],
    transaction_document50                  : [''],
    donate_80g100                           : [],
    donate_80g50                            : [],
  });

  get f() {
    return this.formGp.controls;
  }

  /********* RADIO BUTTON CHANGE FUNCTION ****************/
  radioBtnChange(e:any,check:any,amount: any){
    this.formGp.get(amount)?.setValue('');
    this.amountMsg1 = '';
    this.amountMsg2 = '';
    this.amountMsg3 = '';
    this.amountMsg4 = '';
    this.amountMsg5 = '';


    // New updation start
    if(amount == 'donate_social_cause100_amount' && e.target.id == 'deduction1'){
      this.removeAllDonate_80gs100();
      this.formGp.get('fund_type100')?.setValue(null);
      this.formGp.get('fund_type100')?.clearValidators();
      this.formGp.get('fund_type100')?.updateValueAndValidity();
    }
    if(amount == 'donate_social_cause100_amount' && e.target.id == 'deduction2'){
      this.addDonate_80g100();
    }
    if(amount == 'donate_social_cause50_amount' && e.target.id == 'deduction1'){
        this.removeAllDonate_80gs50();
        this.formGp.get('fund_type50')?.setValue(null);
        this.formGp.get('fund_type50')?.clearValidators();
        this.formGp.get('fund_type50')?.updateValueAndValidity();
      }
      if(amount == 'donate_social_cause50_amount' && e.target.id == 'deduction2'){
        this.addDonate_80g50();
      }
    // New udation end


    this.onDeductionValidation.emit({check:true,checkCn:check,amountCn:amount});
  }

  /********* VALIDATION FOR 80G FUNCTION ****************/
  validateDeductionAmt(){
    // if((this.formGp.value.cash_other100 == true) && (this.formGp.value.donate_social_cause100_amount > 2000)){
    //   this.experianceValidator5 = true;
    //   this.amountMsg5 = this.messageService.fieldlengthvalidation('amount','2,000');;
    // }else{
    //   this.experianceValidator5 = false;
    //   this.amountMsg5 = '';
    // }
    // return this.experianceValidator5;
  }

  /********* VALIDATION FOR 80U FUNCTION ****************/
  validSelfsuffering() {
  // if((this.formGp.value.self_disabled_severely == true) && (this.formGp.value.self_suffering_amount > 75000)){
  //     this.experianceValidator3 = true;
  //     this.amountMsg3 = this.messageService.fieldlengthvalidation('amount','75,000');;
  //   }else if((this.formGp.value.self_disabled_severely == false) && (this.formGp.value.self_suffering_amount > 125000)){
  //     this.experianceValidator3 = true;
  //     this.amountMsg3 = this.messageService.fieldlengthvalidation('amount','1,25,000');;
  //   }else{
  //     this.experianceValidator3 = false;
  //     this.amountMsg3 = '';
  //   }
  //   return this.experianceValidator3;
  }

  /********* VALIDATION FOR 80 DD FUNCTION ****************/
  validHandicappedAmt(){
    // this.experianceValidator1 = false;
    // this.amountMsg1 = '';
    // if((this.formGp.value.disabled_severely == true) && (this.formGp.value.handicapped_dependent_amount > 75000) ){
    //   this.experianceValidator1 = true;
    //   this.amountMsg1 = this.messageService.fieldlengthvalidation('amount','75,000');;
    // }else if((this.formGp.value.disabled_severely == false) && (this.formGp.value.handicapped_dependent_amount > 125000)){
    //   this.experianceValidator1 = true;
    //   this.amountMsg1 = this.messageService.fieldlengthvalidation('amount','1,25,000');;
    // }else{
    //   this.experianceValidator1 = false;
    //   this.amountMsg1 = '';
    // }
    // return this.experianceValidator1;
  }

  /********* VALIDATION FOR 80DDB FUNCTION ****************/
  validExpenditure(){
    // this.experianceValidator2 = false;
    // this.amountMsg2 = '';
    // if((this.formGp.value.year_60_more == true) && (this.formGp.value.medical_expenditure_amount > 40000) ){
    //   this.experianceValidator2 = true;
    //   this.amountMsg2 = this.messageService.fieldlengthvalidation('amount','40,000');;
    // }else if((this.formGp.value.year_60_more == false) && (this.formGp.value.medical_expenditure_amount > 100000) ){
    //   this.experianceValidator2 = true;
    //   this.amountMsg2 = this.messageService.fieldlengthvalidation('amount','1,00,000');;
    // }else{
    //   this.experianceValidator2 = false;
    //   this.amountMsg2 = '';
    // }
    // return this.experianceValidator2;
  }

  /********* VALIDATION FOR 80GGA FUNCTION ****************/
  validDonation() {
    // if((this.formGp.value.cash_other_80gga == true) && (this.formGp.value.total_amount > 10000)){
    //     this.experianceValidator4 = true;
    //     this.amountMsg4 = this.messageService.fieldlengthvalidation('amount','10,000');;
    //   }else{
    //     this.experianceValidator4 = false;
    //     this.amountMsg4 = '';
    //   }
    //   return this.experianceValidator4;
  }

  /********* VALIDATION FOR FORM FUNCTION ****************/
  validateDeductions(btnText:any){

    if(btnText == 'skip'){
      this.skipDeductionFunction();
    }
    else{
      this.submitted = true;

      if (this.formGp.invalid) {
        return;
      }
    }
    this.submitmethod.emit();
  }

  /********* CHECKBOX VALIDATION FUNCTION ****************/
  checkBoxFunctionTest(e:any, amountName:any,radioName: any, dropDown:any) {
    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    if(e.target.checked){

        this.formGp.get(checkControlName)?.setValue(true);
        this.onDeductionValidation.emit({check:true,checkCn:checkControlName,amountCn:amountControlName});

        if(checkControlName == 'gga80' && this.formGp.value.deduction_80gga.length == 0){
          this.addDeduction_80gga();
        }

        if(checkControlName == 'donate_social_cause100' && this.formGp.value.donate_80g100.length == 0){
          this.addDonate_80g100();
        }
        if(checkControlName == 'donate_social_cause_50' && this.formGp.value.donate_80g50.length == 0){
          this.addDonate_80g50();
        }

    }else{
      if(checkControlName == 'donate_social_cause100'){
        this.removeAllDonate_80gs100();
        this.formGp.get('fund_type100')?.clearValidators();
        this.formGp.get('fund_type100')?.updateValueAndValidity();
      }
      if(checkControlName == 'donate_social_cause_50'){
        this.removeAllDonate_80gs50();
        this.formGp.get('fund_type50')?.clearValidators();
        this.formGp.get('fund_type50')?.updateValueAndValidity();
      }
      if(checkControlName == 'gga80')
        this.removeAllDeduction_80ggas();

      if(checkControlName == 'interest_home_loan_80ee'){
        this.formGp.get('homeloan80ee_option1')?.setValue(false);
        this.formGp.get('homeloan80ee_option2')?.setValue(false);
        this.formGp.get('homeloan80ee_option3')?.setValue(false);
        this.formGp.get('homeloan80ee_option4')?.setValue(false);
      }
      if(checkControlName == 'interest_home_loan_80eea'){
        this.formGp.get('homeloan80eea_option1')?.setValue(false);
        this.formGp.get('homeloan80eea_option2')?.setValue(false);
        this.formGp.get('homeloan80eea_option3')?.setValue(false);
      }

      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(radioName)?.setValue(true);
      this.formGp.get(dropDown)?.setValue(null);
      this.formGp.get(amountControlName)?.setValue('');
      this.onDeductionValidation.emit({check:true,checkCn:checkControlName,amountCn:amountControlName});

    }
  }

  /********* RADIO BUTTON CHANGE FUNCTION ****************/
  dedutionRadio(e:any) {
    var checkValue  = e.target.value;

    this.removeAllDeduction_80ggas();

    if(this.formGp.value.deduction_80gga.length == 0)
      this.addDeduction_80gga();

    if(checkValue == 'on'){
      this.formGp.get('total_amount')?.setValue('');
       this.onDeductionValidation.emit({check:true,checkCn:'cash_other_80gga',amountCn:'total_amount'});
    }
  }

  /********* SET VALUE FUNCTION ****************/
  setValue(chekBox: any, amount: any){
    if( this.formGp.get(chekBox)?.value == false){
      this.formGp.get(amount)?.setValue(0);
    }else{
      this.formGp.get(amount)?.setValue(this.formGp.get(amount)?.value);
    }

  }

  donate_80g() : FormArray {
    return this.formGp.get("donate_80g") as FormArray
  }
  /********* donate_80g FORM ARRAY FUNCTIONs ****************/
  donate_80gs100() : FormArray {
    return this.formGp.get("donate_80g100") as FormArray
  }

  newDonate_80g100(): FormGroup {
    if(this.formGp.value.donate_social_cause100 == true && this.formGp.value.cash_other100 == true){
      this.formGp.get('fund_type100')?.setValidators([Validators.required]);
      this.formGp.get('fund_type100')?.updateValueAndValidity();
      return this.fb.group({
        "exemption"               : true,
        "name"                    : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"                     : [null,[Validators.required,PanfieldValidator()]],
        "address"                 : [null,[Validators.required,Validators.maxLength(500)]],
        "payment_mode"            : [null,[Validators.required]],
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator,Validators.maxLength(18)]],
        "payment_date"            : [null,[Validators.required]]
      })
    }else{
      this.formGp.get('fund_type100')?.clearValidators();
      this.formGp.get('fund_type100')?.updateValueAndValidity();
      return this.fb.group({
        "exemption"               : null,
        "name"                    : [null],
        "pan"                     : [null],
        "address"                 : [null],
        "payment_mode"            : null,
        "check_no_transaction_id" : null,
        "payment_date"            : null
      })
    }
  }

  addDonate_80g100() {
    this.donate_80gs100().push(this.newDonate_80g100());
  }

  removeDonate_80gs100(i:number) {
    this.donate_80gs100().removeAt(i);
  }

  removeAllDonate_80gs100(){
    if(this.formGp.value.donate_80g100.length > 0){
      while(this.formGp.value.donate_80g100.length !== 0){
        this.donate_80gs100().removeAt(0);
      }
    }
  }

  /********* donate_80g FORM ARRAY FUNCTIONs ****************/
  donate_80gs50() : FormArray {
    return this.formGp.get("donate_80g50") as FormArray
  }

  newDonate_80g50(): FormGroup {
    if(this.formGp.value.donate_social_cause_50 == true && this.formGp.value.cash_other50 == true){
      this.formGp.get('fund_type50')?.setValidators([Validators.required]);
      this.formGp.get('fund_type50')?.updateValueAndValidity();
      return this.fb.group({
        "exemption" : false,
        "name"                    : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"                     : [null,[Validators.required,PanfieldValidator()]],
        "address"                 : [null,[Validators.required,Validators.maxLength(500)]],
        "payment_mode"            : [null,[Validators.required]],
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator,Validators.maxLength(18)]],
        "payment_date"            : [null,[Validators.required]]
      })
    }else{
      this.formGp.get('fund_type50')?.clearValidators();
      this.formGp.get('fund_type50')?.updateValueAndValidity();
      return this.fb.group({
        "exemption" : null,
        "name"                    : [null],
        "pan"                     : [null],
        "address"                 : [null],
        "payment_mode"            : null,
        "check_no_transaction_id" : null,
        "payment_date"            : null
      })
    }
  }

  addDonate_80g50() {
    this.donate_80gs50().push(this.newDonate_80g50());
  }

  removeDonate_80gs50(i:number) {
    this.donate_80gs50().removeAt(i);
  }

  removeAllDonate_80gs50(){
    if(this.formGp.value.donate_80g50.length > 0){
      while(this.formGp.value.donate_80g50.length !== 0){
        this.donate_80gs50().removeAt(0);
      }
    }
  }

  removeAllDonate_80gs(){
    if(this.formGp.value.donate_80g.length > 0){
      while(this.formGp.value.donate_80g.length !== 0){
        this.donate_80g().removeAt(0);
      }
    }
  }


  /********* deduction_80gga FORM ARRAY FUNCTIONs ****************/
  deduction_80ggas() : FormArray {
    return this.formGp.get("deduction_80gga") as FormArray
  }

  newDeduction_80gga(): FormGroup {
    if(this.formGp.value.gga80 == true && this.formGp.value.cash_other_80gga == false){
      return this.fb.group({
        "check_no_transaction_id" : [null,[Validators.required,GstnumValidator(),Validators.maxLength(18)]],
        "date"                    : [null,Validators.required],
      })
    }else{
      return this.fb.group({
        "check_no_transaction_id": [null],
        "date"                   : null,
      })
    }
  }

  addDeduction_80gga() {
    this.deduction_80ggas().push(this.newDeduction_80gga());
  }

  removeDeduction_80ggas(i:number) {
    this.deduction_80ggas().removeAt(i);
  }

  removeAllDeduction_80ggas(){
    if(this.formGp.value.deduction_80gga.length > 0){
      while(this.formGp.value.deduction_80gga.length !== 0){
        this.deduction_80ggas().removeAt(0);
      }
    }

  }

  dataClearFunction(e:any,amountControlName:any){
    this.formGp.get(amountControlName)?.setValue('');
    if(e.target.id == 'deduction1')
      this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(100000),Validators.maxLength(15)]);
    else
      this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(5000),Validators.maxLength(15)]);
    this.formGp.get(amountControlName)?.updateValueAndValidity();
  }

  amountValidation(checkControlName:any,amountControlName:any ){
    // if(this.formGp.get(checkControlName)?.value == true)
    //   this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(100000)]);
    //   else
    //     this.formGp.get(amountControlName)?.setValidators([Validators.required,AmountValidator(),Validators.max(5000)]);
    //   this.formGp.get(amountControlName)?.updateValueAndValidity();
  }

  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  totalDeclaredFunction(){

    this.totalDeclaredAmount = Number(this.formGp.value.employee_contribution_amount) + Number(this.formGp.getRawValue().employer_contribution_amount) + Number(this.formGp.value.tta_ttb_amount) + Number(this.formGp.value.interest_education_amount) + Number(this.formGp.value.handicapped_dependent_amount) + Number(this.formGp.value.medical_expenditure_amount) + Number(this.formGp.value.self_suffering_amount) + Number(this.formGp.value.royalty_patent_amount) + Number(this.formGp.value.contribution_policalparties_amount) + Number(this.formGp.value.total_amount) + Number(this.formGp.value.health_insurance_selfspouse80d_amount) + Number(this.formGp.value.health_insurance_parents80d_amount) + Number(this.formGp.value.health_checkup_selfspouse80d_amount) + Number(this.formGp.value.health_checkup_parents80d_amount) + Number(this.formGp.value.medicalbill_senior80d_amount) + Number(this.formGp.value.interest_home_loan_amount80eea) + Number(this.formGp.value.interest_electrical_vehicle_loan_amount) + Number(this.formGp.value.interest_home_loan_amount80ee) + Number(this.formGp.value.donate_social_cause100_amount) + Number(this.formGp.value.donate_social_cause50_amount);

    return this.totalDeclaredAmount;
  }

  skipDeductionFunction(){
    Object.keys(this.formGp.controls).forEach(key => {
      if(key != 'employer_contribution' && key != 'employer_contribution_amount' && key != 'donate_80g' && key != 'deduction_80gga' && key != 'donate_80g100' && key != 'donate_80g50'){

      //   if(key == 'employee_additional_contribution' || key == 'employer_contribution' || key == 'tta_ttb' || key == 'interest_education_loan' || key == 'medical_treatment_handicapped' || key == 'disabled_severely' || key == 'medical_expenditure' || key == 'year_60_more' || key == 'self_suffering_disability' || key == 'self_disabled_severely' || key == 'royalty_patent' || key == 'contribution_individual_policalparties' || key == 'gga80' || key == 'cash_other_80gga' || 'health_insurance_selfspouse80d' || key == 'health_insurance_selfspouse80d_age' || key == 'health_insurance_parents80d' || key == 'health_insurance_parents80d_age' || key == 'health_checkup_selfspouse80d' || key == 'health_checkup_parents80d' || key == 'medicalbill_senior80d' || key == 'interest_home_loan_80eea' || key == 'homeloan80eea_option1' || key == 'homeloan80eea_option2' || key == 'homeloan80eea_option3' || key == 'interest_electrical_vehicle_loan_80eeb' || key == 'interest_home_loan_80ee' || key == 'homeloan80ee_option1' || 'homeloan80ee_option2' || key == 'homeloan80ee_option3' || key == 'homeloan80ee_option4' || key == 'donate_social_cause100' || key == 'cash_other100' || key == 'donate_social_cause_50' || key == 'cash_other50' ){
        if(this.formGp.controls[key].value == true){

          this.formGp.controls[key].setValue(false);
          this.formGp.get('issue')?.setValue(null);

        }
        else if((this.formGp.controls[key].value != true) && (this.formGp.controls[key].value != false)){
          this.formGp.controls[key].reset('');
        }
        if(key == 'health_insurance_selfspouse80d_age'){
          this.formGp.get('health_insurance_selfspouse80d_age')?.setValue(true);
        }
        if( key == 'health_insurance_parents80d_age'){
          this.formGp.get('health_insurance_parents80d_age')?.setValue(true);
        }
        if( key == 'disabled_severely'){
          this.formGp.get('disabled_severely')?.setValue(true);
        }
        if( key == 'year_60_more'){
          this.formGp.get('year_60_more')?.setValue(true);
        }
        if(key == 'self_disabled_severely'){
          this.formGp.get('self_disabled_severely')?.setValue(true);
        }

      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'deduction_80gga'){
        this.removeAllDeduction_80ggas();
        this.formGp.get('cash_other_80gga')?.setValue(false);
      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'donate_80g100'){
        this.removeAllDonate_80gs100();
        this.formGp.get('cash_other100')?.setValue(true);
        this.formGp.get('fund_type100')?.setValue(null);
      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'donate_80g50'){
        this.removeAllDonate_80gs50();
        this.formGp.get('cash_other50')?.setValue(true);
        this.formGp.get('fund_type50')?.setValue(null);
      }
      else if((key != 'employer_contribution' && key != 'employer_contribution_amount') && key == 'donate_80g'){
        this.removeAllDonate_80gs();
      }

      else if(key != 'employer_contribution' && key != 'employer_contribution_amount' && key != 'donate_80g' && key != 'deduction_80gga' && key != 'donate_80g100' && key != 'donate_80g50' && (this.formGp.controls[key].value != true) && (this.formGp.controls[key].value != false)){
        this.formGp.controls[key].reset('');
      }
      this.clearValidationFunction(key)
      this.invetData();
    });
  }

  clearValidationFunction(key:any){
    this.formGp.get(key)?.clearValidators();
    this.formGp.get(key)?.updateValueAndValidity();
  }

  invetData(){
    this.formGp.controls.employer_contribution.disable();
    this.formGp.controls.employer_contribution_amount.disable();

    this.setValue('employer_contribution','employer_contribution_amount');
    if(this.from=='behalf'){
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
        this.setInvestmentData(res)
      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
          this.setInvestmentData(res)
      });
    }
  }
  setInvestmentData(res:any){
    if(res.count_info.hasOwnProperty('assigned_salary_data')){
      let npsPercentage = Number(this.limitData.nps_employer_percentage);
        if((res.count_info.proposed_regime == null && res.count_info.assigned_salary_data.tax_regime == true) || (res.count_info.proposed_regime == 'new')){
          npsPercentage = Number(this.limitData.newregima_nps_employer_percentage);
        }
      this.empSalary    = res.count_info.assigned_salary_data.salary;
      this.empSalary    = Number(this.empSalary)*(npsPercentage/100);
      this.empSalary    = parseFloat(this.empSalary).toFixed(2);
      if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){
          let nps_amount_total = Number(res.count_info.assigned_salary_data.nps_amount + res.count_info.assigned_salary_data.prev_employer_nps_deduction)
          if(nps_amount_total>0){
            // condition added for previous employer nps scenario
            this.formGp.get('employer_contribution')?.setValue(true);
          }else{
            this.formGp.get('employer_contribution')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
          }
          this.formGp.get('employer_contribution_amount')?.setValue(nps_amount_total);
        }
      }
    if(res.count_info.hasOwnProperty('employee_data')){
      this.employeeAge = res.count_info.employee_data.age;
    }
  }
  /********* FUND TYPE 100 && 50 DROPDOWN FUNCTIONs ****************/
  getDonation100DropDownData(){
    this.investmentService.donation100DropDown(this.currentYear).subscribe((res:any) =>{
      if(res)
       this.donation100DropDownData = res;
    })
  }
  getDonation50DropDownData(){
    this.investmentService.donation50DropDown(this.currentYear).subscribe((res:any) =>{
      if(res)
       this.donation50DropDownData = res;
    })
  }

}
