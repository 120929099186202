<div class="row row-16">
  <ng-container *ngIf="viewData?.new_assets?.length > 0">
      <div class="col-12 d-flex">
        <p class="text-uppercase fw-500 mb-0">New asset</p>
      </div>
      <ng-container *ngFor="let newAsset of viewData?.new_assets; let i = index">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">{{i+1}}. New Asset</p>
                  <div class="d-flex align-items-center ms-auto gap-8">
                    <span class="badge px-16  fs-14 {{newAsset?.status == 'Pending' ? 'badge-warning' : newAsset?.status == 'Accepted' ? 'badge-success' : ' badge-danger'}} py-1">{{newAsset?.status}}</span>
                    <span class="text-light-400">|</span>
                    <span class="link-primary fs-14" (click)="viewStatus(newAsset?.id)">View Status</span>
                  </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Category</div>
                  <div class="detail-desc">{{newAsset?.category_name}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Subcategory</div>
                  <div class="detail-desc">{{newAsset?.sub_category_name}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Requested on</div>
                  <div class="detail-desc">{{this.appService.dateFormatDisplay(viewData?.created_at)}}</div>
                </div>
                <div class=" col-12">
                  <div class="detail-title">Reason</div>
                  <div class="detail-desc">{{newAsset?.asset_reason}}</div>
                </div>
              </div>
            </div>
          </div>
      </ng-container>
  </ng-container>

  <ng-container *ngIf="viewData?.return_assets?.length > 0">
      <div class="col-12 d-flex">
        <p class="text-uppercase fw-500 mb-0">Return asset</p>
      </div>
      <ng-container *ngFor="let returnAsset of viewData?.return_assets; let j = index">
        <div class="col-12">
          <div class="card details-card shadow-0 px-24 pb-24 pt-16">
            <div class="row row-16 ">
              <div class="col-12 align-items-center d-flex">
                <p class="title mb-8">{{j+1}}. Return Asset</p>
                <div class="d-flex align-items-center ms-auto gap-8">
                  <span class="badge px-16  fs-14 {{returnAsset?.status == 'Pending' ? 'badge-warning' : returnAsset?.status == 'Accepted' ? 'badge-success' : returnAsset?.status == 'Invalid' ? 'badge-danger1' : ' badge-danger'}} py-1">{{returnAsset?.status }}</span>
                  <span class="text-light-400">|</span>
                  <span class="link-primary fs-14" (click)="viewStatus(returnAsset?.id)">View Status</span>
                </div>
              </div>
              <div class="col-sm-4 col-12">
                <div class="detail-title">Product name</div>
                <div class="detail-desc">{{returnAsset?.product_name}}</div>
              </div>
              <div class="col-sm-4 col-12">
                <div class="detail-title">Category</div>
                <div class="detail-desc">{{returnAsset?.category_name}}</div>
              </div>
              <div class="col-sm-4 col-12">
                <div class="detail-title">Subcategory</div>
                <div class="detail-desc">{{returnAsset?.sub_category_name}}</div>
              </div>
              <div class=" col-4">
                <div class="detail-title">Assigned on</div>
                <div class="detail-desc">{{this.appService.dateFormatDisplay(returnAsset?.assigned_date)}}</div>
              </div>
              <div class=" col-4">
                <div class="detail-title">Assigned by</div>
                <!-- <div class="detail-desc">{{returnAsset?.assigned_by}} </div> -->
                <div class="detail-desc align-items-baseline">
                  <img *ngIf="(returnAsset?.assigned_by !=null && (returnAsset?.assigned_by?.profile_image != '' && returnAsset?.assigned_by?.profile_image != null))"
                      class="user-img img-fluid rounded-circle sq-32 "
                      src="{{returnAsset?.assigned_by?.profile_image}}">
                  <div *ngIf="(returnAsset?.assigned_by !=null && (returnAsset?.assigned_by?.profile_image == '' || returnAsset?.assigned_by?.profile_image == null))"
                      class="avatar-circle  fs-14  fw-500 {{returnAsset?.assigned_by?.color_code}} sq-32 rounded-circle">
                      <span>{{
                        this.appService.getFirstChar(
                          returnAsset?.assigned_by?.first_name +
                            "
                        " +
                        returnAsset?.assigned_by?.last_name,
                          2
                        )
                      }}</span>
                  </div>
                  <div class="detail-desc p-2"
                      *ngIf="returnAsset?.assigned_by !=null && returnAsset?.assigned_by !='';else other_content">
                      {{(returnAsset?.assigned_by?.first_name+"
                      "+(returnAsset?.assigned_by?.middle_name
                      !=null?returnAsset?.assigned_by?.middle_name:'')+"
                      "+returnAsset?.assigned_by?.last_name)}}
                  </div>
                  <ng-template #other_content>
                      <div class="detail-desc p-2"> -
                      </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
  </ng-container>
</div>


<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Activities</h5>
            <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData" [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>

    </form>
  </div>
</ng-container>
