<div class="container-fluid p-24 "  *ngIf="permissions">
    <div class="row mb-16 ">
        <div class="col-md-4 col-lg-6">
            <h3>Designation</h3>
            <p class="text-helper mb-md-0">Create various designation profiles that exist in your company.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16 ">
                <div class="" style="width:22.6875rem;">
                    <div class="">
                        <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='designationPlaceholder' (searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchDesig($event)" [isLoading]="isLoading"></app-searchbar>
                    </div>
                    <ul class="drop-down-menu search-result {{(inputFocusClass == true) ? 'active' : ''}}">
                        <p class="note"> No Result msg </p>
                        <p class="text-center text-helper mt-1">No Company Found...</p>
                        <p class="note"> Loader </p>
                        <p class="loader text-center">
                            <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                        </p>
                        <p class="note"> Search Results</p>
                    </ul>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13 "></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                        <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';statusfilter('')">All</button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';statusfilter(true)">Active </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                    </div>
                </div>

                <button  *ngIf="permissions.a"  class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addDesignation($event)"><i
                        class="icon-plus"></i>Add Designation
                </button>
            </div>
        </div>
    </div>

     <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16 " *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <!--No data found-->
    <div *ngIf="nodatafound && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg12.png'" [noDataText]="'Designations are not configured yet.'"
        [noDataPara]="'Click \'Add Designation\' to build a comprehensive list of all company designations for streamlined management.'"
          [hasPermission]="false">
      </app-nodata-view>
    </div>
    <!--End-->
    <ng-template #all_data><div class="row row-16  "  infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [fromRoot]="true"
            (scrolled)="onScrollDown()">
        <ng-container  *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 ffff"
                *ngFor="let designation of designationList let i = index">
                <div class="card card-custom1 card-hover" >
                    <div class="card-body d-flex p-0 align-items-start">
                       <div class="d-flex view-section"(click)="viewDesignation(designation?.id)">
                          <div class="logo-img {{designation?.color_code}}" ng-init="getRandomColor()">{{this.appService.getFirstChar(designation?.name,2)}}</div>
                          <div class="overflow-hidden">
                              <h6 class="mb-0 tips">{{designation?.name}}</h6>
                              <span class="tips-helper">{{designation?.name}}</span>
                              <span
                                  class="fs-10 text-uppercase fw-500 {{(designation?.is_active == true) ? 'text-success' : 'text-danger'}}">{{(designation?.is_active
                                  == true) ? 'Active' : 'Deleted'}}</span>
                          </div>
                        </div>
                      <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="designation?.is_active && (permissions.e || permissions.d)">
                          <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                              ngbDropdownToggle>
                              <i class="icon-three-dots-vertical "></i>
                          </button>
                          <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1" >
                              <button *ngIf="permissions.e" ngbDropdownItem (click)="editDesignation(designation.id)">
                                  <i   class="icon-pencil-square me-1"></i> Edit
                              </button>
                              <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)=" deletedesig(designation.id)">
                                  <i class="icon-trash  me-1"></i> Delete
                              </button>
                          </div>
                      </div>
                     </div>
                    </div>
            </div>
        </ng-container>

         <!-- Loader updations -->
         <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>

        </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->

</div>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Details</h5>
          <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg "></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid px-32  py-24 ">
            <!-- View loader start -->
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <!-- View loader end -->
            <ng-container *ngIf="!loader1">
              <div class="d-flex mb-16 ">
                  <p class="text-uppercase fw-500 mb-0"> Designation</p>
                  <a class="link-primary fs-14  d-flex align-items-center ms-auto" (click)="editDesignation(designation_id)" *ngIf="desig_is_active==true && permissions.e">
                      <i   class="icon-pencil-square me-1"></i> Edit
                  </a>
              </div>
              <div class="row row-16  mb-16 ">
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24  py-16 ">
                          <div class="row row-24 " >
                              <div class="col-12 "><span class="title">Designation DETAILS</span></div>

                              <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Designation name </div>
                                  <div class="detail-desc">{{designation_name}}</div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                <div class="detail-title">Status</div>
                                <div class="detail-desc">
                                  <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="desig_is_active">ACTIVE</span>
                                  <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="desig_is_active==false">DELETED</span>
                                </div>
                             </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Min. experience</div>
                                  <div class="detail-desc">{{designation_min_experience}}</div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Max. experience</div>
                                  <div class="detail-desc">{{designation_max_experience}}</div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Min annual salary</div>
                                  <div class="detail-desc">{{designation_min_annual_salary}}</div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Max annual salary</div>
                                  <div class="detail-desc">{{designation_max_annual_salary}}</div>
                              </div>


                          </div>
                      </div>
                  </div>

              </div>
            </ng-container>
          </div>
      </div>
  </form>
</div>

<!--side panel-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
    <form class="side-panel-container" [formGroup]="designationForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <header class="side-panel-head">
            <h5>{{panelheader}} Designation</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32  py-24 ">
              <!-- Edit loader start -->
              <app-edit-loader *ngIf="loader1" ></app-edit-loader>
              <!-- Edit loader end -->
              <ng-container *ngIf="!loader1" >
                <!--Alert msg section-->
                <div class="alert alert-success show" role="alert" *ngIf="isAlert==true">
                    Designation {{msgText}} successfully.
                   <button class="btn-close" ></button>
                 </div>
                 <div class="alert alert-warning show" role="alert" *ngIf="isAlert==true">
                   Something went to wrong.
                   <button class="btn-close" ></button>
                 </div>
                 <div class="alert alert-danger show" role="alert" *ngIf="isAlert==true">
                    You should check in on some of those fields below.
                   <button class="btn-close" ></button>
                 </div>
                <!--End-->
                <p class="text-uppercase fw-500 mb-12 ">{{panelheader | uppercase}} DESIGNATION DETAILS</p>
                <div class="row row-12  ">
                    <input type="text" value="{{dataId}}" formControlName="id" id="id" hidden>
                    <div class="col-12 col-md-6 form-row ">
                        <label for="name" class="form-label required">Designation name </label>
                        <input type="text" class="form-control" formControlName="name" name="" id="name" value=""
                            placeholder="{{this.messageService.placeholderdisp('designation name')}}"
                            [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
                        <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                            <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                            <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 form-row ">
                        <label for="min_experience" class="form-label">Min. experience </label>
                        <div class="form-icon icon-right " [ngClass]="{ 'is-invalid': (submitted || f.min_experience.dirty) && f.min_experience.errors}">
                            <input type="text" class="form-control" name="min_experience" id="min_experience" value=""  (keyup)="validExperiance()"  formControlName="min_experience" placeholder="{{this.messageService.placeholderdisp('min. experience')}}"
                                [ngClass]="{ 'is-invalid': (submitted || f.min_experience.dirty) && f.min_experience.errors}">
                            <span class="text-light-400 fs-14 ">years</span>

                        </div>
                        <div *ngIf="(submitted || f.min_experience.dirty) && f.min_experience.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.min_experience.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                          <div *ngIf="!(f.min_experience.errors.pattern) && f.min_experience?.errors?.max">{{this.messageService.fieldlengthvalidation('years','100')}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 form-row">
                        <label for="max_experience" class="form-label">Max. experience </label>
                        <div class="form-icon icon-right " [ngClass]="{ 'is-invalid': (((submitted || f.max_experience.dirty || f.max_experience.touched || isdelete) && f.max_experience.errors)||(experianceValidator==true && (submitted || f.max_experience.dirty || f.max_experience.touched || isdelete) ))}">
                            <input type="text" class="form-control" name="" id="max_experience" value="" (keyup)="validExperiance()"
                                formControlName="max_experience" placeholder="{{this.messageService.placeholderdisp('max. experience')}}"
                                [ngClass]="{ 'is-invalid': (((submitted || f.max_experience.dirty || f.max_experience.touched || isdelete) && f.max_experience.errors)||(experianceValidator==true && (submitted || f.max_experience.dirty || f.max_experience.touched || isdelete) ))}">
                            <span class="text-light-400 fs-14 ">years</span>
                        </div>
                        <div *ngIf="(submitted || f.max_experience.dirty) && f.max_experience.errors"
                                class="invalid-feedback">
                            <div *ngIf="f.max_experience.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.max_experience.errors.pattern) && f.max_experience?.errors?.max">{{this.messageService.fieldlengthvalidation('years','100')}}</div>

                        </div>
                        <div *ngIf="(!(f.max_experience?.errors?.pattern) && !(f.max_experience?.errors?.max)) && experianceValidator==true && (f.max_experience.dirty || f.max_experience.touched || isdelete)"  class="invalid-feedback">

                            <div *ngIf="experianceValidator==true">Max experiance should be greater than Min.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 form-row">
                        <label for="min_annual_salary" class="form-label">Min annual salary </label>
                        <input type="text" class="form-control" name="" id="min_annual_salary" value=""
                            formControlName="min_annual_salary" placeholder="{{this.messageService.placeholderdisp('min. annual salary')}}" (keyup)="validSalary()"
                            [ngClass]="{ 'is-invalid': (submitted || f.min_annual_salary.dirty) && f.min_annual_salary.errors }" >
                        <div *ngIf="(submitted || f.min_annual_salary.dirty) && f.min_annual_salary.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.min_annual_salary.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.min_annual_salary.errors.pattern) && f.min_annual_salary.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 form-row">
                        <label for="max_annual_salary" class="form-label">Max. annual salary </label>
                        <input type="text" class="form-control" name="" id="max_annual_salary" value="" (keyup)="validSalary()"
                            formControlName="max_annual_salary" placeholder="{{this.messageService.placeholderdisp('max. annual salary')}}"
                            [ngClass]="{ 'is-invalid': (((submitted || f.max_annual_salary.dirty || f.max_annual_salary.touched || isdelete) && f.max_annual_salary.errors)||(salaryValidator==true &&  (submitted || f.max_annual_salary.dirty || f.max_annual_salary.touched || isdelete)))  }">
                        <div *ngIf="(submitted || f.max_annual_salary.dirty || f.max_annual_salary.touched || isdelete) && f.max_annual_salary.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.max_annual_salary.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.max_annual_salary.errors.pattern) && f.max_annual_salary.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                        </div>
                        <div *ngIf="(!(f.max_annual_salary.errors?.pattern) && !(f.max_annual_salary.errors?.maxlength)) && salaryValidator==true && (submitted==true || isdelete || f.max_annual_salary.dirty)"  class="invalid-feedback">

                            <div *ngIf="salaryValidator==true">Max annual salary should be greater than Min.</div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
        <footer class="side-panel-footer"  *ngIf="permissions && !loader1">
            <button *ngIf="isdelete==true && permissions.d" class="btn btn-outline-danger text-uppercase btn-sm "
                (click)="deleteToggle = true" type="button" [disabled]='!status'>DELETE</button>
            <button *ngIf="(permissions.a && buttonTitle=='ADD') || (permissions.e && buttonTitle=='SAVE')" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='!status || !designationForm.valid ' >Save</button>
        </footer>
    </form>
</div>
<!--Delete confirmation box-->
  <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
              <div class="modal-header gap-16 ">
                  <i class="bi bi-exclamation-triangle text-danger"></i>
                  <h4 class="modal-title">Delete Designation?</h4>
              </div>
              <div class="modal-body">
                  <p class="mb-0">{{this.messageService.Deletemsgdisplay('Designation')}}</p>
              </div>
              <div class="modal-footer"  *ngIf="permissions">
                  <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                      (click)="deleteToggle = false">Cancel</button>
                  <button *ngIf="permissions.d"  type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteDesignation()">Delete</button>
              </div>
          </div>
      </div>
  </div>
