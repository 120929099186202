<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Holiday POLICY DETAILS</p>
        </div>
        <div class="col-12 pb-10" *ngIf="formGp.value.is_requested">
            <ng-container>
                <div role="alert" class="alert alert-warning show m-0">
                    <p class="mb-0"><span class="text-warning">Note:</span> Holiday policy you are trying to edit is
                        mapped with various employees. You cannot edit this holiday policy.</p>
                </div>
            </ng-container>
        </div>
        <div class="row row-16">
            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Holiday policy name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a name for the holiday policy.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" formControlName="holiday_policy_name" class="form-control "
                    placeholder="{{this.messageService.placeholderdisp('Holiday Policy Name')}}"
                    [ngClass]="{ 'is-invalid': (submitted || f.holiday_policy_name.touched || f.holiday_policy_name.dirty) &&  f.holiday_policy_name.errors || f.holiday_policy_name.errors?.exist}"
                    (keyup)="keyUp()">
                <div *ngIf="(submitted || f.holiday_policy_name.touched || f.holiday_policy_name.dirty) && f.holiday_policy_name.errors || f.holiday_policy_name.errors?.exist"
                    class="invalid-feedback">
                    <div *ngIf="f.holiday_policy_name.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.holiday_policy_name.errors?.pattern && f.holiday_policy_name.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    <div *ngIf="f.holiday_policy_name.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="f.holiday_policy_name.hasError('exist')">
                        {{this.messageService.validationDisplay(f.holiday_policy_name.value)}}</div>
                </div>
            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Holiday policy year</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select from which year this policy will be effective.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <ng-select formControlName="year" class="form-ngselect" [clearable]="false"
                    placeholder="{{this.messageService.selectplaceholddisplay('Holiday Policy Year')}}"
                    (change)="changeYear($event);keyUp()"
                    [ngClass]="{ 'is-invalid': (submitted || f.year.touched || f.year.dirty) &&  f.year.errors}">
                    <ng-option *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
                </ng-select>
                <div *ngIf="(submitted || f.year.touched || f.year.dirty) && f.year.errors" class="invalid-feedback">
                    <div *ngIf="f.year.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>

            <!-- design change  -->

            <div class=" col-12 form-row ">
                <label for="" class="form-label">Is floating holiday applicable?</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select Yes to enable floating holidays in this holiday policy.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row  mt-0">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [value]="true" id="is_floating_holiday"
                        name="is_floating_holiday" formControlName="is_floating_holiday"
                        (ngModelChange)="setRequired(true,'max_floating_request')">
                    <label class="form-check-label" for="is_floating_holiday">
                        Yes
                    </label>

                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [value]="false" id="is_not_floating_holiday"
                        name="is_floating_holiday" formControlName="is_floating_holiday"
                        (ngModelChange)="setRequired(false,'max_floating_request')">
                    <label class="form-check-label" for="is_not_floating_holiday">
                        No
                    </label>
                </div>
            </div>
            <!-- end -->
            <div class="col-lg-6 form-row" *ngIf=" f.is_floating_holiday.value==true">
                <label for="" class="form-label required">Maximum floating request count</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify the maximum number of floating holidays an employee can avail in the calendar year.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('count in numbers')}}"
                    formControlName="max_floating_request"
                    [ngClass]="{ 'is-invalid': (submitted || f.max_floating_request.touched || f.max_floating_request.dirty) &&  f.max_floating_request.errors}">
                <div *ngIf="(submitted || f.max_floating_request.touched || f.max_floating_request.dirty) && f.max_floating_request.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.max_floating_request.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.max_floating_request.errors.pattern && f.max_floating_request.errors.maxlength">
                        {{this.messageService.fieldlengthvalidation('num','3')}}</div>
                    <div *ngIf="f.max_floating_request.errors.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="(!f.max_floating_request.errors.pattern) && f.max_floating_request.errors.min">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <!-- design changes -->

            <div class=" col-12 form-row">
                <label for="" class="form-label"> Do you want to prorate floating holidays in case of mid year
                    joiners?</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enable this option If you don't want to provide entire Floating Holidays to employees who joined in the middle of the year." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [value]="true" id="floating_yes"
                        name="prorate_floating_holiday" formControlName="prorate_floating_holiday"
                        (change)="handleChange(true,'from_month');handleChange(true,'to_month');handleChange(true,'count_number')" [attr.disabled]="f.is_floating_holiday.value == false? true : null">
                    <label class="form-check-label" for="floating_yes">
                        Yes
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If the selection is Yes,  define the maximum holiday request count based on calendar months. eg: If you select From as January and To as August, specified count as 4,when an employee joins between January and August will be eligible for 4 floating holidays and employees who joins after August will not be eligible for any floating holidays." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [value]="false" id="floating_no"
                        name="prorate_floating_holiday" formControlName="prorate_floating_holiday"
                        (change)="handleChange(false,'from_month');handleChange(false,'to_month');handleChange(false,'count_number')" [attr.disabled]="f.is_floating_holiday.value == false? true : null">
                    <label class="form-check-label" for="floating_no">
                        No
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If selection is  No, employees will be eligible for full quota of   floating holidays as per the policy irrespective of DOJ." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
            </div>
            <ng-container *ngIf="f.prorate_floating_holiday.value==true" formArrayName="prorated_holiday_policy">
                <ng-container *ngFor="let role of prorated_holiday_policy().controls; let i=index;"
                [formGroupName]="i">
                <div class="col-4">
                    <label for="" class="form-label required">From month </label>
                </div>
                <div class="col-4">
                    <label for="" class="form-label required">To month </label>
                </div>
                <div class="col">
                    <label for="" class="form-label required">Count </label>
                </div>
                <div style="width:2.5rem" *ngIf="i!=0">&nbsp;</div>
                <div class="col-12" >
                    <div class="align-items-start d-flex flex-nowrap row row-16">

                        <div class="col-4 form-row mt-0">

                                <ng-select class="form-ngselect"
                                placeholder="Select month"
                                formControlName="from_month"
                                [ngClass]="{ 'is-invalid': (submitted || prorated_holiday_policy().controls[i].get('from_month')?.touched) &&  prorated_holiday_policy().controls[i].get('from_month')?.errors?.required  || prorated_holiday_policy().controls[i].get('from_month')?.errors?.greater || prorated_holiday_policy().controls[i].get('from_month')?.errors?.overlap}"
                                (ngModelChange)="showValidation(i)">
                                <ng-option *ngFor="let yData of listOfMonth"
                                    [value]="yData.value">{{yData.value}}</ng-option>
                            </ng-select>
                            <div *ngIf="(submitted || prorated_holiday_policy().controls[i].get('from_month')?.touched) && prorated_holiday_policy().controls[i].get('from_month')?.errors?.required  || prorated_holiday_policy().controls[i].get('from_month')?.errors?.greater || prorated_holiday_policy().controls[i].get('from_month')?.errors?.overlap"
                                class="invalid-feedback">
                                <div *ngIf="prorated_holiday_policy().controls[i].get('from_month')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="prorated_holiday_policy().controls[i].get('from_month')?.errors?.greater">{{this.messageService.greaterThanMessage('From month','To month')}}
                                    </div>
                                    <div *ngIf="prorated_holiday_policy().controls[i].get('from_month')?.errors?.overlap">The selected month range should not overlap with previously selected range.
                                    </div>

                            </div>
                        </div>

                        <div class="col-4 form-row mt-0">
                            <ng-select class="form-ngselect"
                            placeholder="Select month"
                            formControlName="to_month"
                            [ngClass]="{ 'is-invalid': (submitted || prorated_holiday_policy().controls[i].get('to_month')?.touched) &&  prorated_holiday_policy().controls[i].get('to_month')?.errors?.required }"
                            (ngModelChange)="showValidation(i)">
                            <ng-option *ngFor="let yData of listOfMonth"
                                [value]="yData.value">{{yData.value}}</ng-option>
                        </ng-select>

                            <div *ngIf="(submitted || prorated_holiday_policy().controls[i].get('to_month')?.touched) && prorated_holiday_policy().controls[i].get('to_month')?.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="prorated_holiday_policy().controls[i].get('to_month')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>



                            </div>
                        </div>
                        <div class="col form-row mt-0">

                            <input type="text" class="form-control"
                                placeholder="{{this.messageService.placeholderdisp('count in numbers')}}"
                                formControlName="count_number"  [ngClass]="{ 'is-invalid': (submitted || prorated_holiday_policy().controls[i].get('count_number')?.touched || prorated_holiday_policy().controls[i].get('count_number')?.dirty)
                                    &&  (prorated_holiday_policy().controls[i].get('count_number')?.errors?.required
                                    ||prorated_holiday_policy().controls[i].get('count_number')?.errors?.pattern
                                    ||prorated_holiday_policy().controls[i].get('count_number')?.errors?.maxlength )}">

                            <div *ngIf=" (prorated_holiday_policy().controls[i].get('count_number')?.errors?.required
                                    ||prorated_holiday_policy().controls[i].get('count_number')?.errors?.pattern
                                    ||prorated_holiday_policy().controls[i].get('count_number')?.errors?.maxlength) && (submitted || prorated_holiday_policy().controls[i].get('count_number')?.touched || prorated_holiday_policy().controls[i].get('count_number')?.dirty)"
                                class="invalid-feedback">
                                <div
                                    *ngIf="prorated_holiday_policy().controls[i].get('count_number')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="prorated_holiday_policy().controls[i].get('count_number')?.errors?.pattern">
                                    Enter count in digits.</div>
                                <div
                                    *ngIf="!(prorated_holiday_policy().controls[i].get('count_number')?.errors?.pattern) && prorated_holiday_policy().controls[i].get('count_number')?.errors?.maxlength">
                                    {{this.messageService.fieldlengthvalidation('num','3')}}

                                </div>

                            </div>
                        </div>
                        <a class="flex-center sq-40 text-danger mt-0" *ngIf="i!=0" (click)="removeRole(i)">
                            <i class="icon-trash "></i>
                        </a>




                    </div>
                </div>
            </ng-container>
            </ng-container>

            <div class="link-primary1 fs-14 fw-500" *ngIf="f.prorate_floating_holiday.value==true && f.is_floating_holiday.value == true" >
                <i class="icon-plus-square-fill me-8" (click)="addRoles()"></i><span (click)="addRoles()">Add</span>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
            (click)="validateHolidayPolicy()">NEXT</button>

        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"
            (click)="validateHolidayPolicy()">NEXT</button>


    </footer>



</form>
