<form class="" [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
      <div class="row ">
          <p class="text-uppercase fw-500">{{heading}}</p>
      </div>
      <div class="col-12 pb-10" *ngIf="isFutureHolday?.is_future == true && alertShow">
        <div role="alert" class="alert alert-warning mb-0 show">
          <p class="mb-0">This will become normal working day and LOP will be applicable if any employee is absent on this date.
          </p>
          <div class="btn-close" (click)="alertShow=false"></div>
        </div>
      </div>
      <div class="row row-16">
          <div class="col-12">
              <label for="" class="form-label required">Applicable to </label>
              <span *ngIf="hint !== ''" class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="{{hint}}" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
          <div class=" col-lg-6 mt-0 form-row">
            <ng-select class="form-ngselect" [ngClass]="{ 'is-invalid': (submitted || f.applicableto.touched) &&  f.applicableto.errors}" (change)="changeApplicableTo(true)"  formControlName="applicableto" placeholder="Select">
              <ng-option *ngFor="let app of applicableTo" [value]="app.name">{{app.name}}</ng-option>
          </ng-select>
          <div *ngIf="(submitted || f.applicableto.touched) && f.applicableto.errors" class="invalid-feedback"><div *ngIf="f.applicableto.errors.required">{{this.messageService.validationDisplay('required')}}</div></div>
          </div>
          <div class="col-12" *ngIf="f.applicableto.value!=null">
            <div class=" col-lg-6 " *ngIf="loaderlist">
              <div class="d-flex py-4 flex-center text-center " >
                  <div class="fs-12 text-muted">Loading </div>
                  <div class="ms-4 dot-pulse"></div>
               </div>
            </div>
          </div>
          <ng-container *ngIf="!loaderlist && list?.length">
            <div class=" col-lg-6 mt-0 form-row" *ngIf="f.applicableto.value!=null">
              <label for="" class="form-label required">{{f.applicableto.value}}</label>
                  <ng-select
                  [ngClass]="{ 'is-invalid': (submitted || f.assignees.touched || f.assignees.errors?.relievedError) &&  f.assignees.errors}"
                  placeholder="{{this.messageService.selectplaceholddisplay(f.applicableto.value)}}"
                  formControlName="assignees"
                  [items]="list"
                  [multiple]="true"
                  groupBy="selectedAllGroup"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="false"
                  bindLabel="name"
                  [closeOnSelect]="false"
                  bindValue="id"
                  class="multiselect"
                  (change)="ngChange($event)"
                  >
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>
                  <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index">
                  <div class="d-inline-flex align-items-center w-100">
                    <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                    <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="item.logo !=  null && item.logo !=  '' && f.applicableto.value  ==  'Company'" src="{{item.logo}}">
                  <span *ngIf="(item.logo ==  null || item.logo ==  '') && (f.applicableto.value  ==  'Company')" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>

                  <img class="avatar-sm sq- img-fluid rounded-circle me-8"  *ngIf="item.profile_image !=  null && item.profile_image !=  '' && f.applicableto.value  ==  'Employee'" src="{{item.profile_image}}">
                  <span *ngIf="(item.profile_image ==  null || item.profile_image ==  '') && (f.applicableto.value  ==  'Employee')" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appService.getFirstChar(item.name,2)}}</span></span>
                  <span class="text-trim">{{item.name}}</span>
                  </div>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="formGp.value.assignees.length ">
                          <span class="ng-value-label">{{formGp.value.assignees.length }} {{f.applicableto.value}} selected</span>
                      </div>
                  </ng-template>
                </ng-select>
                <div *ngIf="(submitted || f.assignees.touched || f.assignees.errors?.relievedError) && f.assignees.errors" class="invalid-feedback">
                  <div *ngIf="f.assignees.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.assignees.errors?.relievedError">{{this.messageService.inactiveStatus()}}</div>
                </div>
            </div>
            <div class="col-12"  *ngIf="f.applicableto.value!=null">
              <ul class="selected-values" *ngIf="formGp.value.assignees.length">
                  <li class="ng-value" [ngClass]="{'ng-value-danger': isInsideItemIdFive(item)}" *ngFor="let item of formGp.value.assignees,let ki =index">
                      <ng-container *ngFor="let insideitem of list, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                            <div class="d-inline-flex align-items-center">
                              <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null && f.applicableto.value  ==  'Employee'" src="{{insideitem.profile_image}}">
                              <span *ngIf="(insideitem.profile_image ==  null || insideitem.profile_image ==  '') && (f.applicableto.value  ==  'Employee')" class="avatar-sm me-8 sq-24 rounded-circle bg{{k%5}}" >
                                  <span>
                                  {{this.appService.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                              </span>

                              <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null && f.applicableto.value  ==  'Company'" src="{{insideitem.logo}}">
                              <span *ngIf="(insideitem.logo ==  null || insideitem.logo ==  '') && (f.applicableto.value  ==  'Company')" class="avatar-sm sq-24 rounded-circle bg{{k%5}}" >
                                  <span>
                                  {{this.appService.getFirstChar(insideitem.name,1)}}</span>
                              </span>
                              <span class=""> {{ (insideitem['name']?.length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                              <span *ngIf="insideitem?.active_state_employee == false" class="lh-1 ms-8"><i class="bi bi-info-circle fs-14 text-danger tooltip-icon" ngbPopover="Employment status : Inactive" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                           </div>

                          </span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
          </ng-container>
      </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid|| submitted" (click)="validateAssignRights()">SAVE</button>
  </footer>
</form>

<!-- Confirmation Policy Future Reference -->
<!-- <div  class="modal  modal-alert {{saveModal == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered " [formGroup]="formGp">
      <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title">Confirmation Policy</h4>
          <button class="btn-reset text-light-600 ms-auto" (click)="saveModal = false">
            <i class="icon-close-lg fs-16"></i></button>
          </div>
          <div class="modal-body">
              <p class="mb-0  d-flex align-items-baseline">You want to apply this policy on</p>
              <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="applicablityOn" type="radio" id="radio7" class="form-check-input" [value]="true" formControlName="applicablityOn">
                    <label for="radio7" class="form-check-label">New employee </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="applicablityOn" type="radio" id="radio8" class="form-check-input" [value]="false" formControlName="applicablityOn">
                    <label for="radio8" class="form-check-label">All employee</label>
                </div>
            </div>
          </div>
          <div  class="modal-footer">
              <button type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="validateAssignRights();">save</button>
          </div>
      </div>
  </div>
</div> -->
