import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MasterSelectComponent } from './components/master-select/master-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxSkeletonLoaderComponent, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ViewLoaderComponent } from './components/view-loader/view-loader.component';
import { EditLoaderComponent } from './components/edit-loader/edit-loader.component';
import { FwEditLoaderComponent } from './components/fw-edit-loader/fw-edit-loader.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TableLoaderComponent } from './components/table-loader/table-loader.component';
import { C1LoaderComponent } from './components/c1-loader/c1-loader.component';
import { ViewInvestmentDeclarationComponent } from './components/view-investment-declaration/view-investment-declaration.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CardViewLoaderComponent } from './components/card-view-loader/card-view-loader.component';
import { ViewLeaveRequestComponent } from './components/view-leave-request/view-leave-request.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ViewLeaveBalanceComponent } from './components/view-leave-balance/view-leave-balance.component';
import { NgbModule, NgbNavItem, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ViewLeavePolicyComponent } from './components/view-leave-policy/view-leave-policy.component';
import { UploadProgressBarComponent } from './components/upload-progress-bar/upload-progress-bar.component';
import { ViewEmployeeProfileComponent } from './components/view-employee-profile/view-employee-profile.component';
import { CalendarLoaderComponent } from './components/calendar-loader/calendar-loader.component';
import { ViewFbpDeclarationComponent } from './components/view-fbp-declaration/view-fbp-declaration.component';
import { ViewReimbursementComponent } from './components/view-reimbursement/view-reimbursement.component';
import { SalaryDashboardFilterComponent } from './components/salary-dashboard-filter/salary-dashboard-filter.component';
import { ViewStatusComponent } from './components/view-status/view-status.component';
import { ViewRegimeComparisonComponent } from './components/view-regime-comparison/view-regime-comparison.component';
import { SalaryRevisionHistoryViewComponent } from './components/salary-revision-history-view/salary-revision-history-view.component';
import { SalaryRevisionDetailsViewComponent } from './components/salary-revision-details-view/salary-revision-details-view.component';
import { SalaryEmployeeFiltersComponent } from './components/salary-employee-filters/salary-employee-filters.component';
import { ReconcilationViewComponent } from './components/reconcilation-view/reconcilation-view.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FinancialYearComponent } from './components/financial-year/financial-year.component';
import { ViewFileComponent } from './components/view-file/view-file.component';
import { AlertToggleComponent } from './components/alert-toggle/alert-toggle.component';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { ImportUploadComponent } from './components/import-upload/import-upload.component';
import { ErrorDownloadComponent } from './components/error-download/error-download.component';
import { InfoPopupComponent } from './components/info-popup/info-popup.component';
import { HomeLoadersComponent } from './components/home-loaders/home-loaders.component';
import { LoginLoaderComponent } from './components/login-loader/login-loader.component';
import { FinancialCalcViewComponent } from './components/financial-calc-view/financial-calc-view.component';
import { MonthlySalaryViewComponent } from './components/monthly-salary-view/monthly-salary-view.component';
import { PriorSummaryComponent } from './components/prior-summary/prior-summary.component';
import { ConfirmedItDeclarationSetupComponent } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-declaration-setup.component';
import { InfinityLoaderComponent } from './components/infinity-loader/infinity-loader.component';

import { ConfirmedItHra80GgComponent } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-hra80-gg/confirmed-it-hra80-gg.component';
import { ConfirmedItChapt2Component } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-chapt2/confirmed-it-chapt2.component';
import { ConfirmedItDeductionsComponent } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-deductions/confirmed-it-deductions.component';
import { ConfirmedItIncomelossComponent } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-incomeloss/confirmed-it-incomeloss.component';
import { ConfirmedItOtherIncomeComponent } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-other-income/confirmed-it-other-income.component';
import { ConfirmedItRegimeComparisonComponent } from './components/it-decleration/confirmed-it-declaration-setup/confirmed-it-regime-comparison/confirmed-it-regime-comparison.component';
import { FinalSettelementExpansionComponent } from './components/final-settelement-expansion/final-settelement-expansion.component';
import { ReimbursementInboxDetailsComponent } from './components/reimbursement-details/reimbursement-details.component';
import { ViewReimbursementDetailsComponent } from './components/view-reimbursement-details/view-reimbursement-details.component';
import { ReimbursementAnalysisComponent } from './components/reimbursement-analysis/reimbursement-analysis.component';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { CommonLoaderComponent } from './components/common-loader/common-loader.component';
import { CancelRequestModalComponent } from './components/cancel-request-modal/cancel-request-modal.component';
import { ViewRegularizationRequestComponent } from './components/view-regularization-request/view-regularization-request.component';
import { ViewOnDutyRequestComponent } from './components/view-on-duty-request/view-on-duty-request.component';
import { ViewWfhRequestComponent } from './components/view-wfh-request/view-wfh-request.component';
import { ViewOvertimeRequestComponent } from './components/view-overtime-request/view-overtime-request.component';
import { NodataViewComponent } from './components/nodata-view/nodata-view.component';

import { AttendanceCalendarComponent } from './components/attendance-calendar/attendance-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IgxTimePickerModule } from 'igniteui-angular';
import { FileUploadDocrepoComponent } from './components/file-upload-docrepo/file-upload-docrepo.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
]);
import { ViewCompOffRequestComponent } from './components/view-comp-off-request/view-comp-off-request.component';
import { PayrollChecklistComponent } from './components/payroll-checklist/payroll-checklist.component';
import { RouterModule } from '@angular/router';
import { ViewTaxSlabComponent } from './components/view-tax-slab/view-tax-slab.component';
import { WeekOffTableComponent } from './components/week-off-table/week-off-table.component';
import { NotificationLeaveYearEndComponent } from './components/notification-leave-year-end/notification-leave-year-end.component';
import { FieldPermissionTableComponent } from './components/field-permission-table/field-permission-table.component';
import { AttendanceTableComponent } from './components/attendance-table/attendance-table.component';
import { NonMappedEmppComponent } from './components/non-mapped-empp/non-mapped-empp.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { GeoLocationAddComponent } from './components/geo-location-add/geo-location-add.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { GraphLoaderComponent } from './components/graph-loader/graph-loader.component';
import { EmployeeMapViewComponent } from './components/app-employee-map-view/app-employee-map-view.component';
import { ViewCheckinRequestComponent } from './components/view-checkin-request/view-checkin-request/view-checkin-request.component';
import { AgmDirectionModule } from 'agm-direction'
import { GpsLostTableComponent } from './components/gps-lost-table/gps-lost-table.component';
import { environment } from 'src/environments/environment';
import { AgmOverlays } from 'agm-overlays';
import { RequestLocationViewComponent } from './components/request-location-view/request-location-view.component';
import { ExpenseCategoryAddComponent } from './components/expense-category-add/expense-category-add.component';
import { AddNewReportComponent } from './components/add-new-report/add-new-report.component';
import { ExpenseApplyAdvanceComponent } from './components/expense-apply-advance/expense-apply-advance.component';

import { AddExpenseAdvanceComponent } from './components/add-expense-advance/add-expense-advance.component';
import { AddUnreportedExpenseComponent } from './components/add-unreported-expense/add-unreported-expense.component';
import { ViewExpenseReceiptComponent } from './components/view-expense-receipt/view-expense-receipt.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MenuTabComponent } from './components/menu-tab/menu-tab.component';
import { ViewAssetRequestComponent } from './components/view-asset-request/view-asset-request.component';
import { InventoryViewComponent } from './components/inventory-view/inventory-view.component';
import { AssetFilterComponent } from './components/asset-filter/asset-filter.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { CameraModalComponent } from './components/camera-modal/camera-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { ProfilePicComponent } from './components/profile-pic/profile-pic.component';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyIconModule } from '@alyle/ui/icon';
import { LyButtonModule } from '@alyle/ui/button';
import { LySliderModule } from '@alyle/ui/slider';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { RequestPanelComponent } from './components/request-panel/request-panel.component';
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LY_THEME_GLOBAL_VARIABLES,
  LyHammerGestureConfig
} from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { color } from '@alyle/ui/color';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { WebcamCropperComponent } from './components/webcam-cropper/webcam-cropper.component';
import { CheckinCaptureComponent } from './components/checkin-capture/checkin-capture.component';
import { CheckinVerificationComponent } from './components/checkin-verification/checkin-verification.component';
import { AppGenerateLetterComponent } from './components/app-generate-letter/app-generate-letter.component';


export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e)
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xB36FBC)
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87)
  };
}

import { AddNewExpenseComponent } from './components/add-new-expense/add-new-expense.component';
import { ReceiptLandingTableComponent } from './components/receipt-landing-table/receipt-landing-table.component';
import { UploadMultipleAttachmentsComponent } from './components/upload-multiple-attachments/upload-multiple-attachments.component';
import { ViewMultipleAttachmentsComponent } from './components/view-multiple-attachments/view-multiple-attachments.component';
import { AdvanceOutstandingDetailViewComponent } from './components/advance-outstanding-detail-view/advance-outstanding-detail-view.component';
import { EmpAdvanceDetailsViewComponent } from './components/emp-advance-details-view/emp-advance-details-view.component';
import { ViewExpenseReportRequestComponent } from './components/view-expense-report-request/view-expense-report-request.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ViewShortLeaveComponent } from './components/view-short-leave/view-short-leave.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ArraySortPipe } from './pipes/sort.pipe';
import { SideMenuLoaderComponent } from './components/side-menu-loader/side-menu-loader.component';
import { CommonApplicabilityComponent } from './components/common-applicability/common-applicability.component';
import { ImportEmployeePunchOverrideComponent } from './components/import-employee-punch-override/import-employee-punch-override.component';
import { MenuLoaderComponent } from './components/menu-loader/menu-loader.component';

// ONBEHALF INBOX VIEW-FOR BOTH INBOX AND MANAGE ACTIVITY
import { LeaveApprovalInboxComponent } from './components/inbox/leave-approval-inbox/leave-approval-inbox.component';
import { ViewLoanRequestComponent } from './components/view-loan-request/view-loan-request.component';
import { ViewLoanDetailsComponent } from './components/view-loan-details/view-loan-details.component';
import { LoanFilterComponent } from './components/loan-filter/loan-filter.component';
import { OuFiltersComponent } from './components/ou-filters/ou-filters.component';
import { TaxChangesBannerComponent } from './components/tax-changes-banner/tax-changes-banner.component';
import { ProgressbarComponent } from './components/inbox/progressbar/progressbar.component';
import { AppointmentLetterInboxComponent } from './components/inbox/appointment-letter-inbox/appointment-letter-inbox.component';
import { AssetRequestInboxComponent } from './components/inbox/asset-request-inbox/asset-request-inbox.component';
import { AttendanceRegularizationInboxComponent } from './components/inbox/attendance-regularization-inbox/attendance-regularization-inbox.component';
import { RequestSubmitComponent } from './components/inbox/request-submit/request-submit.component';
import { CheckinInboxComponent } from './components/inbox/checkin-inbox/checkin-inbox.component';
import { CompoOffInboxComponent } from './components/inbox/compo-off-inbox/compo-off-inbox.component';
import { ConfirmationReqInboxComponent } from './components/inbox/confirmation-req-inbox/confirmation-req-inbox.component';
import { ConfirmedInvestmentInboxComponent } from './components/inbox/confirmed-investment-inbox/confirmed-investment-inbox.component';
import { InvalidRequestComponent } from './components/inbox/invalid-request/invalid-request.component';
import { AdvanceDispenseInboxComponent } from './components/inbox/advance-dispense-inbox/advance-dispense-inbox.component';
import { EmployeeDataInboxComponent } from './components/inbox/employee-data-inbox/employee-data-inbox.component';
import { ExpenseReportInboxComponent } from './components/inbox/expense-report-inbox/expense-report-inbox.component';
import { FbpInboxComponent } from './components/inbox/fbp-inbox/fbp-inbox.component';
import { FinalSettlementProcessInboxComponent } from './components/inbox/final-settlement-process-inbox/final-settlement-process-inbox.component';
import { FinalSettlementApprovalInboxComponent } from './components/inbox/final-settlement-approval-inbox/final-settlement-approval-inbox.component';
import { HolidayInboxComponent } from './components/inbox/holiday-inbox/holiday-inbox.component';
import { ManageAssetInboxComponent } from './components/inbox/manage-asset-inbox/manage-asset-inbox.component';
import { OndutyInboxComponent } from './components/inbox/onduty-inbox/onduty-inbox.component';
import { OvertimeInboxComponent } from './components/inbox/overtime-inbox/overtime-inbox.component';
import { ReimbursementInboxComponent } from './components/inbox/reimbursement-inbox/reimbursement-inbox.component';
import { SalaryRevisionInboxComponent } from './components/inbox/salary-revision-inbox/salary-revision-inbox.component';
import { ShortleaveInboxComponent } from './components/inbox/shortleave-inbox/shortleave-inbox.component';
import { WfhInboxComponent } from './components/inbox/wfh-inbox/wfh-inbox.component';
import { LoanApprovalInboxComponent } from './components/inbox/loan-approval-inbox/loan-approval-inbox.component';
import { LoanProcessInboxComponent } from './components/inbox/loan-process-inbox/loan-process-inbox.component';
// END INBOX VIEW-MANAGE ACTIVITY
import { TableInputPopoverComponent } from './components/table-input-popover/table-input-popover.component';
import { AttendanceLocationComponent } from './components/attendance-location/attendance-location.component';
import { FindAddressComponent } from './components/find-address/find-address.component';
import { MyProposedDeclarationComponent } from '../@module/profile/my-finance/my-it-declarations/my-proposed-declaration/my-proposed-declaration.component';
import { MyConfirmDeclarationComponent } from '../@module/profile/my-finance/my-it-declarations/my-confirm-declaration/my-confirm-declaration.component';
import { CommonEmployeeFiltersComponent } from './components/common-employee-filters/common-employee-filters.component';
@NgModule({
  declarations: [
    MasterSelectComponent,
    FileUploadComponent,
    LoaderComponent,
    SearchbarComponent,
    ViewLoaderComponent,
    EditLoaderComponent,
    FwEditLoaderComponent,
    SearchFilterPipe,
    TableLoaderComponent,
    C1LoaderComponent,
    ViewInvestmentDeclarationComponent,
    CardViewLoaderComponent,
    ViewLeaveRequestComponent,
    ViewLeaveBalanceComponent,
    ViewLeavePolicyComponent,
    UploadProgressBarComponent,
    ViewEmployeeProfileComponent,
    CalendarLoaderComponent,
    ViewFbpDeclarationComponent,
    ViewReimbursementComponent,
    SalaryDashboardFilterComponent,
    ViewStatusComponent,
    ViewRegimeComparisonComponent,
    SalaryRevisionHistoryViewComponent,
    SalaryRevisionDetailsViewComponent,
    SalaryEmployeeFiltersComponent,
    ReconcilationViewComponent,
    ViewFileComponent,
    FinancialYearComponent,
    AlertToggleComponent,
    SelectDropdownComponent,
    ImportUploadComponent,
    ErrorDownloadComponent,
    InfoPopupComponent,
    HomeLoadersComponent,
    LoginLoaderComponent,
    FinancialCalcViewComponent,
    MonthlySalaryViewComponent,

    ConfirmedItHra80GgComponent,
    ConfirmedItChapt2Component,
    ConfirmedItDeductionsComponent,
    ConfirmedItIncomelossComponent,
    ConfirmedItOtherIncomeComponent,
    ConfirmedItRegimeComparisonComponent,
    InfinityLoaderComponent,
    ConfirmedItDeclarationSetupComponent,
    PriorSummaryComponent,
    FinalSettelementExpansionComponent,
    ReimbursementInboxDetailsComponent,
    ViewReimbursementDetailsComponent,
    ReimbursementAnalysisComponent,
    CommonFilterComponent,
    CommonLoaderComponent,
    CancelRequestModalComponent,
    ViewRegularizationRequestComponent,
    ViewOnDutyRequestComponent,
    ViewWfhRequestComponent,
    ViewOvertimeRequestComponent,
    NodataViewComponent,

    AttendanceCalendarComponent,
    ViewCompOffRequestComponent,
    PayrollChecklistComponent,
    ViewTaxSlabComponent,
    WeekOffTableComponent,
    NotificationLeaveYearEndComponent,
    FieldPermissionTableComponent,
    AttendanceTableComponent,
    NonMappedEmppComponent,
    DoughnutChartComponent,
    BarChartComponent,
    GraphLoaderComponent,
    GeoLocationAddComponent,
    ExpenseCategoryAddComponent,
    EmployeeMapViewComponent,
    ViewCheckinRequestComponent,
    GpsLostTableComponent,
    RequestLocationViewComponent,
    // ExpenseCategoryAddComponent,
    AddNewReportComponent,
    ExpenseApplyAdvanceComponent,
	  AddExpenseAdvanceComponent,
   AddUnreportedExpenseComponent,
   ViewExpenseReceiptComponent,
    LineChartComponent,
    FileUploadDocrepoComponent,
    MenuTabComponent,
    CameraModalComponent,
    ProfilePicComponent,
    WebcamCropperComponent,
    CheckinCaptureComponent,
    CheckinVerificationComponent,
    ViewAssetRequestComponent,
    InventoryViewComponent,
    AssetFilterComponent,
    AddNewExpenseComponent,
    ReceiptLandingTableComponent,
    UploadMultipleAttachmentsComponent,
    ViewMultipleAttachmentsComponent,
    AdvanceOutstandingDetailViewComponent,
    EmpAdvanceDetailsViewComponent,
    ViewExpenseReportRequestComponent,
    ViewShortLeaveComponent,
    ProgressBarComponent,
    AppGenerateLetterComponent,
    ArraySortPipe,
    SideMenuLoaderComponent,
    CommonApplicabilityComponent,
    ImportEmployeePunchOverrideComponent,
	CommonEmployeeFiltersComponent,
    TaxChangesBannerComponent,
ImportEmployeePunchOverrideComponent,
    RequestPanelComponent,
    MenuLoaderComponent,
    LeaveApprovalInboxComponent,
    ProgressbarComponent,
    AppointmentLetterInboxComponent,
    AssetRequestInboxComponent,
    AttendanceRegularizationInboxComponent,
    RequestSubmitComponent,
    CheckinInboxComponent,
    CompoOffInboxComponent,
    ConfirmationReqInboxComponent,
    ConfirmedInvestmentInboxComponent,
    InvalidRequestComponent,
    AdvanceDispenseInboxComponent,
    EmployeeDataInboxComponent,
    ExpenseReportInboxComponent,
    FbpInboxComponent,
    FinalSettlementProcessInboxComponent,
    FinalSettlementApprovalInboxComponent,
    HolidayInboxComponent,
    ManageAssetInboxComponent,
    OndutyInboxComponent,
    OvertimeInboxComponent,
    ReimbursementInboxComponent,
    SalaryRevisionInboxComponent,
    ShortleaveInboxComponent,
    WfhInboxComponent,
    LoanApprovalInboxComponent,
    LoanProcessInboxComponent,
    ViewLoanRequestComponent,
    ViewLoanDetailsComponent,
    LoanFilterComponent,
    OuFiltersComponent,
    TableInputPopoverComponent,
    AttendanceLocationComponent,
    FindAddressComponent,
    MyProposedDeclarationComponent,
    MyConfirmDeclarationComponent,
  ],
  exports: [
    MasterSelectComponent,
    FileUploadComponent,
    LoaderComponent,
    SearchbarComponent,
    ViewLoaderComponent,
    EditLoaderComponent,
    FwEditLoaderComponent,
    SearchFilterPipe,
    TableLoaderComponent,
    C1LoaderComponent,
    ViewInvestmentDeclarationComponent,
    CardViewLoaderComponent,
    ViewLeaveRequestComponent,
    ViewLeaveBalanceComponent,
    ViewLeavePolicyComponent,
    UploadProgressBarComponent,
    ViewEmployeeProfileComponent,
    CalendarLoaderComponent,
    ViewFbpDeclarationComponent,
    ViewReimbursementComponent,
    SalaryDashboardFilterComponent,
    ViewStatusComponent,
    ViewRegimeComparisonComponent,
    SalaryRevisionHistoryViewComponent,
    SalaryRevisionDetailsViewComponent,
    SalaryEmployeeFiltersComponent,
    ReconcilationViewComponent,
    ViewFileComponent,
    AlertToggleComponent,
    SelectDropdownComponent,
    FinancialYearComponent,
    ImportUploadComponent,
    ErrorDownloadComponent,
    InfoPopupComponent,
    HomeLoadersComponent,
    LoginLoaderComponent,
    FinancialCalcViewComponent,
    MonthlySalaryViewComponent,
    ConfirmedItDeclarationSetupComponent,
    ConfirmedItHra80GgComponent,
    ConfirmedItChapt2Component,
    ConfirmedItDeductionsComponent,
    ConfirmedItIncomelossComponent,
    ConfirmedItOtherIncomeComponent,
    ConfirmedItRegimeComparisonComponent,
    InfinityLoaderComponent,
    PriorSummaryComponent,
    FinalSettelementExpansionComponent,
    ReimbursementInboxDetailsComponent,
    ViewReimbursementDetailsComponent,
    ReimbursementAnalysisComponent,
    CommonFilterComponent,
    CommonLoaderComponent,
    CancelRequestModalComponent,
    ViewRegularizationRequestComponent,
    ViewOnDutyRequestComponent,
    ViewWfhRequestComponent,
    ViewOvertimeRequestComponent,
    PayrollChecklistComponent,
    NgxSkeletonLoaderComponent,
    AttendanceCalendarComponent,
    ViewCompOffRequestComponent,
    PayrollChecklistComponent,
    ViewTaxSlabComponent,
    NodataViewComponent,
    WeekOffTableComponent,
    NotificationLeaveYearEndComponent,
    FieldPermissionTableComponent,
    AttendanceTableComponent,
    NonMappedEmppComponent,
    DoughnutChartComponent,
    BarChartComponent,
    GraphLoaderComponent,
    GeoLocationAddComponent,
    ExpenseCategoryAddComponent,
    EmployeeMapViewComponent,
    ViewCheckinRequestComponent,
    GpsLostTableComponent,
    RequestLocationViewComponent,
    AddNewReportComponent,
    ExpenseApplyAdvanceComponent,
	  AddExpenseAdvanceComponent,
    AddUnreportedExpenseComponent,
    ViewExpenseReceiptComponent,
    LineChartComponent,
    FileUploadDocrepoComponent,
    MenuTabComponent,
    CameraModalComponent,
    ProfilePicComponent,
    WebcamCropperComponent,
    CheckinCaptureComponent,
    CheckinVerificationComponent,
    ViewAssetRequestComponent,
    InventoryViewComponent,
    AssetFilterComponent,
    AddNewExpenseComponent,
    ReceiptLandingTableComponent,
    UploadMultipleAttachmentsComponent,
    ViewMultipleAttachmentsComponent,
    AdvanceOutstandingDetailViewComponent,
    EmpAdvanceDetailsViewComponent,
    ViewExpenseReportRequestComponent,
    CheckinVerificationComponent,
    AppGenerateLetterComponent,
    ViewShortLeaveComponent,
    ArraySortPipe,
    SideMenuLoaderComponent,
    CommonApplicabilityComponent,
    ProgressBarComponent,
    ImportEmployeePunchOverrideComponent,
	CommonEmployeeFiltersComponent,
    TaxChangesBannerComponent,
ImportEmployeePunchOverrideComponent,
    RequestPanelComponent,
    MenuLoaderComponent,
    LeaveApprovalInboxComponent,
    ProgressbarComponent,
    AppointmentLetterInboxComponent,
    AssetRequestInboxComponent,
    AttendanceRegularizationInboxComponent,
    RequestSubmitComponent,
    CheckinInboxComponent,
    CompoOffInboxComponent,
    ConfirmationReqInboxComponent,
    ConfirmedInvestmentInboxComponent,
    InvalidRequestComponent,
    AdvanceDispenseInboxComponent,
    EmployeeDataInboxComponent,
    ExpenseReportInboxComponent,
    FbpInboxComponent,
    FinalSettlementProcessInboxComponent,
    FinalSettlementApprovalInboxComponent,
    HolidayInboxComponent,
    ManageAssetInboxComponent,
    OndutyInboxComponent,
    OvertimeInboxComponent,
    ReimbursementInboxComponent,
    SalaryRevisionInboxComponent,
    ShortleaveInboxComponent,
    WfhInboxComponent,
    LoanApprovalInboxComponent,
    LoanProcessInboxComponent,
    TableInputPopoverComponent,
    AttendanceLocationComponent,
    ViewLoanRequestComponent,
    LoanFilterComponent,
    OuFiltersComponent,
    ViewLoanDetailsComponent,
    FindAddressComponent,
    MyProposedDeclarationComponent,
    MyConfirmDeclarationComponent,
  ],

  imports: [
    FormsModule,
    CommonModule,
    NgSelectModule,
    NgxSkeletonLoaderModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    NgbModule,
    MatDatepickerModule,
    MatSliderModule,
    MatMomentDateModule,
    Ng2SearchPipeModule,
    FullCalendarModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    IgxTimePickerModule,
    AgmOverlays,
    NgxDocViewerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.locKey,
      libraries: ['places']
    }),
    AgmDirectionModule,
    WebcamModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    NgxImageZoomModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MMM/YYYY',
      clearLabel: 'Cancel'
    }),
  ],
  providers: [
    NgbNavItem,
    NgbNav,
    GoogleMapsAPIWrapper,
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables
    }, // global variables
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    ReceiptLandingTableComponent
  ]
})
export class MasterSelectModule { }
