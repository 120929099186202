<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Asset</h3>
      <p class="text-helper mb-md-0">You can track and manage the assets assigned to you in this page.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <div class="">
              <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='searchPlaceholder' [isLoading]="sLoading"(searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchAsset($event)"></app-searchbar>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle (click)="filterpanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>

        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: calc(100vh - 11rem);" *ngIf="nodatafound && !loader; else all_data">
  <app-nodata-view class="vstack" [noDataImg]="'bg26.png'" [noDataText]="'Asset overview!'"
    [noDataPara]="'View and manage all your assigned and returned assets. Keep track of assets and their status with ease!.'"
    [hasPermission]="false">
  </app-nodata-view>
  </div>
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card11'" *ngFor="let dummy of ' '.repeat(10).split(''); let x = index"></app-common-loader>
  </div>
  <ng-template #all_data>
    <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()" *ngIf="!loader">
      <div class="row row-16">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let item of landingData; let i = index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewDetails(item?.asset_employee_id)">
                <div class="logo-img  {{item?.asset_color_code}}">{{this.appService.getFirstChar(item?.name,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips">{{item?.name}} </h6>
                  <span class="tips-helper">{{item?.name}} </span>
                  <div class="fs-10 text-uppercase fw-500 d-flex gap-8 align-items-center">
                    <span class="text-light-400">{{item?.code}}</span>
                    <span class="text-light-400">|</span>
                    <span class="text-light-400">{{item?.status}}</span>

                  </div>
                </div>
              </div>

            </div>
            <div class="card-body flex-column gap-8">
              <!-- <div class="d-flex justify-content-between w-100 gap-8 text-uppercase fs-10">
                <span class="text-light-400 text-trim">Asset name</span>
                <span class=" text-trim">{{item?.name}}</span>
              </div> -->
              <div  class="d-flex justify-content-between w-100 gap-8 ">
                <div class="text-muted fs-12 me-8 text-nowrap">
                  Assigned by
                </div>
                <div class="d-flex align-items-center overlap-avatar overlap-right">

                    <img class="avatar-sm sq-32 avatar-circle" *ngIf="item.assigned_by_profile_image != '' && item.assigned_by_profile_image != null" src="{{ item.assigned_by_profile_image }}" [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover" container="body"/>
                    <div class="avatar-sm sq-32 avatar-circle {{item.assigned_by_color_code}}" *ngIf="(item.assigned_by_profile_image == '' || item.assigned_by_profile_image == null)" [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover" container="body">
                      <span>{{this.appService.getFirstChar(item.assigned_by,2)}}</span>
                    </div>
                    <ng-template #popContentSingle>
                      <div class="user-details">
                        <span class="user-label">{{item.assigned_by}}</span>
                        <span class="user-code">EMP CODE: {{ item?.assigned_by_employee_code }}</span>
                      </div>
                    </ng-template>

                </div>
            </div>
              <div class="d-flex justify-content-between w-100 gap-8 text-uppercase fs-10">
                <span class="text-light-400 text-trim">Assigned on</span>
                <span class=" text-trim">{{item?.assigned_on!='None'?this.appService.dateFormatDisplay(item?.assigned_on):'-'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>

        <div class="row row-24 mb-24" *ngIf="!viewLoader">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">Assets</p>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12  ">
                  <p class="title mb-8">Asset</p>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Asset name</div>
                  <div class="detail-desc">{{viewData?.name}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Asset code</div>
                  <div class="detail-desc">{{viewData?.code}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">{{viewData?.status}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Assigned by</div>

                          <div class="d-flex align-items-center">
                            <img
                              *ngIf="(viewData?.assigned_by!=null && (viewData?.assigned_by_profile_image !=  '' && viewData?.assigned_by_profile_image !=  null))"
                              class="user-img img-fluid rounded-circle sq-32" src="{{viewData?.assigned_by_profile_image}}">
                            <div
                              *ngIf="(viewData?.assigned_by!=null && (viewData?.assigned_by_profile_image ==  '' || viewData?.assigned_by_profile_image ==  null))"
                              class="avatar-circle fs-14 fw-500 {{viewData?.assigned_by_color_code}} sq-32 rounded-circle">
                              <span>{{this.appService.getFirstChar(viewData?.assigned_by,2)}}</span>
                            </div>
                            <div class="detail-desc p-2">
                              {{viewData?.assigned_by!=null?(viewData?.assigned_by)+" ("+viewData?.assigned_by_employee_code+")":'-'}}
                            </div>
                          </div>
                 </div>
                 <div class="col-sm-4 col-12">
                  <div class="detail-title">Assigned on</div>
                  <div class="detail-desc">{{this.appService.dateFormatDisplay(viewData?.assigned_on)}}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-asset-filter *ngIf="filterpanel" [filterpanel]="filterpanel" [from]="'my_asset'" (filterData)="applyFilter($event)" (close)="closeFilter()" (reset)="resetfilter()" [dataFilter]="datafilter" [filterForm]="filterForm">

</app-asset-filter>
