import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder,FormGroup,Validators,FormArray } from '@angular/forms';
import { EmpcodeValidator } from 'src/app/@shared/validators/empcode.validators';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';

@Component({
  selector: 'app-shift-setup',
  templateUrl: './shift-setup.component.html',
  styleUrls: ['./shift-setup.component.scss']
})
export class ShiftSetupComponent implements OnInit {
  activeTab         =1;
  disabled          = false;
  res               :any;
  id: number = 0;
  editflag: boolean = false;
  loader     = false;
  shift:any;
  title ="Add Shift";
  myForm: FormGroup = this.fb.group({
    id: 0,
    shiftdetails: this.fb.group({
      shift_name        :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      shift_code      : ['',[Validators.required,EmpcodeValidator(),Validators.maxLength(10)]],
      color_code     : ['',Validators.required],
      shift_allowance_applicable  : [false,Validators.required],
    }),
    shifttiming: this.fb.group({
      shift_start_time            : [null,[Validators.required]],
      shift_end_time              : [null,[Validators.required]],
      second_half_start           : [null,[Validators.required]],
      first_half_end              : [null,[Validators.required]],
      before_shift_start_enable   : [false, [Validators.required]],
      before_shift_start_time     :  [null],
      grace_time_late_checkin_enable  : [false],
      grace_time_late_checkin_time    : [null],
      grace_time_early_checkout_enable: [false],
      grace_time_early_checkout_time  : [null],
      min_work_hours_for_halfday      : [null,[Validators.required]],
      min_work_hours_for_fullday      : [null,[Validators.required]],
      mark_half_day_absent_status     : [false],
      mark_half_day_absent            : [null],
      mark_full_day_absent_status     : [false],
      mark_full_day_absent            : [null],
    }),
    shiftbreak: this.fb.group({
      break_hour_deduction_enable: [false, [Validators.required]],
      break_schedule             : this.fb.array([])
    }),
    weekoff: this.fb.group({
      week_off_define: [false, [Validators.required]],
      weekoffdata: this.fb.array([
        this.fb.group({
          week_number:1,
          monday:[null],
          tuesday:[null],
          wednesday:[null],
          thursday:[null],
          friday:[null],
          saturday:[null],
          sunday:[null],
        }),
        this.fb.group({
          week_number:2,
          monday:[null],
          tuesday:[null],
          wednesday:[null],
          thursday:[null],
          friday:[null],
          saturday:[null],
          sunday:[null],
        }),
        this.fb.group({
          week_number:3,
          monday:[null],
          tuesday:[null],
          wednesday:[null],
          thursday:[null],
          friday:[null],
          saturday:[null],
          sunday:[null],
        }),
        this.fb.group({
          week_number:4,
          monday:[null],
          tuesday:[null],
          wednesday:[null],
          thursday:[null],
          friday:[null],
          saturday:[null],
          sunday:[null],
        }),
        this.fb.group({
          week_number:5,
          monday:[null],
          tuesday:[null],
          wednesday:[null],
          thursday:[null],
          friday:[null],
          saturday:[null],
          sunday:[null],
        })
      ])
    })

  });
  tab3touched :any;
  tab2touched : any;
  tab4touched : any;

  isEditForm = false
  breakexceededkey:any;

  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public shiftservice : ShiftService,
    public router: Router,
    public route:ActivatedRoute,
    public appservice:AppService) {
     }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
    if(this.editflag == true){
      this.tab3touched = true
      this.tab4touched = true
    }

  }
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].shiftdetails.invalid) {
        changeEvent.preventDefault();
      }else{
        this.tab2touched = true
      }
    } else  if (changeEvent.nextId === 3) {
     
      if (this.myForm['controls'].shifttiming.invalid || this.myForm['controls'].shiftdetails.invalid|| (this.tab2touched == false && !this.isEditForm)) {
        changeEvent.preventDefault();
      }else{        
        this.tab3touched = true
      }
    } else  if (changeEvent.nextId === 4 ) {
      if (this.myForm['controls'].shiftbreak.invalid ||this.myForm['controls'].shifttiming.invalid || this.myForm['controls'].shiftdetails.invalid || this.breakexceededkey) {
        changeEvent.preventDefault();
      }else{
        this.tab4touched = true
      }
    }
  }
  get shiftdetailsForm() {
    return this.myForm.get('shiftdetails') as FormGroup;
  }
  get shifttimingForm() {
    return this.myForm.get('shifttiming') as FormGroup;
  }
  get shiftbreakForm() {
    return this.myForm.get('shiftbreak') as FormGroup;
  }
  get weekoffForm() {
    return this.myForm.get('weekoff') as FormGroup;
  }
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if(this.activeTab == 4 && this.breakexceededkey==false){
      this.tab4touched = true;
    }
  }
  submitMethodtime(value:any){
    this.shift= value;
    
    this.tab3touched = true
    this.activeTab = 3;
  }
  submitMethod1(value:any){
    this.shift= value;    
  }
  breakexceeded(val:any){
    this.breakexceededkey =val;
  }
  submitForm(){
    var dataArray = {
    "shift_code": this.shiftdetailsForm.controls.shift_code.value,
    "shift_name": this.shiftdetailsForm.controls.shift_name.value,
    "color_code":this.shiftdetailsForm.controls.color_code.value,
    "shift_allowance_applicable":this.shiftdetailsForm.controls.shift_allowance_applicable.value,
    "shift_start_time":this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.shift_start_time.value),
    "shift_end_time": this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.shift_end_time.value),
    "second_half_start": this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.second_half_start.value),
    "first_half_end": this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.first_half_end.value),
    "before_shift_start_enable": this.shifttimingForm.controls.before_shift_start_enable.value,
    "before_shift_start_time": this.shifttimingForm.controls.before_shift_start_time.value,
    "grace_time_late_checkin_enable": this.shifttimingForm.controls.grace_time_late_checkin_enable.value,
    "grace_time_late_checkin_time": this.shifttimingForm.controls.grace_time_late_checkin_time.value,
    "grace_time_early_checkout_enable":this.shifttimingForm.controls.grace_time_early_checkout_enable.value,
    "grace_time_early_checkout_time":this.shifttimingForm.controls.grace_time_early_checkout_time.value,
    "min_work_hours_for_halfday": this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.min_work_hours_for_halfday.value),
    "min_work_hours_for_fullday": this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.min_work_hours_for_fullday.value),
    "mark_half_day_absent_status": this.shifttimingForm.controls.mark_half_day_absent_status.value,
    "mark_half_day_absent": this.shifttimingForm.controls.mark_half_day_absent.value,
    "mark_full_day_absent_status": this.shifttimingForm.controls.mark_full_day_absent_status.value,
    "mark_full_day_absent": this.shifttimingForm.controls.mark_full_day_absent.value,
    "break_hour_deduction_enable":this.shiftbreakForm.controls.break_hour_deduction_enable?.value,
    "break_schedule":this.shiftbreakForm.controls.break_schedule?.value,
    "week_off_define": this.weekoffForm.controls.week_off_define?.value,
    "shift_based_week_off": this.weekoffForm.controls.weekoffdata?.value,
   };

   if(this.shifttimingForm.controls.before_shift_start_enable.value == true){
    dataArray.before_shift_start_time = this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.before_shift_start_time.value);
   }
   if(this.shifttimingForm.controls.grace_time_late_checkin_enable.value == true){
    dataArray.grace_time_late_checkin_time = this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.grace_time_late_checkin_time.value);
   }
   if(this.shifttimingForm.controls.grace_time_early_checkout_enable.value == true){
    dataArray.grace_time_early_checkout_time = this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.grace_time_early_checkout_time.value);
   }
   if(this.shifttimingForm.controls.mark_half_day_absent_status.value == true){
    dataArray.mark_half_day_absent = this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.mark_half_day_absent.value);
   }
   if(this.shifttimingForm.controls.mark_full_day_absent_status.value == true){
    dataArray.mark_full_day_absent = this.appservice.timeFormatAttConvert(this.shifttimingForm.controls.mark_full_day_absent.value);
   }

    if(this.id == 0){
      this.shiftservice.shiftCreate(dataArray).subscribe((res: any) => {
        this.router.navigate(['shift']);
      })
    }else {
      this.shiftservice.shiftupdate(dataArray,this.id).subscribe((res: any) => {
        this.router.navigate(['shift']);
      })
    }

  }
  breakarray() : FormArray {
    return this.shiftbreakForm.get("break_schedule") as FormArray
  }
  newbreakarray(): FormGroup {
    return this.fb.group({
      "break_start_time" : [null,Validators.required],
      "break_end_time" : [null,Validators.required],
    })
  }
  addbreak() {
    this.breakarray().push(this.newbreakarray());
  }
  editForm(){
    this.loader = true;
    this.editflag = true
    this.shiftservice.shiftGet(this.id).subscribe((res: any) => {
      this.isEditForm=true;
      this.title ="Edit Shift";
      this.setEditForm(res);
    },(error:any)=>{
      this.router.navigate(['shift']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }
    this.shift = this.appservice.Timedifference(res.shift_start_time,res.shift_end_time);
    let breaktimearray = []
    this.breakarray()?.removeAt(0);
    for(let key of res.breakschedule){
    this.addbreak();
    breaktimearray.push({
       "break_start_time":key.break_start_time,
       "break_end_time":key.break_end_time,
    });
    }
    this.myForm.reset(
      {
        id: res.id,
        shiftdetails: {
          shift_name: res.shift_name,
          shift_code: res.shift_code,
          shift_allowance_applicable: res.shift_allowance_applicable,
          color_code: res.color_code,
        },
        shifttiming: {
          shift_start_time: res.shift_start_time,
          shift_end_time: res.shift_end_time,
          first_half_end: res.first_half_end,
          second_half_start: res.second_half_start,
          before_shift_start_enable: res.before_shift_start_enable,
          before_shift_start_time: res.before_shift_start_time,
          grace_time_late_checkin_enable: res.grace_time_late_checkin_enable,
          grace_time_late_checkin_time: res.grace_time_late_checkin_time,
          grace_time_early_checkout_enable: res.grace_time_early_checkout_enable,
          grace_time_early_checkout_time: res.grace_time_early_checkout_time,
          min_work_hours_for_halfday: res.min_work_hours_for_halfday,
          min_work_hours_for_fullday: res.min_work_hours_for_fullday,
          mark_half_day_absent_status: res.mark_half_day_absent_status,
          mark_half_day_absent: res.mark_half_day_absent,
          mark_full_day_absent_status: res.mark_full_day_absent_status,
          mark_full_day_absent: res.mark_full_day_absent,
        },
        shiftbreak: {
          break_hour_deduction_enable: res.break_hour_deduction_enable,
          break_schedule: breaktimearray,
        },
        weekoff: {
          week_off_define: res.week_off_define,
          weekoffdata: res.weekoffdata_edit
        }
    });
    this.loader = false;
  }


}
