import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-loan-request',
  templateUrl: './loan-request.component.html',
  styleUrls: ['./loan-request.component.scss']
})
export class LoanRequestComponent implements OnInit {

  // Input
  @Input() loanTypesDropdown :  any
  @Input() formGp            : any
  @Input() from            : any
  @Input()  currency                   : any
  // Output Emitters
  @Output() submitMethod = new EventEmitter();

  // Varibles
  submitted                  : boolean = false;
  loanTypeDetails            : any
  minDate                              = new Date();
  maxDate                              = new Date(new Date().getFullYear()+5,0,1)
  monthlyEMI                 : any     = 0

  // File View
  currentDocName             : any;
  viewDocName                : any;
  currentExtension           : any;
  docURL                     : any     = "";
  alertMsg                   : any     = '';
  fileError                  : boolean = false;
  fileErrorMsg               : any     = "";
  docLoader                  : boolean = false;
  modalToggle                          = false;
  allowedExtension                     = "";
  alertToggle1                         = false;
  fileName                             = "";

  get f() { return this.formGp.controls; }

  constructor(
    public msgService        : MessageService,
    private loanServ         : InvestmentDeclerationService,
    public appService        : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats
  ) { }

  ngOnInit(): void {
    this.getDateFormat(); //date format setup
    if(this.formGp.get('loan_type')?.value != null && !this.loanTypeDetails){
      this.typeChanged()
    }
    if(this.from!='behalf'){

      let userData = this.appService.getEmpData()
      this.currency = userData?.currency
    }
  }

  // Type Selected
  typeChanged(){
    let typeName = this.formGp.get('loan_type')?.value
    if(typeName != null){
      let item = this.loanTypesDropdown.find((item:any) => item?.loan_type == typeName);
      this.loanServ.getLoanTypeDetails(item?.id).subscribe((res:any)=>{
        this.loanTypeDetails = res?.data
        this.formGp.get('loan_amount')?.setValidators([Validators.max(this.loanTypeDetails?.amount_limit),Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')])
        this.formGp.get('number_of_emi')?.setValidators([Validators.max(this.loanTypeDetails?.max_number_emis),Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')])
        this.formGp.get('loan_amount')?.updateValueAndValidity();
        this.formGp.get('number_of_emi')?.updateValueAndValidity();
        this.calculateEMIs()
      })
    }
  }

  // EMI
  calculateEMIs(){
    if(this.formGp.get('loan_amount')?.value != null && this.formGp.get('number_of_emi')?.value != null && this.formGp.get('loan_type')?.value != null && this.formGp.get('loan_type').valid && this.formGp.get('number_of_emi').valid && this.formGp.get('loan_amount').valid){
      let item = this.loanTypesDropdown.find((item:any) => item?.loan_type == this.formGp.get('loan_type')?.value);
      this.loanServ.calculateEMI({'loan_type_id' : item?.id, 'interest_type': this.loanTypeDetails?.interest_type, 'loan_amount':Number(this.formGp.get('loan_amount')?.value),'no_emis':Number(this.formGp.get('number_of_emi')?.value)}).subscribe((res:any)=>{
        this.monthlyEMI = res?.data?.monthly_emi
        if(this.monthlyEMI < 1){
          this.alertToggle1 = true
          this.alertMsg = `Monthly EMI can't be less than 1 ${this.currency}.`
        }else{
          this.alertToggle1 = false
          this.alertMsg = ''
        }
      })
    }
  }

  // Step 1 Submit
  validateLoanDetails(){
    this.submitted = true;
    if(this.alertMsg != ''){
      this.alertToggle1 = true
    }else{
      this.submitMethod.emit({'loanTypeDetails' : this.loanTypeDetails,'currency':this.currency});
    }
  }

  // File Setup
  getFilename(event: any) {
    let fileSize = this.appService.formatSizeUnits(event.size);
    window.sessionStorage.setItem('this.fileSize', fileSize);
    window.sessionStorage.setItem('this.docName', event.name);
  }

  getloader(event: any) {
    this.docLoader = event;
  }

  getfileformat(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docLoader = false;
    if (event == false) {
      this.fileError = true;
      this.fileErrorMsg = this.msgService.validateFileFormat('pdf,doc');
    }
  }

  getvalidsize(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docLoader = false;
    this.fileError = true;
    this.fileErrorMsg = event;
  }

  getURL(event: any) {
    this.docURL = event;
    this.fileError = false;
    this.formGp.get('document')?.setValue(event);
    this.docLoader = false;
  }

  deleteFile(ev:any){
    this.formGp.get('document')?.setValue('');
    this.docURL = '';
  }

  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle1         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  closed(bool: any) {
    this.alertToggle1 = bool;
    this.alertMsg = ''
    if(this.monthlyEMI < 1){
      this.alertMsg = `Monthly EMI can't be less than 1 ${this.currency}.`
    }
  }

  // Download File
  downLoad(val:any){
    let url = this.appService.getDocName(val).split('.')[0]
    this.appService.downloadFile(val,url)
  }

  // Utilities
  // Date Picker
  disableDate(){
    return false;
  }

  notanumber(val: any) {
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

}
