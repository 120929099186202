<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Reasons List</h3>
      <p class="text-helper mb-md-0">Add a list of reasons for an employee's separation from the company.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='separationPlaceholder'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchreason($event)" [isLoading]="isLoading"></app-searchbar>
        </div>

        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13 "></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" (click)="addreason()" class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i
            class="icon-plus"></i>ADD Reason</button>
      </div>
    </div>
  </div>
  <!-- No data found -->

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg34.png'" [noDataText]="'Smooth Exits!'"
      [noDataPara]="'Ensure smooth transitions for departing employees.'" [hasPermission]="false">
    </app-nodata-view>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-template #all_data>
    <div class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let reasons_data of reasons;let i=index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewreason(reasons_data.id)">
                <div class="logo-img {{reasons_data.color_code}}">{{this.appService.getFirstChar(reasons_data.name,2)}}
                </div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips">{{reasons_data.name}}</h6>
                  <span class="tips-helper">{{reasons_data.name}}</span>
                  <div
                    class="fs-10 text-uppercase fw-500 {{(reasons_data.is_active == true) ? 'text-success' : 'text-danger' }} ">
                    {{reasons_data.is_active == true ? 'Active':'Deleted'}}
                  </div>

                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section"
                *ngIf="reasons_data.is_active==true && (permissions.e || permissions.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngIf="permissions.e" (click)="editreason(reasons_data.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button class="text-danger" *ngIf="permissions.d" ngbDropdownItem
                    (click)="deletereason(reasons_data.id)">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
      <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

</div>

<div class="side-panel " style="--sidepanel-width:45rem;" [class.side-pane-active]='detailsView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="detailsView = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">
          <div class="row row-16 ">
            <div class="col-12 d-flex">

              <p class="text-uppercase fw-500 mb-0">Reason</p>
              <a class="link-primary fs-14 d-flex align-items-center ms-auto"
                *ngIf="(permissions.e && viewdata.is_active)" (click)="editreason(viewdata.id)">
                <i class="icon-pencil-square me-1"></i> Edit
              </a>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">REASON added</span></div>

                  <div class="col-md-6">
                    <div class="detail-title">Reason name</div>
                    <div class="detail-desc">{{viewdata.name}}</div>
                  </div>
                  <div class="col-md-6">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewdata.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewdata.is_active==false">DELETED</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>
<!-- slider section start -->
<div class="side-panel" style="--sidepanel-width:23.75rem;" [class.side-pane-active]='editReason === true'>
  <form class="side-panel-container" [formGroup]="reasonsForm" (ngSubmit)="savereason()" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{header}} Reason</h5>
      <a class="toggle-panel" (click)="editReason = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- Edit loader start -->
        <app-edit-loader *ngIf="loader1"></app-edit-loader>
        <!-- <- Edit loader end -->
        <ng-container *ngIf="!loader1">
          <p class="text-uppercase fw-500 mb-12">Reason</p>
          <div class="row row-12">
            <div class=" col-lg-12 form-row">
              <label for="" class="form-label required">Reason name </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enter the reason name for separation. eg: Retirement, health issues etc."
                  popoverClass="popover-default popover-info" placement="bottom" container="body"
                  triggers="hover"></i></span>
              <input type="text" formControlName="name" class="form-control" autofill="name" id="name"
                placeholder="{{this.messageService.placeholderdisp('Reason name')}}" (keyup)="keyUp()"
                [ngClass]="{ 'is-invalid': (submitted || f.name.touched || f.name.dirty) &&  f.name.errors || f.name.errors?.exist}">
              <div *ngIf="(f.name?.dirty || f.name?.touched) && f.name?.errors || f.name?.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name?.value)}}</div>
                <div *ngIf="f.name?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!(f.name?.errors?.pattern) && f.name?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}
                </div>
                <div *ngIf="f.name?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32" *ngIf="!loader1">
      <button class="btn btn-outline-danger text-uppercase btn-sm" type="button" *ngIf="deleteClass==true"
        (click)="deleteToggle = true">Delete</button>
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit"
        [disabled]="!reasonsForm.valid">Save</button>
    </footer>
  </form>
</div>
<!-- slider section end -->
<!-- delete model  -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Reason?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Reason')}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
          (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" [disabled]="deleteClicked"
          (click)="confirmDelete()">Delete</button>
      </div>
    </div>
  </div>
</div>
