import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-request-submit',
  templateUrl: './request-submit.component.html',
  styleUrls: ['./request-submit.component.scss']
})
export class RequestSubmitComponent implements OnInit {

  @Input() requestitem: any;
  @Input() form: any;
  @Input() from: any;
  @Input() error: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();



  constructor(private notificationService: NotificationService,private inboxservice: InboxService,public messageService : MessageService,
  public activityService:ManageActivityService
  ) { }

  ngOnInit(): void {

  }
  get f() {return  this.form.controls; }
  confirm(){
    var status: any;
    var flag = true;
    var type = ""
    if(this.requestitem?.request_type == "On Duty" )
      type = "On Duty Requests"
    else if(this.requestitem?.request_type == "Attendance Regularization" )
      type = "Attendance Regularization Requests"
    else if (this.requestitem?.request_type == "Check-in Request" )
      type = "Check-in Request"
    else
      type = this.requestitem?.request_type
    for(let i = 0;i<this.requestitem?.requests.length;i++){
      if(this.requestitem?.requests[i].status == 'Pending'){

        this.notificationService.handleErrorNotification(type+" cannot be submitted in pending state.","Pending "+type);
        return;
      }
      if(flag == true && this.requestitem?.requests[i].status == 'Approved'){
        status = 'Approved';
        flag = false;
      }
      if(flag == true && this.requestitem?.requests[i].status == 'Rejected'){
        status = 'Rejected';
      }
    }

    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){

      if(this.from=='behalf'){
        this.activityService.SaveClick = true
        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);
        // this.submitted          = false
        this.form.get('comment')?.reset('')


      }
      else{

        this.inboxservice.updateInboxItem(this.requestitem?.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
            // this.submitted          = false

          }
        })
      }
    }
  }

  countSelected(){
    var delArray:any = [];
    this.requestitem?.requests?.forEach((item:any, value:any) => {
      if(item?.checked == true )
        delArray.push(value)
    });
    return delArray.length
  }

  approveReject(val:any){

    if(this.countSelected() == 0)
      return;
    this.requestitem?.requests?.forEach((item:any, value:any) => {
      if(item?.checked == true ){
      if(this.from=='behalf'){
        if(this.requestitem?.request_type == "On Duty" ){
          item.checked = false;
          this.activityService.updateOndutyItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests[value].status = val;
          });
        }
        else if(this.requestitem?.request_type == "Attendance Regularization"){
          item.checked = false;
          this.activityService.updateRegularizationItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests[value].status = val;
          });
        }
        else if(this.requestitem?.request_type == "Check-in Request"){
          item.checked = false;
          this.activityService.updateCheckIn(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests[value].status = val;
          });
        }
      }
      else{

        if(this.requestitem?.request_type == "On Duty" ){
          item.checked = false;
          this.inboxservice.updateOndutyItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests[value].status = val;
          });
        }
        else if(this.requestitem?.request_type == "Attendance Regularization"){
          item.checked = false;
          this.inboxservice.updateRegularizationItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests[value].status = val;
          });
        }
        else if(this.requestitem?.request_type == "Check-in Request"){
          item.checked = false;
          this.inboxservice.updateCheckIn(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
            this.requestitem.requests[value].status = val;
          });
        }
      }
    }

    });
    this.notificationService.handleSuccessNotification("Updated Successfully","Success");
  }
  clearComment(){
    this.form.get('comment')?.reset('')

  }
}
