import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-compo-off-inbox',
  templateUrl: './compo-off-inbox.component.html',
  styleUrls: ['./compo-off-inbox.component.scss']
})
export class CompoOffInboxComponent implements OnInit {

  @Input() requestitem: any;
  @Input() from: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  // For view status
  billLoader                  = false;
  viewstatusPanel             = false;
  viewStatusData:any          = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;

  // For trend graph
  currentYear               =  new Date().getFullYear();
  selectedYearDisp          = String(this.currentYear);
  selectedYear              = this.currentYear;
  yearList: any             = [];
  empId: any;

  multi:any                 = [];

  timeOffTrend = false;

  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;
  sendReminder:any
  constructor(
    public inboxservice: InboxService,
    private fb: FormBuilder,
    private investmentService: InvestmentDeclerationService,
    public messageService: MessageService,
    public appservice: AppService,
    public activityService:ManageActivityService
  ) { }

  ngOnInit(): void {
    this.yearListCreate();
    this.sendReminder = this.from=='behalf'?true:false
  }

  form = this.fb.group({
    read_status      : [true,Validators.required],
    approved_status  : [false,Validators.required],
    comment          : ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls; }

  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,
      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  requestViewStatus(){
    this.viewstatusPanel  = true
    this.billLoader       = true;
     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getCompOffTrendDetails(event:any){
    this.barchartLoader   = true;
    this.selectedYearDisp = String(event)
    this.selectedYear     = event
    this.empId =  this.from=='behalf'?this.requestitem?.compoff.employee:this.requestitem?.from_person?.id
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getCompOffTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.multi = res?.data;
          if(this.multi?.length > 0)
            this.barChartDataConstruction(this.multi);
        }
      })
    }
  }

  confirm(status:any){

    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){
      if(this.from=='behalf'){
        this.activityService.SaveClick = true
        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);
        this.form.get('comment')?.reset('')

      }
      else{

        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
          }
        })
      }
    }
  }

   // Bar chart
   barChartDataConstruction(data:any){
    let approved:any         = [];
    let pending:any          = [];
    let declined:any         = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Days';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Approved"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else{
            declined.push(item1?.value)
          }
      });
    });

    this.barchart.push({label: "Approved", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: declined, backgroundColor: '#DC3545', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }
  // End
  clearComment(){
    this.form.get('comment')?.reset('')

  }
}
