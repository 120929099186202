import { AbstractControl, ValidatorFn, FormArray, FormGroup } from '@angular/forms';

export function dayRangeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const parentArray = control.parent as FormArray;
    if (!parentArray) return null;

    const currentIndex = parentArray.controls.indexOf(control);
    if (currentIndex === -1) return null;

    const dayStartControl = control.get('day_start');
    const dayEndControl = control.get('day_end');
    const dayStart = parseInt(dayStartControl?.value, 10);
    const dayEnd = parseInt(dayEndControl?.value, 10);
    let errors: { [key: string]: any } = {};

    const dayStartErrors = { ...dayStartControl?.errors };
    const dayEndErrors = { ...dayEndControl?.errors };

    removeSpecificError(dayStartErrors, 'invalidPreviousDayRange');
    removeSpecificError(dayEndErrors, 'invalidDayRange');
    removeSpecificError(dayEndErrors, 'invalidFinalDayRange');

    if (currentIndex > 0) {
      const prevControl = parentArray.at(currentIndex - 1) as FormGroup;
      const prevDayEnd = parseInt(prevControl.get('day_end')?.value, 10);

      if (!Number.isNaN(dayStart) && !Number.isNaN(prevDayEnd) && dayStart <= prevDayEnd) {
        errors['invalidPreviousDayRange'] = true;
        dayStartErrors['invalidPreviousDayRange'] = true;
      }
    }

    if (!Number.isNaN(dayStart) && !Number.isNaN(dayEnd) && dayStart >= dayEnd) {
      errors['invalidDayRange'] = true;
      dayEndErrors['invalidDayRange'] = true;
    }

    if (!Number.isNaN(dayEnd) && dayEnd >= 32) {
      errors['invalidFinalDayRange'] = true;
      dayEndErrors['invalidFinalDayRange'] = true;
    }

    dayStartControl?.setErrors(Object.keys(dayStartErrors).length ? dayStartErrors : null);
    dayEndControl?.setErrors(Object.keys(dayEndErrors).length ? dayEndErrors : null);

    if (currentIndex < parentArray.length - 1) {
      const nextControl = parentArray.at(currentIndex + 1) as FormGroup;
      nextControl.get('day_start')?.updateValueAndValidity();
    }

    return Object.keys(errors).length ? errors : null;
  };

  function removeSpecificError(errors: { [key: string]: any }, errorKey: string) {
    delete errors[errorKey];
  }
}