import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LeaveYearEndService } from 'src/app/@core/services/leave-year-end.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-pending-leave-request',
  templateUrl: './pending-leave-request.component.html',
  styleUrls: ['./pending-leave-request.component.scss']
})
export class PendingLeaveRequestComponent implements OnInit {

  policy_id       : any
  policy_typeId   : any
  year            : any
  loader          : boolean = false
  viewData        : any     = []
  searchString    : any     = ''
  count           : any
  objLength       : any
  downloadMsg     : boolean = false
  permissions     : any
  canSave         : boolean = false

  // Page
  page            = 1
  pageSize        = 20

  constructor(
    private route : ActivatedRoute,
    private leaveServ : LeaveYearEndService,
    public appServ : AppService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    // Getting ID
     this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id']) && !isNaN(params['typeId'] ) && !isNaN(params['year'] )){
        this.policy_typeId = +params['typeId']
        this.policy_id = +params['id'];
        this.year = +params['year']
        this.getPendingRequestData()
      }
    })
    // Permission
    this.getPermission()
  }

  // Main API
  getPendingRequestData(){
    this.loader = true
    this.leaveServ.getPendingLeave(this.policy_id,this.policy_typeId,this.year).subscribe((res:any)=>{
      this.viewData = res.body.data
      this.loader = false
    })
  }

   // CheckAll
   checkAll(value:any){
    let list = this.appServ.filterIt(this.viewData,this.searchString)
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = list.slice(start,end)
    slicedArray.forEach((item:any)=>{
      item.checked = value.target.checked
    })
    this.actualCount()
  }

  // Checked Property
  pageChange(){
    let list = this.appServ.filterIt(this.viewData,this.searchString)
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = list.slice(start,end)
    if(slicedArray.length){
      return slicedArray.every((item:any)=>{
        return item.checked
      })
    }else{
      return false;
    }
  }

  // Individual Checking
  individualData(value:any,id:any){
    this.actualCount()
  }

  actualCount(){
    this.count = this.viewData.filter((obj:any) =>obj.checked === true).length
    this.objLength = this.viewData.filter((obj:any) =>obj.checked === true)
  }

  // Cancel Selection
  cancelSelect(){
    this.appServ.unselectAll(this.viewData)
    this.actualCount()
  }

  // Whole Checked data
  getWholeData(){
    return this.viewData.filter((item:any)=> item.checked == true)
  }

  // Approve / Reject
  btnDisable : boolean = false
  rejectApproveRequest(status:any){
    this.btnDisable = true
    let empData = this.getWholeData()
    let ids = empData.map((item:any)=>{
      return item.employee_request_id
    })
    let dataArray = {
      emp_req_ids : ids,
      status : status,
      leave_mapping_id : this.policy_id
    }
    this.leaveServ.acceptRejectRequest(this.policy_id,dataArray).subscribe((res:any)=>{
      this.getPendingRequestData()
      this.btnDisable = false
      this.notificationService.handleSuccessNotification(`Leave request ${status.toLowerCase()} successfully`,"Success");
    },(error)=>{
      this.btnDisable = false
    })
  }

  // Reminder
  sendReminderModel(){
    this.downloadMsg = true
  }

  sendReminder(){
    this.canSave = true
    let checkedArr = this.getWholeData()
    let request_id : any = []
    let dataArray : any = {}
    if(checkedArr.length != 0){
      request_id = checkedArr.map((item:any)=>{
        return {"request_id" : item?.employee_request_id}
      })
      dataArray = { request_id : request_id , section: "Leave Year End Process"}
    }
    this.leaveServ.sendReminder(dataArray).subscribe((res:any)=>{
      this.downloadMsg = false
      this.notificationService.handleSuccessNotification("Reminder send successfully","Success");
      this.cancelSelect()
      this.getPendingRequestData()
      this.canSave = false
    })
  }

  // Export
  export(){
    let exportData : any =[]
    for(let i=0;i< this.viewData.length;i++){
        let accepted_by = this.viewData[i]['accepted_by'].map((item:any)=>{
          return item.name + ' ('+item.employee_code+')'
        })
      exportData.push({
        'Sl No.' : i+1,
        'Employee Code' : this.viewData[i]['employee_code'],
        'Employee Name' :  this.viewData[i]['employee_name'],
        'Company' : this.viewData[i]['company_name'],
        'Business Unit' : this.viewData[i]['bu_name'],
        'Leave Code' : this.viewData[i]['leave_code'],
        'From Date' : this.appServ.dateFormatDisplay(this.viewData[i]['from_date']),
        'To Date' : this.appServ.dateFormatDisplay(this.viewData[i]['to_date']),
        'Leave Days' : this.viewData[i]['leave_days'],
        'Pending With' : accepted_by.join(','),
      })
    }
    this.appServ.exportExcel(exportData,'Pending Leave Request Report')
    this.getPendingRequestData()
  }

  // Permission
  getPermission(){
    this.permissions = this.appServ.getPermissions('/leave-year-end');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
