<div class="container-fluid p-32 form-container" [formGroup]="formGp">
    <div class="row ">
        <p class="text-uppercase fw-500">COMPENSATORY OFF</p>
    </div>
    <div class="row row-16">
        <div class="col-12 form-row">
            <label for="" class="form-label  ">Do you want to enable comp off?</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Enable this option if your company allows comp-off"
                    popoverClass="popover-input popover-info" triggers="hover"></i></span>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="enable_compoff" class="form-check-input" type="radio" [value]=true
                        (click)="setDisabled(true,disData);setFormula()">
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="enable_compoff" class="form-check-input" type="radio" [value]=false
                    (click)="setDisabled(false,disNoData)">
                    No
                </label>
            </div>
        </div>
        <ng-container *ngIf="f.enable_compoff.value == true && (!isconfirmed || activeComp)">
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Minimum working hour for full day compensatory off
                  <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Set the minimum working hours for full day compensatory off."
                    popoverClass="popover-input popover-info" triggers="hover"></i></span>
                </label>
                <div class="form-icon icon-right"
                    [ngClass]="{ 'is-invalid': (submitted || f.min_hour_full_day.touched || f.min_hour_full_day.dirty) &&  f.min_hour_full_day.errors ||  f.min_hour_full_day.errors?.fullZero}">
                    <igx-time-picker formControlName="min_hour_full_day" class="form-control "
                        placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                        [ngClass]="{ 'is-invalid': (submitted || f.min_hour_full_day.touched || f.min_hour_full_day.dirty) &&  f.min_hour_full_day.errors ||  f.min_hour_full_day.errors?.fullZero}"
                        class="form-control form-timepicker " (click)="picker2.open()" #picker2
                        (ngModelChange)="fullDay()">
                    </igx-time-picker>
                    <i *ngIf="f.min_hour_full_day.value!=null" class="icon-close text-light-400"
                        (click)="clearTime(picker2);picker2.close()"></i>
                </div>

                <div *ngIf="(submitted || f.min_hour_full_day.touched || f.min_hour_full_day.dirty) && f.min_hour_full_day.errors || f.min_hour_full_day.errors?.fullZero"
                    class="invalid-feedback">
                    <div *ngIf="f.min_hour_full_day.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.min_hour_full_day.hasError('fullZero')">Value cannot be set as 00:00</div>
                </div>

            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Minimum working hour for half day compensatory off
                  <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Set the minimum working hours for half day compensatory off."
                    popoverClass="popover-input popover-info" triggers="hover"></i></span>
                </label>
                <div class="form-icon icon-right"
                    [ngClass]="{ 'is-invalid': (submitted || f.min_hour_half_day.touched || f.min_hour_half_day.dirty ) &&  f.min_hour_half_day.errors || f.min_hour_half_day.errors?.halfZero}">
                    <igx-time-picker formControlName="min_hour_half_day" class="form-control "
                        placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                        [ngClass]="{ 'is-invalid': (submitted || f.min_hour_half_day.touched || f.min_hour_half_day.dirty) &&  f.min_hour_half_day.errors || f.min_hour_half_day.errors?.halfZero}"
                        class="form-control form-timepicker " (click)="picker3.open()" #picker3
                        [maxValue]="f.min_hour_full_day.value" (ngModelChange)="halfDay()"></igx-time-picker>

                    <i *ngIf="f.min_hour_half_day.value!=null" class="icon-close text-light-400"
                        (click)="clearTime(picker3);picker3.close()"></i>
                </div>

                <div *ngIf="(submitted || f.min_hour_half_day.touched || f.min_hour_half_day.dirty) && f.min_hour_half_day.errors || f.min_hour_half_day.errors?.halfZero"
                    class="invalid-feedback">
                    <div *ngIf="f.min_hour_half_day.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.min_hour_half_day.errors?.maxValue">Minimum working hour for half day compensatory off
                        should not exceed Minimum working hour for full day</div>
                    <div *ngIf="f.min_hour_half_day.hasError('halfZero')">Value cannot be set as 00:00</div>
                    <div *ngIf="f.min_hour_half_day.hasError('samefull')">Value cannot be same as full day compensatory off</div>

                </div>
            </div>
            <div class="col-12 form-row">
                <label for="" class="form-label">Do you want auto initiate extra working benefit </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Selecting 'Yes' will automatically initiate the extra working benefits for approval, whereas selecting 'No' will be manually initiated by the employee for approval."
                        popoverClass="popover-input popover-info" triggers="hover"></i></span>

            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="auto_initiate_extra_working_benefit" class="form-check-input"
                            type="radio" [value]=true>
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="auto_initiate_extra_working_benefit" class="form-check-input"
                            type="radio" [value]=false>
                        No
                    </label>
                </div>
            </div>
            <!-- New Modification -->
            <div class="col-12 form-row">
                <label for="" class="form-label">Do you want to set comp off configuration for weekoff</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option if you want to set comp-off for week off."
                  popoverClass="popover-input popover-info" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_weekoff" class="form-check-input" type="radio" [value]=true>
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_weekoff" class="form-check-input" type="radio" [value]=false>
                        No
                    </label>
                </div>

            </div>

            <div class="col-12 form-row">
                <label for="" class="form-label">Do you want to set comp off configuration for holiday</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option if you want to set comp-off for Holiday."
                  popoverClass="popover-input popover-info" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_holiday" class="form-check-input" type="radio" [value]=true>
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_holiday" class="form-check-input" type="radio" [value]=false>
                        No
                    </label>
                </div>

            </div>
            <div class="col-12 form-row">
                <label for="" class="form-label">Do you want to set comp off configuration for national holiday</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option if you want to set comp-off for National Holiday."
                  popoverClass="popover-input popover-info" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_nationalholiday" class="form-check-input" type="radio"
                            [value]=true>
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_nationalholiday" class="form-check-input" type="radio"
                            [value]=false>
                        No
                    </label>
                </div>

            </div>
            <div class="col-12 form-row">
                <label for="" class="form-label">Do you want to set comp off configuration for workdays</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enable this option if you want to set comp-off for work days"
                        popoverClass="popover-input popover-info" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_workdays" class="form-check-input" type="radio" [value]=true
                            (click)="setDisabled(true,['comp_off_eligibility_fulldays'])">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_workdays"
                            (click)="setDisabled(false,['comp_off_eligibility_fulldays'])" class="form-check-input"
                            type="radio" [value]=false>
                        No
                    </label>
                </div>
            </div>

            <div class="col-12 form-row" *ngIf="f.comp_off_workdays.value == true">
                <label for="" class="form-label required">Formula to calculate comp off eligibility during
                    workdays</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Select any of the  following formula to define  comp-off for the  working day:
            Working hours - Shift hours : It means working hour for comp-off will be calculated as Total Working Hours of employee minus shift hours of employee.
            Working hours - Minimum hours for full day : It means working hour for comp-off will be calculated as Total Working Hours of employee minus minimum working hours of employee.
            Max Check Out Time - Shift End Time Actual: It means working hour for comp off check will be calculated as the Last check out time of the employee minus Shift end time."
                        popoverClass="popover-input popover-info" triggers="hover"></i></span>
                <ng-select formControlName="comp_off_eligibility_fulldays"
                    [ngClass]="{ 'is-invalid': (submitted || f.comp_off_eligibility_fulldays.touched || f.comp_off_eligibility_fulldays.dirty) &&  f.comp_off_eligibility_fulldays.errors}"
                    class="form-ngselect " placeholder="{{this.messageService.selectplaceholddisplay('value')}}">
                    <ng-option [value]="'Working hours - Shift hours'">Working hours - Shift hours</ng-option>
                    <ng-option [value]="'Working hours - Minimum hours for full day'">Working hours - Minimum hours for
                        full day</ng-option>
                    <ng-option [value]="'Max check out time - Shift end time actual'">Max check out time - Shift end
                        time</ng-option>
                </ng-select>
                <div *ngIf="(submitted || f.comp_off_eligibility_fulldays.touched || f.comp_off_eligibility_fulldays.dirty) && f.comp_off_eligibility_fulldays.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.comp_off_eligibility_fulldays.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
            <!-- New Modification -->
            <div class="col-12 form-row">
                <label for="" class="form-label">Do you want to set comp off configuration for half working day</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="If you want to set half working day as compensatory off enable this option."
                        popoverClass="popover-input popover-info" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_halfday_work" class="form-check-input" type="radio"
                            [value]=true (click)="setDisabled(true,['comp_off_eligibility_halfdays'])">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_halfday_work"
                            (click)="setDisabled(false,['comp_off_eligibility_halfdays'])" class="form-check-input"
                            type="radio" [value]=false>
                        No
                    </label>
                </div>
            </div>

            <div class="col-12 form-row" *ngIf="f.comp_off_halfday_work.value == true">
                <label for="" class="form-label required">Formula to calculate comp off eligibility during half working
                    days</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="
        To calculate the compensatory off for a half working day, you can use one of the following formulas:
        1: Working hours - Shift hours (First Half/Second Half)
        This formula calculates the compensatory off by subtracting the shift hours (either for the first half or second half) from the total working hours.
        2: Working hours - Minimum hours for half day
        This formula calculates the compensatory off by subtracting the minimum required hours for a half day from the total working hours." popoverClass="popover-input popover-info"
                        triggers="hover"></i></span>
                <ng-select formControlName="comp_off_eligibility_halfdays"
                    [ngClass]="{ 'is-invalid': (submitted || f.comp_off_eligibility_halfdays.touched || f.comp_off_eligibility_halfdays.dirty) &&  f.comp_off_eligibility_halfdays.errors}"
                    class="form-ngselect " placeholder="{{this.messageService.selectplaceholddisplay('value')}}">
                    <ng-option [value]="'Working hours - Shift hours (First Half/Second half)'">Working hours - Shift
                        hours (First Half/Second half)</ng-option>
                    <ng-option [value]="'Working hours - Minimum hours for half day Actual'">Working hours - Minimum
                        hours for half day</ng-option>
                </ng-select>
                <div *ngIf="(submitted || f.comp_off_eligibility_halfdays.touched || f.comp_off_eligibility_halfdays.dirty) && f.comp_off_eligibility_halfdays.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.comp_off_eligibility_halfdays.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
            <!-- ---------------- -->
            <div class="col-12 form-row">
                <label for="" class="form-label">Comp off will expire </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enable this option If you want to set your company’s comp-off to expire after certain period of time."
                        popoverClass="popover-input popover-info" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_expiry" class="form-check-input" type="radio" [value]=true
                            (click)="setDisabled(true,['after_how_many_days'])">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="comp_off_expiry" class="form-check-input" type="radio" [value]=false
                            (click)="setDisabled(false,['after_how_many_days'])">
                        No
                    </label>
                </div>
            </div>

            <div class=" col-12 form-row" *ngIf="f.comp_off_expiry.value == true">
                <label for="" class="form-label required">After how many days </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Specify after how many days the compensatory off should get expired."
                        popoverClass="popover-input popover-info" triggers="hover"></i></span>
                <input formControlName="after_how_many_days"
                    [ngClass]="{ 'is-invalid': (submitted || f.after_how_many_days.touched || f.after_how_many_days.dirty) &&  f.after_how_many_days.errors}"
                    pattern="^-?\d+$" type="text" class="form-control "
                    placeholder="{{this.messageService.placeholderdisp('Days')}}">
                <div *ngIf="(submitted || f.after_how_many_days.touched || f.after_how_many_days.dirty) && f.after_how_many_days.errors"
                    class="invalid-feedback">

                    <div *ngIf="f.after_how_many_days.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div
                        *ngIf="!f.after_how_many_days.errors?.pattern && f.after_how_many_days.hasError('max')">
                        {{this.messageService.fieldlengthvalidation('days','365')}}
                    </div>
                    <div
                      *ngIf="!f.after_how_many_days.errors?.pattern && f.after_how_many_days.hasError('min')">
                      {{this.messageService.fieldlengthvalidation('minvalue','0')}}
                    </div>
                    <div
                        *ngIf="f.after_how_many_days.errors?.pattern || notanumber(f.after_how_many_days.errors.pattern?.actualValue)">
                        {{this.messageService.validationDisplay('pattern')}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<footer class="submit-footer">

    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
        (click)="validateForm()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
</footer>

<div class="modal  modal-alert {{isconfirmed == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16 ">
              <i class="bi bi-info-circle text-accent2"></i>
              <h4 class="modal-title">Enable Compensatory Off?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">The overtime benefit has already been enabled for employees who work on weekends. "Do you wish to enable compensatory off benefits as well?"</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="activateCompOff(false)">Cancel</button>
              <button type="button" class="btn btn-primary btn-sm text-uppercase"  [disabled]="isClicked" (click)="activateCompOff(true)">Enable</button>
          </div>
      </div>
  </div>
</div>
