<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row row-16 ">
      <div class="col-12">
        <p class="text-uppercase fw-500 mb-0">CONFIGURE Shift allowance </p>
      </div>
      <div class="col-12">
        <label for="" class="form-label required"> Do you want to set shift allowance calculation for workdays?
        </label>
      </div>
      <div class="col-12 mt-8 form-row">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
              <input class="form-check-input" formControlName="set_shiftallowance_workday" type="radio"
                  [value]=true (ngModelChange)="setRequired(true,'min_shiftallowance_hours_workday');">
              Yes
          </label>
      </div>
      <div class="form-check form-check-inline">
          <label class="form-check-label">
              <input formControlName="set_shiftallowance_workday" class="form-check-input" type="radio"
                  [value]=false (ngModelChange)="setRequired(false,'min_shiftallowance_hours_workday');">
              No
          </label>
      </div>
      </div>
      <div class="col-12" *ngIf="f.set_shiftallowance_workday.value">
        <label for="" class="form-label required">Minimum work hours in a day required for shift allowance eligibilty
        </label>
        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hours_workday?.touched || f.min_shiftallowance_hours_workday?.dirty) &&  f.min_shiftallowance_hours_workday.errors) }" >
          <igx-time-picker formControlName="min_shiftallowance_hours_workday"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hours_workday?.touched|| f.min_shiftallowance_hours_workday?.dirty) &&  f.min_shiftallowance_hours_workday.errors) }"
              class="form-control form-timepicker "
              (click)="picker2.open()" #picker2
              >
          </igx-time-picker>
          <i *ngIf="f.min_shiftallowance_hours_workday.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker2);picker2.close()"></i></div>

          <div *ngIf="((submitted || f.min_shiftallowance_hours_workday?.touched || f.min_shiftallowance_hours_workday?.dirty) && f.min_shiftallowance_hours_workday.errors) " class="invalid-feedback">
              <div *ngIf="f.min_shiftallowance_hours_workday.errors?.required">{{this.messageService.validationDisplay('required')}}
              </div>              
          </div>
      </div>
      <div class="col-12" >
        <label for="" class="form-label required"> Do you want to set shift allowance calculation for weekoffs?
        </label>
      </div>
      <div class="col-12 mt-8 form-row">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
              <input class="form-check-input" formControlName="set_shiftallowance_weekoff" type="radio"
                  [value]=true (ngModelChange)="setRequired(true,'min_shiftallowance_hours_weekoff');">
              Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label">
                <input formControlName="set_shiftallowance_weekoff" class="form-check-input" type="radio"
                    [value]=false (ngModelChange)="setRequired(false,'min_shiftallowance_hours_weekoff');">
                No
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="f.set_shiftallowance_weekoff.value">
        <label for="" class="form-label required">Minimum work hours in a day required for shift allowance eligibilty
        </label>       
        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hours_weekoff?.touched || f.min_shiftallowance_hours_weekoff?.dirty) &&  f.min_shiftallowance_hours_weekoff.errors) }" >
          <igx-time-picker formControlName="min_shiftallowance_hours_weekoff"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hours_weekoff?.touched|| f.min_shiftallowance_hours_weekoff?.dirty) &&  f.min_shiftallowance_hours_weekoff.errors) }"
              class="form-control form-timepicker "
              (click)="picker2.open()" #picker2
              >
          </igx-time-picker>
          <i *ngIf="f.min_shiftallowance_hours_weekoff.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker2);picker2.close()"></i></div>

          <div *ngIf="((submitted || f.min_shiftallowance_hours_weekoff?.touched || f.min_shiftallowance_hours_weekoff?.dirty) && f.min_shiftallowance_hours_weekoff.errors) " class="invalid-feedback">
              <div *ngIf="f.min_shiftallowance_hours_weekoff.errors?.required">{{this.messageService.validationDisplay('required')}}
              </div>
              
          </div>
      </div>

      <div class="col-12">
        <label for="" class="form-label required"> Do you want to set shift allowance calculation for holidays?
        </label>
      </div>
      <div class="col-12 mt-8 form-row">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
              <input class="form-check-input" formControlName="set_shiftallowance_holiday" type="radio"
                  [value]=true (ngModelChange)="setRequired(true,'min_shiftallowance_hours_holiday');">
              Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label">
                <input formControlName="set_shiftallowance_holiday" class="form-check-input" type="radio"
                    [value]=false (ngModelChange)="setRequired(false,'min_shiftallowance_hours_holiday');">
                No
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="f.set_shiftallowance_holiday.value">
        <label for="" class="form-label required">Minimum work hours in a day required for shift allowance eligibilty
        </label>
        
        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hours_holiday?.touched || f.min_shiftallowance_hours_holiday?.dirty) &&  f.min_shiftallowance_hours_holiday.errors) }" >
          <igx-time-picker formControlName="min_shiftallowance_hours_holiday"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hours_holiday?.touched|| f.min_shiftallowance_hours_holiday?.dirty) &&  f.min_shiftallowance_hours_holiday.errors) }"
              class="form-control form-timepicker "
              (click)="picker2.open()" #picker2
              >
          </igx-time-picker>
          <i *ngIf="f.min_shiftallowance_hours_holiday.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker2);picker2.close()"></i></div>
      
          <div *ngIf="((submitted || f.min_shiftallowance_hours_holiday?.touched || f.min_shiftallowance_hours_holiday?.dirty) && f.min_shiftallowance_hours_holiday.errors) " class="invalid-feedback">
              <div *ngIf="f.min_shiftallowance_hours_holiday.errors?.required">{{this.messageService.validationDisplay('required')}}
              </div>              
          </div>
      </div>

      <div class="col-12">
        <label for="" class="form-label required"> Do you want to set shift allowance calculation for half working
          days?</label>
      </div>
      <div class="col-12 mt-8 form-row">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
              <input class="form-check-input" formControlName="set_shiftallowance_half_workday" type="radio"
                  [value]=true (ngModelChange)="setRequired(true,'min_shiftallowance_hour_half_workday');">
              Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label">
                <input formControlName="set_shiftallowance_half_workday" class="form-check-input" type="radio"
                    [value]=false (ngModelChange)="setRequired(false,'min_shiftallowance_hour_half_workday');">
                No
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="f.set_shiftallowance_half_workday.value">
        <label for="" class="form-label required">Minimum work hours in a day required for shift allowance eligibilty
        </label>
        
        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hour_half_workday?.touched || f.min_shiftallowance_hour_half_workday?.dirty) &&  f.min_shiftallowance_hour_half_workday.errors) }" >
          <igx-time-picker formControlName="min_shiftallowance_hour_half_workday"
              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': ((submitted || f.min_shiftallowance_hour_half_workday?.touched|| f.min_shiftallowance_hour_half_workday?.dirty) &&  f.min_shiftallowance_hour_half_workday.errors) }"
              class="form-control form-timepicker "
              (click)="picker2.open()" #picker2
              >
          </igx-time-picker>
          <!-- [minValue]="f.shift_start_time.value" -->
          <i *ngIf="f.min_shiftallowance_hour_half_workday.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker2);picker2.close()"></i></div>
      
          <div *ngIf="((submitted || f.min_shiftallowance_hour_half_workday?.touched || f.min_shiftallowance_hour_half_workday?.dirty) && f.min_shiftallowance_hour_half_workday.errors) " class="invalid-feedback">
              <div *ngIf="f.min_shiftallowance_hour_half_workday.errors?.required">{{this.messageService.validationDisplay('required')}}
              </div>
              <div *ngIf="f.min_shiftallowance_hour_half_workday.errors?.same && !f.min_shiftallowance_hour_half_workday.errors?.required">{{this.messageService.sameasvalidation( 'Shift End Time','Shift Start Time')}}</div>
          </div>      </div>

      <div class="col-12">
        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16 ">
            <div class="col-12 align-items-center d-flex">
              <p class="title mb-8">SHIFT ALLOWANCE COMPENSATION CRITERIA</p>
            </div>
          <div class="col-12">
          <div class="row row-16">
            <ng-container  formArrayName="allowance_shift_map">
              <ng-container *ngFor="let shiftlist of shifts()?.controls; let shiftindex=index;" [formGroupName]="shiftindex">              
                  <div class="col-12"  *ngIf="activeIndex != shiftindex ">
                    <div class="card card-c2">
                      <div class="card-body px-24 py-10 d-flex align-items-center">
                          <span class="me-10 text-trim" title=" ">{{shifts().controls[shiftindex]?.get('shift_name')?.value}}</span>
                          <i class="icon-check-circle ms-8 fs-18 text-success" *ngIf="shiftpayrates(shiftindex).valid"></i>
                          <a class="link-primary align-items-center fs-14 d-flex ms-auto" (click)="editshift(shiftlist,shiftindex)">
                            <i class="icon-pencil-square me-1"></i>Edit
                          </a>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="editshifts && activeIndex == shiftindex"  >
                    <div class="col-12">
                      <div class="card card-c2">
                        <div class="card-body d-flex p-0 bg-white position-sticky border-bottom align-items-center"
                          style="top:3.625rem">
                          <p class="mb-0 py-16 ps-24 w-25 text-trim">{{shifts().controls[shiftindex]?.get('shift_name')?.value}}</p>
                          <i class="bi bi-caret-up-fill py-16 pe-24 ms-auto" (click)="Canceledit()"></i>
                        </div>
                        <div class="card-body p-24 border-top-0">
                          <div class="row row-16">
                            <div class="col-md-6">
                              <label for="" class="form-label required">Shift Code </label>
                              <input type="text" formControlName="shift_code" class="form-control" [readonly]="true">
                            </div>
                            <div class="col-md-6">
                              <label for="" class="form-label required">Shift Name </label>
                              <input type="text" class="form-control" formControlName="shift_name" [readonly]="true">
                            </div>                  
                            
                                <ng-container  formArrayName="shift_allowance_mapping">
                                  <ng-container *ngFor="let map of shiftpayrates(shiftindex)?.controls; let mapindex=index;" [formGroupName]="mapindex">                                
                                        <div class="col-4">
                                            <label for="" class="form-label required">Worked from(Days) </label>
                                        </div>
                                        <div class="col-4">
                                            <label for="" class="form-label required">To(Days) </label>
                                        </div>
                                        <div class="col">
                                            <label for="" class="form-label required">Pay rate</label>
                                        </div>
                                        <div style="width:2.5rem" *ngIf="mapindex!=0">&nbsp;</div>

                                        <div class="col-12" >
                                            <div class="align-items-start d-flex flex-nowrap row row-16">
                                              <div class="col-4 form-row mt-0">
                                                <input type="text" class="form-control "  placeholder="Enter day" id="day_start"   formControlName="day_start" [ngClass]="{ 'is-invalid': (shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.touched || shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.dirty) &&  (shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.errors)}" (keyup)="validatedays(shiftindex,mapindex,'day_start')">
                                                    <div *ngIf="(shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.touched || shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.dirty) && 
                                                    (shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.errors )" class="invalid-feedback">
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.errors?.required">
                                                          {{this.messageService.validationDisplay('required')}}
                                                      </div>
                                                        <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.errors?.max">
                                                          {{this.messageService.fieldlengthvalidation('value',30)}}</div>
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.errors?.min">
                                                        {{this.messageService.validationDisplay('pattern')}}
                                                      </div>
                                                        <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_start')?.errors?.invalidPreviousDayRange">
                                                          'From' day must be greater than the previous 'To' day.
                                                        </div>
                                                    </div>
                                              </div>
                                                <div class="col-4 form-row mt-0">
                                                    <input type="text" class="form-control "  placeholder="Enter day" id="day_end" formControlName="day_end" [ngClass]="{ 'is-invalid': (shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.touched || shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.dirty) && ( shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors)}" (keyup)="validatedays(shiftindex,mapindex,'day_end')">
                                                    <div *ngIf="(shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.touched || shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.dirty) && (shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors)" class="invalid-feedback">
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}
                                                      </div>
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors?.pattern">
                                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors?.max || shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors?.invalidFinalDayRange">
                                                      {{this.messageService.fieldlengthvalidation('value',31)}}</div>
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('day_end')?.errors?.invalidDayRange">
                                                        'To' day must be greater than the 'From' day.
                                                      </div>                                          
                                                    </div>
                                                  </div>
                                                <div class="col form-row mt-0">
                                                    <input type="text" class="form-control " formControlName="pay_rate" placeholder="Enter Count" [ngClass]="{ 'is-invalid': (shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.touched || shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.dirty) &&  shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.errors}">
                                                    <div *ngIf="(shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.touched || shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.dirty) && shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.errors" class="invalid-feedback">
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.errors?.required">
                                                          {{this.messageService.validationDisplay('required')}}
                                                      </div> 
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.errors?.maxLength">
                                                         {{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                      <div *ngIf="shiftpayrates(shiftindex).controls[mapindex]?.get('pay_rate')?.errors?.pattern">
                                                          {{this.messageService.validationDisplay('pattern')}}
                                                        </div>                                                      
                                                    </div>
                                                </div>
                                              <a class="flex-center sq-40 text-danger mt-0" *ngIf="mapindex!=0" (click)="removeshiftpayrate(shiftindex,mapindex)">
                                                  <i class="icon-trash "></i>
                                              </a>
                                            </div>
                                        </div>
                                  </ng-container>
                                </ng-container>
                                  <div class="col-12 mt-8">
                                      <div class="link-primary1 fs-14" (click)="addshiftpayrate(shiftindex)">
                                          <i class="icon-plus-square-fill me-8" ></i>Add
                                      </div>
                                  </div>
                              <!-- </ng-container>                        
                            </ng-container>  -->
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </ng-container>
              </ng-container>    
            </ng-container>  
          </div>  
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" [disabled]="!formGp.valid " (click)="validateshiftdetail()" class="btn btn-primary btn-sm ms-auto">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateshiftdetail()">NEXT</button>
  </footer>
</form>