<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Held Salaries Till {{month}} {{year}}</h5>
        <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <main  class=" config-container">
        <!-- <form class=""> -->
            <div class="container-fluid p-32 form-container">
                <div class="row row-16">
                    <div class="col-12 d-flex align-items-center">
                        <span class="fw-500 text-uppercase">HELD SALARIES  till {{month}} {{year}}</span>
                        <div class="d-flex align-items-center gap-16 ms-auto ">
                            <div style="min-width:22.688rem;">
                                <div class="form-icon2">
                                  <i class="icon-search icon-left fs-14"></i>
                                  <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                                  <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                                </div>
                            </div>
                            <button class="btn-square btn btn-secondary" (click)="filter  = true">
                                <i class="icon-filter-left fs-13"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 p-0">
                            <app-table-loader *ngIf="loader"></app-table-loader>

                            <div *ngIf="!loader" class="table-responsive radius-4">
                                <table class="table  td-12  th-12 sticky-header td-white-space td-fs-14
                        tr-fs-14 table-striped table-sm form-table">
                                    <thead>
                                        <tr>

                                            <th scope="col" class="form-cell">
                                                <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                                            </th>
                                            <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                            <th class="fw-600" scope="col">Employee code</th>
                                            <th class="fw-600" scope="col">PAY SALARY FOR</th>
                                            <th class="fw-600 col-2" scope="col">Total work days</th>
                                            <th class="fw-600" scope="col">PAID DAYS</th>
                                            <th class="fw-600" scope="col">NET PAY</th>
                                            <th class="fw-600 " scope="col" >Status</th>
                                            <th class="fw-600" scope="col">DETAILS </th>
                                        </tr>
                                    </thead>
                                    <tbody class="cell-16 height-full">
                                        <tr *ngIf="items.length<=0">
                                            <td colspan="9">
                                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                  <span class="fw-500 fs-14">No data found</span>
                                                </div>
                                              </td>
                                        </tr>
                                        <tr *ngFor="let item of $any(items |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                                 <td class="form-cell">
                                                    <input [ngClass]="'page'+page" class="form-check-input m-0" type="checkbox"
                                                [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" value="{{item.id}}" >
                                                </td>
                                                <td  class="text-trim w-15ch" data-toggle="tooltip" title="{{item.employee_name}}">{{item.employee_name}}</td>
                                                <td>{{item.employee_code}}</td>
                                                <td>{{item.pay_month}}, {{item.pay_year}}</td>
                                                <td >{{item?.work_day}}</td>
                                                <td >{{item.paid_day}}</td>
                                                <!-- <td>{{this.appservice.roundUp(item.gross_pay) | currency:item.currency}}</td> -->
                                                <td>{{(item.net_pay) | currency:item.currency}}</td>
                                                <td>
                                                  <span *ngIf="item.status=='Publish'"  class="badge  badge-success py-1">Published</span>
                                                  <span *ngIf="item.status=='Processed'"  class="badge badge-warning py-1">Processed</span>
                                                  <span *ngIf="item.status=='Hold'"  class="badge badge-primary py-1">Held</span>
                                                  <span *ngIf="item.status=='Freeze'"  class="badge badge-holiday py-1">Freezed</span>

                                                </td>
                                                <td><span (click)="viewBreakUp(item.id,item.status,item.pay_year,item.pay_month)" class="link-primary1"><i
                                                    class="icon-eye me-1"></i>View breakup</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="filteredcount()>pageSize">
                        <ngb-pagination class="d-flex justify-content-end"  [collectionSize]="filteredcount()"  [pageSize]="pageSize" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                </div>
            </div>
            <footer class="submit-footer gap-16">
                <p class="fs-14  d-flex gap-8 mb-0">
                    <span class="">{{countSelected()}} of {{items?.length}} selected </span>
                    <span *ngIf="items.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{items?.length}} employees</span>
                    <span *ngIf="items.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{items?.length}} employees</span>
                </p>
                <button [disabled]="countSelected()==0" (click)="createSelectedLIst();"  class="btn btn-primary btn-sm  text-uppercase" >RELEASE SALARY</button>
            </footer>
        <!-- </form> -->
    </main>
</div>
<!--Slider -->
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>

<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='viewBreakup === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="viewBreakup = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <div class="side-panel-body" style="overflow-y: auto;">
            <app-view-breakup-details
            [componentLoader]="componentLoader"
            [viewBreakUpDetails]="viewBreakUpDetails"></app-view-breakup-details>
        </div>

    </div>
</div>

<div class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
            <h4 class="modal-title">{{selectedList.length}} Employee(s) selected</h4>
            <button type="button" class="btn-close ms-auto my-0" (click)="downloadMsg = false" ></button>
            </div>
            <div class="modal-body">
                <p class="mb-0"> {{message}}</p>
            </div>
            <div class="modal-footer">
            <button [disabled]="saveComplete" type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="downloadMsg = false;" >Cancel</button>
            <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase" (click)="releaseSalary()">Release Salary</button>
            </div>
        </div>
    </div>
</div>
