
    <div *ngIf="employeeDetails" class="container-fluid p-32  form-container"  [formGroup]="formGp">

      <div class="row row-16"  >
        <div class="col-12 mt-16" *ngIf="Isalert && employeeDetails.message!=''">
          <div role="alert" class="alert alert-warning mb-0 show">
              <p class="mb-0 fs-14">{{employeeDetails.message}}</p>
              <div class="btn-close pointer" (click)="Isalert=false;"></div>
          </div>
        </div>
          <div class="col-12">
              <div class="card details-card">
                  <div class="card-body p-16 d-flex align-items-start">
                        <img *ngIf="employeeDetails.employee_data?.profile_image !=  null && employeeDetails.employee_data?.profile_image !=  ''" class="img-fluid avatar-circle  sq-42 me-16"  src="{{employeeDetails.employee_data.profile_image}}" />

                        <div *ngIf="employeeDetails.employee_data?.profile_image ==  '' || employeeDetails.employee_data?.profile_image ==  null" class="avatar-circle avatar-border sq-42  me-16 {{employeeDetails.employee_data?.color_code}} fs-14">{{this.appService.getFirstChar(employeeDetails.employee_data?.first_name+" "+employeeDetails.employee_data?.last_name,2)}}</div>
                      <div class="d-flex gap-6 flex-column overflow-hidden">
                          <div class="fs-14 fw-600">{{employeeDetails.employee_data?.first_name+" "+(employeeDetails.employee_data?.middle_name!=null?employeeDetails.employee_data?.middle_name:'')+" "+employeeDetails.employee_data?.last_name}} ({{employeeDetails.employee_data?.employee_code}})</div>
                          <div class="fs-12 fw-500 text-light-400 text-trim">{{employeeDetails.employee_data?.designation_name}} | {{employeeDetails.employee_data?.company__company_name}}</div>
                      </div>
                      <div *ngIf="permissions?.v" class=" ms-auto link-primary1 fs-12 fw-500" (click)="salaryRevisionHistory = true" > <i class="icon-eye me-1"></i> Salary Revision History</div>
                  </div>
                  <div class="card-body p-16">
                    <div class="row">
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Current pay group</div>
                            <div class="detail-desc">{{employeeDetails.pay_group_name}}</div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Current annual CTC</div>
                            <div class="detail-desc">{{employeeDetails.ctc| currency:employeeDetails.currency}}</div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Current monthly CTC</div>
                            <div class="detail-desc">{{employeeDetails.monthly_payout| currency:employeeDetails.currency}}</div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        <div class="col-12">
            <div class="text-uppercase fw-500">SALARY DETAILS</div>
        </div>
        <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Designation </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the employee's revised designation." popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
            <ng-select dropdownPosition="top" [ngClass]="{ 'is-invalid': (submitted || f.designation.touched) && f.designation.errors }" class="form-ngselect"  formControlName="designation" placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}">
                <ng-option *ngFor="let item of DesignationDD" [value]="item.id">{{ item?.name }}</ng-option>
            </ng-select>
            <div *ngIf="(submitted || f.designation.touched) && f.designation.errors" class="invalid-feedback">
                <div *ngIf="f.designation.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
            </div>
        </div>
        <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Pay group </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the employee's newly revised paygroup." popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Pay Group')}}" formControlName="pay_group" class="form-ngselect"  [ngClass]="{ 'is-invalid':   (submitted || f.pay_group.touched) && f.pay_group.errors}">
                <ng-option *ngFor="let pay_group of payGroupDD" [value]="pay_group.id">{{pay_group?.name}}</ng-option>
            </ng-select>
            <div *ngIf="(submitted || f.pay_group.touched) && f.pay_group.errors" class="invalid-feedback"><div *ngIf="f.pay_group.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.pay_group.errors.conflict">{{f.pay_group.errors.conflict}}
                </div>

            </div>
        </div>
        <div class="col-12"> <label for="" class="form-label required mb-0">Revise CTC </label>
            <i class="bi bi-info-circle text-accent4 ms-2 fs-12 tooltip-icon" ngbPopover="Salary revision is a process of updating or revising the salary of an employee" popoverClass="popover-default popover-info" container="body" triggers="hover"></i></div>
        <div class="col-md-12 form-row">
            <div class="form-check form-check-inline" >
                <label for="regime1" class="form-check-label">
                  <input id="regime1" type="radio" [value]="false" formControlName="rev_type" class="form-check-input" (ngModelChange)="addConditionalValidation('revise_ctc')">
                New CTC Amount</label>
            </div>
            <div class="form-check form-check-inline">
                <label for="regime2" class="form-check-label">
                    <input [value]="true" id="regime2" formControlName="rev_type" type="radio" class="form-check-input" (ngModelChange)="addConditionalValidation('revise_per')">Percentage of Current CTC</label>
            </div>
        </div>
        <div class="col-12 mt-8"  *ngIf="f.rev_type.value == false">
            <input type="text" class="form-control" placeholder="Enter amount " [ngClass]="{ 'is-invalid': (submitted || f.revise_ctc.touched || f.revise_ctc.dirty) &&  f.revise_ctc.errors}"  formControlName="revise_ctc" >

            <div *ngIf="(submitted || f.revise_ctc.touched || f.revise_ctc.dirty) && f.revise_ctc.errors" class="invalid-feedback">
              <div *ngIf="f.revise_ctc.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.revise_ctc.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="f.revise_ctc.errors.exist">{{this.messageService.revisedCTCValidation()}}</div>
              <div *ngIf="(f.revise_ctc.errors.maxlength && !(f.revise_ctc.errors.pattern))">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
            </div>

        </div>
        <div class="col-12 mt-8"  *ngIf="f.rev_type.value == true">
            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.revise_per.touched || f.revise_per.dirty) &&  f.revise_per.errors}">
                <input type="text" class="form-control" placeholder="Enter amount " [ngClass]="{ 'is-invalid': (submitted || f.revise_per.touched || f.revise_per.dirty) &&  f.revise_per.errors}"  formControlName="revise_per" >
                <span class="fs-14 text-light-400">% of {{employeeDetails.ctc| currency:employeeDetails.currency}}</span>
              </div>

                              <div *ngIf="(submitted ||f.revise_per.touched || f.revise_per.dirty) && f.revise_per.errors" class="invalid-feedback">
                                <div *ngIf="f.revise_per.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="f.revise_per.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                <div *ngIf="(f.revise_per.errors.exist && !(f.revise_per.errors.pattern))">{{this.messageService.revisedCTCValidation()}}</div>
                                <div *ngIf="(f.revise_per.errors.max && !(f.revise_per.errors.pattern))">{{this.messageService.fieldlengthvalidation('percentage','100')}}</div>
                              </div>
            <div class="fs-14">Amount : {{employeeDetails.ctc}}</div>
        </div>
        <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Effective from </label>
            <i class="bi bi-info-circle text-accent4 ms-2 fs-12 tooltip-icon" ngbPopover="Select the month from when this new salary update should be effective from" popoverClass="popover-default popover-info" container="body" triggers="hover"></i>

            <input type="hidden" class="form-control" formControlName="effective_from">
            <div ngbDropdown container="body" class="input-dropdown">
              <button type="button" class="card card-c2 " ngbDropdownToggle>{{defaultMonthData[0].effectiveDate}}</button>

              <div ngbDropdownMenu class="card-c2 card p-24" style="width: 22rem;">
                <div class="row">
                  <div class="col-5">
                    <label for="" class="form-label required">Year </label>
                    <ng-select class="form-ngselect" placeholder="Select year" [ngModelOptions]="{standalone: true}" [(ngModel)]="defaultMonthData[0].effectiveYear" (ngModelChange)="constructMonthData($event,'effective_from','year')">
                      <ng-option [value]="year-1">{{year-1}}</ng-option>
                      <ng-option [value]="year">{{year}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-7">
                    <label for="" class="form-label required">Month </label>
                    <ng-select class="form-ngselect" placeholder="Select month " [ngModelOptions]="{standalone: true}" [(ngModel)]="defaultMonthData[0].effectiveMonth" (ngModelChange)="constructMonthData($event,'effective_from','month')">
                      <ng-container *ngFor="let item of monthDate">
                        <ng-container *ngIf="item['effective']==false">
                          <ng-option [value]="item.id">
                            {{item.data}}
                          </ng-option>
                        </ng-container>
                      </ng-container>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

        </div>




        <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Salary payout month </label>
            <i class="bi bi-info-circle text-accent4 ms-2 fs-12 tooltip-icon" ngbPopover="Salary payout month is the month when the revised or the incremented salary is paid to the employee" popoverClass="popover-default popover-info" container="body" triggers="hover"></i>


            <input type="hidden" class="form-control" formControlName="salary_pay_month">
            <div ngbDropdown container="body" class="input-dropdown" [ngClass]="{ 'is-invalid': f.salary_pay_month.errors}">
              <button type="button" class="card card-c2"  [ngClass]="{ 'is-invalid': f.salary_pay_month.errors}" ngbDropdownToggle>{{defaultMonthData[0].payoutDate}}</button>

              <div ngbDropdownMenu class="card-c2 card p-24" style="width: 22rem;">
                <div class="row">
                  <div class="col-5">
                    <label for="" class="form-label required">Year </label>
                    <ng-select class="form-ngselect" placeholder="Select year" [ngModelOptions]="{standalone: true}" [(ngModel)]="defaultMonthData[0].payoutYear" (ngModelChange)="constructMonthData($event,'salary_pay_month','year')">
                      <ng-option [value]="year-1">{{year-1}}</ng-option>
                      <ng-option [value]="year">{{year}}</ng-option>
                      <ng-option [value]="nextYear">{{nextYear}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-7">
                    <label for="" class="form-label required">Month </label>
                    <ng-select class="form-ngselect" placeholder="Select month " [ngModelOptions]="{standalone: true}" [(ngModel)]="defaultMonthData[0].payoutMonth" (ngModelChange)="constructMonthData($event,'salary_pay_month','month')">
                      <ng-container *ngFor="let item of monthDate">
                        <!-- <ng-container *ngIf="item['payoutMonth']==false"> -->
                          <ng-option [value]="item.id">
                            {{item.data}}
                          </ng-option>
                        <!-- </ng-container> -->
                      </ng-container>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="f.salary_pay_month.errors" class="invalid-feedback">
              <div *ngIf="f.salary_pay_month.errors">{{this.messageService.greaterThanMessage('Effective from','Payout month')}}</div>
            </div>

        </div>
        <div class="col-12" *ngIf="alertmsg != '' && arrear">
            <div role="alert" class="alert alert-warning mb-0 show">
                <p class="mb-0 fs-14"> {{alertmsg}}</p>
                <div class="btn-close" (click)="arrear=false;"></div>
            </div>
        </div>

      </div>
    </div>
    <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto"  [disabled]="!formGp.valid " (click)="validateForm()">Next</button>
    </footer>

<!-- SALARY REVISION HISTORY VIEW START -->
<div class="side-panel" *ngIf="employeeDetails" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionHistory === true'>
    <div class="side-panel-container">
      <header class="side-panel-head">
          <h5>Salary revision history</h5>
          <a class="toggle-panel" (click)="salaryRevisionHistory = false"><i class=" icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
            <app-salary-revision-history-view  *ngIf='salaryRevisionHistory === true' [employeeId]="employeeDetails.employee"></app-salary-revision-history-view>

      </div>
    </div>
  </div>
<!-- SALARY REVISION HISTORY VIEW START -->
