import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators,FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MessageService } from 'src/app/message.global';
import { nonSpaceValidator } from 'src/app/@shared/validators/nonspace.validator';
import { AppService } from 'src/app/app.global';
import { LoginFormValidators } from 'src/app/@shared/validators/loginForm.validators';
import { OrganizationService } from '../../services/organization.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-one-time-password',
  templateUrl: './one-time-password.component.html',
  styleUrls: ['./one-time-password.component.scss']
})
export class OneTimePasswordComponent implements OnInit {

  submitted               = false;
  userdata:any            = [];
  show_pass: boolean      = false;
  viewicon_password       = "icon-eye-slash";
  show_confirm: boolean   = false;
  viewicon_confirm        = "icon-eye-slash";
  CardVisible             = true;
  inValidInput            = false;
  errorMsg                = "";
  profileImage            = "";
  fullname                = "";
  colourCode              = "";
  currentlogodata:any     = [];
  loader                  = true;
  @ViewChild(NgbPopover) popover!: NgbPopover;
  resetPasswordForm   = this.formBuilder.group({
                              password        : ['',[Validators.required,LoginFormValidators.ValidateSpecialCharacter,LoginFormValidators.ValidateFormat,LoginFormValidators.ValidateLowerCase,LoginFormValidators.ValidateNumber,LoginFormValidators.ValidateUpperCase,Validators.minLength(8),nonSpaceValidator()]],
                              confirmPassword : [''] },
                              { validators: LoginFormValidators.ValidatePassword }
                        );

  constructor(private formBuilder:FormBuilder,private authService:AuthService,
    public messageService : MessageService,public appservice:AppService,
    public OrganizationService: OrganizationService,
    ) { 
     
    }

  ngOnInit(): void {
    this.getorgdata();

    let empdata       = this.appservice.getEmpData();

    if(!empdata.id || empdata.first_login == false)
      this.logout()
    this.colourCode   = empdata.color_code;
    this.profileImage = empdata.profile_image;
    this.fullname     = empdata.first_name+" "+(empdata.middle_name!=null?empdata.middle_name:'')+" "+ empdata.last_name;

  }
  get f(){
    return this.resetPasswordForm.controls;
  }

  resetPassword(){
    this.submitted = true;
    if(this.resetPasswordForm.invalid){
      if (this.popover && !this.f.password.errors?.pattern) {
        this.popover.open();
      }
      return
    }else{

        this.userdata = this.appservice.getEmpData();
        let newData  = {"username":this.userdata.id,"password":this.resetPasswordForm.value.password}
        this.authService.resetPassword(newData).subscribe((res:any)=>{
          if (res.reset == '1') {
            this.authService.logoutwithoutredirect()
            this.CardVisible = false

          }

        },
        (err: any) => {
          this.inValidInput = true;
          this.errorMsg     = err.error.error;

        }
        )

    }
  }

  getpassword(key:any) {
    if(key=='pass'){

      this.show_pass        = !this.show_pass;
      this.viewicon_password =this.show_pass==true?'icon-eye':'icon-eye-slash';
    }
    else{
      this.show_confirm         = !this.show_confirm;
      this.viewicon_confirm     = this.show_confirm==true?'icon-eye':'icon-eye-slash';
    }
  }
  getorgdata(){

    this.OrganizationService.getloginpagedata()
    .subscribe((res: any) => {
     this.currentlogodata = res.success;
     this.loader          = false;

    })
  }
  logout(){
    this.appservice.resetPermissions()
    this.authService.doLogout()
  }

}
