<app-c1-loader *ngIf="landingLoader"></app-c1-loader>

<!-- <form class="" > -->
    <div *ngIf="!landingLoader" class="container-fluid p-32 form-container">

      <div class="row row-16">
            <div class="col-12 ">
                    <p class="text-uppercase fw-500 mb-0">SUMMARY</p>
                    <p class="text-uppercase fs-10 fw-500 mb-0">{{uploadedMonth}}</p>
            </div>
            <div class="col-12" *ngIf="!isClosed">
              <div role="alert" class="alert alert-warning mb-0 show">
                <p class="mb-0 fs-14">Summary of the consolidate months is displayed below.</p>
                <div class="btn-close pointer" (click)="isClosed=true;"></div>
              </div>
            </div>
            <div class="col-12 ">
              <div class="card card-c2 card-highlight highlight-primary">
                <div class="card-body px-24 py-12 ">
                    <div class="d-flex gap-40 flex-wrap">
                        <div class="">
                            <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                                total payroll cost</div>
                            <div class="fs-24 mb-6 lh-1">{{summaryData?.total_pay_salary| currency:summaryData?.currency}}</div>

                        </div>
                        <div class="my-auto">=</div>
                        <div class="">
                            <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                                Employee Net Pay</div>
                            <div class="fs-24 mb-6 lh-1">{{summaryData?.total_net_pay| currency:summaryData?.currency }}</div>

                        </div>
                        <div class="my-auto">+</div>
                        <div class="">
                            <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6"> Total
                                deductions</div>
                            <div class="fs-24 mb-6 lh-1">{{summaryData?.total_total_deductions| currency:summaryData?.currency }}</div>

                        </div>
                        <div class="my-auto">+</div>
                        <div class="">
                            <div class="fs-10 fw-500 text-uppercase text-light-400 mb-1">
                                Total contributions</div>
                            <div class="fs-24 mb-1 lh-1">
                                {{summaryData?.total_employer_contributions| currency:summaryData?.currency}}</div>

                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body py-0 px-24 d-flex align-items-end">
                        <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab2" class="nav radius-4 nav-custom shadow-0 p-0"
                            style="min-height: unset;">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink class="nav-link h-100 py-16">Salary Summary</a>
                                <ng-template ngbNavContent>

                                  <app-prior-summary [issalsumm]="true"
                                    [salarysummaryList]="summaryData?.salary_summary
                                    "
                                    [searchString]="searchString"
                                    [page]="page" [pageSize]="pageSize" [currency]="summaryData?.currency">
                                  </app-prior-summary>

                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink class="nav-link h-100 py-16">Employee Salary Summary</a>

                                  <ng-template ngbNavContent>
                                    <app-prior-summary [isempsalsumm]="true"
                                        [empsalarysummaryList]="summaryData?.employee_salary_summary"
                                        [searchString]="searchString"
                                        [page]="page" [pageSize]="pageSize" [currency]="summaryData?.currency"></app-prior-summary>
                                </ng-template>

                            </li>

                        </nav>
                        <div class="d-flex align-items-center gap-16 ms-auto py-12">
                          <div class="form-icon2">
                            <i class="icon-search icon-left fs-14"></i>
                            <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                            <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                          </div>
                          <button class="btn-square btn btn-secondary" (click)="filter  = true">
                              <i class="icon-filter-left fs-13"></i>
                          </button>
                        </div>
                    </div>
                    <div class="card-body p-24">
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer" *ngIf="!landingLoader">
        <button class="btn btn-primary btn-sm ms-auto" (click)="saveData()"  [disabled]="saveClicked" >SAVE</button>
    </footer>
<!-- </form> -->

<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
(resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>

<div class="modal  modal-alert {{saveConfirmed == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16 ">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Are you sure you want to proceed?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">You haven't uploaded the files for some employee(s). Are you sure you want to proceed?</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="saveConfirmed = false">Cancel</button>
              <button  type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="partialSave" (click)="savePriorPayroll()">Save</button>
          </div>
      </div>
  </div>
</div>
