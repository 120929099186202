<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{action_header}} Holiday Policy</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step"
          [class]="{'success':holidayForm['controls'].policydetails.valid || holidayForm['controls'].policydetails.disabled}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Holiday Policy Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-hp-details [formGp]="policy_details" [id]="id" [editflag]="editflag" [disabled]="disabled"
            [submitted]="iseditForm" (previousYearEmitter)="previousYear($event)"
            (submitmethod)="submitOne()"></app-hp-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success':holidayForm['controls'].holiday_policy.valid || holidayForm['controls'].holiday_policy.disabled}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Holiday Mapping</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-hp-mapping [formGp]="holidayForm" [previousYear]="prevYear" [disabled]="disabled"
            [submitted]="iseditForm" (submitmethod)="submitTwo()" (validFn)="validFn($event)"></app-hp-mapping>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="step"
          [class]="{'success':holidayForm['controls'].assign.valid|| holidayForm['controls'].assign.disabled}">
          <div class="count">3</div>
          <div class="content">
            <span>Step 3</span>
            <h6>Assign Rights</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-common-applicability [hint]="hint" [heading]="'Assign Rights'" [from]="'holiday'" [disabled]="disabled" [isEditForm]="iseditForm" [formGp]="assignForm" [isFutureHolday]="isFutureHolday" (submitMethod)="submitForm()"></app-common-applicability>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
