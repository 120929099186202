<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="detailedForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16 "
                  [ngClass]="{ 'is-invalid': (submitted || f.attendance_date.touched || f.attendance_date.dirty) &&  f.attendance_date.errors}">
                  <div class="col-12 form-row">
                      <label for="" class="form-label required">Attendance Date</label>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="attendance_date" type="radio" id="last" class="form-check-input"
                              formControlName="attendance_date" value="yesterday"
                              (change)="radioBtnChangeFunctions($event,'','yesterday','sm_specific_month');setRequired(false)">
                          <label for="last" class="form-check-label">Yesterday</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="attendance_date" type="radio" id="this" class="form-check-input"
                              formControlName="attendance_date" value="today"
                              (change)="radioBtnChangeFunctions($event,'','today','sm_specific_month');setRequired(false)">
                          <label for="this" class="form-check-label">Today</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="attendance_date" type="radio" id="specific" class="form-check-input "
                              formControlName="attendance_date" [value]="true"
                              (change)="radioBtnChangeFunctions($event,'',true,'sm_specific_month');setRequired(true)">
                          <label for="specific" class="form-check-label">Specific period</label>
                      </div>
                  </div>
                  <ng-container *ngIf="detailedForm.value.attendance_date==true">
                      <div class="input-group form-icon icon-right"
                      [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}">
                          <input (click)="datePicker1.open()" (dateInput)="fromDateVal($event)" class="form-control" placeholder="From date" matInput
                              formControlName="from_date" [matDatepicker]="datePicker1" [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}"
                              [matDatepickerFilter]="dojFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                          <mat-datepicker  touchUi="false" #datePicker1>

                          </mat-datepicker>
                      </div>
                      <div *ngIf="(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors" class="invalid-feedback">
                        <div *ngIf="f?.from_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                    <div class="input-group form-icon icon-right"
                      [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}">
                          <input (click)="datePicker2.open()" class="form-control" placeholder="End date" matInput [min]="f?.from_date.value" [max]="setMaxDate()"
                              formControlName="to_date" [matDatepicker]="datePicker2" [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}"
                              ><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                          <mat-datepicker  touchUi="false" #datePicker2>

                          </mat-datepicker>
                      </div>
                      <div *ngIf="(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors" class="invalid-feedback">
                        <div *ngIf="f?.to_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                      <!-- </div> -->
                  </ng-container>
              </div>
              <div *ngIf="(submitted || f.attendance_date.touched || f.attendance_date.dirty) && f.attendance_date.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.attendance_date.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
            <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage('apply');submitDetailedReport()">
              Apply
          </button>
      </footer>
  </form>
</div>
