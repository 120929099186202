<form [formGroup]="myForm">
  <div class=" config-layout">
    <header class=" config-header">
      <h5 class="m-0"> {{heading}} Shift Allowance Policy</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <div *ngIf="!loader">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step"
            [class]="{'success':myForm['controls'].configure.valid || myForm['controls'].configure.disabled}">
            <div class="count">1</div>
            <div class="content">
            <span>Step 1</span>
            <h6>Shift Allowance Policy Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-shift-allowance-policy-details  [formGp]="configureForm" [shiftslist]="shiftslist" [editflag]="editflag" [disabled]="disabled"
          [submitted]="iseditForm" (submitmethod)="submitOne()"
          ></app-shift-allowance-policy-details>
        </ng-template>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step" [class]="{'success':(myForm['controls'].shiftmap.valid && myForm['controls'].shiftmap.get('allowance_shift_map')?.value.length != 0) || myForm['controls'].shiftmap.disabled}" (submitmethod)="submitTwo()">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Configure Shift Allowance</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-shift-allowance-policy-configure [disabled]="disabled" [submitted]="iseditForm"  [shiftslist]="configureForm.value?.allowance_shift"
          [formGp]="shiftmapForm" (submitmethod)="submitTwo()"
          ></app-shift-allowance-policy-configure>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="step" [class]="{'success':myForm['controls'].assign.valid|| myForm['controls'].assign.disabled}">
          <div class="count">3</div>
          <div class="content">
            <span>Step 3</span>
            <h6>Applicability</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-common-applicability [heading]="'Assign Rights'" [disabled]="disabled" [isEditForm]="iseditForm" [formGp]="assignForm" (submitMethod)="submitForm()"></app-common-applicability>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
    <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
  </main>
</div>
</div>
</form>