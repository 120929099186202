import { Component, OnInit } from '@angular/core';


import { MyAttendenceCalenderService } from 'src/app/@core/services/my-attendence-calender.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

import * as moment from 'moment';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';
@Component({
  selector: 'app-my-attendance',
  templateUrl: './my-attendance.component.html',
  styleUrls: ['./my-attendance.component.scss']
})
export class MyAttendanceComponent implements OnInit {
  viewDetail = false;
  checkOutAction = false;
  isChecked: boolean = true;
  leaveBalanceDeduction = false;
  payDayDeduction = false;
  view = 1;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Rupees';
  animations: boolean = true;
  legendPosition: any = 'below';
  legendTitle: string = '';
  barPadding: number = 40;
  movetoPoint = 0;
  activeItem: any = [];
  year: any = false;
  month: any = false;
  attendanceData: any = [];
  attendanceLabels: any = [];
  attendanceColor: any = ['#AF8CD1', '#6166DB', '#4CBBBB', '#DC3545']
  WebcheckinValidations:any = []

  // Variables for check-in and check-out functionality
  currentDate: any;              // for current date and time
  isEmployeeId: any;             //  employee id
  webcheckIn = false;            //  boolean variable for webcheckin
  checkinFlag = true;           //   boolean variable for checkin and checkout
  intervalId: any              //   for set interval for current time

  validation: any = ""
  activeYear: any
  activeMonth: any = ""
  dropdownMonth: any
  dropdownYear: any
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  oLoader:any
  loader: any
  tableData = []
  page = 1;
  pageSize = 20;
  advanceDetails: any = []
  today = this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  attendanceLoader = true
  noGraphData = false
  badge: any = []
  options = [
    { name: 'Leave' },
    { name: 'Holiday' },
  ];
  empDoj: any
  currentMonth = new Date().toLocaleString('en-us', { month: 'long' })
  summaryTitle: any

  requestValidationData: any;
  alertToggle: boolean = false
  alertMsg: any

  captureModal = false;
  verificationModal = false;
  WebCamDataUrl = ""
  VerifyText = ""
  VerifyClass = ""
  retake = false
  failed  = 0
  failedmodal  = false

  constructor( private attendenceCalenderService: MyAttendenceCalenderService, public router: Router,
    public route: ActivatedRoute, private authService: AuthService, private datePipe: DatePipe, public appservice: AppService, private investmentService: InvestmentDeclerationService,  private  livelocationService:LivelocationService) {
    this.badge["Leave"] = "badge-warning"
    this.badge["Absent"] = "badge-danger"
    this.badge["Present"] = "badge-success"
    this.badge["Holiday"] = "badge-holiday"
    this.badge["Weekoff"] = "badge-weekoff"
    this.checkinLiveUpdate()
  }

  ngOnInit(): void {
    this.loader = true
    this.oLoader = true
    this.attendanceLoader = true
    this.route.params.subscribe((params: Params) => {

      if (!isNaN(params['myTeamEmpId'])) {
        this.isEmployeeId = +params['myTeamEmpId'];
      } else {
        this.isEmployeeId = this.authService.isEmployeeId;
      }
    })
    this.intervalId = setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
    this.checkelibility()
    this.webCheckinValid()
    this.attendenceCalenderService.checkinSocket();
  }
  // checkinLiveUpdate
  checkinLiveUpdate() {
    this.attendenceCalenderService.checkin_item.subscribe(result => {
      // this.employee_shift?.punches.push(result.event)
      this.checkinFlag = result.event?.punch_inout == null ? true : result.event?.punch_inout
      // this.getFirstCheckin(this.employee_shift?.punches).then(res => this.updateTime());
    })
  }
  ngOnDestroy() {
    this.attendenceCalenderService.closeSocket();
  }
  visibleRange:any
  currentServerDate:any
  // function for check web checkin enabled or not for the corresponsing employee
  webCheckinValid() {
    let currentTime = new Date();

    this.activeYear = currentTime.getFullYear()
    this.activeMonth = this.months[currentTime.getMonth()]
    this.summaryTitle = "today"
    this.attendenceCalenderService.getWebcheckinValidations(this.isEmployeeId).subscribe((res: any) => {
      if (res.status != 0) {
        let serverOffset = moment(res?.output?.current_date_time).diff(new Date());
        this.currentServerDate = moment().add('milliseconds', serverOffset);
        this.today = this.datePipe.transform(this.currentServerDate, 'yyyy-MM-dd')?.toString()
        this.WebcheckinValidations = res?.output
        if (res?.output?.is_attendance_rule_configed == false)
          this.validation = "Attendance rule is not configured. For more details contact HR."
        else if (res?.output?.is_attendance_capture_configed == false)
          this.validation = "Attendance capture policy is not configured. For more details contact HR."
        // else if (res?.output?.shift_data?.is_shift_error)
        //   this.validation = "Shift not configured. For more details contact HR."
        // this.webcheckIn = res?.output?.web_checkin ;  // value true or false from response
        this.webcheckIn = (res?.output?.is_attendance_capture_configed && res?.output?.is_attendance_rule_configed && res?.output?.web_checkin )
        this.dropdownYear = Object.keys(res?.output?.dropdown_data)
        this.dropdownMonth = res?.output?.dropdown_data
        const monthsNums: any = { January: 0, February: 1, March: 2, April: 3, May: 4, June: 5, July: 6, August: 7, September: 8, October: 9, November: 10, December: 11 }
        this.visibleRange= {
          start: new Date(this.dropdownYear[0], monthsNums[this.dropdownMonth[this.dropdownYear[0]][0]], 1),
          end:  new Date(this.dropdownYear[this.dropdownYear.length-1], monthsNums[this.dropdownMonth[this.dropdownYear[this.dropdownYear.length-1]][this.dropdownMonth[this.dropdownYear[this.dropdownYear.length-1]].length-1]]+1, 0,23,59,59)
        }

        if (res?.output?.last_punch_details?.punch_date == this.today && res?.output?.last_punch_details?.punch_inout == false) {
          this.checkinFlag = false;
        }
        this.empDoj = res?.output?.date_of_joined
        this.loader = false
      }
      else {
        this.webcheckIn = false;  //  assign false to webcheckin variable when web checkin not enabled for the employee
        this.loader = false
      }
      this.validateMonth();

    })
  }

  //function for check-in and check-out
  checkin_out() {

    if(this.WebcheckinValidations.is_selfie_enabled && (this.WebcheckinValidations.save_photo_checkin_from_web || this.WebcheckinValidations.verify_photo_checkin_from_web)){
      if(this.failed  == 3){
        this.failedmodal = true
        this.verificationModal = true
        this.VerifyText = "Unavailable because of 3 failed attempts. Try again after 30 seconds."
      } else
        this.captureModal=true
    } else {
      if (this.checkinFlag == true)
        this.checkin();
      else {
        // this.checkOutAction = true
        this.checkout();
      }
    }

  }
  checkin() {
    this.attendenceCalenderService.createAttendenceMark(this.isEmployeeId, { 'punch_inout': false }).subscribe((res: any) => {
      this.checkinFlag = false;
    })
  }

  checkout() {
    this.attendenceCalenderService.createAttendenceMark(this.isEmployeeId, { 'punch_inout': true }).subscribe((res: any) => {
      this.checkinFlag = true;
      // this.checkOutAction = false
    })
  }
  validationShift = ''
  getattendance() {
    var monthNumber = moment(this.activeMonth, "MMMM").month() + 1;
    const lastDayOfMonth = moment().month(monthNumber - 1).endOf('month').date();
    
    this.summaryTitle = monthNumber == Number(new Date().getMonth()) + 1 && new Date().getFullYear() == this.activeYear ? `today` : ` ${lastDayOfMonth}${lastDayOfMonth == 31 ? 'st' : 'th'} ${this.activeMonth.slice(0, 3)} `
    this.attendanceLoader = true
    this.attendanceData = []
    this.attendanceLabels = ["Present", "Absent", "Leave"]
    this.attendenceCalenderService.getAttendanceSummary(this.isEmployeeId, this.activeMonth, this.activeYear).subscribe((res: any) => {
      this.advanceDetails = res?.output;
      if (!res?.output?.is_unassigned_shift_dates_exist)
          this.validationShift = "Shift not configured. For more details contact HR."
      else
        this.validationShift = ""

      if (res?.output?.graph_data?.present_days) {

        this.attendanceData.push(res?.output?.graph_data?.present_days ? res?.output?.graph_data?.present_days : 0)
        this.attendanceData.push(res?.output?.graph_data?.present_days ? res?.output?.graph_data?.absent_days : 0)
        this.attendanceData.push(res?.output?.graph_data?.present_days ? res?.output?.graph_data?.leave_days : 0)
        this.noGraphData = false
      } else {
        this.noGraphData = true
      }
      this.attendanceLoader = false
    })

    if (this.view == 0)
      this.getTableData()
  }
  getTableData() {
    this.attendenceCalenderService.getCalendarData(this.isEmployeeId, this.activeMonth, this.activeYear).subscribe((res: any) => {
      this.tableData = res.ouput_data
    })
  }
  gotoAction($event: any) {
    this.investmentService.requestDetails().subscribe((res: any) => {
      this.requestValidationData = res?.count_info
      if ($event?.name == 'On Duty') {

        if (!this.requestValidationData.on_duty){
          this.alertToggle = true
          this.alertMsg = 'As per policy,  You are not eligible for On-duty request.';
        }else if(!this.requestValidationData?.attend_rule_assigned){
          this.alertToggle = true;
          this.alertMsg = 'On-duty request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.router.navigate(['/on-duty-setup']);
        }

      } else if ($event?.name == 'Regularization') {

        if (!this.requestValidationData?.is_attend_regular_allowed) {
          this.alertToggle = true
          this.alertMsg = 'As per policy, You are not eligible for Attendance Regularization request.';
        } else if (!this.requestValidationData?.attend_rule_assigned){
          this.alertToggle = true
          this.alertMsg = 'Attendance Regularization request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.router.navigate(['/regularization-setup']);
        }
      } else if ($event?.name == 'Leave') {
        if(!this.requestValidationData?.leave_configured){
          this.alertToggle = true
          this.alertMsg = 'Leave request cannot be raised as Leave policy is not configured yet.';
        }else if(!this.requestValidationData?.attend_rule_assigned){
          this.alertToggle = true;
          this.alertMsg = 'Leave request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.router.navigate(['/leave-request-setup']);
        }

      } else if ($event?.name == 'Holiday') {

        if(!this.requestValidationData?.attend_rule_assigned){
          this.alertToggle = true;
          this.alertMsg = 'Holiday request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.holidayRequestFlag = true;
          this.holidayLists();
          this.searchHoliday();
        }

      } else if ($event?.name == 'Work From Home') {
        if(!this.requestValidationData?.is_wfh_allowed){
          this.alertToggle = true;
          this.alertMsg = 'As per policy, You are not eligible for WFH request.';
        }else if(!this.requestValidationData?.attend_rule_assigned){
          this.alertToggle = true;
          this.alertMsg = 'WFH request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.router.navigate(['/work-from-home-setup']);
        }
      }else if ($event?.name == 'Short Leave') {
         if(!this.requestValidationData?.attend_rule_assigned){
          this.alertToggle = true;
          this.alertMsg = 'Short Leave request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.router.navigate(['/short-leave-setup']);
        }      
      }
    })


  }
  holidayRequestFlag: any
  holidayFloatData: any
  holidatSearchData: any
  holiday_loader: any
  holiday_nodata: any
  saveClicked: any
  holidayReqCloseFunction(data: any) {
    this.holidayRequestFlag = false;
  }
  holidaySearchFunction(data: any) {
    if (data.search == false) {
      this.holidayLists();
    } else {
      this.singleHoliday(data.searchid);
    }
  }
  singleHoliday(id: number) {
    this.holiday_loader = true;
    this.investmentService.holidayFloatGet(id).subscribe((res: any) => {
      this.holidayFloatData = res;
      this.holiday_loader = false;
    });
  }
  holidayReqSaveFunction(data: any) { this.holidayRequestFlag = false; }
  holidayLists() {
    this.saveClicked = false;
    this.holiday_loader = true;
    this.investmentService.holidayFloatList('holiday_date', '').subscribe((res: any) => {
      this.holiday_loader = false;
      this.holidayFloatData = res;
      if (this.holidayFloatData?.length == 0) {
        this.holiday_nodata = true;
      }
      else {
        this.holiday_nodata = false;
      }
    });
  }
  searchHoliday() {
    this.holiday_loader = true;
    this.investmentService.holidayFloatDropDown().subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i]['day_name'] != null && res[i]['day_name'] != '')
          res[i]['name'] = res[i]['holiday_name'] + ' ' + 'day ' + res[i]['day_name'];
        else
          res[i]['name'] = res[i]['holiday_name'];
      }
      this.holiday_loader = false;
      this.holidatSearchData = res;
    });
  }

  checkelibility(){
    this.investmentService.requestDetails().subscribe((res:any)=>{
      if(res?.count_info?.is_wfh_allowed){
        this.options.push({ name: 'Work From Home' })
      }
      if (res?.count_info?.is_attend_regular_allowed){
      this.options.push({ name: 'Regularization' })
      }
      if (res?.count_info?.on_duty){
      this.options.push({ name: 'On Duty' })
      }
      
      if (res?.count_info?.short_leave_configured){
        this.options.push({ name: 'Short Leave' })
        }
      this.oLoader =false
    });
  }

  viewdata: any
  viewempmap: any

  viewempmapfn(data: any) {
    this.filterDataofTime( data)
  }
  filterDataofTime( punchData: any) {
    let rtd: any[] = [{
      "created_at_formatted": punchData?.clickedDate + " " + punchData?.punchData?.punch_time,
      "latitude": punchData?.punchData?.latitude,
      "location_name": punchData?.punchData?.location,
      "longitude": punchData?.punchData?.longitude
    }];
    this.viewempmap = true;
    this.viewdata = rtd
    this.viewdata.empData = JSON.parse(this.authService.getUserData())
  }
  changeMonthYear(currentTime:any){
    this.activeYear = currentTime.getFullYear()
    this.activeMonth = this.months[currentTime.getMonth()]
    this.getattendance()
  }
  validateMonth(){
    if(!this.dropdownMonth[this.activeYear].includes(this.activeMonth))
      this.activeMonth = this.dropdownMonth[this.activeYear][0]
    this.getattendance();
  }
  pictureTaken(DataUrl:any){
    if(this.WebcheckinValidations.verify_photo_checkin_from_web){
      this.verifyPhoto(DataUrl)
    } else {
      var checkin = false
      if (this.checkinFlag == true)
        checkin = false
      else 
        checkin = true
        this.captureModal=false
      this.attendenceCalenderService.createAttendenceMark(this.isEmployeeId, { 'punch_inout': checkin,'dataURL':DataUrl,'name':'webcamimage.jpeg' }).subscribe((res: any) => {
        this.checkinFlag = !this.checkinFlag
      })
    }
    
  }
  verifyPhoto(DataUrl:any){
    this.failedmodal  = false
    this.WebCamDataUrl = DataUrl

    this.verificationModal = true
    this.VerifyClass = "progress-pending"
    this.VerifyText = "Kindly wait, we are verifying the images..."

    this.retake = false
    this.captureModal=false
    var checkin = false

    if (this.checkinFlag == true)
      checkin = false
    else 
      checkin = true
  
    this.attendenceCalenderService.createAttendenceMark(this.isEmployeeId, { 'punch_inout': checkin,'dataURL':DataUrl,'name':'webcamimage.jpeg' }).subscribe((res: any) => {
      this.checkinFlag = !this.checkinFlag
      this.verificationModal = true
      this.VerifyText = "Verification successful. The faces are matching."
      this.VerifyClass = "progress-success"
      
    },
    (err: any) => {
      this.verificationModal = true
      this.failed++
      this.VerifyClass = "progress-danger"
      let bal:any = 3-this.failed
      if(err.error.error == 'Not verified')
        this.VerifyText = "Verification failed. The faces are not matching. You have "+bal+" more attempts remaining."
      else if(err.error.error == 'Profile image not added')
        this.VerifyText = "Employee image not found in SkizzleHR server. You have "+bal+" more attempts remaining."
      this.retake = true
      if(this.failed == 3){
        setTimeout(() => {this.failed = 0},3000)
      }

    })
  }
  retakeFn(){
    if(this.failed  == 3){
      this.failedmodal = true
      this.verificationModal = true
      this.VerifyText = "Unavailable because of 3 failed attempts. Try again after 30 seconds."
    } else{
      this.captureModal=true
      this.verificationModal = false;
    }
  }

}
