<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Library</h3>
      <p class="text-helper mb-md-0">Access, download and acknowledge HR documents.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div style="width:22.6875rem;">
          <div class="">
            <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='policyPlaceholder' [isLoading]="sLoading"(searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchCategory($event)"></app-searchbar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-16 " *ngIf="loader">
    <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="nodatafound && !loader;else all_data" class="row" style="height: calc(100vh - 12rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg19.png'" [noDataText]="'Library is Awaiting Upload'"
      [noDataPara]="'Currently, there are no documents in the library. Files shared to you by authorized users will be listed here.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16 "  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
    <ng-container  *ngIf="loader==false">
      <div class=" col-lg-4 col-md-6 col-sm-12" *ngFor="let item of policyList let i = index">
        <div class="card card-custom1 card-hover">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex view-section" (click)="viewPolicy(item?.category_id)">
              <div class="logo-img  sq-48 fs-22 flex-center lh-1 logo-img rounded-circle  unique-cbg{{item?.category_color_code.slice(-1)}}"><i
                  class="bi bi-folder"></i> </div>
              <div class="overflow-hidden align-items-center d-flex">
                <h6 class="mb-1 tips">{{item?.category_name}}</h6>
                <span class="tips-helper"> {{item?.category_name}} </span>

              </div>
            </div>

          </div>
          <div class="card-body">
            <div class="d-flex align-items-center w-100 gap-6">
              <span class="fs-10 text-light-400 text-uppercase">
                ACKNOWLEDGEMENT:
              </span>
              <span class="badge {{item?.document__attachment_acknowledge && item?.status === 'Pending' ? 'badge-warning' : 'badge-success'}} py-1 px-16 fs-14 ms-auto">{{item?.document__attachment_acknowledge? item?.status : 'Completed'}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    </div>
  </ng-template>





</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">
          <div class="row row-24 mb-24">
            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">{{singlePolicyData[0]?.category_name}}</p>
            </div>
            <div class="col-12" *ngFor="let item of singlePolicyData let i = index">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">{{item?.policy_subject}}</p>
                  </div>
                  <div class="col-12" >
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-md-6">
                          <div class="detail-title">Priority</div>
                          <div class="detail-desc">
                            <div class="d-flex align-items-center">
                              <span class="badge badge-circle {{item?.policy_priority=='Medium'?'badge-orange':item?.policy_priority=='High'?'badge-red':'badge-green'}} sq-10 p-0 me-8"></span>
                              <span>{{item?.policy_priority}}</span>
                            </div>
                          </div>
                        </div>
                        <div class=" col-12">
                          <div class="detail-title">Description </div>
                          <div class="detail-desc">{{item?.policy_description}}</div>
                        </div>
                        <div class="col-12">
                          <div class="detail-title">Attachments </div>
                          <div class="detail-desc">
                            <div class="card card-c2 w-100">
                              <div class="table-responsive radius-4">
                                <table
                                  class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                                  <thead>
                                    <tr>
                                      <th class="fw-600 text-uppercase w-50" scope="col">File name</th>
                                      <th class="fw-600 text-uppercase" scope="col">ACKNOWLEDGEMENT Status</th>
                                    </tr>
                                  </thead>
                                  <tbody class="cell-16 ">
                                    <!-- <ng-container *ngFor="let i of [1,2]"> -->
                                      <tr>
                                        <td><a class="link-primary1" routerLink="/acknowledge-file/{{item?.id}}">{{appService.getDocName(item?.document_file)}}</a></td>
                                        <td><span class="badge {{item?.acknowledgemet_status=='Pending' && item?.is_acknowledge==true?'badge-warning':'badge-success'}} py-1 px-3">{{item?.is_acknowledge==true?item?.acknowledgemet_status:'Completed'}}</span></td>
                                      </tr>
                                    <!-- </ng-container> -->
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="detail-title">URL </div>
                          <div class="detail-desc">
                            <ng-container *ngIf="item?.url!=null && item?.url!='';else noLink">
                              <a (click)="goToLink(item?.url)" class="link-primary1">{{item?.url}}</a>
                            </ng-container>
                            <ng-template #noLink>
                              <span class="">--</span>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
