<form action="" class=""  [formGroup]="formGp" autocomplete="off">
   <div class="container-fluid p-32 form-container">
    <div class="row row-16">
      <div class="col-12">
        <p class="text-uppercase fw-500 mb-0">Shift allowance policy details </p>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">Policy Name </label>       
                <input type="text" formControlName="name" class="form-control " [ngClass]="{ 'is-invalid': (f.name?.touched || f.name?.dirty) && f.name?.errors || f.name?.errors?.exist }" placeholder="{{this.messageService.placeholderdisp('Policy Name')}}" (keyup)="keyUp()" >
                <div *ngIf=" f.name?.errors || f.name?.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.name?.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                  <div *ngIf="f.name?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.name?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!f.name?.errors?.pattern && f.name?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                </div>
      </div>
      <div class=" col-12 form-row">
        <label for="" class="form-label required">Description </label>
        <textarea [ngClass]="{ 'is-invalid': (f.description?.touched) && f.description?.errors }" type="text"
          class="form-control " rows="4" placeholder="Enter description here..."
          formControlName="description"></textarea>
        <div *ngIf="(f.description?.touched) && f.description?.errors" class="invalid-feedback">
          <div *ngIf="f.description?.errors?.required">{{this.messageService.validationDisplay('required')}}
          </div>
          <div *ngIf="!f.description?.errors?.pattern && f.description?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
        </div>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">Policy Valid From </label>
        <div class="input-group form-icon icon-right"
        [ngClass]="{ 'is-invalid': (submitted || f.policy_valid_from?.touched) && f.policy_valid_from?.errors }">
        <input (click)="datePicker1.open()" class="form-control"
          placeholder="{{this.messageService.selectplaceholddisplay('date')}}" matInput
          formControlName="policy_valid_from" [matDatepickerFilter]="fromDateFilter"
          [matDatepicker]="datePicker1" (keydown)="disableDate()"
          [ngClass]="{ 'is-invalid': (submitted || f.policy_valid_from?.touched) && f.policy_valid_from?.errors }">
        <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
        <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
        </div>
        <div *ngIf="(submitted || f.policy_valid_from?.touched) && f.policy_valid_from?.errors" class="invalid-feedback">
          <div *ngIf="f.policy_valid_from?.errors">{{this.messageService.validationDisplay('required')}}</div>         
        </div>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">Select Shifts </label>
      </div>
      <ng-container formArrayName="allowance_shift">
        <ng-container  *ngFor="let item of shiftslist;let i=index">
        <div class="col-12 mt-8">
          <label for="{{item['shift_name']}}" class="form-check p-0 d-flex ">
            <input
              type="checkbox" 
              name="check" type="checkbox"
              [disabled]="disabled" 
              [checked]="shiftIdsFromList.includes(item['id'])" 
              [value]="item['id']" 
              id="{{item['shift_name']}}"
              (click)="onCheckChange($event,item)" 
              class="form-check-input ms-0">
            <label for="{{item['shift_name']}}" class="form-check-label fs-16" >{{item['shift_name']}}</label>
          </label>
        </div>
        </ng-container>
      </ng-container>      
    </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" [disabled]="!formGp.valid || this.formGp.get('allowance_shift')?.value?.length==0 " (click)="validateshiftdetail()" class="btn btn-primary btn-sm ms-auto">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateshiftdetail()">NEXT</button>
  </footer>
</form>
