import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ChangeDetectorRef, Inject} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { AttendanceProcessDashboardService } from 'src/app/@core/services/attendance-process-dashboard.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { LineChartService } from 'src/app/@core/services/line-chart.service';
import { LineChartComponent } from '@swimlane/ngx-charts';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';

@Component({
  selector: 'app-attendance-dashboard',
  templateUrl: './attendance-dashboard.component.html',
  styleUrls: ['./attendance-dashboard.component.scss']
})
export class AttendanceDashboardComponent implements OnInit {

  graphvalues:any =[]
  graphLabels:any =[]
  activeTab1 = 1;
  graphloader: boolean = false;
  movetoPoint = 0;
  activeItem:any = [];
  payrollCount:any = [];
  payrollMonthlyCost:any = [];
  year:any=false;
  graphyear:any =[];
  month:any=false;
  companyList :any=[];
  graphselect:any;
  loader: boolean = true;
  company:any = ""
  currentYear = new Date().getFullYear();
  nodataFound = false;
  nographdata = false
  range:any=[]
  currentslider:any;
  Selecetedmonth:any;
  Selecetedyear:any;
  summerydata:any=[];
  attendancedata:any=[];
  graphdata:any=[];
  currentTime:any;
  selecteddate:any;
  selectedmonth:any
  selectedyear:any;
  permissions:any=[];
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  attendanceloader=false;
  loadersub =false;

  public carouselTileItems: Array<any> = [];
  @ViewChild('myCarousel') myCarousel!: NguCarousel<any>;
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 5, lg: 6, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    loop:false,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  @ViewChild('chart') chart: LineChartComponent | any;

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private _cdr: ChangeDetectorRef,
    public aps:AttendanceProcessDashboardService,
    public route:ActivatedRoute,
    public router:Router,
    public messageService : MessageService,
    public appService: AppService,
    public sds:SalaryDashboardService,
    private lineChartService:LineChartService
    ) { }

  ngOnInit(): void {
	  this.getPermission();
    this.getDateFormat();
    // this.carouselTileItems.forEach(el => {
    // });
    this.getCompanyDropdown();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
        this.companySelected();
      }
    })
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/attendance-dashboard');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  clickCarousel(item:any) {
      this.loadersub =true;
      this.loader =false;
      this.carouselTileItems[item['key']]['status'] = "active";
      this.carouselTileItems[this.activeItem['key']]['status'] = this.carouselTileItems[this.activeItem['key']]['state'];
      this.activeItem = item;
      this.currentslider = item.month;
      const myArray = this.currentslider.split(" ");
        this.selectedyear = myArray[1];
        this.selectedmonth = myArray[0];
      if(this.selectedyear !='' && this.selectedyear != undefined) {
          this.router.navigateByUrl("/attendance-dashboard/"+this.selectedyear+"/"+this.selectedmonth+"/"+this.company)
      }
      this.graphselect = item.month;
      this.getsummerydata();

   }

   getCompanyDropdown(){
    this.loader =true;
    this.aps.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader   = false;
        } else {
          this.companyList = res.body;
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;
            this.companySelected();
          }
        }
      }
    })
    this._cdr.detectChanges();
  }

  companySelected(){
    this.loadersub =true;
    this.nodataFound = false;
    if(this.company!='' && this.company!=null){
      if(this.selectedyear !='' && this.selectedyear != undefined) {
      this.router.navigateByUrl("/attendance-dashboard/"+this.selectedyear+"/"+this.selectedmonth+"/"+this.company)
      }
      this.getlopdetails();
    }
  }

  createCaroselItem(){
    var currentyear;
    var currentmonth;
    var months;
    let currentdate:any;
    this.currentTime = new Date()
    months = ["January", "February", "March", "April", "May","June", "July", "August", "September", "October", "November", "December"];
    if(this.year=='' || this.month==''){
     currentyear = this.currentTime.getFullYear();
     currentmonth = this.currentTime.getMonth();
     currentdate = months[currentmonth] + " " +currentyear ;
    }else{
     currentdate = this.month + " " + this.year;
    }
    this.graphselect = currentdate;
    this.carouselTileItems = [];
    this.activeItem = []
    this.aps.getDateRangeSlider(this.company).subscribe((res:any)=>{
        if(res.body.ouput_data != null){
          let apiData = res.body.ouput_data;
          Object.keys(apiData)?.forEach((key:any,value:any)=>{
                      this.carouselTileItems.push({
                        state:apiData[key],
                        status:apiData[key],
                        month : key,
                        key : value,
                      });

                if(currentdate == key){
                    this.activeItem = this.carouselTileItems[value];
                    this.carouselTileItems[value]['status'] = "active";
                    this.movetoPoint = value;
                    this.currentslider = key;
                    const myArray = this.currentslider.split(" ");
                    this.selectedyear = myArray[1];
                    this.selectedmonth = myArray[0];
                  }
                this.graphyear.push(key);

          });
            if(this.activeItem.length == 0){
                this.carouselTileItems[0]['status'] = "active";
                this.activeItem = this.carouselTileItems[0];
                this.movetoPoint = 0
            }
            this.nodataFound = false;
            this.loader =false;
            this.getsummerydata()
        } else {
          this.nodataFound = true;
          this.loader =false;
        }

        this.myCarousel?.moveTo(this.movetoPoint-3,true);
        this._cdr.detectChanges();
    });
   }

  getsummerydata(){
    const myArray = this.currentslider.split(" ");
    this.selectedyear = myArray[1];
    this.selectedmonth = myArray[0];
    this.aps.getattendanceSummary(this.selectedyear,this.company,this.months[this.selectedmonth]).subscribe((res:any)=>{
      if(res.body.status == 1){
        this.summerydata = res.body.data;
      } else {

      }
      this.loader =false;
    });

    this.getattendancedetails('');
    this.getgraphdata('');
  }

  dateEvent(inp:any){
    this.getattendancedetails(moment(inp.value).format('YYYY-MM-DD'));
  }


  getattendancedetails(inp:any){
    this.attendanceloader=true;
    if(inp==''){
       this.selecteddate = moment(this.currentTime).format('YYYY-MM-DD');;
    }else{
      this.selecteddate = inp;
    }
    this.aps.getattendancedetails(this.company,this.selecteddate).subscribe((res:any)=>{
      if(res.body.status == 1){
        this.attendancedata = res.body.data;
        this.attendanceloader=false;
      } else {
      }
    });
    this._cdr.detectChanges();
  }

  getgraphdata(inp:any){
    this.graphloader = true;
    var myArray ;
    var month;
    var year
    if(inp==''){
      month = this.selectedmonth;
      year = this.selectedyear;
    }else{
      myArray = this.graphselect.split(" ");
      month = myArray[0];
      year = myArray[1];
    }

    this.aps.getgraphdata(year,this.company,this.months[month]).subscribe((res:any)=>{
      if(res.body.status == 1){
        this.graphdata = res.body.data;
        this.createGraphData(this.graphdata);
      } else {

      }
      this.loadersub =false;
    });
    this._cdr.detectChanges();

  }

  createGraphData(graph:any){
    this.graphvalues = [];
    this.graphLabels = [];
    let nodata :any;
    nodata = graph.every((key:any) => key.avg_work_hour === null);
    if(nodata==true){
      for(let key of graph){
       this.graphLabels.push(key.avg_work_hour);
       this.graphvalues.push(key.day_value);
      }
      this.graphloader = false;
    }else{
      for(let key of graph){
        if(key.avg_work_hour != null){
          const myArray = key.avg_work_hour.split(":");
          let key1= myArray[1];
          let key2 = myArray[0];
          let time = key2+'.'+key1
          this.graphLabels.push(Number(time));
        }else{
          this.graphLabels.push(0)
        }
        this.graphvalues.push(key.day_value)
      }

      this.showDots();
      this.graphloader = false;
    }
  }

  showDots(){
    if(this.chart == undefined){
      setTimeout(() => {
        this.showDots();
      }, 1000);
    } else
      this.lineChartService.showDots(this.chart);
  }

  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  disableDate(){
    return false;
  }

  dateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear() + 1, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  getlopdetails(){
    this.sds.tabdetails(this.company,'dashboard').subscribe((res: any) => {
      if(res?.body?.attendance_lop == true){
        this.nodataFound = true;
        this.loader = false
      } else {
        this.nodataFound = false;
        this.createCaroselItem();
      }
    },(error: any) => {
      this.nodataFound = true;
      this.loader = false;
    })
  }

  getPreMonth(){
    let myArray = this.currentslider.split(" ");
    let month= myArray[0];
    let year = myArray[1];
    const current = new Date(month+' 1'+year);
    current.setMonth(current.getMonth()-1);
    return  current.toLocaleString('default', { month: 'short' });
  }

}
