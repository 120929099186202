<div class=" config-layout" *ngIf="permissions">
    <header class=" config-header">
        <h5 class="m-0">Update challan ({{month}})</h5>
        <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">
        <!-- <form class=""> -->
        <div class="container-fluid px-32 py-24 form-container">
            <app-c1-loader *ngIf="loader"></app-c1-loader>
            <div class="row row-24" *ngIf="!loader">

                <div class="col-3">
                    <div class="card card-c2" style="  position: sticky;top: calc(3.625rem + 1.5rem);
                      ">
                        <div class="d-flex  flex-column gap-12  px-16 py-24" style="max-height: calc(100vh - (3.625rem + 3rem + 4.35rem));
                            overflow: auto;">
                            <div class="card card-c7 card-hover active px-16 py-8" [class.active]='index === i'
                                *ngFor="let challan of challanUpdateList let i = index" (click)="setIndex(i)">
                                <div class="fs-14 fw-600">{{challan?.voucher_no}}</div>
                                <div class="fs-12">{{challan?.no_of_employees_mapped}} employees</div>
                                <span class="fs-10 text-warning" *ngIf="challan?.duplicate">Excess amount
                                    adjusted</span>
                            </div>
                            <!-- <div class="card card-c7 card-hover px-16 py-8">
                                    <div class="fs-14 fw-600">#JKHI394837</div>
                                    <div class="fs-12">45 Employees</div>
                                </div>
                                <div class="card card-c7 card-hover px-16 py-8">
                                    <div class="fs-14 fw-600">#JKHI394837</div>
                                    <div class="fs-12">45 Employees</div>
                                </div>
                                <div class="card card-c7 card-hover px-16 py-8">
                                    <div class="fs-14 fw-600">#JKHI394837</div>
                                    <div class="fs-12">45 Employees</div>
                                </div> -->


                        </div>
                        <a class="d-flex align-items-center gap-12 bg-white px-16 pb-16"
                            *ngIf="challanUpdateEmpData?.pending_employees <= 0">
                            <div class="btn btn-accent2 sq-36"><i class="icon-plus"></i></div>
                            <div>
                                <div class="fs-12 link-primary fw-500">Add challan</div>
                                <div class="fs-10 text-light-500">{{challanUpdateEmpData?.pending_employees}} Employees
                                    Pending</div>
                            </div>
                        </a>
                        <a class="d-flex align-items-center gap-12 bg-white px-16 pb-16"
                            *ngIf="challanUpdateEmpData?.pending_employees > 0 && permissions?.a"
                            routerLink="/challan/challan-setup/{{challanId}}/{{month}}/{{company}}/{{currency}}/{{financialyear}}">
                            <div class="btn btn-accent2 sq-36"><i class="icon-plus"></i></div>
                            <div>
                                <div class="fs-12 link-primary fw-500">Add challan</div>
                                <div class="fs-10 text-light-500">{{challanUpdateEmpData?.pending_employees}} Employees
                                    Pending</div>
                            </div>
                        </a>


                    </div>

                </div>
                <div class="col-9">
                    <div class="row row-24">
                        <div class="col-12 d-flex gap-12">
                            <div class="fw-500">{{challanUpdateList[index]?.['voucher_no']}}</div>
                            <div class="ms-auto d-flex align-items-center gap-12">
                                <a class="link-primary fs-14 d-flex align-items-center" *ngIf="permissions?.e"
                                    (click)="updateAmount(index)">
                                    <i class="icon-pencil-square me-1"></i> Edit</a>
                                <span class="text-light-500" *ngIf="permissions?.d">|</span>
                                <a class="link-danger fs-14 d-flex align-items-center" *ngIf="permissions?.d"
                                    (click)="deleteChallanClick(challanUpdateList[index]?.['id'])">
                                    <i class="icon-trash  me-1"></i> Delete</a>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card px-24 py-16">
                                <div class="row row-16">
                                    <div class="col-12">
                                        <span class="title">CHALLAN DETAILS</span>
                                    </div>

                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Payroll month</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['payroll_month']}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Amount</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['amount'] | currency:
                                            currency}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Voucher date</div>
                                        <div class="detail-desc">
                                            {{this.appService.dateFormatDisplay(challanUpdateList[index]?.['voucher_date'])}}
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Branch code</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['branch_code']}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">TDS</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['tds'] | currency:
                                            currency}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Bank details</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['branch_details']}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Surcharge</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['surcharge'] | currency:
                                            currency}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Education cess</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['education_cess'] |
                                            currency: currency}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Interest</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['intrest']}}%</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Others</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['others'] | currency:
                                            currency}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Cheque/DD no</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['cheque_dd_no']}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Payment date</div>
                                        <div class="detail-desc">
                                            {{this.appService.dateFormatDisplay(challanUpdateList[index]?.['payment_date'])}}
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Deduction date</div>
                                        <div class="detail-desc">
                                            {{this.appService.dateFormatDisplay(challanUpdateList[index]?.['deduction_date'])}}
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Ack no</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['ack_no']}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Minor head code</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['minor_head_code']}}</div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="detail-title">Late pay fee</div>
                                        <div class="detail-desc">{{challanUpdateList[index]?.['late_pay_fee'] |
                                            currency: currency}}</div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card px-24 py-16">
                                <div class="row row-16">
                                    <div class="col-12 d-flex align-items-start">
                                        <span class="title">MAPPED EMPLOYEES</span>
                                        <div class="ms-auto d-flex gap-16">
                                            <div class="form-icon icon-left" style="min-width:22.688rem;">
                                                <div style="min-width:22.688rem;">
                                                    <div class="form-icon2">
                                                        <i class="icon-search icon-left fs-14"></i>
                                                        <div *ngIf="(searchString != '' && searchString != null)"
                                                            class="btn-close icon-right pointer fs-12"
                                                            (click)="searchString=''"></div>
                                                        <input class="form-control  fs-14"
                                                            placeholder="{{this.messageService.searchdisplay('novalue')}}  "
                                                            name="searchString" [(ngModel)]="searchString"
                                                            (keyup)="page=1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn-square btn btn-secondary" (click)="filter  = true">
                                                <i class="icon-filter-left fs-13"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card card-c2 overflow-hidden">
                                            <div class="table-responsive">
                                                <table class="table table-striped form-table table-sm td-white-space">
                                                    <thead>
                                                        <tr>
                                                            <th class="fw-600 col-3" scope="col">Employee CODE</th>
                                                            <th class="fw-600 col-3" scope="col">Employee name</th>
                                                            <th class="fw-600 col-3" scope="col">PAN NO</th>
                                                            <th class="fw-600 col-3" scope="col">SALARY PROCESSED DATE
                                                            </th>
                                                            <th class="fw-600 col-3" scope="col">gross salary</th>
                                                            <th class="fw-600 col-3" scope="col">INCOME TAX</th>
                                                            <th class="fw-600 col-3" scope="col">SURCHARGE</th>
                                                            <th class="fw-600 col-3" scope="col">EDUCATIONAL CESS</th>
                                                            <th class="fw-600 col-3" scope="col">Total TAX</th>
                                                            <th class="fw-600 col-3" scope="col">TAX PAID IN THIS
                                                                CHALLAN</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="tableData?.length<=0">
                                                            <td colspan="10">
                                                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                                    <span class="fw-500 fs-14">No data found</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            *ngFor='let item of $any(tableData |filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)'>
                                                            <td class=" col-3" scope="col">{{item?.employee_code}}</td>
                                                            <td class=" col-2" scope="col">{{item?.name}}</td>
                                                            <td class=" col-2" scope="col">{{item?.pan_number}}</td>
                                                            <td class=" col-2" scope="col">
                                                                {{this.appService.dateFormatDisplay(item?.created_at)}}
                                                            </td>
                                                            <td class=" col-2" scope="col">{{item?.gross_amount |
                                                                currency:currency}}</td>
                                                            <td class=" col-2" scope="col">{{item?.month_raw_tax |
                                                                currency:currency}}</td>
                                                            <td class=" col-2" scope="col">{{item?.month_surcharge |
                                                                currency:currency}}</td>
                                                            <td class=" col-2" scope="col">{{item?.month_education_cess
                                                                |
                                                                currency:currency}}</td>
                                                            <td class=" col-2" scope="col">{{item?.pay_tax |
                                                                currency:currency}}</td>
                                                            <td class=" col-2" scope="col">{{item?.mapped_amount |
                                                                currency:currency}}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12"
                                        *ngIf="this.appService.filteredcount(tableData,searchString)>pageSize">
                                        <ngb-pagination class="d-flex justify-content-end"
                                            [collectionSize]="this.appService.filteredcount(tableData,searchString)"
                                            [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false"
                                            [boundaryLinks]="true"></ngb-pagination>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- </form> -->
    </main>
</div>

<!-- test start -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16 ">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Delete Challan?</h4>

            </div>
            <div class="modal-body">
                <p class="mb-0">{{this.messageService.Deletemsgdisplay('Challan')}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="deleteToggle = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
                    (click)="deleteChallan()">Delete</button>
            </div>
        </div>
    </div>
</div>

<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
    (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
<!-- test end -->
