<!-- <form class="" > -->
<div class="container-fluid p-32 form-container">
  <div class="row row-16 mb-16">
    <div class="col-12 d-flex">
      <p class="text-uppercase fw-500 mb-0">Prior payroll Month wise</p>
      <a class="link-danger ms-auto fs-12" (click)="confirmDelete('all')">Disable prior payroll</a>
    </div>
    <div class="col-12" *ngIf="!isClosed">
      <div role="alert" class="alert alert-warning mb-0 show">
        <p class="mb-0 fs-14">Input all employees' YTD earnings and tax deducted information on monthly basis before
          running your payroll to ensure accurate calculation of taxes.</p>
        <div class="btn-close pointer" (click)="isClosed=true;"></div>
      </div>
    </div>
    <app-loader *ngIf="monthLoader"></app-loader>
    <ng-container *ngIf="!monthLoader">
      <ng-container *ngFor="let data of MonthlyData let i = index">
        <div class="col-md-6 col-sm-12 ">
          <div class="card card-custom1 card-hover">
            <div class="card-body d-flex align-items-start p-0">
              <div class="d-flex p-16 w-100 align-items-start">
                <div class="logo-img bg{{i%5}}">{{this.appService.getFirstChar(data.month,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-0 mb-1 tips">{{data.month}} {{data.year}}</h6>
                  <span class="tips-helper">{{data.month}} {{data.year}}</span>
                  <div class="d-flex gap-8 fs-10 fw-500 ">
                    <span
                      class="fs-10 {{data.status=='PENDING'?'text-danger':data.status=='UPLOADED'?'text-warning':'text-success'}} text-uppercase fw-500 ">{{data.status}}</span>
                    <span class="text-light-400">({{data.completed}}/{{data.tot_emp}})</span>
                  </div>
                </div>

              </div>

            </div>
            <div class="card-body align-items-center px-16 pt-12 pb-16  ">

              <div class="d-flex gap-24 fs-12 fw-500">
                <div class="link-primary1 fw-500 fs-12" (click)="showSidePanel = true;month=data.month;year=data.year">
                  <i class="bi bi-cloud-upload m-1"></i>{{data.status=='PENDING'?'Upload new':'Upload document'}}
                </div>

              </div>


              <span class="link-primary1 d-flex align-items-center fs-12 ms-auto text-nowrap"
                *ngIf="data.completed!=0"
                (click)="getMonthlySummary(data.month,data.year,data.status,false)">
                <i class="icon-eye me-1"></i> View details
              </span>
            </div>
          </div>
        </div>

      </ng-container>
    </ng-container>

  </div>

  <div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='detailedPanel === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>{{selectedmonth}} {{selectedyear}}</h5>
        <a class="toggle-panel" (click)="detailedPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
          <app-c1-loader *ngIf="detailedView==false"></app-c1-loader>
          <ng-container *ngIf="detailedView">
            <div class="row row-16">
              <div class="col-12 d-flex">
                <button type="button" class="btn ms-auto btn-outline-primary fs-10 fw-700 btn-sm text-uppercase"
                  (click)="exportMonthlyPrior()">Export</button>
              </div>

              <div class="col-12 ">
                <app-financial-calc-view [payrollMonthlyCost]="monthlySumm?.total_cost"
                  [payroll_month]="monthlySumm?.total_cost?.pay_month" [payroll_year]="monthlySumm?.total_cost.pay_year"
                  [currency]="monthlySumm?.currency" [from]="'slider'">

                </app-financial-calc-view>
              </div>

              <div class="col-12">
                <div class="card card-c2">
                  <div class="card-body py-0 px-24 d-flex align-items-end">
                    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab2" class="nav radius-4 nav-custom shadow-0 p-0"
                      style="min-height: unset;">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink class="nav-link h-100 py-16">Salary Summary</a>
                        <ng-template ngbNavContent>
                          <app-monthly-salary-view [issalsumm]="true" [salarysummaryList]="monthlySumm.salary_summary"
                            [month]="monthlySumm?.total_cost?.pay_month" [year]="monthlySumm?.total_cost.pay_year"
                            [searchString]="searchString" [page]="page" [pageSize]="pageSize">
                          </app-monthly-salary-view>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink class="nav-link h-100 py-16">Employee Salary Summary</a>
                        <ng-template ngbNavContent>
                          <app-monthly-salary-view [isempsalsumm]="true"
                            [empsalarysummaryList]="monthlySumm?.employee_salary_summary"
                            [month]="monthlySumm?.total_cost.pay_month" [year]="monthlySumm?.total_cost.pay_year"
                            [searchString]="searchString" [page]="page" [pageSize]="pageSize"
                            (reloadpage)="reloadPage()" [isRunParoll]="monthlySumm.edit_flag">
                          </app-monthly-salary-view>
                        </ng-template>
                      </li>

                    </nav>
                    <div class="d-flex align-items-center gap-16 ms-auto py-12">

                      <div class="form-icon2">
                        <i class="icon-search icon-left fs-14"></i>
                        <div *ngIf="(searchString != '' && searchString != null)"
                          class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                        <input class="form-control  fs-14"
                          placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString"
                          [(ngModel)]="searchString" (keyup)="page=1" />
                      </div>
                      <button class="btn-square btn btn-secondary" (click)="filter  = true">
                        <i class="icon-filter-left fs-13"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-24">
                    <div [ngbNavOutlet]="nav"></div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</div>
<footer class="submit-footer">
  <button class="btn btn-primary btn-sm ms-auto" [disabled]="!hasData" (click)="nextpage()">NEXT</button>
</footer>
<!-- </form> -->

<!-- Slider -->
<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>PRIOR PAYROLL</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">{{month}} {{year}}</div>
              <div class="row" *ngIf="fileName">
                <div class="col-md-6">

                  <div class="card card-c2  bg-secondary mb-24">
                    <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                      <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                      <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                        <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                        <span class="tips-helper ">{{fileName}}</span>

                      </div>
                      <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearData()"></i>
                    </div>
                  </div>
                  <!-- <div class="card card-c2 bg-secondary  mb-24">
                    <div class="d-flex p-24 ">
                      <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                      <span class="text-trim fw-500">{{fileName}}</span>
                      <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                      <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()"><i
                          class="bi bi-trash ms-8 "></i></span>
                    </div>
                  </div> -->
                </div>
              </div>
              <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                  (change)="onFileChange($event)" id="upload" hidden>
                Import Prior Payroll
              </label>

            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">Instructions </div>
              <ol class="fs-14 ps-16 mb-0 li-24">
                <li>Download the <a class="link-primary" href="assets/docs/sample-prior-payroll.xlsx"> <i
                      class="icon-download me-1"></i> Sample Excel
                    template</a></li>
                <li>Enter the prior payroll details in the excel as per the format provided in the excel sheet.</li>
                <li>Save the file.</li>
                <li>To import the file click the "Import prior payroll" button.</li>
                <li>Choose the file to be imported and click on the upload button.</li>
                <li>The file chosen should be in (.xls/.csv)format only.</li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </div>
    <footer class="side-panel-footer gap-32 pt-16">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
        (click)="uploadPrior()">Upload</button>
    </footer>
  </div>

</div>

<div class="modal  modal-alert {{errorToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Error Occured</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{errorMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="errorToggle = false;">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="failedData.length" class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title" *ngIf="successDataCount>0">{{successDataCount}} employee prior payroll uploaded successfully.</h4>
          <h4 class="modal-title" *ngIf="successDataCount==0">No employee prior payroll uploaded</h4>
          <button type="button" class="btn-close ms-auto m-0" (click)="downloadMsg = false;clearData()"></button>
          </div>
          <div *ngIf="failedData.length" class="modal-body">
            <p class="mb-0">{{failedData.length}} error data found.</p>
            <p class="mb-0">Click on download to view the error details</p>
          </div>
          <div  *ngIf="failedData.length" class="modal-footer">
              <button type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
          </div>
      </div>
  </div>
</div> -->
<app-error-download [fileName]="'Prior payroll error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg"
  [failedData]="exportData" [modalHeading]="'Prior Payroll'"
  (closePanel)="downloadMsg = false;showSidePanel=false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">
</app-error-download>

<div class="modal  modal-alert {{isDisable == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16 ">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">{{deleteHead}}</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{confirmMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="isDisable = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="deletePrior()">{{buttonText}}</button>
      </div>
    </div>
  </div>
</div>

<app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
  [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
  (resetmethod)="filterReset()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
