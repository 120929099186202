import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ReimbursementRequestService } from 'src/app/@core/services/reimbursement-request.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
@Component({
  selector: 'app-view-reimbursement',
  templateUrl: './view-reimbursement.component.html',
  styleUrls: ['./view-reimbursement.component.scss']
})
export class ViewReimbursementComponent implements OnInit {
  modalToggle                     = false;
  allowedExtension                = "";
  alertMsg:any                    = '';
  alertToggle                     = false;
  fileName                        = "";
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  currentYear                     = new Date().getFullYear();
  currentMonth                    = new Date().toLocaleString('en-us', { month: 'long' }).toUpperCase();
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  @Input()currency:any
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;


  @Input()reimbursementData:any;
  @Input()loader1:any;
  @Input()requestId:any;
  @Input()from:any;
  @Input()reimbursementType:any;

  @Output() closeViewPage= new EventEmitter();

  requestCancel = false;
  specificId: any;

  constructor(
    public appservice:AppService,
    public reqService:ReimbursementRequestService,
    private notificationService: NotificationService,
    private investmentService: InvestmentDeclerationService,
  ) {
    this.investmentService.request_id.subscribe(result =>{
      if(Number(this.requestId) == Number(result))
        this.viewSingleBillData(this.specificId);
    })
   }

  ngOnInit() {
    if(this.from!='behalf'){
      console.log("hreee")
      let userData        = this.appservice.getEmpData();
      this.currency       = userData.currency;
    }
  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }
  /*View Individual Bill Status*/
  viewSingleBillData(billId:any){
    this.viewstatusPanel  = true
    this.billLoader       = true;
    this.specificId       = billId;
    if(typeof this.requestId === 'number' && typeof billId === 'number'){
      this.reqService.getIndividualBillData(billId,this.requestId).subscribe((res:any)=>{
        this.viewStatusData     = res;
        if(!res?.apporval_matrix_loading)
          this.billLoader       = false;
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
      })
    }
  }
 ;

// Modal Close
close1(bool:any){
  this.modalToggle = bool;
}

// test start
cancelId:any;
cancelRequest(status:any, cancelId:any, cancelStatus:any){
  if(cancelStatus){
    this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
  }else{
    this.requestCancel = true;
    this.cancelId      = cancelId;
  }
}

closeCancelModal(data:any){
  this.requestCancel = data?.show;
  if(data?.action == 'submit')
    this.closeViewPage.emit(data?.show);
}
updateStatus(data:any){
  this.closeViewPage.emit(data);
  this.requestCancel = false;

}
// test end

}

