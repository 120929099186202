import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { LetterTemplateSettingsService } from 'src/app/@core/services/letter-template-settings.service';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';

@Component({
  selector: 'app-letter-template-setup',
  templateUrl: './letter-template-setup.component.html',
  styleUrls: ['./letter-template-setup.component.scss']
})
export class LetterTemplateSetupComponent implements OnInit {

  activeTab                 = 1;
  id                        = 0
  typesDropdown   : any     = [];
  modulesDropdown : any     = [];
  title                     ="Add"
  iseditForm      : boolean = false;
  disabled        : boolean = false
  moduleId                  = 0
  loader          : boolean = false
  defaultTemplate : any     = []
  tagList         : any     = []
  pdfURL          : any

  tab2touched     : boolean = false
  tab3touched     : boolean = false
  tab4touched     : boolean = false

  showTab         : boolean = false

  constructor(
    private fb        : FormBuilder,
    private letterServ: LetterTemplateSettingsService,
    private previousRouteService : PreviousRouteService,
    private router:Router,
    private _location: Location,
    private route : ActivatedRoute,
    private cd : ChangeDetectorRef
  ) { }

  letterTemplateForm : FormGroup = this.fb.group({
    details          : this.fb.group({
      name           : [null,[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      module         : [null,[Validators.required]],
      letter_type    : [null,[Validators.required]],
    }),
    templateSelect   : this.fb.group({
      templateID     : [null,[Validators.required]],
      tags           : [[]]
    }),
    templateEditor   : this.fb.group({
      template       : [null,[Validators.required]],
      recentTags     : [[]],
      is_default     : [false]
    }),
    preview          : this.fb.group({
      previewURL     : [null,[Validators.required]]
    })
  })

  get detailsForm(){
    return this.letterTemplateForm.get('details') as FormGroup
  }

  get templateSelectForm(){
    return this.letterTemplateForm.get('templateSelect') as FormGroup
  }

  get templateEditorForm(){
    return this.letterTemplateForm.get('templateEditor') as FormGroup
  }

  get previewForm(){
    return this.letterTemplateForm.get('preview') as FormGroup
  }

  ngOnInit(): void {
    this.loader = true
    this.showTab = false
    // Letter Type Dropdown
    this.getModuleDropdown();
    // Get Tag List
    this.getTagLists();
    // Default Template
    this.getDefaultTemplate();
    // Edit
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editTemplateForm();
      }else{
        this.loader = false
      }
    })
  }

  updatePreviewURLValidation() {
    if (this.iseditForm) {
      this.previewForm.get('previewURL')?.clearValidators();
    }
    this.previewForm.get('previewURL')?.updateValueAndValidity();
  }

  // Navbar
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.letterTemplateForm['controls'].details.invalid) {
        changeEvent.preventDefault();
      } else
        !this.showTab ? this.tab2touched = true : this.tab3touched = true
    }
    else  if (changeEvent.nextId === 3) {
      if (this.letterTemplateForm['controls'].templateSelect.invalid || this.letterTemplateForm['controls'].details.invalid|| (this.tab2touched == false && !this.iseditForm)) {
        changeEvent.preventDefault();
      } else
      this.previewForm.get('previewURL')?.setValue('')
      this.tab3touched = true
    } else  if (changeEvent.nextId === 4) {
      if (this.letterTemplateForm['controls'].templateSelect.invalid || this.letterTemplateForm['controls'].details.invalid || this.letterTemplateForm['controls'].templateEditor.invalid || (this.tab3touched == false && !this.iseditForm) ) {
        changeEvent.preventDefault();
      } else
        this.convertHTMLtoPDF();
        this.tab4touched = true;
    }
    else if (changeEvent.nextId === 5) {
      if (this.letterTemplateForm['controls'].templateSelect.invalid ||this.letterTemplateForm['controls'].templateEditor.invalid || this.letterTemplateForm['controls'].details.invalid || this.letterTemplateForm['controls'].preview.invalid || (this.tab4touched == false && !this.iseditForm) ) {
        changeEvent.preventDefault();
      }
    }
  }

  // Next Button
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if (activeTab === 4) {
      this.convertHTMLtoPDF();
      this.tab4touched = true;
    }
    if (activeTab === 3) {
      this.tab3touched =true
    } else  if (activeTab === 2) {
      if(!this.showTab){
        this.tab2touched = true
      }else{
        this.tab3touched = true
        this.activeTab = 3;
      }
    }
  }

  // Id Retrieve
  moduleIdFn(id:any){
    this.moduleId = id
    this.getLetterTypes()
  }

  // Module Dropdown
  getModuleDropdown(){
    this.letterServ.getModuleDropdown().subscribe((res:any)=>{
      this.modulesDropdown = res?.body
      this.modulesDropdown.push({
        "id": 0,
        "name": "Other"
      })
    })
  }

  // Letter Type Dropdown
  getLetterTypes(){
    this.letterServ.getLetterTypeDropdown(this.moduleId == undefined ? '' : this.moduleId).subscribe((res:any)=>{
      this.typesDropdown = res?.body
    })
  }

  // Default Template
  getDefaultTemplate(){
    this.letterServ.getDefaultLetters().subscribe((res:any)=>{
      this.defaultTemplate = res?.body
    })
  }

  // Tag List
  getTagLists(){
    this.letterServ.getTagsList().subscribe((res:any)=>{
      this.tagList = res?.body
    })
  }

  fontName : any
  fontDeatils(font:any){
    this.fontName = font
  }

  replaceForPDF(content:string):string {
    content = content.replace(/font-family:null/g, `font-family:\'${this.fontName}\'`);
    content = content.replace(/font-size: var\(--bs-body-font-size\)/g, 'font-size:16px');
    content = content.replace(/text-align: var\(--bs-body-text-align\)/g, 'text-align:left');
    content = content.replace(/border-color: var\(--bs-table-border-color\)/g, 'border-color:black');
    return content
  }

  // Convert HTML to PDF
  convertHTMLtoPDF(){
    const regex = /{{([^{}]*)}}/g;
    let keysArray : any = [];
    let match;
    while ((match = regex.exec(this.templateEditorForm.get('template')?.value)) !== null) {
      const key = match[1].trim();
      if (key !== "" && !keysArray.includes(key)) {
        keysArray.push(key);
      }
    }
    let dataArray = {
      'letter_tag' : keysArray.length == 0 ? [] : JSON.stringify(keysArray),
      'template_html' : this.commonHTMLContent(this.replaceForPDF(this.templateEditorForm.get('template')?.value))
    }
    this.letterServ.createPDF(dataArray).subscribe((res:any)=>{
      this.previewForm.get('previewURL')?.setValue(res?.body?.letter_template_pdf)
    },
    (error:any)=>{
      this.activeTab = 3
    })
  }

  // Edit Form
  editTemplateForm(){
    this.showTab = true
    this.loader = true
    this.letterServ.getSingleData(this.id).subscribe((res:any)=>{
      this.moduleIdFn(res?.body.module?.id)
      this.title = 'Edit'
      this.iseditForm = true;
      this.setEditTemplateForm(res.body)
      this.loader = false
    },(error)=>{
      this.router.navigate(['/letter-template']);
    })
  }

  setEditTemplateForm(res:any){
    if(res.is_active == false){
      this.letterTemplateForm.disable();
      this.disabled = true;
    }
    this.letterTemplateForm.reset({
      details : {
        name           : res?.name,
        module         : res?.module?.id == undefined ? 0 : res?.module?.id,
        letter_type    : res?.letter_type?.id
      },
      templateSelect : {
        templateID     : res?.template
      },
      templateEditor : {
        template       : res?.code_view_html,
        recentTags     : this.parseData(res?.letter_tag),
        is_default     : res?.is_default
      },
      preview : {
        previewURL     : ''
      }
    })
    this.updatePreviewURLValidation()
  }

  parseData(dataString: string): any {
    try {
      return JSON.parse(dataString);
    } catch (error) {
      if (dataString.includes("'")) {
        const correctedDataString = dataString.replace(/'/g, '"');
        try {
          return JSON.parse(correctedDataString);
        } catch (innerError) {
          return [];
        }
      } else {
        return [];
      }
    }
  }

  commonHTMLContent(data:any){
    function convertPercentageToPixels(percentage: string, containerWidth: number): string {
      const percentValue = parseFloat(percentage);
      const pixelValue = (percentValue / 100) * containerWidth;
      return `${Math.round(pixelValue)}px`;
    }
    const updatedData = data.replace(/style="([^"]*?)"/g, (match: string, styleContent: string) => {
      const updatedStyle = styleContent.replace(/(\d+(\.\d+)?)%/g, (percentMatch: string) => {
        return convertPercentageToPixels(percentMatch, 800);
      });
      return `style="${updatedStyle}"`;
    });
    return `<html>
    <head>
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100,200,300,400,500,600,700,800,900;1,100,200,300,400,500,600,700,800,900&display=swap');
      @page{
        size: a4 portrait;
        margin: 1.2cm;
      }

      table {
        word-break: break-all;
      }

      th, td {padding: 8px;border: 1px solid #dddddd;text-align: left;font-size: 8px;-pdf-word-wrap: CJK;}

      body {
        font-family: 'Work Sans';
        font-size: 16px;
      }

      .wrapper {
        white-space: pre-wrap;
        width: 100%;
      }

      .tag-data {
        font-size:15px;
        word-break: break-all;
        -pdf-word-wrap: CJK;
      }

      .container {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        white-space: pre-wrap;
      }

      p{
        margin : 0;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    </style>
    </head>
    <body>
      <div class="container">
        ${updatedData}
      </div>
    </body>
    </html>`
  }

  submitForm(){
    const regex = /{{([^{}]*)}}/g;
    let keysArray : any = [];
    let match;
    while ((match = regex.exec(this.templateEditorForm.get('template')?.value)) !== null) {
      const key = match[1].trim();
      if (key !== "" && !keysArray.includes(key)) {
        keysArray.push(key);
      }
    }
    let dataArray =  {
      ...this.detailsForm.getRawValue(),
      'template' : this.commonHTMLContent(this.replaceForPDF(this.templateEditorForm.get('template')?.value)),
      "letter_tag": keysArray.length == 0 ? JSON.stringify([]) : JSON.stringify(keysArray),
      // Is other should be true for Other Module Selection
      "is_other": this.detailsForm.get('module')?.value == 0 ? true : false
    }
    dataArray['module'] = dataArray['module'] == 0 ? '' : dataArray['module']
    if(this.id == 0){
      this.letterServ.createTemplate(dataArray).subscribe((res:any)=>{
        this.router.navigate(['letter-template']);
      })
    }else{
      this.letterServ.updateLetter(dataArray,this.id,'').subscribe((res:any)=>{
        this.router.navigate(['letter-template']);
      })
    }
  }

  // Backclicked
  backClicked() {
    if(this.previousRouteService.getPreviousUrl() == '/login')
      this.router.navigate(['letter-template']);
    else
      this._location.back();
  }

}
