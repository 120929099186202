import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ShiftAllowanceService } from 'src/app/@core/services/shift-allowance.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shift-allowance-policy',
  templateUrl: './shift-allowance-policy.component.html',
  styleUrls: ['./shift-allowance-policy.component.scss']
})
export class ShiftAllowancePolicyComponent implements OnInit {
  viewDetail = false;
  permissions:any       = [];
  offset                 = 0;
  listlength             = 0;
  direction              = '';
  defaultlimit:any       = 20;
  shiftDetailsList:any   = [];
  infinityloader         = false;
  shiftDetails: any      = [];  
  searchlistdata:any     = [];
  shiftsearch:string =this.messageService.searchdisplay('Shift allowance');
  nodata                 = false;
  loader                 = false;
  statusBtn:any    = "Active";
  searchKeyword    = "";
  searchData : Subject<void> = new Subject();
  status: any      = true;
  isLoading = false
  deleteAlert     = false;
  deleteClicked         = false;
  deleteId        = 0;
  submitted        = false;
  loader1          = false;
  shiftView:any         =[];
  editid:any;
  assignees:any           = [];
  
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    public appService: AppService,
    public breakpointObserver: BreakpointObserver,
    public messageService: MessageService,
    public ShiftAllowanceService: ShiftAllowanceService,
    public router: Router,

  ) { }

  ngOnInit(): void {
    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });

    this.shiftList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.ShiftAllowanceService.getShiftAllocatList( this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/shift-allowance');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  shiftList() {
    this.loader           = true;
    this.ShiftAllowanceService.getShiftAllocatList( this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength       = res.body.results.length;
    if(this.offset ==0){
      this.shiftDetails = [];
    }
    if (res.body.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata               = false;
      this.shiftDetailsList = res.body.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.shiftDetails.push(this.shiftDetailsList[i]);
    }
  }

  addNewdata(){
    this.router.navigate(['/shift-allowance/shift-allowance-setup']);
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset         += this.defaultlimit;
    if(this.listlength){
      this.shiftList();
      this.loader       = false;
      this.direction    = "down";
    }
  } 

  searchresults(data:any){
    this.loader = true;
    this.shiftDetails = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchshift(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.shiftDetails = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  statusfilter(active_status:any){
    this.status           = active_status;
    this.shiftDetails     = [];
    this.offset           = 0;
    this.child.clearBar();
  }
  viewShift(id:any){
    this.assignees=[];
    if(this.permissions.v){
      this.loader1 = true;
      this.viewDetail = true;
      this.ShiftAllowanceService.shiftGet(id).subscribe((res: any) => {
        this.shiftView = res?.body;
        let key = res?.body?.applicability?.applicable;
        this.assignees = res?.body?.applicability[key];
        if(key == 'BU'){
          res.body.applicability['applicable']='Business Unit';
        }
        this.loader1 = false;

      })
  }
}

confirmDelete(id:any){
  this.deleteClicked = true;
    this.ShiftAllowanceService.shiftDelete(id).subscribe((res: any) => {
        this.deleteClicked = false;
        this.deleteAlert      = false;
        this.loader           = true;
        this.offset           = 0;
        this.shiftDetails = [];
        this.shiftList();
    });
}

editshiftallowance(id:any){
  this.editid=id;
  this.router.navigate(['/shift-allowance/shift-allowance-setup/'+id]);
}

}
