
  <div class="row row-8">

    <div class="col-12 d-flex position-relative">
      <span class=" text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>

      <button *ngIf="from!='behalf'" class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
        (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8">
        <h5 class="mb-8">Salary Revision Approval</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
          <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
            *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
          <span class="badge px-16 ms-auto fs-14 badge-success py-1"
            *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
          <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
            *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>
          <span class="text-light-400">|</span>
          <span class="link-primary fs-14 text-nowrap" (click)="requestViewStatus()">View Status</span>
        </ng-container>
        <!-- Invalid request -->
        <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
          class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <span class=" badge-custom {{this.inboxservice.getColor(requestitem.request_type_color_code)}} ">Salary Revision Approval</span>
      <span
        *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
        class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <ng-container *ngIf="from!='behalf'">

      <app-progressbar [requestitem]="requestitem"></app-progressbar>

      <div class="col-12">
        <p class="mb-0 fs-14" [innerHTML]="requestitem.message">
        </p>
      </div>
    </ng-container>
    <div class=" col-md-9  col-lg-6">
      <button class="btn btn-outline-primary w-100 fw-400 btn-sm radius-2" (click)="salaryRevision()">Salary
        Revision
        Approval</button>
    </div>

  </div>

<!-- SALARY REVISION VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;"
  [class.side-pane-active]='salaryRevisionDetailsView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Salary Revision Details</h5>
      <a class="toggle-panel" (click)="salaryRevisionDetailsView = false;clearComment()"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">

      <app-salary-revision-details-view [requestitem]="requestitem" *ngIf="revId!=0" [from]="from" [hisId]="revId"
        (refreshEmitter)="refreshInbox($event)" [inboxId]="inboxId"></app-salary-revision-details-view>


    </div>
  </div>
</div>
<!-- SALARY REVISION VIEW START -->

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem?.request_data?.id" (closePanel)="viewstatusPanel=false"></app-view-status>



    </form>
  </div>
</ng-container>
<!-- end view status panel -->
