import { Component, Inject, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormArray,FormBuilder,Validators,FormGroup } from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { PanfieldValidator} from 'src/app/@shared/validators/panfield.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';


@Component({
  selector: 'app-confirmed-investment-decleration-setup',
  templateUrl: './confirmed-investment-decleration-setup.component.html',
  styleUrls: ['./confirmed-investment-decleration-setup.component.scss']
})

export class ConfirmedInvestmentDeclerationSetupComponent implements OnInit {

  active                = 1;
  cityDropDown          = [];
  investDetails         = [];
  name:any              = '';
  proposed_confirm:any  = false;
  taxRegima: any        = false;
  empId                 = 0;
  currentYear:number    = 0;
  latestProposedData:any= [];
  secTab:any            = false;
  tax_chapt2 : any      = '';
  tax_deduction : any   = '';
  loader                = false;
  investmentCnt:any     = [];
  proposed_cnt:any      = [];
  confirmed_cnt:any     = [];
  employeeAge:number    = 0;
  tot_amount            = false;
  currency:any;
  limitData             = [];
  taxRegimeData:any     = [];
  hraValue: any         = 1;
  investmentDetails:any = [];

  tab2touched = false;
  tab3touched = false;
  tab4touched = false;
  tab5touched = false;
  tab6touched = false;
  from:any
  requestFor:any = ''
  investmentDeclarationForm: FormGroup = this.formBuilder.group({
    hra80gg: this.formBuilder.group({

      hra80gg                           : this.formBuilder.array([])
    }),
    chapt2: this.formBuilder.group({
      employee_contribute_pf            : false,
      employee_contribute_pf_amount     : [''],
      contribution_vpf                  : false,
      contribution_vpf_amount           : [''],
      contribution_15y_ppf              : false,
      ppf_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ppf_document                      : [''],
      previous_employer_pf              : false,
      previous_employer_pf_amount       : [''],
      five_year_POTD                    : false,
      POTD_amount                       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      POTD_document                     : [''],
      subscribe_national_saving         : false,
      nsc_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      nsc_document                      : [''],
      deduction_contribute_NPS          : false,
      nps_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      nps_document                      : [''],
      equity_linked_saving              : false,
      ELSS_amount                       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ELSS_document                     : [''],
      ULIP                              : false,
      ULIP_amount                       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ULIP_document                     : [''],
      interest_NSC                      : false,
      interest_NSC_amount               : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_nsc_document             : [''],
      child_education_tuition_fees      : false,
      tuition_fees                      : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      tuition_fees_document             : [''],
      repay_house_principal             : false,
      repay_house_amount                : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      repay_house_document              : [''],
      stampduty_purchase_house          : false,
      stampduty_amount                  : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      stampduty_document                : [''],
      saukanya_samridhi_yojana          : false,
      ssy_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ssy_document                      : [''],

      nabard_rural_bond                 : false,
      nabard_rural_bond_amount          : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      nabard_rural_bond_document        : [''],
      lic                               : false,
      lic_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      lic_document                      : [''],
      annuity                           : false,
      annuity_amount                    : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      annuity_document                  : ['']
    }),
    deductions: this.formBuilder.group({

      employee_additional_contribution          : false,
      employee_contribution_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      employee_contribution_document            : [''],
      employer_contribution                     : false,
      employer_contribution_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      employer_contribution_document            : [''],
      tta_ttb                                   : false,
      tta_ttb_amount                            : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      tta_tta_document                          : [''],
      interest_education_loan                   : false,
      interest_education_amount                 : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_education_document               : [''],
      medical_treatment_handicapped             : false,
      disabled_severely                         : true,
      handicapped_dependent_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      handicapped_dependent_document            : [''],
      medical_expenditure                       : false,
      year_60_more                              : true,
      medical_expenditure_amount                : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      medical_expenditure_document              : [''],
      issue                                     : [],
      self_suffering_disability                 : false,
      self_disabled_severely                    : true,
      self_suffering_amount                     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      self_suffering_document                   : [''],
      donate_80g                                : this.formBuilder.array([]),
      royalty_patent                            : false,
      royalty_patent_amount                     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      royalty_patent_document                   : [''],
      contribution_individual_policalparties    : false,
      contribution_policalparties_amount        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      contribution_policalparties_document      : [''],
      gga80                                     : false,
      cash_other_80gga                          : false,
      deduction_80gga                           : this.formBuilder.array([]),
      total_amount                              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      transaction_80gga_document                : [''],


      health_insurance_selfspouse80d            : false,
      health_insurance_selfspouse80d_age        : true,
      health_insurance_selfspouse80d_amount     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_insurance_selfspouse80d_document   : [''],
      health_insurance_parents80d               : false,
      health_insurance_parents80d_age           : true,
      health_insurance_parents80d_amount        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_insurance_parents80d_document      : [''],
      health_checkup_selfspouse80d              : false,
      health_checkup_selfspouse80d_amount       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_checkup_selfspouse80d_document     : [''],
      health_checkup_parents80d                 : false,
      health_checkup_parents80d_amount          : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_checkup_parents80d_document        : [''],
      medicalbill_senior80d                     : false,
      medicalbill_senior80d_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      medicalbill_senior80d_document            : [''],
      interest_home_loan_80eea                   : false,
      homeloan80eea_option1                     : false,
      homeloan80eea_option2                     : false,
      homeloan80eea_option3                     : false,
      interest_home_loan_amount80eea            : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_home_loan_document80eea          : [''],
      interest_electrical_vehicle_loan_80eeb    : false,
      interest_electrical_vehicle_loan_amount   : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_electrical_vehicle_loan_document : [''],
      interest_home_loan_80ee                   : false,
      homeloan80ee_option1                      : false,
      homeloan80ee_option2                      : false,
      homeloan80ee_option3                      : false,
      homeloan80ee_option4                      : false,
      interest_home_loan_amount80ee             : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_home_loan_document80ee           : [''],
      donate_social_cause100                    : false,
      donate_social_cause100_amount             : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      cash_other100                             : true,
      fund_type100                              : [],
      transaction_document100                   : [''],
      donate_social_cause_50                    : false,
      donate_social_cause50_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      cash_other50                              : true,
      fund_type50                               : [],
      transaction_document50                    : [''],
      donate_80g100                             : this.formBuilder.array([]),
      donate_80g50                              : this.formBuilder.array([]),
    }),

    incomeloss: this.formBuilder.group({
      incomefrom_self_occupiedproperty           : false,
      intreston_houseloan_amount                 : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      intreston_houseloan_document               : [''],
      incomeself_lender                          : this.formBuilder.array([]),
      incomefrom_letoutproperty                  : false,
      loss_lender                                : this.formBuilder.array([]),

    }),

    otherincome: this.formBuilder.group({

      incomefrom_othersource                : false,
      othersource_amount                    : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      othersource_document                  : [''],
      interest_earned_sbaccount             : false,
      sb_account_amount                     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      sb_account_document                   : [''],
      source_80TTA_80TTB                    : false,
      source_80TTA_80TTB_amount             : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      source_80TTA_80TTB_document           : [''],
      intrest_earned                        : false,
      intrest_earned_amount                 : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      intrest_earned_document               : [''],
    }),
  });

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    private formBuilder:FormBuilder,
    private investmentService:InvestmentDeclerationService,
    public router: Router,
    public route:ActivatedRoute,
    private authService:AuthService,
    private activityService:ManageActivityService
  ) { }

  backClicked() {
    this.investmentService.backClicked('/confirmed-investment-decleration')
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.empId = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
          this.requestFor = "";
          if(res['first_name'] != null)
          this.requestFor+= res['first_name']+" ";
          if(res['middle_name'] != null)
          this.requestFor+= res['middle_name']+" ";
          if(res['last_name'] != null)
          this.requestFor+= res['last_name'];
          this.requestFor+=" ("+res['employee_code']+")"
          this.currency = res.currency
        })
      }
      else{
        let userData    = this.appservice.getEmpData();
        this.empId       = this.authService.isEmployeeId;
        this.currency    = userData.currency;

      }


    })
    let donate_80g100:any = [];
    let donate_80g50:any  = [];

    this.limitList();

    this.salaryStructureConfig();

    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.currentYear = this.appservice.getFinancialYear();
    this.loader      = true;

    this.investmentService.investmentDatas(this.empId,this.currentYear,'').subscribe((res:any)=>{
      this.loader = false;
      if(res.length > 0){
        this.latestProposedData = res[0];

        if(this.latestProposedData.hra80gg.length > 0){
          for(let i=0; i<this.latestProposedData.hra80gg.length; i++){
            this.addHra80gg();
          }
          (<FormArray>this.investmentDeclarationForm.get("hra80gg")?.get("hra80gg")).reset(this.latestProposedData.hra80gg);
        }

        if(this.latestProposedData.chapter4a80c != null && this.latestProposedData.chapter4adeduction != null){
        this.investmentDeclarationForm['controls'].chapt2.reset(this.latestProposedData.chapter4a80c);

        this.decuctionDateFormateChange();
        if(this.latestProposedData.chapter4adeduction.deduction_80gga.length > 0){
        for(let i=0; i<this.latestProposedData.chapter4adeduction.deduction_80gga.length; i++){
          this.addDeduction_80gga();
        }}
        for(let k=0; k<this.latestProposedData.chapter4adeduction.donate_80g.length; k++){
          if(this.latestProposedData.chapter4adeduction.donate_80g[k]['exemption'] == true){
            this.addDonate_80g100();
            donate_80g100.push(this.latestProposedData.chapter4adeduction.donate_80g[k]);
          }if(this.latestProposedData.chapter4adeduction.donate_80g[k]['exemption'] == false){
            this.addDonate_80g50();
            donate_80g50.push(this.latestProposedData.chapter4adeduction.donate_80g[k]);
          }
          this.addDonate_80g();
        }

        this.investmentDeclarationForm['controls'].deductions.reset(this.latestProposedData.chapter4adeduction);
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g100")).reset(donate_80g100);
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g50")).reset(donate_80g50);

        }

        // Income loss reset start
        this.investmentDeclarationForm['controls'].incomeloss.reset(this.latestProposedData.incomeloss_property);
        if(this.latestProposedData.incomeloss_property.incomeself_lender.length > 0){
          for(let i=0; i<this.latestProposedData.incomeloss_property.incomeself_lender.length; i++){
            this.addLenderIncome();
          }
        }
        (<FormArray>this.investmentDeclarationForm.get("incomeloss")?.get("incomeself_lender")).reset(this.latestProposedData.incomeloss_property.incomeself_lender);
        if(this.latestProposedData.incomeloss_property.loss_lender.length > 0){
        for(let i=0; i<this.latestProposedData.incomeloss_property.loss_lender.length; i++){
          this.addLenderLoss();
        }}
        (<FormArray>this.investmentDeclarationForm.get("incomeloss")?.get("loss_lender")).reset(this.latestProposedData.incomeloss_property.loss_lender);

        this.incomeLossValidation();
        // Income loss reset end

        this.investmentDeclarationForm['controls'].otherincome.reset(this.latestProposedData.income_from_othersource);


        this.chapt2Setvalue('null');
        this.deductionsSetvalue('null');
        this.otherincomeSetvalue('null');
        this.incomeLossSetValue('null');


      }else{
        this.latestProposedData = '';
      }
       // Validation section start
       this.hra80ggValidation(this.name);
       this.chap2Validation(this.name);
       this.deductionValidation(this.name);
       this.otherincome(this.name);
     // Validation section end

    });

    this.investmentService.cityDropdownList().subscribe((res:any)=>{
      this.cityDropDown = res;
    });
  }

  limitList(){
    this.investmentService.investLimitList().subscribe((res:any)=>{
      this.limitData = res;
    });
  }

  decuctionDateFormateChange(){
    if(this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga.length;k++){
        let final_from_Date2 = (this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga[k]['date'] != null) ? (this.appservice.dateFormatConvert(this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga[k]['date'])) : null;
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("deduction_80gga")).at(k).get('date')?.setValue(final_from_Date2);
      }
    }

    if(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length;k++){
        let final_from_Date3 = (this.investmentDeclarationForm.getRawValue().deductions.donate_80g[k]['payment_date'] != null) ? (this.appservice.dateFormatConvert(this.investmentDeclarationForm.getRawValue().deductions.donate_80g[k]['payment_date'])) : null;
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g")).at(k).get('payment_date')?.setValue(final_from_Date3);
      }
    }

  }

  investmentDeclarationFormDataModificationFunction(){

   this.hr80ggSetvalue();
   this.chapt2Setvalue('zero');
   this.deductionsSetvalue('zero');
   this.otherincomeSetvalue('zero');
   this.incomeLossSetValue('zero');
    let deductionDonate_80g:any =  [];
    deductionDonate_80g = [ ...this.investmentDeclarationForm.getRawValue().deductions.donate_80g100, ...this.investmentDeclarationForm.getRawValue().deductions.donate_80g50];
    this.removeAllDonate_80gs();

    if(deductionDonate_80g.length > 0){
       for(let k=0; k<deductionDonate_80g.length; k++){
         this.addDonate_80g();
       }
       (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g")).reset(deductionDonate_80g);
    }

    if(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length;k++){
        let final_from_Date3 = (this.investmentDeclarationForm.getRawValue().deductions.donate_80g[k]['payment_date'] != null) ? (this.appservice.dateFormatConvert(this.investmentDeclarationForm.getRawValue().deductions.donate_80g[k]['payment_date'])) : null;
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g")).at(k).get('payment_date')?.setValue(final_from_Date3);
      }
    }

 }

  submitOne(){
    this.active = 2;
    this.tab2touched = true;
  }

  submitTwo(event: any){
    this.active = 3;
    this.tab3touched = true;
  }

  submitThree(){
    this.active = 4;
    this.tab4touched = true;
  }

  submitFour(){
    this.active = 5;
    this.tab5touched = true;
  }

  submitForm(){
    this.taxRegimeCalculationFunction();
  }

  taxRegimeCalculationFunction(){

    this.investmentDeclarationFormDataModificationFunction();

    let save_data = {'proposed_confirm':true, 'employee':this.authService.isEmployeeId, 'hra80gg': this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg, 'chapter4a80c': this.investmentDeclarationForm.getRawValue().chapt2,'chapter4adeduction': this.investmentDeclarationForm.getRawValue().deductions,'incomeloss_property': this.investmentDeclarationForm.getRawValue().incomeloss,'income_from_othersource': this.investmentDeclarationForm.getRawValue().otherincome}
    if(this.from=='behalf'){
      this.activityService.taxRegimeCalculation(this.empId, {"data":save_data}).subscribe((res:any)=>{
       this.taxRegime(res)
      });
    }
    else{

    this.investmentService.taxRegimeCalculation(this.empId, {"data":save_data},'').subscribe((res:any)=>{
        this.taxRegime(res)

      });
    }
  }
  taxRegime(res:any){
    this.taxRegimeData = res.data;
    this.active        = 6;
    this.tab6touched   = true;
  }
  finalSubmit(data:any){

    this.hr80ggSetvalue();
    this.chapt2Setvalue('zero');
    this.deductionsSetvalue('zero');
    this.otherincomeSetvalue('zero');
    this.incomeLossSetValue('zero');

    this.investmentDeclarationFormDataModificationFunction();

    if(this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga.length;k++){
        let final_from_Date2 = (this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga[k]['date'] != null) ? (this.appservice.dateFormatConvert(this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga[k]['date'])) : null;
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("deduction_80gga")).at(k).get('date')?.setValue(final_from_Date2);
      }
    }
    let saveData:any ={'proposed_confirm':true, 'employee':null, 'hra80gg': this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg, 'chapter4a80c': this.investmentDeclarationForm.getRawValue().chapt2,'chapter4adeduction': this.investmentDeclarationForm.getRawValue().deductions,'incomeloss_property': this.investmentDeclarationForm.getRawValue().incomeloss,'income_from_othersource': this.investmentDeclarationForm.getRawValue().otherincome,'tax_regime': data.tax_regime,'declared_datail': data.declared_datail}
    if(this.from=='behalf'){
      saveData['employee_id'] = this.empId
      this.activityService.investmentDeclaration(saveData).subscribe((res:any)=>{
        this.router.navigate(['manage-activities']);
      });
    }
    else{

    this.investmentService.createInvestment({'proposed_confirm':true, 'employee':null, 'hra80gg': this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg, 'chapter4a80c': this.investmentDeclarationForm.getRawValue().chapt2,'chapter4adeduction': this.investmentDeclarationForm.getRawValue().deductions,'incomeloss_property': this.investmentDeclarationForm.getRawValue().incomeloss,'income_from_othersource': this.investmentDeclarationForm.getRawValue().otherincome,'tax_regime': data.tax_regime,'declared_datail': data.declared_datail},'').subscribe((res:any)=>{
        this.router.navigate(['request']);
      });
    }
  }

  public hra80ggValidation(data: any):void {
    if(data == true){

    }else{

      }

  }

  public chap2Validation(data: any):void {

    if( this.investmentDeclarationForm['controls'].chapt2.get('contribution_15y_ppf')?.value==true){
      this.shortChapt2Validation('ppf_amount','ppf_document',0);
    }else{
      this.shortChapt2Validation('ppf_amount','ppf_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('five_year_POTD')?.value==true){
      this.shortChapt2Validation('POTD_amount','POTD_document',0);
    }else{
      this.shortChapt2Validation('POTD_amount','POTD_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('subscribe_national_saving')?.value==true){
      this.shortChapt2Validation('nsc_amount','nsc_document',0);
    }else{
      this.shortChapt2Validation('nsc_amount','nsc_document',1);
    }
    if(this.investmentDeclarationForm['controls'].chapt2.get('deduction_contribute_NPS')?.value==true){
      this.shortChapt2Validation('nps_amount','nps_document',0);
    }else{
      this.shortChapt2Validation('nps_amount','nps_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('equity_linked_saving')?.value==true){
      this.shortChapt2Validation('ELSS_amount','ELSS_document',0);
    }else{
      this.shortChapt2Validation('ELSS_amount','ELSS_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('ULIP')?.value==true){
      this.shortChapt2Validation('ULIP_amount','ULIP_document',0);
    }else{
      this.shortChapt2Validation('ULIP_amount','ULIP_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('interest_NSC')?.value==true){
      this.shortChapt2Validation('interest_NSC_amount','interest_nsc_document',0);
    }else{
      this.shortChapt2Validation('interest_NSC_amount','interest_nsc_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('child_education_tuition_fees')?.value==true){
      this.shortChapt2Validation('tuition_fees','tuition_fees_document',0);
    }else{
      this.shortChapt2Validation('tuition_fees','tuition_fees_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('repay_house_principal')?.value==true){
      this.shortChapt2Validation('repay_house_amount','repay_house_document',0);
    }else{
      this.shortChapt2Validation('repay_house_amount','repay_house_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('stampduty_purchase_house')?.value==true){
      this.shortChapt2Validation('stampduty_amount','stampduty_document',0);
    }else{
      this.shortChapt2Validation('stampduty_amount','stampduty_document',1);
    }if(this.investmentDeclarationForm['controls'].chapt2.get('saukanya_samridhi_yojana')?.value==true){
      this.shortChapt2Validation('ssy_amount','ssy_document',0);
    }else{
      this.shortChapt2Validation('ssy_amount','ssy_document',1);
    }

    if(this.investmentDeclarationForm['controls'].chapt2.get('nabard_rural_bond')?.value==true){
      this.shortChapt2Validation('nabard_rural_bond_amount','nabard_rural_bond_document',0);
    }else{
      this.shortChapt2Validation('nabard_rural_bond_amount','nabard_rural_bond_document',1);
    }
    if(this.investmentDeclarationForm['controls'].chapt2.get('lic')?.value==true){
      this.shortChapt2Validation('lic_amount','lic_document',0);
    }else{
      this.shortChapt2Validation('lic_amount','lic_document',1);
    }
    if(this.investmentDeclarationForm['controls'].chapt2.get('annuity')?.value==true){
      this.shortChapt2Validation('annuity_amount','annuity_document',0);
    }else{
      this.shortChapt2Validation('annuity_amount','annuity_document',1);
    }

  }

  shortChapt2Validation(amount: any, doc: any, stat: any){
    if(stat == 0){

      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].chapt2.get(doc)?.setValidators([Validators.required]);
      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].chapt2.get(doc)?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.clearValidators();
      this.investmentDeclarationForm['controls'].chapt2.get(doc)?.clearValidators();
      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].chapt2.get(doc)?.updateValueAndValidity();
    }
  }

  public deductionValidation(data: any):void {

      if(this.investmentDeclarationForm['controls'].deductions.get('employee_additional_contribution')?.value==true){
        this.shortDeductionsValidation('employee_contribution_amount','employee_contribution_document',0);
      }else{
        this.shortDeductionsValidation('employee_contribution_amount','employee_contribution_document',1);
      }if(this.investmentDeclarationForm['controls'].deductions.get('employer_contribution')?.value==true){

      }else{

      }

      if(this.investmentDeclarationForm['controls'].deductions.get('interest_education_loan')?.value==true){
        this.shortDeductionsValidation('interest_education_amount','interest_education_document',0);
      }else if(this.investmentDeclarationForm['controls'].deductions.get('interest_education_loan')?.value==false){
        this.shortDeductionsValidation('interest_education_amount','interest_education_document',1);
      }if(this.investmentDeclarationForm['controls'].deductions.get('interest_home_loan_80ee')?.value==true){
        this.shortDeductionsValidation('interest_home_loan_amount80ee','interest_home_loan_document80ee',0);
      }else{
        this.shortDeductionsValidation('interest_home_loan_amount80ee','interest_home_loan_document80ee',1);
      }if(this.investmentDeclarationForm['controls'].deductions.get('medical_treatment_handicapped')?.value==true){

        if(this.investmentDeclarationForm.getRawValue().deductions['disabled_severely'] == false){
          this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        }else{
          this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        }
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_document')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_document')?.updateValueAndValidity();
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_document')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_document')?.updateValueAndValidity();
      }if(this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure')?.value==true){

        if(this.investmentDeclarationForm.getRawValue().deductions['year_60_more'] == false){
          this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        }else{
          this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        }
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_document')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('issue')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_document')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('issue')?.updateValueAndValidity();
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_document')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('issue')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_document')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('issue')?.updateValueAndValidity();
      }if(this.investmentDeclarationForm['controls'].deductions.get('self_suffering_disability')?.value==true){

        if(this.investmentDeclarationForm.getRawValue().deductions['self_disabled_severely'] == false){
          this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        }else{
          this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        }
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_document')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_document')?.updateValueAndValidity();
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_document')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_document')?.updateValueAndValidity();
      }if(this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100')?.value==true){
         this.investmentDeclarationForm['controls'].deductions.get('transaction_document100')?.setValidators([Validators.required]);
        if(this.investmentDeclarationForm.getRawValue().deductions['cash_other100'] == false){
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }
        this.investmentDeclarationForm['controls'].deductions.get('transaction_document100')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.updateValueAndValidity();
      }else{
         this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.clearValidators();
         this.investmentDeclarationForm['controls'].deductions.get('transaction_document100')?.clearValidators();
         this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.updateValueAndValidity();
         this.investmentDeclarationForm['controls'].deductions.get('transaction_document100')?.updateValueAndValidity();
      }if(this.investmentDeclarationForm['controls'].deductions.get('royalty_patent')?.value==true){
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_document')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_document')?.updateValueAndValidity();
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_document')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_document')?.updateValueAndValidity();
      }if(this.investmentDeclarationForm['controls'].deductions.get('contribution_individual_policalparties')?.value==true){
        this.shortDeductionsValidation('contribution_policalparties_amount','contribution_policalparties_document',0);
      }else{
        this.shortDeductionsValidation('contribution_policalparties_amount','contribution_policalparties_document',1);
      }if(this.investmentDeclarationForm['controls'].deductions.get('gga80')?.value==true){

        if(this.investmentDeclarationForm.getRawValue().deductions['cash_other_80gga'] == false){
          this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
          this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.setValidators([Validators.required]);
          this.investmentDeclarationForm['controls'].deductions.get('date')?.setValidators([Validators.required]);
        }else{
          this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
          this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.clearValidators();
          this.investmentDeclarationForm['controls'].deductions.get('date')?.clearValidators();
        }
        this.investmentDeclarationForm['controls'].deductions.get('transaction_80gga_document')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('transaction_80gga_document')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('date')?.updateValueAndValidity();
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('transaction_80gga_document')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('date')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('transaction_80gga_document')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('date')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.updateValueAndValidity();
      }


      if(this.investmentDeclarationForm['controls'].deductions.get('health_insurance_selfspouse80d')?.value==true){
        this.shortDeductionsValidation('health_insurance_selfspouse80d_amount','health_insurance_selfspouse80d_document',0);
      }else{
        this.shortDeductionsValidation('health_insurance_selfspouse80d_amount','health_insurance_selfspouse80d_document',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('health_insurance_parents80d')?.value==true){
        this.shortDeductionsValidation('health_insurance_parents80d_amount','health_insurance_parents80d_document',0);
      }else{
        this.shortDeductionsValidation('health_insurance_parents80d_amount','health_insurance_parents80d_document',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('health_checkup_selfspouse80d')?.value==true){
        this.shortDeductionsValidation('health_checkup_selfspouse80d_amount','health_checkup_selfspouse80d_document',0);
      }else{
        this.shortDeductionsValidation('health_checkup_selfspouse80d_amount','health_checkup_selfspouse80d_document',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('health_checkup_parents80d')?.value==true){
        this.shortDeductionsValidation('health_checkup_parents80d_amount','health_checkup_parents80d_document',0);
      }else{
        this.shortDeductionsValidation('health_checkup_parents80d_amount','health_checkup_parents80d_document',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('medicalbill_senior80d')?.value==true){
        this.shortDeductionsValidation('medicalbill_senior80d_amount','medicalbill_senior80d_document',0);
      }else{
        this.shortDeductionsValidation('medicalbill_senior80d_amount','medicalbill_senior80d_document',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('interest_home_loan_80eea')?.value==true){
        this.shortDeductionsValidation('interest_home_loan_amount80eea','interest_home_loan_document80eea',0);
      }else{
        this.shortDeductionsValidation('interest_home_loan_amount80eea','interest_home_loan_document80eea',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('interest_electrical_vehicle_loan_80eeb')?.value==true){
        this.shortDeductionsValidation('interest_electrical_vehicle_loan_amount','interest_electrical_vehicle_loan_document',0);
      }else{
        this.shortDeductionsValidation('interest_electrical_vehicle_loan_amount','interest_electrical_vehicle_loan_document',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('interest_home_loan_80ee')?.value==true){
        this.shortDeductionsValidation('interest_home_loan_amount80ee','interest_home_loan_document80ee',0);
      }else{
        this.shortDeductionsValidation('interest_home_loan_amount80ee','interest_home_loan_document80ee',1);
      }
      if(this.investmentDeclarationForm['controls'].deductions.get('tta_ttb')?.value==true){
        this.shortDeductionsValidation('tta_ttb_amount','tta_tta_document',0);
      }else{
        this.shortDeductionsValidation('tta_ttb_amount','tta_tta_document',1);
      }

      if(this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause_50')?.value==true){
        this.investmentDeclarationForm['controls'].deductions.get('transaction_document50')?.setValidators([Validators.required]);
       if(this.investmentDeclarationForm.getRawValue().deductions['cash_other50'] == false){
       this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
     }else{
       this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
     }
       this.investmentDeclarationForm['controls'].deductions.get('transaction_document50')?.updateValueAndValidity();
       this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.updateValueAndValidity();
     }else{
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('transaction_document50')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.updateValueAndValidity();
        this.investmentDeclarationForm['controls'].deductions.get('transaction_document50')?.updateValueAndValidity();
     }
  }

  shortDeductionsValidation(amount: any, doc: any, stat: any){
    if(stat == 0){
      // if(amount == 'interest_home_loan_amount')
      //   this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValidators([Validators.required,digitdecimalValidator(),Validators.max(50000)]);
      // else{

        this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      // }
      this.investmentDeclarationForm['controls'].deductions.get(doc)?.setValidators([Validators.required]);
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get(doc)?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get(doc)?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get(doc)?.updateValueAndValidity();
    }
  }

  incomeLossValidation(){
    if(this.investmentDeclarationForm['controls'].incomeloss.get('incomefrom_self_occupiedproperty')?.value){
      this.investmentDeclarationForm['controls'].incomeloss.get('intreston_houseloan_document')?.setValidators([Validators.required]);
    }else{
      this.investmentDeclarationForm['controls'].incomeloss.get('intreston_houseloan_document')?.clearAsyncValidators();
    }
    this.investmentDeclarationForm['controls'].incomeloss.get('intreston_houseloan_document')?.updateValueAndValidity();
  }

  public otherincome(data: any):void {

    if( this.investmentDeclarationForm['controls'].otherincome.get('incomefrom_othersource')?.value==true){
      this.shortOtherIncomeValidation('othersource_amount','othersource_document',0);
    }else{
      this.shortOtherIncomeValidation('othersource_amount','othersource_document',1);
    }if( this.investmentDeclarationForm['controls'].otherincome.get('interest_earned_sbaccount')?.value==true){
      this.shortOtherIncomeValidation('sb_account_amount','sb_account_document',0);
    }else{
      this.shortOtherIncomeValidation('sb_account_amount','sb_account_document',1);
    }if( this.investmentDeclarationForm['controls'].otherincome.get('source_80TTA_80TTB')?.value==true){
      this.shortOtherIncomeValidation('source_80TTA_80TTB_amount','source_80TTA_80TTB_document',0);
    }else{
      this.shortOtherIncomeValidation('source_80TTA_80TTB_amount','source_80TTA_80TTB_document',1);
    }

    if( this.investmentDeclarationForm['controls'].otherincome.get('intrest_earned')?.value==true){
      this.shortOtherIncomeValidation('intrest_earned_amount','intrest_earned_document',0);
    }else{
      this.shortOtherIncomeValidation('intrest_earned_amount','intrest_earned_document',1);
    }
  }

  shortOtherIncomeValidation(amount: any, doc: any, stat: any){
    if(stat == 0){

      this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].otherincome.get(doc)?.setValidators([Validators.required]);
      this.investmentDeclarationForm['controls'].otherincome.get(amount)?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].otherincome.get(doc)?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].otherincome.get(amount)?.clearValidators();
      this.investmentDeclarationForm['controls'].otherincome.get(doc)?.clearValidators();
      this.investmentDeclarationForm['controls'].otherincome.get(amount)?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].otherincome.get(doc)?.updateValueAndValidity();
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.investmentDeclarationForm['controls'].hra80gg.invalid) {
        changeEvent.preventDefault();
      }else{
        this.chapt2Setvalue('null');
        this.tab2touched = true;
      }
    }
    else  if (changeEvent.nextId === 3) {
      if (this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab2touched == false) {
        changeEvent.preventDefault();
      }else{
        this.deductionsSetvalue('null');
        this.tab3touched = true;
      }
    }else  if (changeEvent.nextId === 4) {
      if (this.investmentDeclarationForm['controls'].deductions.invalid || this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab3touched == false) {
        changeEvent.preventDefault();
      }else{
        this.incomeLossSetValue('null');
        this.tab4touched = true;
      }
    }else  if (changeEvent.nextId === 5) {
      if (this.investmentDeclarationForm['controls'].incomeloss.invalid || this.investmentDeclarationForm['controls'].deductions.invalid || this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab4touched == false) {
        changeEvent.preventDefault();
      }else{
        this.otherincomeSetvalue('null');
        this.tab5touched = true;
      }
    }else  if (changeEvent.nextId === 6) {
      if (this.investmentDeclarationForm['controls'].otherincome.invalid || this.investmentDeclarationForm['controls'].incomeloss.invalid || this.investmentDeclarationForm['controls'].deductions.invalid || this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab5touched == false) {
        changeEvent.preventDefault();
      }else{
        this.taxRegimeCalculationFunction();
      }
    }
  }

  chapt2Setvalue(type:any){
    this.shortChapt2SetValue('employee_contribute_pf','employee_contribute_pf_amount',type);
    this.shortChapt2SetValue('contribution_vpf','contribution_vpf_amount',type);
    this.shortChapt2SetValue('previous_employer_pf','previous_employer_pf_amount',type);

    this.shortChapt2SetValue('contribution_15y_ppf','ppf_amount',type);
    this.shortChapt2SetValue('five_year_POTD','POTD_amount',type);
    this.shortChapt2SetValue('subscribe_national_saving','nsc_amount',type);
    this.shortChapt2SetValue('deduction_contribute_NPS','nps_amount',type);
    this.shortChapt2SetValue('equity_linked_saving','ELSS_amount',type);
    this.shortChapt2SetValue('ULIP','ULIP_amount',type);
    this.shortChapt2SetValue('interest_NSC','interest_NSC_amount',type);
    this.shortChapt2SetValue('child_education_tuition_fees','tuition_fees',type);
    this.shortChapt2SetValue('repay_house_principal','repay_house_amount',type);
    this.shortChapt2SetValue('stampduty_purchase_house','stampduty_amount',type);
    this.shortChapt2SetValue('saukanya_samridhi_yojana','ssy_amount',type);

    this.shortChapt2SetValue('nabard_rural_bond','nabard_rural_bond_amount',type);
    this.shortChapt2SetValue('lic','lic_amount',type);
    this.shortChapt2SetValue('annuity','annuity_amount',type);

  }

  shortChapt2SetValue(check : any, amount : any, type: any){
    if(this.investmentDeclarationForm['controls'].chapt2.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.value;
      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].chapt2.get(amount)?.value))
    }
  }

  hr80ggSetvalue(){
    if(this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg.length;k++){
        let data = (this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg[k]['amount'] != null) ? (Number(this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg[k]['amount'])) : 0;
        (<FormArray>this.investmentDeclarationForm.get("hra80gg")?.get("hra80gg")).at(k).get('amount')?.setValue(data);
      }
    }
  }

  deductionsSetvalue(type:any){
    this.shortDeductionsSetValue('employer_contribution','employer_contribution_amount',type);

    this.shortDeductionsSetValue('self_paid_80D','self_paid_80D_amount',type);
    this.shortDeductionsSetValue('employee_additional_contribution','employee_contribution_amount',type);
    this.shortDeductionsSetValue('employer_contribution','employer_contribution_amount',type);
    this.shortDeductionsSetValue('tta_ttb','tta_ttb_amount',type);
    this.shortDeductionsSetValue('interest_education_loan','interest_education_amount',type);
    this.shortDeductionsSetValue('medical_treatment_handicapped','handicapped_dependent_amount',type);
    this.shortDeductionsSetValue('medical_expenditure','medical_expenditure_amount',type);
    this.shortDeductionsSetValue('stampduty_purchase_house','stampduty_amount',type);
    this.shortDeductionsSetValue('self_suffering_disability','self_suffering_amount',type);
    this.shortDeductionsSetValue('royalty_patent','royalty_patent_amount',type);
    this.shortDeductionsSetValue('contribution_individual_policalparties','contribution_policalparties_amount',type);
    this.shortDeductionsSetValue('gga80','total_amount',type);

    this.shortDeductionsSetValue('interest_home_loan_80ee','interest_home_loan_amount80ee',type);

    this.shortDeductionsSetValue('health_insurance_selfspouse80d','health_insurance_selfspouse80d_amount',type);
    this.shortDeductionsSetValue('health_insurance_parents80d','health_insurance_parents80d_amount',type);
    this.shortDeductionsSetValue('health_checkup_selfspouse80d','health_checkup_selfspouse80d_amount',type);
    this.shortDeductionsSetValue('health_checkup_parents80d','health_checkup_parents80d_amount',type);
    this.shortDeductionsSetValue('medicalbill_senior80d','medicalbill_senior80d_amount',type);
    this.shortDeductionsSetValue('interest_home_loan_80eea','interest_home_loan_amount80eea',type);
    this.shortDeductionsSetValue('interest_electrical_vehicle_loan_80eeb','interest_electrical_vehicle_loan_amount',type);
    this.shortDeductionsSetValue('donate_social_cause_50','donate_social_cause50_amount',type);
    this.shortDeductionsSetValue('donate_social_cause100','donate_social_cause100_amount',type);


  }

  shortDeductionsSetValue(check : any, amount : any, type:any){
    if(this.investmentDeclarationForm['controls'].deductions.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].deductions.get(amount)?.value));
    }
  }

  otherincomeSetvalue(type:any){

    this.shortOtherIncomeSetValue('incomefrom_othersource','othersource_amount',type);
    this.shortOtherIncomeSetValue('interest_earned_sbaccount','sb_account_amount',type);
    this.shortOtherIncomeSetValue('source_80TTA_80TTB','source_80TTA_80TTB_amount',type);

    this.shortOtherIncomeSetValue('intrest_earned','intrest_earned_amount',type);
  }

  shortOtherIncomeSetValue(check : any, amount : any,type:any){
    if(this.investmentDeclarationForm['controls'].otherincome.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].otherincome.get(amount)?.value));
    }
  }

  incomeLossSetValue(type:any){

    this.shortIncomeLossSetValue('incomefrom_self_occupiedproperty','intreston_houseloan_amount',type);

    if(this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender.length;k++){
        let data = (this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'] != null) ? ((this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'])) : (this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'] != "") ? ((this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'])) : 0;

        (<FormArray>this.investmentDeclarationForm.get("incomeloss")?.get("loss_lender")).at(k).get('intrestpaid_houseloan_amount')?.setValue(Number(data));
      }
    }
  }

  shortIncomeLossSetValue(check : any, amount : any, type:any){
    if(this.investmentDeclarationForm['controls'].incomeloss.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.value));
    }
  }

  get hra80ggForm() {
    return this.investmentDeclarationForm.get('hra80gg') as FormGroup;
  }
  get chapt2Form(){
    return this.investmentDeclarationForm.get('chapt2') as FormGroup;
  }
  get deductionsForm(){
    return this.investmentDeclarationForm.get('deductions') as FormGroup;
  }
  get incomeLossForm(){
    return this.investmentDeclarationForm.get('incomeloss') as FormGroup;
  }
  get otherIncomeForm(){
    return this.investmentDeclarationForm.get('otherincome') as FormGroup;
  }

  lenders() : FormArray {
    return this.otherIncomeForm.get("lender") as FormArray
  }

  newLender(): FormGroup {
    return this.formBuilder.group({
      "name": [null,[digitdecimalValidator(),Validators.maxLength(100),Validators.maxLength(15)]],
			"pan": [null,[PanfieldValidator()]],
			"property_address": [null,[Validators.maxLength(500)]],
			"possession_date": null,
			"owner_name": [null,[Validators.maxLength(100)]]
    })
  }

  addLenders() {
    this.lenders().push(this.newLender());
  }

  donate_80gs() : FormArray {
    return this.deductionsForm.get("donate_80g") as FormArray
  }

  newDonate_80g(): FormGroup {
    return this.formBuilder.group({
      "exemption"                 : null,
      "name"                      : [null],
			"pan"                       : [null],
			"address"                   : [null],
      "payment_mode"              : null,
			"check_no_transaction_id"   : null,
      "payment_date"              : null
    })
  }

  addDonate_80g() {
    this.donate_80gs().push(this.newDonate_80g());
  }

  removeAllDonate_80gs(){
    if(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length > 0){
      while(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length !== 0){
        this.donate_80gs().removeAt(0);
      }
    }
  }

  deduction_80ggas() : FormArray {
    return this.deductionsForm.get("deduction_80gga") as FormArray
  }

  newDeduction_80gga(): FormGroup {
    return this.formBuilder.group({
      "check_no_transaction_id": [null],
			"date": null,
    })
  }

  addDeduction_80gga() {
    this.deduction_80ggas().push(this.newDeduction_80gga());
  }

  salaryStructureConfig(){
    if(this.from=='behalf')
    {
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
      this.setSalaryStructureConfig(res)

      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
        this.setSalaryStructureConfig(res)

      });
    }
  }
  setSalaryStructureConfig(res:any){
    this.investmentDetails  = res;
      if(res.count_info.hasOwnProperty('max_count_limit')){
        this.investmentCnt= res.count_info.max_count_limit;
      }if(res.count_info.hasOwnProperty('assigned_salary_data')){
        if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){
          this.taxRegima= res.count_info.assigned_salary_data.tax_regime;

          let nps_amount_total = Number(res.count_info.assigned_salary_data.nps_amount + res.count_info.assigned_salary_data.prev_employer_nps_deduction)
          if(nps_amount_total>0){
            // condition added for previous employer nps scenario
            this.investmentDeclarationForm['controls'].deductions.get('employer_contribution')?.setValue(true);
          }else{
            this.investmentDeclarationForm['controls'].deductions.get('employer_contribution')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
          }
          this.investmentDeclarationForm['controls'].deductions.get('employer_contribution_amount')?.setValue(nps_amount_total);

          this.investmentDeclarationForm['controls'].chapt2.get('employee_contribute_pf')?.setValue(res.count_info.assigned_salary_data.provident_fund);
          this.investmentDeclarationForm['controls'].chapt2.get('employee_contribute_pf_amount')?.setValue(res.count_info.assigned_salary_data.pf_amount);

          this.investmentDeclarationForm['controls'].chapt2.get('contribution_vpf')?.setValue(res.count_info.assigned_salary_data.volentary_provident_fund);
          this.investmentDeclarationForm['controls'].chapt2.get('contribution_vpf_amount')?.setValue(res.count_info.assigned_salary_data.amount);

          this.investmentDeclarationForm['controls'].chapt2.get('previous_employer_pf')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
          this.investmentDeclarationForm['controls'].chapt2.get('previous_employer_pf_amount')?.setValue(res.count_info.assigned_salary_data.prev_emplyer_pf);

        }else{
          this.taxRegima= res.count_info.assigned_salary_data.AssignSalaryStructure;
        }
      }
      if(res.count_info.hasOwnProperty('request_count_info')){
        if(res.count_info.request_count_info.hasOwnProperty('Proposed_Investment_Declaration')){
          this.proposed_cnt= res.count_info.request_count_info.Proposed_Investment_Declaration;
        }if(res.count_info.request_count_info.hasOwnProperty('Confirmed_Investment_Declaration')){
          this.confirmed_cnt= res.count_info.request_count_info.Confirmed_Investment_Declaration;
        }
      }
      if(res.count_info.hasOwnProperty('employee_data')){
        this.employeeAge = res.count_info.employee_data.age;
      }

      this.hraValue = res.count_info.assigned_salary_data.HRA;
  }
  /********* TOTAL FORM AMOUNT VALIDATION FUNCTION ****************/
  validDeduction() {
    let total_amount      = 0;
    total_amount = Number(this.investmentDeclarationForm.getRawValue().chapt2['ppf_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['POTD_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['nsc_amount']) + Number( this.investmentDeclarationForm.getRawValue().chapt2['nps_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['ELSS_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['ULIP_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['interest_NSC_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['tuition_fees']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['repay_house_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['stampduty_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['ssy_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['employee_contribute_pf_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['contribution_vpf_amount']) + Number(this.investmentDeclarationForm.getRawValue().chapt2['previous_employer_pf_amount']);
    if(total_amount > 150000){
      this.tot_amount       = true;
      this.scrollToTop();
    }
    else{
      this.tot_amount       = false;
    }
  }

  scrollToTop() {
    document.getElementsByClassName('config-layout')[0].scrollTo(0, 0);
  }

  // NEW
  lenderIncome() : FormArray {
    return this.incomeLossForm.get("incomeself_lender") as FormArray
  }

  newLenderIncome(): FormGroup {
    return this.formBuilder.group({
      "lender_name" : [null],
			"lender_pan"  : [null],
    })
  }

  addLenderIncome() {
    this.lenderIncome().push(this.newLenderIncome());
  }


  lenderLoss() : FormArray {
    return this.incomeLossForm.get("loss_lender") as FormArray
  }

  newLenderLoss(): FormGroup {
    console.log("fvdgdf", this.investmentDeclarationForm.get("incomeloss")?.get("incomefrom_letoutproperty")?.value);
    if(this.investmentDeclarationForm.get("incomeloss")?.get("incomefrom_letoutproperty")?.value == true){
      return this.formBuilder.group({
        "annual_rent_received"         : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "muncipal_taxpaid"             : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "lender_name"                  : [null,[Validators.maxLength(100),NamefieldValidator()]],
        "lender_pan"                   : [null,[PanfieldValidator()]],
        "net_income_houseproperty_document" : [null,[Validators.required]],
        "net_income_houseproperty"     : [null]
      })
    }else{
      return this.formBuilder.group({
        "annual_rent_received"         : [null],
        "muncipal_taxpaid"             : [null],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null],
        "lender_name"                  : [null],
        "lender_pan"                   : [null],
        "net_income_houseproperty_document" : [null],
        "net_income_houseproperty"     : [null]
      })
    }
  }

  addLenderLoss() {
    this.lenderLoss().push(this.newLenderLoss());
  }

  hra80ggs() : FormArray {
    return this.hra80ggForm.get("hra80gg") as FormArray
  }

  newHra80gg(): FormGroup {
    if(this.hraValue == 1){
      return this.formBuilder.group({
        "select_one"        : this.hraValue == 1 ? true : false,
        "from_month"        : [,[Validators.required]],
        "to_month"          : [,[Validators.required]],
        "amount"            : [,[Validators.required, digitdecimalValidator(),Validators.maxLength(15)]],
        "landlord_name"     : [,[Validators.required,Validators.maxLength(100)]],
        "landlord_pan"      : [,[PanfieldValidator()]],
        "landlord_address"  : [,[Validators.required,Validators.maxLength(500)]],
        "landlord_document" : ['',[Validators.required]],
        "city"              : [,[Validators.required]],
        "from_year"        : [,[Validators.required]],
        "to_year"          : [,[Validators.required]],
      })
    }
    if(this.hraValue == 0){
      return this.formBuilder.group({
        "select_one"        : this.hraValue == 1 ? true : false,
        "from_month"        : [null],
        "to_month"          : [null],
        "amount"            : [,[Validators.required, digitdecimalValidator(),Validators.maxLength(15)]],
        "landlord_name"     : [null],
        "landlord_pan"      : [null],
        "landlord_address"  : [null],
        "landlord_document" : ['',[Validators.required]],
        "city"              : [null],
        "from_year"        : [null],
        "to_year"          : [null],
      })
    }
    else{
      return this.formBuilder.group({
        "select_one"        : this.hraValue == 1 ? true : false,
        "from_month"        : null,
        "to_month"          : null,
        "amount"            : [''],
        "landlord_name"     : [null],
        "landlord_pan"      : [null],
        "landlord_address"  : [null],
        "landlord_document" : [''],
        "city"              : null,
        "from_year"        : [null],
        "to_year"          : [null],
      })
    }
  }

  addHra80gg() {
    this.hra80ggs().push(this.newHra80gg());
  }

  donate_80gs100() : FormArray {
    return this.deductionsForm.get("donate_80g100") as FormArray
  }

  newDonate_80gs100(): FormGroup {
     return this.formBuilder.group({
      "exemption" : null,
      "name": [null],
			"pan": [null],
			"address": [null],
      "payment_mode": null,
			"check_no_transaction_id": null,
      "payment_date": null
    })
  }

  addDonate_80g100() {
    this.donate_80gs100().push(this.newDonate_80gs100());
  }

  donate_80gs50() : FormArray {
    return this.deductionsForm.get("donate_80g50") as FormArray
  }

  newDonate_80gs50(): FormGroup {
     return this.formBuilder.group({
      "exemption" : null,
      "name": [null],
			"pan": [null],
			"address": [null],
      "payment_mode": null,
			"check_no_transaction_id": null,
      "payment_date": null
    })
  }
  addDonate_80g50() {
    this.donate_80gs50().push(this.newDonate_80gs50());
  }
  // END

}
