import { Component, OnInit, EventEmitter, Input, Inject, Output,Renderer2  } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MessageService } from 'src/app/message.global';
import { WorkfromhomeService } from 'src/app/@core/services/workfromhome.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-work-from-home-request',
  templateUrl: './work-from-home-request.component.html',
  styleUrls: ['./work-from-home-request.component.scss']
})
export class WorkFromHomeRequestComponent implements OnInit {
  @Input() employee:any;
  id                : any
  from_day          : any
  to_day            : any
  submitted         : boolean = false
  loader            : boolean = false
  maxValue          : any
  wfhdays           : any = [];
  statusBtn         = 'Full Day' ;
  holidaycount      = 0;
  weekoffcount      = 0;
  holidayArray:any  = [];
  weekoffArray:any  = [];
  totday            :any = 0;
  alertToggle       = false;
  alertMsg:any      = '';
  disablenext       = false;
  temparr           : any = [];
  displayclass      = 'd-none';
  loadertbl         = false;

  eligibilityDetails: any = [];
  warningMsgToggle        = false;
  warningMsg:any          = '';
  restrictkey :any;
  dateError               = false;
  dateErrorMsg            = '';

  @Input() disabled : any
  @Input() formGp   : any;
  @Input() wfhdayss   : any;
  @Input() temparrr   : any;
  @Input() from   : any;

  @Output() submitmethod  = new EventEmitter()
  @Output() submitmethod2 = new EventEmitter()
  @Output() isrestricted  = new EventEmitter();

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public fb:FormBuilder,
    public appservice:AppService,
    private authService:AuthService,
    private renderer: Renderer2,
    private WorkfromhomeService:WorkfromhomeService,
    public messageService : MessageService,
    private activityService:ManageActivityService
  ) { }

  ngOnInit(): void {
    if(this.wfhdayss.length != 0){
      this.wfhdays=this.wfhdayss;
      this.temparr=this.temparrr;
    }
    this.getDateFormat();


    if(this.formGp.get('from').value != undefined){
      this.displayclass='';
      let sum       = 0;
      let tempsum   = 0;
        for(let i=0;i<this.wfh_request().value.length;i++){
            if(this.wfh_request().value[i]['day_portion']=='Full Day'){
              tempsum = 1;
            }else if(this.wfh_request().value[i]['day_portion']=='First Half Day' || this.wfh_request().value[i]['day_portion']== 'Second Half Day'){
              tempsum = 0.5;
            }
            sum = sum + tempsum;
        }
        this.totday = sum;
    }
  }

  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  disableDate(){
    return false;
  }

  wfh_request() : FormArray {
    return this.formGp.get("wfh_request") as FormArray;
  }

  // From Date
  fromDate(value:any){
    this.dateError    = false;
    this.dateErrorMsg = '';
    this.formGp.get('to')?.setValue('')
   if(this.formGp.get('to').value != null && this.formGp.get('to').value != '' && this.formGp.get('to').value != 'Invalid date'){
    this.toDate();
   }
  }

  // To Date
  toDate(){
    let from = moment(this.formGp.get('from').value).format('YYYY-MM-DD');
    let to = moment(this.formGp.get('to').value).format('YYYY-MM-DD');
    if(this.formGp.get('from').value != null && this.formGp.get('from').value != '' && this.formGp.get('from').value != 'Invalid date'){
      this.geteligibleDays(from,to);
     }else{
      this.formGp.get('from').errors.required = true;
     }
  }

  //  Days API
  geteligibleDays(from:any,to:any){

    this.holidaycount = 0;
    this.weekoffcount = 0;
    this.holidayArray = [];
    this.weekoffArray = [];
    this.totday       = 0;
    this.dateError    = false;
    this.dateErrorMsg = '';
    this.wfh_request().clear();
    this.loadertbl    = true;
    this.disablenext  = false;
    this.isrestricted.emit(false);
    this.wfhdays      = [];

    // if(this.wfh_request().controls.length>0){
    //     // while(this.wfh_request().controls.length != 0){
    //     // this.wfh_request().removeAt(0);
    //     // }
    //     this.wfh_request().clear();
    // }
      if(this.from=='behalf'){
        this.activityService.getwfhDays(from,to,this.employee).subscribe((res:any)=>{
          let response:any = []
          response['body'] = res;
          this.setWFHData(response)
         
        },(error:any)=>{
         this.handleError(error)
        })
      }
      else{

        this.WorkfromhomeService.getwfhDays(from,to,this.employee).subscribe((res:any)=>{

          this.setWFHData(res)
        },(error:any)=>{
         this.handleError(error)
        })
      }
  }
  handleError(error:any){
    this.displayclass   = 'd-none';
    this.alertToggle    = true;
    this.alertMsg   = error.error.error;
    this.disablenext    = true;
    this.isrestricted.emit(true);
    this.loadertbl      = false;
    this.wfh_request().clear();
    this.removeValidation();
  }
  setWFHData(res:any){
    this.eligibilityDetails = res?.body?.data;
    this.restrictkey        = res?.body?.data?.request_restricted;

    if(res?.body?.data?.is_wfh_allowed == true && res?.body?.data?.attendance_capture == true){
      this.displayclass='';
      for(let i=0;i<res?.body?.data?.details?.length;i++){
        if(res?.body?.data?.details[i]?.shift_mapping_id != null){

            this.wfh_request()?.push(this.newwfh_request(res?.body?.data?.details[i]));
            this.wfhdays?.push(res?.body?.data?.details[i]);
            this.temparr = this.wfh_request()?.value;

          if(this.restrictkey == null){

            for(let h=0;h<this.wfh_request()?.value?.length;h++){
              this.temparr[h]['daycount'] = 1;
              }

          }else if((this.restrictkey == 'holiday' && res?.body?.data?.details[i]?.holiday == true) || (this.restrictkey == 'weekoff' && res?.body?.data?.details[i]?.weekoff_day == true) || (this.restrictkey == 'holiday_weekoff' && res?.body?.data?.details[i]?.holiday == true) || (res?.body?.data.details[i]?.weekoff_day == true && this.restrictkey == 'holiday_weekoff')){

              this.temparr[i]['daycount'] = 0;
              // this.holidaycount           = this.holidaycount + 1 ;

              if((this.restrictkey == 'holiday' && res?.body?.data?.details[i]?.holiday == true) || (this.restrictkey == 'holiday_weekoff' && res?.body?.data?.details[i]?.holiday == true)){
                this.holidaycount           = this.holidaycount + 1 ;
                this.holidayArray?.push(this.appservice.dateFormatDisplay(res?.body?.data?.details[i]?.date))
              }else if((this.restrictkey == 'weekoff' && res?.body?.data?.details[i]?.weekoff_day == true) || (res?.body?.data.details[i]?.weekoff_day == true && this.restrictkey == 'holiday_weekoff')){
                this.weekoffcount           = this.weekoffcount + 1 ;
                this.weekoffArray?.push(this.appservice.dateFormatDisplay(res?.body?.data?.details[i]?.date))
              }

              this.wfh_request().at(i).patchValue({
                'day_portion' : ''
              })
              this.wfhdays[i]['disablekey'] = true;
          }else{
              this.temparr[i]['daycount']   = 1;
          }
          this.submitmethod2.emit({'data1':this.wfhdays,'data2':this.temparr});

        }else if(res?.body?.data?.details[i]?.shift_mapping_id == null || res?.body?.data?.details[i]?.shift_mapping_id ==''){

          this.displayclass   = 'd-none';
          this.alertToggle    = true;
          this.disablenext    = true;
          this.isrestricted.emit(true);
          this.alertMsg = 'Work From Home request cannot be raised as a shift has not been assigned for the selected date.';
          if(this.wfh_request()?.controls?.length > 0){
            this.wfh_request().clear();
          }
        }
      }


      this.totday = res?.body?.data?.details?.length - this.holidaycount - this.weekoffcount;
      if( this.totday == 0){
          // Validation for week off and holiday
          if((this.holidaycount > 0) && (this.weekoffcount > 0)){
            this.dateError    = true;
            let arr           = [...this.holidayArray, ...this.weekoffArray];
            let mergedArr     = [...new Set(arr)]
            this.dateErrorMsg = 'Work From Home request cannot be raised for holidays/weekends ('+mergedArr?.join(', ')+').';
          }
          else if(this.holidaycount > 0){
            this.dateError    = true;
            this.dateErrorMsg = 'Work From Home request cannot be raised for holiday ('+this.holidayArray?.join(', ')+').';
          }else if(this.weekoffcount > 0){
            this.dateError    = true;
            this.dateErrorMsg = 'Work From Home requests cannot be raised for weekends ('+this.weekoffArray?.join(', ')+').';
          }
          //End

          this.disablenext    = true;
          this.isrestricted.emit(true);
      }
      // else if((this.totday + res?.body?.data?.total_wfh_request_days_count) > res?.body?.data?.max_wfh_request_days){

      //     this.displayclass   = 'd-none';
      //     this.disablenext    = true;
      //     this.isrestricted.emit(true);
      //     this.alertToggle    = true;
      //     this.alertMsg = 'WFH request limit has exceeded';
      //     if(this.wfh_request().controls?.length>0){

      //       this.wfh_request().clear();
      //     }
      // }
      // else if(res?.body?.data?.is_backdated_wfh_allowed == true && this.totday > res?.body?.data?.max_wfh_back_days){
      //     this.displayclass   = 'd-none';
      //     this.disablenext    = true;
      //     this.isrestricted.emit(true);
      //     this.alertToggle    = true;
      //     this.alertMsg = 'Backdated Work From Home request cannot be raised for days prior to '+res?.body?.data?.max_wfh_back_days +' days.';

      //     if(this.wfh_request().controls?.length > 0){
      //       this.wfh_request().clear();
      //     }
      // }
      else if(this.eligibilityDetails?.overtime_dates?.length > 0 ){
        this.showValidation('Overtime');
      }else if((this.eligibilityDetails?.overtime_dates?.length == 0) && this.eligibilityDetails?.compoff_dates?.length > 0){
        this.showValidation('Compoff');
      }else if((this.eligibilityDetails?.overtime_dates?.length == 0) && this.eligibilityDetails?.compoff_dates?.length == 0){
        this.removeValidation();
      }else{

        this.disablenext    =   false;
        this.isrestricted.emit(false);
      }
    }else{

      this.disablenext    = true;
      this.isrestricted.emit(true);
      this.alertToggle    = true;
      this.alertMsg       = 'You cannot raise Work From home request as per your organizational policy';
    }
   this.loadertbl =false;
  }
  daytime(active_status:any,shiftid:any,index:any){
    let sum = 0;

    this.wfh_request().controls[index].get('day_portion')?.setValue(active_status);
    for(let i=0;i<this.wfh_request().value.length;i++){
      if(this.wfh_request().value[i].shift_mapping == shiftid){
          if(active_status=='Full Day'){
            this.temparr[i]['daycount'] = 1;
          }else if(active_status=='First Half Day'){
            this.temparr[i]['daycount'] = 0.5;
          }else if(active_status=='Second Half Day'){
            this.temparr[i]['daycount'] = 0.5;
          }else{
          }
        }
        if(this.temparr[i]['day_portion']!=''){
          sum = sum + this.temparr[i]['daycount'];
        }
      }
    this.totday = sum;
    this.submitmethod2.emit({'data1':this.wfhdays,'data2':this.temparr});
  }

  get f() {return  this.formGp.controls; }

  newwfh_request(data:any): FormGroup {
    return this.fb.group({
      "wfh_date": data.date,
      "day_portion": 'Full Day',
      "shift_mapping": data.shift_mapping_id,
      "weekoff_status": data.weekoff_day?true:false,
      "holiday_status": data.holiday
    })
  }

  validateDetails(){
    if (this.formGp.invalid) {
      return;
    }

    this.submitted = true;
    this.submitmethod.emit({'data1':this.wfhdays,'data2':this.temparr});
  }
   // Modal close for Invalid Extension
   closed(bool:any){
    this.alertToggle = bool
  }

  // Overtime and Comp off related validation start
  showValidation(type: any){
    this.disablenext      = true;
    this.isrestricted.emit(true);
    this.warningMsgToggle = true;
    if(type == 'Overtime'){
      if(this.eligibilityDetails?.overtime_dates?.length > 0){
        for(let i=0;i<this.eligibilityDetails?.overtime_dates?.length;i++){
          this.eligibilityDetails.overtime_dates[i] = this.appservice.dateFormatDisplay(this.eligibilityDetails?.overtime_dates[i])
        }
        // this.warningMsg = "Overtime has already been processed for the selected dates" +' ('+this.eligibilityDetails?.overtime_dates?.join(', ')+')'+ ". You cannot raise the work from home request for these dates.";

        this.warningMsg = "Work from home request cannot be raised as OT hours and attendance has already been processed for this dates" +' ('+this.eligibilityDetails?.overtime_dates?.join(', ')+')'+ ".";

      }}else if(type == 'Compoff'){
        if(this.eligibilityDetails?.compoff_dates?.length > 0){
          for(let i=0;i<this.eligibilityDetails?.compoff_dates?.length;i++){
            this.eligibilityDetails.compoff_dates[i] = this.appservice.dateFormatDisplay(this.eligibilityDetails?.compoff_dates[i])
          }
          // this.warningMsg = "Compensatory off credited against the selected dates" +' ('+this.eligibilityDetails?.compoff_dates?.join(', ')+')'+ " has already been availed. You cannot raise work from home request for the selected dates.";

          this.warningMsg = "Work from home request cannot be raised, as comp-off for this dates" +' ('+this.eligibilityDetails?.compoff_dates?.join(', ')+')'+ " has already been availed."
        }

   }

  }

  removeValidation(){
    this.warningMsgToggle = false;
    this.warningMsg       = '';
  }

  dateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear() + 1, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // End
}
