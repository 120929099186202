import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { ActivatedRoute, Router,Params } from '@angular/router';

@Component({
  selector: 'app-loan-request-setup',
  templateUrl: './loan-request-setup.component.html',
  styleUrls: ['./loan-request-setup.component.scss']
})
export class LoanRequestSetupComponent implements OnInit {

  // Variables
  loanTypesDropdown  : any = [];
  activeTab                = 1 ;
  loanTypeDetails    : any = []
  currency           : any
  tab2touched        : boolean = false

  // Form
  loanRequestForm    !: FormGroup
  from:any
  employee:any
  empData:any
  requestFor:any = ''

  constructor(
    private loanServ : InvestmentDeclerationService,
    private fb       : FormBuilder,
    private router   : Router,
    private loanReServ     : LoanProcessService,
    private activityService:ManageActivityService,
    public route:ActivatedRoute,


  ) {
    this.loanRequestForm = this.fb.group({
      loanRequest    : this.fb.group({
        loan_type    : [null, [Validators.required]],
        loan_amount  : [null, [Validators.required, Validators.min(1)]],
        number_of_emi: [null, [Validators.required, Validators.min(1)]],
        required_date: [null, [Validators.required]],
        reason       : [null, [Validators.maxLength(100)]],
        document     : ['']
      })
    })
  }

  get loanDetailsForm() {
    return this.loanRequestForm.get('loanRequest') as FormGroup
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.employee = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.employee).subscribe((res:any)=>{
        this.requestFor = "";
        if(res['first_name'] != null)
        this.requestFor+= res['first_name']+" ";
        if(res['middle_name'] != null)
        this.requestFor+= res['middle_name']+" ";
        if(res['last_name'] != null)
        this.requestFor+= res['last_name'];
        this.requestFor+=" ("+res['employee_code']+")"
        this.currency = res.currency
      })
      }

    })
    this.getLoanTypes(); //Loan Type Fn
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(this.loanRequestForm['controls'].loanRequest.invalid){
        changeEvent.preventDefault();
      }else{
        this.tab2touched = true
      }
    }
  }

  // Step 1
  submitOne(data: any){
    this.activeTab = 2;
    this.tab2touched = true
    this.loanTypeDetails = data?.loanTypeDetails
    this.currency = data?.currency
  }

  // Step 2
  submitForm(data:any){
    let dataArray:any = {
      'loan_type' : this.loanDetailsForm.get('loan_type')?.value,
      'loan_amount' : Number(this.loanDetailsForm.get('loan_amount')?.value),
      "number_of_emi": Number(this.loanDetailsForm.get('number_of_emi')?.value),
      "required_date": moment(this.loanDetailsForm.get('required_date')?.value).format('YYYY-MM-DD'),
      "reason": this.loanDetailsForm.get('reason')?.value,
      "document": this.loanDetailsForm.get('document')?.value
    }
    if(this.from=='behalf'){
      dataArray['employee_id'] = this.employee
      this.activityService.createLoanRequest(dataArray).subscribe((res:any)=>{

        this.router.navigate(['manage-activities']);
      })

    }
    else{

      this.loanServ.createLoanRequest(dataArray).subscribe((res:any)=>{
        this.router.navigate(['request'])
      })
    }
  }

  // Dropdown API
  getLoanTypes(){
    if(this.from=='behalf'){
      this.activityService.getLoanTypesDropdown().subscribe((res:any)=>{
        this.loanTypesDropdown = res?.data
      })
    }
    else{

      this.loanReServ.getLoanTypesDropdown().subscribe((res:any)=>{
        this.loanTypesDropdown = res?.data
      })
    }
  }

  // Setup Close
  backClicked() {
    this.loanServ.backClicked('/loan-setup');
  }
}
