<div class="container-fluid p-24 " *ngIf="permissions">
    <div class="row  mb-16 ">
        <div class="col-md-4 col-lg-6">
            <h3>Role</h3>
            <p class="text-helper mb-md-0">Roles are set of responsibilities. Create new roles and assign them to the appropriate OUs.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16 ">
                <div class="" style="width:22.6875rem;">
                    <app-searchbar  [searchlist]='roleDD' [searchplaceholder]='searchPH' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchRole($event)" [isLoading]="isLoading"></app-searchbar>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13 "></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                        <button ngbDropdownItem  [class]="{'active':listType == 'all'  }" (click)="selectItemList('');listType='all'">All</button>
                        <button ngbDropdownItem  [class]="{'active':listType == 'active' }" (click)="selectItemList(true);listType='active'">Active </button>
                        <button ngbDropdownItem  [class]="{'active':listType == 'inactive'}" (click)="selectItemList(false);listType='inactive'">Deleted</button>
                    </div>
                 </div>
                <button *ngIf="permissions.a" routerLink="/role/setup-role" class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add
                    Role</button>

            </div>
        </div>
    </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16 " *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card3'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <div *ngIf="nodata == false" class="" infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScrollDown()">
    <ng-container  *ngIf="loader==false">
        <div *ngIf="roleDetails.length > 0" class="row row-16  ">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let role of roleDetails;let ind = index">
                <div class="card card-custom1 card-hover" >
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex  view-section" (click)="viewrole(role.id)">

                            <div class="logo-img {{role.color_code}}">{{this.appService.getFirstChar(role.role_name,2)}}</div>
                            <div class="overflow-hidden ">
                                <h6 class="tips text-trim mb-0">{{role.role_name}}</h6>
                                <span class="tips-helper">{{role.role_name}}</span>
                                <span
                                    class="fs-10  text-uppercase fw-500 {{(role.is_active == true) ? 'text-success' : 'text-danger' }} ">{{role.is_active
                                    == true ? 'Active':'Deleted'}}</span>
                            </div>
                        </div>
                        <div  *ngIf="role.default==false && role.is_active==true && (permissions.e || permissions.d)" ngbDropdown class=" dropdown-section d-inline-block " >
                            <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                ngbDropdownToggle>
                                <i class="icon-three-dots-vertical "></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-left " aria-labelledby="dropdownBasic1">
                                <button *ngIf="permissions.e"  ngbDropdownItem routerLink="/role/setup-role/{{role.id}}/">
                                    <i   class="icon-pencil-square me-1"></i> Edit
                                </button>
                                <button *ngIf="permissions.d"  class="text-danger" ngbDropdownItem (click)="deleteAlert=true;deleteId=role.id;roleDefault=role.default;deleteClicked=false;">
                                    <i class="icon-trash  me-1"></i> Delete
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="card-body pt-8  justify-content-between">
                        <div class="d-flex align-items-center overlap-avatar overlap-left"
                        container="main-content" (click)="showUserList = !showUserList;">
                            <ng-container *ngFor="let re of role.role_employee, let i = index">
                                <ng-container *ngIf="re?.employee!=null">
                                    <img  class="avatar-sm sq-32  img-fluid rounded-circle"  *ngIf="i < 3 && re?.employee?.profile_image !=  '' && re?.employee?.profile_image !=  null"  src="{{re?.employee?.profile_image}}" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body">

                                    <div class="avatar-sm sq-32  rounded-circle {{re?.employee?.color_code}}" *ngIf="i < 3 && (re?.employee?.profile_image ==  ''|| re?.employee?.profile_image ==  null)" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body">
                                      <span>
                                        {{this.appService.getFirstChar((re?.employee?.first_name+" "+re?.employee?.last_name),1)}}
                                      </span>
                                    </div>
                                    <ng-template #popContentSingle>
                                      <div class="user-details">
                                          <span class="user-label">{{re?.employee?.first_name+' '+(re?.employee?.middle_name!=null?re?.employee?.middle_name:'')+' '+re?.employee?.last_name}}</span>
                                          <span class="user-code">EMP CODE:{{re?.employee?.employee_code}} </span>
                                      </div>
                                   </ng-template>

                                    <div *ngIf="i==3" class="avatar-sm sq-32  rounded-circle"  [ngbPopover]="popContentMultilpe"  popoverClass="user-list multi-user" triggers="click" container="body"><span>+{{this.appService.subEmp(role.role_employee.length)}}</span></div>

                                </ng-container>
                              </ng-container>

                                    <!-- User List -->
                            <ng-template #popContentMultilpe>
                            <ul>
                                <ng-container *ngFor="let re of role.role_employee, let i = index">
                                <ng-container *ngIf="i>=3">
                                    <li *ngIf="re?.employee?.profile_image !='' && re?.employee?.profile_image !=null">
                                        <img  class="user-avatar" src="{{re?.employee?.profile_image}}">
                                        <div class="user-details">
                                            <span class="user-label">{{re?.employee?.first_name+' '+(re?.employee?.middle_name!=null?re?.employee?.middle_name:'')+' '+re?.employee?.last_name}}</span>
                                            <span class="user-code">EMP CODE : {{re?.employee?.employee_code}}</span>
                                        </div>
                                    </li>
                                    <li *ngIf="re?.employee?.profile_image =='' || re?.employee?.profile_image ==null">
                                        <span class="user-avatar {{re?.employee?.color_code}}">{{this.appService.getFirstChar(re?.employee.first_name+' '+re?.employee?.last_name,2)}}</span>
                                        <div class="user-details">
                                            <span class="user-label">{{re?.employee?.first_name+' '+(re?.employee?.middle_name!=null?re?.employee?.middle_name:'')+' '+re?.employee?.last_name}}</span>
                                            <span class="user-code">EMP CODE : {{re?.employee?.employee_code}}</span>
                                        </div>
                                    </li>
                                </ng-container>
                                </ng-container>
                            </ul>
                            </ng-template>
                                    <!-- User List end-->

                        </div>
                        <div class="fs-12  fw-500 text-light-400">
                            <span *ngIf="role.role_employee.length <= 1" class="text-nowrap">{{role.role_employee.length}} Employee</span>
                            <span *ngIf="role.role_employee.length > 1" class="text-nowrap">{{role.role_employee.length}} Employees</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Loader updations -->
            <ng-container *ngIf="infinityloader">
              <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card3'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
            </ng-container>
        </div>
        <!--<div class="note my-3">No Data Block</div>-->

    </ng-container>
    </div>
    <div *ngIf="nodata && !loader" class="row" style="height: calc(100vh - 11rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg12.png'" [noDataText]="'Define team roles'"
        [noDataPara]="'Set roles for the team so that everyone understands their responsibilities. To get started, simply click \'Add Role\'.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>
</div>
<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:44.6875rem;" [class.side-pane-active]='showSidePanel === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32  py-24 ">
              <!-- View loader start -->
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <!-- View loader end -->
              <ng-container *ngIf="!loader1">
                <div class="d-flex mb-16 ">
                    <p class="text-uppercase fw-500 mb-0">ROLE</p>
                    <a *ngIf="viewRoleData.default==false && permissions.e && viewRoleData.is_active" class="link-primary fs-14  d-flex align-items-center ms-auto" routerLink="/role/setup-role/{{viewRoleData.id}}/">
                        <i class="icon-pencil-square me-1"></i> Edit
                    </a>
                </div>
                <div class="row row-16  ">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24  py-16 ">
                            <div class="row row-16  ">
                                <div class="col-12 "><span class="title">role details</span></div>
                                <div class="col-6">
                                    <div class="detail-title">Role name</div>
                                    <div class="detail-desc">{{viewRoleData.role_name}}</div>
                                </div>
                              <div class="col-6">
                                <div class="detail-title">Status</div>
                                    <div class="detail-desc">
                                      <span class=" fw-500 text-success text-uppercase" *ngIf="viewRoleData.is_active">ACTIVE</span>
                                      <span class=" fw-500 text-danger text-uppercase" *ngIf="viewRoleData.is_active==false">DELETED</span>
                                    </div>
                              </div>
                                <div class="col-12">
                                    <div class="detail-title">Employees</div>
                                    <div class="detail-desc">
                                        <div class="d-flex flex-wrap mt-8  gap-8" >
                                            <span class="badge2" *ngFor="let roleemp of viewRoleData.employee, let i = index" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover">
                                              <div class="avatar-circle sq-24 fs-10  {{roleemp.color_code}}"  alt="" *ngIf="roleemp.profile_image ==null || roleemp.profile_image ==''">{{this.appService.getFirstChar(roleemp.first_name+" "+roleemp.last_name,1)}}</div>
                                              <img class="avatar-circle sq-24 {{roleemp.color_code}}"  src="{{roleemp.profile_image}}" alt="" *ngIf="roleemp.profile_image !=null && roleemp.profile_image !=''">

                                              {{ ((roleemp.first_name+roleemp.middle_name+roleemp.last_name).length>25)? ((roleemp.first_name+' '+roleemp.middle_name+' '+roleemp.last_name+' ('+roleemp?.employee_code+')')| slice:0:25)+'..':(roleemp.first_name+' '+(roleemp.middle_name?roleemp.middle_name:'')+' '+roleemp.last_name+' ('+roleemp?.employee_code+')')  }}
                                              <ng-template #popContentSingle>
                                                <div class="user-details">
                                                    <span class="user-label">{{roleemp?.first_name+' '+(roleemp?.middle_name!=null?roleemp?.middle_name:' ')+' '+roleemp?.last_name}}</span>
                                                    <span class="user-code">EMP CODE: {{roleemp?.employee_code}} </span>
                                                </div>
                                             </ng-template>
                                            </span>

                                            <!-- <span class="badge2"> <div class="avatar-circle sq-24 fs-10  bg1"  alt="">LR</div> Lindsey Donin</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24  pt-16  pb-24 ">
                            <div class="row row-16  ">
                                <div class="col-12 "><span class="title">Rights</span></div>
                                <div class="col-12 mt-24" *ngFor="let emprights of viewRoleData.employee_rights, let i = index">
                                    <div class="card card-c2 px-16 ">
                                        <div class="card-body px-0 py-16 " >
                                            <div class="d-flex align-items-center" >
                                                <img  class="avatar-circle sq-40 {{emprights.color_code}}"  src="{{emprights.profile_image}}"   alt="" *ngIf="emprights.profile_image !=null && emprights.profile_image !=''">

                                                <div class="avatar-circle sq-40 {{emprights.color_code}} fs-14  fw-500"  alt="" *ngIf="emprights.profile_image ==  null || emprights.profile_image ==''">{{this.appService.getFirstChar(emprights.first_name+" "+emprights.last_name,1)}}</div>

                                                <p class="mb-0 fw-600 fs-14  ms-8 text-break me-16"> {{ emprights.first_name+' '+(emprights.middle_name?emprights.middle_name:' ')+' '+emprights.last_name
                                                  }}</p>
                                            </div>
                                        </div>
                                        <div class="card-body px-0 py-16 ">
                                            <div class="row row-24 ">
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Company</div>
                                                    <div class="detail-desc">{{emprights.company == '' ? '-': ''}}
                                                        <div *ngIf="emprights.company!=''" class="selected-values mt-1">
                                                          <li class="ng-value" *ngFor="let rights of emprights.company, let i=index;">
                                                            <div class="ng-value-label">
                                                              <span class="d-inline-flex align-items-center" [ngbPopover]="popContentSingle2"  popoverClass="user-list single-user" triggers="hover">
                                                                <img class="img-fluid rounded-circle sq-24"  src="{{rights.company_logo}}" *ngIf="rights.company_logo !=  '' && rights.company_logo !=  null">
                                                                <span class="avatar sq-24 avatar-circle bg1"  *ngIf="rights.company_logo ==  null || rights.company_logo ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                                                  <span> {{rights.company_name}}</span>
                                                               </span>
                                                               <ng-template #popContentSingle2>
                                                                <div class="user-details">
                                                                    <span class="user-label">{{rights.company_name}}</span>
                                                                </div>
                                                             </ng-template>
                                                            </div>
                                                          </li>

                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Business unit</div>
                                                    <div class="detail-desc">{{emprights.businessunit == '' ? '-': ''}}
                                                        <div *ngIf="emprights.businessunit!=''" class="d-flex flex-wrap mt-8 gap-8">
                                                        <span  *ngFor="let item of emprights.businessunit" class="badge3">
                                                            {{item.businessunit}}</span></div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Department</div>
                                                    <div class="detail-desc">{{emprights.department == '' ? '-': ''}}
                                                        <div *ngIf="emprights.department!=''" class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.department" class="badge3">
                                                                {{item.department}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Designation</div>
                                                    <div class="detail-desc">{{emprights.designation == '' ? '-': ''}}
                                                        <div *ngIf="emprights.designation!=''" class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.designation" class="badge3">
                                                                {{item.designation}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Branch</div>
                                                    <div class="detail-desc">{{emprights.branch == '' ? '-': ''}}
                                                        <div *ngIf="emprights.branch!=''" class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.branch" class="badge3">
                                                                {{item.branch}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">City</div>
                                                    <div class="detail-desc">{{emprights.city == '' ? '-': ''}}
                                                        <div *ngIf="emprights.city!=''" class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.city" class="badge3">
                                                                {{item.city}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Grade</div>
                                                    <div class="detail-desc">{{emprights.grade == '' ? '-': ''}}
                                                        <div *ngIf="emprights.grade!=''" class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.grade" class="badge3">
                                                                {{item.grade}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="deleteAlert" class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header ">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Role?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Role')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteAlert = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm" [disabled]="deleteClicked" (click)="deleteRole(deleteId)">Delete</button>
        </div>
      </div>
    </div>
  </div>

