<form action="" class=""  [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">SHIFT DETAILS</p>
        </div>
        <div class="row row-24">
            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Shift Code </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Shift code is a short abbreviation that represents the shift." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <input type="text" formControlName="shift_code" class="form-control " [ngClass]="{ 'is-invalid': (f.shift_code?.touched || f.shift_code?.dirty) && f.shift_code.errors || f.shift_code.errors?.exist}" placeholder="{{this.messageService.placeholderdisp('Shift Code')}}" (keyup)="CodekeyUp()">
                <div *ngIf=" f.shift_code?.errors" class="invalid-feedback">
                    <div *ngIf="f.shift_code.hasError('exist')">{{this.messageService.validationDisplay(f.shift_code.value)}}</div>
                  <div *ngIf="f.shift_code.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.shift_code.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!f.shift_code.errors?.pattern && f.shift_code.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','10')}}</div>
                    <div *ngIf="f.shift_code.errors?.duplicatecode">Shift Code and Shift Name cannot be same.</div>
                </div>
            </div>
            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Shift Name</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a shift name and this should be unique." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <input type="text" formControlName="shift_name" class="form-control " [ngClass]="{ 'is-invalid': (f.shift_name?.touched || f.shift_name?.dirty) && f.shift_name.errors || f.shift_name.errors?.exist }" placeholder="{{this.messageService.placeholderdisp('Shift Name')}}" (keyup)="keyUp()" >
                <div *ngIf=" f.shift_name?.errors || f.shift_name.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.shift_name.hasError('exist')">{{this.messageService.validationDisplay(f.shift_name.value)}}</div>
                  <div *ngIf="f.shift_name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.shift_name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!f.shift_name.errors?.pattern && f.shift_name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf="f.shift_name.errors?.duplicatecode">Shift Code and Shift Name cannot be same.</div>
                </div>
            </div>
            <div class="col-12 form-row  ">
                <label for="" class="form-label required">Shift colour  </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose a color for the shift." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8">
                <div class="card card-c2 p-24">
                    <div class="col-12 form-row">
                        <div class="d-flex gap-24 flex-wrap">
                            <div class="form-check d-flex flex-column align-items-center gap-16 ps-0" *ngFor=" let i of [1,2,3,4,5,6,7,8]">
                                <input type="radio" id="uniquebg{{i}}"
                                    class="form-check-input"  formControlName="color_code" m-0 value="uniquebg{{i}}" [checked]="true" hidden (change)="colorchange(i)">
                                <label for="uniquebg{{i}}" class="form-check-label uniquebg{{i}} input-uniquebg sq-40 radius-24 "> </label>
                                <div *ngIf=" f.color_code?.errors" class="invalid-feedback">
                                    <div *ngIf="f.color_code.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-16">
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label required">Is shift allowance is applicable for this shift?</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="Select Yes if you want to allow shift allowance."
                            popoverClass="popover-default popover-info" placement="bottom" container="body"
                            triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" formControlName="shift_allowance_applicable" type="radio"
                                [value]=true>
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input formControlName="shift_allowance_applicable" class="form-check-input" type="radio"
                                [value]=false >
                            No
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
      <button *ngIf="disabled==false" [disabled]="!formGp.valid " (click)="validateshiftdetail()" class="btn btn-primary btn-sm ms-auto">NEXT</button>
      <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateshiftdetail()">NEXT</button>
    </footer>
</form>
