<div class="card card-c2 hstack align-items-start">

  <div class="py-24 border-end" style="width:11.375rem;background-color: #DCEAFB;">
      <div class="text-uppercase fs-12 fw-500 px-24 text-light-500 pb-8">MONTHS</div>
      <ul class=" vetical-tab vetical-tab-inverted tab-scroll overflow-auto">
          <li *ngFor="let fy of fyArray; let i = index">
            <a class="tab-link text-trim {{fMonth == fy?.month ? 'active' : ''}}" (click)="challanMonthYear(fy?.month,fy?.year)">{{fy?.month+' '+fy?.year}}</a>
        </li>
      </ul>
  </div>
  <div class="w-100">
      <div class="card-body px-24 pt-24">
          <div class="fw-500">Employee state insurance</div>
      </div>
      <div class="card-body p-0">
          <nav class="nav nav-custom shadow-0">
              <a class="nav-link {{activeTab2 == 'challan' ? 'active' : ''}}"
                  (click)="loader=true;tabClick('challan')">Challan</a>
              <a class="nav-link {{activeTab2 == 'returns' ? 'active' : ''}}"
                  (click)="loader=true;tabClick('returns')">Generate Returns</a>
          </nav>
      </div>
      <div class="card-body p-24">
          <ng-container *ngIf="activeTab2 == 'challan'">
              <div class="row row-16">
                <div *ngIf="(nodata && !loader);else all_data" class="row " style="height:25rem;">
                  <app-nodata-view class="vstack" [imgClass]="'img-md'" [noDataImg]="'bg41.png'" [noDataText]="'Oops, ESI challan unavailable!'"
                    [noDataPara]="'It seems like the salary for the selected month hasn\'t been processed. Once it\'s done, you\'ll be able to generate the ESI challans here.'"
                    [hasPermission]="false">
                  </app-nodata-view>
                </div>
                  <div class="row mt-0 row-16" *ngIf="loader">
                    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card1'"
                        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
                  </div>
                  <ng-template #all_data>
                    <ng-container *ngIf="!loader">
                      <div class="col-12">
                        <div  role="alert" class="alert alert-info mb-0 show">
                            <p class="mb-0 "><span class="fs-14  fw-500">Always regenerate the challan to reflect the latest change of payroll in challan before downloading the file</span></p>
                          </div>
                      </div>
                      <div class="col-md-6 col-sm-12" *ngFor="let challan of challanList let i = index">
                        <div class="card card-custom1 card-hover">
                            <div class="card-body p-0 d-flex align-items-start">
                                <div class="d-flex view-section  align-items-center">
                                    <div class="logo-img  {{challan?.challan_group?.color_code}}">{{this.appService.getFirstChar(challan?.challan_group?.name,2)}} </div>
                                    <div class="overflow-hidden">
                                        <h6 class="mb-1 tips">{{challan?.challan_group?.name}} </h6>
                                        <span class="tips-helper">{{challan?.challan_group?.name}}</span>
                                        <div
                                            class="fs-10 text-uppercase fw-500  text-success ">
                                            PROCESSED</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body flex-column ">
                                <div
                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                    <span class="text-trim "><span
                                            class="text-light-400 ">Processed
                                            Employee Count : </span> {{challan?.processed_count}}/{{challan?.total_count}} </span>
                                    <span class="link-primary fw-500"
                                        (click)="generateViewChallan(challan?.challan_data,challan?.generate,challan?.challan_group?.name,challan?.employee_challan,challan?.challan_group?.challan_group_is_active,challan?.challan_group?.group_owner?.currency)">{{challan?.generate == false ? 'Generate' : 'View details'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                  </ng-template>
              </div>
          </ng-container>
          <ng-container *ngIf="activeTab2 == 'returns'">
              <div class="row row-16">
                  <div *ngIf="(nodata && !loader);else all_data" class="row " style="height:25rem;">
                    <app-nodata-view class="vstack"  [imgClass]="'img-md'"  [noDataImg]="'bg41.png'" [noDataText]="'Awaiting ESI challan generation!'"
                      [noDataPara]="'It seems no ESI challans have been generated for this month. Once you generate them, you can regenerate them to update any recent changes.'"
                      [hasPermission]="false">
                    </app-nodata-view>
                  </div>
                  <div class="row mt-0 row-16" *ngIf="loader">
                    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card1'"
                        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
                  </div>
                  <ng-template #all_data>
                    <ng-container *ngIf="!loader">
                      <div class="col-12">
                        <div  role="alert" class="alert alert-info mb-0 show">
                            <p class="mb-0 "><span class="fs-14  fw-500">Always regenerate the challan to reflect the latest change of payroll in challan before downloading the file</span></p>
                          </div>
                      </div>
                      <div class="col-md-6 col-sm-12" *ngFor="let challan of challanList let i = index">
                      <div class="card card-custom1 card-hover">
                          <div class="card-body p-0 d-flex align-items-start">
                              <div class="d-flex w-100 align-items-center p-16">
                                  <div class="logo-img  {{challan?.representation_data?.challan_group?.color_code}}">{{this.appService.getFirstChar(challan?.representation_data?.challan_group?.name,2)}}  </div>
                                  <div class="overflow-hidden">
                                    <h6 class="mb-1 tips">{{challan?.representation_data?.challan_group?.name}} </h6>
                                    <span class="tips-helper">{{challan?.representation_data?.challan_group?.name}}</span>
                                      <div
                                          class="fs-10 text-uppercase fw-500  text-success ">
                                          PROCESSED</div>
                                  </div>
                                  <a class="link-primary ms-auto fs-12 fw-500"
                                  (click)="downloadchellan(challan?.challan_summary)"><i
                                          class="icon-download me-1"></i> Download</a>
                              </div>
                          </div>
                          <div class="card-body flex-column ">
                              <div
                                  class="d-flex justify-content-between w-100 gap-8 fs-12">
                                  <span class="text-trim "><span
                                          class="text-light-400 ">Processed
                                          Employee Count : </span> {{challan?.representation_data?.processed_count}}/{{challan?.representation_data?.total_count}} </span>
                                  <span class="link-primary fw-500"
                                      (click)="regenerateview(challan?.id,challan?.representation_data?.challan_group?.name,challan?.representation_data?.challan_data,challan?.challan_group_is_active,challan?.representation_data?.challan_group?.group_owner?.currency);">Regenerate
                                  </span>
                              </div>
                          </div>
                      </div>
                      </div>
                    </ng-container>
                  </ng-template>
              </div>
          </ng-container>
      </div>
  </div>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='generateChallan === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>{{buttonLabel}} </h5>
            <a class="toggle-panel" (click)="generateChallan = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <div class="row row-16" *ngIf="!loader1">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0">{{groupName}}</p>

                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">GROUP DETAILS</p>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Total Gross Wages</div>
                                    <div class="detail-desc">{{generateViewData?.total_gross_wages | currency : currency}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Total ESIC Wages</div>
                                    <div class="detail-desc">{{generateViewData?.total_esic_wages | currency : currency}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Total ESIC Contribution</div>
                                    <div class="detail-desc">{{generateViewData?.total_esic_contribution | currency : currency}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer" *ngIf="buttonLabel == 'Generate' || buttonLabel == 'Regenerate'">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="buttonLabel == 'Generate' ? generateEsiChallan() : regenerateEsiChallan()" [disabled]="btnDisable">
                {{buttonLabel}}
            </button>
        </footer>
    </div>
</div>

