<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Separation Policy</h3>
      <p class="text-helper mb-md-0">A process that an organization follows when an employee leaves the company.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='separationPlaceholder'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpolicy($event)" [isLoading]="isLoading"></app-searchbar>
        </div>

        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13 "></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add"
          routerLink="/separation/separation-policy-setup"><i class="icon-plus"></i>ADD POLICY </button>
      </div>
    </div>
  </div>
  <!-- No data found -->

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg48.png'" [noDataText]="'Time to set up a separation policy'"
      [noDataPara]="'The separation policy isn’t configured yet. Click \'Add Separation Policy\' to manage employee exits smoothly.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-template #all_data>
    <div class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let sep_data of SeparationPolicyData;let i=index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewdatapolicy(sep_data.id)">
                <div class="logo-img {{sep_data.color_code}}">{{this.appService.getFirstChar(sep_data.name,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips">{{sep_data.name}}</h6>
                  <span class="tips-helper">{{sep_data.name}}</span>
                  <div
                    class="fs-10 text-uppercase fw-500 {{(sep_data.is_active == true) ? 'text-success' : 'text-danger' }} ">
                    {{sep_data.is_active == true ? 'Active':'Deleted'}}
                  </div>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section"
                *ngIf="sep_data.is_active==true && (permissions.e || permissions.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
                  <button *ngIf="permissions.e" ngbDropdownItem (click)="editseparationpolicy(sep_data.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                    (click)="deleteAlert=true;deleteId=sep_data.id">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body p-16 justify-content-between" (click)="detailsView = true">
              <span class="fs-10 text-light-400">APPLICABLE ON</span>
              <span class="fs-12 ">{{sep_data.seperation_policy_applicablity[0].applicable}}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card1'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
      </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

</div>

<div class="side-panel " style="--sidepanel-width:45rem;" [class.side-pane-active]='detailsView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="detailsView = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">

          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">POLICY</p>
            <a class="link-primary fs-14 d-flex align-items-center ms-auto"
              (click)="editseparationpolicy(viewpolicydata.id)" *ngIf="(permissions.e && viewpolicydata.is_active)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                <div class="row row-24 ">
                  <div class="col-12 ">
                    <span class="title">POLICY SETTINGS</span>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Separation policy name</div>
                    <div class="detail-desc">{{viewpolicydata.name}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase"
                        *ngIf="viewpolicydata.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewpolicydata.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Policy description</div>
                    <div class="detail-desc">{{viewpolicydata.description}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Notice period days for probation period employee (in days)</div>
                    <div class="detail-desc">{{viewpolicydata.probation_notice_period
                      }}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Notice period days for confirmed employee (in days)</div>
                    <div class="detail-desc">{{viewpolicydata.confirm_notice_period
                      }}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Send relieving letter?</div>
                    <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>{{viewpolicydata.is_send_relieving_letter?'Yes':'No'}}</div>
                  </div>
                <ng-container *ngIf="viewpolicydata.is_send_relieving_letter">
                  <div class="col-12">
                    <div class="detail-title">How many days before relieving should relieving letter task be
                      initiated to task owner?</div>
                    <div class="detail-desc">{{viewpolicydata?.relieveing_process_days}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Map a letter template</div>
                    <div class="detail-desc">{{viewpolicydata?.letter_template?.name}}</div>
                  </div>
                </ng-container>
                  <!--  <div class="col-12">
                    <div class="detail-title">Is backdated resignation allowed?</div>
                    <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>Yes</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Maximum number of days allowed for backdated resignation(If backdated
                      resignation is allowed)</div>
                    <div class="detail-desc">5</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Enable exit interview to the employees?</div>
                    <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>Yes</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">How many days prior to the leaving, exit interview should be triggered?
                    </div>
                    <div class="detail-desc">5</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Exit interview template to be applicable?</div>
                    <div class="detail-desc">Applicable1</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Enable exit clearance workflow?</div>
                    <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>Yes</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">How many days before relieving should exit clearance task be initiated to
                      task owner?</div>
                    <div class="detail-desc">5</div>
                  </div> -->


                  <!-- <div class="col-12">
                                <div class="detail-title">Is backdated resignation allowed?</div>
                                <div class="detail-desc" *ngIf="viewpolicydata.backdated_resignation_allow">Yes</div>
                                <div class="detail-desc" *ngIf="viewpolicydata.backdated_resignation_allow==false">No</div>
                              </div>
                              <div class="col-12" *ngIf="viewpolicydata.backdated_resignation_allow !=false">
                                <div class="detail-title">Maximum number of  days allowed for backdated resignation(If backdated resignation is allowed)</div>
                                <div class="detail-desc">{{viewpolicydata.backdated_resignation_period}}</div>
                              </div> -->
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Applicability</span></div>
                  <div class="col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{viewpolicydatarights?.applicable}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">{{viewpolicydatarights?.applicable}}(s) </div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="viewpolicydatarights?.applicable == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,2)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="viewpolicydatarights?.applicable == 'Company'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="(viewpolicydatarights?.applicable != 'Employee') && (viewpolicydatarights?.applicable != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of assignees, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>

                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>
<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Separation Policy?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Separation Policy')}}

        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="deletePoilcy(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
