
  <div class="row row-8">
    <div class="col-12">
      <div class="col-12 d-flex position-relative">
        <span class=" text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>

        <button *ngIf="from!='behalf'" class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
          (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
          <i class="bi toggle-star"></i>
        </button>
      </div>
      <div class="col-12 card-ribbon">
        <div class="w-100 d-flex align-items-center gap-8">
          <h5 class="mb-8">Final Settlement Approval</h5>
          <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">

            <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
              *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
            <span class="badge px-16 ms-auto fs-14 badge-success py-1"
              *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
            <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
              *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
            <span class="text-light-400">|</span>
            <span class="link-primary fs-14 text-nowrap" (click)="requestViewStatus()">View Status</span>
          </ng-container>
          <!-- Invalid request -->
          <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
            class="ribbon ribbon-right ribbon-danger  ribbon-sm"
            style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
        </div>
        <span class=" badge-custom {{this.inboxservice.getColor(requestitem.request_type_color_code)}} ">Final Settlement Approval</span>

        <span
          *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
          class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
      </div>
    </div>
    <ng-container *ngIf="from!='behalf'">

      <ng-container *ngIf="requestitem.level == 1">
        <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Approved'">
          <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
            <i class="bi fs-16 bi-check-square-fill text-success "></i>
            <span class="text-nowrap">Approved by </span>
            <span class="fw-600 text-trim">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id
              ?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
          </div>
        </div>
        <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Rejected'">
          <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
            <i class="bi fs-16  bi-x-square-fill text-danger "></i>
            <span class="text-nowrap">Rejected by </span>
            <span class="fw-600 text-trim">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id
              ?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
          </div>
        </div>
        <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Pending'">
          <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
            <i class="bi fs-16  bi-x-square-fill text-warning "></i>
            <span class="text-nowrap">Pending with </span>
            <span class="fw-600 text-trim">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id ||
              requestitem.approved_person_list[0]['id'] ==
              null?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="requestitem.level > 1">
        <div class="col-12">
          <div class="card card-c2 px-16 pt-16 pb-8">
            <div class="dropdown_progres">
              <ul class="progress_drp progress_vertical">
                <ng-container *ngFor="let item of requestitem.approved_person_list;let i = index">
                  <ng-container
                    *ngIf="requestitem.approved_person_list[i]['level'] < requestitem.level || requestitem.approved_person_list[i]['level'] == requestitem.level ">
                    <li class="progress__item progress__item--accepted"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Approved'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Approved by </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] ==
                            requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="progress__item progress__item--rejected"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Rejected'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Rejected by </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] ==
                            requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="progress__item progress__item--pending"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Pending'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Pending with </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] == requestitem.to_person.id ||
                            requestitem.approved_person_list[i]['id'] ==
                            null?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                        </div>
                      </div>
                    </li>
                    <li class="progress__item progress__item--intimation"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Intimation'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Intimation sent to </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] ==
                            requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-container>

                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-12">
        <p class="mb-0 fs-14" [innerHTML]="requestitem.message">
        </p>
      </div>
    </ng-container>
    <div class=" col-md-9  col-lg-6">
      <button class="btn btn-outline-primary w-100 fw-400 btn-sm radius-2" (click)="DetailsView = true;">Final
        Settlement Details</button>
    </div>

  </div>

<!--  VIEW START -->
<ng-container *ngIf="DetailsView">
  <app-final-settelement-expansion [from]="from" [employeeId]="requestitem.request_data.employee"
    [showFinalSettlementView]="DetailsView" (closeExpansion)="DetailsView=false" [requestitem]="requestitem"
    (refreshEmitter)="refreshInbox($event)">
  </app-final-settelement-expansion>
</ng-container>


<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem.request_data['id']" (closePanel)="viewstatusPanel=false"></app-view-status>



    </form>
  </div>
</ng-container>
<!-- end view status panel -->
