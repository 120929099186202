import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { BranchComponent } from './organizatin-structure/branch/branch.component';
import { BusinessUnitComponent } from './organizatin-structure/business-unit/business-unit.component';
import { CityComponent } from './organizatin-structure/city/city.component';
import { CompanyComponent } from './organizatin-structure/company/company.component';
import { DepartmentComponent } from './organizatin-structure/department/department.component';
import { DesignationComponent } from './organizatin-structure/designation/designation.component';
import { GradeComponent } from './organizatin-structure/grade/grade.component';
import { OrganizatinStructureComponent } from './organizatin-structure/organizatin-structure.component';
import { RoleComponent } from './role/role/role.component';
import { RoleSetupComponent } from './role/role-setup/role-setup.component';

import { PageAccessComponent } from './page-access/page-access/page-access.component';
import { PageAccessSetupComponent } from './page-access/page-access-setup/page-access-setup.component';
import { PaAssignRightsComponent } from './page-access/page-access-setup/pa-assign-rights/pa-assign-rights.component';
import { PaRightsComponent } from './page-access/page-access-setup/pa-rights/pa-rights.component';
import { PaVisiblityComponent } from './page-access/page-access-setup/pa-visiblity/pa-visiblity.component';
import { DataAccessComponent } from './data-access/data-access/data-access.component';
import { DataAccessSetupComponent } from './data-access/data-access-setup/data-access-setup.component';
import { DaRightsComponent } from './data-access/data-access-setup/da-rights/da-rights.component';
import { DaDefineComponent } from './data-access/data-access-setup/da-define/da-define.component';
import { ApprovalMatrixComponent } from './approval-matrix/approval-matrix/approval-matrix.component';
import { ApprovalMatrixSetupComponent } from './approval-matrix/approval-matrix-setup/approval-matrix-setup.component';

import { DocumentsComponent } from './documents/documents.component';
import { PayComponentComponent } from './pay-component/pay-component/pay-component.component';
import { PayComponentSetupComponent } from './pay-component/pay-component-setup/pay-component-setup.component';

import { StatutoryComponent } from './statutory/statutory.component';
import { PayGroupComponent } from './pay-group/pay-group/pay-group.component';
import { PayGroupSetupComponent } from './pay-group/pay-group-setup/pay-group-setup.component';
import { PgDefineComponent } from './pay-group/pay-group-setup/pg-define/pg-define.component';
import { PgCreateFormulaComponent } from './pay-group/pay-group-setup/pg-create-formula/pg-create-formula.component';
import { PgMapPayComponent } from './pay-group/pay-group-setup/pg-map-pay/pg-map-pay.component';
import { TaskOwnerComponent } from './task-owner/task-owner.component';
import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';

import { AttendanceRuleComponent } from './attendance-rule/attendance-rule/attendance-rule.component';
import { AttendanceRuleSetupComponent } from './attendance-rule/attendance-rule-setup/attendance-rule-setup.component';

import { HolidayComponent } from './holiday/holiday.component';
import { HolidayPolicySetupComponent } from './holiday/holiday-policy/holiday-policy-setup/holiday-policy-setup.component';
import { ShiftDetailComponent } from './shift-detail/shift-detail.component';
import { ShiftComponent } from './shift-detail/shift/shift.component';
import { LeaveComponent } from './leave/leave.component';
import { LeaveTypeComponent } from './leave/leave-type/leave-type.component';
import { LeavePolicyComponent } from './leave/leave-policy/leave-policy.component';
import { LeavePolicySetupComponent } from './leave/leave-policy-setup/leave-policy-setup.component';
import { CompanyStatutoryComponent } from './statutory/company-statutory/company-statutory.component';
import { ConfigurationComponent } from './configuration/configuration/configuration.component';
import { ConfigurationSetupComponent } from './configuration/configuration-setup/configuration-setup.component';
import { LoginPageCustomizationComponent } from './login-page-customization/login-page-customization.component';
import { OrganizationComponent } from './organizatin-structure/organization/organization.component';
import { TaxDeductorComponent } from './tax-deductor/tax-deductor.component';
import { ShiftSetupComponent } from './shift-detail/shift-setup/shift-setup.component';
import { ShiftRotationSetupComponent } from './shift-detail/shift-rotation-setup/shift-rotation-setup.component';
import { AttendanceCapturePolicyComponent } from './attendance-capture-policy/attendance-capture-policy/attendance-capture-policy.component';
import { WeeklyOffComponent } from './weekly-off/weekly-off/weekly-off.component';
import { AttendanceCapturePolicySetupComponent } from './attendance-capture-policy/attendance-capture-policy-setup/attendance-capture-policy-setup.component';
import { WeeklyOffSetupComponent } from './weekly-off/weekly-off-setup/weekly-off-setup.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { EmailHeaderComponent } from './email-header/email-header.component';
import { SeperationComponent } from './seperation/seperation.component';
import { SeperationPolicySetupComponent } from './seperation/seperation-policy-setup/seperation-policy-setup.component';
import { ReasonListComponent } from './seperation/reason-list/reason-list.component';
import { SeperationPolicyComponent } from './seperation/seperation-policy/seperation-policy.component';
import { HolidayTypeComponent } from './holiday/holiday-type/holiday-type.component';
import { HolidayPolicyComponent } from './holiday/holiday-policy/holiday-policy/holiday-policy.component';
import { SnapshotComponent } from './permissions/snapshot/snapshot.component';
import { ShiftRotationComponent } from './shift-detail/shift-rotation/shift-rotation.component';
// import { LeaveTypeSetupComponent } from './leave/leave-type-setup/leave-type-setup.component';
import { TimeAttendanceSettingsComponent } from './time-attendance-settings/time-attendance-settings.component';
import { TimeAttendanceSettingsSetupComponent } from './time-attendance-settings/time-attendance-settings-setup/time-attendance-settings-setup.component';
import { FieldsPermissionsComponent } from './permissions/fields-permissions/fields-permissions.component';
import { TabsPermissionsComponent } from './permissions/tabs-permissions/tabs-permissions.component';
import { ShortLeavePolicyComponent } from './short-leave/short-leave-policy/short-leave-policy.component';
import { ShortLeavePolicySetupComponent } from './short-leave/short-leave-policy-setup/short-leave-policy-setup.component';
import { ChallanSettingsComponent } from './challan/challan-settings/challan-settings.component';
import { ChallanSettingsSetupComponent } from './challan/challan-settings-setup/challan-settings-setup.component';
import { AssetPolicyComponent } from './asset-policy/asset-policy.component';
import { AssetSettingsComponent } from './asset-settings/asset-settings.component';
import { AssetSettingsSetupComponent } from './asset-settings/asset-settings-setup/asset-settings-setup.component';
import { ExpenseComponent } from './expense/expense.component';
import { ExpensePolicySetupComponent } from './expense/expense-policy-setup/expense-policy-setup.component';
import { LocationGroupComponent } from './expenses/location-group/location-group.component';
import { LocationGroupSetupComponent } from './expenses/location-group-setup/location-group-setup.component';
import { AdvancePolicyComponent } from './expense-advance/advance-policy/advance-policy.component';
import { AdvancePolicySetupComponent } from './expense-advance/advance-policy-setup/advance-policy-setup.component';
import { ExpenseCategoryComponent } from './expense/expense-category/expense-category.component';
import { ExpensePolicyComponent } from './expense/expense-policy/expense-policy.component';

import { ExpenseSettingsComponent } from './expense-settings/expense-settings/expense-settings.component';
import { ExpenseSettingsSetupComponent } from './expense-settings/expense-settings-setup/expense-settings-setup.component';
// import { LocationPolicyComponent } from './location-policy/location-policy/location-policy.component';
// import { LocationPolicySetupComponent } from './location-policy/location-policy-setup/location-policy-setup.component';

import { AssetCategoryComponent } from './asset-policy/asset-category/asset-category.component';
import { AssetSubCategoryComponent } from './asset-policy/asset-sub-category/asset-sub-category.component';
import { LetterTemplateComponent } from './letter-template/letter-template/letter-template.component';
import { LetterTemplateSetupComponent } from './letter-template/letter-template-setup/letter-template-setup.component';
import { ConfirmationPolicyComponent } from './confirmation-policy/confirmation-policy/confirmation-policy.component';
import { ConfirmationPolicySetupComponent } from './confirmation-policy/confirmation-policy-setup/confirmation-policy-setup.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { ShiftAllowancePolicyComponent } from './shift-allowance-policy/shift-allowance-policy/shift-allowance-policy.component';
import { ShiftAllowancePolicySetupComponent } from './shift-allowance-policy/shift-allowance-policy-setup/shift-allowance-policy-setup.component';
const routes: Routes = [LayoutService.childRoutes([
  { path: 'org_structure', redirectTo: '/org_structure/organization', pathMatch: 'full' },
  {
    path: 'org_structure', component: OrganizatinStructureComponent, children: [
      { path: 'organization', component: OrganizationComponent, },
      { path: 'company', component: CompanyComponent, },
      { path: 'bu', component: BusinessUnitComponent, },
      { path: 'department', component: DepartmentComponent, },
      { path: 'designation', component: DesignationComponent, },
      { path: 'grade', component: GradeComponent, },
      { path: 'city', component: CityComponent, },
      { path: 'branch', component: BranchComponent, },
    ]
  },
  { path: 'role', component: RoleComponent },
  { path: 'role/setup-role', component: RoleSetupComponent },
  { path: 'role/setup-role/:id', component: RoleSetupComponent },


  { path: 'page-access', component: PageAccessComponent },
  { path: 'page-access/setup-page-access', component: PageAccessSetupComponent },
  { path: 'page-access/setup-page-access/:id', component: PageAccessSetupComponent },

  { path: 'data-access', component: DataAccessComponent },
  { path: 'data-access/setup-data-access', component: DataAccessSetupComponent },
  { path: 'data-access/setup-data-access/:id', component: DataAccessSetupComponent },

  { path: 'approval-matrix', component: ApprovalMatrixComponent, },
  { path: 'approval-matrix/setup-approval-matrix', component: ApprovalMatrixSetupComponent },
  { path: 'approval-matrix/setup-approval-matrix/:id', component: ApprovalMatrixSetupComponent },

  { path: 'documents', component: DocumentsComponent },

  { path: 'pay-component', component: PayComponentComponent },
  { path: 'pay-component/pay-component-setup', component: PayComponentSetupComponent },
  { path: 'pay-component/pay-component-setup/:id', component: PayComponentSetupComponent },

  { path: 'statutory', component: StatutoryComponent },
  { path: 'statutory/company-statutory/:id/:year/:isActive', component: CompanyStatutoryComponent },
  { path: 'task-owner', component: TaskOwnerComponent },

  { path: 'pay-group', component: PayGroupComponent },
  { path: 'pay-group/pay-group-setup/:id', component: PayGroupSetupComponent },
  { path: 'pay-group/pay-group-setup', component: PayGroupSetupComponent },

  { path: 'settings', component: MiscellaneousComponent },

  { path: 'time-attendance-settings', component: TimeAttendanceSettingsComponent },
  { path: 'time-attendance-settings/time-attendance-settings-setup', component: TimeAttendanceSettingsSetupComponent },
  { path: 'time-attendance-settings/time-attendance-settings-setup/:id', component: TimeAttendanceSettingsSetupComponent },


  // { path: 'shift/shiftmap-setup/new', component:ShiftMapSetupComponent },
  // { path: 'shift/shiftmap-setup/:id', component:ShiftMapSetupComponent },
  // { path: 'shift/:tab', component:ShiftDetailComponent },


  { path: 'attendance-rule', component: AttendanceRuleComponent },
  { path: 'attendance-rule/attendance-rule-setup', component: AttendanceRuleSetupComponent },
  { path: 'attendance-rule/attendance-rule-setup/:id', component: AttendanceRuleSetupComponent },


  // { path: 'holiday-rule', component:HolidayComponent},
  // { path: 'holiday-rule/:tab', component:HolidayComponent},
  { path: 'holiday-rule/holiday-policy-setup/new', component: HolidayPolicySetupComponent },
  { path: 'holiday-rule/holiday-policy-setup/:id', component: HolidayPolicySetupComponent },

  {
    path: 'holiday-rule', component: HolidayComponent, children: [
      { path: 'holiday-type', component: HolidayTypeComponent, },
      { path: 'holiday-policy', component: HolidayPolicyComponent, },

    ]
  },


  {
    path: 'leave-rule', component: LeaveComponent, children: [
      { path: 'leave-type', component: LeaveTypeComponent, },
      { path: 'leave-policy', component: LeavePolicyComponent, },

    ]
  },
  // { path: 'leave-rule', component:LeaveComponent},
  // { path: 'leave-rule/:tab', component:LeaveComponent},
  // { path: 'leave-rule/leave-type-setup', component:LeaveTypeSetupComponent},
  { path: 'leave-rule/leave-policy-setup/new', component: LeavePolicySetupComponent },
  { path: 'leave-rule/leave-policy-setup/:id', component: LeavePolicySetupComponent },



  { path: 'configuration', component: ConfigurationComponent },
  { path: 'configuration/configuration-setup', component: ConfigurationSetupComponent },
  { path: 'configuration/configuration-setup/:id', component: ConfigurationSetupComponent },

  { path: 'customize-login', component: LoginPageCustomizationComponent },
  { path: 'tax-deductor', component: TaxDeductorComponent },

  { path: 'attendance-capture-policy', component: AttendanceCapturePolicyComponent },
  { path: 'attendance-capture-policy/attendance-capture-policy-setup', component: AttendanceCapturePolicySetupComponent },
  { path: 'attendance-capture-policy/attendance-capture-policy-setup/:id', component: AttendanceCapturePolicySetupComponent },

  { path: 'weekly-off', component: WeeklyOffComponent },
  { path: 'weekly-off/weekly-off-setup', component: WeeklyOffSetupComponent },
  { path: 'weekly-off/weekly-off-setup/:id', component: WeeklyOffSetupComponent },

  {
    path: 'permissions', component: PermissionsComponent, children: [
      // { path: 'snapshot-permission', component: SnapshotComponent },
      { path: 'tab-permission', component: TabsPermissionsComponent },
      { path: 'field-permission', component: FieldsPermissionsComponent },
    ]
  },
  { path: 'email-header', component: EmailHeaderComponent },

  {
    path: 'shift', component: ShiftDetailComponent, children: [
      { path: 'shift-rotation', component: ShiftRotationComponent },
      { path: 'shift-setup', component: ShiftComponent },
    ]
  },
  { path: 'shift/shift-setups', component: ShiftSetupComponent },
  { path: 'shift/shift-setups/:id', component: ShiftSetupComponent },
  { path: 'shift/shift-rotation-setup', component: ShiftRotationSetupComponent },
  { path: 'shift/shift-rotation-setup/:id', component: ShiftRotationSetupComponent },



  {
    path: 'separation', component: SeperationComponent, children: [
      { path: 'separation-policy', component: SeperationPolicyComponent, },
      { path: 'reasons-list', component: ReasonListComponent, },

    ]
  },
  { path: 'separation/separation-policy-setup', component: SeperationPolicySetupComponent },
  { path: 'separation/separation-policy-setup/:id', component: SeperationPolicySetupComponent },
  // { path: 'live-location-policy', component: LocationPolicyComponent },
  // { path: 'live-location-policy/live-location-policy-setup', component: LocationPolicySetupComponent },
  { path: 'challan-settings', component: ChallanSettingsComponent },
  { path: 'challan-settings-setup', component: ChallanSettingsSetupComponent },
  { path: 'challan-settings/challan-settings-setup/:pf', component: ChallanSettingsSetupComponent },
  { path: 'challan-settings/challan-settings-setup/:pf/:id', component: ChallanSettingsSetupComponent },
  { path: 'challan-settings/challan-settings-setup/:test/:esi', component: ChallanSettingsSetupComponent },
  { path: 'challan-settings/challan-settings-setup/:esi/:id', component: ChallanSettingsSetupComponent },
  { path: 'location-group', component: LocationGroupComponent },
  { path: 'location-group/location-group-setup', component: LocationGroupSetupComponent },
  { path: 'location-group/location-group-setup/:id', component: LocationGroupSetupComponent },
  { path: 'advance-policy', component: AdvancePolicyComponent },
  {
    path: 'policy', component: ExpenseComponent, children: [
      { path: 'expense-category', component: ExpenseCategoryComponent },
      { path: 'expense-policy', component: ExpensePolicyComponent },
    ]
  },
  { path: 'policy/expense-policy-setup', component: ExpensePolicySetupComponent },
  { path: 'policy/expense-policy-setup/:id', component: ExpensePolicySetupComponent },
  { path: 'advance-policy/advance-policy-setup', component: AdvancePolicySetupComponent },
  { path: 'advance-policy/advance-policy-setup/:id', component: AdvancePolicySetupComponent },
  { path: 'expense-settings', component: ExpenseSettingsComponent },
  { path: 'expense-settings/expense-settings-setup', component: ExpenseSettingsSetupComponent },
  { path: 'expense-settings/expense-settings-setup/:id', component: ExpenseSettingsSetupComponent },
  // { path: 'asset-policy', component: AssetPolicyComponent },
  { path: 'asset-settings', component: AssetSettingsComponent },
  { path: 'asset-settings/asset-settings-setup', component: AssetSettingsSetupComponent },
  { path: 'asset-settings/asset-settings-setup/:id', component: AssetSettingsSetupComponent },
  {
    path: 'asset-policy', component: AssetPolicyComponent, children: [
      { path: 'asset-category', component: AssetCategoryComponent, },
      { path: 'asset-sub-category', component: AssetSubCategoryComponent, },]
  },
  { path: 'short-leave', component: ShortLeavePolicyComponent },
  { path: 'short-leave/short-leave-policy-setup', component: ShortLeavePolicySetupComponent },
  { path: 'short-leave/short-leave-policy-setup/:id', component: ShortLeavePolicySetupComponent },

  { path: 'letter-template', component: LetterTemplateComponent },
  { path: 'letter-template/letter-template-setup', component: LetterTemplateSetupComponent },
  { path: 'letter-template/letter-template-setup/:id', component: LetterTemplateSetupComponent },

  { path: 'confirmation-policy', component: ConfirmationPolicyComponent },
  { path: 'confirmation-policy/confirmation-policy-setup', component: ConfirmationPolicySetupComponent },
  { path: 'confirmation-policy/confirmation-policy-setup/:id', component: ConfirmationPolicySetupComponent },
  { path: 'home-page', component: QuickLinksComponent },

  { path: 'notifications', component: NotificationsComponent },
  { path: 'shift-allowance', component: ShiftAllowancePolicyComponent },
  { path: 'shift-allowance/shift-allowance-setup', component: ShiftAllowancePolicySetupComponent },
  { path: 'shift-allowance/shift-allowance-setup/:id', component: ShiftAllowancePolicySetupComponent },


])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
