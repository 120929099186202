<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Leave Year End</h3>
      <p class="text-helper mb-md-0">On this page, you can perform the leave year end process for your employees.
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:16.625rem;">
          <app-searchbar #search [searchlist]='searchListData' [searchplaceholder]="searchKey" [isLoading]="sLoading"
            (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchLeave($event)"></app-searchbar>
        </div>
        <ng-select class="form-ngselect" [(ngModel)]="yearSelection" style="width: 9rem;" (change)="changeYear($event)" [clearable]="false">
          <ng-option *ngFor="let yData of yearRange" [value]="yData">{{yData}}</ng-option>
        </ng-select>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="leaveYearEnd = true; bulkForm.reset()"
          *ngIf="permissions?.a">bulk year
          end process</button>
      </div>
    </div>
  </div>
  <!-- No data found -->
  <div *ngIf="noData && !loader;else all_data" class="row " style="height: calc(100vh - 11rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg8.png'" [noDataText]="'Leave year-end process not initiated'"
      [noDataPara]="'Wrap up the year with smooth leave management. Review and finalize leave balances with ease.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <!-- Loader -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6" [cardType]="'card17'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="!loader">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let item of leaveData; let i = index">
          <div class="card card-custom1 card-hover" (click)="policyData(item?.id,item?.enable_process_year_end)">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section">
                <div class="logo-img {{item?.leave_policy_details?.color_code}}">
                  {{this.appService.getFirstChar(item?.leave_policy_details?.name,0)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 text-trim tips">{{item?.leave_policy_details?.name}}</h6>
                  <span class="tips-helper">{{item?.leave_policy_details?.name}}</span>
                  <div class="fs-12 fw-500 text-light-400 text-trim">
                    {{item?.leave_type_details?.leave_name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-16 gap-8 flex-column ">
              <div class="d-flex justify-content-between w-100">
                <span class="fs-10 text-light-400">PROCESSED DATE:</span>
                <span class="fs-12 ">{{item?.processed_date==null ?
                  '-':this.appService.dateFormatDisplay(item?.processed_date)}}</span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <span class="d-flex gap-8 align-items-center">
                  <span class="fs-12">Completed </span>
                  <span class="fs-14 fw-600 text-success">{{item?.completed}}</span>
                </span>
                <span class="d-flex gap-8 align-items-center">
                  <span class="fs-12">Pending </span>
                  <span class="fs-14 fw-600 text-warning">{{item?.pending}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Loader updations -->
      <ng-container *ngIf="infinityLoader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card17'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='leaveYearEnd === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Bulk Year End Process</h5>
      <button class="btn-reset text-light-600" (click)="cancelSidePanel()">
        <i class="icon-close-lg fs-16"></i></button>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <app-view-loader *ngIf="vLoader"></app-view-loader>
      <ng-container *ngIf="!vLoader">
        <div class="container-fluid p-32" [formGroup]="bulkForm">
          <div class="row row-16 ">
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">LEAVE YEAR END</p>
            </div>
            <div class="col-12">
              <div role="alert" class="alert alert-warning py-10 show">
                <p class="mb-0 fs-13">Employees with pending leave requests will not be processed.
                  Individually send the reminder in order to take the necessary actions on the requests.
                </p>
                <div class="btn-close pointer" (click)="closeAlert()"></div>
              </div>
            </div>
            <div class="col-12 mt-0">
              <label for="" class="form-label">Policy</label>
              <ng-select formControlName="leave_map_ids"
                placeholder="{{this.messageService.selectplaceholddisplay('leave policy')}}" [items]="newBulkData"
                [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                bindLabel="leave_type_details" [closeOnSelect]="false" bindValue="id" class="multiselect">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                    {{ item.leave_policy_details.name }}, {{ item.leave_type_details.leave_name }}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngIf="bulkForm.value.leave_map_ids.length">
                    <span class="ng-value-label">{{bulkForm.value.leave_map_ids.length}} leave policy selected.</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{ ((item.leave_policy_details.name.length + item.leave_type_details.leave_name.length) > 15 ? item.leave_policy_details.name+', '+item.leave_type_details.leave_name.slice(0, 5) + '..' : item.leave_policy_details.name+', '+item.leave_type_details.leave_name) }}
                </ng-template>
              </ng-select>
            </div>
            <div class="col-12">
              <ul class="selected-values">
                <li class="ng-value" *ngFor="let item of bulkForm.value.leave_map_ids, let m =index">
                  <ng-container *ngFor="let insideitem of leaveData, let k =index">
                    <span
                      class="ng-value-label"
                      title="{{ insideitem.leave_policy_details.name }}, {{ insideitem.leave_type_details.leave_name }}"
                      *ngIf="insideitem['id'] === item">
                      {{ ((insideitem?.leave_type_details.leave_name.length + insideitem?.leave_policy_details.name.length) > 15
                          ? insideitem?.leave_policy_details.name + ', ' + insideitem?.leave_type_details.leave_name.slice(0, 5) + '..'
                          : insideitem?.leave_policy_details.name + ', ' + insideitem?.leave_type_details.leave_name) }}
                    </span>
                  </ng-container>
                  <span class="ng-value-icon right" aria-hidden="true" (click)="clear(m)"><i
                      class="icon-close"></i></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <footer class="side-panel-footer" *ngIf="!vLoader && permissions?.a">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        [disabled]="isValid()" (click)="processYearEnd(0,'bulk')">
        Process year end
      </button>
    </footer>
  </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="vLoader"></app-view-loader>
        <ng-container *ngIf="!vLoader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">{{viewData?.leave_policy_details?.name}}</p>
            <a class="link-primary fs-14 fw-500 ms-auto"
              routerLink="/leave-year-end/leave-year-end-details/{{viewData?.id}}/{{yearSelection}}">
              View and download details
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Leave end year details</span></div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Policy name</div>
                    <div class="detail-desc">{{viewData?.leave_policy_details?.name}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Leave type</div>
                    <div class="detail-desc">{{viewData?.leave_type_details?.leave_name}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">No. of leaves to be elapsed</div>
                    <div class="detail-desc">{{viewData?.leaves_to_be_elapsed}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">No. of leaves to be carry forwarded</div>
                    <div class="detail-desc">{{viewData?.leaves_to_be_carryforward}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">No. of leaves to be encashed</div>
                    <div class="detail-desc">{{viewData?.leaves_to_be_encash}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-24 mb-24" *ngIf="viewData?.pending_requests !=0">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-24">
                <div class="row row-24 ">
                  <div class="col-12 d-flex">
                    <span class="title">LEAVE REQUESTS</span>
                    <a class="link-primary fs-14 fw-500 ms-auto"
                      routerLink="/leave-year-end/pending-leave-request/{{viewData?.leave_policy}}/{{viewData?.leave_type}}/{{yearSelection}}">
                      View details
                    </a>
                  </div>
                  <div class=" col-12">
                    <div class="detail-title">Pending requests </div>
                    <div class="detail-desc">{{viewData?.pending_requests}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer" *ngIf="!vLoader && permissions?.a">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="!processYear"
        (click)="processYearEnd(viewData?.id,'view')">
        Process year end
      </button>
    </footer>
</div>

<div  class="modal  modal-alert {{downloadPanel == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title">Leave Year End Pending Employee</h4>
          <button class="btn-reset text-light-600" (click)="closePanel()">
            <i class="icon-close-lg fs-16"></i></button>
          </div>
          <div class="modal-body">
              <p class="mb-0  d-flex align-items-baseline">Leave year end successfully processed for the selected leave policies. Leave year end is not processed for employees with pending leave requests.</p>
          </div>
          <div  class="modal-footer">
              <button type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadReport();">Download</button>
              <!-- <button type="button" class="btn btn-outline-primary btn-sm">Close</button> -->
          </div>
      </div>
  </div>
</div>
