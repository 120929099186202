<!-- <div class="container-fluid px-32 py-24"> -->
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>

      <button *ngIf="from!='behalf'" class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
        (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Expense Report</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
        <div class="d-flex align-items-center ms-auto gap-8">
          <span class="badge px-16 ms-auto fs-14 badge-warning py-1" *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
          <span class="badge px-16 ms-auto fs-14 badge-success py-1" *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
          <span class="badge px-16 ms-auto fs-14 badge-danger py-1" *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
          <span class="text-light-400">|</span>
          <span class="link-primary fs-14 text-nowrap"  (click)="requestViewStatus()">View Status</span>
        </div>
        </ng-container>
        <span *ngIf="requestitem?.request_data?.status == 'Invalid'" class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <span class=" badge-custom {{this.inboxservice.getColor(requestitem.request_type_color_code)}}">Expense Report</span>
      <span *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
        class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <ng-container  *ngIf="from!='behalf'">

      <app-progressbar [requestitem]="requestitem"></app-progressbar>
      <div class="col-12">
        <p class="mb-0 fs-14" [innerHTML]="requestitem?.message"> </p>
      </div>
    </ng-container>

    <div class="col-12">
      <div class="card details-card px-24 pb-24 pt-16">
        <div class="row row-16 ">
          <div class="col-12 align-items-center d-flex">
            <p class="title mb-8">{{requestitem?.requests?.expense_report_data?.name}}</p>
          </div>
          <div class="col-12">
            <div class="card details-card p-16">
              <div class="row row-16">
                <div class=" col-12">
                  <div class="detail-title">Report Name</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_report_data?.name}}</div>
                </div>
                <div class=" col-12">
                  <div class="detail-title">Business Purpose</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_report_data?.business_purpose!=""?requestitem?.requests?.expense_report_data?.business_purpose:"--"}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">From Date</div>
                  <div class="detail-desc">{{appService.dateFormatDisplay(requestitem?.requests?.expense_report_data?.from_date)}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">To Date</div>
                  <div class="detail-desc">{{appService.dateFormatDisplay(requestitem?.requests?.expense_report_data?.to_date)}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Total Amount </div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_report_data?.total_amount| currency :
                    requestitem?.requests?.currency}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Advance Amount </div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_report_data?.total_advance| currency :
                    requestitem?.requests?.currency}}</div>
                </div>
                <div class="col-sm-8 col-12">
                  <div class="detail-title">Non Reimbursible Amount </div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_report_data?.non_reimbusursable_amount| currency :
                    requestitem?.requests?.currency}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Amount to be Reimbursed</div>
                  <div class="detail-desc">{{requestitem?.requests?.expense_report_data?.to_reimbursed| currency :
                    requestitem?.requests?.currency}}</div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card ">
              <div class="card-body pb-0 pt-8">
                <nav class="nav nav-custom   px-0 shadow-0">
                  <a class="nav-link {{activeMenu=='Expense'?'active':''}}" (click)="activeMenu='Expense';page=1">Expense</a>
                  <a class="nav-link {{activeMenu=='Advances'?'active':''}}" (click)="activeMenu='Advances';page=1">Advances & Refunds</a>
                </nav>
              </div>
              <div class="card-body ">
                <div class="row row-16">
                  <div class="col-12">
                    <div class="card card-c2">
                      <div class="table-responsive radius-4">
                        <table *ngIf="activeMenu=='Expense'" class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                          <thead>
                            <tr>
                              <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                              <th class="fw-600 text-uppercase" scope="col">Expense date</th>
                              <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                              <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                              <th class="fw-600 text-uppercase" scope="col">Report name</th>
                              <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                            </tr>
                          </thead>
                          <tbody class="cell-16 ">
                            <ng-container *ngIf="requestitem?.requests?.expense_report_data?.expense_receipts?.length == 0">
                              <tr>
                                <td colspan="6">
                                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                                </td>
                              </tr>
                            </ng-container>
                            <ng-container *ngFor="let rec of $any(requestitem?.requests?.expense_report_data?.expense_receipts | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                              <tr [class]="rec?.is_expired ? 'td-alert' : ''">
                                <td>
                                  <a class="link-primary1" (click)="detailedReceiptView(rec?.id)">{{rec.number}}</a>

                                  <i *ngIf="rec?.is_expired" class="fs-14 ms-8 icon-info-circle text-danger" ngbPopover="Expired" popoverClass="popover-default popover-info " container="body" triggers="hover"></i>
                                </td>
                                <td><span class="text-nowrap">{{appService.dateFormatDisplay(rec.expense_date)}}</span></td>
                                <td>{{rec.category_name}}</td>
                                <td>{{rec.amount| currency : requestitem?.requests?.currency}}</td>
                                <td>{{requestitem?.requests?.expense_report_data?.name}}</td>
                                <td>
                                  <ng-container *ngIf="rec?.attachments?.length != 0">
                                    <span class="link-primary"><i class="icon-eye fs-18 " (click)="viewAttachmentFunction(ind,rec?.id)"></i></span>
                                  </ng-container>
                                  <ng-container *ngIf="rec?.attachments?.length == 0">--</ng-container>
                              </td>
                              </tr>

                            </ng-container>
                          </tbody>
                        </table>
                        <table *ngIf="activeMenu=='Advances'" class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                          <thead>
                            <tr>
                              <th class="fw-600 text-uppercase" scope="col">Advance number</th>
                              <th class="fw-600 text-uppercase" scope="col">Date</th>
                              <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                              <th class="fw-600 text-uppercase" scope="col">Dispensed AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody class="cell-16 ">

                            <ng-container *ngIf="requestitem?.requests?.expense_report_data?.expense_advance?.length == 0">
                              <tr>
                                <td colspan="4">
                                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                                </td>
                              </tr>
                            </ng-container>
                            <ng-container *ngFor="let rec of $any(requestitem?.requests?.expense_report_data?.expense_advance | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                              <tr>
                                <td><a class="link-primary1"  (click)="viewAdvanceFunction(rec?.id)">{{rec.number}}</a></td>
                                <td><span class="text-nowrap">{{appService.dateFormatDisplay(rec.expense_date)}}</span></td>
                                <td>{{rec.amount| currency : requestitem?.requests?.currency}}</td>
                                <td>{{rec.dispansed_amount| currency : requestitem?.requests?.currency}}</td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <ngb-pagination *ngIf="requestitem?.requests?.expense_report_data?.expense_receipts?.length>pageSize" class="d-flex justify-content-end"
                    [collectionSize]="requestitem?.requests?.expense_report_data?.expense_receipts?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>

                    <ngb-pagination *ngIf="requestitem?.requests?.expense_report_data?.expense_advance?.length>pageSize" class="d-flex justify-content-end"
                      [collectionSize]="requestitem?.requests?.expense_report_data?.expense_advance?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
                      [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container [formGroup]="form" *ngIf="!requestitem?.achieved_status">
      <div class="col-12">
          <textarea name="" id="" rows="3" placeholder="Comment here..." formControlName="comment"
              class="form-control my-12 textarea-comment"
              [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
          <div *ngIf="f.comment.errors" class="invalid-feedback">
              <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
              </div>
          </div>
      </div>
      <div class="col-12 d-flex gap-16">
          <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
              (click)="confirm('Rejected')" [disabled]="this.activityService.SaveClick">Decline</button>
          <button class=" btn btn-primary text-uppercase btn-sm fw-400"
              (click)="confirm('Approved')" [disabled]="this.activityService.SaveClick">Confirm</button>
      </div>
  </ng-container>

  </div>
<!-- </div> -->

<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header><app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem?.request_data?.id" (closePanel)="viewstatusPanel=false"></app-view-status>
    </form>
  </div>
</ng-container>

<app-view-expense-receipt *ngIf="viewReciept" [from]="'inbox'" [viewReciept]="viewReciept" [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData" [currency]="requestitem?.requests?.currency" (closeView)="viewReciept  = false"></app-view-expense-receipt>

<app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [viewdata]="viewdata" [advanceid]="advanceid" [viewLoader]="viewLoader" [currency]="requestitem?.requests?.currency" [from]="'inbox'"  (panelclose)="viewAdvance=false"> </app-emp-advance-details-view>

<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'expenseReportRequestView'" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId" (closeViewAttachment)="viewAttchments = false" ></app-view-multiple-attachments>
