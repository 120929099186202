import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-find-address',
  templateUrl: './find-address.component.html',
  styleUrls: ['./find-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindAddressComponent implements OnInit {
  @Input() lat: any;
  @Input() lng: any;
  @Input() location: any;
  @Input() class: any;
  @Input() from:any
  @Input() id:any
  @Output() saveLocation:any = new EventEmitter();
  latlng:any
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.latlng =  this.lat+", "+this.lng
    if (navigator.geolocation && this.location== null && this.from=='sidebar') {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(this.lat, this.lng);
      let request = { location: latlng };
      let result = ''
      geocoder.geocode(request, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          let i = 0
          while(result == '' && results.length>i){
            if (isNaN(parseInt(results[i].formatted_address, 10))) {
              result = results[i].formatted_address;
            }
            i++
          }
          // let 
          this.saveLocation.emit({location:result,id:this.id})
          if (result != null) {
            this.location = result  
            this.cd.detectChanges()
          } else {
            this.location = this.lat+", "+this.lng
          }
        }else {
          this.location = this.lat+", "+this.lng
        }
      });
    }
  }


}
