<div class="container-fluid p-24 "  *ngIf="permissions">

  <div class="row mb-16 ">
      <div class="col-md-4 col-lg-6">
          <h3>Branch</h3>
          <p class="text-helper mb-md-0">A branch is a site of a company in a specific area. Include a list of your company's branches.</p>
      </div>
      <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end  gap-16 ">

              <div class="" style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='departmentsearch' (searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchBranch($event)" [isLoading]="isLoading"></app-searchbar>
              </div>
                <button class="btn-square btn btn-secondary" (click)="filterpanel = true" placement="bottom-left">
                    <i class="icon-filter-left fs-13"></i>
                </button>
              <!-- <div ngbDropdown class="d-inline-block">
                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13 "></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-end" >
                   <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';selectItemList('')">All</button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';selectItemList('true')">Active </button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';selectItemList('false')">Deleted</button>
                </div>
             </div> -->
              <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addBranch($event)"><i
                      class="icon-plus"></i>Add
                  Branch</button>
          </div>

      </div>
  </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16 " *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg53.png'" [noDataText]="'Branches are not configured yet.'"
      [noDataPara]="'Click \'Add Branch\' to include and manage your company’s branches.'" [hasPermission]="false">
    </app-nodata-view>
  </div>
    <ng-template #all_data>
      <div  class="row row-16 " infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [fromRoot]="true"
        (scrolled)="onScrollDown()">
        <ng-container *ngIf="loader==false">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let branch of branches,let i = index">
              <div class="card card-custom1 card-hover">
                  <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex view-section" (click)="viewbranch(branch.id)">
                      <div class="logo-img {{branch.color_code}}">{{ this.appService.getFirstChar(branch.name,2) }}</div>
                      <div class="overflow-hidden">
                        <h6 class="mb-0 tips">{{ branch.name }}</h6>
                        <span class="tips-helper">{{ branch.name }}</span>
                          <span class="fs-10 text-uppercase fw-500 {{(branch.is_active == true) ? 'text-success' : 'text-danger'}}">{{(branch.is_active
                              == true) ? 'Active' : 'Deleted'}}</span>
                      </div>
                    </div>

                      <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="branch.is_active && (permissions.e || permissions.d)">
                        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                            ngbDropdownToggle>
                            <i class="icon-three-dots-vertical "></i>
                        </button>
                        <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
                            <button *ngIf="permissions.e" ngbDropdownItem  (click)="editBranch(branch.id,branch.is_active)" >
                                <i   class="icon-pencil-square me-1"></i> Edit
                            </button>
                            <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletebranchfn(branch.id)">
                                <i class="icon-trash  me-1"></i> Delete
                            </button>
                        </div>
                    </div>

                  </div>
              </div>
          </div>
        </ng-container>

         <!-- Loader updations -->
         <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
      <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

  <!-- view -->
  <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg "></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32  py-24 ">
              <!-- View loader start -->
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <!-- View loader end -->
              <ng-container *ngIf="!loader1">
                <div class="d-flex mb-16 ">
                    <p class="text-uppercase fw-500 mb-0"> Branch </p>
                    <a class="link-primary fs-14  d-flex align-items-center ms-auto"  (click)="editBranch(branchid,branchStatus)" *ngIf="branchStatus && (permissions.e)">
                        <i   class="icon-pencil-square me-1"></i> Edit
                    </a>
                </div>
                <div class="row row-16  mb-24">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24  py-16 ">
                            <div class="row row-24 " >
                                <div class="col-12 "><span class="title">BRANCH DETAILS</span></div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                    <div class="detail-title">Branch name</div>
                                    <div class="detail-desc">{{branchname}}
                                    </div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Status</div>
                                  <div class="detail-desc">
                                    <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="branchStatus">ACTIVE</span>
                                    <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="branchStatus==false">DELETED</span>
                                  </div>
                              </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Country</div>
                                  <div class="detail-desc">{{country_name}}
                                  </div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                <div class="detail-title">State</div>
                                <div class="detail-desc">{{state_name}}
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>

                </div>
              </ng-container>
            </div>
        </div>
    </form>
  </div>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
            <h5><span [innerHTML]="header"></span> Branch</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid px-32  py-24 ">
            <!-- Edit loader start -->
            <app-edit-loader *ngIf="loader1" ></app-edit-loader>
            <!-- Edit loader end -->
            <ng-container *ngIf="!loader1" >
              <p class="text-uppercase fw-500 mb-12 "><span [innerHTML]="header"></span> BRANCH DETAILS</p>
              <div class="row row-12  ">
                  <input type="text" value="{{ editId }}" formControlName="id" id="id" hidden>
                  <div class="col-12 col-md-6 form-row">
                      <label for="name" class="form-label required">Branch name </label>
                      <input type="text"  class="form-control" formControlName="name" id="name" placeholder="{{this.messageService.placeholderdisp('branch name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist }" (keyup)="keyUp()">
                      <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                        <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                        <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      </div>
                  </div>
                  <div class="col-12 col-md-6 form-row">
                    <label for="country" class="form-label required">Country </label>

                    <ng-select class="form-ngselect"
                                 (change)="changeCountryFun()"
                                formControlName="country"
                                [ngClass]= "{ 'is-invalid': (submitted || f.country.touched) && f.country.errors }"
                                name=""  id="country"
                                ng-model="selectedState"
                                placeholder="{{this.messageService.selectplaceholddisplay('country')}}"  [clearable]="false">
                        <ng-option *ngFor="let country of countrylistdata" [value]="country.id">{{ country.name }}</ng-option>
                        </ng-select>
                    <div *ngIf="(submitted || f.country.touched) && f.country.errors" class="invalid-feedback">
                      <div *ngIf="f.country.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 form-row">
                      <label for="state" class="form-label required">State </label>

                      <ng-select [readonly]="!CountrySelect" class="form-ngselect"
                                formControlName="state"
                                [ngClass]="{ 'is-invalid': (submitted || f.state.touched) &&  f.state.errors }"
                                name="state"  id="state"
                                placeholder="{{this.messageService.selectplaceholddisplay('state')}}">
                        <ng-option *ngFor="let state of statelistdata" [value]="state.id">{{state.name}}</ng-option>
                        </ng-select>
                      <div *ngIf="(submitted || f.state.touched) && f.state.errors" class="invalid-feedback">
                        <div *ngIf="f.state.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                      </div>
                  </div>
              </div>
            </ng-container>
          </div>
      </div>
      <footer class="side-panel-footer gap-32 " *ngIf="!loader1">
        <button class="btn btn-outline-danger text-uppercase btn-sm" [disabled]=!updateClass type="button" *ngIf="deleteButtonEnable && permissions.d" (click)="deleteToggle = true">Delete</button>
        <button *ngIf="(addEditBtn=='Add' && permissions.a) || (addEditBtn=='Save' && permissions.e)" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='!updateClass || !checkoutForm.valid' type="submit" ><span [innerHTML]="addEditBtn"></span></button>
      </footer>
    </form>
</div>
<!-- slider section end -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16 ">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Branch?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Branch')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm" [disabled]="deleteClicked" (click)="deleteBranch()">Delete</button>
      </div>
    </div>
  </div>
</div>
<!-- Notification start -->
<!-- <div class="notification notification-success fadeInLR"  [class.show]='notification_success === true'>
  <header class="notification-title">
      Success
      <button class="btn-close"  (click)="notification_success = false"></button>
  </header>
  <header class="notification-body">
     Branch {{msg}} successfully.
  </header>
</div> -->
<!-- Notification end -->

  <app-common-filter [commonFilter]="branchFilter" [filterpanel]="filterpanel" (filterPanel)="panel($event)"  (filterDone)="filterDone($event)" (resetFilter)="resetFilter()" ></app-common-filter>
