<div class="config-layout">
  <header class="config-header">
    <h5 class="m-0 text-trim w-50">Loans and advances request<span *ngIf="from=='behalf'" title="{{requestFor}}">
        {{' of '+ requestFor}}
      </span>
    </h5>    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class="config-sidebar ">
    <ul ngbNav #nav="ngbNav" class="steps gap-24" [(activeId)]="activeTab" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success': loanRequestForm['controls'].loanRequest.valid}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Loans and advances request</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-loan-request [loanTypesDropdown]="loanTypesDropdown" [formGp]="loanDetailsForm" (submitMethod)="submitOne($event)" [currency]="currency" [from]="from"></app-loan-request>
        </ng-template>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step" [class]="{'success' : loanRequestForm['controls'].loanRequest.valid && tab2touched}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Summary</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-loan-summary [formGp]="loanDetailsForm" [currency]="currency" [loanTypeDetails]="loanTypeDetails" (submitMethod)="submitForm($event)"></app-loan-summary>
        </ng-template>
      </li>

    </ul>
  </aside>
  <main class="config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
