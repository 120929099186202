import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { FormBuilder,Validators } from '@angular/forms';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-confirmation-req-inbox',
  templateUrl: './confirmation-req-inbox.component.html',
  styleUrls: ['./confirmation-req-inbox.component.scss']
})
export class ConfirmationReqInboxComponent implements OnInit {
  @Input() requestitem: any;
  @Input() from: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  alertToggle                     = false;
  terminateToggle                 = false;
  probationToggle                 = false;
  viewDetail                      = false;
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewMapPanel          : boolean = false;
  confirmationDetails             = false;
  confirm                         = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  regenerate = false;
  Viewdata:any=[];
  loader1 =false;
  fromViewStatus                  = '';
  sendReminder:any
  form = this.fb.group({
    extension_period: ['',[Validators.required,NumberpatternValidator(),Validators.max(365),Validators.min(1)]],
    comment        : ['', [Validators.required,Validators.maxLength(500)]],
  })

  formterminate = this.fb.group({
    comment        : ['', [Validators.required,Validators.maxLength(500)]],
  })

  formconfirm = this.fb.group({
    comment        : ['', [Validators.required,Validators.maxLength(500)]],
  })

  approverdata:any=[];
  prevdata:any=[];
  currentdata:any=[];
  disablebtn=false;
  disablebtnterminate = false;
  saveClick = false
  constructor(public inboxservice: InboxService,
    private investmentService:InvestmentDeclerationService,
    public messageService: MessageService,
    public appService: AppService,
    private fb: FormBuilder,
    private activityService:ManageActivityService
    ) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
     this.viewapprover();

  }

  ngOnchanges(): void {

  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  requestViewStatus(){
    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.fromViewStatus     = ( res?.request_type == 'Employee Profile' && res?.category == 'document') ? 'employee-inbox' : '';
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

  get f() {return  this.form.controls; }
  get ft() {return  this.formterminate.controls; }
  get fc() {return  this.formconfirm.controls; }

  Extendfn(){
    let extendlimit= this.requestitem?.requests?.confirmation_policy?.max_extension - this.requestitem?.requests?.probation_extended_count;
    if(extendlimit == 0 || extendlimit < 0){
      this.alertToggle =true;
    }else{
      this.probationToggle=true;
      this.form.get('extension_period')?.setValue(this.requestitem?.requests?.confirmation_policy?.extension_period);
    }
  }

  Extend(){
    this.disablebtn = true;
    this.probationToggle=false;
    let data:any=[]
    data = {
      'extension_period':this.form.controls['extension_period']?.value,
      'comment':this.form.controls['comment']?.value,
      'approved_status':'Extended Probation'
    }
   this.updateInbox(data,'probationToggle')


  }

  confirmemp(){

    this.disablebtn = true;
    let data:any=[]

    data = {
      'comment':this.formconfirm.controls['comment']?.value,
      'approved_status':'Confirmed'
     }

   this.updateInbox(data,'confirm')
  }
  updateInbox(data:any,type:any){
    if(this.from=='behalf'){
      data['request_id']      = this.requestitem?.request_data?.id
      this.successEmitter.emit(data);
      if(type=='probationToggle')
        this.probationToggle = false
      else if(type=='confirm')
        this.confirm = false
      else
        this.terminateToggle = false
    }
    else{
      this.inboxservice.updateInboxItem(this.requestitem['id'],data).subscribe((res: any)=>{
        this.successEmitter.emit(true);
      })
    }
  }
  terminate(){
    this.disablebtnterminate = true;
    this.terminateToggle = false;
    let data:any=[]

    data = {
      'comment':this.formterminate.controls['comment']?.value,
      'approved_status':'Terminated'
    }
    this.updateInbox(data,'terminateToggle')

  }

  viewapprover(){

    let id = this.from=='behalf'?this.requestitem?.request_data?.id:this.requestitem['id']
    if(this.from=='behalf'){
      this.activityService.approverhistory(id).subscribe((res:any)=>{
        this.setApproverData(res)
      })
    }
    else{
      this.inboxservice.approverhistory(id).subscribe((res: any)=>{
        this.setApproverData(res)
      })
    }
  }
  setApproverData(res:any){
    this.approverdata = res;
    this.approverdata.approved_person_list?.filter((obj :any) => {
      if(obj?.id == this.approverdata?.approved_person){
            this.currentdata.push(obj);
      }else{
            this.prevdata.push(obj);
      }
    })
  }

}
