import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { dayRangeValidator } from 'src/app/@shared/validators/dayrange.validator';

@Component({
  selector: 'app-shift-allowance-policy-configure',
  templateUrl: './shift-allowance-policy-configure.component.html',
  styleUrls: ['./shift-allowance-policy-configure.component.scss']
})
export class ShiftAllowancePolicyConfigureComponent implements OnInit {
   @Output() submitmethod        = new EventEmitter();
   @Input()disabled              = false;
   @Input()submitted:boolean=false;
   @Input()shiftslist:any=[];
   @Input()formGp : FormGroup    = this.fb.group({
      set_shiftallowance_workday: [false, [Validators.required]],
      min_shiftallowance_hours_workday : [null],
      set_shiftallowance_weekoff: [false, [Validators.required]],
      min_shiftallowance_hours_weekoff : [null],
      set_shiftallowance_holiday : [false, [Validators.required]],
      min_shiftallowance_hours_holiday : [null],
      set_shiftallowance_half_workday : [false, [Validators.required]],
      min_shiftallowance_hour_half_workday : [null],
      allowance_shift_map: this.fb.array([])
   });
    editshifts:any=false;
    editdata:any=[];
    activeIndex:any = -1;

  constructor(
    public messageService : MessageService,
    public appservice:AppService,
    private fb:FormBuilder 
  ) { }

  ngOnInit(): void {   
    this.addshift();    
  }

  shifts(): FormArray {
    return this.formGp.get('allowance_shift_map') as FormArray;
  }

  newshift(inp:any): FormGroup {
    return this.fb.group({
      shift_name              : inp.shift_name,
      shift_code              : inp.shift_code,
      shift                   : inp.id,
      shift_allowance_mapping: this.fb.array([])
    });
  }

  addshift() {
    if(this.shifts()?.controls.length==0){
        this.shiftslist.forEach((ctrl: any) => {
          this.shifts().push(this.newshift(ctrl));      
        });
    }else{
      const existingShiftIds = this.shifts()?.value.map((shift: any) => shift.shift);
      this.shiftslist.forEach((shift: any) => {
        if (!existingShiftIds.includes(shift.id)) {
          this.shifts().push(this.newshift(shift));
        }
      });  
      const currentShifts = this.shifts();
      const shiftIdsFromList = this.shiftslist.map((shift: any) => shift.id);
      for (let i =0; i<currentShifts.length; i++) {
        const shiftId = currentShifts.at(i).value.shift;        
        if (!shiftIdsFromList.includes(shiftId)) {
          this.shifts().removeAt(i);
        }
      } 
    }
    for(let i=0;i<this.shiftslist.length;i++){
      if(this.shiftpayrates(i)?.controls?.length==0){
        this.addshiftpayrate(i);
      }
    }
  }

  shiftpayrates(empIndex: number): FormArray {
    return this.shifts()
      .at(empIndex)
      ?.get('shift_allowance_mapping') as FormArray;
  }

  newpayrate(): FormGroup {
    return this.fb.group({
      day_start: ['', [Validators.required,NumberpatternValidator(),Validators.min(1),Validators.max(30)]],
      day_end: ['', [Validators.required,NumberpatternValidator(),Validators.max(31)]],
      pay_rate: ['', [Validators.required,NumberpatternValidator(),Validators.maxLength(10)]]
    }, { validators: dayRangeValidator() });
  }

  addshiftpayrate(empIndex: number) {
    this.shiftpayrates(empIndex).push(this.newpayrate());
  }

  removeshiftpayrate(empIndex: number, payrateIndex: number) {
    this.shiftpayrates(empIndex).removeAt(payrateIndex);
  }

  setRequired(value: boolean,item:any) {
    if(value) {
      this.formGp.get(item)?.setValidators([Validators.required]);
      
    } else {
      this.formGp.get(item)?.clearValidators();
      this.formGp.get(item)?.setValue(null);
    }
      this.formGp.get(item)?.updateValueAndValidity();
  }

  get f() { return this.formGp.controls; }

  addValidation(key:any,key2:any){

  }

  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }

  editshift(shiftslist:any,index:any){  
     this.activeIndex = index;
      this.editshifts=true;
      this.editdata = shiftslist;          
    }
  
  validatedays(shiftindex: any, mapindex: any,key:any) {
   
    // This will trigger the validation for the specific shift pay rate fields.
    const dayStartControl = this.shiftpayrates(shiftindex).at(mapindex).get('day_start');
    const dayEndControl = this.shiftpayrates(shiftindex).at(mapindex).get('day_end');

    this.shiftpayrates(shiftindex).at(mapindex).get(key)?.markAsTouched();

    // Update the validator state
    dayStartControl?.updateValueAndValidity();
    dayEndControl?.updateValueAndValidity();

  }


  Canceledit(){
    this.editshifts=false;
    this.editdata = [];    
    this.activeIndex = -1;
  }

  validateshiftdetail() {
    this.submitted = true;

    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }
 
}


