import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-loan-process-report',
  templateUrl: './loan-process-report.component.html',
  styleUrls: ['./loan-process-report.component.scss']
})
export class LoanProcessReportComponent implements OnInit {

  @Input() loanProcessForm: any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callMonthlyTDS: any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() DesignationDD : any
  @Input() ouFilterForm: any
  @Input() Years : any;
  @Input() resDataFilter:any;

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  yearList : any        = [];
  hasChangeCount:any  = 0;
  changedYear     = Number(new Date().getFullYear());
  selectedYear    = Number(new Date().getFullYear());
  currentYear               = new Date().getFullYear();
  placeholderData : any
  year_placeholder  = 'Select year'
  month_placeholder = 'Select month'
  monthValue : any
  attendListOfMonth:string  = "listOfMonth";
  loanTypeDrop      : any = []
  interestDrop      : any  = [
    {id:"No Interest",name:"No Interest"},
    {id:"Fixed",name:"Fixed"},
    {id:"Reducible",name:"Reducible"}
  ]
  loanStatus         : any  = [
    {id:"Active",name:"Active"},
    {id:"Closed",name:"Closed"},
    {id:"Restructured",name:"Restructured"}
  ]
  requestStatus     : any  = [
    {id:"All",name:"All"},
    {id:"Processed",name:"Processed"},
    {id:"Pending",name:"Pending"}
  ]
  loanTypeLoader : boolean = false
  get f()  { return this.loanProcessForm.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  constructor(
    public appservice:AppService,
    public messageService: MessageService,
    private reportService:ReportsService,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public loanServ : LoanProcessService
  ) { }

  ngOnInit(): void {
    this.dateFormat();
    this.selectAllForDropdownItems(this.loanStatus)
    this.selectAllForDropdownItems(this.requestStatus)
    this.selectAllForDropdownItems(this.interestDrop)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
      this.getLoanType() // Loan Type Dropdown
    }
  }

  getLoanType(){
    this.loanTypeLoader = true
    this.loanServ.listLoanTypes().subscribe((res:any)=>{
      this.loanTypeDrop = res?.body?.data
      this.loanTypeLoader = false
      this.selectAllForDropdownItems(this.loanTypeDrop)
    })
  }

  dateFormat(){
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.dateFormat();
      }, 1000);
    }
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }else if(this.closeControl == 'employment_type'){
      this.closeControl = 'employee_type'
    }
    if(this.closeControl == 'interest_type' || this.closeControl == 'loan_status' || this.closeControl == 'loan_type' || this.closeControl == 'loan_process_status'){
      this.loanProcessForm.get(this.closeControl)?.setValue('');
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitLoanProcess();
  }

  applyBtn : boolean = false

  filterFormData(use:any){
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);

    let loan_status    = this.stringifyCont(this.loanProcessForm.controls.loan_status.value);
    let loan_type    = this.stringifyCont(this.loanProcessForm.controls.loan_type.value);
    let interest_type    = this.stringifyCont(this.loanProcessForm.controls.interest_type.value);
    let loan_process_status = this.stringifyCont(this.loanProcessForm.controls.loan_process_status.value)

    if(use == 'filter'){
      return `loan_process_status=${loan_process_status}&loan_status=${loan_status}&loan_type=${loan_type}&interest_type=${interest_type}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'loan_process_status':this.loanProcessForm.controls.loan_process_status.value,'loan_status':this.loanProcessForm.controls.loan_status.value,'loan_type':this.loanProcessForm.controls.loan_type.value,'interest_type':this.loanProcessForm.controls.interest_type.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  submitLoanProcess(){
    this.submitted      = true;
    let offset = 0
    if (this.loanProcessForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail      = false;
    this.applyBtn = false
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.loanProcessForm.get('loan_process_status')?.reset('');
      this.loanProcessForm.get('loan_type')?.reset('');
      this.loanProcessForm.get('loan_status')?.reset('');
      this.loanProcessForm.get('interest_type')?.reset('');
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
    }
    this.callMonthlyReport.emit({
      reportName : 'Loan Process Details'
    })
  }

  resetPage(){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }

  clearcommonForm(item:any,index:any){
    if(item == "loan_type"){
      let daata = this.loanProcessForm.value.loan_type;
      daata.splice(index, 1);
      this.loanProcessForm.patchValue({
        loan_type: daata
      });
    }else if(item == "interest_type"){
      let daata = this.loanProcessForm.value.interest_type;
      daata.splice(index, 1);
      this.loanProcessForm.patchValue({
        interest_type: daata
      });
    }else if(item == "loan_process_status"){
      let daata = this.loanProcessForm.value.loan_process_status;
      daata.splice(index, 1);
      this.loanProcessForm.patchValue({
        loan_process_status: daata
      });
    }else if(item == "loan_status"){
      let daata = this.loanProcessForm.value.loan_status;
      daata.splice(index, 1);
      this.loanProcessForm.patchValue({
        loan_status: daata
      });
    }
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
