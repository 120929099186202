import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SalaryRevisionService } from 'src/app/@core/services/salary-revision';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { LineChartService } from 'src/app/@core/services/line-chart.service';
import { LineChartComponent } from '@swimlane/ngx-charts';


@Component({
  selector: 'app-salary-revision-history-view',
  templateUrl: './salary-revision-history-view.component.html',
  styleUrls: ['./salary-revision-history-view.component.scss']
})
export class SalaryRevisionHistoryViewComponent implements OnInit{

  constructor(private salaryRevisionService:SalaryRevisionService,
    public appService:AppService,
    public router: Router,
    private lineChartService:LineChartService
    ) { }

  @ViewChild('chart') chart: LineChartComponent | any;
  viewloader                = false;
  legend: boolean           = false;
  showLabels: boolean       = true;
  animations: boolean       = true;
  xAxis: boolean            = true;
  yAxis: boolean            = true;
  showYAxisLabel: boolean   = true;
  showXAxisLabel: boolean   = true;
  xAxisLabel: string        = 'Year';
  yAxisLabel: string        = 'CTC';
  timeline: boolean         = true;
  graph:any                 = []
  tableData:any             = [];
  hisId:any                 = 0;
  empData:any               = []
  colorScheme :any          = {
                                domain: ['#0078CE']
                              };
  appraisalMonth:any        ="";
  permissions:any           = [];
  employemntAlert           = true;
  salaryRevisionDetailsView = false;
  Isalert                   = true;
  showInfo                  = false;
  reqConfirm                = false;
  confirmMsg:any            = '';
  @Input() employeeId:any   = [];
  financialLabels : any[]   = [];
  financialData   : any[]   = [];
  revId:any;
  infoMsg:any;
  @Input()from:any;
  @Input()inboxId:any;
  @Input() buttonActivate:any ;

  ngOnInit(): void {
    this.viewloader = true;
    this.getHistoryDetails()
    this.getTableData()
    this.getemployeeData()

    // this.getPermission();

  }
  // getPermission(){
  //   this.permissions = this.appService.getPermissions('/salary-revision');

  //   if(this.permissions == undefined){
  //     setTimeout(() => {
  //       this.getPermission();
  //     }, 1000);
  //   }
  // }
  showDots(){
    if(this.chart == undefined){
      setTimeout(() => {
        this.showDots();
      }, 1000);
    } else
      this.lineChartService.showDots(this.chart);
  }
  getHistoryDetails(){
    this.salaryRevisionService.getRevisionGraph(this.employeeId,this.from,this.inboxId).subscribe((res: any) => {
      // var data = [];
      this.financialData = []
      this.financialLabels = []
      var last_year;
      // Include last 2 years in Revision Analysis graph
      if(res.body.status=="1"){
        if(res.body.data.length>0){
          last_year = res.body.data[0].year;
          for(let i =0;i<2;i++){
            last_year = i==0?Number(last_year)-2:Number(last_year)+1;
            this.financialData.push(last_year)
            this.financialLabels.push(0)
            // data.push({"name":last_year.toString(),"value":0});
          }
        }

        for(let key of  res.body.data){
          this.financialData.push(key.year)
          this.financialLabels.push(key.ctc)
          // data.push({"name":key.year,"value":key.ctc});
        }

        // this.multi =[
        //   {
        //     "name": "CTC",
        //     "series": data
        //   }
        // ];

      }
    });
    this.showDots();
  }
  getTableData(){
    this.salaryRevisionService.getRevisionTable(this.employeeId,this.from,this.inboxId).subscribe((res: any) => {
    this.tableData =  res.body;

      for(let i=0;i<this.tableData.length;i++){
        if(res.body[i]['approver'].length>0){

          if(res.body[i]['approver'][0]['first_name'] != null && res.body[i]['approver'][0]['first_name'] != undefined && res.body[i]['approver'][0]['first_name'] != '')
             res.body[i]['approver']['approver_full_name'] = res.body[i]['approver'][0]['first_name']+" ";
          if(res.body[i]['approver'][0]['middle_name'] != null && res.body[i]['approver'][0]['middle_name'] != undefined && res.body[i]['approver'][0]['middle_name'] != '')
             res.body[i]['approver']['approver_full_name'] += res.body[i]['approver'][0]['middle_name']+" ";
          if(res.body[i]['approver'][0]['last_name'] != null && res.body[i]['approver'][0]['last_name'] != undefined && res.body[i]['approver'][0]['last_name'] != '')
             res.body[i]['approver']['approver_full_name']   += res.body[i]['approver'][0]['last_name'];


        }
      }

    });
  }
  getemployeeData(){
    this.salaryRevisionService.getEmployeeData(this.employeeId,this.from,this.inboxId).subscribe((res: any) => {

      this.empData    =  res.body.data;
      this.viewloader = false;
      let find_length =  Object.entries(this.empData.last_revision_details).length;
      this.empData['last_revision_length'] = Number(find_length);
    });
  }
  randomClass(min:number, max:number) {
    var ints = Math.floor(Math.random() * (max - min + 1)) + min;
    return "bg"+ints;
  }

  reviseSalary(id:any,msg:any,status:any,processedStatus:any){
    this.revId  = id;
    if(msg=='' && status==false)
      this.router.navigate(['/salary-revision/salary-revision-setup/'+id]);
    else if(status==true){

      this.infoMsg  ='The salary revision request for '+this.empData.employee_details?.first_name+" "+(this.empData.employee_details?.middle_name!=null?this.empData.employee_details?.middle_name:'')+" "+this.empData.employee_details?.last_name+ '('+this.empData.employee_details?.employee_code+')'+' has already been raised and is awaiting for approval.';
      this.showInfo = true;

    }
    else if(processedStatus==true){
      this.confirmMsg = 'You have already revised the salary for this employee and it will be paid out in '+this.empData?.payout_month+' '+this.empData?.payout_year+'. If you are revising again the previous revision will become invalid. Do you want to continue?'
      this.reqConfirm = true;
    }
    else{
      this.infoMsg  ="You cannot revise the salary for this employee. We are waiting for the last revised salary's effective month to run it's payroll.";
      this.showInfo = true;
    }
  }

  closeInfo(bool:any){
  this.showInfo = false;
  }
  confirmRequest(){
    this.router.navigate(['/salary-revision/salary-revision-setup/'+this.revId]);

  }
}
