<form class="">
  <div class="container-fluid p-32 form-container">
      <app-c1-loader *ngIf="loader"></app-c1-loader>
      <div class="row row-16 mb-16" *ngIf="!loader">
          <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0"> REIMBURSEMENT CATEGORY</p>
          </div>
          <div *ngIf="requestedCategoryData?.length == 0; else requestedCategoryDatas"  class="row" style="height: calc(100vh - 15rem);">
            <app-nodata-view class="vstack" [noDataImg]="'bg15.png'" [imgClass]="'img-sm'" [hasPermission]="false">
            </app-nodata-view>
          </div>
          <ng-template #requestedCategoryDatas>
            <form [formGroup]="formGp" class="row row-16">
              <ng-container formArrayName="fbpArray" *ngFor="let item of fbpArrays().controls; let i=index; ">
                <ng-container [formGroupName]="i">
                  <div class="col-12">
                    <ngb-accordion class="custom-accordion3 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                        <ngb-panel id="ngb-panel-1">
                            <ng-template ngbPanelHeader>

                                <button ngbPanelToggle class="accordion-button details-card card-hover card indicater1 indicater-bg px-24 py-16">
                                    <div class="row row-24 w-100 ">
                                        <div class="col-4">
                                            <div class="fw-600 mb-1 tips text-trim text-uppercase fs-14 pe-24">{{fbpArrays().controls[i].get('name')?.value}}</div>

                                            <div class="fs-10 fw-500 text-success">AS OF {{currentMonth}} {{this.appService.getFinancialYear()}}</div>
                                        </div>
                                        <div class="col-4">
                                            <div class="detail-title">Eligible</div>
                                            <div class="detail-desc">{{fbpArrays().controls[i].get('eligible')?.value | currency: currency}}</div>
                                        </div>

                                        <div class="col-4">
                                            <div class="detail-title">Claimed</div>
                                            <div class="detail-desc">{{fbpArrays().controls[i].get('claimed')?.value | currency: currency}}</div>
                                        </div>
                                    </div>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row row-16">
                                    <div class="col-12">
                                        <label for="first_name" class="form-label required">Update Claimed Amount </label>

                                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (fbpArrays().controls[i].get('bill_amount')?.dirty || fbpArrays().controls[i].get('bill_amount')?.touched) &&  fbpArrays().controls[i].get('bill_amount')?.errors}">
                                        <input [ngClass]="{ 'is-invalid': (fbpArrays().controls[i].get('bill_amount')?.dirty || fbpArrays().controls[i].get('bill_amount')?.touched) &&  fbpArrays().controls[i].get('bill_amount')?.errors}" type="text" class="form-control" autocomplete="off" id="bill_amount" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="bill_amount" (keyup)="calculateValidation(i)">
                                        <span>{{currency}}</span>
                                      </div>
                                      <div
                                      *ngIf="(fbpArrays().controls[i].get('bill_amount')?.dirty || fbpArrays().controls[i].get('bill_amount')?.touched) && fbpArrays().controls[i].get('bill_amount')?.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="fbpArrays().controls[i].get('bill_amount')?.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                      <div *ngIf="fbpArrays().controls[i].get('bill_amount')?.errors?.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                      <div
                                        *ngIf="!(fbpArrays().controls[i].get('bill_amount')?.errors?.pattern) && fbpArrays().controls[i].get('bill_amount')?.errors?.max">
                                        {{this.messageService.balanceAmountValidation()}}
                                      </div>
                                    </div>
                                    </div>
                                    <div class="col-12 d-flex">
                                        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid || saveClicked" (click)="reimbursementSave(i)">SAVE</button>
                                    </div>
                                </div>
                                <div class="d-flex flex-column gap-12 fs-14">
                                    <div class="d-flex w-100">
                                        <div class="ms-auto "> </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                  </div>
                </ng-container>
              </ng-container>
            </form>
          </ng-template>


      </div>
  </div>
  <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitReimbursement()">NEXT</button>
  </footer>
</form>
