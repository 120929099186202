<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <p class="text-uppercase fw-500 mb-0">EXPENSE RECEIPTS</p>
      </div>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex justify-content-end  gap-16">
          <button class="btn flex-shrink-0 btn-outline-primary btn-icon btn-add" (click)="getUnReportedData();"><i
              class="icon-plus"></i>Add
            Unreported Expenses </button>
          <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addExpenseData()"><i class="icon-plus"></i>Add
            New Expenses </button>
        </div>
      </div>
    </div>
    <div class="row row-16">

      <div *ngIf="!receiptListLoader && receiptListData?.length == 0" class="col-12">
        <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}">
          <p class="mb-0">Please add atleast 1 receipt to submit the report.
          </p>
          <div class="btn-close" (click)="alertShow='hide'"></div>
        </div>
      </div>

      <app-c1-loader *ngIf="receiptListLoader"></app-c1-loader>

      <ng-container *ngIf="!receiptListLoader">
        <div class="col-12">
          <div class="card card-c2">
            <div class="table-responsive radius-4">
              <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>

                    <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                    <th class="fw-600 text-uppercase" scope="col">EXPENSE Date</th>
                    <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                    <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                    <th class="fw-600 text-uppercase" scope="col">Report name</th>
                    <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                    <th class="fw-600 text-uppercase" scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody class="cell-16 ">

                  <ng-container *ngIf="receiptListData?.length == 0">
                    <tr>
                      <td colspan="7">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngFor='let receipt of $any(receiptListData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize);let i=index;'>
                    <tr [class]="receipt?.is_expired ? 'td-alert' : ''">

                      <td>
                        <a class="link-primary1" (click)="detailedReceiptView(receipt?.id)">{{receipt?.number}}</a>

                        <i *ngIf="receipt?.is_expired" class="fs-14 ms-8 icon-info-circle text-danger" ngbPopover="Expired" popoverClass="popover-default popover-info " container="body" triggers="hover"></i>

                      </td>
                      <td><span class="text-nowrap">{{(receipt?.expense_date == null || receipt?.expense_date == '') ? '--' : this.appService.dateFormatDisplay(receipt?.expense_date)}}</span></td>
                      <td>{{(receipt?.category?.name == null || receipt?.category?.name == '') ? '--' : receipt?.category?.name}}</td>
                      <td>{{(receipt?.amount == null || receipt?.amount == '') ? '--' : (receipt?.amount | currency : currency)}}</td>
                      <td>{{(receipt?.report?.name == null || receipt?.report?.name == '') ? '--' : receipt?.report?.name}}</td>
                      <td>
                        <span *ngIf="(receipt?.attachments == null || receipt?.attachments == '' || receipt?.attachments?.length == 0 )" class="link-primary" (click)="addAttachmentFunction(i,receipt?.id)">Add</span>
                        <span  *ngIf="(receipt?.attachments != null && receipt?.attachments != '' && receipt?.attachments?.length > 0 )" class="link-primary"><i class="icon-eye fs-18 " (click)="viewAttachmentFunction(i,receipt?.id)"></i></span>
                      </td>
                      <td><a class="link-primary1" (click)="removeReceipt(receiptListData.indexOf(receipt),receipt)">Remove</a></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-12" *ngIf="this.appService.filteredcount(receiptListData,searchString)>pageSize">
          <ngb-pagination class="d-flex justify-content-end"
            [collectionSize]="this.appService.filteredcount(receiptListData,searchString)" [(page)]="page" [maxSize]="3"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </div>
      </ng-container>

    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="receiptListData?.length == 0" (click)="changeTab()">NEXT</button>
  </footer>
</form>

<!-- Other components -->
<app-add-unreported-expense *ngIf="unreportedExpense" [from]="from" [unreportedExpense]="unreportedExpense" [unReportedReceiptList]="unReportedReceiptList" [loader]="unReportReceiptLoader" (addreceipt)="addedReceipt($event)" (closeUnReportedReceipt)="closeReceipt()" [currency]="currency"></app-add-unreported-expense>

<app-view-expense-receipt *ngIf="viewReciept" [from]="from" [viewReciept]="viewReciept" [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData" [currency]="currency" (closeView)="viewReciept=false"></app-view-expense-receipt>

<app-add-new-expense *ngIf="addExpense" [from]="from" [addExpense]="addExpense" (saveReceipt)="saveReceipt($event)" (closeAddExpense)="closeAddExpense()" [currentreport]="formGp.controls.id.value"></app-add-new-expense>

<app-upload-multiple-attachments *ngIf="uploadeAttachments" [from]="from" [uploadeAttachments]="uploadeAttachments" [fileType]="'.pdf,.doc,.img'" [receiptId]="receiptId" [finalReceiptAttachment]="finalReceiptAttachment" (closeAttachment)="closeAttachment()" (saveAttachment)="saveAttachment($event)"></app-upload-multiple-attachments>

<app-view-multiple-attachments *ngIf="viewAttchments" [from]="from" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId" [isAttachmentRequired]="isAttachmentRequired" (closeViewAttachment)="closeViewAttachment()" (saveViewAttachment)="saveViewAttachment($event)"></app-view-multiple-attachments>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16 ">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Receipt?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Receipt')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteReceipt()">Delete</button>
      </div>
    </div>
  </div>
</div>
<!-- End -->
