<!-- test start -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewReciept === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel " (click)="viewReciept = false;closeViewModel()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewRecieptLoader"></app-view-loader>
        <ng-container *ngIf="!viewRecieptLoader">

          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Expense recEIpt ({{viewRecieptData?.number}})</p>
            <a *ngIf="from != 'expenseReportRequest'  && from != 'behalf'&& from != 'inbox' && (viewRecieptData?.status=='Unreported')" class="link-primary fs-14 ms-auto" (click)="editRreceipt(viewRecieptData?.id)"><i class="icon-pencil-square me-1"></i>
              Edit</a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">RECEIPT DETAILS</p>
                  </div>
                  <div *ngIf="from != 'expenseReportRequest'  && from != 'behalf'&& from != 'inbox' && viewRecieptData?.report.name==''" class="col-12">
                    <div role="alert" class="alert alert-warning py-10 mb-0 pe-16
                                       show">
                      <p class="mb-0 fs-14 hstack "> <span class="fw-600">Note</span> : Add this
                        expense to a report and submit
                      </p>
                      <span class="ms-auto link-primary1 fw-500" (click)="viewRecieptData?.status=='Expired' ? expiredFunction(viewRecieptData?.expiry_based_on,viewRecieptData?.expiry_period) : editRreceipt(viewRecieptData?.id)">Add to report</span>
                      <!-- <div class="btn-close pointer"></div> -->
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Expense Type </div>
                          <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>{{(viewRecieptData?.expense_type == true) ? 'Milege' : 'Amount' }}
                          </div>
                        </div>
                        <div class="col-sm-4 col-12" *ngIf=" viewRecieptData?.report.name!==''">
                          <div class="detail-title">Report </div>
                          <div class="detail-desc">{{(viewRecieptData?.report?.name == null || viewRecieptData?.report?.name == '') ? '-' : viewRecieptData?.report?.name }} </div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Expense Date </div>
                          <div class="detail-desc">{{this.appservice.dateFormatDisplay(viewRecieptData?.expense_date)}}</div>
                        </div>
                        <div class="col-sm-4 col-12" >
                          <div class="detail-title">{{viewRecieptData?.expense_type == true?"From Location":'Location'}} </div>
                          <div class="detail-desc">{{viewRecieptData?.from_location?.name}} </div>
                        </div>
                        <ng-container *ngIf="viewRecieptData?.expense_type == true">

                            <div class="col-sm-4 col-12" >
                            <div class="detail-title">To Location </div>
                            <div class="detail-desc">{{viewRecieptData?.to_location?.name}} </div>
                          </div>
                        </ng-container>

                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Category </div>
                          <div class="detail-desc">{{viewRecieptData?.category?.name}} </div>
                        </div>

                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Amount</div>
                          <div class="detail-desc">{{viewRecieptData?.amount | currency : currency}}</div>
                        </div>
                        <div  class="hstack align-items-center gap-8 fs-14">
                          <i class="{{viewRecieptData?.is_reimbursement ? 'icon-check-square-fill' : 'icon-square' }}  text-light-400"></i>Claim Reimbursement
                        </div>
                        <div class=" col-12">
                          <div class="detail-title">Description </div>
                          <div class="detail-desc">{{viewRecieptData?.desicription!=null && viewRecieptData?.desicription!=null?viewRecieptData?.desicription:'--'}}</div>
                        </div>
                        <div class=" col-12" *ngIf="viewRecieptData?.attachments.length">
                          <div class="detail-title">Document </div>
                          <div class="detail-desc">
                            <div class="row row-16 w-100" >
                              <div class="col-6" *ngFor="let attach of viewRecieptData?.attachments;let i=index;">

                                <!-- <ng-container > -->

                                <div class="card card-c2  bg-secondary">
                                  <div class="p-12 bg-secondary d-flex align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                    <div
                                      class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase fs- fw-700 text-trim">{{this.appservice.getDocName(attach?.attachment)}}
                                      </span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(attach?.attachment)}} </span>
                                      <div class="d-flex gap-24 fs-12 fw-500">
                                        <div class="link-primary" (click)="this.appservice.downloadFile(attach?.attachment,this.appservice.getDocName(attach?.attachment))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                        <div class="link-primary" (click)="viewDocument(attach?.attachment)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- </ng-container> -->
                              </div>
                            </div>
                            <!-- <ng-container *ngFor="let attach of viewRecieptData?.attachments;let i=index;">
                              <div class="card card-c2 p-24">
                                <div class="row row-16">
                                  <div class="col-lg-6 ">

                                    <div class="card card-c2  bg-secondary">
                                      <div class="card-body  ">
                                        <div class="d-flex align-items-start mb-8 gap-16">
                                          <i class="icon-file-earmark-text-fill fs-32 mt-1 text-accent2"></i>
                                          <div>
                                            <h6 class="mb-1">{{this.appservice.getDocName(attach?.attachment)}}</h6>

                                          </div>
                                          <div class="ms-auto ">
                                            <i class="icon-check-circle-bold text-success fs-20"></i>
                                          </div>
                                        </div>
                                        <div class="d-flex gap-24 fs-12 fw-500">
                                          <div class="link-primary" (click)="this.appservice.downloadFile(attach?.attachment,this.appservice.getDocName(attach?.attachment))">
                                            <i class="bi bi-download me-1"></i>Download
                                          </div>
                                          <div class="link-primary" (click)="viewDocument(attach?.attachment)">
                                            <i class="bi bi-eye me-1"></i>View
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>

                              </div>
                             </div>
                             </ng-container> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- test end -->
<ng-container *ngIf="editDetail">

  <app-add-new-expense [from]="'receipt'" [addExpense]="editDetail" (saveReceipt)="saveReceipt($event)" [editId]="editId" (closeAddExpense)="closeAddExpense()"></app-add-new-expense>
</ng-container>
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
 [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>


  <app-info-popup *ngIf="alertToggle == true" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

