<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row ">
        <div class="col-12 d-flex align-items-start">
            <div>
                <h3>TDS Return </h3>
                <p class="mb-0 text-helper">This page enables you to generate TDS return file for each quarter.</p>
            </div>
            <div class="ms-auto d-flex gap-16">
                <div style="width:22.6875rem;">
                    <ng-select #ngSelecter class="form-ngselect fs-14" placeholder="Select company"
                        [(ngModel)]="company" (change)="filterChange(company,'company')" [items]="companyList"
                        bindLabel="company_name" bindValue="id">
                        <ng-template ng-label-tmp let-item="item" let-index="index">
                            <div class="d-inline-flex  align-items-center">

                                <img class="avatar-sm me-8 sq-28 img-fluid rounded-circle"
                                    *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                                <span
                                    *ngIf="(item.logo ==  ''|| item.logo == null) && item.company_name != '' && item.company_name != null"
                                    class="avatar-sm sq-28 me-8 rounded-circle bg{{index%5}}">
                                    <span>{{this.appservice.getFirstChar(item.company_name,1)}}</span>
                                </span>
                                {{item.company_name}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="d-inline-flex  align-items-center">

                                <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                    *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                                <span *ngIf="item.logo ==  ''|| item.logo == null"
                                    class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                                    <span>
                                        {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                                </span>
                                {{item.company_name}}
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
            </div>
        </div>
    </div>
    <div *ngIf="(nodata && !loader);else all_data" class="row " style="height: calc(100vh - 12rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg41.png'" [noDataText]="'Awaiting TDS challan generation!'"
        [noDataPara]="'It seems no challan details have been added for the selected financial year. Start generating the TDS return file for the employees by adding the challan details.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>
    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row mt-24 row-16" *ngIf="loader">
        <app-common-loader class="col-md-6" [cardType]="'card4'"
            *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-common-loader>
    </div>

    <ng-template #all_data>
        <div class="row mt-24 row-16" *ngIf="!loader">
            <div class="col-12 mt-0 text-uppercase fw-500">Financial year
                {{this.appservice.getFinancialYearRange(selectedYear)}}</div>


            <div class="col-md-6 " *ngFor="let tds of tdsList let i = index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex align-items-center p-16 w-100">
                            <div *ngIf="tds?.challan_period == 'Q1'" class="logo-img cbg-accent2">
                                {{tds?.challan_period}}</div>
                            <div *ngIf="tds?.challan_period == 'Q2'" class="logo-img cbg-warning">
                                {{tds?.challan_period}}</div>
                            <div *ngIf="tds?.challan_period == 'Q3'" class="logo-img cbg-secondary">
                                {{tds?.challan_period}}</div>
                            <div *ngIf="tds?.challan_period == 'Q4'" class="logo-img cbg-success">
                                {{tds?.challan_period}}</div>

                            <!-- <div class="logo-img text-accent2 {{tds?.color_code}}">{{tds?.challan_period}}</div> -->
                            <div class="overflow-hidden ">
                                <h6 class="tips fs-14 text-trim mb-0">{{tds?.challan_period == 'Q1'? '1st Quarter':
                                    tds?.challan_period == 'Q2'? '2nd Quarter' : tds?.challan_period == 'Q3'? '3rd
                                    Quarter': '4th Quarter'}} </h6>
                                <span class="tips-helper">{{tds?.challan_period == 'Q1'? '1st Quarter':
                                    tds?.challan_period == 'Q2'? '2nd Quarter' : tds?.challan_period == 'Q3'? '3rd
                                    Quarter': '4th Quarter'}} </span>
                                <span class="text-light-400 fs-12">{{tds?.start_month.substring(0, 3)}} {{tds?.year}} -
                                    {{tds?.end_month.substring(0, 3)}} {{tds?.year}}</span>

                            </div>
                            <div class="ms-auto mb-auto link-primary fs-14"
                                *ngIf="!tds?.inactive && tds?.generate_status"
                                (click)="downloadChallan(tds?.id,tds?.challan_period)">
                                <i class="bi bi-download me-1"></i>Download

                            </div>
                        </div>

                    </div>
                    <div class="card-body p-16 fs-12 d-flex align-items-center justify-content-between">
                        <div class="fs-12">
                            <span class="text-light-400 me-1">Total challan :</span>
                            <span>{{tds?.total_challan}}</span>
                        </div>
                        <div class="fs-12">
                            <span class="text-light-400 me-1">Total tax :</span>
                            <span>{{tds?.total_tax | currency: tds?.company_currency
                                }}</span>
                        </div>
                        <button class="btn btn-sm btn-primary text-uppercase" [disabled]="tds?.inactive"
                            (click)="genarateFile(tds?.id)">{{tds?.generate_status == false ? 'Generate' :
                            'ReGenerate'}}</button>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-6 ">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex align-items-center p-16 w-100"  >
                            <div class="logo-img  bg16 text-warning">Q2</div>
                            <div class="overflow-hidden ">
                                <h6 class="tips fs-14 text-trim mb-0">2nd Quarter </h6>
                                <span class="tips-helper">2nd Quarter </span>
                                <span class="text-light-400 fs-12">Apr 2017 - Jun 2021</span>
                            </div>
                            <div class="ms-auto mb-auto link-primary fs-14">
                                <i class="bi bi-download me-1"></i>Download

                            </div>
                        </div>

                    </div>
                    <div class="card-body p-16 fs-12 d-flex align-items-center justify-content-between">
                        <div class="fs-12">
                            <span class="text-light-400 me-1">Total Challan :</span>
                            <span>5</span>
                        </div>
                        <div class="fs-12">
                            <span class="text-light-400 me-1">Total Tax :</span>
                            <span>25,000</span>
                        </div>
                        <button class="btn btn-sm btn-primary text-uppercase">ReGenerate</button>
                    </div>
                </div>
            </div> -->

        </div>
    </ng-template>

</div>

<!-- Without tax deductor -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="bi bi-info-circle text-accent2"></i>
                <h4 class="modal-title">Info</h4>

            </div>
            <div class="modal-body py-0">
                <p class="mb-0">TDS returns cannot be generated as this company's tax deductor information is not
                    configured.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary text-uppercase btn-sm"
                    (click)="alertToggle = false">Ok</button>
            </div>
        </div>
    </div>
</div>
