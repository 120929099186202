<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Details</h5>
        <button class="btn-reset text-light-600" routerLink="/leave-year-end">
            <i class="icon-close-lg fs-16" ></i></button>
    </header>
    <main class=" config-container">
        <div class="container-fluid p-32 form-container">
          <app-table-loader *ngIf="loader"></app-table-loader>
            <div class="row mb-24" *ngIf="!loader">
                <div class="col-md-4">
                    <p class="text-uppercase fw-500 mb-0">pending Leave requests</p>
                </div>
                <div class="col-md-8">
                    <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
                        <button class="btn btn-outline-primary fs-14 radius-2 text-uppercase" (click)="export()">Export</button>
                        <button class="btn btn-primary text-uppercase radius-2 fs-14" [disabled]="getWholeData().length == 0" (click)="sendReminderModel()">Send reminder</button>
                    </div>
                </div>
            </div>
            <div class="row row-24" *ngIf="!loader">
                <div class="col-12">
                    <div class="card card-c2 overflow-hidden">
                        <div class="table-responsive">
                            <table class="table table-striped table-sm form-table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="form-cell">
                                            <input class="form-check-input m-0" type="checkbox" value="" (click)="checkAll($event)" [checked]="pageChange()" *ngIf="permissions?.a">
                                        </th>
                                        <th class="fw-600" scope="col">EMP CODE</th>
                                        <th class="fw-600" scope="col">Emp name</th>
                                        <th class="fw-600" scope="col">company</th>
                                        <th class="fw-600" scope="col">Business unit</th>
                                        <th class="fw-600" scope="col">LEAVE CODE</th>
                                        <th class="fw-600" scope="col">From date</th>
                                        <th class="fw-600" scope="col">TO DATE</th>
                                        <th class="fw-600" scope="col">LEAVE Days</th>
                                        <th class="fw-600" scope="col">PENDING WITH</th>
                                    </tr>
                                </thead>
                                <tbody class="cell-16">
                                  <tr *ngIf="this.appServ.filterIt(viewData,'').length<=0">
                                    <td colspan="10">
                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                            <span class="fw-500 fs-14">No data found</span>
                                          </div>
                                    </td>
                                  </tr>
                                    <tr *ngFor="let item of $any(viewData| filter:searchString | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)">
                                        <td class="form-cell">
                                            <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked" (change)="individualData($event,item.id)" *ngIf="permissions?.a">
                                        </td>
                                        <td ngbPopover="{{item?.employee_code}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{item?.employee_code}}</td>
                                        <td ngbPopover="{{item?.employee_name}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{item?.employee_name}}</td>
                                        <td ngbPopover="{{item?.company_name}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{item?.company_name}}</td>
                                        <td ngbPopover="{{item?.bu_name}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{item?.bu_name}}</td>
                                        <td ngbPopover="{{item?.leave_code}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{item?.leave_code}}</td>
                                        <td ngbPopover="{{this.appServ.dateFormatDisplay(item?.from_date)}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{this.appServ.dateFormatDisplay(item?.from_date)}}</td>
                                        <td ngbPopover="{{this.appServ.dateFormatDisplay(item?.to_date)}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{this.appServ.dateFormatDisplay(item?.to_date)}}</td>
                                        <td ngbPopover="{{item?.leave_days}}" popoverClass="popover-input popover-info"  container="body"  triggers="hover">{{item?.leave_days}}</td>
                                        <td>
                                            <div class="d-flex align-items-center overlap-avatar overlap-left" >
                                              <ng-container *ngFor="let rights of item['accepted_by'],let j = index">
                                                <img  class="avatar-sm sq-32 img-fluid rounded-circle"  *ngIf="j < 3 && (rights?.profile_image !=  '' && rights?.profile_image !=  null)"  src="{{rights?.profile_image}}"  [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body">
                                                <div class="avatar-sm sq-32 rounded-circle {{rights?.color_code}}" *ngIf="j < 3 && (rights.profile_image ==  '' || rights.profile_image ==  null)"  [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body" ><span>{{this.appServ.getFirstChar(rights?.first_name+' '+(rights?.middle_name!=null?rights?.middle_name:'')+' '+rights?.last_name,2)}}</span></div>
                                                <ng-template #popContentSingle>
                                                      <div class="user-details">
                                                          <span class="user-label">{{rights?.first_name+' '+(rights?.middle_name!=null?rights?.middle_name:'')+' '+rights?.last_name}}</span>
                                                          <span class="user-code">EMP CODE: {{rights?.employee_code}} </span>
                                                      </div>
                                              </ng-template>
                                                <div *ngIf="j==3" class="avatar-sm sq-32 rounded-circle" [ngbPopover]="popContentMultilpe"  popoverClass="user-list multi-user" triggers="click" container="body"><span>+{{item['accepted_by'].length - 3}}</span></div>
                                            </ng-container>
                                            <ng-template #popContentMultilpe>
                                              <ul>
                                                <ng-container *ngFor="let re of item['accepted_by'],let i = index">
                                                  <ng-container *ngIf="i >= 3">
                                                    <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
                                                        <img  class="user-avatar {{re?.color_code}}" src="{{re?.profile_image}}">
                                                        <div class="user-details">
                                                          <span class="user-label">{{re?.first_name+' '+(re?.middle_name!=null?re?.middle_name:'')+' '+re?.last_name}}</span>
                                                          <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                                                        </div>
                                                    </li>
                                                    <li *ngIf="re?.profile_image =='' || re?.profile_image ==null">
                                                        <span class="user-avatar {{re?.color_code}}">{{this.appServ.getFirstChar(re?.first_name+' '+(re?.middle_name!=null?re?.middle_name:'')+' '+re?.last_name,2)}}</span>
                                                        <div class="user-details">
                                                            <span class="user-label">{{re?.first_name+' '+(re?.middle_name!=null?re?.middle_name:'')+' '+re?.last_name}}</span>
                                                            <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                                                        </div>
                                                    </li>
                                                  </ng-container>
                                                </ng-container>
                                              </ul>
                                            </ng-template>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4" *ngIf="!loader && this.appServ.filteredcount(viewData,searchString)>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="this.appServ.filteredcount(viewData,searchString)"
                  [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize" ></ngb-pagination>
          </div>
        </div>
        <footer class="submit-footer justify-content-between gap-16" *ngIf="permissions?.a">
            <div class="fs-14  d-flex align-items-center  gap-16" *ngIf="getWholeData().length !== 0 && !loader">
                <span class="" >{{count}} Employees selected</span>
                <button class="btn btn-outline-danger btn-icon fw-400 btn-sm text-uppercase" (click)="rejectApproveRequest('Rejected')" [disabled]="btnDisable"> <i class="bi bi-x-circle"></i> Reject</button>
                <button class="btn btn-outline-success btn-icon fw-400 btn-sm text-uppercase" (click)="rejectApproveRequest('Accepted')" [disabled]="btnDisable"> <i class="icon-check-circle"></i> Approve</button>

            </div>
            <button class="btn btn-primary btn-sm text-uppercase"  *ngIf="getWholeData().length !== 0 && !loader" (click)="cancelSelect()">CANCEL SELECTION</button>
        </footer>
    </main>
</div>

<div class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title">{{getWholeData().length}} Employee(s) selected</h4>
          <button type="button" class="btn-close ms-auto my-0" (click)="downloadMsg = false" ></button>
          </div>
          <div class="modal-body">
              <p class="mb-0">Click the Send Reminder button to notify the approver of the pending requests. </p>
          </div>
          <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="downloadMsg = false;" >Cancel</button>
          <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="sendReminder()" [disabled]="canSave">SEND REMINDER</button>

          </div>
      </div>
  </div>
  </div>
