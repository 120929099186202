import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function EmpcodeValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const empcodePattern = /^[A-Za-z0-9]{0,15}$/.test(value);

      return !empcodePattern ? {pattern:true}: null;
  }
}

