<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row  mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Task Owner</h3>
            <p class="text-helper mb-md-0">Processes can be assigned and delegated to different users based on their roles.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class="" style="width:22.6875rem;">
                        <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='tasksearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchtaskowner($event)" [isLoading]="isLoading"></app-searchbar>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                       <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';selectItemList('')">All</button>
                       <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';selectItemList(true)">Active </button>
                       <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
                    </div>
                 </div>
                <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addTasks($event)"><i class="icon-plus"></i>Add TASK OWNER</button>

            </div>
        </div>
    </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16 " *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <div *ngIf="nodata && !loader;else all_data" class="row " style="height: calc(100vh - 11rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg37.png'" [noDataText]="'Ready to delegate?'"
        [noDataPara]="'There aren\'t any task owners assigned at the moment. Click the \'Add Task Owner\' button to start assigning tasks and managing responsibilities.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>
    <ng-template #all_data>
        <div  class="" infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [fromRoot]="true"
            (scrolled)="onScrollDown()">
            <div class="row row-16 ">
              <ng-container *ngIf="loader==false">

                      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 "  *ngFor="let task of tasks,let i = index">
                          <div class="card card-custom1 card-hover" >
                              <div class="card-body p-0 d-flex align-items-start">
                              <div class="d-flex view-section" (click)="viewtask(task.id)">
                                  <div class="logo-img {{task.color_code}}">{{ this.appService.getFirstChar(task.process_name,2) }}</div>
                                  <div class="overflow-hidden">
                                      <h6 class="tips text-trim mb-0">{{task.process_name}}</h6>
                                      <span class="tips-helper">{{task.process_name}}</span>
                                      <span class="fs-10 text-uppercase fw-500 {{(task.is_active == true) ? 'text-success' : 'text-danger'}}  ">{{(task.is_active
                                          == true) ? 'Active' : 'Deleted'}}</span>
                                  </div>
                                </div>
                                <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="task.is_active && (permissions.e || permissions.d)">
                                  <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                      ngbDropdownToggle>
                                      <i class="icon-three-dots-vertical fs-16"></i>
                                  </button>
                                  <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                      <button *ngIf="permissions.e"  ngbDropdownItem (click)="editTask(task.id,task.is_active)">
                                          <i   class="icon-pencil-square me-1"></i> Edit
                                      </button>
                                      <button  *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletetaskowner(task.id)">
                                          <i class="icon-trash  me-1"></i> Delete
                                      </button>
                                  </div>
                              </div>

                          </div>
                          </div>
                      </div>
              </ng-container>

              <!-- Loader updations -->
              <ng-container *ngIf="infinityloader">
                <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
              </ng-container>
          </div>
        </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

  <!-- view -->
  <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <!-- View loader start -->
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <!-- View loader end -->
              <ng-container *ngIf="!loader1">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0"> TASK OWNER </p>
                    <a class="link-primary fs-14 d-flex align-items-center ms-auto"  (click)="editTask(taskid,task_is_active)" *ngIf="task_is_active && permissions.e">
                        <i   class="icon-pencil-square me-1"></i> Edit
                    </a>
                </div>
                <div class="row row-16 mb-16">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 py-16">
                            <div class="row row-24 " >
                                <div class="col-12 "><span class="title">TASK OWNER DETAILS</span></div>
                               <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Module </div>
                                  <div class="detail-desc">{{task_module}}
                                  </div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Status</div>
                                  <div class="detail-desc">
                                    <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="task_is_active">ACTIVE</span>
                                    <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="task_is_active==false">DELETED</span>
                                  </div>
                               </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Process</div>
                                  <div class="detail-desc">{{taskname}}
                                  </div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Role </div>
                                  <div class="detail-desc">{{task_role}}
                                  </div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                    <div class="detail-title">Due days</div>
                                    <div class="detail-desc">{{task_due}}
                                    </div>
                                  </div>

                            </div>
                        </div>
                    </div>

                </div>
              </ng-container>
            </div>
        </div>
    </form>
  </div>
<!-- slider section start -->
</div>
    <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
        <form class="side-panel-container" [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <header class="side-panel-head">
                <h5><span [innerHTML]="header"></span> Task Owner</h5>
                <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
            </header>
            <div class="side-panel-body" style="overflow-y: auto;">
                <div class="container-fluid p-32">
                   <!-- Edit loader start -->
                  <app-edit-loader *ngIf="loader1" ></app-edit-loader>
                  <!-- Edit loader end -->
                  <ng-container *ngIf="!loader1" >
                    <p class="text-uppercase fw-500 mb-12">TASK OWNER</p>
                    <div class="row row-12">
                        <input type="text" value="{{ editId }}" formControlName="id" id="id" hidden>
                        <div class="col-12 col-md-6 form-row ">
                            <label for="" class="form-label required">Module </label>
                            <ng-select class="form-ngselect"
                            formControlName="module" id="module"
                            (change)="moduleSelectFun()"
                            [ngClass]="{ 'is-invalid': (submitted || f.module.dirty || f.module.touched) && f.module.errors }"
                            placeholder="{{this.messageService.selectplaceholddisplay('module')}} ">
                                <ng-option *ngFor="let li of moduleDetailsList" [value]="li.id">{{li.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="(submitted || f.module.dirty || f.module.touched) && f.module.errors" class="invalid-feedback">
                                 <div *ngIf="f.module.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            </div>
                        </div>
                        <ng-container  *ngIf="multiDrop;else singleDrop">
                            <div class="col-12 col-md-6 form-row ">
                                <label for="" class="form-label required">Process </label>

                                <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('process')}}"
                                formControlName="process"
                                [items]="processDropdown"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="process_id"
                                class="multiselect"
                                [ngClass]="{ 'is-invalid': (submitted || f.process.dirty || f.process.touched) && f.process.errors }"
                                >
                                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index">
                                        <div  class="d-inline-flex align-items-center overflow-hidden w-100 gap-4"> <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                         <span  class="text-trim"> {{ item.name }}</span></div>

                                    </ng-template>
                                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                                          <div class="ng-value" *ngIf="checkoutForm.value.process.length">
                                              <span class="ng-value-label">{{checkoutForm.value.process.length}} Process Selected</span>
                                          </div>
                                      </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-index="index">
                                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                  </ng-template>
                                </ng-select>
                                <div *ngIf="(submitted || f.process.dirty || f.process.touched) && f.process.errors" class="invalid-feedback">
                                    <div *ngIf="f.process.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                </div>
                                <ul class="selected-values mt-8 w-100" *ngIf="checkoutForm.value.process!='' || checkoutForm.value.process.length !=0">
                                    <li class="ng-value" *ngFor="let item of checkoutForm.value.process, let m =index">
                                        <ng-container *ngFor="let insideitem of processDropdown, let k =index">
                                        <span class="ng-value-label" *ngIf="insideitem['process_id']==item">
                                            {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                        </ng-container>
                                        <span class="ng-value-icon right" aria-hidden="true"  (click)="clear('process',m)"><i class="icon-close"></i></span>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                        <ng-template #singleDrop>
                            <div class="col-12 col-md-6 form-row ">
                                <label for="" class="form-label required">Process </label>
                                <ng-select class="form-ngselect"
                                    placeholder="{{this.messageService.selectplaceholddisplay('process')}}"
                                    formControlName="process" id="process"
                                    [ngClass]="{ 'is-invalid': (submitted || f.process.dirty || f.process.touched) && f.process.errors }"
                                    >
                                        <ng-option *ngFor="let li of processDropdown" [value]="li.process_id">{{li.name}}</ng-option>
                                </ng-select>
                                <div *ngIf="(submitted || f.process.dirty || f.process.touched) && f.process.errors" class="invalid-feedback">
                                    <div *ngIf="f.process.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="col-12 col-md-6 form-row ">
                            <label for="" class="form-label required">Role </label>
                            <ng-select class="form-ngselect"
                            formControlName="role" id="role"
                            [ngClass]="{ 'is-invalid': (submitted || f.role.dirty || f.role.touched) && f.role.errors }"
                            placeholder="{{this.messageService.selectplaceholddisplay('role')}} ">
                                <ng-option *ngFor="let li of roleDetailsList" [value]="li.id">{{li.role_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="(submitted || f.role.dirty || f.role.touched) && f.role.errors" class="invalid-feedback">
                                <div *ngIf="f.role.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 form-row ">
                            <label for="" class="form-label required ">Due days </label>
                            <input type="text" class="form-control" formControlName="due_days" name="due_days" id="due_days" value="" placeholder="{{this.messageService.placeholderdisp('days')}}" [ngClass]="{ 'is-invalid': (submitted || f.due_days.dirty || f.due_days.touched)  && f.due_days.errors }">
                            <div *ngIf="(submitted || f.due_days.dirty || f.due_days.touched) && f.due_days.errors" class="invalid-feedback">
                                <div *ngIf="f.due_days.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="f.due_days.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                                </div>
                                <div *ngIf="!(f.due_days.errors.pattern) && f.due_days.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','3')}}

                                </div>
                            </div>
                        </div>
                    </div>
                  </ng-container>
                </div>
            </div>
            <footer class="side-panel-footer gap-32" *ngIf="!loader1">
                <button class="btn btn-outline-danger text-uppercase btn-sm" [disabled]="!updateClass" type="button" *ngIf="deleteButtonEnable" (click)="deleteToggle = true">Delete</button>
                <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="!updateClass" type="submit" ><span [innerHTML]="addEditBtn"></span></button>
            </footer>
        </form>
    </div>
  <!-- slider section end -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Task owner?</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Task Owner')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteTask()">Delete</button>
        </div>
      </div>
    </div>
  </div>
