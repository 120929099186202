import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { LeaveYearEndService } from 'src/app/@core/services/leave-year-end.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ToastrService } from 'ngx-toastr';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { ReportDownload } from './report-download';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-leave-year-end',
  templateUrl: './leave-year-end.component.html',
  styleUrls: ['./leave-year-end.component.scss']
})
export class LeaveYearEndComponent implements OnInit {

  leaveYearEnd       : boolean = false;
  viewDetail         : boolean = false;
  loader             : boolean = false;
  noData             : boolean = false;
  infinityLoader     : boolean = false;
  filterPanel        : boolean = false
  vLoader            : boolean = false
  processYear        : boolean = false
  searchKey          : any     = ""
  searchKeyword      : any     = ""
  searchListData     : any     = []
  leaveDataList      : any     = []
  leaveData          : any     = []
  policy_id          : any     = 0
  viewData           : any      = []
  yearSelection      : any     = new Date().getFullYear()
  direction                    = '';
  listLength                   = 0;
  yearRange          : any     = []
  filterData         : any     = ''
  permissions        : any     = [];
  searchData                   = new Subject<any>();
  selectedPolicy     : any
  policyDropdown     : any     = []
  exportAPIData      : any     = []
  toastRef           : any
  currentYr          = new Date().getFullYear()
  clicked : boolean = false
  sLoading = false
  // Form for Bulk Year
  bulkForm          !: FormGroup
  newBulkData       : any = []
  // Page
  limit                        = 20
  offset                       = 0

  downloadPanel                = false
  @ViewChild(SearchbarComponent) search:any;


  constructor(
    public messageService : MessageService,
    private leaveServ : LeaveYearEndService,
    public appService : AppService,
    private fb : FormBuilder,
    public notificationService : NotificationService,
    private router : Router,
    public toasterService: ToastrService,
    private reportDownload :ReportDownload
  ) { }

  ngOnInit(): void {
    // Placeholder for search
    this.searchKey = this.messageService.searchdisplay("leave policy")
    // Permissions
    this.getPermission();
    // Previous Years
    this.yearRange = [new Date().getFullYear()]
    // Search Delay
    this.searchData.pipe(delay(1000),
    switchMap(()=>this.leaveServ.getAllData(this.limit,this.offset,this.searchKeyword,this.yearSelection,this.policy_id))).subscribe((value: any) => {
      this.arrangeAPIData(value)
    });
    // Form for Bulk Year
    this.bulkForm = this.fb.group({
      leave_map_ids : [null]
    })
    if(this.leaveServ.FILTERDATA != undefined){
      this.yearSelection = this.leaveServ.FILTERDATA
    }
    // Initial API
    this.getLandingData()

    // Added to stay open those side panel after close action from child component
    this.router.events .pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise()  ).subscribe((e: any) => {
      if(e[0].urlAfterRedirects.startsWith('/leave-year-end/leave-year-end-details')||e[0].urlAfterRedirects.startsWith('/leave-year-end/pending-leave-request')){
        this.leaveServ.setURLDATA({
          isRedirected : true,
          URL : e[0].urlAfterRedirects,
          redirectedFrom : e[0].urlAfterRedirects.startsWith('/leave-year-end/pending-leave-request') ? 'pending_request' : ''
        })
      }else{
        this.leaveServ.setURLDATA(null)
      }
    })
  }

  // Main API
  getLandingData(){
    this.loader = true
    this.leaveServ.getAllData(this.limit,this.offset,this.searchKeyword,this.yearSelection,this.policy_id).subscribe((res:any)=>{
      this.arrangeAPIData(res)
      if(res.body.results.length != 0){
        let data = res.body.results
        this.policyDropdown = data.map((item:any)=>{
          return {'leave_name' : item.leave_type_details.leave_name, 'id' : item.leave_type_details.id, 'leave_policy' : item?.leave_policy_details?.name, 'combinedId': `${item?.leave_policy_details?.name}-${item.leave_type_details.leave_name}`}
        })
      }else{
        this.policyDropdown = []
      }
    })
  }

  arrangeAPIData(res:any){
    this.listLength = res?.body.results.length
    if(this.offset ==0){
      this.leaveData = [];
    }
    if(res.body.results.length!=0){
      this.leaveDataList = res?.body?.results
      this.yearRange = res?.body?.results[0].year_list
      this.noData = false
      this.addItems(this.listLength);
    }else{
      this.noData = this.offset == 0 ? true : false
      this.yearSelection = this.currentYr
    }
    this.loader = false;
    this.sLoading = false
    this.infinityLoader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.leaveData.push(this.leaveDataList[i]);
    }
    this.newBulkData = this.leaveData.filter((item:any)=>{
      return item?.enable_process_year_end == true
    })
    this.selectAllForDropdownItems(this.newBulkData)
    this.yearSelection = this.leaveData[0]?.year== undefined && this.leaveServ.FILTERDATA == undefined ?  Number(this.leaveData[0]?.leave_policy_valid_year) : this.leaveServ.FILTERDATA == undefined ? Number(this.leaveData[0]?.year) : this.leaveServ.FILTERDATA
    if(this.leaveServ.URLDATA != null && this.leaveServ.URLDATA != undefined){
      const regex = /\/leave-year-end\/(leave-year-end-details|pending-leave-request)\/(\d+)\/(\d+)/
      const match = this.leaveServ.URLDATA.URL?.match(regex);
      if (match) {
        const id = Number(match[2]);
        const subId = Number(match[3])

        let item : any = {}
        if(this.leaveServ.URLDATA?.redirectedFrom == 'pending_request'){
          item = this.leaveData.find((item:any)=>item?.leave_policy == id && item?.leave_type_details?.id == subId)
        }else{
          item = this.leaveData.find((item:any)=>item?.id == id)
        }
        this.policyData(item?.id,item?.enable_process_year_end)
      }
    }
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Search
  searchResults(val:any){
    this.loader = true
    if(val == ""){
      this.searchKeyword  = "";
      this.policy_id = this.offset = 0
      this.leaveData = this.leaveDataList = []
      this.searchData.next()
    }else{
      this.offset = 0
      this.policy_id = val
      this.searchData.next()
    }
  }

  // With Keyword Search
  searchLeave(val:any){
    this.sLoading = true
    this.loader         = true;
    this.offset         = this.policy_id = 0;
    this.leaveDataList = this.leaveData  = [];
    this.searchKeyword  = val;
    this.clicked = false
    if(val != ""){
      this.offset = 0
      this.searchData.next()
    }else{
      this.offset = 0
    }
  }


  // Year Change
  changeYear(val:any){
    this.searchKeyword = ''
    this.leaveServ.setFilterData(Number(val))
    this.searchKey = this.messageService.searchdisplay("leave policy")
    this.search.clearBar()
    this.yearSelection = Number(val)
    this.offset = 0
    this.leaveServ.setURLDATA(null)
  }

  // Infinity Scroll
  onScrollDown() {
    this.infinityLoader     = true;
    this.offset            += this.limit;
    this.leaveServ.setURLDATA(null)
    if(this.listLength){
     this.getLandingData()
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityLoader = false;
    }
  }

  // Bulk
  closeAlert(){
    let alert = document.getElementsByClassName('alert') as HTMLCollection
    alert[0].classList.remove('show')
  }

  // Bulk Cancel
  cancelSidePanel(){
    this.leaveYearEnd = false
    this.limit = 20
    this.offset = 0
    this.policy_id = 0
    this.leaveServ.setURLDATA(null)
    this.getLandingData()
  }

  // Clear Selected Policy
  clear(val:any){
    let data = this.bulkForm.value.leave_map_ids;
    data.splice(val, 1);
    this.bulkForm.patchValue({
      leave_map_ids: data
    });
  }

  isValid() {
    return !this.bulkForm.value.leave_map_ids || this.bulkForm.value.leave_map_ids.length === 0;
  }

  // Process Year End
  policyFailedId : any

  processYearEnd(id:any,val:any){
    let exactID = val == 'view' ? JSON.stringify([id]) : this.bulkForm['controls'].leave_map_ids.value
    let bulkDataArr = {
      leave_map_id: this.leaveDataList
          .filter((item:any) => exactID.includes(item?.id))
          .map((item:any) => ({
              id: item?.id,
              process_end: item?.enable_process_year_end
          })),
      year : this.yearSelection
    };
    this.leaveServ.createBulkYear(bulkDataArr).subscribe((res:any)=>{
      this.bulkForm.reset()
      this.search.clearBar()
      this.policy_id = 0
      this.offset = 0
      this.limit = 20
      if(res?.body?.failed_data.length == 0){
        this.viewDetail = false
        this.leaveYearEnd = false
        this.notificationService.handleSuccessNotification("Processed year end successfully.","Success")
      }else{
        this.policyFailedId = res?.body?.failed_data
        this.downloadPanel = true
      }
    })
  }

  downloadReport(){
    this.reportDownload.downLoadReport(this.policyFailedId,this.yearSelection)
    this.downloadPanel = false
    this.viewDetail = false
    this.leaveYearEnd = false
    this.getLandingData()
  }

  closePanel(){
    this.downloadPanel = false
    this.getLandingData()
  }

  // Individual View
  policyData(id:any,process:any){
    this.policy_id = id
    this.viewDetail = true
    this.vLoader = true
    this.leaveServ.getIndividualLeave(id,this.yearSelection).subscribe((res:any)=>{
      this.viewData = res?.body[0]
      this.vLoader = false
      this.processYear = process
    })
  }

  // Permission
  getPermission(){
    this.permissions = this.appService.getPermissions('/leave-year-end');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // No Data
  addNewdata(){
    this.router.navigate(['/leave-rule/leave-policy']);
  }
}
