import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';

@Component({
  selector: 'app-run-payroll',
  templateUrl: './run-payroll.component.html',
  styleUrls: ['./run-payroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunPayrollComponent implements OnInit {

  activeItem:any = [];
  company:any = ""
  companyList:any = [];
  year:any=false;
  graphyear:any = ""
  month:any=false;
  movetoPoint = 0;
  loader = true
  loader1 = true
  loader2 = true
  loader3 = true
  payrollCount:any = [];
  payrollMonthlyCost:any = [];
  currency =""
  payrollRunData:any = []
  countData:any = [];
  holdData:any = [];
  months:any = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  }
  config:any = [];
  progress = 0;
  access = true;
  hasEmployee = true
  isClosed = false;
  nodataFound = false
  sliderDropdownMonth:any = []
  sliderDropdownYear:any = []
  disableclick = false
  yearEndReminder = false;
  isOpen = true;
  isAttendanceLop:any
  constructor(private _cdr: ChangeDetectorRef,public sds:SalaryDashboardService,public route:ActivatedRoute,public router:Router,private NotificationService:NotificationService,public appservice:AppService,public messageService : MessageService) { }
  selMonth:any
  selYear:any
  yearmonthKey:any = []
bannerSidebar = false;
banneropen = true;
endDate:any
  ngOnInit(): void {
    this.getCompanyDropdown();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
        this.companySelected()

      }
    })
  }
  getPayrollRunData(){
    if(this.company!=null && this.company!=''){

      this.payrollRunData =[]
      this.countData = []
      this.sds.getPayrollRun(this.activeItem.payroll_year,this.months[this.activeItem.payroll_month],this.company).subscribe((res:any)=>{
        if(res.status == 200){
          this.payrollRunData =res.body.data
          this.countData = res.body
          this.loader2 = false
          this.checkloader()
          this._cdr.detectChanges();
        }})
    }
    else
    this.loader2 = false
  }
  public carouselTileItems: Array<any> = [];

  @ViewChild('myCarousel') myCarousel!: NguCarousel<any>;
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 4, lg: 6, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    loop:false,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  createCaroselItem(){
    var state = "";
    this.carouselTileItems = [];
    this.activeItem = []
    this.sliderDropdownMonth = []
    this.sliderDropdownYear  = []
    this.progress = 0;
    this.sds.getDateRangeSlider(this.company).subscribe((res:any)=>{
      this.config           = res.body.configure_dict;
      this.access           = res.body.data_access;
      this.hasEmployee      = res.body.is_company_has_employee;
      this.yearEndReminder  = res.body.year_end_reminder;

      let increment = this.config.prior_payroll_status==true?33:50;
      if(this.config?.prior_payroll_status && this.config?.complete_priorpayroll)
        this.progress += increment;
      if(this.config?.is_payroll_configured == false){
        this.progress += increment;
      }
      if(this.config?.is_statutory_configured == false){
        this.progress += increment;
      }
      if(res.status == 200){
        if(res.body.data.length){
            res.body.data.forEach( (value:any, key:any)=>{
              if(!this.sliderDropdownYear.includes(value.year))
                this.sliderDropdownYear.push(value.year)
              if(this.sliderDropdownMonth[value.year] == undefined)
                this.sliderDropdownMonth[value.year] = []
              this.sliderDropdownMonth[value.year].push({month:value.payroll_month,key:key})
                this.yearmonthKey[value.year+value.payroll_month] = key
                state = value.payroll_status;
                this.carouselTileItems.push({
                  state:value.payroll_status,
                  status:state,
                  // month : value.payroll_month.substring(0, 3)+" "+value.year,
                  month : value.payroll_month+" "+value.year,
                  range : value.start_month.substring(0, 3)+" "+value.start_day+" - "+value.end_month.substring(0, 3)+" "+value.end_day,
                  payroll_year :value.year,
                  payroll_month : value.payroll_month,
                  key:key
                });
                var currentTime = new Date()
              var vyear = currentTime.getFullYear()
              var cMonth = currentTime.getMonth()
              var months = ["January", "February", "March", "April", "May","June", "July", "August", "September", "October", "November", "December"];

              if(value.payroll_status == "current"  && !this.year && !this.month ){
                  this.carouselTileItems[key]['status'] = "active";
                  this.activeItem = this.carouselTileItems[key];
                  this.setSelected()
              }
              else if(this.year  && this.month  && this.year == value.year && this.month == value.payroll_month){
                this.activeItem = this.carouselTileItems[key];
                this.carouselTileItems[key]['status'] = "active";
                this.setSelected()
              }
              else if(this.activeItem.length == 0 && vyear == value.year && months[cMonth] == value.payroll_month && !this.year && !this.month){
                this.activeItem = this.carouselTileItems[key];
                this.carouselTileItems[key]['status'] = "active";
                this.setSelected()
              }
              if(value.payroll_month == this.activeItem.payroll_month && value.year == this.activeItem.payroll_year){

                this.defStartEndDate(value);
              }
            });
            if(this.activeItem.length == 0){
                this.carouselTileItems[0]['status'] = "active";
                this.activeItem = this.carouselTileItems[0];
                this.setSelected()
            }

            this.nodataFound = false;
            this.getPayrollDetails()
            this.getPayrollRunData()
            this.getStatutoryCheckData();

            this._cdr.detectChanges();
        } else {
          this.nodataFound = true;
          this.loader = false;
          this._cdr.detectChanges();
        }
        this._cdr.detectChanges();
      }
    });

   }
   setSelected(){
    this.selMonth = this.activeItem.payroll_month
    this.selYear= this.activeItem.payroll_year
   }
   getCompanyDropdown(){
    this.sds.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        this.companyList = res.body;
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader   = false;
          this._cdr.detectChanges();
        } else {
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;
            this.companySelected()
          }
        }
      }
    })
  }
  changeCarousel(key:any){
    this.clickCarousel(this.carouselTileItems[key])
  }
  changeYear(yearKey:any){
    let key = yearKey+this.activeItem.payroll_month
    this.changeCarousel(this.yearmonthKey[key])
  }
  companySelected(){
    if(this.company!='' && this.company!=null){
      this.loader   = true;
      this.isClosed = false
      this.createCaroselItem();
      this.gettabdetails();
      // this.createYearArray()
    }
  }
  clickCarousel(item:any) {
    if(this.year != "" && this.month != "" )
      this.router.navigateByUrl("/run-payroll/"+item.payroll_year+"/"+item.payroll_month+"/"+this.company)
    else {
        this.loader =true;
        this.loader1 =true;
        this.loader2 =true;
        this.loader3 =true;
        this.carouselTileItems[item['key']]['status'] = "active";
        this.carouselTileItems[this.activeItem['key']]['status'] = this.carouselTileItems[this.activeItem['key']]['state'];
        this.activeItem = item;
        this.setSelected()
        this.getPayrollDetails();
        this.getPayrollRunData();
        this.getHoldDetails();
        this.getStatutoryCheckData();
    }
  }

  getPayrollDetails(){

    if(this.activeItem.payroll_month!= undefined && this.company!=null && this.company!=''){

      // let financilYear = this.appservice.getFinacialYearByMonth(this.months[this.config.paycycle_month],this.config.paycycle_year)
      let paycycledate = new Date(this.config.paycycle_year+'-'+this.months[this.config.paycycle_month]+"-"+'01');
      let selectedDate = new Date(this.activeItem.payroll_year+'-'+this.months[this.activeItem.payroll_month]+"-"+'01' );

      if(moment(selectedDate).format('YYYY-MM-DD') < moment(paycycledate).format('YYYY-MM-DD') && this.config.prior_payroll_status){
        this.disableclick = true;
      }
      else{
        this.disableclick = false;
      }

      this.currency = ""
      this.payrollCount = []
      this.payrollMonthlyCost = []
      this.sds.getPayrollMonthData(this.activeItem.payroll_year,this.activeItem.payroll_month,this.company).subscribe((res:any)=>{
        this.currency = res.body.data?.currency
        this.payrollCount = res.body.data?.payroll_count;

        this.payrollMonthlyCost = res.body.data?.payroll_monthly_cost;
        if(this.payrollCount?.calender_days?.error)
          this.NotificationService.handleErrorNotification(this.payrollCount?.calender_days?.error,"Error")
          this.loader1 = false
          this.checkloader()
        this._cdr.detectChanges();
      });
    }
    else{

      this.loader = false
      this.loader1 = false

    }

  }
  getHoldDetails(){

    if(this.activeItem.payroll_month!= undefined && this.company!=null && this.company!=''){
      this.holdData = []
      this.sds.getCompanyHold(this.company,this.endDate).subscribe((res:any)=>{
        this.holdData = res.body.landing_list;
        this.loader3   = false
        this.checkloader()
        this._cdr.detectChanges();

      })
    }
    else{
      this.loader3   = false

    }
  }
  checkloader(){
    if(this.loader1||this.loader2||this.loader3)
      this.loader = true;
    else
      this.loader = false;
  }
  movePrior(){
    if(this.config.is_payroll_configured==false && this.config.is_statutory_configured==false)
    this.router.navigateByUrl("/prior-payroll-setup/"+this.appservice.getFinancialYear()+"/"+this.activeItem.payroll_month+"/"+this.company)

  }

  getStatutoryCheckData(){
    if(this.activeItem.payroll_month!= undefined && this.company!=null && this.company!=''){

      this.progress = 0;
      this.isOpen   = true;
      this.sds.getStatutoryCheckData(this.activeItem.payroll_year,this.activeItem.payroll_month,this.company).subscribe((res:any)=>{

        this.config.is_statutory_configured = res?.body?.data?.is_statutory_configured;
        this.yearEndReminder  = res?.body?.data?.year_end_reminder;

        let increment = this.config.prior_payroll_status==true?33:50;
        if(this.config?.prior_payroll_status && this.config?.complete_priorpayroll)
        this.progress += increment;
        if(this.config?.is_payroll_configured == false){
          this.progress += increment;
        }

        if(this.config?.is_statutory_configured == false){
          this.progress += increment;
        }
          this.loader1 = false
          this.checkloader()
        this._cdr.detectChanges();
      });
    }
    else
    this.loader1 = false

  }
  gettabdetails(){
    this.loader   = true;
    this.sds.tabdetails(this.company,'payroll').subscribe((res: any) => {
      this.isAttendanceLop = res.body.attendance_lop;

    })

  }
  closebanner(key:any){
    this.bannerSidebar=key;
  }
  bannerCloseBtn(event: Event): void {
    this.banneropen = false;
    event.stopPropagation();
  }
  defStartEndDate(value:any){

    let startYear = value.year;
    if(value.end_month=='January' && value.start_month=='December')
     startYear = value.year - 1;

    this.endDate   = value.year+"-"+this.months[value.end_month]+"-"+this.pad(value.end_day)
    this.getHoldDetails();

  }
  pad(d:number) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }
}
