<div class="container-fluid p-24 ">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Employee State Insurance</h3>
            <p class="text-helper mb-md-0"> Create and configure ESI challan group and map it to relevant OU.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
              <div class=" " style="width:22.6875rem;">
                <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='esigroupPlaceholder'
                  (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchesigroup($event)" [isLoading]="isLoading"></app-searchbar>
              </div>
                <div class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" (click)="filterPanel=true">
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                </div>
                <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/challan-settings/challan-settings-setup/esi"><i
                        class="icon-plus"></i>Add CHALLAN GROUP</button>
            </div>
        </div>
    </div>
      <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata == false" class="row row-16" infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
    <ng-container *ngIf="loader==false">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 " *ngFor="let item of esigroupList,let i = index">
        <div class="card card-custom1 card-hover">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex p-16 {{(item.is_active == true) ? 'view-section' : 'w-100' }} "
              (click)="viewesigrp(item.id)">

              <div class="logo-img {{item.color_code}}"> {{ this.appService.getFirstChar(item['name'],2) }}</div>
              <div class="overflow-hidden">
                <h6 class="tips text-trim mb-0">{{item['name']}}</h6>
                <span class="tips-helper">{{item['name']}}</span>
                <span
                  class="fs-10 text-uppercase fw-500 {{(item['is_active'] == true) ? 'text-success' : 'text-danger'}}">{{(item['is_active']
                  == true) ? 'Active' : 'Deleted'}}</span>
              </div>
            </div>
            <div *ngIf="item.is_active==true && (permissions.e || permissions.d)" ngbDropdown
              class="pe-16 py-16 dropdown-section d-inline-block ">
              <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical fs-16"></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem *ngIf="permissions.e"
                routerLink="/challan-settings/challan-settings-setup/esi/{{item?.id}}/">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>
                <button class="text-danger" ngbDropdownItem  *ngIf="permissions.d"
                  (click)="deleteToggle=true;deleteId=item.id;">
                  <i class="icon-trash  me-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="infinityloader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </ng-container>

  </div>
  <div *ngIf="nodata == true && !loader" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg41.png'" [noDataText]="'Oops, ESI challan groups unavailable!'"
      [noDataPara]="'It seems no ESI challan groups have been created yet. To create one, click the \'Add Challan\' button, and it will appear here once completed.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='challansettingsView === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>View </h5>
            <a class="toggle-panel" (click)="challansettingsView = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <ng-container *ngIf="!loader1">
                <div class="row row-16">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0"></p>
                        <a class="link-primary fs-14 ms-auto text-nowrap"  *ngIf="permissions.e && esigroupview.is_active" routerLink="/challan-settings/challan-settings-setup/esi/{{esigroupview?.id}}/"><i
                                class="icon-pencil-square me-1"></i>
                            Edit</a>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">Challan group details </p>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Group Name</div>
                                    <div class="detail-desc">{{esigroupview?.name}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Status</div>
                                    <div class="detail-desc">
                                        <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="esigroupview.is_active">ACTIVE</span>
                                        <span class="fs-12 fw-500 text-danger text-uppercase"
                                         *ngIf="esigroupview.is_active==false">DELETED</span>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Responsible Person</div>
                                    <div class="detail-desc">
                                        <div class="d-flex align-items-center">
                                            <img
                                              *ngIf="(esigroupview?.responsible_persone!=null && (esigroupview?.responsible_persone?.profile_image !=  '' && esigroupview?.responsible_persone?.profile_image !=  null))"
                                              class="user-img img-fluid rounded-circle sq-32" src="{{esigroupview?.responsible_persone?.profile_image}}">
                                            <div
                                              *ngIf="(esigroupview?.responsible_persone!=null && (esigroupview?.responsible_persone?.profile_image ==  '' || esigroupview?.responsible_persone?.profile_image ==  null))"
                                              class="avatar-circle fs-14 fw-500 {{esigroupview?.responsible_persone?.color_code}} sq-32 rounded-circle">
                                              <span>{{this.appService.getFirstChar(esigroupview?.responsible_persone?.first_name+'
                                                '+esigroupview?.responsible_persone?.last_name,2)}}</span>
                                            </div>
                                            <div class="detail-desc p-2">
                                              {{esigroupview?.responsible_persone!=null?(esigroupview?.responsible_persone?.first_name+"
                                              "+(esigroupview?.responsible_persone?.middle_name!=null?esigroupview?.responsible_persone?.middle_name:'')+"
                                              "+esigroupview?.responsible_persone?.last_name+" ("+esigroupview?.responsible_persone?.employee_code+")"):'-'}}
                                            </div>
                                          </div>
                                   </div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Group Owner Name</div>
                                    <div class="detail-desc">
                                        <div class="d-flex align-items-center">
                                            <img
                                              *ngIf="(esigroupview?.group_owner!=null && (esigroupview?.group_owner?.profile_image !=  '' && esigroupview?.group_owner?.profile_image !=  null))"
                                              class="user-img img-fluid rounded-circle sq-32" src="{{esigroupview?.group_owner?.profile_image}}">
                                            <div
                                              *ngIf="(esigroupview?.group_owner!=null && (esigroupview?.group_owner?.profile_image ==  '' || esigroupview?.group_owner?.profile_image ==  null))"
                                              class="avatar-circle fs-14 fw-500 {{esigroupview?.group_owner?.color_code}} sq-32 rounded-circle">
                                              <span>{{this.appService.getFirstChar(esigroupview?.group_owner?.first_name+'
                                                '+esigroupview?.group_owner?.last_name,2)}}</span>
                                            </div>
                                            <div class="detail-desc p-2">
                                              {{esigroupview?.group_owner!=null?(esigroupview?.group_owner?.first_name+"
                                              "+(esigroupview?.group_owner?.middle_name!=null?esigroupview?.group_owner?.middle_name:'')+"
                                              "+esigroupview?.group_owner?.last_name+" ("+esigroupview?.group_owner?.employee_code+")"):'-'}}
                                            </div>
                                         </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Establishments ESI Number</div>
                                    <div class="detail-desc">{{esigroupview?.esi_number}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Description </div>
                                    <div class="detail-desc">{{esigroupview?.address}}</div>
                                </div>
                                <!-- <div class="col-sm-4 col-12">
                                    <div class="detail-title">Employers Contribution</div>
                                    <div class="detail-desc"> Contribution</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Employees Contribution</div>
                                    <div class="detail-desc"> Contribution</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">APPLICABILITY</p>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Applicable to</div>
                                    <div class="detail-desc">{{esigroupview?.applicability?.applicable}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">{{esigroupview?.applicability?.applicable}}</div>
                                    <div class="detail-desc">
                                        <div class="selected-values mt-1" >
                                            <ng-container *ngIf="esigroupview?.applicability?.applicable == 'Employee'">
                                             <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                               <div class="ng-value-label" title="{{rights['first_name']+rights['middle_name']+rights['last_name'] + ' (' + rights['employee_code'] +' )'}}">
                                                 <span class="d-inline-flex align-items-center">
                                                   <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}" *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                                                   <span class="avatar sq-24 avatar-circle {{rights.color_code}}" *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.first_name,2)}}</span>
                                                     <span> {{rights['first_name']+(rights['middle_name']!=null && rights['middle_name']!=""?" " +rights['middle_name']:"")+" "+rights['last_name'] +" ("+rights['employee_code']+")"}}</span>
                                                  </span>
                                               </div>
                                             </li>
                                            </ng-container>
                                            <ng-container *ngIf="esigroupview?.applicability?.applicable == 'Company'">
                                             <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                               <div class="ng-value-label" title="{{rights.company_name}}">
                                                 <span class="d-inline-flex align-items-center">
                                                   <img class="img-fluid rounded-circle sq-24"  src="{{rights.logo}}" *ngIf="rights.logo !=  '' && rights.logo !=  null">
                                                   <span class="avatar sq-24 avatar-circle {{rights.color_code}}"  *ngIf="rights.logo ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                                     <span> {{rights.company_name}}</span>
                                                  </span>
                                               </div>
                                             </li>
                                            </ng-container>
                                            <ng-container *ngIf="(esigroupview?.applicability?.applicable != 'Employee') && (esigroupview?.applicability?.applicable != 'Company')">
                                                   <div class="d-flex flex-wrap mt-8 gap-8" >
                                                     <ng-container *ngFor="let rights of assignees, let i=index;">
                                                       <span class="badge3"  title="{{rights.name}}"> {{ (rights.length>25)? (rights.name | slice:0:25)+'..':(rights.name)  }}
                                                       </span>
                                                     </ng-container>

                                                   </div>
                                            </ng-container>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            </div>
        </div>
        <!-- <footer class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm">
                Save
            </button>
        </footer> -->
    </div>
</div>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete ESI Group?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('ESI Group')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
            (click)="deleteToggle = false;deleteClicked = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
            (click)="deletegrp(deleteId)">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <app-salary-dashboard-filter [filter]="filterPanel" [isCompanyNeeded]='true' (applymethod)="submitForm($event)" [from]="'groupEsi'"  (resetmethod)="resetFilter()" (closemethod)="closeFilter()">
  </app-salary-dashboard-filter>
