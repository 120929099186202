<div  *ngIf=" nodata && loopcompleted " class="row" style="height: calc(100vh - 9rem);" >
  <header *ngIf="from != 'myteam'" class=" config-header">
    <h5 class="m-0">Employee Attendance</h5>
    <button class="btn-reset text-light-600" (click)="backClicked();">
      <i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-nodata-view class="vstack" [noDataImg]="'bg54.png'" [imgClass]="'img-sm'" [hasPermission]="false"> </app-nodata-view>
</div>
<div class="{{from == 'myteam' ? '' :'config-layout'}}" *ngIf="loaderView">
  <div class="config-container">
   <div class="container-fluid p-32 form-container">
    <div class="row">
      <div class="col-12">
        <app-c1-loader></app-c1-loader>
      </div>
    </div>
   </div>
  </div>
</div>
<div class="{{from == 'myteam' ? '' :'config-layout'}}" *ngIf="!viewempmap && !nodata && !loaderView">
  <header *ngIf="from != 'myteam'" class=" config-header">
    <h5 class="m-0">Employee Attendance</h5>
    <button class="btn-reset text-light-600" (click)="backClicked();">
      <i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" {{from == 'myteam' ? '' :'config-container'}} ">
    <div class="container-fluid p-32 form-container">
      <div class="row row-16 mb-24">
        <div class="col-12 d-flex">
          <ng-select *ngIf="EmpListData?.length" [(ngModel)]="activeEmployee" [clearable]="false"
            class="form-ngselect fs-14" placeholder="Select Employee Name" style="width:20.1875rem"
            (change)="activatePunch =false;getSummary();this.webCheckinValid()">
            <ng-container *ngFor="let emp of EmpListData;let i=index">
              <ng-option [value]="emp.id">
                <span class="ng-value-label">
                  <div class="d-inline-flex align-items-center">
                    <div *ngIf="emp.profile_image ==  '' || emp.profile_image ==  null"
                      class="avatar-sm sq-24 fw-500 fs-10 rounded-circle {{emp?.color_code}} me-1">
                      <span>{{this.appservice.getFirstChar(emp.name,2)}}</span>
                    </div>
                    <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                      *ngIf="emp.profile_image !=  '' && emp.profile_image !=  null" [src]="emp.profile_image">
                    {{emp.first_name+" "+(emp.middle_name!=null?emp.middle_name+" ":" ")+emp.last_name +"
                    ("+emp.employee_code+")"}}
                  </div>
                </span>
              </ng-option>
            </ng-container>
          </ng-select>
          <div class="d-flex ms-auto gap-16">
            <!-- <button *ngIf="permissions?.a" class="btn btn-primary fs-10 fw-700 text-uppercase"
              [disabled]="!activatePunch" (click)="activateAddPunch()">Add punch</button> -->
            <button *ngIf="permissions?.a" class="btn flex-shrink-0 radius-2 btn-primary fs-10 fw-700 text-uppercase px-4" [disabled]="!activatePunch" (click)="activateAddPunch();">Override Attendance</button>
          </div>
        </div>
        <div class="col-12" *ngIf="validation != '' || validationShift!=''">
          <div role="alert" class="alert alert-warning mb-0  show">
            <p class="mb-0 fs-14  ">{{validation !=''?validation:validationShift}}</p>
            <div class="btn-close pointer" (click)="validation ='' && validationShift=''"></div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <p class="text-uppercase fw-500 mb-0">{{activeEmpData}}</p>
        </div>
        <div class="col-md-8">
        </div> -->
        <!-- </div> -->
        <!--Attendance Details-->
        <div class="col-12">
          <div class="card card-c2 p-16">
            <div class="row row-16">
              <div class="col-12 d-flex align-items-center">
                <p class="mb-0 fw-500">Attendance Info</p>
                <div class="input-group1 ms-auto fs-14">
                  <ng-select *ngIf="!loader" style="width:9rem" placeholder="Month" class="form-ngselect h-36"
                    [(ngModel)]="activeYear" [clearable]="false" (change)="activatePunch=false; validateMonth()">
                    <ng-option *ngFor="let yData of dropdownYear" [value]="yData">{{yData}}</ng-option>
                  </ng-select>
                  <ng-select *ngIf="!loader" style="width:9rem" placeholder="Year" class="form-ngselect h-36"
                    [(ngModel)]="activeMonth" [clearable]="false" (change)="activatePunch=false; this.getSummary()">
                    <ng-option *ngFor="let yData of dropdownMonth[activeYear]" [value]="yData">{{yData}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 ">
                <div class="card hstack card-c2 h-100 p-16 gap-8">
                  <div class="  gap-8">
                    <div class="fs-20 fw-500 text-accent1" *ngIf="advanceDetails?.time_metrix?.overtime_monthly_hrs">
                      {{appservice.hhmmTimeFormat(advanceDetails?.time_metrix?.overtime_monthly_hrs)}} Hrs</div>
                    <div class="fs-22 fw-500 text-accent1" *ngIf="!advanceDetails?.time_metrix?.overtime_monthly_hrs">0
                      Hrs</div>
                    <div class="fs-10 fw-500  text-uppercase ">Overtime Monthly hrs</div>
                  </div>
                  <i class="icon-stopwatch-fill text-accent1 fs-32  ms-auto"></i>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 ">
                <div class="card hstack card-c2 h-100 p-16 gap-8">
                  <div class="  gap-8">
                    <div class="fs-20 fw-500 text-accent1 " *ngIf="advanceDetails?.time_metrix?.late_log_days">
                      {{advanceDetails?.time_metrix?.late_log_days}} Day(s)</div>
                    <div class="fs-20 fw-500 text-accent1 " *ngIf="!advanceDetails?.time_metrix?.late_log_days">0 Day(s)
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">Late logs this month </div>
                  </div>
                  <i class="icon-snooze-fill text-accent1  fs-32 ms-auto"></i>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 ">
                <div class="card hstack card-c2 h-100 p-16 gap-8">
                  <div class="gap-8">
                    <div class="fs-20  text-accent1  fw-500"
                      *ngIf="appservice.isValidDateTime(today+' '+advanceDetails?.time_metrix?.avg_working_hrs)">
                      {{today+" "+advanceDetails?.time_metrix?.avg_working_hrs|date:'HH:mm'}} Hrs</div>
                    <div class="fs-20  text-accent1  fw-500"
                      *ngIf="!appservice.isValidDateTime(today+' '+advanceDetails?.time_metrix?.avg_working_hrs)">0 Hrs
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">Average working hours </div>
                  </div>
                  <i class="icon-hourglass  text-accent1   fs-32 ms-auto"></i>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 ">
                <div class="card card-c2 hstack h-100 p-16 gap-8">
                  <div class=" gap-8">
                    <div class="fs-20 fw-500 text-accent1 "
                      *ngIf="advanceDetails?.time_metrix?.on_time_monthly_percentage">
                      {{advanceDetails?.time_metrix?.on_time_monthly_percentage}} %</div>
                    <div class="fs-20 fw-500 text-accent1 "
                      *ngIf="!advanceDetails?.time_metrix?.on_time_monthly_percentage">0 %
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">ON TIME </div>
                  </div>
                  <i class="icon-clock-fill  text-accent1 fs-32 ms-auto"></i>
                </div>
              </div>
              <div class="col-12">
                <hr class="hr-1">
              </div>
              <div class="col-12">
                <div class="row row-8">
                  <div class="col">
                    <div class="card card-c2 ps-16 pe-8 py-8  radius-8 indicater4 indicater-bg10">
                      <p class="mb-0 fs-10 ">Present</p>
                      <p class="mb-0 fs-12  fw-500 " *ngIf="advanceDetails?.attendance_details?.present">
                        {{advanceDetails?.attendance_details?.present}} Days</p>
                      <p class="mb-0 fs-12  fw-500" *ngIf="!advanceDetails?.attendance_details?.present"> 0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg6">
                      <p class="mb-0 fs-10">Paid Leave</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.paid_leave">
                        {{advanceDetails?.attendance_details?.paid_leave}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.paid_leave">0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg8">
                      <p class="mb-0 fs-10">Week Off</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.week_off">
                        {{advanceDetails?.attendance_details?.week_off}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.week_off">0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg9">
                      <p class="mb-0 fs-10">Holidays</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.holidays">
                        {{advanceDetails?.attendance_details?.holidays}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.holidays">0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg5">
                      <p class="mb-0 fs-10">Absent</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.absent">
                        {{advanceDetails?.attendance_details?.absent}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.absent">0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg5">
                      <p class="mb-0 fs-10">Unpaid Leave</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.unpaid_leave">
                        {{advanceDetails?.attendance_details?.unpaid_leave}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.unpaid_leave">0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg5">
                      <p class="mb-0 fs-10">Arrears</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.arrears">
                        {{advanceDetails?.attendance_details?.arrears}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.arrears">0 Days</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8  radius-8 indicater4 indicater-bg8">
                      <p class="mb-0 fs-10">Overtime</p>
                      <p class="mb-0 fs-12 fw-500 text-nowrap" *ngIf="advanceDetails?.attendance_details?.overtime">
                        {{advanceDetails?.attendance_details?.overtime}} Hrs</p>
                      <p class="mb-0 fs-12 fw-500  text-nowrap" *ngIf="!advanceDetails?.attendance_details?.overtime">
                        00:00 Hrs</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8 radius-8 indicater4 indicater-bg5"
                      (click)="deductionLeave = true">
                      <p class="mb-0 fs-10 text-nowrap text-accent2">Deduction (Leave)</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.leave_deduction">
                        {{advanceDetails?.attendance_details?.leave_deduction}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.leave_deduction">0
                        Days
                      </p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-c2 py-8 ps-16 pe-8 radius-8 indicater4 indicater-bg5"
                      (click)="deductionPaid = true">
                      <p class="mb-0 fs-10 text-nowrap text-accent2">Deduction (Paid days)</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="advanceDetails?.attendance_details?.paid_day_deduction">
                        {{advanceDetails?.attendance_details?.paid_day_deduction}} Days</p>
                      <p class="mb-0 fs-12 fw-500 " *ngIf="!advanceDetails?.attendance_details?.paid_day_deduction">0
                        Days
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card card-c2 p-16 hstack border-0 bg28 gap-8  ">
                  <p class="mb-0 fs-12 fw-500">
                    Total paid days : </p>
                  <p class="ms-auto mb-0 fw-500">{{advanceDetails?.attendance_details?.total_paid_days ?
                    advanceDetails?.attendance_details?.total_paid_days:'0'}} Days</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Attendance Details-->
        <div class="col-12" *ngIf="!loader">
          <div class="card card-c2 px-24 py-16">
            <div class="row row-16">
              <div class="col-12 d-flex align-items-center">
                <div class="fs-16 fw-500" *ngIf="activeMonth != null">Calendar (For {{activeMonth.slice(0,3)}}
                  {{activeYear}})</div>
                <div class="btn-group ms-auto" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" [value]="1" [(ngModel)]="view"
                    [checked]="isChecked">
                  <label style="min-width: 9.0625rem;" class="btn btn-outline-accent2 text-uppercase fs-14 fw-600"
                    for="btnradio1">Calendar</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" [value]="0" [(ngModel)]="view"
                    [checked]="!isChecked">
                  <label style="min-width: 9.0625rem;" class="btn btn-outline-accent2 text-uppercase fs-14 fw-600"
                    for="btnradio2">Table</label>
                </div>
              </div>
              <ng-container *ngIf="view == 1">
                <app-attendance-calendar [currentServerDate]="currentServerDate" [visibleRange]="visibleRange" [activeMonth]="activeMonth"
                  [activeYear]="activeYear" [isEmployeeId]="activeEmployee" [options]="options" [activateAction]="false"
                  [from]="isteam == true?'team':'HR'" [fromoverride]="fromoverrde" (addPunchAction)="addPunchAction($event)" [empDoj]="empDoj"
                  (viewempmap)="viewempmapfn($event)"
                  (changeMonthYear)="changeMonthYear($event)"></app-attendance-calendar>
              </ng-container>
              <ng-container *ngIf="view == 0">
                <app-attendance-table [activeMonth]="activeMonth" [activeYear]="activeYear"
                  [isEmployeeId]="activeEmployee" [options]="options" [from]="isteam == true?'team':'HR'"
                  [empDoj]="empDoj"></app-attendance-table>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<ng-container *ngIf="showInfo">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<app-attendance-location *ngIf="viewempmap" [data]="viewdata"
  (closepanel)="viewempmap=false"></app-attendance-location>
<div class="side-panel " style="--sidepanel-width:45rem;" [class.side-pane-active]='deductionPaid === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Deduction (Paid Days) </h5>
      <a class="toggle-panel" (click)="deductionPaid = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <div class="card card-c2">
              <div class="table-responsive radius-4">
                <table class="table   td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                  <thead>
                    <tr>
                      <th class="fw-600 text-uppercase" scope="col">Attendance date</th>
                      <th class="fw-600 text-uppercase" scope="col">value</th>
                      <th class="fw-600 text-uppercase" scope="col">reason</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr *ngIf="advanceDetails?.paid_day_deduction_data_list?.length<=0">
                      <td colspan="3">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let val of advanceDetails?.paid_day_deduction_data_list">
                      <td>{{val.absent_date| date:this.appservice.getdateformat()}}</td>
                      <td>{{val.deduction_paid_days}}</td>
                      <td>{{val.reason}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="side-panel " style="--sidepanel-width:45rem;" [class.side-pane-active]='deductionLeave === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Deduction (Leave) </h5>
      <a class="toggle-panel" (click)="deductionLeave = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <div class="card card-c2">
              <div class="table-responsive radius-4">
                <table class="table    td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                  <thead>
                    <tr>
                      <th class="fw-600 text-uppercase" scope="col">Attendance date</th>
                      <th class="fw-600 text-uppercase" scope="col">value</th>
                      <th class="fw-600 text-uppercase" scope="col">Leave code</th>
                      <th class="fw-600 text-uppercase" scope="col">Leave name</th>
                      <th class="fw-600 text-uppercase" scope="col">reason</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr *ngIf="advanceDetails?.leave_dudction_data_list?.length<=0">
                      <td colspan="5">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let val of  advanceDetails?.leave_dudction_data_list">
                      <td>{{val.leave_date_data| date:this.appservice.getdateformat()}}</td>
                      <td>{{val.value}}</td>
                      <td>{{val.leave_code}}</td>
                      <td><p class="w-30ch">{{val.leave_name}}</p></td>
                      <td>{{val.reason}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='overrideAttendance === true' >
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Override attendance</h5>
      <a class="toggle-panel" (click)="resetform()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <label for="" class="form-label required">Select option to override </label>
          </div>
          <div class="col-12 mt-8 form-row">
            <div class="form-check form-check-inline">
              <input type="radio" id="radio1" class="form-check-input" [value]="true"  [(ngModel)]="radiovalue" (click)="this.overrideform.reset()">
              <label for="radio1" class="form-check-label">Add punch</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" id="radio2" class="form-check-input" [value]="false" [(ngModel)]="radiovalue" (click)="overrideclick();">
              <label for="radio2" class="form-check-label">Update attendance status</label>
            </div>
          </div>
          <ng-container *ngIf="radiovalue == false">
            <ng-container [formGroup]="overrideform">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">ATTENDANCE DETAILS</p>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Date</div>
                          <div class="detail-desc">{{this.appservice.dateFormatDisplay(clickedDate)}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Shift Code </div>
                          <div class="detail-desc">{{overrideData[3]['shiftCode']}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Shift Start Time </div>
                          <div class="detail-desc">{{this.appservice.timeFormatDisplay(overrideData[1]['shiftFrom'])}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">First In </div>
                          <div class="detail-desc">{{(overrideData[4]['FirstIn']=='None')?'--':this.appservice.timeFormatDisplay(overrideData[4]['FirstIn'])}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Shift End Time </div>
                          <div class="detail-desc">{{this.appservice.timeFormatDisplay(overrideData[2]['shiftTo'])}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Last Out </div>
                          <div class="detail-desc">{{(overrideData[5]['LastOut']=='None')?'--':this.appservice.timeFormatDisplay(overrideData[5]['LastOut'])}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <label for="" class="form-label required">First Half</label>
              <div class="form-row" [ngClass]="{ 'is-invalid': (f?.firsthalfstatus?.touched || f?.firsthalfstatus?.dirty) &&  f?.firsthalfstatus?.errors}">
                  <ng-select  placeholder="status" class="form-ngselect h-36" formControlName="firsthalfstatus" [clearable]="false" [readonly]="firsthalfdisable" (change)="statusoverride('firsthalf')" [ngClass]="{ 'is-invalid': ( f.firsthalfstatus?.touched || f?.firsthalfstatus?.dirty) &&  f?.firsthalfstatus?.errors}">
                  <ng-option *ngFor="let status of statusoptions" [value]="status.name" >{{status.name}}</ng-option>
                  </ng-select>
              </div>
              <div *ngIf="(f?.firsthalfstatus?.touched || f?.firsthalfstatus?.dirty) &&  f?.firsthalfstatus?.errors" class="invalid-feedback">
                  <div *ngIf="f?.firsthalfstatus?.errors?.firsthalferr">{{errormsg}}
                  </div>
              </div>
            </div>
            <div class="col-6">`
              <label for="" class="form-label required">Second Half</label>
              <div class="form-row" [ngClass]="{ 'is-invalid': (f?.secondhalfstatus?.touched || f?.secondhalfstatus?.dirty) &&  f?.secondhalfstatus?.errors}">
                <ng-select placeholder="status" class="form-ngselect h-36"
                formControlName ="secondhalfstatus" [clearable]="false" [readonly]="secondhalfdisable" (change)="statusoverride('secondhalf')" [ngClass]="{ 'is-invalid': ( f.secondhalfstatus?.touched || f?.secondhalfstatus?.dirty) &&  f?.secondhalfstatus?.errors}">
                <ng-option *ngFor="let status of statusoptions" [value]="status.name">{{status.name}}</ng-option>
                </ng-select>
              </div>
            <div *ngIf="(f?.secondhalfstatus?.touched || f?.secondhalfstatus?.dirty) &&  f?.secondhalfstatus?.errors" class="invalid-feedback">
              <div *ngIf="f?.secondhalfstatus?.errors?.secondhalferr">{{errormsg}}
              </div>
            </div>
          </div>
          </ng-container>
          </ng-container>
          <ng-container *ngIf="radiovalue">
            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">punch date</p>
              <p class="text-uppercase ms-auto fw-500 mb-0">{{this.appservice.dateFormatDisplay(clickedDate)}}</p>
            </div>
            <ng-container [formGroup]="punchForm">
              <ng-container formArrayName="punch" *ngFor="let item of punch().controls; let i=index; ">
                <ng-container [formGroupName]="i">
                  <div class="col-12">
                    <div class="card card-c2 p-24">
                      <div class="row row-16">
                        <div class="col-12 d-flex align-item-center">
                          <div class="title">punch {{i+1}}</div>
                          <a class="ms-auto  link-danger" *ngIf="i!=0" (click)="deletePunch(i)"><i
                              class="icon-trash"></i></a>
                        </div>
                        <div class="col-12">
                          <label for="" class="form-label required">Punch time</label>
                          <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid':(punch().controls[i].get('punch_time')?.touched || punch().controls[i].get('punch_time')?.dirty ) &&  punch().controls[i].get('punch_time')?.errors}">
                            <igx-time-picker formControlName="punch_time"
                              placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                              [ngClass]="{ 'is-invalid':(punch().controls[i].get('punch_time')?.touched || punch().controls[i].get('punch_time')?.dirty ) &&  punch().controls[i].get('punch_time')?.errors}"
                              class="form-control form-timepicker " (click)="picker1.open()" #picker1></igx-time-picker>
                            <i *ngIf="punch().controls[i].get('punch_time')?.value!=null" class="icon-close text-light-400"
                              (click)="clearTime(picker1);picker1.close()"></i>
                          </div>
                          <div
                            *ngIf="(punch().controls[i].get('punch_time')?.dirty|| punch().controls[i].get('punch_time')?.touched) && punch().controls[i].get('punch_time')?.errors"
                            class="invalid-feedback">
                            <div *ngIf="punch().controls[i].get('punch_time')?.errors?.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                          </div>
                        </div>
                        <div class="col-12">
                          <label for="" class="form-label">Remarks </label>
                          <input type="text" rows="3" class="form-control" autocomplete="off" id="reason"
                            placeholder="{{this.messageService.descriptiontext('desc')}}" formControlName="remark"
                            [ngClass]="{ 'is-invalid': ( punch().controls[i].get('remark')?.dirty ) &&  punch().controls[i].get('remark')?.errors}">
                          <div
                            *ngIf="( punch().controls[i].get('remark')?.dirty || punch().controls[i].get('remark')?.touched) && punch().controls[i].get('remark')?.errors"
                            class="invalid-feedback">
                            <div
                              *ngIf="!(punch().controls[i].get('remark')?.errors?.pattern) && punch().controls[i].get('remark')?.errors?.maxlength">
                              {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <div class="col-12">
              <span class=" link-primary1 fs-14" (click)="addpunchData()">
                <i class="icon-plus-square-fill me-8"></i>Add Punch Time
              </span>
            </div>
            <!-- <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">PUNCH DATE</p>
                    <span class="fw-500 ms-auto  ">20/01/2023</span>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-12 d-flex">
                          <p class="title mb-0">punch 1 </p>
                          <a class="ms-auto link-danger">
                            <i class="icon-trash"></i>
                          </a>
                        </div>
                        <div class="col-12">
                          <label for="" class="form-label required">Punch time </label>
                          <input type="text" name="" class="form-control" placeholder="hh:mm:ss" id="">
                        </div>
                        <div class=" col-12 form-row">
                          <label for="" class="form-label required">Remarks </label>
                          <textarea type="text" class="form-control " rows="4"
                            placeholder="Enter remarks here..."></textarea>
                        </div>
                        <div class="col-12">
                          <div class="link-primary1 fs-14 d-flex align-items-center">
                            <i class="icon-plus-square-fill fs-16 me-8"></i>Add Punch Time
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="  btn btn-outline-primary text-uppercase btn-sm" (click)="resetform()">
        Cancel
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="savePunchdata()"
        [disabled]="!punchForm.valid" *ngIf="radiovalue">
        save
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="statusalert = true;btnclicked=false;"
        [disabled]="!overrideform.valid || overridedisable || noedit" *ngIf="!radiovalue">
        save
      </button>
    </footer>
  </div>
</div>

<div class="modal  modal-alert {{statusalert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16 ">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Alert</h4>
     </div>
      <div class="modal-body">
        <p class="mb-0">The attendance override will not affect any existing requests (overtime, comp time, floating holiday, leave) that the employee has already submitted. When these requests are approved, the system will not automatically change the attendance status. You must manually override the attendance status via Attendance calendar.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="statusalert = false;btnclicked=false;">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm text-uppercase" [disabled]="btnclicked" (click)="saveoverridedata()"> Ok</button>
      </div>
    </div>
  </div>
</div>

