<div class=" config-layout">
    <header class=" config-header">
        <h5>Bulk Upload</h5>
        <a class="toggle-panel" (click)="clearData();backClicked();"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <main class=" config-container">
      <div class="container-fluid p-32 form-container">
        <div class="row row-24 mb-24">
            <div class="col-12">
                <ng-container [formGroup]="templateForm">                     
                      <div class="col-6">
                          <label for="" class="form-label  required">Investment Details</label>
                          <div class="form-icon"
                              [ngClass]="{ 'is-invalid': ( f.formtype.touched) && f.formtype.errors }">
                              <ng-select #ngSelecter class="form-ngselect text-muted" formControlName="formtype"
                               (change)="formselectfn()">
                              <ng-option *ngFor="let formvalues of formvalues" [value]="formvalues.value">{{ formvalues.value }}</ng-option>                          
                              </ng-select>
                          </div>
                          <div *ngIf="(( f.formtype.touched) || f.formtype.touched) && f.formtype.errors"
                              class="invalid-feedback">
                              <div *ngIf="f.formtype.errors">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                      <div class="col-12 mt-10rem" *ngIf="templateForm.valid">
                        <div role="alert" class="alert alert-info   mb-0 pe-32 show">
                          <p class="mb-0 fs-14 hstack gap-8">                          
                            Download the excel sheet generated, fill the details and upload.                           
                          </p>
                          <a (click)="generateTemplate();" class="fw-500 link-primary1">
                            <i class="icon-download text-accent2"></i>
                            Download 
                          </a>
                        </div>    
                      </div>
                    </ng-container>
            </div>
            <div class="col-12">
                <div class="card card-c2 p-24">
                    <div class="title mb-24">INVESTMENT DETAILS {{fileName}}</div>
                    <div class="row row-16"  *ngIf="fileName!=''"> 
                        <div class="col-md-6">
                            <ol class="fs-16  mb-24 li-16 list-type-none ">
                                <li  class="d-flex ">
                                    <span class="text-trim fw-500">{{fileName}}</span>
                                    <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                    <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()" ><i class="bi bi-trash ms-8 "></i></span>
                                </li>  
                            </ol>
                        </div>
                    </div>
                    <label for="upload"  [class.disabled]="fileName != '' && filekey==''" style="max-width: 21.375rem;" class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}"> <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null" (change)="onFileChange($event)" id="upload" hidden>UPLOAD INVESTMENT DETAILS</label>
                    <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>

                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2 p-24">
                    <div class="title mb-24">Instructions</div>
                    <ol class="fs-14 ps-16 mb-0 li-24">
                          <li>Download the excel sheet generated after selecting the Investment Detail option.</li>
                          <li>Enter or update the Investment detals as per the format provided in the excel sheet.</li>
                          <li>Save the template.</li>
                          <li>To import the file, click the "Upload Investment Details" button.</li>
                          <li>Select the file to be imported.</li>
                          <li>Click on "Submit" button below.</li>
                          <li>The file should be in (.xls, .csv) format only.</li>
                    </ol>
                </div>
            </div>
        </div>
      </div>  
      <footer class="gap-32 pt-16 submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == ''" type="button" (click)="Uploaddata()">Submit</button>
      </footer>
    </main>    
</div>