<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="loanProcessForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
            <div class="col-12 form-row mb-2">
              <label for="" class="form-label">Loan Processing Status </label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan type')}}" [ngClass]="{ 'is-invalid': (submitted || f.loan_process_status.touched || f.loan_process_status.dirty) &&  f.loan_process_status.errors}"
                  formControlName="loan_process_status" [items]="requestStatus" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                  [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="loanTypeLoader">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!loanTypeLoader">
                      <div class="ng-value" *ngIf="loanProcessForm.value.loan_process_status?.length ">
                          <span class="ng-value-label">{{loanProcessForm.value.loan_process_status?.length }} loan type
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.name?.length>15)? (item.name |
                              slice:0:15)+'..':(item.name) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="loanTypeLoader">
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.loan_process_status.touched || f.loan_process_status.dirty) && f.loan_process_status.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.loan_process_status.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanProcessForm.value.loan_process_status?.length != 0 && !loanTypeLoader">
                  <li class="ng-value" *ngFor="let item of loanProcessForm.value.loan_process_status,let ki =index">
                      <ng-container *ngFor="let insideitem of requestStatus, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['id'].length>25)?
                                      (insideitem['id'] |
                                      slice:0:25)+'..':(insideitem['id']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('loan_process_status',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-12 form-row mb-2">
              <label for="" class="form-label">Loan type </label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan type')}}" [ngClass]="{ 'is-invalid': (submitted || f.loan_type.touched || f.loan_type.dirty) &&  f.loan_type.errors}"
                  formControlName="loan_type" [items]="loanTypeDrop" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="loan_type"
                  [closeOnSelect]="false" bindValue="loan_type" class="multiselect" [loading]="loanTypeLoader">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!loanTypeLoader">
                      <div class="ng-value" *ngIf="loanProcessForm.value.loan_type?.length ">
                          <span class="ng-value-label">{{loanProcessForm.value.loan_type?.length }} loan type
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.loan_type?.length>15)? (item.loan_type |
                              slice:0:15)+'..':(item.loan_type) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="loanTypeLoader">
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.loan_type.touched || f.loan_type.dirty) && f.loan_type.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.loan_type.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanProcessForm.value.loan_type?.length != 0 && !loanTypeLoader">
                  <li class="ng-value" *ngFor="let item of loanProcessForm.value.loan_type,let ki =index">
                      <ng-container *ngFor="let insideitem of loanTypeDrop, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['loan_type']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['loan_type'].length>25)?
                                      (insideitem['loan_type'] |
                                      slice:0:25)+'..':(insideitem['loan_type']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('loan_type',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-12 form-row mb-2">
              <label for="" class="form-label">Interest type </label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('interest type')}}" [ngClass]="{ 'is-invalid': (submitted || f.interest_type.touched || f.interest_type.dirty) &&  f.interest_type.errors}"
                  formControlName="interest_type" [items]="interestDrop" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                  [closeOnSelect]="false" bindValue="id" class="multiselect">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="loanProcessForm.value.interest_type?.length ">
                          <span class="ng-value-label">{{loanProcessForm.value.interest_type?.length }} interest type
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.name?.length>15)? (item.name |
                              slice:0:15)+'..':(item.name) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" >
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.interest_type.touched || f.interest_type.dirty) && f.interest_type.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.interest_type.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanProcessForm.value.interest_type?.length != 0">
                  <li class="ng-value" *ngFor="let item of loanProcessForm.value.interest_type,let ki =index">
                      <ng-container *ngFor="let insideitem of interestDrop, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['name'].length>25)?
                                      (insideitem['name'] |
                                      slice:0:25)+'..':(insideitem['name']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('interest_type',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-12 form-row mb-2">
              <label for="" class="form-label">Loan status</label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan status')}}" [ngClass]="{ 'is-invalid': (submitted || f.loan_status.touched || f.loan_status.dirty) &&  f.loan_status.errors}"
                  formControlName="loan_status" [items]="loanStatus" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                  [closeOnSelect]="false" bindValue="id" class="multiselect">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="loanProcessForm.value.loan_status?.length ">
                          <span class="ng-value-label">{{loanProcessForm.value.loan_status?.length }} loan status
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.name?.length>15)? (item.name |
                              slice:0:15)+'..':(item.name) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" >
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.loan_status.touched || f.loan_status.dirty) && f.loan_status.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.loan_status.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanProcessForm.value.loan_status?.length != 0">
                  <li class="ng-value" *ngFor="let item of loanProcessForm.value.loan_status,let ki =index">
                      <ng-container *ngFor="let insideitem of loanStatus, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['name'].length>25)?
                                      (insideitem['name'] |
                                      slice:0:25)+'..':(insideitem['name']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('loan_status',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitLoanProcess()">
              Apply
          </button>
      </footer>
  </form>
</div>
