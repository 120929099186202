import {  Component, OnInit } from '@angular/core';
import {  FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ShiftAllowanceService } from 'src/app/@core/services/shift-allowance.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { dayRangeValidator } from 'src/app/@shared/validators/dayrange.validator';

@Component({
  selector: 'app-shift-allowance-policy-setup',
  templateUrl: './shift-allowance-policy-setup.component.html',
  styleUrls: ['./shift-allowance-policy-setup.component.scss']
})
export class ShiftAllowancePolicySetupComponent implements OnInit {
  res:any;
  id: number = 0;
  iseditForm=false;
  loader = false;
  disabled = false;
  editflag: boolean = false
  heading = "Add"
  activeTab = 1;
  shiftslist:any=[];
  myForm: FormGroup = this.fb.group({
    id: 0,
    configure: this.fb.group({
      name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      description: ['', [Validators.required,Validators.maxLength(500)]],
      policy_valid_from: ['', [Validators.required]],
      allowance_shift    : this.fb.array([])
    }),
    shiftmap: this.fb.group({
      set_shiftallowance_workday: [false, [Validators.required]],
      min_shiftallowance_hours_workday : [null],
      set_shiftallowance_weekoff: [false, [Validators.required]],
      min_shiftallowance_hours_weekoff : [null],
      set_shiftallowance_holiday : [false, [Validators.required]],
      min_shiftallowance_hours_holiday : [null],
      set_shiftallowance_half_workday : [false, [Validators.required]],
      min_shiftallowance_hour_half_workday : [null],
      allowance_shift_map: this.fb.array([])
    }),
    assign: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })
  });

  constructor(
    public fb:FormBuilder,
    private ShiftAllowanceService:ShiftAllowanceService,
    public router: Router,
    public route:ActivatedRoute,
    private _location: Location,
    public appservice:AppService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
    this.shiftList();
  }
  submitOne(){
    this.activeTab = 2;
  }
  submitTwo(){
    this.activeTab = 3;
  }
  get configureForm() {
    return this.myForm.get('configure') as FormGroup;
  }
  get shiftmapForm() {
    return this.myForm.get('shiftmap') as FormGroup;
  }
  get assignForm() {
    return this.myForm.get('assign') as FormGroup;
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {
      if (this.myForm['controls'].shiftmap.invalid || this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

  submitForm(){

    this.res = this.assignForm.controls.assignees.value;
    var applicable = this.assignForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.res){
        arr.push(key)
    }
    var applicabledata = {
      "applicable":applicable,
      [applicableStr.toLowerCase()]:arr
    }
    var dataArray = {
      "name": this.configureForm.controls.name.value,
      "description": this.configureForm.controls.description.value,
      "policy_valid_from": moment(this.configureForm.controls.policy_valid_from.value).format('YYYY-MM-DD'),
      "set_shiftallowance_workday": this.shiftmapForm.controls.set_shiftallowance_workday.value,
      "min_shiftallowance_hours_workday": this.shiftmapForm.controls.min_shiftallowance_hours_workday.value?this.appservice.timeFormatAttConvert(this.shiftmapForm.controls.min_shiftallowance_hours_workday.value):null,
      "set_shiftallowance_weekoff": this.shiftmapForm.controls.set_shiftallowance_weekoff.value,
      "min_shiftallowance_hours_weekoff": this.shiftmapForm.controls.min_shiftallowance_hours_weekoff.value?this.appservice.timeFormatAttConvert(this.shiftmapForm.controls.min_shiftallowance_hours_weekoff.value):null,
      "set_shiftallowance_holiday": this.shiftmapForm.controls.set_shiftallowance_holiday.value,
      "min_shiftallowance_hours_holiday": this.shiftmapForm.controls.min_shiftallowance_hours_holiday.value?this.appservice.timeFormatAttConvert(this.shiftmapForm.controls.min_shiftallowance_hours_holiday.value):null,
      "set_shiftallowance_half_workday": this.shiftmapForm.controls.set_shiftallowance_half_workday.value,
      "min_shiftallowance_hour_half_workday": this.shiftmapForm.controls.min_shiftallowance_hour_half_workday.value?this.appservice.timeFormatAttConvert( this.shiftmapForm.controls.min_shiftallowance_hour_half_workday.value):null,
      "allowance_shift" :this.shiftmapForm.controls.allowance_shift_map.value,
      "applicability":applicabledata
    }

    if(this.id == 0){
      this.ShiftAllowanceService.shiftCreate(dataArray).subscribe((res: any) => {
        this.router.navigate(['shift-allowance']);
      });
    } else {
      this.ShiftAllowanceService.shiftUpdate(this.id,dataArray).subscribe((res: any) => {
        this.router.navigate(['shift-allowance']);
      });
    }

  }
  editForm(){
    this.heading = "Edit"
    this.loader = true;
    this.editflag = true
    this.ShiftAllowanceService.shiftGet(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res.body);
    },(error)=>{
      this.router.navigate(['shift-allowance']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }

    let shiftarrmap:any=[] ;
    let shiftarr:any=[] ;
      for(let i=0;i<res.allowance_shift?.length;i++){
        const formArray: FormArray = this.configureForm.get('allowance_shift') as FormArray;
        formArray.push(new FormControl(res.allowance_shift[i].shift));
        shiftarr.push(res.allowance_shift[i].shift);
        shiftarrmap.push({
          'shift_code': res.allowance_shift[i]?.shift?.shift_code,
          'shift_name': res.allowance_shift[i]?.shift?.shift_name,
          'shift': res.allowance_shift[i]?.shift?.id,
          'shift_allowance_mapping': res.allowance_shift[i]?.shift_allowance_mapping ,
      });
      }

    this.addshift(shiftarr)

    let assignees = [];
    let applicableto = "";
    let appkey ="";
    let keydata =res.applicability.applicable;
    for(let key of res.applicability[keydata]){
      appkey = keydata.toLowerCase();
      if(appkey == "BU")
        appkey = "businessunit";
      assignees.push(key?.id);
      applicableto = keydata
    }

    this.myForm.reset(
      {
        id: res.id,
        configure: {
          name:  res.name,
          description: res.description,
          policy_valid_from: res.policy_valid_from,
          allowance_shift: shiftarr,
        },
        shiftmap: {
          set_shiftallowance_workday: res.set_shiftallowance_workday,
          min_shiftallowance_hours_workday : res.min_shiftallowance_hours_workday,
          set_shiftallowance_weekoff: res.set_shiftallowance_weekoff,
          min_shiftallowance_hours_weekoff : res.min_shiftallowance_hours_weekoff,
          set_shiftallowance_holiday : res.set_shiftallowance_holiday,
          min_shiftallowance_hours_holiday : res.min_shiftallowance_hours_holiday,
          set_shiftallowance_half_workday : res.set_shiftallowance_half_workday,
          min_shiftallowance_hour_half_workday : res.min_shiftallowance_hour_half_workday,
          allowance_shift_map : shiftarrmap
        },
        assign: {
          applicableto: applicableto,
          assignees: assignees
        }
    });
    this.loader = false;

  }


backClicked() {
  this._location.back();
}

shiftList() {
  this.ShiftAllowanceService.shiftList().subscribe((res:any)=>{
    this.shiftslist = res?.body;
  })
}

shifts(): FormArray {
  return this.shiftmapForm.get('allowance_shift_map') as FormArray;
}

newshift(inp:any): FormGroup {
  return this.fb.group({
    shift_name              : inp.shift_name,
    shift_code              : inp.shift_code,
    shift                   : inp.id,
    shift_allowance_mapping: this.fb.array([])
  });
}

addshift(inp:any) {
  inp.forEach((ctrl: any) => {
        this.shifts().push(this.newshift(ctrl));
      });
  for(let i=0;i<inp.length;i++){
    if(this.shiftpayrates(i).controls.length==0){
      this.addshiftpayrate(i);
    }
  }
}

shiftpayrates(empIndex: number): FormArray {
  return this.shifts()
    .at(empIndex)
    .get('shift_allowance_mapping') as FormArray;
}

newpayrate(): FormGroup {
  return this.fb.group({
    day_start: ['', [Validators.required,NumberpatternValidator(),Validators.min(1),Validators.maxLength(2),Validators.max(30)]],
    day_end: ['', [Validators.required,NumberpatternValidator(),Validators.maxLength(2),Validators.max(31)]],
    pay_rate: ['', [Validators.required,NumberpatternValidator(),Validators.maxLength(10)]]
  }, { validators: dayRangeValidator() });
}

addshiftpayrate(empIndex: number) {
  this.shiftpayrates(empIndex).push(this.newpayrate());
}

}
