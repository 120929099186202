import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss']
})
export class ShiftDetailsComponent implements OnInit {
  @Input()formGp : FormGroup    = this.fb.group({
    shift_name      : ['',Validators.required],
    shift_code      : ['',Validators.required],
    color_code     : ['',Validators.required],
    shift_allowance_applicable  : [false,Validators.required],
  });

  value:any;
  id:any;
  validated : boolean = false;
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError:any;
  @Input()disabled              = false;
  @Input()submitted:boolean=false;
  private subjectKeyUp = new Subject<any>();

  constructor(
    private fb:FormBuilder,
    public messageService : MessageService,
    public route : ActivatedRoute,
    public existDataService :  ExistDatasService
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d);
    })
  }
    // Simplified function to use for validation
    existService(type:any){
      let value = ""

      if(type == "shift_name")
        value =this.f.shift_name.value
      else
        value =this.f.shift_code.value
      if(!this.editflag){
        this.value ={
          "page": "shift",
          "field": type,
          "value": value
        }
      }else{
         this.value ={
          "id": this.id,
          "page": "shift",
          "field": type,
          "value": value
        }
      }

      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        if(res.body.data ==='invalid'&& type == "shift_name"){
          this.f.shift_name.setErrors({
            'exist' : true
          })
        } else if(res.body.data ==='invalid'&& type == "shift_code"){
          this.f.shift_code.setErrors({
            'exist' : true
          })
        }
      })
    }
  colorchange(i:any){
    this.formGp.controls['color_code'].setValue('uniquebg'+i);
  }
  validateshiftdetail() {
    this.submitted = true;

    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  keyUp(){
    this.subjectKeyUp.next("shift_name");
    if(((this.formGp.controls['shift_code'].value)==(this.formGp.controls['shift_name'].value)) && this.formGp.controls['shift_name'].value != null && this.formGp.controls['shift_name'].value != '' && this.formGp.controls['shift_name'].value != ' '){
      this.formGp.controls['shift_name']?.setErrors({
        'duplicatecode': true,
      })
    }
    else if((this.formGp.controls['shift_code'].value)!=(this.formGp.controls['shift_name'].value) && !this.formGp.controls['shift_name'].hasError('exist') && !this.formGp.controls['shift_name'].errors?.required && !this.formGp.controls['shift_name'].errors?.pattern && !this.formGp.controls['shift_name'].errors?.maxlength){
      this.formGp.controls['shift_name']?.setErrors(null)
        if(!this.formGp.controls['shift_code'].hasError('exist') && !this.formGp.controls['shift_code'].errors?.required && !this.formGp.controls['shift_code'].errors?.pattern && !this.formGp.controls['shift_code'].errors?.maxlength){
             this.formGp.controls['shift_code']?.setErrors(null);
        }
    }else{
      this.formGp.get('shift_name')?.markAsTouched();
    }
  }
  CodekeyUp(){
    this.subjectKeyUp.next("shift_code");
    if(((this.formGp.controls['shift_code'].value)==(this.formGp.controls['shift_name'].value)) && this.formGp.controls['shift_code'].value != null && this.formGp.controls['shift_code'].value != '' && this.formGp.controls['shift_code'].value != ' '){
      this.formGp.controls['shift_code']?.setErrors({
        'duplicatecode': true,
      })
    }
    else if((this.formGp.controls['shift_code'].value)!=(this.formGp.controls['shift_name'].value) && !this.formGp.controls['shift_code'].hasError('exist') && !this.formGp.controls['shift_code'].errors?.required && !this.formGp.controls['shift_code'].errors?.pattern && !this.formGp.controls['shift_code'].errors?.maxlength){
      this.formGp.controls['shift_code']?.setErrors(null)
      if(!this.formGp.controls['shift_name'].hasError('exist') && !this.formGp.controls['shift_name'].errors?.required && !this.formGp.controls['shift_name'].errors?.pattern && !this.formGp.controls['shift_name'].errors?.maxlength){
         this.formGp.controls['shift_name']?.setErrors(null)
      }
    }else{
      this.formGp.get('shift_code')?.markAsTouched();
    }
  }
}
