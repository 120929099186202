import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShiftAllowanceService {
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  getShiftAllocatList(status: any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-allowance-policy/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  shiftMappingDropDownData(status: any,limit:any,offset:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping-list-dropdown/?is_active=${status}&limit=${limit}&offset=${offset}&ordering=shift_mapping_name`, this.httpOption)
  }

  shiftList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-list-dropdown/?is_active=true&ordering=shift_name&shift_allowance_applicable=true`, this.httpOption)
  }
  shiftGet(id: number) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-allowance-policy/${id}`, this.httpOption)
  }
  shiftDelete(id: number) {
    return this.http.patch(`${this.API_PATH}attendance/api/v1/shift-allowance-policy/${id}/?is_active=false`,  { is_active: false },this.httpOption)
  }
  shiftCreate(data: any) {
    return this.http.post<any>(`${this.API_PATH}attendance/api/v1/shift-allowance-policy/`, data);

  }
  shiftUpdate(id: number,data: any) {
      return this.http.put<any>(`${this.API_PATH}attendance/api/v1/shift-allowance-policy/${id}/`, data);

  }
  
}
