import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoanProcessService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  // Get my loan details
  getMyLoanDetails(filter:any){
    return this.http.get(`${this.API_PATH}loan/api/v1/loan-profile-listing/?${filter}`, this.httpOption)
  }

  // Get specific loan data
  getSpecificLoan(id:any){
    return this.http.get(`${this.API_PATH}loan/api/v1/loan-profile-details/${id}`, this.httpOption)
  }

  // Loan Code Dropdown
  getLoanCodeDropdown(){
    return this.http.post(`${this.API_PATH}loan/api/v1/loan-code-populate/`, this.httpOption);
  }

  // Get Loan Types
  getLoanTypesDropdown(){
    return this.http.post(`${this.API_PATH}loan/api/v1/loan-types/`, this.httpOption);
  }

  hrLoanListing( offset:any,limit:any,filter:any,search:any){
    return this.http.get(`${this.API_PATH}loan/api/v1/loan-listing/?limit=${limit}&offset=${offset}&search=${search}${filter}`, this.httpOption)
  }

  hrLoanDetails(id:any){
    return this.http.get(`${this.API_PATH}loan/api/v1/loan-detail/${id}`, this.httpOption)
  }

  adjust(data:any){
    return this.http.post(`${this.API_PATH}loan/api/v1/loan-adjustment/`, data, this.httpOption)
  }

  getLoanTypeDetails(id:any){
    return this.http.post(`${this.API_PATH}loan/api/v1/admin-loan-type-details/${id}`, this.httpOption);
  }

  listLoanTypes(){
    return this.http.get(`${this.API_PATH}loan/api/v1/admin-loan-type-listing/`, this.httpOption);
  }

  listLoanCodes(){
    return this.http.get(`${this.API_PATH}loan/api/v1/admin-loan-code-populate/`, this.httpOption);
  }

  adjustDetails(id:any){
    return this.http.get(`${this.API_PATH}loan/api/v1/loan-adjusted-details/${id}`, this.httpOption)
  }

  importLoan(body:any){
    return this.http.post(`${this.API_PATH}loan/api/v1/loan-bulk-import/`,body, this.httpOption)
  }

  // Loan Process Details
  processDetails(id:any,from:any){
    if(from == 'hr'){
      return this.http.get(`${this.API_PATH}loan/api/v1/hr-loan-processDetails/${id}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}loan/api/v1/profile-loan-processDetails/${id}`,this.httpOption)
    }
  }
}
