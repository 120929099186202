<div class="row">
  <div class="col-12 col-lg-8">
    <app-calendar-loader *ngIf="loader"></app-calendar-loader>
    <ng-container *ngIf="!loader">
      <full-calendar #calendar id="fullcalendar"
        class="fullcalendar-reset fullcalendar-c3 fullcalendar-fulloption bg-white" [options]="calendarOptions">
      </full-calendar>
    </ng-container>
    <div class="d-flex flex-wrap pt-16 gap-16 fs-12">
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-regularized"></span>Regularized
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-deduction"></span>Deduction
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-punch"></span>Punch
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-on-duty"></span>On duty
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-WFH"></span>WFH
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-late"></span>Short Leave
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-override"></span>Override
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4">
    <div class="card card-c2 h-100">
      <div class="card-body flex-grow-0 p-16">
        <div class="d-flex align-items-center mb-16">
          <div class="fs-16 fw-500">Details</div>
          <ng-select *ngIf="activateAction!=false" class="form-ng-select  ms-auto w-50 fs-14" [items]="options"
            (change)="this.gotoAction.emit($event)" bindLabel="name" bindValue="name" placeholder="Action"></ng-select>
        </div>
        <div class="d-flex align-items-center" *ngIf="!loader">
          <div class="flex-center flex-column radius-4 me-16 bg15 text-light-500 avatar sq-72">
            <span class="fs-24 fw-500">{{clickedDate |date:'dd'}}</span>
            <span class="fs-14">{{activeMonth.slice(0,3)}}</span>
          </div>
          <div class="flex-column d-flex gap-4"
            *ngIf="!loader && attendanceDataArray[clickedDate]?.shift.from_time != 0 && attendanceDataArray[clickedDate]?.shift.from_time != null && attendanceDataArray[clickedDate]?.shift.to_time != null">
            <span
              class="fs-10 text-light-400 text-uppercase text-break">{{attendanceDataArray[clickedDate]?.shift.shift_name}}
              ({{attendanceDataArray[clickedDate]?.shift.shift_code}})</span>
            <span class="fs-12">{{clickedDate+"
              "+attendanceDataArray[clickedDate]?.shift.from_time|date:this.appservice.gettimeformat()}} to
              {{clickedDate+"
              "+attendanceDataArray[clickedDate]?.shift.to_time|date:this.appservice.gettimeformat()}}</span>
          </div>
        </div>
      </div>
      <div class="card-body p-16 overflow-auto" id="dayDetails-body">
        <app-c1-loader *ngIf="loader"></app-c1-loader>
        <ng-container *ngIf="!loader">
          <ngb-accordion class="custom-accordion" #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel id="ngb-panel-0">
              <ng-template ngbPanelHeader>
                <button ngbPanelToggle class="accordion-button">Scheme</button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row row-16">
                  <div class="col-6">
                    <div class="text-light-400 fs-12">First in</div>
                    <div class="fs-14"
                      *ngIf="attendanceDataArray[clickedDate]?.checkIn!='None' && attendanceDataArray[clickedDate]?.checkIn!=null">
                      {{clickedDate+" "+attendanceDataArray[clickedDate]?.checkIn|date:this.appservice.gettimeformat()}}
                    </div>
                    <div class="fs-14"
                      *ngIf="attendanceDataArray[clickedDate]?.checkIn=='None' || attendanceDataArray[clickedDate]?.checkIn==null">
                      --</div>
                  </div>
                  <div class="col-6">
                    <div class="text-light-400 fs-12">Last out</div>
                    <div class="fs-14"
                      *ngIf="attendanceDataArray[clickedDate]?.checkOut!='None' && attendanceDataArray[clickedDate]?.checkOut!=null">
                      {{clickedDate+"
                      "+attendanceDataArray[clickedDate]?.checkOut|date:this.appservice.gettimeformat()}}</div>
                    <div class="fs-14"
                      *ngIf="attendanceDataArray[clickedDate]?.checkOut=='None' || attendanceDataArray[clickedDate]?.checkOut==null">
                      --</div>
                  </div>
                  <div class="col-6">
                    <div class="text-light-400 fs-12">Total Hrs.</div>
                    <div class="fs-14"
                      *ngIf="appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.totalHour)">
                      {{clickedDate+" "+attendanceDataArray[clickedDate]?.totalHour|date:'HH:mm'}} Hrs.</div>
                    <div class="fs-14"
                      *ngIf="!appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.totalHour)">
                      --</div>
                  </div>
                  <div class="col-6">
                    <div class="text-light-400 fs-12">Actual Hrs.</div>
                    <div class="fs-14"
                      *ngIf="appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.actualHour)">
                      {{clickedDate+" "+attendanceDataArray[clickedDate]?.actualHour|date:'HH:mm'}} Hrs.</div>
                    <div class="fs-14"
                      *ngIf="!appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.actualHour)">
                      --</div>
                  </div>
                  <div class="col-6">
                    <div class="text-light-400 fs-12">Break Hrs.</div>
                    <div class="fs-14"
                      *ngIf="appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.break_hour)">
                      {{clickedDate+" "+attendanceDataArray[clickedDate]?.break_hour|date:'HH:mm'}} Hrs.</div>
                    <div class="fs-14"
                      *ngIf="!appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.break_hour)">
                      --</div>
                  </div>
                  <!-- //short leave -->
                  <div class="col-6" *ngIf="attendanceDataArray[clickedDate]?.short_leave_status">
                    <div class="text-light-400 fs-12">Short leave Hrs.</div>
                    <div class="fs-14"
                      *ngIf="appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.short_leave_hour)">
                      {{clickedDate+" "+attendanceDataArray[clickedDate]?.short_leave_hour|date:'HH:mm'}} Hrs.
                      <i *ngIf="attendanceDataArray[clickedDate]?.short_leave_details.length"
                        class="bi bi-info-circle text-accent4 fs-11 tooltip-icon " [ngbPopover]="popContenti"
                        popoverClass="popover-default popover-info" container="body"
                        triggers="mouseenter:mouseleave"></i>
                    </div>
                    <ng-template #popContenti>
                      <div style="text-align: center;">
                        <ng-container *ngFor="let shl of attendanceDataArray[clickedDate]?.short_leave_details">
                          <div>
                            {{clickedDate+' '+shl.duration|date:'HH:mm'}} Hours Short Leave<br>
                            {{clickedDate+" "+shl.from_time|date:this.appservice.gettimeformat()}} to
                            {{clickedDate+" "+shl.to_time|date:this.appservice.gettimeformat()}}
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="ngb-panel-1">
              <ng-template ngbPanelHeader>
                <button ngbPanelToggle class="accordion-button">Raw punches </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container *ngIf="attendanceDataArray[clickedDate]?.punchesArray.accepted.length>0">

                  <div class="fs-10 fw-500 text-success text-uppercase mb-8">Accepted</div>
                  <div class="row row-16">
                    <div class="col-6"
                      *ngFor="let i of attendanceDataArray[clickedDate]?.punchesArray.accepted;let ind = index">
                      <ng-template #popContent>
                        <div style="text-align: center;">{{i.remarktext}}<br>{{i.remark}}</div>
                      </ng-template>
                      <div class="text-light-400 fs-12  d-flex align-items-center gap-8">{{clickedDate+"
                        "+i?.punch_time|date:this.appservice.gettimeformat()}}
                        <span class="badge badge-success1 radius-4 fs-10 p-1" *ngIf="!i.punch_inout">IN</span>
                        <span class="badge badge-danger1 radius-4 fs-10 p-1" *ngIf="i.punch_inout">OUT</span>
                        <i *ngIf="i.remark!='' && i.remark!=null"
                          class="bi bi-info-circle text-accent4 fs-11 tooltip-icon " [ngbPopover]="popContent"
                          popoverClass="popover-default popover-info" container="body"
                          triggers="mouseenter:mouseleave"></i>
                      </div>
                      <div class="fs-14 d-flex" *ngIf="i.punch_type">{{i.punch_type}}
                        <!-- <a class="ms-1" *ngIf="i.is_location"><i class="icon-geo-alt-fill" ngbPopover="{{i.location}}"
                            popoverClass="popover-input popover-info" container="body" triggers="hover"
                            (click)="viewemployee(i)"></i></a>
                        <a class="ms-1"
                          *ngIf="from=='HR' && i.punch_type!=='Attendance Regularization' && i.punch_type!=='On Duty' && i.punch_type!=='WFH'">
                          <i class="icon-trash pointer link-danger" (click)="deletePunchConfirm(i?.id)"></i>
                        </a>
                        <a class="ms-1" *ngIf="i.selfie_image!='' && i.selfie_image!=null" (click)="viewProfileModal = true;viewProfileImage=i.selfie_image">
                          <i class="icon-gallery pointer link-primary1"></i>
                        </a> -->
                      </div>
                      <div class="fs-14 gap-8 d-flex" *ngIf="i.punch_type">
                        <a class="link-primary1" *ngIf="i.is_location"><i class="icon-geo-alt-fill"
                            ngbPopover="{{i.location}}" popoverClass="popover-input popover-info" container="body"
                            triggers="hover" (click)="viewemployee(i)"></i></a>
                        <a class="link-danger"
                          *ngIf="from=='HR' && i.punch_type!=='Attendance Regularization' && i.punch_type!=='On Duty' && i.punch_type!=='WFH'">
                          <i class="icon-trash pointer" (click)="deletePunchConfirm(i?.id)"></i>
                        </a>
                        <a class="link-primary1" *ngIf="i.selfie_image!='' && i.selfie_image!=null"
                          (click)="viewProfileModal = true;viewProfileImage=i.selfie_image">
                          <i class="icon-gallery pointer "></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="attendanceDataArray[clickedDate]?.punchesArray.pending.length>0">
                  <div class="fs-10 fw-500 text-warning text-uppercase mt-16 mb-8">Pending</div>
                  <div class="row row-16">
                    <div class="col-6"
                      *ngFor="let i of attendanceDataArray[clickedDate]?.punchesArray.pending;let ind = index">
                      <ng-template #popContent>
                        <div style="text-align: center;">{{i.remarktext}}<br>{{i.remark}}</div>
                      </ng-template>
                      <div class="text-light-400 fs-12  d-flex align-items-center gap-8">{{clickedDate+"
                        "+i?.punch_time|date:this.appservice.gettimeformat()}}
                        <span class="badge badge-success1 radius-4 fs-10 p-1" *ngIf="!i.punch_inout">IN</span>
                        <span class="badge badge-danger1 radius-4 fs-10 p-1" *ngIf="i.punch_inout">OUT</span>
                        <i *ngIf="i.remark!='' && i.remark!=null"
                          class="bi bi-info-circle text-accent4 fs-11 tooltip-icon " [ngbPopover]="popContent"
                          popoverClass="popover-default popover-info" container="body"
                          triggers="mouseenter:mouseleave"></i>
                      </div>
                      <div class="fs-14 d-flex" *ngIf="i.punch_type">{{i.punch_type}}
                        <!-- <a class="ms-1" *ngIf="i.is_location"><i class="icon-geo-alt-fill" ngbPopover="{{i.location}}"
                            popoverClass="popover-input popover-info" container="body" triggers="hover"
                            (click)="viewemployee(i)"></i></a>
                        <a class="ms-1"
                          *ngIf="from=='HR' && i.punch_type!=='Attendance Regularization' && i.punch_type!=='On Duty' && i.punch_type!=='WFH'">
                          <i class="icon-trash pointer link-danger" (click)="deletePunchConfirm(i?.id)"></i>
                        </a>
                        <a class="ms-1" *ngIf="i.selfie_image!='' && i.selfie_image!=null"
                          (click)="viewProfileModal = true;viewProfileImage=i.selfie_image">
                          <i class="icon-gallery pointer link-primary1"></i>
                        </a> -->
                      </div>
                      <div class="fs-14 gap-8 d-flex" *ngIf="i.punch_type">
                        <a class="link-primary1" *ngIf="i.is_location"><i class="icon-geo-alt-fill"
                            ngbPopover="{{i.location}}" popoverClass="popover-input popover-info" container="body"
                            triggers="hover" (click)="viewemployee(i)"></i></a>
                        <a class="link-danger"
                          *ngIf="from=='HR' && i.punch_type!=='Attendance Regularization' && i.punch_type!=='On Duty' && i.punch_type!=='WFH'">
                          <i class="icon-trash pointer  " (click)="deletePunchConfirm(i?.id)"></i>
                        </a>
                        <a class="link-primary1" *ngIf="i.selfie_image!='' && i.selfie_image!=null"
                          (click)="viewProfileModal = true;viewProfileImage=i.selfie_image">
                          <i class="icon-gallery pointer "></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="ngb-panel-2">
              <ng-template ngbPanelHeader>
                <button ngbPanelToggle class="accordion-button">Advance Details</button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row row-16">

                  <div class="col-6">
                    <div class="text-light-400 fs-12">Late in Hrs.</div>
                    <div class="fs-14 "
                      *ngIf="attendanceDataArray[clickedDate]?.late_coming_status && appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.lateIn)">
                      {{clickedDate+"
                      "+attendanceDataArray[clickedDate]?.lateIn|date:'HH:mm'}} Hrs.</div>
                    <div class="fs-14 "
                      *ngIf="!attendanceDataArray[clickedDate]?.late_coming_status || !appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.lateIn)">
                      --</div>
                  </div>
                  <div class="col-6">
                    <div class="text-light-400 fs-12">Early out Hrs.</div>
                    <div class="fs-14 "
                      *ngIf="attendanceDataArray[clickedDate]?.early_checkout_status && appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.earlyOut)">
                      {{clickedDate+"
                      "+attendanceDataArray[clickedDate]?.earlyOut|date:'HH:mm'}} Hrs.</div>
                    <div class="fs-14 "
                      *ngIf="!attendanceDataArray[clickedDate]?.early_checkout_status || !appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.earlyOut)">
                      --</div>
                  </div>
                  <div class="col-6">
                    <div class="text-light-400 fs-12">Overtime Hrs.</div>
                    <div class="fs-14 "
                      *ngIf="attendanceDataArray[clickedDate]?.overtime_status&& appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.overTime)">
                      {{clickedDate+"
                      "+attendanceDataArray[clickedDate]?.overTime|date:'HH:mm'}} Hrs.</div>
                    <div class="fs-14 "
                      *ngIf="!attendanceDataArray[clickedDate]?.overtime_status || !appservice.isValidDateTime(clickedDate+' '+attendanceDataArray[clickedDate]?.overTime)">
                      --</div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Delete punch data -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Punch?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('punch')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteToggle = false">Cancel</button>

        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClick"
          (click)="deletePunchData()">Delete</button>
      </div>
    </div>
  </div>
</div>
<div class="modal {{viewProfileModal ? 'show':''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:30.875rem">
    <div class="modal-content radius-24">
      <div class="modal-header px-24 ">
        <h5 class="modal-title ">Attendance image</h5>
        <button type="button" (click)="viewProfileModal = false" class="btn-close shadow-0" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body pb-32 px-24  mx-auto">
        <div class="row row-16">
          <div class="col-12">
            <img [src]="viewProfileImage" class="img-fluid radius-24 ">
          </div>

        </div>
      </div>
    </div>
  </div>
</div>