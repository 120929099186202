<div class="ng-autocomplete ">
    <i class="icon-search fs-14 "></i>
    <ng-autocomplete id="inputitem" #auto [data]="searchlist" [searchKeyword]="keyword" [customFilter]="defaultFilterFunction" [placeholder]="searchplaceholder" (inputChanged)="onChangeSearch($event)" (selected)='selectEvent($event)' (keyup.enter)="addToKeywords()" [(ngModel)]="keyinput"
        (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [isLoading]="isLoading">
    </ng-autocomplete>
    <ng-template #itemTemplate let-item>
        <div *ngIf="isShowList != false" class="drop-down-item">
            <ng-container *ngIf="item.image; else elseblock">
                <img class="imgitem" src="{{item.image}}" height="25">
            </ng-container>
            <ng-template #elseblock>
                <!-- <img class="imgitem logo-img" height="25"> -->
                <div class="item-image {{item.color_code}}"> {{this.appService.getFirstChar(item.name,1) | uppercase}}
                </div>
            </ng-template>
            <span class="text-trim" [innerHTML]="item.name"></span> &nbsp;
        </div>
    </ng-template>
    <ng-template  #notFoundTemplate let-notFound>
        <div *ngIf="searchlist.length" [innerHTML]="notFound"></div>
    </ng-template>
</div>
