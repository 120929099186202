
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='leaveRequestView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>{{from=='behalf'?title:'View'}}</h5>

      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-32 py-24">
        <div class="d-flex mb-16">
          <p class="text-uppercase fw-500 mb-0">leave request</p>
        </div>
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <ng-container *ngIf="!loader1">
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                  <div class="row row-24">
                      <div class="col-12 d-flex">
                          <span class="title">Leave details</span>
                          <span class=" fs-14 ms-auto px-2 fw-500 badge3" [ngClass]="{'badge-warning': leaveRequestData?.status === 'Pending','badge-success': leaveRequestData?.status === 'Accepted','badge-danger': leaveRequestData?.status === 'Rejected'}"  >
                            {{leaveRequestData?.status}}
                          </span>
                      </div>
                      <div class="col-12">
                        <div class="card card-c2 p-16">
                          <div class="row row-24">
                            <div class="col-md-4">
                              <div class="detail-title">Leave Type</div>
                              <div class="detail-desc">{{leaveRequestData?.leave_type_name}}</div>
                            </div>
                            <div class="col-md-4">
                              <div class="detail-title">From Date</div>
                              <div class="detail-desc">{{this.appservice.dateFormatDisplay(leaveRequestData?.from_date) +', '+leaveRequestData?.from_date_day}}</div>
                            </div>
                            <div class="col-md-4">
                              <div class="detail-title">To date</div>
                              <div class="detail-desc">{{this.appservice.dateFormatDisplay(leaveRequestData?.to_date)+', '+leaveRequestData?.to_date_day}}</div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">No. of Days</div>
                              <div class="detail-desc">{{leaveRequestData?.leave_num_days}}</div>
                            </div>
                            <div *ngIf="compOffData?.length > 0" class="col-12">
                              <div class="detail-title">Comp off date to compensate with</div>
                              <div class="detail-desc">{{compOffData?.join(', ')}}</div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Full Day</div>
                              <div class="detail-desc">{{(from == 'request' && leaveRequestData?.full_day_dates?.length == 0) ? '-' : processedDate('fullday').length == 0 ? '-' : processedDate('fullday').join(', ')}}</div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Half Day</div>
                              <div class="detail-desc">{{(from == 'request' && leaveRequestData?.half_day_dates?.length == 0) ? '-' : halfday().length == 0 ? '-' : halfday().join(', ')}}</div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="detail-title">Reason</div>
                              <div class="detail-desc">{{leaveRequestData?.reason}}</div>
                            </div>
                            <div class="col-sm-6 col-12" *ngIf="requestName == 'Leave Cancellation'">
                              <div class="detail-title">Reason for cancellation</div>
                              <div class="detail-desc">{{leaveRequestData?.remark}}</div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Document</div>
                              <div class="detail-desc" *ngIf="leaveRequestData?.document == ''">-</div>

                              <div  style="width:12.9375rem;" *ngIf="leaveRequestData?.document != ''">
                                <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                                  <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                                  <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                                    <span class="tips text-uppercase fs- fw-700 text-trim" *ngIf="leaveRequestData?.document != ''">{{ this.appservice.getDocName(leaveRequestData?.document) }}</span>
                                    <span class="tips-helper " *ngIf="leaveRequestData?.document != ''">{{ this.appservice.getDocName(leaveRequestData?.document) }}</span>
                                    <div class="d-flex gap-8">
                                      <div class="link-primary" (click)="viewDocument(leaveRequestData?.document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary" (click)="this.appservice.downloadFile(leaveRequestData?.document,getDocName(leaveRequestData?.document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="((from == 'request' || from=='behalf') && requestName != 'Leave Cancellation') || (from == 'myleave')">
      <footer class="side-panel-footer" *ngIf="(leaveRequestData?.status == 'Pending' || leaveRequestData?.status == 'Accepted' )  && (leaveRequestData?.cancellation_allowed)">
        <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm" (click)="cancelRequest(leaveRequestData?.status,leaveRequestData?.cancellation_raised_status,leaveRequestData?.arrear_valid,leaveRequestData?.yearend_cancel_status)">
            CANCEL REQUEST
        </button>
      </footer>

    <!-- Rquest cancel section -->
    <app-cancel-request-modal [requestCancel]="requestCancel" [requestName]="'leave'" [requestId]="requestId" [requestStatus]="leaveRequestData?.status" (modal)="closeCancelModal($event)" (updateStatus)="updateStatus($event)" [from]="from"></app-cancel-request-modal>
    </ng-container>
  </div>
</div>

<!-- File view setion -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close1($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<!-- Info popup start -->
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="investalertMsg"></app-info-popup>
</ng-container>
