import { Component, OnInit } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Location } from '@angular/common';
import { FormArray, FormBuilder,Validators ,FormGroup } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-final-settlement-setup',
  templateUrl: './final-settlement-setup.component.html',
  styleUrls: ['./final-settlement-setup.component.scss']
})

export class FinalSettlementSetupComponent implements OnInit {

  activeTab                     = 1;
  empId:any                     = "";
  inboxId:any                   = "";
  tab1touched                   = false;
  tab2touched                   = false;
  tab3touched                   = false;
  tab4touched                   = false;
  tab5touched                   = false;
  tab6touched                   = false;
  tab7touched                   = false;
  tab8touched                   = false;
  tab9touched                   = false;
  tab10touched                  = false;
  tab11touched                  = false;
  loader                        = false;
  showInfo                      = false;
  infoMsg:any;
  gratuityData:any              = [];
  noticePayAndClearenceData:any = [];
  otherPaymentsData:any         = [];
  processDetails:any            = [];
  payrollStatus:any;
  firstFormsave                 = true;
  secondFormsave                = true;
  currrentMonth                 = moment(new Date()).format('MMMM')
  currentYear                   = new Date().getFullYear();
  errnoticeCompmsg              = false;
  errclearenceCompmsg           = false;
  errBothCompmsg                = false;
  attendanceProcessFlag         : any
  errCompmsg = false
  errleaveCompmsg = false
  bothCompMsg = false;
  company_id:any = 0;
  is_attendance_lop = false;
  currency:any = '';

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    private _location: Location,
    private formBuilder:FormBuilder,
    private previousRouteService: PreviousRouteService,
    public datePipe: DatePipe,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.empId = parseInt(params['id']);
      if(!isNaN(params['inboxid']))
        this.inboxId = parseInt(params['inboxid']);
        this.getProcessDetails();
        this.resignationDatas();
        this.getEmployeeCompany();
      }
    });
  }

  finalSettlementForm: FormGroup = this.formBuilder.group({
    resignationDetails    : this.formBuilder.group({
      pay_month: ['',Validators.required],
      pay_year: ['',Validators.required],
    }),
    workDaysPayable       : this.formBuilder.group({

      present_days        : [''],
      weekoff_days        : [''],
      lop_days            : [''],
      total_payable_days  : [''],
      fnf_reversal_lop    : this.formBuilder.array([])

    }),

    gratuity             : this.formBuilder.group({
      payable_amount     : [],
      leave_payable_amount :[]
    }),

    noticePayClearence   : this.formBuilder.group({
      notice_paytype     : [''],
      no_of_days         : [''],
      no_of_days_edited  : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(5)]],
      total_amount       : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
      clearance_comments : ['',[Validators.maxLength(500)]],
      clearance_amount   : ['']
    }),

    otherComponents      : this.formBuilder.group({
      components         : this.formBuilder.array([])
    }),

    processFandF         : this.formBuilder.group({
      comments           : ['',[Validators.maxLength(500)]],
    }),

  });

  get workDaysPayableForm() {
    return this.finalSettlementForm.get('workDaysPayable') as FormGroup;
   }

  get gratuityForm() {
    return this.finalSettlementForm.get('gratuity') as FormGroup;
  }

  get noticePayClearenceForm() {
    return this.finalSettlementForm.get('noticePayClearence') as FormGroup;
  }

  get componentForm() {
    return this.finalSettlementForm.get('otherComponents') as FormGroup;
  }

  get processFandFForm() {
    return this.finalSettlementForm.get('processFandF') as FormGroup;
  }

   get resignationDetails() {
    return this.finalSettlementForm.get('resignationDetails') as FormGroup;
   }

  backClicked() {
    if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
      this.router.navigate(['final-settlement']);
    else
    this._location.back();
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId > 3  && this.activeTab == 2)
      this.verifyRequestPending()
    if (changeEvent.nextId === 2) {
      if(this.finalSettlementForm['controls'].resignationDetails.invalid || this.allowSave == false){
        changeEvent.preventDefault();
      }else{
        this.tab2touched =true;
        this.fnfList();
      }
    }
    else  if (changeEvent.nextId === 3) {
      changeEvent.preventDefault();
      this.verifyRequestPending().then(res=>{
        if(!this.pendingReqs || this.finalSettlementForm['controls'].resignationDetails.invalid || this.tab2touched == false || this.allowSave == false){

        }else{
          this.activeTab = 3;
          this.tab3touched = true;
        }
      })
    }else  if (changeEvent.nextId === 4) {

      if ( this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].workDaysPayable.invalid ||  this.tab3touched == false || this.attendanceProcessFlag == false || this.secondFormsave || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.tab4touched = true;
      }
    }else  if (changeEvent.nextId === 5) {

      if (this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].workDaysPayable.invalid ||  this.tab4touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.tab5touched = true;
      }
    }else  if (changeEvent.nextId === 6) {
      if (this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].workDaysPayable.invalid || this.tab5touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.tab6touched = true;
      }
    }else  if (changeEvent.nextId === 7) {
      if ( this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].workDaysPayable.invalid ||  this.tab6touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.tab7touched = true;
      }
    }else  if (changeEvent.nextId === 8) {
      if ( this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].workDaysPayable.invalid || this.tab7touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.tab8touched = true;
      }
    }else  if (changeEvent.nextId === 9) {

      if ( this.finalSettlementForm['controls'].resignationDetails.invalid  || this.finalSettlementForm['controls'].noticePayClearence.invalid || this.finalSettlementForm['controls'].workDaysPayable.invalid ||  this.tab8touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.getProcessDetails();
        this.tab9touched = true;
      }
    }
    else  if (changeEvent.nextId === 10) {
      if ( this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].gratuity.invalid || this.finalSettlementForm['controls'].noticePayClearence.invalid && this.finalSettlementForm['controls'].workDaysPayable.invalid ||  this.tab9touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.getProcessDetails();
        this.tab10touched = true;
      }
    }
    else  if (changeEvent.nextId === 11) {
      if ( this.finalSettlementForm['controls'].resignationDetails.invalid || this.finalSettlementForm['controls'].gratuity.invalid || this.finalSettlementForm['controls'].noticePayClearence.invalid && this.finalSettlementForm['controls'].workDaysPayable.invalid ||  this.tab10touched == false || this.allowSave == false) {
        changeEvent.preventDefault();
      }else{
        this.getProcessDetails();
        this.tab11touched = true;
      }
    }
  }

  submitOne(data:any){
    this.resignationDetails.get('pay_month')?.setValue(data?.pay_month)
    this.resignationDetails.get('pay_year')?.setValue(data?.pay_year)
    this.fnfList();
    this.tab2touched = true;
    this.activeTab   = 2;
  }

  submitTwo(){
    this.verifyRequestPending().then(res=>{

      if(this.pendingReqs){
      this.tab3touched = true;
      this.activeTab   = 3;
      }
    })
  }

  submitThree(data:any){
    this.tab4touched = true;
    this.activeTab   = 4;
  }

  submitFour(){
    this.tab5touched = true;
    this.activeTab   = 5;
  }

  submitFive(){
    this.tab6touched = true;
    this.activeTab   = 6;
  }

  submitSix(){
    this.tab7touched = true;
    this.activeTab   = 7;
  }

  submitSeven(){
    this.tab8touched = true;
    this.activeTab   = 8;
  }

  submitEight(){
    this.tab9touched = true;
    this.activeTab   = 9;
  }

  submitNine(data:any){
    if(data != ''){
      this.finalSettlementService.finalSettlementSave(data, this.inboxId).subscribe((res:any)=>{

      })
    }
    this.tab10touched = true;
    this.activeTab    = 10;

  }

  submitTen(){
    this.tab11touched = true;
    this.activeTab    = 11;
  }

  pendingReqs:boolean | undefined
  verifyRequestPending(){
    return new Promise<void>((resolve, reject) => {
      this.finalSettlementService.getResignationDetails(this.empId, 'resignation_details',this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((respons: any) => {
        if(respons?.data?.pending_request){
          this.showInfo = true;
          this.infoMsg  = 'All Pending requests needs to be answered before processing F&F!';
        }
        this.pendingReqs =  !respons?.data?.pending_request
        resolve();
      })
    });
  }

  getGratuityDetails(){
    this.loader = true;
    this.finalSettlementService.getResignationDetails(this.empId, 'gratuity', this.resignationDetails?.value?.pay_month, this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((res: any) => {
      this.gratuityData = res?.data;

        this.finalSettlementForm['controls'].gratuity.get('payable_amount')?.setValue(this.gratuityData?.payable_amount);
        if(this.gratuityData?.payable_encashed_amount){
        this.finalSettlementForm['controls'].gratuity.get('leave_payable_amount')?.setValue(this.gratuityData?.payable_encashed_amount);}
        else{
          this.finalSettlementForm['controls'].gratuity.get('leave_payable_amount')?.setValue(0)
        }
        this.finalSettlementForm['controls'].gratuity.get('payable_amount')?.setValidators([Validators.required, digitdecimalValidator(),Validators.maxLength(10)]);

        if((res?.data?.error == 'Gratuity component is not associated with the employees pay group.') || (res?.data?.gratuity_eligible == false)){
          this.errCompmsg = true;
          this.finalSettlementForm['controls'].gratuity.get('payable_amount')?.clearValidators();
        }if(res?.data?.error == 'Leave encashment component is not associated with the employees pay group.'){
          this.errleaveCompmsg = true
        }

        this.finalSettlementForm['controls'].gratuity.get('payable_amount')?.updateValueAndValidity();
        this.loader = false;


    },(error:any) => { // second parameter is to listen for error
      this.loader = false;

    });
  }

  getOtherPaymentData(){
    this.finalSettlementService.getResignationDetails(this.empId, 'notice_pay_and_clearance',this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((res: any) => {
      // if(res?.data?.error == null || res?.data?.error == '' ){
        if(res?.data?.notice_paytype == 'Notice Pay'){
          res.data.notice_paytype = 'Notice pay'
        }
        this.noticePayAndClearenceData = res?.data;

        this.finalSettlementForm['controls'].noticePayClearence.get('notice_paytype')?.setValue(this.noticePayAndClearenceData?.notice_paytype);
        this.finalSettlementForm['controls'].noticePayClearence.get('no_of_days_edited')?.setValue(this.noticePayAndClearenceData?.no_of_days_edited);
        this.finalSettlementForm['controls'].noticePayClearence.get('no_of_days')?.setValue(this.noticePayAndClearenceData?.no_of_days);

        this.noticePayAndClearenceData?.notice_id == null ? this.finalSettlementForm['controls'].noticePayClearence.get('total_amount')?.setValue(this.noticePayAndClearenceData?.amount) : this.finalSettlementForm['controls'].noticePayClearence.get('total_amount')?.setValue(this.noticePayAndClearenceData?.payable_amount);

        this.noticePayAndClearenceData?.clearance_id == null ? this.finalSettlementForm['controls'].noticePayClearence.get('clearance_amount')?.setValue(this.noticePayAndClearenceData?.clearance_amount) : this.finalSettlementForm['controls'].noticePayClearence.get('clearance_amount')?.setValue(this.noticePayAndClearenceData?.clearance_payable_amount);

        this.finalSettlementForm['controls'].noticePayClearence.get('clearance_comments')?.setValue(this.noticePayAndClearenceData?.clearance_comments);

    },(error:any) => { // second parameter is to listen for error

    });
  }

  getOtherPayments(){
    this.finalSettlementService.componentsDropdown(this.empId,this.resignationDetails.value.pay_month,this.resignationDetails.value.pay_year).subscribe((res: any) => {
      this.otherPaymentsData = res;
    });
  }

  getProcessDetails(){
    if(this.inboxId != ""){
      this.finalSettlementService.geInboxtProcessDetails(this.empId).subscribe((res: any) => {
        this.processDetails = res?.data;
        this.payrollStatus  = res?.data?.status;
      });
    } else {
      this.finalSettlementService.getProcessDetails(this.empId).subscribe((res: any) => {
        this.processDetails = res?.data;
        this.payrollStatus  = res?.data?.status;
      });
    }
  }

  getNoticePayAndClearence(){
    this.finalSettlementService.getResignationDetails(this.empId, 'other_payments',this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((res: any) => {
      for(let i=0; i< this.componentArray().length; i++){
        this.componentArray().removeAt(i)
      }
      if(res?.data?.length == 0){
        this.addComponentArray();
        }else{
        for(let i=0; i<res?.data?.length; i++){
          this.componentArray().removeAt(i)
          this.addComponentArray();
        }
        (<FormArray>this.finalSettlementForm.get("otherComponents")?.get("components")).reset(res?.data);
      }

    });
  }

  componentArray() : FormArray {
    return this.componentForm.get("components") as FormArray
  }

  newComponentArray(): FormGroup {

    return this.formBuilder.group({

      "pay_component"     : [null,[Validators.required,digitdecimalValidator()]],
      "pay_variable"            : [null,[Validators.required,digitdecimalValidator()]],
      "comments"          : [null,[Validators.maxLength(500)]],
      "no_of_days"        : [null],
      "component_name"    : [null],
      "formula"           : [null],
      "payable_amount"      : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
      "encashment_amount" : [null],
      "pay_component_type": [null],
      "exception_amount"  : [null]

    })
  }

  addComponentArray() {
    this.componentArray().push(this.newComponentArray());
  }

  unProcessWarning(){
    this.showInfo = true;
    this.infoMsg  = 'The F&F you are trying to edit is already processed. Please un-process the F&F before making any changes.';
  }

  loanWarningMsg(){
    this.showInfo = true
    this.infoMsg = 'The loan pay components are not allowed to be overridden in F&F.'
  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  payRollStatusEmitter(data:any){
    this.payrollStatus = data;
    this.resignationDatas();
  }

  allowSaveWorkDays(data:any){
    this.secondFormsave = data;
  }

  isSalaryProsessed(data:any){
    this.firstFormsave = data;
    this.allowSave     = data;
  }


  attendanceProcessed(data:any){
    this.attendanceProcessFlag = data;
  }

  fnfList(){
    if(this.resignationDetails?.value?.pay_month !=null && this.resignationDetails?.value?.pay_year !=null){
      this.resignationDatas();
      this.getOtherPaymentData();
      this.getNoticePayAndClearence();
      this.getGratuityDetails();
      this.getOtherPayments();
    }
  }


  getEmployeeCompany(){
    this.finalSettlementService.getEmployeeCompany(this.empId).subscribe((res:any)=>{
      this.company_id = res?.company_id;
    })
  }

  // test start
  resignationData:any = [];
  salary_hold:any     = [];
  resigloader         = false;
  allowSave           = true;
  errorMsg:any;

  resignationDatas(){
    this.salary_hold = [];
     this.resigloader = true;
     this.resignationData = [];
    let month = (this.resignationDetails.get('pay_month')?.value == null || this.resignationDetails.get('pay_month')?.value == '') ? '' : this.resignationDetails.get('pay_month')?.value;

    let year = (this.resignationDetails.get('pay_year')?.value == null || this.resignationDetails.get('pay_year')?.value == '') ? null : this.resignationDetails.get('pay_year')?.value;

    this.finalSettlementService.getResignationDetails(this.empId, 'resignation_details',month,year,this.inboxId).subscribe((res: any) => {

      this.resignationData = res?.data;
      this.currency        = res?.data?.currency;

      this.is_attendance_lop = res?.data?.attendance_lop;

      // test start
      if(res?.data?.settlement_month == null || res?.data?.settlement_month == '' || res?.data?.settlement_year == null || res?.data?.settlement_year == ''){
        this.resignationDetails.get('pay_month')?.setValue(this.currrentMonth);
        this.resignationDetails.get('pay_year')?.setValue(this.currentYear);
      }else{
        this.resignationDetails.get('pay_month')?.setValue(res?.data?.settlement_month);
        this.resignationDetails.get('pay_year')?.setValue(res?.data?.settlement_year);
      }
      // test end

      const leavingYearMonth = new Date(new Date(res?.data?.leaving_date).getFullYear(), new Date(res?.data?.leaving_date).getMonth());
      const settleYearMonth = new Date(this.resignationDetails.get('pay_year')?.value, this.appService.getMonthNumberFromName(this.resignationDetails.get('pay_month')?.value));

      if(this.resignationData?.salary_hold?.length > 0){
        for(let i=0; i<this.resignationData?.salary_hold?.length; i++){
          this.salary_hold.push(this.resignationData?.salary_hold[i]?.['pay_month']+' '+ this.resignationData?.salary_hold[i]?.['pay_year'])
        }
      }

      if(this.resignationData?.salary_structure_exist){
        if(this.resignationData?.relieving_month_payroll_details?.error == 'Salary is not processed for the leaving month, kindly process the salary before processing the Final settlement.'){
          this.errorMsg = "Salary is not processed for the leaving month, kindly process the salary before processing the Final settlement.";
          this.allowSave = false;
        }
        else if(this.resignationData?.relieving_month_payroll_details?.error == 'Salary is already Processed for the selected month.'){
          this.errorMsg = "Selected month salary is already processed.";
          this.allowSave = false;
        } else if(this.resignationData?.relieving_month_payroll_details?.error == 'Attendance is already processed for the selected month through payroll.'){
          this.errorMsg = "Selected month's attendance is processed in run payroll. Please process the salary from run payroll or unprocess the attendance from the run payroll  to  continue the settlement.";
          this.allowSave = false;
        }
        else if(this.resignationData?.relieving_month_payroll_details?.status == 'Unprocessed' && (this.datePipe.transform(leavingYearMonth, 'yyyy-MM') != this.datePipe.transform(settleYearMonth, 'yyyy-MM'))){
          this.errorMsg = "Salary unprocessed for the leaving month. Kindly set the leaving month as the settlement month.";
          this.allowSave = false;
        }
        else if(this.resignationData?.error != null && this.resignationData?.error != ''){
          this.errorMsg = this.resignationData?.error;
          this.allowSave = false;
        }else{
          this.errorMsg  = '';
          this.allowSave = true;
        }
      }else{
        this.errorMsg = "Employee's salary structure is not yet assigned.";
        this.allowSave = false;
      }

      this.resigloader = false;
    });
  }

  resignationEmitter(){
    this.resignationDatas();
  }
  // test end

  workDaysSaveData:any;
  lopOrAttendance:any;
  attendanceModuleWorkDaysSaveData(data:any){
    this.workDaysSaveData = data?.data;
  }

  lopOrAttendanceModule(data:any){
    this.lopOrAttendance = data;
  }



}
