<div class="container-fluid p-24 "  *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Letter</h3>
      <p class="text-helper mb-md-0">Access and download the published letters.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon " style="width:22.6875rem;">
          <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='searchplaceholder' [isLoading]="sLoading"
          (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
        </div>
        <ng-select class="form-ngselect " [clearable]="false" style="width:9.5rem"
          placeholder="{{this.messageService.selectplaceholddisplay('year')}}" [(ngModel)]="year"
           (change)="addEvent($event)">
        <ng-option *ngFor="let yData of yearRange" [value]="yData">{{yData}}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && loader==false;else all_data"  class="row" style="height: calc(100vh - 11rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg56.png'" [noDataText]="'Your letterbox is quiet'"
      [noDataPara]="'Currently no letters available for download. New letters will be added as they are published.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>

  <ng-template #all_data>
   <div class="row row-16 " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let empletterlist of empletterlist let i = index">
            <div class="card card-custom1 card-hover">
              <div class="card-body p-16 gap-4 d-flex align-items-start" >
                <div class="logo-img {{empletterlist?.color_code}}" ng-init="getRandomColor()">{{this.appService.getFirstChar(empletterlist?.letter_type,2)}}</div>
                <div class="overflow-hidden">
                  <div class="fw-500 fs-14 text-trim pe-16">{{empletterlist?.letter?.letter_template?.name}}</div>
                  <div class="  fs-10 fw-500 text-nowrap">{{empletterlist?.letter_type}}</div>
                </div>
              </div>
              <div class="card-body fs-12 d-flex gap-8">
              <div class=" text-trim">{{this.appService.dateFormatDisplay(empletterlist?.generated_date)}}</div>
                <div class="ms-auto d-flex   gap-8">
                  <a class="link-primary1" (click)="downLoad(empletterlist?.letter_pdf_url,empletterlist?.file_name)"><i class="icon-download me-1"></i> Download</a>
                  <a class="link-primary1" (click)="viewDocument(empletterlist?.letter_pdf_url,empletterlist?.file_name)"><i class="icon-eye me-1"></i> View</a>
                </div>
              </div>
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
   </div>
  </ng-template>
</div>

<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName">
</app-view-file>
