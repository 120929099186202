import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Form16Service } from 'src/app/@core/services/form16.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { AuthService } from 'src/app/@core/auth/auth.service';


@Component({
  selector: 'app-form16-setup',
  templateUrl: './form16-setup.component.html',
  styleUrls: ['./form16-setup.component.scss']
})
export class Form16SetupComponent implements OnInit {
  activeTab               = 1 ;
  form16A_Data:any        = "";/*Hold Part A Data*/
  form16B_Data:any        = "";/*Hold Part B Data*/
  generatedForm:any       = "";
  publishedForm:any       = "";
  fileNameA:any           = '';/*partA file name*/
  progress                = 0;
  loading:boolean         = true;
  deleteToggle:boolean    = false;
  percentDone: any        = "";
  iscompleted:boolean     = false;
  isProgressStart:boolean = false;
  generateLoader:boolean  = false;
  partAUpload:boolean     = true;
  partBUpload:boolean     = true;
  oldDataLoaderCount:any  = 0;/*Stepper Loader- Initial loading*/
  step5Status:any         = "";/*Step have data-Initial load*/
  fileNameB:any           = '';/*Hold part B file name*/
  form16A_File:any;           /*Step 2-File name*/
  form16B_File:any;           /*step 4 file name*/
  fileSizeA:any;              /*Part A file size*/
  fileSizeB:any;              /*Part B file size*/
  uploadedEmployee_A:any = "";/*Step1:Uploaded By data-name and date*/
  uploadedEmployee_B:any = "";/*step 3 :uploaded By data-name and date*/
  deletepopup_A:any="";          /*Employee name In delete popup-Part A*/
  deletepopup_B:any="";          /*Employee name in delete popup-Part B*/
  employeecode:any;
  partAData:any;
  partBData:any;
  financialyear:number = 0;



  constructor(private service:Form16Service,private notificationService:NotificationService,public router: Router,private _httpClient: HttpClient,private changeDetectorRef: ChangeDetectorRef,public appservice:AppService,private snapshotService: SnapshotService,private authService:AuthService,public route:ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if((params['financialyear'])){
        if (!isNaN(params['financialyear'])) {
          this.financialyear = params['financialyear'];
        }
      }
      this.getEmpBasicData();
      this.getAllPreviousData();
    });

  }
  getEmpBasicData() {
    if(this.authService.isEmployeeId!=0){

      this.snapshotService.getEmployeeBasicData(this.authService.isEmployeeId).subscribe((res: any) => {
        this.employeecode = res.employee_code ;
      })
    }
    else if(this.authService.isSuperUser()){
      this.employeecode = '';
    }


  }

  API_PATH: string = environment.apiPath;

  submitForm16A(data:any){
    if(this.partAUpload==true)
    this.progresscalculation(this.partAData,'partA','hr/api/v1/hr-employee-pan-list/');
    else
    this.activeTab        = 2;

  }
  Form16A_FileData(data:any){
    this.partAData  = data;
  }
  Form16B_FileData(data:any){
    this.partBData  = data;
  }

  submitForm16B(data:any){

    if(this.partBUpload==true)
    this.progresscalculation(this.partBData,'partB','hr/api/v1/hr-employee-pan-list/');
    else
    this.activeTab        = 4;
  }
  changeTab(tabIndex:number){
    if(tabIndex==5)
      this.generateForm16();
    else
      this.activeTab = tabIndex;
  }
  /*Step 1 data hold during swith the stepper*/
  Form16A_ZipFile(data:any){
    if(data.name != undefined)
      this.fileNameA          = data.name;
      this.fileSizeA          = data.size;
      this.uploadedEmployee_A = data.updatedEmp;
    if(data.name==''){
      this.form16A_Data = "";
      this.form16A_File = "";
      this.loading      = true;
      this.progress     = 0;
    }
  }
  /*Step 3 data hold during switch the stepper*/
  Form16B_ZipFile(data:any){
    if(data.name != undefined)
      this.fileNameB = data.name;
    this.fileSizeB          = data.size;
    this.uploadedEmployee_B = data.updatedEmp;

    if(data.name==''){
      this.form16B_Data = "";
      this.form16B_File = "";
    }
  }
  /*Step5 Data-data before and after publishing*/
  form16Data(status:any){
    this.generateLoader = true;
    // let currentYear     = this.appservice.getFinancialYear();
    let currentYear     = this.financialyear;
    let data            = {"year": currentYear,"published":status}
    this.service.generateForm16(data).subscribe((res:any)=>{
      this.oldDataLoaderCount ++;

      if(status==false){

        this.generatedForm = res.body;

      }
      else
      this.publishedForm  = res.body.data;
      this.generateLoader = false;


    })
  }
  /*Form 16 generation-click step next button*/
  generateForm16(){

    // let currentYear       = this.appservice.getFinancialYear();
    let currentYear       = this.financialyear;
    let data              = {"year": currentYear,"published":false};
    this.progresscalculation(data,'generate','hr/api/v1/employeeform16-generate/');

  }
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.form16A_Data=="") {
        changeEvent.preventDefault();
      }
    }
    else if(changeEvent.nextId === 4) {
      if (this.form16B_Data=="") {
        changeEvent.preventDefault();
      }
    }
    else if(changeEvent.nextId === 5) {

      if (this.generatedForm.data.length==0 && this.generatedForm.error_list.length==0) {
        changeEvent.preventDefault();
      }
    }
    else if(changeEvent.nextId === 6) {
      if (this.publishedForm=="") {
        changeEvent.preventDefault();
      }
    }
  }
//Generate form 16-move step6
  publishForm16(){
    this.generateLoader = true;
    // let currentYear   = this.appservice.getFinancialYear();
    let currentYear   = this.financialyear;
    let data          = {"year": currentYear,"published":false}
    this.service.publishForm16(data).subscribe((res:any)=>{
      this.form16Data(true);
      this.notificationService.handleSuccessNotification("Form 16 generation is completed successfully","Success");
      this.activeTab      = 6;
    })
  }
  // Publish form 16-Last step
  publishSaveForm16(){
    // let currentYear   = this.appservice.getFinancialYear();
    let currentYear   = this.financialyear;
    let data          = {"year": currentYear,"published":true}
    this.service.publishForm16(data).subscribe((res:any)=>{
      this.publishedForm  = res.body.data;
      this.notificationService.handleSuccessNotification("Form 16 published successfully","Success");
      this.router.navigate(['form-16']);

    })
  }

  progresscalculation(data:any,part:any,url:any){

    this.percentDone      = "0";
    this.iscompleted      = false;
    this.isProgressStart  = true;

    this._httpClient
    .post(`${this.API_PATH}${url}`, data, {
      reportProgress: true,
      observe: "events"
    }).subscribe((response: any) => {

      if (response.type === HttpEventType["UploadProgress"]) {
        const percentDone = Math.round(
          (100 * response.loaded) / response.total
        );
        this.percentDone = percentDone;
        if (this.percentDone === 100) {
          this.percentDone = this.percentDone;
          setTimeout(() => {
            this.percentDone = 99;
          }, 0);
          setTimeout(() => {
            if(response.body==undefined || response.body=='')
            this.percentDone = "";
          }, 2000);
        } else {
          this.percentDone = this.percentDone;
        }

      }


      if(response.body!=undefined && response.body!=''){
       this.percentDone = 100;

       if(part=='partA'){

        this.iscompleted        = true;
        this.form16A_File       = data.filename;
        this.deletepopup_A      = this.empDataDeletePopup(response.body.file_data.uploaded_by);
        this.partAUpload        = false;
        if(this.form16B_Data!=""){
          this.form16Data(false);
        }
        if(this.publishedForm!=""){
          this.form16Data(true);

        }
        setTimeout(() => {
          this.form16A_Data     = response.body;
          this.isProgressStart  = false;
          this.activeTab        = 2;

        }, 2000);

       }
       else if(part=='partB'){

        this.iscompleted        = true;
        this.form16B_File       = data.filename;
        this.deletepopup_B      = this.empDataDeletePopup(response.body.file_data.uploaded_by);
        this.partBUpload        = false;

        if(this.generatedForm!=""){
          this.form16Data(false);
        }
        if(this.publishedForm!=""){
          this.form16Data(true);
        }
        setTimeout(() => {
          this.isProgressStart  = false;
          this.form16B_Data     = response.body;
          this.activeTab        = 4;

        }, 2000);

       }
       else{
        this.iscompleted  = true;

        setTimeout(() => {
          this.isProgressStart  = false;
          this.generatedForm = response.body;
          this.activeTab    = 5;

        }, 2000);
       }

      }

    },
    (err: any) => {
      this.isProgressStart  = false;

    }
    );
  }
  empDataDeletePopup(data:any){

    let empData      = data.employee_code!=this.employeecode && data.employee_code!=null?data.name+" ("+data.employee_code+")":data.employee_code!=null?"you":data.name;
    return empData;
  }
  /*Get previously saved data:Part A,Partt B, Generted & published*/
  getAllPreviousData(){
    // let currentYear     = this.appservice.getFinancialYear();
    let currentYear     = this.financialyear;
    let partA           = {"year": currentYear,"doc_type": "partA"};
    let partB           = {"year": currentYear,"doc_type": "partB"};
    this.getPreviousUploadedFile(partA,"partA");
    this.getPreviousUploadedFile(partB,"partB");
    this.form16Data(false);
    this.form16Data(true);


  }
  /*Get previously uploaded part A and part B data*/
  getPreviousUploadedFile(data:any,type:any){
    this.service.createForm16(data).subscribe((res:any)=>{
      this.oldDataLoaderCount ++;

      if((res.body.file_data.file_name!=null && res.body.file_data.file_name!="") && type=="partA"){

        this.fileNameA          = res.body.file_data.file_name;
        this.form16A_File       = res.body.file_data.file_name;
        this.form16A_Data       = res.body;
        this.uploadedEmployee_A = this.mergeDateAndTime(this.form16A_Data);
        this.deletepopup_A      = this.empDataDeletePopup(res.body.file_data.uploaded_by);
        this.fileSizeA          = res.body.file_data.file_size;
        this.partAUpload        = false;
      }
      else if((res.body.file_data.file_name!=null && res.body.file_data.file_name!="") && type=="partB"){
        this.fileNameB          = res.body.file_data.file_name;
        this.form16B_Data       = res.body;
        this.form16B_File       = res.body.file_data.file_name;
        this.uploadedEmployee_B = this.mergeDateAndTime(this.form16B_Data);
        this.deletepopup_B      = this.empDataDeletePopup(res.body.file_data.uploaded_by);
        this.fileSizeB          = res.body.file_data.file_size;
        this.partBUpload        = false;


      }

    })

  }
  mergeDateAndTime(data:any){
    const Date              = moment(data.file_data.uploaded_time).format('YYYY-MM-DD');
    const Time              = moment(data.file_data.uploaded_time).format('HH:mm:ss');
    let convert_Time        = this.appservice.timeFormatDisplay(Time);
    let convert_Date        = this.appservice.dateFormatDisplay(Date);
    return(data.file_data.uploaded_by.name+" ("+convert_Time+", "+convert_Date+")");

  }
  deleteUploadedFile(type:any){
    // let currentYear     = this.appservice.getFinancialYear();
    let currentYear = this.financialyear;
    if(type=="partA" && this.form16A_Data==''){
      this.clearpartABData('partA',true);
    }
    else if(type=="partB" && this.form16B_Data==''){
      this.clearpartABData('partB',true);
    }
    else{

      let data            = {"year": currentYear,"doc_type": type};
      this.service.deleteUploadedData(data).subscribe((res:any)=>{
        if(type=="partA")
        this.clearpartABData('partA',false);
        else
        this.clearpartABData('partB',false);
        this.deleteToggle = false;
        this.form16Data(false);
        this.form16Data(true);
        this.changeDetectorRef.detectChanges();
        this.notificationService.handleSuccessNotification(res.body.message,"Success");

      })
    }
  }
  clearpartABData(type:any,haveData:boolean){
    if(type=="partA"){
      this.form16A_Data       = "";
      this.form16A_File       = "";
      this.uploadedEmployee_A = "";
      this.fileNameA          = "";
      this.deletepopup_A      = "";
      this.partAUpload        = true;
      if(haveData==true)
      this.notificationService.handleSuccessNotification("Part A file is successfully deleted.","Success");


    }
    else if(type=="partB"){
      this.form16B_Data       = "";
      this.form16B_File       = "";
      this.uploadedEmployee_B = "";
      this.fileNameB          = "";
      this.deletepopup_B      = "";
      this.partBUpload        = true;
      if(haveData==true)
      this.notificationService.handleSuccessNotification("Part B file is successfully deleted.","Success");


    }
  }


}
