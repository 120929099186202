import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-ar-late-coming-penalty',
  templateUrl: './ar-late-coming-penalty.component.html',
  styleUrls: ['./ar-late-coming-penalty.component.scss']
})
export class ArLateComingPenaltyComponent implements OnInit {

  constructor(public messageService : MessageService, public leaveType : leavetypeService, public fb : FormBuilder) { }
  @Input() formGp:any  = []
  @Input() detailsForm:any  = []

  submitted = false;
  @Input() leaveTypes   : any = []
  disData      : any = ['deduction_type','grace_day_come_late_go_early']
  disNoData      : any = ['deduction_type','grace_day_come_late_go_early']

  @Input()disabled = false;
  @Output() submitmethod = new EventEmitter();

  ngOnInit(): void {
    if(this.formGp.controls.late_coming_penalty.value){
      this.formGp.get('grace_day_come_late_go_early').setValidators([Validators.required])
      this.formGp.get('grace_day_come_late_go_early').updateValueAndValidity()
    }
  }
  ngAfterViewInit() {
    if(this.leave_prio().length > 0){
    this.updateDuplicateValidation()
    }

  }
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(3);
  }


  leave_prio() : FormArray{
    return this.formGp.get('prior_deduct_attendancerule') as FormArray
  }

  createLeaveFormGroup(): FormGroup {
    return this.fb.group({
      'leave_type': new FormControl(null,[Validators.required]),
      'priority': new FormControl(this.leave_prio().length+1)
    })
  }

  addLeaves(){
    this.leave_prio().push(this.createLeaveFormGroup())
  }

  setRequired(value: boolean,data:any) {
    if(value) {
    data.forEach((item:any)=>{
      this.formGp.get(item).setValidators([Validators.required])
    })
    } else {
      data.forEach((item:any)=>{
        this.formGp.get(item).clearValidators()
      })
    }
    data.slice(1).forEach((item:any)=>{
      this.formGp.get(item)?.setValue(null);
      this.formGp.get(item).updateValueAndValidity()
    })
  }
  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }


  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  deletePriority(index:number){
    this.leave_prio().removeAt(index);
  }

  setDisabled(value:any){
    if(value && this.leave_prio().length<= 0){
      this.addLeaves()
    } else {
      while ( this.leave_prio().length !== 0) {
        this.leave_prio().removeAt(0)
      }
    }
  }

  setCycle(){
    this.formGp.controls.late_come_go_early_deduction.value = false
  }
  leaveList:any
  duplicateLeaveType(index:any){
    let val = this.leave_prio()?.controls[index]?.get('leave_type')?.value
    for(let i=0;i<this.leave_prio().length;i++){
      if(i!=index){
      let valx = this.leave_prio()?.controls[i]?.get('leave_type')?.value
      if(val == valx){
        this.leave_prio()?.controls[index]?.get('leave_type')?.setErrors({
          'duplicate' : true
        })
      }
      }
    }
  }
  updateDuplicateValidation(){
    for(let i=1;i<this.leave_prio().length;i++){
      this.duplicateLeaveType(i)
    }
  }
}
