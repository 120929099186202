<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Shift Allowance Policy </h3>
      <p class="text-helper mb-md-0">Configure the shift rotation policy to automatically provide additional payment to employees for working in certain shifts.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='shiftsearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchshift($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13rem"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" routerLink="/shift-allowance/shift-allowance-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add
          Shift Allowance Policy 
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg7.png'" [noDataText]="'Start defining shift allowance Policy'"
      [noDataPara]="'Automate employee additional payments by defining shift allowance. Click \'+ Shift allowance Policy\' to start.'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()">
    </app-nodata-view>
  </div>
  <div class="row row-16">
    <ng-container *ngIf="loader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </ng-container>
  </div>
  <ng-template #all_data>
    <div class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let shift_data of shiftDetails;let i=index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewShift(shift_data?.id)">
                <div class="logo-img {{shift_data.color_code}}">{{this.appService.getFirstChar(shift_data?.name,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips" title="{{shift_data?.name}}">{{shift_data?.name}}</h6>
                  <span
                  class="fs-10 text-uppercase fw-500 {{(shift_data.is_active == true) ? 'text-success' : 'text-danger' }} ">
                  {{shift_data.is_active == true ? 'Active':'Deleted'}}
                 </span>
                  <!-- <div class="fs-10 text-uppercase fw-500  text-success ">Active</div> -->
                </div>
              </div>
              <div ngbDropdown class="dropdown-section"
                *ngIf="shift_data.is_active==true && (permissions.e || permissions.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngIf="permissions.e" (click)="editshiftallowance(shift_data?.id);">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button class="text-danger" ngbDropdownItem (click)="deleteAlert=true;deleteId=shift_data?.id"
                    *ngIf="permissions.d">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>  
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;" [class.side-pane-active]='viewDetail=== true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="viewDetail= false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">      
          <div class="container-fluid p-32">
          <app-view-loader *ngIf="loader1"></app-view-loader>
          <ng-container *ngIf="!loader1">
            <div class="row row-16">
              <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">{{shiftView?.name}}</p>
                <a class="link-primary fs-14 ms-auto text-nowrap" (click)="editshiftallowance(shiftView?.id);"><i class="icon-pencil-square me-1"></i>
                  Edit</a>
              </div>
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                  <div class="row row-16 ">
                    <div class="col-12 align-items-center d-flex">
                      <p class="title mb-8">SHIFT ALLOWANCE POLICY DETAILS</p>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Policy Name </div>
                      <div class="detail-desc">{{shiftView?.name}}</div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Description </div>
                      <div class="detail-desc">{{shiftView?.description}}</div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title"> Policy Valid From </div>
                      <div class="detail-desc">{{this.appService.dateFormatDisplay(shiftView?.policy_valid_from)}}</div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title">Select Shifts </div>
                      <div class="detail-desc d-flex gap-16 align-items-center">
                        <ng-container *ngFor="let shift_list of shiftView?.allowance_shift;let i=index">
                          <span><i class="icon-check-square-fill text-light-400 me-8"></i>{{shift_list?.shift?.shift_name}}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                  <div class="row row-16 ">
                    <div class="col-12 align-items-center d-flex">
                      <p class="title mb-8">CONFIGURE SHIFT ALLOWANCE</p>
                    </div>
                    <div class="col-12">
                      <div class="detail-title"> Do you want to set shift allowance calculations for week days?</div>
                      <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>{{shiftView?.set_shiftallowance_workday?'Yes':'No'}} </div>
                    </div>
                    <div class=" col-12"  *ngIf="shiftView?.set_shiftallowance_workday">
                      <div class="detail-title"> Minimum work hours in a day required for shift allowance eligibility </div>
                      <div class="detail-desc"> {{shiftView?.min_shiftallowance_hours_workday}} Hours</div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title"> Do you want to set shift allowance calculations for weekoffs?</div>
                      <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i> {{shiftView?.set_shiftallowance_weekoff?'Yes':'No'}}</div>
                    </div>
                    <div class=" col-12" *ngIf="shiftView?.set_shiftallowance_weekoff">
                      <div class="detail-title"> Minimum work hours in a day required for shift allowance eligibility </div>
                      <div class="detail-desc"> {{shiftView?.min_shiftallowance_hours_weekoff}} Hours</div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title"> Do you want to set shift allowance calculations for holidays?</div>
                      <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i> {{shiftView?.set_shiftallowance_holiday?'Yes':'No'}}</div>
                    </div>
                    <div class=" col-12" *ngIf="shiftView?.set_shiftallowance_holiday">
                      <div class="detail-title"> Minimum work hours in a day required for shift allowance eligibility </div>
                      <div class="detail-desc"> {{shiftView?.min_shiftallowance_hours_holiday}} Hours</div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title"> Do you want to set shift allowance calculations for half working days?
                      </div>
                      <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i> {{shiftView?.set_shiftallowance_half_workday?'Yes':'No'}}</div>
                    </div>
                    <div class=" col-12" *ngIf="shiftView?.set_shiftallowance_half_workday">
                      <div class="detail-title"> Minimum work hours in a day required for shift allowance eligibility </div>
                      <div class="detail-desc"> {{shiftView?.min_shiftallowance_hour_half_workday}} Hours</div>
                    </div>
                    <ng-container *ngFor="let shift_list of shiftView?.allowance_shift;let i=index">
                      <div class="col-12">
                        <div class="card details-card shadow-0 p-16">
                          <div class="row row-16">
                            <div class="col-12 align-items-center d-flex">
                              <p class="title mb-8">{{shift_list?.shift?.shift_name}}</p>
                            </div>
                            <div class="col-sm-4 col-12">
                              <div class="detail-title">Shift Code</div>
                              <div class="detail-desc">{{shift_list?.shift?.shift_code}}</div>
                            </div>
                            <div class="col-sm-8 col-12">
                              <div class="detail-title">Shift Name</div>
                              <div class="detail-desc">{{shift_list?.shift?.shift_name}}</div>
                            </div>
                          <ng-container *ngFor="let shift_listmap of shift_list?.shift_allowance_mapping;let i=index">
                            <div class="col-sm-6 col-12">
                              <div class="detail-title">Worked between(Days)</div>
                              <div class="detail-desc">{{shift_listmap?.day_start}} to {{shift_listmap?.day_end}} in a month</div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="detail-title">Pay rate</div>
                              <div class="detail-desc">{{shift_listmap?.pay_rate}} </div>
                            </div>
                          </ng-container>                           
                          </div>
                        </div>
                      </div>
                    </ng-container>                
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                  <div class="row row-16 ">
                    <div class="col-12 align-items-center d-flex">
                      <p class="title mb-8">APPLICABILITY</p>
                    </div>
                    <div class="col-12">
                      <div class="detail-title">Applicable to</div>
                      <div class="detail-desc">{{shiftView?.applicability?.applicable}}</div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title">{{shiftView?.applicability?.applicable}}(s) </div>
                        <div class="detail-desc">
                          <div class="selected-values mt-1" >
                           <ng-container *ngIf="shiftView?.applicability?.applicable == 'Employee'">
                            <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                              <div class="ng-value-label" title="{{rights['first_name']+rights['middle_name']+rights['last_name'] + ' (' + rights['employee_code'] +' )'}}">
                                <span class="d-inline-flex align-items-center">
                                  <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}" *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                                  <span class="avatar sq-24 avatar-circle {{rights.color_code}}" *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.first_name,2)}}</span>
                                    <span> {{rights['first_name']+(rights['middle_name']!=null && rights['middle_name']!=""?" " +rights['middle_name']:"")+" "+rights['last_name'] +" ("+rights['employee_code']+")"}}</span>
                                 </span>
                              </div>
                            </li>
                           </ng-container>
                           <ng-container *ngIf="shiftView?.applicability?.applicable == 'Company'">
                            <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                              <div class="ng-value-label" title="{{rights.company_name}}">
                                <span class="d-inline-flex align-items-center">
                                  <img class="img-fluid rounded-circle sq-24"  src="{{rights.logo}}" *ngIf="rights.logo !=  '' && rights.logo !=  null">
                                  <span class="avatar sq-24 avatar-circle {{rights.color_code}}"  *ngIf="rights.logo ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                    <span> {{rights.company_name}}</span>
                                 </span>
                              </div>
                            </li>
                           </ng-container>
                           <ng-container *ngIf="(shiftView?.applicability?.applicable != 'Employee') && (shiftView?.applicability?.applicable != 'Company')">
                                  <div class="d-flex flex-wrap mt-8 gap-8" >
                                    <ng-container *ngFor="let rights of assignees, let i=index;">
                                      <span class="badge3"  title="{{rights.name}}"> {{ (rights.length>25)? (rights.name | slice:0:25)+'..':(rights.name)  }}
                                      </span>
                                    </ng-container>

                                  </div>
                          </ng-container>
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Shift?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Shift Allowance  Policy')}}

        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false;deleteClicked=false;">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="confirmDelete(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>