<div class="container-fluid p-32 form-container" *ngIf="permissions">
  <div class="row row-24 " >
      <div class="col-12 d-flex flex-wrap align-items-start">
          <div class="mb-0 fw-500 text-uppercase">LOP Reversal - {{month}} {{year}}</div>
          <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">
              <!-- <a *ngIf="countSelected()>=1 && permissions?.a" class="link-danger text-nowrap" (click)="bulkDelete()"><i class="icon-trash me-8 "></i>Delete</a> -->
              <div style="min-width:22.688rem;">
                <div class="form-icon2">
                  <i class="icon-search icon-left fs-14"></i>
                  <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                  <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1" autocomplete="off"/>
                </div>
              </div>
              <!-- <div  class="d-inline-block" >
                  <button class="btn-square btn btn-secondary">
                      <i class="icon-filter-left fs-13"></i>
                  </button>
              </div> -->
              <button *ngIf="permissions?.a && status=='Unprocessed'" class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 " (click)="showSidePanel=true;cleardoc();">IMPORT </button>
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [checked]='status=="Unprocessed"' (click)='status="Unprocessed";searchString=""' [disabled]="loader">
                  <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">PENDING</label>
                  <input type="radio" class="btn-check"  name="btnradio" id="btnradio2" autocomplete="off" [checked]='status=="Processed"' (click)='status="Processed";searchString=""' [disabled]="loader">
                  <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">COMPLETED</label>
                </div>
          </div>
      </div>
      <div class="col-12" *ngIf="validatedLopData.length>0 && Isalert" >
          <div role="alert" class="alert alert-warning show mb-0">
              <p class="mb-0 fs-14">Click on the editable fields to update the data</p>
              <div class="btn-close pointer" (click)="Isalert=false;"></div>
          </div>
      </div>
      <div class="col-12">
          <div class="card card-c2 p-0">
            <app-table-loader *ngIf="loader"></app-table-loader>
            <div *ngIf="!loader" c class="table-responsive radius-4">
              <table *ngIf="status=='Unprocessed'" class="table  td-12  sticky-header td-fs-14
                  tr-fs-14  table-sm form-table  table-striped">
                      <thead>
                          <tr>
                             <th class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                                    </th>
                              <th class="fw-600" scope="col">EMP CODE</th>
                              <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                               <th class="fw-600" scope="col">SALARY STATUS</th>
                              <!-- <th class="fw-600" scope="col">LOP MOnth</th> -->
                              <th class="fw-600" scope="col">LOP MOnth</th>
                              <th class="fw-600" scope="col">ACTUAL LOP</th>
                              <th class="fw-600" scope="col">LOP REVERSAL DAYS</th>
                              <th class="fw-600" scope="col">COMMENT</th>
                              <!-- <th class="fw-600" scope="col"> </th> -->


                          </tr>
                      </thead>
                      <tbody class="height-full">
                        <ng-container *ngIf="validatedLopData.length==0">
                          <tr>
                            <td colspan="9">

                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                <span class="fw-500 fs-14">No data found</span>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <tr *ngFor="let item of $any(validatedLopData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                           <td class="form-cell">
                                            <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked">
                                        </td>

                          <td>
                            <!-- <div class="text-break">
                                <div class="{{item?.employee?.is_valid?'cell-error is-invalid':''}}">
                                  {{item.employee.data}}
                                </div>
                                <div class="invalid-feedback"  *ngIf="item?.employee?.is_valid">{{item?.employee.error}}
                                </div>
                             </div> -->
                             <div class="d-flex align-items-center gap-8 {{item?.employee?.is_valid ? 'cell-error':''}}">
                               <p class="fs-14 mb-0 text-trim w-15ch">{{item.employee.data}}</p>
                               <app-table-input-popover [errorCheck]="item?.employee?.is_valid" [errorData]="item?.employee.error"></app-table-input-popover>
                             </div>
                          </td>
                          <td class="form-cell">
                            <ng-select appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Employee')}}" [(ngModel)]="item.employee_id" (change)="setEmpCode($event,validatedLopData.indexOf(item))" [items]="empDD"
                            class="form-ngselect"
                            bindValue="id" bindLabel="fullname" [clearable]="false">
                                <!-- <ng-container *ngFor="let emp of empDD;let i=index"> -->
                                  <!-- <ng-container *ngIf="!selctedData.includes(emp.id) || item.employee_id==emp.id "> -->
                                    <ng-template ng-label-tmp let-item="item">
                                      <span class="ng-value-label">
                                        <div class="d-inline-flex align-items-center">
                                          <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null" class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1" ><span>{{this.appService.getFirstChar(item.fullname,2)}}</span></div>
                                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.profile_image !=  '' && item.profile_image !=  null" [src]="item.profile_image" >
                                              {{item.fullname}}
                                         </div>
                                       </span>   </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                      <span class="ng-value-label">
                                        <div class="d-inline-flex align-items-center">
                                          <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null" class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1" ><span>{{this.appService.getFirstChar(item.fullname,2)}}</span></div>
                                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.profile_image !=  '' && item.profile_image !=  null" [src]="item.profile_image" >
                                              {{item.fullname}}
                                         </div>
                                       </span>
                                     </ng-template>
                                  <!-- </ng-container> -->
                                <!-- </ng-container> -->
                             </ng-select>

                          </td>
                          <td >

                            <span *ngIf="item?.payroll_status=='Publish'"  class="badge badge-success py-1 px-3">Published</span>
                            <span *ngIf="item?.payroll_status=='Unprocessed'"  class="badge badge-danger py-1 px-3">{{item?.payroll_status}}</span>
                            <span *ngIf="item?.payroll_status=='Processed'"  class="badge badge-warning py-1 px-3">{{item?.payroll_status}}</span>
                            <span *ngIf="item?.payroll_status=='Hold'"  class="badge badge-primary py-1 px-3">Held</span>
                            <span *ngIf="item?.payroll_status=='Freeze'"  class="badge badge-holiday py-1 px-3">Freezed</span>
                          </td>
                          <td class="td-dropdown">
                            <!-- <div ngbDropdown container="body">
                              <button class=" {{(item?.lop_month?.is_valid) ?'cell-error is-invalid':''}}" type="button" class="card card-c2" ngbDropdownToggle>{{item.lop_month?.data}} {{item.lop_year.data}}</button>
                              <div ngbDropdownMenu class="card-c2 card p-24" style="min-width:480px" >
                                <div class="row">
                                  <div class="col-4">
                                    <label for="" class="form-label required">Year </label>
                                    <ng-select class="form-ngselect" [(ngModel)]="item.lop_year.data" (ngModelChange)="getlopmonth($event,validatedLopData.indexOf(item))" [placeholder]="listOfYearkey">
                                      <ng-option *ngFor="let pay_year of payrollYears[validatedLopData.indexOf(item)]" [value]="pay_year">{{pay_year}}</ng-option>
                                       </ng-select>
                                  </div>
                                  <div class="col-8">
                                    <label for="" class="form-label required">Month </label>
                                    <ng-select class="form-ngselect" [(ngModel)]="item.lop_month.data" (ngModelChange)="getlopmonth($event,validatedLopData.indexOf(item))" [placeholder]="listOfMonthkey">
                                      <ng-option *ngFor="let pData of payrollData[validatedLopData.indexOf(item)]" [value]="pData.pay_month">{{pData.pay_month}}</ng-option>
                                       </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="item?.lop_month?.is_valid" class="invalid-feedback">
                                <div>{{item?.lop_month?.error}}</div>
                              </div>
                            </div> -->
                            <div class="form-icon {{(item?.lop_month?.is_valid) ?'icon-right':''}}">
                              <div ngbDropdown container="body">
                                <button class=" {{(item?.lop_month?.is_valid) ?'cell-error is-invalid':''}}" type="button" class="card card-c2"
                                  ngbDropdownToggle>{{item.lop_month?.data}} {{item.lop_year.data}}</button>
                                <div ngbDropdownMenu class="card-c2 card p-24" style="min-width:480px">
                                  <div class="row">
                                    <div class="col-4">
                                      <label for="" class="form-label required">Year </label>
                                      <ng-select class="form-ngselect" [(ngModel)]="item.lop_year.data"
                                        (ngModelChange)="getlopmonth($event,validatedLopData.indexOf(item))" [placeholder]="listOfYearkey">
                                        <ng-option *ngFor="let pay_year of payrollYears[validatedLopData.indexOf(item)]"
                                          [value]="pay_year">{{pay_year}}</ng-option>
                                      </ng-select>
                                    </div>
                                    <div class="col-8">
                                      <label for="" class="form-label required">Month </label>
                                      <ng-select class="form-ngselect" [(ngModel)]="item.lop_month.data"
                                        (ngModelChange)="getlopmonth($event,validatedLopData.indexOf(item))" [placeholder]="listOfMonthkey">
                                        <ng-option *ngFor="let pData of payrollData[validatedLopData.indexOf(item)]"
                                          [value]="pData.pay_month">{{pData.pay_month}}</ng-option>
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <app-table-input-popover  [errorCheck]="item?.lop_month?.is_valid" [errorData]="item?.lop_month?.error"></app-table-input-popover>
                            </div>

                          </td>

                          <td>{{item.actual_lop}}</td>
                          <td>
                            <!-- <input type="text"
                            class="hasError form-control {{(item.hasOwnProperty('reversal_lop_pattern') && item.reversal_lop_pattern==true) || (item.reversal_lop.is_valid) ?'is-invalid':''}}" [disabled]="item.actual_lop == 0 || item.actual_lop=='undefined'|| item.actual_lop==undefined"
                            [(ngModel)]="item.reversal_lop.data" placeholder="{{this.messageService.placeholderdisp('Reversal lop days')}}" (keyup)="validateData('reversal_lop',validatedLopData.indexOf(item))"/>
                            <div *ngIf="item.hasOwnProperty('reversal_lop_pattern') && item.reversal_lop_pattern==true" class="invalid-feedback">
                              <div >{{item?.reversal_lop_msg}}</div>
                            </div>
                            <div *ngIf="item?.reversal_lop?.is_valid" class="invalid-feedback {{(item?.reversal_lop_msg)?'d-none':''}}">
                              <div>{{item.reversal_lop.error}}</div>
                            </div> -->
                            <div class="form-icon {{(item.hasOwnProperty('reversal_lop_pattern') && item.reversal_lop_pattern==true) || (item.reversal_lop.is_valid) ?'icon-right':''}}">
                              <input type="text"
                                class=" form-control {{(item.hasOwnProperty('reversal_lop_pattern') && item.reversal_lop_pattern==true) || (item.reversal_lop.is_valid) ?'is-invalid':''}}"
                                [disabled]="item.actual_lop == 0 || item.actual_lop=='undefined'|| item.actual_lop==undefined"
                                [(ngModel)]="item.reversal_lop.data" placeholder="{{this.messageService.placeholderdisp('Reversal lop days')}}"
                                (keyup)="validateData('reversal_lop',validatedLopData.indexOf(item))" />
                              <app-table-input-popover [errorCheck]="item.hasOwnProperty('reversal_lop_pattern') && item.reversal_lop_pattern==true"
                                [errorData]="item?.reversal_lop_msg"></app-table-input-popover>
                              <app-table-input-popover *ngIf="item?.reversal_lop_msg" [errorCheck]="item?.reversal_lop?.is_valid" [errorData]="item.reversal_lop.error"></app-table-input-popover>
                            </div>
                          </td>

                          <td>
                            <!-- <input  type="text"
                            class="hasError form-control {{(item.comment.is_valid) ?'is-invalid':''}}"
                            [(ngModel)]="item.comment.data" placeholder="{{this.messageService.descriptiontext('comment')}}" (keyup)="validateData('comment',validatedLopData.indexOf(item))"/>
                            <div *ngIf="item?.comment?.is_valid" class="invalid-feedback">
                              <div>{{item.comment.error}}</div>
                            </div> -->
                            <div class="form-icon {{(item.comment.is_valid) ?'icon-right':''}}">
                              <input type="text" class="hasError form-control {{(item.comment.is_valid) ?'is-invalid':''}}"
                                [(ngModel)]="item.comment.data" placeholder="{{this.messageService.descriptiontext('comment')}}"
                                (keyup)="validateData('comment',validatedLopData.indexOf(item))" />
                              <app-table-input-popover [errorCheck]="item?.comment?.is_valid"
                                [errorData]="item.comment.error"></app-table-input-popover>
                            </div>
                          </td>
                          <td *ngIf="permissions?.a">
                            <i  class="icon-trash pointer" (click)="deleteRow(validatedLopData.indexOf(item))"></i>
                          </td>
                        </tr>
                      </tbody>
              </table>
              <table *ngIf="status=='Processed'" class="table sticky-header  td-12 td-fs-14 tr-fs-14 table-sm form-table table-striped sticky-first-col sticky-last-col">
                      <thead>
                          <tr>
                            <th class="form-cell">
                              <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                          </th>
                              <th class="fw-600" scope="col">EMP CODE</th>
                              <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                              <th class="fw-600" scope="col">SALARY STATUS</th>
                              <th class="fw-600" scope="col">LOP MOnth</th>
                              <th class="fw-600" scope="col">ACTUAL LOP</th>
                              <th class="fw-600" scope="col">LOP REVERSAL DAYS</th>
                              <th class="fw-600" scope="col">COMMENT</th>
                              <th class="fw-600" scope="col" *ngIf="permissions?.a"> </th>

                          </tr>
                      </thead>
                      <tbody class="height-full">
                        <ng-container *ngIf="historyData.length==0">
                          <tr>
                            <td colspan="9">

                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                <span class="fw-500 fs-14">No data found</span>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <tr *ngFor="let item of $any(historyData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                          <td class="form-cell"><input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked"></td>
                          <td >{{item.employee_code}}</td>
                          <td >{{item.employee_name}}</td>
                          <td >
                            <span *ngIf="item?.payroll_status=='Publish'"  class="badge badge-success py-1 px-3">Published</span>
                            <span *ngIf="item?.payroll_status=='Unprocessed'"  class="badge badge-danger py-1 px-3">{{item?.payroll_status}}</span>
                            <span *ngIf="item?.payroll_status=='Processed'"  class="badge badge-warning py-1 px-3">{{item?.payroll_status}}</span>
                            <span *ngIf="item?.payroll_status=='Hold'"  class="badge badge-primary py-1 px-3">Held</span>
                            <span *ngIf="item?.payroll_status=='Freeze'"  class="badge badge-holiday py-1 px-3">Freezed</span>
                        </td>
                          <td >{{item.lop_month}} {{item.lop_year}}</td>
                          <td >{{item.actual_lop}}</td>
                          <td >{{item.reversal_lop}}</td>
                          <td >{{item.comment}}</td>
                          <td *ngIf="permissions?.a">
                            <i  class="icon-trash pointer" (click)="deleteRow(historyData.indexOf(item))"></i>
                          </td>
                        </tr>
                      </tbody>
              </table>
            </div>
          </div>
      </div>
      <div class="col-12"  *ngIf="status=='Unprocessed'" >
        <button  *ngIf="permissions?.a" class="btn btn-outline-primary radius-2" (click)="addEmployee()">Add Employee</button>
      </div>
      <div class="col-12" *ngIf="filteredcount()>pageSize">
        <ngb-pagination class="d-flex justify-content-end"  [pageSize]="pageSize"  [collectionSize]="filteredcount()" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
       </div>
   </div>

</div>
<footer class="submit-footer  gap-16" *ngIf="status=='Unprocessed' ">
  <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="bulkDelete()" *ngIf="countSelected()>=1 && permissions?.a">Delete</button>
  <p class="fs-14 ms-auto d-flex gap-8 mb-0"  *ngIf="validatedLopData.length>0">
    <span class="">{{countSelected()}} of {{validatedLopData.length}} selected </span>
    <span *ngIf="validatedLopData.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{validatedLopData?.length}} employees</span>
    <span *ngIf="validatedLopData.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{validatedLopData?.length}} employees</span>
</p>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="(validatedLopData.length==0) || (selctedData==0) ||  countSelected()==0 " (click)="confirmation()">SAVE</button>
</footer>


<div class="side-panel" style="--sidepanel-width:56.875rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Import LOP Reversal - {{month}} {{year}}</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
            <div class="row row-24 mb-24">
              <div class="col-12">
                  <div class="card card-c2 p-24">
                        <div class="title mb-24">LOP REVERSAL - {{month}} {{year}}</div>
                        <div class="row" *ngIf="fileName">
                          <div class="col-md-6">
                            <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                              <div class="d-flex p-24 ">
                                  <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                  <span class="text-trim fw-500">{{fileName}}</span>
                                  <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                  <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()" ><i class="bi bi-trash ms-8 "></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      <label for="upload"  [class.disabled]="fileName != ''" style="max-width: 18.375rem;" class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}" >
                          <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null" (change)="onFileChange($event)" id="upload" hidden>
                          Import LOP Reversal
                      </label>
                      <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                   </div>
              </div>
              <div class="col-12">
                    <div class="card card-c2 p-24">
                        <div class="title mb-24">Instructions</div>
                        <ol class="fs-14 ps-16 mb-0 li-24">
                              <li>Download the <a class="link-primary" href="assets/docs/sample-lop-reversal-import.xlsx"> <i class="icon-download me-1"></i> Sample Excel
                                template</a></li>
                            <li>To import the LOP Reversal details of the employees, click on the Import LOP Reversal
                                button.</li>
                            <li>Choose the file to be imported.</li>
                            <li>The file chosen should be in (.xls/.csv)format only.</li>
                        </ol>
                    </div>
              </div>
            </div>
          </div>
      </div>
      <footer class="side-panel-footer gap-32 pt-16">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || submiited" type="button" (click)="uploadData()">Upload</button>
      </footer>
  </div>
</div>
<!-- error download -->
<!-- <div  class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
<div class="modal-dialog modal-dialog-centered ">
  <div class="modal-content">
        <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title" *ngIf="successDataCount>0">{{successDataCount}} LOP Reversal data uploaded successfully.</h4>
        <h4 class="modal-title" *ngIf="successDataCount==0">No LOP Reversal data uploaded</h4>
        </div>
        <div *ngIf="failedData.length>0" class="modal-body">
                <p class="mb-0">{{failedData.length}} error data found.</p>
                <p class="mb-0">Click on download to view the error details</p>
        </div>
        <div  class="modal-footer">
            <button *ngIf="failedData.length>0" type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="downloadMsg = false;bulkDelete();">Close</button>
        </div>
    </div>
</div>
</div> -->
<!-- Delete -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
<div class="modal-dialog modal-dialog-centered ">
  <div class="modal-content">
        <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Error Occured</h4>
        </div>
        <div class="modal-body">
                <p class="mb-0">{{deleteMsg}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false;bulkDelete();">Close</button>
        </div>
    </div>
</div>
</div>

 <div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
              <h4 class="modal-title">{{saveData.length}} Employees selected</h4>
              <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{confirmMsgText}}</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="confirmMsg = false;">Cancel</button>
              <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase"
                  (click)="saveLOPreverseData();confirmMsg = false;">Save</button>
          </div>
      </div>
  </div>
</div>
<div class="modal  modal-alert {{isDelete == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16 ">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete LOP Reversal?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{this.messageService.Deletemsgdisplay('LOP Reversal')}}</p>
          </div>
          <div class="modal-footer"  *ngIf="permissions">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="isDelete = false">Cancel</button>
              <button *ngIf="permissions?.a"  type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteLOPReversal()">Delete</button>
          </div>
      </div>
  </div>
</div>
<ng-container *ngIf="alertToggle == true">
    <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg" ></app-info-popup>
</ng-container>
<app-upload-progress-bar  *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'" [body]="body" ></app-upload-progress-bar>

<app-error-download [fileName]="'LOP Reversal Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Import LOP Reversal'" (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>
