import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-common-applicability',
  templateUrl: './common-applicability.component.html',
  styleUrls: ['./common-applicability.component.scss']
})
export class CommonApplicabilityComponent implements OnInit {

  // Input from Parent
  @Input() isEditForm : any
  @Input() formGp     : any
  @Input() disabled   : any
  @Input()isFutureHolday:any;
  @Input() from       = ''
  @Input() heading    = ''
  @Input() hint       = ''
  // Output Emit
  @Output() submitMethod = new EventEmitter()
  @Output() emitApplicableList = new EventEmitter();

  submitted                = false;
  saveModal                = false
  selectedItem       : any = []
  list               : any = []
  alertShow                = true;
  applicableTo: { id: number, name: string }[] = [];
  loaderlist               = false;

  constructor(
    private ams      : ApprovalMatrixService,
    public appService: AppService,
    public messageService : MessageService,
    private cd       : ChangeDetectorRef
  ) { }

  get f() {return  this.formGp.controls; }

  ngOnInit(): void {
    // Var Initialization
    if (this.from === 'challan') {
      this.applicableTo = [
          { id: 1, name: 'Company' },
          { id: 2, name: 'BU' },
          { id: 3, name: 'Branch' },
          { id: 4, name: 'City' },
      ];
    } else if(this.from == 'holiday'){
      this.applicableTo = [
        { id: 1, name: 'Company' },
        { id: 2, name: 'BU' },
        { id: 3, name: 'Department' },
        { id: 4, name: 'Designation' },
        { id: 5, name: 'Grade' },
        { id: 6, name: 'Branch' },
        { id: 7, name: 'Employee' },
      ];
    } else {
      this.applicableTo = [
          { id: 1, name: 'Company' },
          { id: 2, name: 'BU' },
          { id: 3, name: 'Department' },
          { id: 4, name: 'Designation' },
          { id: 5, name: 'Employee' },
          { id: 6, name: 'Grade' },
      ];
    }
    this.submitted = false
    if(this.f.applicableto.value != null){
      this.changeApplicableTo();
    }
  }

  validateAssignRights(){
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitMethod.emit();
    this.saveModal = false
  }

  changeApplicableTo(clearAssignees = false){
    this.loaderlist = true
    if(clearAssignees){
      this.formGp.patchValue({
        assignees: []
      });
    }
    this.list = [];
    this.ams.applicableList(this.formGp['controls'].applicableto.value).subscribe((res: any) => {
      if(this.formGp['controls'].applicableto.value == "Employee"){
        this.list = [];
        for(let key of res){
          key.name = key.first_name+" ";
          if(key.middle_name !=null && key.middle_name!='')
            key.name +=key.middle_name+" ";
          if(key.last_name !=null)
            key.name +=key.last_name;
            key.name +=" ("+key.employee_code+")";
          this.list.push(key);
        }
        this.initiateErrorStatus();
      } else if(this.formGp['controls'].applicableto.value == "Company"){
        this.list = [];
        for(let key of res){
          key.name = key.company_name;
          this.list.push(key);
        }
      }
      else
        this.list = res;
        if (this.list.length != 0 && this.formGp['controls'].applicableto.value != "Employee" && this.formGp['controls'].applicableto.value != "City") {
          this.selectedItem = this.list[0].id;
          this.selectAllForDropdownItems(this.list);
        }
      // this.selectAllForDropdownItems(this.list);
    this.loaderlist = false
    });
    this.submitted = false
  }

  clear(index:number){
    let daata = this.formGp.value.assignees;
    daata.splice(index, 1);
    this.formGp.patchValue({
      assignees: daata
    });
    this.initiateErrorStatus();
    this.submitted = false
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  ngChange(e:any){
    // !this.isEditForm
    if(this.f.applicableto.value == 'Employee' && this.hasSpecificStatus(e)){
      this.formGp?.get('assignees')?.setErrors({
        'relievedError' : true
      })
    }
    this.submitted = false
    this.emitApplicableList.emit(this.list);
  }

  hasSpecificStatus(employees:any){
    return employees.every((employee:any)=> employee?.active_state_employee == false) && employees?.length != 0;
  }

  initiateErrorStatus(){
    if(this.f.assignees.value != null && this.f.assignees.value != ''){
      const filteredData = this.list.filter((item:any) => this.f.assignees.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData) && !this.isEditForm){
          this.formGp?.get('assignees')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('assignees')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.list.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
