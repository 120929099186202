import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';


@Component({
  selector: 'app-loan-approval-inbox',
  templateUrl: './loan-approval-inbox.component.html',
  styleUrls: ['./loan-approval-inbox.component.scss']
})
export class LoanApprovalInboxComponent implements OnInit {

 // Input
  @Input() requestitem: any;
  @Input() from: any;

  // Output
  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter();

  // View Status
  billLoader              = false;
  viewstatusPanel         = false;
  viewStatusData    : any = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;

  modalToggle             = false;
  currentDocName          = "";
  currentExtension        = "";
  allowedExtension        = "";
  investalertMsg    : any = '';
  alertToggle             = false;
  fileName          : any;
  loader1                 = true;

  viewExistingLoan  : boolean = false
  viewExistingData  : any = []
  navData           : any = []

  form                    = this.fb.group({
    read_status     : [true, Validators.required],
    star            : [false, Validators.required],
    approved_status : [false, Validators.required],
    comment         : ['', Validators.maxLength(500)],
  })

  sendReminder = false

  get f() { return this.form.controls; }

  constructor(
    public  inboxservice     : InboxService,
    private investmentService: InvestmentDeclerationService,
    public appservice        : AppService,
    public messageService    : MessageService,
    private fb               : FormBuilder,
    public activityService:ManageActivityService

  ) { }

  ngOnInit(): void {

    this.sendReminder = this.from=='behalf'?true:false

  }

  ngOnChanges(){
    this.loader1       = true;
    setTimeout(() => {
      this.loader1     = false;
    }, 2000);
  }

  existingFullData: any = []
  viewExisting(){
    this.viewExistingLoan = true
    this.billLoader = true
    let empId = this.requestitem?.requests?.from_person?.id
    this.inboxservice.getExistingLoan({'employee_id':empId,'employee_request_id' : this.requestitem?.request_data?.id}).subscribe((res:any)=>{
      let response = res?.data
      this.navData = response.map((item:any)=>{
        return {
          'loan_code' : item.loan_details.loan_code,
          'loan_id' : item.loan_details.id
        }
      })
      this.viewExistingData = response[0]?.loan_details
      this.existingFullData  = response
      this.billLoader = false
    })
  }

  panelClose(val:any){
    this.viewExistingLoan = val
  }

  confirm(status: any) {
    if (status == 'Approved') {
      this.form.controls['approved_status'].setValue('Approved');
    } else {
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if (this.form['controls'].comment.valid) {

      if(this.from=='behalf'){
        this.activityService.SaveClick = true

        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);
        this.form.get('comment')?.reset('')

      }
      else{

        this.inboxservice.updateInboxItem(this.requestitem?.id, this.form.value).subscribe((res) => {
          if (res) {
            this.successEmitter.emit(true);
          }
        })
      }
    }
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let dataArray: any = [];
    if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        if (res) {
          Object.entries(dataArray).forEach(([key, value]) => {
            if (this.requestitem['id'] == id) {
              this.requestitem[key] = value;
            }
          })
        }
      })
    }
  }

  // View Status
  requestViewStatus() {
    this.viewstatusPanel = true
    this.billLoader = true;
    this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) => {
      this.viewStatusData = res;
      this.billLoader = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
    });
  }

  // Document
  // Modal Close
  close(bool: any) {
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool: any) {
    this.alertToggle = bool
  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename: any) {
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.investalertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  getDocName(name:string){
    if(name != undefined){
      var docname : any  = name.split('_').pop();
      docname = docname.replace(/[&\/\\#,+()$~%'":*?<>{}0-9]/g, '', "");
      return docname;
    }
  };

  downloadFile(val:any){
    let url = this.appservice.getDocName(val).split('.')[0]
    this.appservice.downloadFile(val,url)
  }
  clearComment(){
    this.form.get('comment')?.reset('')

  }

}
