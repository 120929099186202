import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';


@Component({
  selector: 'app-shortleave-inbox',
  templateUrl: './shortleave-inbox.component.html',
  styleUrls: ['./shortleave-inbox.component.scss']
})
export class ShortleaveInboxComponent implements OnInit {

  timeOffTrend = false;
  loader1 = true;
  sendReminder = false
  @Input() requestitem: any;
  @Input() from: any;
  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelEmitter: EventEmitter<boolean> = new EventEmitter();

  // View Status
  billLoader = false;
  viewstatusPanel = false;
  viewStatusData: any = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;

  constructor(
    public inboxservice: InboxService,
    private investmentService: InvestmentDeclerationService,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    public appservice: AppService,
    public messageService: MessageService,
    private notifyServ:NotificationService,
    public activityService:ManageActivityService

  ) { }


  yearList: any = []
  currentYear = new Date().getFullYear()
  selectedYear: any;

  modalToggle = false;
  currentDocName = "";
  currentExtension = "";
  allowedExtension = "";
  investalertMsg: any = '';
  alertToggle = false;
  fileName: any;
  nullStatus = false;
  multi: any = [];
  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;
  requestCancel = false
  onSelect(event:any) {
  }

  ngOnChanges(){
    this.loader1       = true;
      setTimeout(() => {
        this.loader1       = false;
      }, 2000);
    }

  ngOnInit(): void {

    this.sendReminder = this.from=='behalf'?true:false
  }

  form = this.fb.group({
    read_status: [true, Validators.required],
    star: [false, Validators.required],
    approved_status: [false, Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  trendsForm = this.formBuilder.group({
    leave_type: [''],
  })

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let dataArray: any = [];
    if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        if (res) {
          Object.entries(dataArray).forEach(([key, value]) => {
            if (this.requestitem['id'] == id) {
              this.requestitem[key] = value;
            }
          })
        }
      })
    }
  }

  refreshInbox(event: boolean) {
    if (event) {
      this.successEmitter.emit(event);
    }
  }

  requestViewStatus() {
    this.viewstatusPanel = true
    this.billLoader = true;
    this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) => {
      this.viewStatusData = res;
      this.billLoader = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
    });
  }

  trendFunction() {

    this.yearListCreate();
    this.selectCurrentYear()
    this.getTrendData();
  }



  yearListCreate() {
    this.yearList = [];
    var i: number;
    for (i = this.currentYear; i >= 2020; i--) {
      this.yearList.push(String(i))
    }
  }

  selectCurrentYear() {
    this.selectedYear = this.currentYear
    this.yearChange(this.selectedYear)
  }

  getTrendData(){

    this.barchartLoader = true;
    let fromPerson = this.from=='behalf'?this.requestitem?.requests.employee:this.requestitem?.from_person?.id

    this.investmentService.shortLeaveTrend({'employee' : fromPerson,'year' : Number(this.selectedYear)}).subscribe((res: any) => {
      this.multi      = res?.data;
      if(this.multi?.length > 0)
       this.barChartDataConstruction(this.multi)
      this.nullStatus = res?.null_status;
    });
  }

  yearChange(val: any) {
    this.selectedYear = val;
    this.getTrendData();
  }




  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename: any) {
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.investalertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }


  // Modal Close
  close(bool: any) {
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool: any) {
    this.alertToggle = bool
  }

  get f() { return this.form.controls; }

  confirm(status: any) {
    if (status == 'Approved') {
      this.form.controls['approved_status'].setValue('Approved');
    } else {
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if (this.form['controls'].comment.valid) {

      if(this.from=='behalf'){
        this.activityService.SaveClick = true
        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);
        this.form.get('comment')?.reset('')

      }
      else{
        this.inboxservice.updateInboxItem(this.requestitem?.id, this.form.value).subscribe((res) => {
          if (res) {
            this.successEmitter.emit(true);
          }
        })
      }
    }
  }



  // Bar chart
  barChartDataConstruction(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    let cancelled:any         = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Hours';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Accepted"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else if(item1?.name == "Cancelled"){
            cancelled.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });

    this.barchart.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 },{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }
  clearComment(){
    this.form.get('comment')?.reset('')

  }

}
