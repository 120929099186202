import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HolidayPolicyService } from 'src/app/@core/services/holiday-policy.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { holidaytypeService } from 'src/app/@core/services/holiday-type.service';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-holiday-policy',
  templateUrl: './holiday-policy.component.html',
  styleUrls: ['./holiday-policy.component.scss']
})
export class HolidayPolicyComponent implements OnInit {
  searchKeyword="";
  viewDetail = false;
  holidayPolicyDetails: any = [];
  hpDetailsList: any = [];
  status: any = true;
  offset = 0;
  limit = 20;
  statusBtn:any = "Active";
  permissions: any = [];
  loader = false;
  listlength = 0;
  public hp_list_map_flag: boolean = true;
  searchlistdata:any = [];
  infinityloader = false;
  holidaypolicysearch:string = this.messageService.searchdisplay('Holiday policy');
  deleteAlert = false;
  deleteId : any;
  del_msg: string = '';
  hp_Id: any;
  viewIsActive: boolean = true;
  viewHolidayPolicyDetails: any = [];
  viewApplicable: any;
  viewRightsArray: any = [];
  inputFocusClass = false;
  holidayListItems: any = [];
  deleteClicked = false
  yearList : any;
  selectedYear : any;
  year:any
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;
  loader1=  true;

  constructor(
    public appService: AppService,
    private hps: HolidayPolicyService,
    public router: Router,
    public breakpointObserver: BreakpointObserver,
    public messageService: MessageService,
    private holidaytypeService : holidaytypeService,
  ) { }

  ngOnInit(): void {
    this.getPermissions();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.limit = 40;
      }
    });
    this.selectedYear = new Date().getFullYear();
    this.holidayPolicyList();
    this.yearListCreate()
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.hps.getHolidayPolicyList(this.status,this.limit, this.offset,this.searchKeyword,this.selectedYear)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermissions(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermissions();
      }, 1000);
    }
  }

  yearListCreate(){
    this.year = new Date().getFullYear();
    this.yearList=[];
    var i:number;
    for(i = this.year+1; i >= (this.year) ; i--){
      this.yearList.push(String(i))
    }
    for(i = this.year-1; i >= this.year-1 ; i--){
      this.yearList.push(String(i))
    }
  }

  yearChange(value:any){
    this.selectedYear         = Number(value);
    this.offset               = 0;
    this.holidayPolicyDetails = [];
    this.holidayPolicyList()
  }

  holidayPolicyList(){
    this.loader = true;
    this.hps.getHolidayPolicyList(this.status,this.limit, this.offset,this.searchKeyword,this.selectedYear)
    .subscribe((res: any) => {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    if (res.results.length == 0) {
      this.hp_list_map_flag = (this.offset == 0) ? false :true;
      this.listlength  = 0;
    }else {
      this.hp_list_map_flag = true;
      this.listlength = res.results.length;
      if(this.offset ==0){
        this.holidayPolicyDetails = [];
      }
      this.hpDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.holidayPolicyDetails.push(this.hpDetailsList[i]);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.limit;
    if(this.listlength){
      this.holidayPolicyList();
      this.loader       = false;
    }
  }

  selectItemList(value:any){
    this.status=value;
    this.offset            = 0;
    this.holidayPolicyDetails = [];
    this.child.clearBar();
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.holidayPolicyDetails = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  editHolidayPolicy(id:number){
    this.router.navigate(['/holiday-rule/holiday-policy-setup/'+id]);
  }

  deleteHolidayPolicy(id:any){
    this.deleteId = id
    this.del_msg = 'This will delete the shift permanently. You’ll have to add again to get it back in the list.';
    this.deleteAlert = true
  }

  confirmDelete(){
    this.deleteClicked = true
    this.hps.holidayPolicyDelete(this.deleteId).subscribe((res: any) => {
      this.deleteClicked = false
      if(res){
        this.deleteAlert = false
        this.viewDetail = false
        this.offset            = 0;
        this.holidayPolicyDetails = [];
        this.holidayPolicyList();
      }
    },
    (err: any) => {
      this.deleteClicked = false
      this.deleteAlert = false
      this.viewDetail = false
    });
  }

  viewHolidayPolicy(id:any){
    this.loader1=true;
    this.viewHolidayPolicyDetails = [];
    if(this.viewRightsArray.length!=0){
      this.viewRightsArray = [];
    }
    if(this.permissions.v){
      this.viewDetail = true;
      this.hps.getHolidayPolicy(id).subscribe((res: any) => {
        this.viewHolidayPolicyDetails = res;
        for(let i in this.viewHolidayPolicyDetails.holiday_policy_assignright){
         let item=this.viewHolidayPolicyDetails.holiday_policy_assignright[i];
            item['name']  = "";
            if(item['first_name'] != null)
            item['name'] += item['first_name']+" ";
            if(item['middle_name'] != null)
            item['name'] += item['middle_name']+" ";
            if(item['last_name'] != null)
            item['name'] += item['last_name'];
         this.viewApplicable=item.applicable;
         if(item.applicable=='Company'){

            let compName = item.company_name;
            let compImg  = item.company_logo;
            this.viewRightsArray.push({'name': compName,'img': compImg})
         }
         if(item.applicable=='BU'){
            this.viewRightsArray.push(item.businessunit_name);
         }
         if(item.applicable=='Department'){
            this.viewRightsArray.push(item.department_name);
         }
         if(item.applicable=='Designation'){
            this.viewRightsArray.push(item.designation_name);
         }
         if(item.applicable=='Employee'){

            let empName = item.employee_name.name+" ("+item.employee_name.employee_code+")";
            let empImg  =  item.employee_name.profile_image;
           let empclrcode =  item.employee_name.color_code;
           this.viewRightsArray.push({'name': empName,'img': empImg,'color_code':empclrcode})
         }
         if(item.applicable=='Grade'){
            this.viewRightsArray.push(item.grade_name);
         }
         if(item.applicable=='Branch'){ //branch view
          this.viewRightsArray.push(item.branch_name);
         }
       }
         this.loader1= false;
      })
    }
  }

  searchholidaypolicy(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.holidayPolicyDetails= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/holiday-rule/holiday-policy-setup/new']);
  }
}
