<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Bulk Upload</h5>
        <a class="toggle-panel" (click)="closepanel()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
          <div class="row row-24 mb-24">
            <div class="col-12">
              <label for="" class="form-label required">Select option to bulk upload </label>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check form-check-inline">
                <input type="radio" id="radio1" class="form-check-input" [value]="true"  [(ngModel)]="radiobtnvalue">
                <label for="radio1" class="form-check-label">Punch upload </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="radio2" class="form-check-input" [value]="false" [(ngModel)]="radiobtnvalue" (click)="statusuploadclick()">
                <label for="radio2" class="form-check-label">Attendance status upload </label>
              </div>
            </div>
            <ng-container *ngIf="radiobtnvalue">
              <div class="col-12">
                <div class="card card-c2 p-24">
                  <div class="row row-16">
                    <div class="col-12">
                      <div class="title  ">Punches</div>
                    </div>
                    <div class="col-12" *ngIf="fileName">
                      <div class="card card-c2  bg-secondary  " style="width:16.375rem">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                            <span class="tips-helper ">{{fileName}}</span>

                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearData()"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <label for="upload" [class.disabled]="fileName != ''"
                        class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                        <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                          (change)="onFileChange($event)" id="upload" hidden>
                        Upload Punches
                      </label>
                      <span class="invalid-feedback"
                        *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card card-c2 p-24">
                  <div class="title mb-24">Instructions </div>
                  <ol class="fs-14 ps-16 mb-0 li-24">
                    <li>Download the <a class="link-primary" href="assets/docs/sample-punch.xlsx"> <i
                          class="icon-download me-1"></i> Sample Excel
                        template</a></li>
                    <li>Enter or update the Punch details as per the format provided in the excel sheet.</li>
                    <li>Save the template.</li>
                    <li>To import the file, Click the "Upload Punches" button.</li>
                    <li>Select the file to be imported.</li>
                    <li>Click on "Upload" button below.</li>
                    <li>The file chosen should be in (.xls/.csv)format only.</li>
                  </ol>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="radiobtnvalue == false">
                  <ng-container [formGroup]="templateForm">
                  <div class="col-6">
                      <label for="" class="form-label required">From Date</label>
                      <div class="input-group form-icon icon-right"
                          [ngClass]="{ 'is-invalid': (f.fromdate.touched) && f.fromdate.errors}">
                          <input (click)="datePicker1.open()" (dateInput)="fromDateoverride($event)"
                              placeholder="{{this.messageService.selectplaceholddisplay('date')}}" [min]="minDate"
                              [max]="maxDate"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': ( f.fromdate.touched) && f.fromdate.errors}" matInput
                              formControlName="fromdate" [matDatepicker]="datePicker1"
                              (keydown)="disableDate()">
                          <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                          <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                      </div>
                      <div *ngIf="( f.fromdate.touched) && f.fromdate.errors" class="invalid-feedback">
                          <div *ngIf="f.fromdate.errors.required">
                              {{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>
                    </div>
                    <div class="col-6">
                        <label for="" class="form-label  required">To Date</label>
                        <div class="input-group form-icon icon-right"
                            [ngClass]="{ 'is-invalid': ( f.todate.touched) && f.todate.errors }">
                            <input (click)="datePicker2.open()"
                                placeholder="{{this.messageService.selectplaceholddisplay('date')}}"
                                class="form-control" [matDatepickerFilter]="dobFilter"
                                [ngClass]="{ 'is-invalid': ( f.todate.touched) && f.todate.errors }" matInput
                                formControlName="todate" [min]="f.fromdate.value" [max]="maxValue"
                                [matDatepicker]="datePicker2" (keydown)="disableDate()"><i
                                class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                            <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                        </div>
                        <div *ngIf="(( f.todate.touched) || f.todate.touched) && f.todate.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.todate.errors">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="templateForm.valid">
                      <div role="alert" class="alert alert-info   mb-0 pe-32 show">
                        <p class="mb-0 fs-14 hstack gap-8">
                          Download the excel sheet generated, fill the details and upload.
                        </p>
                        <a (click)="generateTemplate();" class="fw-500 link-primary1">
                          <i class="icon-download text-accent2"></i>
                          Download
                        </a>
                      </div>
                    </div>
                  </ng-container>
                    <div class="col-12">
                      <div class="card card-c2 p-24">
                        <div class="row row-16">
                          <div class="col-12">
                            <div class="title  ">Attendance status</div>
                          </div>
                          <div class="col-12" *ngIf="fileNameoverride">
                            <div class="card card-c2  bg-secondary  " style="width:16.375rem">
                              <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                  <span class="tips text-uppercase  fw-700 text-trim">{{fileNameoverride}}</span>
                                  <span class="tips-helper ">{{fileNameoverride}}</span>

                                </div>
                                <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearDataoverride()"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-5 mt-24">
                            <label for="upload" [class.disabled]="fileNameoverride != ''"
                              class="btn btn-outline-primary radius-2 px-5 {{invalidFormatoverride?'is-invalid':''}}">
                              <input [disabled]="fileNameoverride != ''" type="file" name="" onclick="this.value = null"
                                (change)="onFileChangeoverride($event)" id="upload" hidden>
                              Upload Attendance Status
                            </label>
                            <span class="invalid-feedback"
                              *ngIf="invalidFormatoverride">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="card card-c2 p-24">
                        <div class="title mb-24">Instructions </div>
                        <ol class="fs-14 ps-16 mb-0 li-24">
                          <li>Download the excel sheet generated after filling the dates.</li>
                          <!-- <li>Uploading Leave as Attendance Status: Enter the leave code of the leave type, such as "CL" for casual leave.</li> -->
                          <!-- <li>Uploading Different Leave Types for First and Second Half: Enter the leave codes separated by a vertical bar, like "Leave code1|Leave code2".</li> -->
                          <!-- <li>Uploading Half-Day Leave and Half-Day Present: Enter the "leave code|P" or "P|leave code".</li> -->
                          <!-- <li>Uploading Half-Day Leave Without Specifying Other Half: Enter the "leave code|--" or "--|leave code".</li> -->
                          <li>Uploading Half-Day Present Without Specifying Other Half: Enter "P|--" or "--|P".</li>
                          <li>Uploading Half-Day Absent Without Specifying Other Half: Enter "A|--" or "--|A".</li>
                          <li>Enter or update the Attendance status details as per the format provided in the  excel sheet.</li>
                          <li>Save the template.</li>
                          <li>To import the file, Click the "Upload Attendance Status" button.</li>
                          <li>Select the file to be imported.</li>
                          <li>Click on "Upload" button below.</li>
                          <li>The file should be in (.xls, .csv) format only.</li>
                        </ol>
                      </div>
                    </div>
              <!-- </div> -->
            </ng-container>
          </div>
        </div>
      </div>
      <footer class="side-panel-footer gap-32 pt-16" *ngIf="radiobtnvalue">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
          (click)="uploadPunches()">Submit</button>
      </footer>
      <footer class="side-panel-footer gap-32 pt-16" *ngIf="!radiobtnvalue">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileNameoverride == '' || iscompletedoverride"
          (click)="statusalert = true;btnclicked=false;">Submit</button>
      </footer>
    </div>
  </div>
  <div class="modal  modal-alert {{errorToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Error Occured</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{errorMsg}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="errorToggle = false;">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal  modal-alert {{statusalert == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16 ">
          <i class="bi bi-info-circle text-accent2"></i>
          <h4 class="modal-title">Alert</h4>
       </div>
        <div class="modal-body">
          <p class="mb-0">The attendance override will not affect any existing requests (overtime, comp time, floating holiday, leave) that the employee has already submitted. When these requests are approved, the system will not automatically change the attendance status. You must manually override the attendance status via Attendance calendar.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
            (click)="statusalert = false;btnclicked=false;">Cancel</button>
          <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="uploadsatatus()"> Ok</button>
        </div>
      </div>
    </div>
  </div>

  <app-upload-progress-bar [body]="body" *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
    [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
  <app-error-download [fileName]="'Punch Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg"
    [failedData]="exportData" [modalHeading]="'Upload Punch'" (closePanel)="closepunch()"
    [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>

  <!-- override -->
<app-upload-progress-bar *ngIf=" isDataUploadoverride !=0" [body]="bodyoverride" [progress]="percentageoverride| number:'1.0-0'" [iscompleted]="iscompletedoverride"
    [isProgressStart]="isProgressStartoverride" [heading]="'Uploading'"></app-upload-progress-bar>

<app-error-download  *ngIf="downloadMsgoverride" [fileName]="'Attendance Status Error'" [successDataCount]="successDataCountoverride" [downloadMsg]="downloadMsgoverride"
    [failedData]="exportDataoverride" [modalHeading]="'Upload Attendance Status'" (closePanel)="closepunchoverride()"
    [successMsg]="successMsgoverride" [errorMsg]="errorMsgoverride"> </app-error-download>


    <div class="modal  modal-alert {{deleteToggleoverride == true ? 'show': ''}}"  tabindex="-1">
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
              <div class="modal-header gap-16">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">{{deleteMsgoverride}}</h4>
              </div>
              <div class="modal-body">
                      <p class="mb-0"> Please verify the template and Add/Update the data appropriately.</p>
              </div>
              <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggleoverride = false">Cancel</button>
              </div>
          </div>
      </div>
  </div>
