<div class="container-fluid p-24 " *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Category</h3>
            <p class="text-helper mb-md-0">Create categories to which you want to assign the documents.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div style="width:22.6875rem;">
                  <div class="">
                    <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='categoryPlaceholder' (searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchCategory($event)"  [isLoading]="sLoading"></app-searchbar>
                  </div>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end">
                      <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';statusfilter('')">All</button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';statusfilter(true)">Active </button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                    </div>
                </div>
                <button *ngIf="permissions?.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addCategory()"><i
                        class="icon-plus"></i>Add CATEGORY</button>

            </div>
        </div>
    </div>

    <div class="row row-16 " *ngIf="loader">
      <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <div *ngIf="nodatafound && !loader;else all_data" class="row " style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg9.png'" [noDataText]="'Effortlessly organize documents!'"
        [noDataPara]="'Start categorizing company documents for easy access and management. Click the \'Add Category\' button to keep everything tidy and structured for a smoother workflow.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>

    <ng-template #all_data><div class="row row-16 "  infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [fromRoot]="true"
      (scrolled)="onScrollDown()">
  <ng-container  *ngIf="loader==false">

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let item of categoryList let i = index">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex view-section align-items-center" (click)="viewcategory(item?.id)">
                      <div class="logo-img {{item.color_code}}" ng-init="getRandomColor()">{{this.appService.getFirstChar(item.name,2)}}</div>

                      <div class="overflow-hidden">
                        <h6 class="mb-0 tips">{{item?.name}}</h6>
                        <span class="tips-helper">{{item?.name}}</span>
                        <span class="fs-10 text-uppercase fw-500 {{(item?.is_active == true) ? 'text-success' : 'text-danger'}}">{{(item?.is_active == true) ? 'Active' : 'Deleted'}}</span>
                      </div>
                    </div>
                    <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="item?.is_active && (permissions?.e || permissions?.d)">
                        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icon-three-dots-vertical "></i>
                        </button>
                        <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
                          <button ngbDropdownItem (click)="editCategory(item.id)" *ngIf="permissions?.e ">
                            <i   class="icon-pencil-square me-1"></i> Edit
                          </button>
                          <button
                           class="text-danger" ngbDropdownItem (click)="deleteCategory(item.id)" *ngIf="permissions?.d">
                            <i class="icon-trash  me-1"></i> Delete
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </ng-container>
  </div>
  </ng-template>

</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <ng-container *ngIf="!loader1">

                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">CATEGORY </p>
                    <a class="link-primary fs-14  d-flex align-items-center ms-auto" (click)="editCategory(singleCategory?.id)" *ngIf="singleCategory?.is_active==true && permissions?.e">

                      <i
                            class="icon-pencil-square me-1"></i> Edit</a>
                </div>
                <div class="row row-16 mb-24">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 ">
                                    <p class="title mb-8">CATEGORY DETAILS</p>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                    <div class="detail-title">Category name </div>
                                    <div class="detail-desc">{{singleCategory?.name}} </div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Status</div>
                                  <div class="detail-desc">
                                    <span class="fs-12 fw-500 {{singleCategory?.is_active?'text-success':'text-danger'}} text-uppercase">{{singleCategory?.is_active?'ACTIVE':'DELETED'}}</span>

                                  </div>
                               </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='editDetail === true' >
    <form  [formGroup]="categoryForm"  class="side-panel-container" autocomplete="off">

        <header class="side-panel-head">
            <h5>{{categoryLabel}} Category</h5>
            <a class="toggle-panel " (click)="editDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <ng-container *ngIf="!loader1">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">{{categoryLabel | uppercase}} CATEGORY DETAILS</p>
                </div>
                <div class="row row-16 mb-24">
                    <div class=" col-12  ">
                        <label for="" class="form-label required">Category name </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Enter the Category name. For example  Policy document, Training Documents etc.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                        <input type="text" class="form-control" formControlName="name" name="" id="name" value=""
                        placeholder="{{this.messageService.placeholderdisp('category name')}}"
                        [ngClass]="{ 'is-invalid': ( f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
                        <div *ngIf="( f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                            <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                            <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
        <footer class="side-panel-footer">

          <button  class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='categoryForm.invalid || submitted' (click)="saveCategory()">Save</button>
      </footer>
    </form>
</div>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16 ">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete Category?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{this.messageService.Deletemsgdisplay('Category')}}</p>
          </div>
          <div class="modal-footer" >
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="deleteToggle = false">Cancel</button>
              <button   type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteCategoryConfirm()">Delete</button>
          </div>
      </div>
  </div>
</div>
