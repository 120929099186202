<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">POLICY DETAILS</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Policy name</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Mention Policy Name and Description to create a policy.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" formControlName="name" class="form-control "
                    placeholder="{{this.messageService.placeholderdisp('Policy name')}}"
                    [ngClass]="{ 'is-invalid': (saveBtnClicked || f.name.touched|| f.name.dirty) &&  f.name.errors || f.name.errors?.exist}"
                    (keyup)="keyUp()">
                <div *ngIf="(saveBtnClicked || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist"
                    class="invalid-feedback">
                    <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay('Policy name')}}</div>
                    <div *ngIf="f.name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.name.errors.pattern && f.name.errors.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf=" f.name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>

                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label">Policy description </label>
                <textarea placeholder="{{this.messageService.descriptiontext('desc')}}" class="form-control"
                    [ngClass]="{ 'is-invalid': (saveBtnClicked || f.attend_capture_desc.touched || f.attend_capture_desc.dirty) &&  f.attend_capture_desc.errors}"
                    formControlName="attend_capture_desc"></textarea>

                <div *ngIf="(saveBtnClicked ||f.attend_capture_desc.touched || f.attend_capture_desc.dirty) && f.attend_capture_desc.errors"
                    class="invalid-feedback">
                    <div *ngIf="!f.attend_capture_desc.errors.pattern && f.attend_capture_desc.errors.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                </div>
            </div>
            <div class="col-12">
                <label class="form-check p-0 d-flex " for="check1">
                    <input type="checkbox" name="check" class="form-check-input ms-0" formControlName="web_checkin" (change)="checksetRequired($event,'web_checkin')" #webchk>
                    <label class="form-check-label fs-16">Web checkin</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the checkbox to enable Web Check In option for employees.
                        " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </label>
            </div>
            <ng-container *ngIf="f.web_checkin.value">
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label ">Do you want to enable IP restriction </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes to enable IP restriction option.Specify a range of IP addresses in the below fields, only devices within that range can be used to mark attendance.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">

                            <input class="form-check-input" type="radio" [value]=true
                                (ngModelChange)="multipleSetRequired()" formControlName="is_ip_restricted">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" [value]=false
                                (ngModelChange)="multipleSetRequired()" formControlName="is_ip_restricted">
                            No
                        </label>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="f.web_checkin.value && f.is_ip_restricted.value" formArrayName="attendance_capture_iprange">
            <ng-container *ngFor="let ips of attendance_capture_iprange().controls; let i=index;"
                    [formGroupName]="i">
                    <div class="col-6">
                        <label for="" class="form-label required">From IP range</label>
                    </div>
                    <div class="col">
                        <label for="" class="form-label required">To IP range </label>
                    </div>
                   <div style="width:2.5rem" *ngIf="i!=0">&nbsp;</div>
                    <div class="col-12" >
                        <div class="align-items-start d-flex flex-nowrap row row-16">
                          <div class="col-6 form-row mt-0">
                           <input type="text" formControlName="from_ip_range" class="form-control "
                                placeholder="Enter IP range"
                                [ngClass]="{ 'is-invalid': (saveBtnClicked || attendance_capture_iprange().controls[i].get('from_ip_range')?.touched || attendance_capture_iprange().controls[i].get('from_ip_range')?.dirty) && (attendance_capture_iprange().controls[i].get('from_ip_range')?.errors || attendance_capture_iprange().controls[i].get('from_ip_range')?.errors?.overlap)}" (keyup)="showValidation(i)">
                            <div *ngIf="(saveBtnClicked || attendance_capture_iprange().controls[i].get('from_ip_range')?.touched || attendance_capture_iprange().controls[i].get('from_ip_range')?.dirty) && (attendance_capture_iprange().controls[i].get('from_ip_range')?.errors || attendance_capture_iprange().controls[i].get('from_ip_range')?.errors?.overlap)"
                                class="invalid-feedback">
                                <div *ngIf="attendance_capture_iprange().controls[i].get('from_ip_range')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="attendance_capture_iprange().controls[i].get('from_ip_range')?.hasError('pattern')">Enter a valid data</div>
                                    <div *ngIf="(!attendance_capture_iprange().controls[i].get('from_ip_range')?.hasError('pattern') )&& (attendance_capture_iprange().controls[i].get('from_ip_range')?.errors?.overlap)">The selected IP range should not overlap with previously selected range.</div>

                              </div>
                           </div>
                            <div class="col form-row mt-0">
                                <input type="text" formControlName="to_ip_range" class="form-control "
                                placeholder="Enter IP range"
                                [ngClass]="{ 'is-invalid': (saveBtnClicked || attendance_capture_iprange().controls[i].get('to_ip_range')?.touched || attendance_capture_iprange().controls[i].get('to_ip_range')?.dirty) && attendance_capture_iprange().controls[i].get('to_ip_range')?.errors}" (keyup)="showValidation(i)">
                            <div *ngIf="(saveBtnClicked || attendance_capture_iprange().controls[i].get('to_ip_range')?.touched || attendance_capture_iprange().controls[i].get('to_ip_range')?.dirty) && attendance_capture_iprange().controls[i].get('to_ip_range')?.errors"
                                class="invalid-feedback">
                                <div *ngIf="attendance_capture_iprange().controls[i].get('to_ip_range')?.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="attendance_capture_iprange().controls[i].get('to_ip_range')?.hasError('pattern')">Enter a valid data</div>

                            </div>
                            </div>
                            <a class="flex-center sq-40 text-danger mt-0" *ngIf="i!=0" (click)="removeIp(i)">
                                <i class="icon-trash "></i>
                            </a>
                            </div>
                            </div>
                      </ng-container>
                    </ng-container>
                    <div class="link-primary1 fs-14 fw-500" *ngIf="f.web_checkin.value && f.is_ip_restricted.value">
                        <i class="icon-plus-square-fill me-8" (click)="addIps
                            ()"> </i>Add IP range
                    </div>

                    <div class="col-12">
                      <label class="form-check p-0 d-flex " for="mobile_checkin">
                          <input type="checkbox" name="checked" class="form-check-input ms-0" formControlName="mobile_checkin" (ngModelChange)="mobileValidator('mobile_checkin')">
                          <label class="form-check-label fs-16" >Mobile check in</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the checkbox to enable Mobile Check-in option for employee." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                      </label>
                  </div>
                  <ng-container *ngIf="f.mobile_checkin.value">
                    <div class="col-12">
                      <div class="card card-c2 border-0 overflow-hidden">

                        <div class="card-body p-0">
                          <div role="alert" class="alert alert-premium   mb-0 pe-32 show radius-b-0">
                            <p class="mb-0 fs-14">This is an <span class="fw-500 text-warning">&nbsp;add-on&nbsp;</span> feature that <span
                                class="fw-500 text-warning">&nbsp; incurs charges per user per month </span>. Reach out to your SkizzleHR contact
                              person for any clarification.</p>

                          </div>
                        </div>
                        <div class="card-body  border">
                          <div class="row row-16">
                            <div class="col-lg-12 form-row ">
                              <label for="" class="form-label">Does location need to be captured? </label>
                              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                  ngbPopover="Select 'yes' to capture the employee's live location."
                                  popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                            </div>
                            <div class="col-lg-12 form-row mt-0">
                              <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                  <input class="form-check-input" type="radio" formControlName="location_captured" [value]=true
                                    (ngModelChange)="mobileValidator('location_captured')">
                                  Yes
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                  <input class="form-check-input" type="radio" formControlName="location_captured" [value]=false
                                    (ngModelChange)="mobileValidator('location_captured')">
                                  No
                                </label>
                              </div>
                            </div>
                            <ng-container *ngIf="f.location_captured.value">

                              <div class="col-lg-12 form-row ">
                                <label for="" class="form-label">Do you want to enable geo fencing? </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes if you want employees to mark attendance inside defined virtual boundaries.
                                                                                                  "
                                    popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                              </div>
                              <div class="col-lg-12 form-row mt-0">
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" formControlName="enable_geo_fencing" [value]=true
                                      (ngModelChange)="mobileValidator('enable_geo_fencing')">
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" formControlName="enable_geo_fencing" [value]=false
                                      (ngModelChange)="mobileValidator('enable_geo_fencing')">
                                    No
                                  </label>
                                </div>
                              </div>
                            </ng-container>
                            <div class="col-lg-12 form-row ">
                              <label for="" class="form-label">Enable real time location track? </label>
                              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes if you want to track live location of employees.
                                                                                                      "
                                  popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                            </div>
                            <div class="col-lg-12 form-row mt-0">
                              <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                  <input class="form-check-input" type="radio" formControlName="enable_realtime_tracking" [value]=true
                                    (ngModelChange)="mobileValidator('enable_realtime_tracking')">
                                  Yes
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                  <input class="form-check-input" type="radio" formControlName="enable_realtime_tracking" [value]=false
                                    (ngModelChange)="mobileValidator('enable_realtime_tracking')">
                                  No
                                </label>
                              </div>
                            </div>
                            <ng-container *ngIf="f.enable_realtime_tracking.value">
                              <div class="col-12">
                                <label for="" class="form-label required">Idle interval threshold (in minutes)</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                    ngbPopover="Duration (in minutes) for a segment to be considered idle"
                                    popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                              </div>
                              <div class="col-lg-6 mt-0 form-row">
                                <ng-select type="text" class="form-ngselect" placeholder="Select options"
                                  formControlName="idle_interval_threshold" appendTo="body"
                                  [ngClass]="{ 'is-invalid': (saveBtnClicked || f.idle_interval_threshold.touched || f.idle_interval_threshold.dirty) &&  f.idle_interval_threshold.errors}">
                                  <ng-option *ngFor="let Intervel of trackIntervel" [value]="Intervel">{{Intervel}}
                                  </ng-option>
                                </ng-select>
                                <div
                                  *ngIf="(saveBtnClicked || f.idle_interval_threshold.touched || f.idle_interval_threshold.dirty) && f.idle_interval_threshold.errors"
                                  class="invalid-feedback">
                                  <div *ngIf="f.idle_interval_threshold.errors.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                </div>
                              </div>

                              <!-- <div class="col-12">
                                                                                                    <label for="" class="form-label required">Location track interval (in minutes)</label>
                                                                                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Notify location changes at specified intervals (e.g., every 15 minutes) for the Reporting Manager." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                                                                                                  </div>
                                                                                                  <div class="col-lg-6 mt-0 form-row">
                                                                                                      <ng-select type="text" class="form-ngselect" placeholder="Select options"
                                                                                                          formControlName="track_intravel" appendTo="body"
                                                                                                          [ngClass]="{ 'is-invalid': (saveBtnClicked || f.track_intravel.touched || f.track_intravel.dirty) &&  f.track_intravel.errors}">
                                                                                                          <ng-option *ngFor="let trackIntervel of trackIntervel" [value]="trackIntervel">{{trackIntervel}}
                                                                                                              </ng-option>
                                                                                                      </ng-select>
                                                                                                      <div *ngIf="(saveBtnClicked || f.track_intravel.touched || f.track_intravel.dirty) && f.track_intravel.errors"
                                                                                                          class="invalid-feedback">
                                                                                                          <div *ngIf="f.track_intravel.errors.required">
                                                                                                              {{this.messageService.validationDisplay('required')}}</div>
                                                                                                      </div>
                                                                                                  </div> -->

                              <!-- <div class="col-lg-12 form-row ">
                                                                                                      <label for="" class="form-label">Do you want to notify reporting manager regarding live location of employees?</label>
                                                                                                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes if you want to notify the Reporting Manager on location change.
                                                                                                      " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                                                                                                    </div>

                                                                                                    <div class="col-lg-12 form-row mt-0">
                                                                                                      <div class="form-check form-check-inline">
                                                                                                          <label class="form-check-label">
                                                                                                              <input class="form-check-input" type="radio" formControlName="live_location_notify"
                                                                                                                  [value]=true >
                                                                                                              Yes
                                                                                                          </label>
                                                                                                      </div>
                                                                                                      <div class="form-check form-check-inline">
                                                                                                          <label class="form-check-label">
                                                                                                              <input class="form-check-input" type="radio" formControlName="live_location_notify"
                                                                                                                  [value]=false >
                                                                                                              No
                                                                                                          </label>
                                                                                                      </div>
                                                                                                    </div> -->
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>




                    <ng-container *ngIf="f.enable_geo_fencing.value==false && f.location_captured.value">

                      <div class="col-lg-12 form-row ">
                        <label for="" class="form-label">Require approval for remote check-in? </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes if remote check-ins and check-outs of the employees are to be approved.
                                            " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                      </div>
                      <div class="col-lg-12 form-row mt-0">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="location_request_approves" [value]=true>
                            Yes
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="location_request_approves" [value]=false>
                            No
                          </label>
                        </div>
                      </div>
                    </ng-container>


                  </ng-container>

            <!-- selfie Attendance -->
            <div class="col-12">
                <label class="form-check p-0 d-flex " for="checkid3">
                    <input id="checkid3" type="checkbox" name="checked" class="form-check-input ms-0" formControlName="is_selfie_enabled" #checkid3 (change)="resetSelfie($event)">
                    Selfie attendance
                </label>
            </div>
            <ng-container *ngIf="f.is_selfie_enabled.value">
              <div class="col-lg-12 form-row ">
                <label for="" class="form-label">Do you want to capture and save photos when employees check in/check out? </label>
              </div>
              <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" formControlName="is_save_photo" [value]=true
                      (change)="ValidateSaveCapture()">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" formControlName="is_save_photo" [value]=false
                      (change)="resetSave()">
                    No
                  </label>
                </div>
              </div>
              <ng-container *ngIf="f.is_save_photo.value">
                <div class="col-lg-12 form-row ">
                  <label for="" class="form-label">Save photo when employee check-in/check-out from? </label>
                </div>
                <div class="col-lg-12 form-row mt-0">
                  <div class="form-check-inline">
                    <label class="form-check p-0 d-flex " for="checkid4">
                      <input type="checkbox" name="checked4" class="form-check-input ms-0"
                        formControlName="save_photo_checkin_from_mobile" #checkid4 id="checkid4"
                        (change)="ValidateSaveCapture()">Mobile
                    </label>
                  </div>
                  <div class=" form-check-inline">
                    <label class="form-check p-0 d-flex " for="checkid5">
                      <input type="checkbox" name="checked5" class="form-check-input ms-0"
                        formControlName="save_photo_checkin_from_web" #checkid5 id="checkid5" (change)="ValidateSaveCapture()"> Web
                    </label>
                  </div>
                </div>
              </ng-container>
              <div class="col-12">
                <div class="card card-c2 border-0 overflow-hidden">
                  <div class="card-body p-0">
                    <div role="alert" class="alert alert-premium   mb-0 pe-32 show radius-b-0">
                      <p class="mb-0 fs-14">This is an <span class="fw-500 text-warning">&nbsp; add-on &nbsp;</span> feature that <span
                          class="fw-500 text-warning">&nbsp;incurs charges per user per month </span>. Reach out to your SkizzleHR
                        contact
                        person for any clarification.</p>
                    </div>
                  </div>
                  <div class="card-body  border">
                    <div class="row row-16">
                      <div class="col-lg-12 form-row ">
                        <label for="" class="form-label">Do you want to capture and verify photos when employees
                          check-in/check-out?</label>
                      </div>
                      <div class="col-lg-12 form-row mt-0">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_photo_need_verification" [value]=true
                              (change)="ValidateVerifyCapture()">
                            Yes
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_photo_need_verification" [value]=false
                              (change)="resetVerify()">
                            No
                          </label>
                        </div>
                      </div>
                      <ng-container *ngIf="f.is_photo_need_verification.value">
                        <div class="col-lg-12 form-row ">
                          <label for="" class="form-label">Verify photo when employee check-in/check-out from?</label>
                        </div>
                        <div class="col-lg-12 form-row mt-0">
                          <div class="form-check-inline">
                            <label class="form-check p-0 d-flex " for="checkid6">
                              <input type="checkbox" name="checked4" class="form-check-input ms-0"
                                formControlName="verify_photo_checkin_from_mobile" #checkid6 id="checkid6"
                                (change)="ValidateVerifyCapture()">Mobile
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check p-0 d-flex " for="checkid7">
                              <input type="checkbox" name="checked5" class="form-check-input ms-0"
                                formControlName="verify_photo_checkin_from_web" #checkid7 id="checkid7"
                                (change)="ValidateVerifyCapture()">Web
                            </label>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- selfie Attendance -->
            <div class="col-12">
                <label class="form-check p-0 d-flex " for="onduty">
                    <input type="checkbox" name="checked" class="form-check-input ms-0" formControlName="on_duty"
                        (change)="checksetRequired($event,'max_on_duty_day_type')" #checkid2>
                    <label class="form-check-label fs-16" >On duty</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Check this option, if you want to enable On Duty option for employees.
                        " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </label>
            </div>
            <ng-container *ngIf="f.on_duty.value">
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label">Do you want to allow backdated On duty </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select ‘Yes’ if you want to allow employee to raise On Duty request for past days.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_allow_backdated_onduty"
                                [value]=true (ngModelChange)="backDatedRestriction('is_allow_backdated_onduty','backdated_onduty_daytype')" >
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_allow_backdated_onduty"
                                [value]=false (ngModelChange)="backDatedRestriction('is_allow_backdated_onduty','backdated_onduty_daytype')">
                            No
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.is_allow_backdated_onduty.value">
                  <div class="col-lg-12 form-row ">

                        <label for="" class="form-label required">Maximum back days On Duty an employee can
                            apply</label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify the  maximum backdated days allowed for employees to raise an On Duty request. This dropdown will provide the values from 0 to 6 months (here the calendar month will be considered, eg: if 1 is selected from the dropdown and the current date is 25th Feb 2023, employee can raise a request for January month.)
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                  </div>

                  <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="backdated_onduty_daytype"
                                [value]=false (ngModelChange)="backDatedValidator('is_allow_backdated_onduty','backdated_onduty_daytype','backday_day','backday_month')">
                            Days
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="backdated_onduty_daytype"
                                [value]=true (ngModelChange)="backDatedValidator('is_allow_backdated_onduty','backdated_onduty_daytype','backday_month','backday_day')">
                            Month
                        </label>
                    </div>
                  </div>
                  <ng-container *ngIf="f.backdated_onduty_daytype.value==true;else daypart">

                    <div class=" col-lg-6 form-row" >

                      <ng-select type="text" class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('month')}}"
                          formControlName="backday_month" appendTo="body"
                          [ngClass]="{ 'is-invalid': (saveBtnClicked || f.backday_month.touched || f.backday_month.dirty) &&  f.backday_month.errors}">
                          <ng-option *ngFor="let number of createRange(6,0)" [value]="number">{{displaymonth(number)}}
                          </ng-option>
                      </ng-select>
                      <div *ngIf="(saveBtnClicked || f.backday_month.touched || f.backday_month.dirty) && f.backday_month.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.backday_month.errors.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #daypart>
                    <div class="col-lg-6 form-row">
                      <input type="text" class="form-control " formControlName="backday_day"
                          placeholder="{{this.messageService.placeholderdisp('days')}}"
                          [ngClass]="{ 'is-invalid': (saveBtnClicked || f.backday_day.touched || f.backday_day.dirty) &&  f.backday_day.errors}">
                      <div *ngIf="(saveBtnClicked || f.backday_day.touched || f.backday_day.dirty) && f.backday_day.errors"
                          class="invalid-feedback">
                          <div
                              *ngIf="!f.backday_day.errors.pattern && f.backday_day.errors.max">
                              {{this.messageService.fieldlengthvalidation('value',31)}}</div>
                          <div *ngIf="f.backday_day.errors.pattern">
                              {{this.messageService.validationDisplay('pattern')}}</div>
                              <div *ngIf="f.backday_day.errors?.required">
                                  {{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf=" !f.backday_day.errors.pattern && f.backday_day?.errors?.min">
                                    {{this.messageService.validationDisplay('pattern')}}</div>
                          </div>
                  </div>
                  </ng-template>
                </ng-container>
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label required">Maximum number of On Duty days allowed in a</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Set the limit on the number of on-duty requests employees can raise per week or month
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="max_on_duty_day_type"
                                [value]=false (ngModelChange)="resetRequestdaytype('max_on_duty_day_type','click')">
                            Week
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="max_on_duty_day_type"
                                [value]=true (ngModelChange)="resetRequestdaytype('max_on_duty_day_type','click')">
                            Month
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.max_on_duty_day_type.value !=null">
                    <div class=" col-lg-6 form-row">
                        <input type="text" class="form-control " placeholder="Enter count"
                            formControlName="max_on_duty_request_days"
                            [ngClass]="{ 'is-invalid': (saveBtnClicked || f.max_on_duty_request_days.touched || f.max_on_duty_request_days.dirty) &&  f.max_on_duty_request_days.errors}" (keyup)="resetRequestdaytype('max_on_duty_day_type','keyup')">
                        <div *ngIf="(saveBtnClicked || f.max_on_duty_request_days.touched || f.max_on_duty_request_days.dirty) && f.max_on_duty_request_days.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.max_on_duty_request_days.errors.required">
                                {{this.messageService.validationDisplay('required')}}</div>

                            <!-- <div
                                *ngIf="!f.max_on_duty_request_days.errors.pattern && f.max_on_duty_request_days.errors.maxlength">
                                {{this.messageService.fieldlengthvalidation('num','2')}}</div> -->
                                <div *ngIf="f.max_on_duty_request_days.errors.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                                <div
                                *ngIf="!f.max_on_duty_request_days.errors.pattern && f.max_on_duty_request_days.errors.max">
                                {{this.messageService.fieldlengthvalidation('value',formGp.value.max_on_duty_day_type == true ? '31' :'7')}}</div>
                                <div *ngIf="!(f.max_on_duty_request_days.errors.pattern) && f.max_on_duty_request_days?.errors?.min">
                                  {{this.messageService.validationDisplay('pattern')}}</div>

                        </div>
                    </div>
                </ng-container>
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label ">Do you want to allow future dated on duty </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enable this option if you want to allow employees to raise on duty request for future dates.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_future_on_duty"
                                [value]=true (click)="setRequired(true,'is_prior_notice_for_on_duty')">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_future_on_duty"
                                [value]=false (click)="setRequired(false,'is_prior_notice_for_on_duty')">
                            No
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.is_future_on_duty.value">
                <div class="col-lg-12 form-row " >
                    <label for="" class="form-label required">Prior days notice requires for applying On duty </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If future date is enabled, mention how many days prior employee has to raise on duty request.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_prior_notice_for_on_duty"
                                [value]=true (ngModelChange)="setRequired(true,'prior_day_for_on_duty')">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_prior_notice_for_on_duty"
                                [value]=false (ngModelChange)="setRequired(false,'prior_day_for_on_duty');setResetfunction('prior_day_for_on_duty')">
                            No
                        </label>
                    </div>
                </div>
                </ng-container>
                <ng-container *ngIf="f.is_prior_notice_for_on_duty.value">
                    <div class=" col-12 mt-8 form-row">
                        <input type="text" class="form-control " formControlName="prior_day_for_on_duty"
                            placeholder="{{this.messageService.placeholderdisp('days')}}"
                            [ngClass]="{ 'is-invalid': (saveBtnClicked || f.prior_day_for_on_duty.touched || f.prior_day_for_on_duty.dirty) &&  f.prior_day_for_on_duty.errors}">
                        <div *ngIf="(saveBtnClicked || f.prior_day_for_on_duty.touched || f.prior_day_for_on_duty.dirty) && f.prior_day_for_on_duty.errors"
                            class="invalid-feedback">
                            <div
                                *ngIf="!f.prior_day_for_on_duty.errors.pattern && f.prior_day_for_on_duty.errors.maxlength">
                                {{this.messageService.fieldlengthvalidation('num','3')}}</div>
                            <div *ngIf="f.prior_day_for_on_duty.errors.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                                <div *ngIf="f.prior_day_for_on_duty.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf=" !f.prior_day_for_on_duty.errors.pattern && f.prior_day_for_on_duty?.errors?.min">
                                      {{this.messageService.validationDisplay('pattern')}}</div>
                            </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="col-12">
                <label class="form-check p-0 d-flex " for="wfh">
                    <input type="checkbox" name="wfhcheck" class="form-check-input ms-0" formControlName="is_wfh_allowed"
                        (change)="checksetRequired($event,'max_wfh_request_day_type')" #wfhid>
                    <label class="form-check-label fs-16">Work from home</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Check this option, if you want to enable Work from home for the employees.
                        " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </label>
            </div>
            <ng-container *ngIf="f.is_wfh_allowed.value">
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label ">Do you want to allow backdated WFH? </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select ‘Yes’ if you want to allow employee to raise WFH request for past days.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_backdated_wfh_allowed"
                                [value]=true (ngModelChange)="backDatedRestriction('is_backdated_wfh_allowed','backdated_wfh_daytype')">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_backdated_wfh_allowed"
                                [value]=false (ngModelChange)="backDatedRestriction('is_backdated_wfh_allowed','backdated_wfh_daytype')">
                            No
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.is_backdated_wfh_allowed.value">
                    <div class="col-12">
                        <label for="" class="form-label required">Maximum back days WFH an employee can apply </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify maximum back dated days an employee can apply in a month.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                    </div>

                    <div class="col-lg-12 form-row mt-0">
                      <div class="form-check form-check-inline">
                          <label class="form-check-label">
                              <input class="form-check-input" type="radio" formControlName="backdated_wfh_daytype"
                                  [value]=false (ngModelChange)="backDatedValidator('is_backdated_wfh_allowed','backdated_wfh_daytype','wfh_day','wfh_month')">
                              Days
                          </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <label class="form-check-label">
                              <input class="form-check-input" type="radio" formControlName="backdated_wfh_daytype"
                                  [value]=true (ngModelChange)="backDatedValidator('is_backdated_wfh_allowed','backdated_wfh_daytype','wfh_month','wfh_day')">
                              Month
                          </label>
                      </div>
                  </div>
                  <ng-container *ngIf="f.backdated_wfh_daytype.value==true;else wfhdaypart">

                    <div class=" col-lg-6 form-row" >

                      <ng-select type="text" class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('month')}}"
                          formControlName="wfh_month" appendTo="body"
                          [ngClass]="{ 'is-invalid': (saveBtnClicked || f.wfh_month.touched || f.wfh_month.dirty) &&  f.wfh_month.errors}">
                          <ng-option *ngFor="let number of createRange(6,0)" [value]="number">{{displaymonth(number)}}
                          </ng-option>
                      </ng-select>
                      <div *ngIf="(saveBtnClicked || f.wfh_month.touched || f.wfh_month.dirty) && f.wfh_month.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.wfh_month.errors.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #wfhdaypart>
                      <div class="col-lg-6 form-row">
                      <input type="text" class="form-control " formControlName="wfh_day"
                          placeholder="{{this.messageService.placeholderdisp('days')}}"
                          [ngClass]="{ 'is-invalid': (saveBtnClicked || f.wfh_day.touched || f.wfh_day.dirty) &&  f.wfh_day.errors}">
                      <div *ngIf="(saveBtnClicked || f.wfh_day.touched || f.wfh_day.dirty) && f.wfh_day.errors"
                          class="invalid-feedback">
                          <div
                              *ngIf="!f.wfh_day.errors.pattern && f.wfh_day.errors.max">
                              {{this.messageService.fieldlengthvalidation('value',31)}}</div>
                          <div *ngIf="f.wfh_day.errors.pattern">
                              {{this.messageService.validationDisplay('pattern')}}</div>
                              <div *ngIf="f.wfh_day.errors?.required">
                                  {{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf=" !f.wfh_day.errors.pattern && f.wfh_day?.errors?.min">
                                      {{this.messageService.validationDisplay('pattern')}}</div>
                          </div>
                  </div>
                  </ng-template>
                </ng-container>
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label required">Maximum number of WFH days allowed in a
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Set the number of work from home requests the employees can raise per week or month.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="max_wfh_request_day_type"
                                [value]=false (ngModelChange)="resetRequestdaytype('max_wfh_request_day_type','click')">
                            Week
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="max_wfh_request_day_type"
                                [value]=true (ngModelChange)="resetRequestdaytype('max_wfh_request_day_type','click')">
                            Month
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.max_wfh_request_day_type.value !=null">
                    <div class=" col-lg-6 form-row">
                        <input type="text" class="form-control " placeholder="Enter count"
                            formControlName="max_wfh_request_days"
                            [ngClass]="{ 'is-invalid': (saveBtnClicked || f.max_wfh_request_days.touched || f.max_wfh_request_days.dirty) &&  f.max_wfh_request_days.errors}" (keyup)="resetRequestdaytype('max_wfh_request_days','keyup')">
                        <div *ngIf="(saveBtnClicked || f.max_wfh_request_days.touched || f.max_wfh_request_days.dirty) && f.max_wfh_request_days.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.max_wfh_request_days.errors.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <div
                                *ngIf="!f.max_wfh_request_days.errors.pattern && f.max_wfh_request_days.errors.max">
                                {{this.messageService.fieldlengthvalidation('value',formGp.value.max_wfh_request_day_type == true ? '31' :'7')}}</div>
                            <div *ngIf="f.max_wfh_request_days.errors.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                                <div *ngIf=" !f.max_wfh_request_days.errors.pattern && f.max_wfh_request_days?.errors?.min">
                                  {{this.messageService.validationDisplay('pattern')}}</div>
                            </div>
                    </div>
                </ng-container>
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label">Do you want to allow future dated WFH </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enable this option if you want to allow employees to raise work from home request for future dates.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_future_wfh" [value]=true (click)="setRequired(true,'is_prior_notice_for_wfh')">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_future_wfh" [value]=false (click)="setRequired(false,'is_prior_notice_for_wfh')">
                            No
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.is_future_wfh.value">
                <div class="col-lg-12 form-row required">
                    <label for="" class="form-label ">Prior days notice requires for applying WFH </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If future dated work from home is enabled, mention how many days prior employee has to raise work from home request.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_prior_notice_for_wfh"
                                [value]=true (ngModelChange)="setRequired(true,'prior_day_for_wfh')">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="is_prior_notice_for_wfh"
                                [value]=false (ngModelChange)="setRequired(false,'prior_day_for_wfh');setResetfunction('prior_day_for_wfh')">
                            No
                        </label>
                    </div>
                </div>
                </ng-container>
                <ng-container *ngIf="f.is_prior_notice_for_wfh.value">
                    <div class=" col-12 mt-8 form-row">
                        <input type="text" class="form-control " formControlName="prior_day_for_wfh"
                            placeholder="{{this.messageService.placeholderdisp('days')}}"
                            [ngClass]="{ 'is-invalid': (saveBtnClicked || f.prior_day_for_wfh.touched || f.prior_day_for_wfh.dirty) &&  f.prior_day_for_wfh.errors}">
                        <div *ngIf="(saveBtnClicked || f.prior_day_for_wfh.touched || f.prior_day_for_wfh.dirty) && f.prior_day_for_wfh.errors"
                            class="invalid-feedback">
                            <div *ngIf="!f.prior_day_for_wfh.errors.pattern && f.prior_day_for_wfh.errors.maxlength">
                                {{this.messageService.fieldlengthvalidation('num','3')}}</div>
                            <div *ngIf="f.prior_day_for_wfh.errors.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                                <div *ngIf="f.prior_day_for_wfh.errors?.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf=" !f.prior_day_for_wfh.errors.pattern && f.prior_day_for_wfh?.errors?.min">
                                  {{this.messageService.validationDisplay('pattern')}}</div>
                            </div>
                            </div>
                </ng-container>
                <div class="col-12">
                    <label for="" class="form-label required">Restrict employees from raising WFH requests on </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the appropriate option from the dropdown if you want to restrict the employees from raising WFH option on weekends or holidays by default weekends and holiday option is selected. For None option, Restriction is not applicable." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-6 mt-0 form-row">
                    <ng-select type="text" class="form-ngselect" placeholder="Select options"
                        formControlName="request_raising_restricted" appendTo="body"
                        [ngClass]="{ 'is-invalid': (saveBtnClicked || f.request_raising_restricted.touched || f.request_raising_restricted.dirty) &&  f.request_raising_restricted.errors}">
                        <ng-option *ngFor="let restrictedValue of restrictedValue" [value]="restrictedValue">{{restrictedValue}}
                            </ng-option>
                    </ng-select>
                    <div *ngIf="(saveBtnClicked || f.request_raising_restricted.touched || f.request_raising_restricted.dirty) && f.request_raising_restricted.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.request_raising_restricted.errors.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                    </div>
                </div>
            </ng-container>
            <div class="col-12">
                <label class="form-check p-0 d-flex " for="attndregular">
                    <input type="checkbox" name="check" class="form-check-input ms-0"
                        formControlName="is_attend_regular_allowed"
                        (change)="checksetRequired($event,'max_attend_regular_day_type')" #attndid>
                    <label class="form-check-label fs-16">Attendance regularization </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Check this option, if you want to enable Attendance regularization for the employees.
                        " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </label>
            </div>
            <ng-container *ngIf="f.is_attend_regular_allowed.value">
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label ">Do you want to allow backdated attendance regularization
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover=" Select ‘Yes’ if you want to allow employee to raise Attendance regularization request for past days.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio"
                                formControlName="is_backdated_attend_regular_allowed" [value]=true
                                (ngModelChange)="backDatedRestriction('is_backdated_attend_regular_allowed','backdated_attend_regular_daytype')">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio"
                                formControlName="is_backdated_attend_regular_allowed" [value]=false
                                (ngModelChange)="backDatedRestriction('is_backdated_attend_regular_allowed','backdated_attend_regular_daytype')">
                            No
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.is_backdated_attend_regular_allowed.value">
                    <div class="col-12">
                      <label for="" class="form-label required">Maximum back days attendance regularization can be
                          applied</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify maximum back dated days an employee can apply in a month.
                      " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                    </div>
                    <div class="col-lg-12 form-row mt-0">
                      <div class="form-check form-check-inline">
                          <label class="form-check-label">
                              <input class="form-check-input" type="radio" formControlName="backdated_attend_regular_daytype"
                                  [value]=false (ngModelChange)="backDatedValidator('is_backdated_attend_regular_allowed','backdated_attend_regular_daytype','regular_day','regular_month')">
                              Days
                          </label>
                      </div>
                      <div class="form-check form-check-inline">
                          <label class="form-check-label">
                              <input class="form-check-input" type="radio" formControlName="backdated_attend_regular_daytype"
                                  [value]=true (ngModelChange)="backDatedValidator('is_backdated_attend_regular_allowed','backdated_attend_regular_daytype','regular_month','regular_day')">
                              Month
                          </label>
                      </div>
                  </div>

                  <ng-container *ngIf="f.backdated_attend_regular_daytype.value==true;else regulardaypart">

                    <div class=" col-lg-6 form-row" >

                      <ng-select type="text" class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('month')}}"
                          formControlName="regular_month" appendTo="body"
                          [ngClass]="{ 'is-invalid': (saveBtnClicked || f.regular_month.touched || f.regular_month.dirty) &&  f.regular_month.errors}">
                          <ng-option *ngFor="let number of createRange(6,0)" [value]="number">{{displaymonth(number)}}
                          </ng-option>
                      </ng-select>
                      <div *ngIf="(saveBtnClicked || f.regular_month.touched || f.regular_month.dirty) && f.regular_month.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.regular_month.errors.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #regulardaypart>
                    <div class="col-lg-6 form-row">
                    <input type="text" class="form-control " formControlName="regular_day"
                        placeholder="{{this.messageService.placeholderdisp('days')}}"
                        [ngClass]="{ 'is-invalid': (saveBtnClicked || f.regular_day.touched || f.regular_day.dirty) &&  f.regular_day.errors}">
                    <div *ngIf="(saveBtnClicked || f.regular_day.touched || f.regular_day.dirty) && f.regular_day.errors"
                        class="invalid-feedback">
                        <div
                            *ngIf="!f.regular_day.errors.pattern && f.regular_day.errors.max">
                            {{this.messageService.fieldlengthvalidation('value',31)}}</div>
                        <div *ngIf="f.regular_day.errors.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="f.regular_day.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf=" !f.regular_day.errors.pattern && f.regular_day?.errors?.min">
                                    {{this.messageService.validationDisplay('pattern')}}</div>
                        </div>
                </div>
                </ng-template>
                </ng-container>
               <div class="col-lg-12 form-row ">
                    <label for="" class="form-label required">Maximum number of attendance regularization allowed in a</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Set the number of attendance regularization requests the employees can raise per week or month.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="max_attend_regular_day_type"
                                [value]=false (ngModelChange)="resetRequestdaytype('max_attend_regular_day_type','click')">
                            Week
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="max_attend_regular_day_type"
                                [value]=true (ngModelChange)="resetRequestdaytype('max_attend_regular_day_type','click')">
                            Month
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="f.max_attend_regular_day_type.value !=null">
                    <div class=" col-lg-6 form-row">
                        <input type="text" class="form-control " placeholder="Enter count"
                            formControlName="max_attend_regular_request_days"
                            [ngClass]="{ 'is-invalid': (saveBtnClicked || f.max_attend_regular_request_days.touched || f.max_attend_regular_request_days.dirty) &&  f.max_attend_regular_request_days.errors}" (keyup)="resetRequestdaytype('max_attend_regular_request_days','keyup')">
                        <div *ngIf="(saveBtnClicked || f.max_attend_regular_request_days.touched || f.max_attend_regular_request_days.dirty) && f.max_attend_regular_request_days.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.max_attend_regular_request_days.errors.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <!-- <div
                                *ngIf="!f.max_attend_regular_request_days.errors.pattern && f.max_attend_regular_request_days.errors.maxlength">
                                {{this.messageService.fieldlengthvalidation('num','2')}}</div> -->
                                <div
                                *ngIf="!f.max_attend_regular_request_days.errors.pattern && f.max_attend_regular_request_days.errors.max">
                                {{this.messageService.fieldlengthvalidation('value',formGp.value.max_attend_regular_day_type == true ? '31' :'7')}}</div>
                            <div *ngIf="f.max_attend_regular_request_days.errors.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                                <div *ngIf="!f.max_attend_regular_request_days.errors.pattern && f.max_attend_regular_request_days?.errors?.min">
                                  {{this.messageService.validationDisplay('pattern')}}</div>
                            </div>
                    </div>
                </ng-container>
                <div class="col-lg-12 form-row ">
                    <label for="" class="form-label ">Do you want to allow future dated attendance regularization? </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes, if you want to allow employees to raise attendance regularization request for future dates.
                    " popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="col-lg-12 form-row mt-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio"
                                formControlName="is_future_attend_regular_allowed" [value]=true>
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio"
                                formControlName="is_future_attend_regular_allowed" [value]=false>
                            No
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateForm()">NEXT</button>
    </footer>
</form>
