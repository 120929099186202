
<div class="container-fluid p-24 " #myIdentifier *ngIf="permissions">
  <div class="row mb-16 ">
      <div class="col-md-4 col-lg-6">
          <h3>Department</h3>
          <p class="text-helper mb-md-0">Departments are company's functional units. Add all of your company's departments.</p>
      </div>
      <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end gap-16 ">

              <div class="" style="width:22.6875rem;">
                  <div class="">
                      <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='departmentsearch' (searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchDept($event)" [isLoading]="isLoading"></app-searchbar>
                  </div>
                  <ul class="drop-down-menu search-result {{(inputFocusClass == true) ? 'active' : ''}}">
                      <p class="note"> No Result msg </p>
                      <p class="text-center text-helper mt-1">No Company Found...</p>
                      <p class="note"> Loader </p>
                      <p class="loader text-center">
                          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                      </p>
                      <p class="note"> Search Results</p>

                  </ul>

              </div>
              <div ngbDropdown class="d-inline-block">
                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13 "></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-end" >
                   <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';selectItemList('')">All</button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';selectItemList(true)">Active </button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
                </div>
             </div>
              <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addheadercontent($event)"><i
                      class="icon-plus"></i>Add
                  department</button>
          </div>
      </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 " *ngIf="loader">
      <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg20.png'" [noDataText]="'Department list is not configured yet.'"
      [noDataPara]="'Click \'Add Department\' to create the Department master list to manage workflows efficiently.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <ng-template #all_data>
      <div class="row row-16 "
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [fromRoot]="true"
        (scrolled)="onScrollDown()">
        <ng-container  *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 ffff" *ngFor="let dept of departments,let i = index">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                  <div class="d-flex view-section" (click)="viewDepartment(dept.id)">
                    <div class="logo-img {{dept.color_code}}">{{this.appService.getFirstChar(dept.name,2)}}</div>
                    <div class="overflow-hidden">
                        <h6 class="mb-0 tips">{{dept.name}}</h6>
                        <span class="tips-helper">{{dept.name}}</span>
                        <span class="fs-10 text-uppercase fw-500 {{(dept.is_active == true) ? 'text-success' : 'text-danger'}}">{{dept.is_active == true ? 'Active':'Deleted'}}</span>
                    </div>
                  </div>

                    <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="dept.is_active && (permissions.e || permissions.d)">
                      <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                          ngbDropdownToggle>
                          <i class="icon-three-dots-vertical "></i>
                      </button>
                      <div ngbDropdownMenu class="bottom-left fs-14 " aria-labelledby="dropdownBasic1">
                          <button *ngIf="permissions.e" ngbDropdownItem (click)="editheadercontent(dept.id,dept.is_active)">
                              <i   class="icon-pencil-square me-1"></i> Edit
                          </button>
                          <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletedept(dept.id)">
                              <i class="icon-trash  me-1"></i> Delete
                          </button>
                      </div>
                  </div>
                </div>
                </div>
            </div>
        </ng-container>

         <!-- Loader updations -->
         <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
        </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="hiddenvalue" >
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

</div>

<!-- <app-pagination></app-pagination> -->
<div class="side-panel" style="--sidepanel-width:23.75rem;" [class.side-pane-active]='showSidePanel1 === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Details</h5>
          <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg "></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid px-32  py-24 ">
            <!-- View loader start -->
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <!-- View loader end -->
            <ng-container *ngIf="!loader1">
              <div class="d-flex mb-16 ">
                  <p class="text-uppercase fw-500 mb-0"> Department</p>
                  <a class="link-primary fs-14  d-flex align-items-center ms-auto" (click)="editheadercontent(Deptid,Dept_is_active)" *ngIf="Dept_is_active==true && permissions.e">
                      <i   class="icon-pencil-square me-1"></i> Edit
                  </a>
              </div>
              <div class="row row-16  mb-16 ">
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 py-16">
                          <div class="row row-24 " >
                              <div class="col-12 "><span class="title">DEPARTMENT DETAILS</span></div>
                              <div class="col-6">
                                  <div class="detail-title">Department name</div>
                                  <div class="detail-desc">{{deptView}}
                                  </div>
                              </div>
                              <div class="col-6">
                                <div class="detail-title">Status</div>
                                <div class="detail-desc">
                                  <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="Dept_is_active">ACTIVE</span>
                                  <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="Dept_is_active==false">DELETED</span>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>

              </div>
            </ng-container>
          </div>
      </div>
  </form>
</div>

<!-- Slider add start-->
<div class="side-panel" style="--sidepanel-width:23.75rem;" [class.side-pane-active]='showSidePanel === true'>
    <form [formGroup]="deptForm" (ngSubmit)="onSubmit()"  class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5><span [innerHTML]="header"></span> Department</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32  py-24 ">
               <!-- Edit loader start -->
               <app-edit-loader *ngIf="loader1" ></app-edit-loader>
               <!-- Edit loader end -->
              <ng-container *ngIf="!loader1" >
                <p class="text-uppercase fw-500 mb-12 "><span [innerHTML]="header" class="text-uppercase"></span>  DEPARTMENT DETAILS </p>
                <div class="row row-12  ">
                    <div class="col-12 form-row">
                        <label for="name" class="form-label required fs-14 ">Department name </label>
                        <!-- <input type="text" formControlName="name" class="form-control {{((userPost.form.dirty && pattern == true) || (userPost.form.dirty && validationCount == true) || ((userPost.form.touched && validationmsg == true ))) ? 'is-invalid' : ''}}" name="name" id="name" placeholder="Add Department Name" ngModel [(ngModel)]=depGetRes (ngModelChange)="nameChanged($event)">
                        <div *ngIf="userPost.form.touched && validationmsg==true" class="invalid-feedback">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="userPost.form.dirty && validationCount==true" class="invalid-feedback">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf="userPost.form.dirty && pattern==true" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div> -->
                        <input type="text" class="form-control" formControlName="name" name="" id="name" value=""
                        placeholder="{{this.messageService.placeholderdisp('department name')}}"
                        [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
                        <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                            <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                            <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
        <footer class="side-panel-footer gap-32 "  *ngIf="permissions && !loader1">
            <button *ngIf="deleteClass==true && permissions.d" [disabled]=!updateClass class="btn btn-outline-danger text-uppercase btn-sm" (click)="deleteToggle = true" type="button">DELETE</button>
            <button *ngIf="(addEditBtn=='Save' && permissions.a) || (addEditBtn=='Save' && permissions.e)" [disabled]='!updateClass || !deptForm.valid'  class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit" ><span [innerHTML]="addEditBtn"></span></button>
        </footer>
    </form>

</div>
<!-- slider section end -->

  <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16 ">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Department?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Department')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteContent()">Delete</button>
        </div>
      </div>
    </div>
  </div>
