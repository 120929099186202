<!-- <router-outlet></router-outlet> -->

<app-sidebar  *ngIf="showSidebar && this.authService.MENUCREATED" (changeThemesidePanel1)="themefunction($event)"  [changeThemesidePanel]="changeThemesidePanel" class="main-sidebar {{authService.SIDEBARCLASS}}" (sidebarExpanded)="checkSidebarExpanded($event)"></app-sidebar>
<app-header (changeThemesidePanel1)="themefunction($event)" *ngIf="showHeader" [changeThemesidePanel]="changeThemesidePanel" class="main-topbar "  ></app-header>
<main class="main-container" *ngIf="((this.authService.isEmployeeId || this.authService.isSuperUser()) && !logInDataReq) || nouser">
    <div class="main-content">
      <app-profile-pic  *ngIf="addProfileModal" [addProfileModal]="addProfileModal" [loginAs]="'Employee'" [text]="'Add'" [imageUrl]="null"  (close)="addProfileModal=false;this.authService.NEEDPROFILEPIC =false"></app-profile-pic>
      <app-notification-leave-year-end *ngIf="showNotification" [bannerData]="bannerData"></app-notification-leave-year-end>
        <router-outlet></router-outlet>
    </div>
 </main>

