<div class="container-fluid p-24 " *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Manage Documents</h3>
            <p class="text-helper mb-md-0">View and upload documents and map them under specific category.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">

              <div style="width:22.6875rem;">
                <div class="">
            <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='policyPlaceholder'
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchPolicy($event)" [isLoading]="sLoading"></app-searchbar>
                </div>
              </div>
                <div ngbDropdown class="d-inline-block" (click)="filter=true">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>

                </div>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/document/manage-documents-setup"
          *ngIf="permissions?.a">
                  <i class="icon-plus"></i>Add DOCUMENT
                </button>
            </div>
        </div>
    </div>
    <div class="row row-16">
      <app-table-loader *ngIf="loader"></app-table-loader>
        <ng-container *ngIf="policyDetails.length>0 && !loader">

          <div class="col-12" *ngIf="usedFileSize>=80">
              <div class="card overflow-hidden card-c2 border-0 hstack radius-8">
                  <div class="cbg-danger p-16 fs-12 text-nowrap">
                      {{usedFileSize}}% of storage used
                  </div>
                  <div class="hstack align-items-center gap-16 w-100 p-16">
            <span class="fs-10 text-light-400 text-nowrap">{{consumedFileData?.used_size}}
              {{consumedFileData?.used_unit}}</span>
            <ngb-progressbar class="progress-type4 h-13 w-100" type="danger" [value]="usedFileSize"
              ngbPopover='{{consumedFileData?.used_size}} {{consumedFileData?.used_unit}} of 5 GB used'
              popoverClass="popover-default popover-info" container="body" triggers="hover"></ngb-progressbar>
                      <span class="fs-10 text-light-400 text-nowrap">5 GB</span>
                  </div>
              </div>
          </div>

          <div class="col-12" *ngIf="usedFileSize<80">
            <div class="card overflow-hidden card-c2 border-0 hstack radius-8">
                <div class="cbg-1 p-16 fs-12 text-nowrap">
                    {{usedFileSize}}% of storage used
                </div>
                <div class="hstack align-items-center gap-16 w-100 p-16">
            <span class="fs-10 text-light-400 text-nowrap">{{consumedFileData?.used_size}}
              {{consumedFileData?.used_unit}}</span>
            <ngb-progressbar class="progress-type4 h-13 w-100" type="info" [value]="usedFileSize"
              ngbPopover='{{consumedFileData?.used_size}} {{consumedFileData?.used_unit}} of 5GB used'
              popoverClass="popover-default popover-info" container="body" triggers="hover"></ngb-progressbar>
                    <span class="fs-10 text-light-400 text-nowrap">5 GB</span>
                </div>
            </div>
          </div>
        </ng-container>
        <div class="col-12">
          <div class="row " style="height: calc(100vh - 14rem);" *ngIf="!loader && policyDetails.length<=0">
            <app-nodata-view class="vstack" [noDataImg]="'bg9.png'" [noDataText]="'Effortlessly manage company docs!'"
              [noDataPara]="'It looks like there are no documents available yet. Click the &quot;Add Document&quot; button to upload company policies or official documents for employees.'"
              [hasPermission]="false">
            </app-nodata-view>
          </div>
        </div>
        <div class="col-12">
            <div class="card card-c2 p-0" *ngIf="!loader && (policyDetails.length>0)">

        <div class="table-responsive table-minheight scrollbar-10 radius-4"
          style="max-height: calc(100vh - 20.5rem);">
                    <table class="table sticky-header table-striped table-sm td-white-space">
                        <thead>
                            <tr>
                                <th class="fw-600" scope="col">Policy name</th>
                                <th class="fw-600" scope="col">Category</th>
                                <th class="fw-600" scope="col">Acknowledgement</th>
                                <th class="fw-600" scope="col">Acknowledgement Recieved</th>
                                <th class="fw-600" scope="col">Created on</th>
                                <th class="fw-600" scope="col">Created by</th>
                                <th class="fw-600" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                          <!-- <tr *ngIf="policyDetails.length<=0">
                            <td colspan="7">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                              </div>
                            </td>
                          </tr> -->

              <tr
                *ngFor="let item of $any(policyDetails |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">

                            <td>
                              <div class="align-items-center d-flex text-trim w-30ch" title="{{item?.subject}}">
                    <span
                      class="badge badge-circle {{item?.priority=='Medium'?'badge-orange':item?.priority=='High'?'badge-red':'badge-green'}} sq-10 p-0 me-8"></span>
                                <span (click)="viewPolicyDetails(item?.id)" class="link-primary1 ">{{item?.subject}}</span>
                              </div>
                            </td>
                            <td>{{item['category name']}}</td>
                            <td>{{item?.is_acknowledge==true?'Yes':'No'}}</td>
                <td>{{item?.is_acknowledge==false?'--':''}}<span class="link-primary1" (click)="getAckList(item?.id)"
                    *ngIf="item?.total_employee_count>0">{{item?.acknowledgement_accepted_count}}/{{item?.total_employee_count}}</span>
                </td>
                            <td>{{this.appService.dateFormatDisplay(item?.created_on)}}</td>
                            <td>{{item?.created_by}} {{item?.created_emp_code!=null?'('+item?.created_emp_code+')':''}}</td>
                            <td>
                              <span *ngIf="item.status==true"  class="badge  badge-success py-1">Active</span>
                              <span *ngIf="item.status==false"  class="badge badge-danger py-1">Inactive</span>

                            </td>
                          </tr>


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="this.appService.filteredcount(policyDetails,searchString)>pageSize">
          <ngb-pagination class="d-flex justify-content-end"
            [collectionSize]="this.appService.filteredcount(policyDetails,searchString)" [(page)]="page" [maxSize]="3"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
    </div>
    </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <app-view-loader *ngIf="viewLoader"></app-view-loader>
              <ng-container *ngIf="!viewLoader">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">{{singlePolicyData?.subject}}</p>
            <a *ngIf="singlePolicyData?.status==true && permissions?.e" class="link-primary fs-14 ms-auto text-nowrap"
              routerLink="/document/manage-documents-setup/{{activeId}}"><i class="icon-pencil-square me-1"></i>
              Edit</a>
                </div>
                <div class="row row-16 mb-24">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 ">
                                    <p class="title mb-8">POLICY Details</p>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Subject</div>
                                    <div class="detail-desc">{{singlePolicyData?.subject}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Description </div>
                                    <div class="detail-desc">{{singlePolicyData?.description}}</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="detail-title">Priority</div>
                                    <div class="detail-desc">
                                        <div class="d-flex align-items-center">
                        <span
                          class="badge badge-circle  sq-10 p-0 me-8 {{singlePolicyData?.priority=='Medium'?'badge-orange':singlePolicyData?.priority=='High'?'badge-red':'badge-green'}}"></span>
                                            <span>{{singlePolicyData?.priority}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="detail-title">Category</div>
                                    <div class="detail-desc">{{singlePolicyData['category name']}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Attachments</div>
                                    <div class="detail-desc">
                                        <div class="card card-c2 p-0 w-100">
                                            <div class="table-responsive  scrollbar-10 radius-4">
                                              <table class="table sticky-header table-striped table-sm  td-white-space">
                                                    <thead>
                                                        <tr>
                                                            <th class="fw-600" scope="col">Files</th>
                                                            <th class="fw-600" scope="col">Uploaded on</th>
                                                            <th class="fw-600" scope="col">uploaded by</th>
                                                            <th class="fw-600" scope="col">versions</th>
                                <th class="fw-600" scope="col" *ngIf="permissions?.a && singlePolicyData?.status==true">
                                  ACTION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                <td class="text-trim w-18ch"
                                  title="{{this.appService.getDocName(singlePolicyData?.doc_policy_attachment)}}">
                                  {{this.appService.getDocName(singlePolicyData?.doc_policy_attachment)}}</td>
                                                            <td>{{this.appService.dateFormatDisplay(singlePolicyData?.created_on)}}</td>
                                                            <td>{{singlePolicyData?.created_by}}</td>
                                                            <td><span (click)="getVersions()" class="link-primary1">Show versions</span></td>
                                <td *ngIf="permissions?.a && singlePolicyData?.status==true"><span
                                    (click)="clearUploadedData()" class="link-primary1">Upload new version</span></td>
                              </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">URL</div>
                                    <div class="detail-desc">
                                      <!-- {{singlePolicyData?.url!=null && singlePolicyData?.url!=''?singlePolicyData?.url:'-'}} -->
                                      <ng-container *ngIf="singlePolicyData?.url!=null && singlePolicyData?.url!='';else noLink">
                                        <a (click)="goToLink(singlePolicyData?.url)" class="link-primary1">{{singlePolicyData?.url}}</a>
                                      </ng-container>
                                      <ng-template #noLink>
                                        <span class="">--</span>
                                      </ng-template>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="singlePolicyData?.is_download">
                                    <div class="hstack align-items-center gap-8 fs-14">
                                        <i class="icon-check-square-fill text-light-400"></i>Allow employee to download
                                        the document
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="singlePolicyData?.is_acknowledge">
                                  <div class="hstack align-items-center gap-8 fs-14">
                                      <i class="icon-check-square-fill text-light-400"></i>Acknowledgement required from employee
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>



                    <div class="col-12">
                      <div class="card details-card shadow-0 px-24 py-16">
                          <div class="row row-16 ">
                              <div class="col-12 "><span class="title">APPLICABILITY</span></div>
                              <div class="col-12">
                                  <div class="detail-title">Applicable To</div>
                                  <div class="detail-desc">{{assignRight?.is_organisation==true?'Organization':'Employee'}}</div>
                              </div>
                              <div class="col-md-6 " *ngIf="assignRight?.company.length">
                                  <div class="detail-title">Company </div>
                                  <div class="detail-desc">

                                    <div class="selected-values mt-1" >

                                      <li class="ng-value" *ngFor="let rights of assignRight?.company, let i=index;">
                                          <div class="ng-value-label" title="{{rights.company_name}}">
                                            <span class="d-inline-flex align-items-center">
                              <img class="img-fluid rounded-circle sq-24" src="{{rights.logo}}"
                                *ngIf="rights.logo !=  '' && rights.logo !=  null">
                              <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                *ngIf="rights.logo ==  null || rights.logo ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                                <span> {{rights.company_name}}</span>
                                             </span>
                                          </div>
                                        </li>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-6 " *ngIf="assignRight?.businessunit.length">
                                  <div class="detail-title">Business Unit </div>
                                  <div class="detail-desc">
                                    <div class="d-flex flex-wrap mt-8 gap-8">

                                    <ng-container *ngFor="let rights of assignRight?.businessunit, let i=index;">
                          <span class="badge3" title="{{rights.name}}"> {{ (rights.length>25)? (rights |
                            slice:0:25)+'..':(rights.name) }}
                                      </span>
                                    </ng-container>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-6 " *ngIf="assignRight?.department.length">
                                  <div class="detail-title">Department </div>
                                  <div class="detail-desc">
                                    <div class="d-flex flex-wrap mt-8 gap-8">

                                      <ng-container *ngFor="let rights of assignRight?.department, let i=index;">
                          <span class="badge3" title="{{rights.name}}"> {{ (rights.length>25)? (rights |
                            slice:0:25)+'..':(rights.name) }}
                                        </span>
                                      </ng-container>
                                    </div>

                                  </div>
                              </div>
                              <div class="col-md-6 " *ngIf="assignRight?.designation.length">
                                  <div class="detail-title">Designation </div>
                                  <div class="detail-desc">
                                    <div class="d-flex flex-wrap mt-8 gap-8">

                                      <ng-container *ngFor="let rights of assignRight?.designation, let i=index;">
                          <span class="badge3" title="{{rights.name}}"> {{ (rights.length>25)? (rights |
                            slice:0:25)+'..':(rights.name) }}
                                        </span>
                                      </ng-container>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-6 " *ngIf="assignRight?.branch.length">
                                  <div class="detail-title">Branch </div>
                                  <div class="detail-desc">
                                    <div class="d-flex flex-wrap mt-8 gap-8">

                                      <ng-container *ngFor="let rights of assignRight?.branch, let i=index;">
                          <span class="badge3" title="{{rights.name}}"> {{ (rights.length>25)? (rights |
                            slice:0:25)+'..':(rights.name) }}
                                        </span>
                                      </ng-container>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-6 " *ngIf="assignRight?.grade.length">
                                  <div class="detail-title">Grade </div>
                                  <div class="detail-desc">
                                    <div class="d-flex flex-wrap mt-8 gap-8">

                                    <ng-container *ngFor="let rights of assignRight?.grade, let i=index;">
                          <span class="badge3" title="{{rights.name}}"> {{ (rights.length>25)? (rights |
                            slice:0:25)+'..':(rights.name) }}
                                      </span>
                                    </ng-container>
                                    </div>
                                  </div>
                              </div>

                              <div class="col-12" *ngIf="assignRight?.employee.length">
                                  <div class="detail-title">Employee </div>
                                  <div class="detail-desc">

                                    <div class="selected-values mt-1" >

                                      <li class="ng-value" *ngFor="let rights of assignRight?.employee, let i=index;">
                                          <div class="ng-value-label" title="{{rights?.name}}">
                                            <span class="d-inline-flex align-items-center">
                              <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}"
                                *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                              <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                                <span> {{rights.name}}</span>
                                             </span>
                                          </div>
                                      </li>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                </div>
              </ng-container>
            </div>
        </div>
        <footer class="side-panel-footer" *ngIf="!viewLoader && singlePolicyData?.status==true && permissions?.d" >
          <button  class="btn btn-outline-danger text-uppercase btn-sm ms-auto"
              (click)="deletConfirm('Document policy',activeId)" type="button">DELETE</button>

        </footer>
    </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='versionDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Versions</h5>
            <a class="toggle-panel " (click)="versionDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="d-flex mb-16" *ngIf="!versionLoader">
                    <p class="text-uppercase fw-500 mb-0">{{singlePolicyData?.subject | uppercase  }} VERSIONS</p>
                </div>
                <div class="row row-16 mb-24">
                    <div class="col-12">
                        <div class="card card-c2 p-0">
                        <app-table-loader *ngIf="versionLoader"></app-table-loader>

                            <div class="table-responsive  scrollbar-10 radius-4" *ngIf="!versionLoader">
                                <table class="table sticky-header table-striped table-sm td-white-space ">
                                    <thead>
                                        <tr>
                                            <th class="fw-600" scope="col">Files</th>
                                            <th class="fw-600" scope="col">Uploaded on</th>
                                            <th class="fw-600" scope="col">uploaded by</th>
                                            <th class="fw-600" scope="col">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tr *ngIf="versionHistory.length<=0">
                                      <td colspan="4">
                                          <div class="card card-c2 flex-center p-16 bg-dark-500">
                                              <span class="fw-500 fs-14">No data found</span>
                                            </div>
                                      </td>
                                    </tr>
                                    <tbody>
                    <tr
                      *ngFor="let item of $any(versionHistory | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                      <td (click)="viewDocument(item?.doc_file)" class="text-trim w-18ch"
                        title="{{item?.doc_file_name}}"> <span class="link-primary1">{{item?.doc_file_name}}</span>
                      </td>
                                            <td>{{this.appService.dateFormatDisplay(item?.created_at)}}</td>
                                            <td>{{item?.created_by}}</td>
                                            <td>
                                                <div class="d-flex gap-16">
                          <span class="link-primary1"
                            (click)="this.appService.downloadFile(item?.doc_file,item?.doc_file_name)"> <i
                              class="icon-download"></i></span>
                          <span *ngIf="singlePolicyData?.status==true" class="link-danger"
                            (click)="deletConfirm('File',item?.id)"> <i class="icon-trash"></i></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="this.appService.filteredcount(versionHistory,'')>pageSize">
                      <ngb-pagination class="d-flex justify-content-end"
                        [collectionSize]="this.appService.filteredcount(versionHistory,'')" [(page)]="page" [maxSize]="3"
                        [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='uploadVersion === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Upload Versions</h5>
            <a class="toggle-panel " (click)="uploadVersion = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">{{singlePolicyData?.subject | uppercase }} </p>
                </div>
                <div class="row row-16 mb-24">
                    <div class="col-12">
                        <div class="card card-c2 p-24">
                            <div class="row row-16">
                                <div class="col-12 d-flex">
                                    <span class="title">{{singlePolicyData?.subject | uppercase }} </span>
                                    <!-- <label class="link-primary d-flex fs-14 align-items-center ms-auto" *ngIf="docURL"(click)="deleteFile()">
                                      <i class="icon-trash me-8"></i> delete

                                    </label> -->
                  <a class="link-danger d-flex align-items-center ms-auto" *ngIf="docURL" (click)="deleteFile()"><i
                      class="icon-trash me-8 "></i></a>

                                </div>
                                <div class="col-12" *ngIf="isupdate==false && docURL==''">
                                    <label class="upload-btn " [ngClass]="{ 'is-invalid': (fileError == true)  }">
                                        <div class="upload-text">Upload documents <span class="text-primary">here
                        <app-file-upload-docrepo [crop]="false" [round]="false" [id]="'empDoc'"
                          [FileType]="'.pdf,.doc,.docx'" [width]="504" (dataURL)="getURL($event)"
                          (loader)="getloader($event)" (fileformat)="getfileformat($event)"
                          (validsize)="getvalidsize($event)"></app-file-upload-docrepo>
                                        </span>
                                            <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC</div>
                                        </div>
                                        <input type="file" class="form-control" placeholder="" hidden>
                                    </label>
                                    <div *ngIf="(fileError == true)" class="invalid-feedback">
                                      <div>{{fileErrorMsg}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 " *ngIf="(docURL!=''&& docURL!=null) || docLoader ">
                                    <div class="card card-c2  bg-secondary">
                                        <div class="card-body  ">
                                            <div class="d-flex align-items-start mb-8 gap-16">
                                                <i class="icon-file-earmark-text-fill fs-32 mt-1 text-accent2"></i>
                                                <div *ngIf="!docLoader">
                                                  <h6 class="mb-1">{{this.appService.getDocName(docURL)}}</h6>

                                                </div>
                                                <div class="ms-auto ">
                                                    <i class="icon-check-circle-bold text-success fs-20"></i>
                                                </div>
                                            </div>
                                            <div class="d-flex gap-24 fs-12 fw-500">
                                                <ng-container *ngIf="docURL!='' && docURL!=null && docLoader==false">

                                                  <div class="link-primary" (click)="this.appService.downloadFile(docURL,currentDocName)">
                                                      <i class="bi bi-download me-1"  ></i>Download
                                                  </div>
                                                  <div class="link-primary" (click)="viewDocument(docURL)">
                                                      <i class="bi bi-eye me-1"></i>View
                                                  </div>
                                                </ng-container>
                                                <div class="  flex-center text-center" *ngIf="docLoader">
                                                  <div class="fs-12 text-muted">Loading </div>
                                                  <div class="ms-4 dot-pulse"></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

    </div>
      <footer class="side-panel-footer">
      <button type="submit" class="btn btn-primary btn-sm ms-auto" [disabled]="(docURL=='' || docURL==null)"
        (click)="saveVersion()">SAVE</button>
    </footer>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:27rem;" [class.side-pane-active]='viewEmp === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Acknowledgement details</h5>
            <a class="toggle-panel" (click)=" viewEmp = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">

            <ng-container *ngIf="!ackLoader">

          <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab2" class="nav nav-custom sticky top-0"
            (navChange)="onNavChange($event)">
                  <li [ngbNavItem]="1">
                      <a ngbNavLink class="nav-link">Acknowledged</a>
                      <ng-template ngbNavContent>
                        <div class="container-fluid p-32 h-100">
                          <div class="row row-16">
                            <div class="col-12">
                              <div class="form-icon2">
                                <i class="icon-search icon-left fs-14"></i>
                        <div _ngcontent-kjn-c1041="" class="btn-close icon-right pointer fs-12"
                          (click)="ackSearchString=''" *ngIf="(ackSearchString != '' && ackSearchString != null)"></div>
                        <input type="text" class="form-control fs-14"
                          placeholder="{{this.messageService.searchdisplay('')}}" [(ngModel)]="ackSearchString"
                          [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <ng-template #all_data>

                              <div class="col-12" *ngFor="let item of ackCompleteList|filter:ackSearchString;let ind = index">
                                <div class="card card-c2 ">
                                  <div class="card-body d-flex align-items-center">
                            <div *ngIf="(item.employee.profile_image == null || item.employee.profile_image=='')"
                              class="avatar-circle sq-57 {{item.employee.color_code}} avatar-border">
                              {{this.appService.getFirstChar(item.employee_full_name,2)}}</div>
                            <img *ngIf="(item.employee.profile_image != null && item.employee.profile_image!='')"
                              src="{{ item.employee.profile_image }}" class="avatar-circle sq-57 avatar-border2" />
                                      <div class="overflow-hidden  d-flex flex-column">
                                          <p class="fs-14 fw-600 ms-16 mb-0">{{item?.employee_full_name}} </p>
                                          <p class="fs-12 fw-500 text-light-400 ms-16 mb-0">{{item?.designation.name}} |
                                              {{item?.employee.employee_code}}
                                          </p>
                                      </div>
                                  </div>
                                </div>
                              </div>

                            </ng-template>
                            <ng-container  *ngIf="ackLoader">
                              <div class="col-12" *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
                                <app-card-view-loader></app-card-view-loader>
                              </div>
                            </ng-container>
                            <div class="col-12" *ngIf="(ackCompleteList|filter:ackSearchString).length <=0 ;else all_data">
                              <div class="row" style="height: calc(100vh - 19rem);" >
                                <app-nodata-view class="vstack" [imgClass]="'img-xs'" [noDataImg]="'bg9.png'"></app-nodata-view>
                              </div>
                            </div>
                           </div>
                        </div>
                      </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link">Not Acknowledged</a>
                    <ng-template ngbNavContent>
                      <div class="container-fluid p-32 h-100">
                        <div class="row row-16">
                          <div class="col-12">
                            <div class="form-icon2">
                              <i class="icon-search icon-left fs-14"></i>
                        <div _ngcontent-kjn-c1041="" class="btn-close icon-right pointer fs-12"
                          (click)="ackSearchString=''" *ngIf="(ackSearchString != '' && ackSearchString != null)"></div>
                        <input type="text" class="form-control fs-14"
                          placeholder="{{this.messageService.searchdisplay('')}}" [(ngModel)]="ackSearchString"
                          [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                          <ng-template #all_data>

                            <div class="col-12" *ngFor="let item of ackPendingList|filter:ackSearchString;let ind = index">
                              <div class="card card-c2 ">
                                <div class="card-body d-flex align-items-center">
                            <div *ngIf="(item.employee.profile_image == null || item.employee.profile_image=='')"
                              class="avatar-circle sq-57 {{item.employee.color_code}} avatar-border">
                              {{this.appService.getFirstChar(item.employee_full_name,2)}}</div>
                            <img *ngIf="(item.employee.profile_image != null && item.employee.profile_image!='')"
                              src="{{ item.employee.profile_image }}" class="avatar-circle sq-57 avatar-border2" />
                                    <div class="overflow-hidden  d-flex flex-column">
                                        <p class="fs-14 fw-600 ms-16 mb-0">{{item?.employee_full_name}} </p>
                                        <p class="fs-12 fw-500 text-light-400 ms-16 mb-0">{{item?.designation.name}} |
                                            {{item?.employee.employee_code}}
                                        </p>
                                    </div>
                                </div>
                              </div>
                            </div>

                          </ng-template>
                          <ng-container  *ngIf="ackLoader">
                            <div class="col-12" *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
                              <app-card-view-loader></app-card-view-loader>
                            </div>
                          </ng-container>
                            <div class="col-12"  *ngIf="(ackPendingList|filter:ackSearchString).length <=0 ;else all_data">
                              <div class="row" style="height: calc(100vh - 19rem);">
                                <app-nodata-view class="vstack" [imgClass]="'img-xs'" [noDataImg]="'bg9.png'"></app-nodata-view>
                              </div>
                            </div>
                         </div>
                      </div>
                    </ng-template>
                </li>
              </nav>
              <div class=" " [ngbNavOutlet]="nav"></div>
            </ng-container>
        </div>
      <footer class="side-panel-footer"
        *ngIf="(activeTab2==1 && ackCompleteList.length>0) || (activeTab2==2 && ackPendingList.length>0)">
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="exportAckData()">
            Export
          </button>
        </footer>
    </form>
</div>

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
      [currentExtension]="currentExtension" [fileName]="viewUrl"></app-view-file>
</ng-container>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16 ">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete {{from}}?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{deleteMsg}}</p>
          </div>
          <div class="modal-footer" >
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
            (click)="deletePolicy()">Delete</button>
          </div>
      </div>
  </div>
</div>

  <div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='filter === true'
    [formGroup]="filterForm">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="closefn()"><i class=" icon-close-lg fs-16"></i></a>

    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 ">
          <div class="col-12 form-row">
            <label for="" class="form-label ">Category</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Category')}}"
              formControlName="category" [items]="categoryData" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ item.name }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.category.length">
                  <span class="ng-value-label">{{filterForm.value.category.length}} Category
                    Selected</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16 w-100" *ngIf="filterForm.value.category?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.category,let ki =index">
                <ng-container *ngFor="let insideitem of categoryData, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                  <span class="ng-value-icon right" (click)="clearcommonForm('category',ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <div class="col-12 form-row">
            <label for="" class="form-label ">Acknowledgement</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Acknowledgement')}}"
              formControlName="acknowledgement" [items]="ackData" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ item.name }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.acknowledgement.length">
                  <span class="ng-value-label">{{filterForm.value.acknowledgement.length}} Acknowledgement
                    Selected</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16 w-100" *ngIf="filterForm.value.acknowledgement?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.acknowledgement,let ki =index">
                <ng-container *ngFor="let insideitem of ackData, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span class="ng-value-icon right" (click)="clearcommonForm('acknowledgement',ki)"
                  aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <div class="col-12">
            <label for="name" class="form-label ">Created on</label>
            <div class="input-group form-icon icon-right">
                <input (click)="datePicker2.open()" class="form-control"
                placeholder="{{this.messageService.selectplaceholddisplay('Created on')}}" matInput
                formControlName="createdon" [matDatepicker]="datePicker2" [min]="minDate"><i
                class="icon-calendar pointer" (click)="datePicker2.open()"></i>
              <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
            </div>

          </div>
          <!-- <div class="col-12 form-row">
            <label for="" class="form-label ">Created by</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Created by')}}"
              formControlName="createdby" [items]="createdByData" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ item.name }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.createdby.length">
                  <span class="ng-value-label">{{filterForm.value.createdby.length}} Created by
                    Selected</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.createdby?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.createdby,let ki =index">
                <ng-container *ngFor="let insideitem of createdByData, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span class="ng-value-icon right" (click)="clearcommonForm('createdby',ki)"
                  aria-hidden="true">×</span>
              </li>
            </ul>
          </div> -->
          <div class="col-12 form-row">
            <label for="" class="form-label">Created by</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Created by')}}"
              formControlName="createdby" [items]="createdByData" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.createdby?.length">
                  <span class="ng-value-label">{{filterForm.value.createdby.length}} Created by
                    Selected</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                    [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                    class="avatar-sm sq-24 img-fluid rounded-circle"
                    *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                  <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                    class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>
                  <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                    slice:0:30)+'..':(item.name) }}</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values my-16 w-100" *ngIf="filterForm.value.createdby?.length !=0">
              <li class="ng-value" *ngFor="let item of filterForm.value.createdby, let m =index">
                <ng-container *ngFor="let insideitem of createdByData, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    <div class="d-inline-flex">
                      <img class="avatar-sm sq-32 img-fluid rounded-circle"
                        *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                        src="{{insideitem.profile_image}}">
                      <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                        class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                        <span>
                          {{this.appService.getFirstChar(insideitem.first_name+"
                          "+insideitem.last_name,2)}}</span>
                      </span>
                      <span class="mt-2 ms-1">
                        {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                        ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                      </span>
                    </div>
                  </span>
                </ng-container>
                  <span class="ng-value-icon right" (click)="clearcommonForm('createdby',m)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <div class="col-12 form-row">
            <label for="" class="form-label ">Status</label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Status')}}" formControlName="status"
                [items]="statusData" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true"
                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"
                class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ item.name }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.status.length">
                  <span class="ng-value-label">{{filterForm.value.status.length}} Status
                    Selected</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16 w-100" *ngIf="filterForm.value.status?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.status,let ki =index">
                <ng-container *ngFor="let insideitem of statusData, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                  <span class="ng-value-icon right" (click)="clearcommonForm('status',ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetFilter()">
        reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyFilter()">
        Apply
      </button>
    </footer>
  </div>
  </div>
