import { Component, Input, OnInit } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-inbox-title',
  templateUrl: './inbox-title.component.html',
  styleUrls: ['./inbox-title.component.scss']
})
export class InboxTitleComponent implements OnInit {

  title:any =""
  tag: any = ""
  constructor(public appservice: AppService,public inboxServ : InboxService) { }

  ngOnInit(): void {
    this.createTags()
  }
  @Input() inboxitem:any
  createTags(){
    if(this.inboxitem['request_type'] =='Confirmed Investment Declaration' ||this.inboxitem['request_type'] =='Proposed Investment Declaration'){
      this.title = "Investment Declaration Approval"
      this.tag = "Investment Declaration"
    } else if(this.inboxitem['request_type'] == 'Assign Salary Structure') {
      this.title = "Assign Salary Structure"
      this.tag = "Salary Structure"
    } else if(this.inboxitem['request_type'] == 'Holiday' &&  this.inboxitem['request_data']['is_cancel'] == false) {
      this.title = "Floating Holiday Approval"
      this.tag = "Holiday Approval"
    } else if(this.inboxitem['request_type'] == 'Holiday' && this.inboxitem['request_data']['is_cancel'] == true) {
      this.title = "Floating Holiday Request Cancellation Approval"
      this.tag = "Floating Holiday Cancellation request"
    } else if(this.inboxitem['request_type'] == 'Employee Profile') {
      this.title = "Employee Data Approval"
      this.tag = "Employee Data"
    } else if(this.inboxitem['request_type'] == 'Leave' && this.inboxitem['request_data']['is_cancel'] == false) {
      this.title = "Leave Request Approval"
      this.tag = "Leave Request"
    } else if(this.inboxitem['request_type'] == 'Leave' &&  this.inboxitem['request_data']['is_cancel'] == true) {
      this.title = "Leave Cancellation Request Approval"
      this.tag = "Leave Cancellation Request"
    } else if(this.inboxitem['request_type'] == 'Salary Revision') {
      this.title = "Salary Revision Approval"
      this.tag = "Salary Revision"
    } else if(this.inboxitem['request_type'] == 'Reimbursement' && this.inboxitem['request_data']['is_cancel'] == false) {
      this.title = "Reimbursement Request Approval"
      this.tag = "Reimbursement Request"
    } else if(this.inboxitem['request_type'] == 'Reimbursement' && this.inboxitem['request_data']['is_cancel'] == true) {
      this.title = "Reimbursement Cancellation Request Approval"
      this.tag = "Reimbursement Cancellation Request"
    } else if(this.inboxitem['request_type'] == 'FBP Declaration') {
      this.title = "Flexible Benefit Plan Approval"
      this.tag = "FBP Request"
    } else if( this.inboxitem['request_type'] == 'Final settlement Approval' ) {
      this.title = "Final Settlement Approval"
      this.tag = "Final Settlement Approval"
    } else if(this.inboxitem['request_type'] == 'Final settlement Process') {
      this.title = "Final Settlement Process"
      this.tag = "Final Settlement Process"
    } else if(this.inboxitem['request_type'] == 'Attendance Regularization' && this.inboxitem['request_data']['is_cancel'] == false ) {
      this.title = "Regularization Request Approval"
      this.tag = "Regularization Request"
    } else if(this.inboxitem['request_type'] == 'On Duty' && this.inboxitem['request_data']['is_cancel'] == false ) {
      this.title = "On Duty Request"
      this.tag = "On Duty"
    } else if( this.inboxitem['request_type'] == 'Attendance Regularization' &&  this.inboxitem['request_data']['is_cancel'] == true) {
      this.title = "Regularization Cancellation Request Approval"
      this.tag = "Regularization Cancellation Request"
    } else if( this.inboxitem['request_type'] == 'On Duty' &&  this.inboxitem['request_data']['is_cancel'] == true ) {
      this.title = "On Duty Cancellation Request"
      this.tag = "On Duty"
    } else if(this.inboxitem['request_type'] == 'Overtime') {
      this.title = "Overtime Approval"
      this.tag = "Overtime"
    } else if(this.inboxitem['request_type'] == 'Work From Home' &&  this.inboxitem['request_data']['is_cancel'] == false ) {
      this.title = "Work From Home Request Approval"
      this.tag = "WFH Request"
    } else if(this.inboxitem['request_type'] == 'Work From Home' && this.inboxitem['request_data']['is_cancel'] == true) {
      this.title = "Work From Home Cancellation Request Approval"
      this.tag = "WFH Cancellation Request"
    } else if(this.inboxitem['request_type'] == 'Compensatory Off') {
      this.title = "Compensatory Off Approval"
      this.tag = "Comp Off Approval"
    } else if(this.inboxitem['request_type'] == 'Check-in Request') {
      this.title = "Check-in"
      this.tag = "Check-in"
    } else if(this.inboxitem['request_type'] == 'Asset') {
      this.title = "Asset Request Approval"
      this.tag = "Asset Request"
    } else if(this.inboxitem['request_type'] == 'Manage Asset') {
      this.title = "Manage Asset"
      this.tag = "Manage Asset"
    } else if(this.inboxitem['request_type'] == 'Expense Advance') {
      this.title = "Expense Advance"
      this.tag = "Expense Advance"
    } else if(this.inboxitem['request_type'] == 'Advance Dispense') {
      this.title = "Dispense Advance"
      this.tag = "Dispense Advance"
    } else if(this.inboxitem['request_type'] == 'Expense Report') {
      this.title = "Expense Report"
      this.tag = "Expense Report"
    } else if(this.inboxitem['request_type'] == 'Confirmation') {
      this.title = "Confirmation Approval"
      this.tag = "Confirmation"
    } else if(this.inboxitem['request_type'] == 'Confirmation Letter' || this.inboxitem['request_type'] == 'Termination Letter' || this.inboxitem['request_type'] == 'Probation Extension Letter' || this.inboxitem['request_type'] == 'Appointment Letter' || this.inboxitem['request_type'] == 'Relieving Letter' || this.inboxitem['request_type'] == 'Salary Revision Letter'){
      this.title = this.inboxitem['request_type']
      this.tag = this.inboxitem['request_type']
    } else if(this.inboxitem['request_type'] == "Short Leave" &&  this.inboxitem['request_data']['is_cancel'] == false ){
      this.title = "Short Leave (SHL) ("+this.appservice.dateFormatDisplay(this.inboxitem['short_leave_date'])+")"
      this.tag = "Short Leave (SHL)"
    } else if(this.inboxitem['request_type'] == "Short Leave" && this.inboxitem['request_data']['is_cancel'] == true){
      this.title = "Short Leave Cancellation (SHL) ("+this.appservice.dateFormatDisplay(this.inboxitem['short_leave_date'])+")"
      this.tag = "Short Leave Cancellation (SHL)"
    } else if(this.inboxitem['request_type'] == 'Loan') {
      this.title = "Loans and Advances Approval"
      this.tag = "Loans and Advances Approval"
    } else if(this.inboxitem['request_type'] == 'Loan Process') {
      this.title = "Loans and Advances Process"
      this.tag = "Loans and Advances Process"
    } else {
      this.title = this.inboxitem['request_type']
      this.tag = this.inboxitem['request_type']
    }
  }

}
