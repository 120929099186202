import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, HostListener, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';
import { MessageService } from 'src/app/message.global';
import { DatePipe } from '@angular/common';
import { GoogleRoadService } from 'src/app/@core/services/googleRoad.service';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';

@Component({
  selector: 'app-employee-map-view',
  templateUrl: './app-employee-map-view.component.html',
  styleUrls: ['./app-employee-map-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeMapViewComponent implements OnInit,OnChanges {
  activeTab = 1;
  sidebarCollapse=true;
  constructor(
    private cd: ChangeDetectorRef,
    public messageServ : MessageService,
    public appservice: AppService,
    public datePipe:DatePipe,private grs:GoogleRoadService,
    private livelocationService: LivelocationService,) {

  }

  @Input() from: any
  @Input() today: any
  @Input()childloader = false;
  @Input() data: any;
  @Input() empData: any;
  @Output() closepanel = new EventEmitter();
  @Output() emitDateSlider = new EventEmitter();

  zoom: number = 18;
  infoToggle:any
  dirs: Array<any> = []
  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  ]
  ways:any = []
  map: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.sidebarCollapse = true
  }
  ngOnInit(): void {
    // this.prepareMapList()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.dirs = []
    this.clickedMarkerKey = -1
    this.click = -1
    this.firstdirKey = -1
    this.prepareMapList()
  }
  closePanel() {
    this.closepanel.emit();
  }

  click = -1
  showbredcrumb = false
  openedWindow: number = -1; // alternative: array of numbers
  openedMWindow: number = -1; // alternative: array of numbers
  fullbreadcrumb:any = []
  fulldirs:any = []
  lastdirKey = 0
  firstdirKey = -1
  ros = { polylineOptions: { strokeColor: "#111B22",strokeWeight: 5,zIndex:99 },suppressMarkers: true }
  roswob = { polylineOptions: { strokeColor: '#0066DD',strokeWeight: 5,zIndex:99 } ,suppressMarkers: true}

  centre:any
  icentre:any
  centre2:any
  icentre2:any
  clickedMarker:any
  clickedMarkerKey:any =-1

  prepareloader = true
  badges: any = {
    "Stationary": 'badge-warning',
    "Mobile": 'badge-success',
    "Check out": 'badge-danger',
    "Check in": 'badge-primary',
    "End of Tracking": "cbg-7"
  }
  openWindow(id: any) {
    if (this.isInfoWindowOpen(id))
      this.openedWindow = -1;
    else
      this.openedWindow = id; // alternative: push to array of numbers
  }

  isInfoWindowOpen(id: any) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Zoom'));
  }

  async prepareMapList(){
    this.dirs = []
    this.prepareloader = true
    let origin = false
    let originVal:any
    let originKey:any
    let DestVal:any
    let DestKey:any
    let ways:any = []
    let waysVal:any = []
    let polypoints:any = []
    let k=0
    let rs:any,ms:any,fbms:any
    let dirCount = 0
    rs =   { polylineOptions: { strokeColor: '#0066DD',strokeWeight: 5,zIndex:1  } ,suppressMarkers: true},
    ms =  {}
    fbms = {}
    this.fullbreadcrumb = []
   this.data.tracking_datas?.forEach(async (val: any, key: any) => {
    await new Promise((resolve, reject) => {
      setTimeout(async () =>{
        if(this.firstdirKey!=k){
          fbms = {
            origin: {visible:false},
            destination:  {visible:false},
          }
        }
        if(key == 0)
          this.centre =  val

          if(this.dirs.length>=0 || val.realtime_status == 'Check-in'|| origin == true ){
            this.fullbreadcrumb.push({ lat: parseFloat(val.latitude.toFixed(6)), lng: parseFloat(val.longitude.toFixed(6)),val:val })
            // if(origin == false && val.mobility_status == "Mobile" && val?.plugin_data?.location?.activity?.type !="still" && val.realtime_status != "Check-out"){
            if(origin == false && val.mobility_status == "Mobile" && val.realtime_status != "Check-out"){
              if(this.firstdirKey==-1){
                this.centre =   val
                this.icentre =   val
                this.firstdirKey = k
                fbms = {
                  origin: { "icon":"assets/images/helper/map-img/map-pin1-45.png" },
                  destination:  {visible:false},
                }
              }
              originVal = val
              originKey = key
              origin = true
              ways = []
              waysVal = []
              polypoints = []
              if(this.data.tracking_datas[key+1]==undefined){
                if(k>0 && this.dirs[k-1].isDirection == false ){
                  this.dirs[k-1].markerVal.plugin_data.stationary_interval = this.sumTime(this.dirs[k-1].markerVal.plugin_data?.stationary_interval ,val.plugin_data?.stationary_interval )
                } else {
                  this.dirs.push({
                    isDirection : false,
                    key:k,
                    markerVal:val,
                    markerKey:key,
                  })
                }
              }
            } else if(origin == false && (val.mobility_status == "Stationary" || val.realtime_status == "Check-out" )){
              if(k>0 && this.dirs[k-1].isDirection == false && val.realtime_status!= "Check-out"){
                this.dirs[k-1].markerVal.plugin_data.stationary_interval = this.sumTime(this.dirs[k-1].markerVal.plugin_data?.stationary_interval ,val.plugin_data?.stationary_interval )
              } else {
                this.dirs.push({
                  isDirection : false,
                  key:k,
                  markerVal:val,
                  markerKey:key,
                })
                k++
              }
            } else if(origin == true && (val.mobility_status=="Stationary"|| key == this.data.tracking_datas.length-1 || val.realtime_status == "Check-out")){
              // || waysVal.length ==98
              this.lastdirKey = k
              origin = false
              DestVal = val
              DestKey = key

              let dis = this.getTotalDistance(originKey,DestKey)

              if(key==this.data.tracking_datas.length-1) {
                this.centre2 =   val
                this.icentre2 =   val
                fbms =  dirCount == 0?{
                   origin: { "icon":"assets/images/helper/map-img/map-pin1-45.png" ,  },
                  destination:  { "icon":"assets/images/helper/map-img/map-pin1-45.png",  },
                }:{
                  origin: {visible:false},
                  destination:  { "icon":"assets/images/helper/map-img/map-pin1-45.png",  },
                };
              }
              dirCount++

              if(waysVal.length>0  && dis !='0.00 Km'){
                this.polypointsloader =true
                this.processSnapToRoadResponse(waysVal,k);
              } else {
                polypoints = this.polypoints(waysVal);
              }
                this.dirs.push({
                  isDirection : true,
                  key:k,
                  waysVal: waysVal,
                  originVal:originVal,
                  DestVal:DestVal,
                  originKey:originKey,
                  DestKey:DestKey,
                  distance:dis,
                  fbms:fbms,
                  polypoints:polypoints,
                  strokeColor: "#0066DD"
                })
                k++
                // if(key<this.data.tracking_datas.length-1 && val.realtime_status != "Check-out"){
                //   originVal = DestVal
                //   // originVal.timestamp = this.data.tracking_datas[DestKey+1].timestamp
                //   originKey = DestKey
                //   origin = true
                //   ways = []
                //   waysVal = []
                //   polypoints = []
                // }

              if(val.mobility_status=="Stationary" && val.realtime_status != "Check-out"){
                if(k>0 && this.dirs[k-1].isDirection == false ){
                  this.dirs[k-1].markerVal.plugin_data.stationary_interval = this.sumTime(this.dirs[k-1].markerVal.plugin_data?.stationary_interval ,val.plugin_data?.stationary_interval )
                } else {
                  this.dirs.push({
                    isDirection : false,
                    key:k,
                    markerVal:val,
                    markerKey:key,
                  })
                  k++
                }
              }

            } else {
              if(origin == true) 
                waysVal.push({ latitude: parseFloat(val.latitude.toFixed(6)), longitude: parseFloat(val.longitude.toFixed(6)),val:val })
            }
          }
          this.cd.detectChanges()
          // console.log(this.dirs)
          resolve("successful");
        }, 10);
      });

    })

    setTimeout(()=>{
      if(this.dirs[0]?.isDirection == false){
        this.clickMarker(0)
      }
      this.prepareloader = false;
      this.cd.detectChanges()
    },1000);
  }
    sumTime(timex1:any,timex2:any){
      var time1 = timex1 != null?timex1.split(':'):['00','00','00'];
      var time2 = timex2 != null?timex2.split(':'):['00','00','00'];
      let sSum:any = Number(time1[2]) + Number(time2[2]);
      let mSum:any = Number(time1[1]) + Number(time2[1]);
      let hSum:any = Number(time1[0]) + Number(time2[0]);

      if(sSum > 59){
        sSum = Math.abs(sSum -60);
        mSum += 1;
      }
      
      if(mSum > 59){
        mSum = Math.abs(mSum -60);
        hSum += 1;
      }
      
      if(mSum < 10){
        mSum = `0${mSum}`;
      }
      
      if(hSum < 10){
        hSum = `0${hSum}`;
      }
      return `${hSum}:${mSum}:${sSum}`;   
  }

  // timeConvert(stationaryInterval: any){
  //   if(stationaryInterval!=null)
  //     return " ("+this.timeFormatConvert(stationaryInterval)+")"
  //   else return ""
  // }
  timeFormatConvert(stationaryInterval: any){
    let time = this.today+" "+stationaryInterval
    if(time != null&& stationaryInterval!=null){
      const [hourString, minute] = stationaryInterval.split(":");
      return (hourString.replace(/^0/, '')) + "h " + minute.replace(/^0/, '') + "m";
    }else return "";
  }

  centreMap() {
    const bonds: any = new google.maps.LatLngBounds();
    if(this.centre?.latitude && this.centre?.longitude)
    bonds.extend(new google.maps.LatLng(this.centre?.latitude, this.centre?.longitude));
    if(this.centre2?.latitude && this.centre2?.longitude)
      bonds.extend(new google.maps.LatLng(this.centre2?.latitude, this.centre2?.longitude));
    this.map?.fitBounds(bonds);
  }

  clicklist(click:any,showBreadcrumb:boolean=false){
    this.openedMWindow = -1;
    this.openedPWindow = -1
    if(click == this.click && showBreadcrumb ==this.showbredcrumb)
      this.click = click = -1;this.centre = this.icentre;this.centre2 = this.icentre2
    this.showbredcrumb = showBreadcrumb
    this.clickedMarkerKey = -1
    if(click != -1){
    this.click = click
    let newdirs:any = []
      this.dirs?.forEach((val: any, key: any) => {
        if(key==click && val?.isDirection){
          this.centre =   val.originVal
          this.centre2 =   val.DestVal
          newdirs.push({
            isDirection : true,
            key:val.key,
            waysVal :val.waysVal,
            originVal:val.originVal,
            DestVal:val.DestVal,
            originKey:val.originKey,
            DestKey:val.DestKey,
            distance:val.distance,
            fbms:val.fbms,
            polypoints:val.polypoints,
            strokeColor: "#111B22",zIndex:99 
          })
          if(showBreadcrumb)
            this.ways = val.waysVal
          else
            this.ways = []
        } else if(val?.isDirection){
          newdirs.push({
            isDirection : true,
            key:val.key,      
            waysVal :val.waysVal,
            originVal:val.originVal,
            DestVal:val.DestVal,
            originKey:val.originKey,
            DestKey:val.DestKey,
            distance:val.distance,
            fbms:val.fbms,
            polypoints:val.polypoints,strokeColor: "#0066DD",zIndex:1 
          })
        } else {
          newdirs.push({
            isDirection : false,
            key:val.key,
            markerVal:val.markerVal,
            markerKey:val.markerKey,
        })
      }
      })

      this.dirs = newdirs
      setTimeout(() => {
      }, 100);
    }
  }
  timeCompare(datetimeStart:any,datetimeEnd:any){
    if(Date.parse(datetimeStart) < Date.parse(datetimeEnd))
      return true
    else
      return false
  }
  clickMarker(key:any){
    this.centre =  this.dirs[key].markerVal
    this.centre2 =  this.dirs[key].markerVal
    this.openedMWindow = -1;
    if(this.clickedMarkerKey != key){
      this.click= -1
      this.clickedMarker = this.dirs[key].markerVal
      this.showbredcrumb = false
      this.clickedMarkerKey = key
    } else
      this.clickedMarkerKey = -1
    setTimeout(() => {
      this.centreMap()
    }, 100);
  }

  openMarkerWindow(id: any) {
    if (this.isInfoMWindowOpen(id))
      this.openedMWindow = -1;
    else
      this.openedMWindow = id; // alternative: push to array of numbers
  }

  isInfoMWindowOpen(id: any) {
    return this.openedMWindow == id; // alternative: check if id is in array
  }

  changeWeek(val:any){
    let pDay
    if(val=='left'){
      pDay = new Date(moment(this.today).subtract(1, 'days').format('YYYY-MM-DD'))
    }else{
      pDay = new Date(moment(this.today).add(1, 'days').format('YYYY-MM-DD'))
    }
    this.today = moment(pDay).format('YYYY-MM-DD')
    this.emitDateSlider.emit({locData:this.empData,time:this.today})
  }

  fromDates(val:any){
    this.today = moment(val.value).format('YYYY-MM-DD')
    this.emitDateSlider.emit({locData:this.empData,time:this.today})
  }

  getDistanceBetweenTwoPoints(cord1: any, cord2: any) {
    if (cord1.lat == cord2.lat && cord1.lng == cord2.lng) {
      return 0;
    }

    const radlat1 = (Math.PI * cord1.lat) / 180;
    const radlat2 = (Math.PI * cord2.lat) / 180;

    const theta = cord1.lng - cord2.lng;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; //convert miles to km

    return dist;
  }
  getTotalDistance(originKey:any,DestKey:any) {
    var coordinates:any = []
    for(let i=originKey;i<=DestKey;i++){
      let val = this.data.tracking_datas[i]
      coordinates.push({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6))})
    }

    let totalDistance = 0;

    if (!coordinates) {
      return 0;
    }

    if (coordinates.length < 2) {
      return 0;
    }

    for (let i = 0; i < coordinates.length - 2; i++) {
      if (
        !coordinates[i].lng ||
        !coordinates[i].lat ||
        !coordinates[i + 1].lng ||
        !coordinates[i + 1].lat
      ) {
        totalDistance = totalDistance;
      }
      totalDistance =
        totalDistance +
        this.getDistanceBetweenTwoPoints(coordinates[i], coordinates[i + 1]);
    }
    return totalDistance<0.1?"0.00 Km":totalDistance.toFixed(2) + " Km";
  }
 polypointsloader =false
  async processSnapToRoadResponse(waysVal: any,k:any) {
    
    let  polypoints:any = []

    const chunkSize = 100;
    for (let x = 0; x < waysVal.length; x += chunkSize) {
      this.polypointsloader = true
      await new Promise((resolve, reject) => {
        setTimeout(async () =>{
          const chunk = waysVal.slice(x, x + chunkSize);

          this.grs.snapToRoads(chunk,{},{}).subscribe((data: any) => {
            for (let i = 0; i < data.snappedPoints.length; i++) {
              polypoints.push({lat:data.snappedPoints[i].location.latitude,lng:data.snappedPoints[i].location.longitude})
            } 
          })
          resolve("successful");
        }, 500)
      })
    }
    this.dirs[k].polypoints =polypoints;
    setTimeout(()=>{ this.polypointsloader = false;this.cd.detectChanges() }, 1000)
    // return polypoints
  }
  openedPWindow =-1
  openPWindow(id: any) {
    if (this.isInfoPWindowOpen(id))
      this.openedPWindow = -1;
    else
      this.openedPWindow = id; // alternative: push to array of numbers
  }

  isInfoPWindowOpen(id: any) {
    return this.openedPWindow == id; // alternative: check if id is in array
  }

  polypoints(waysVal:any){
    let arrpolypoint:any = []
    for (let i = 0; i < waysVal.length; i++) {
      arrpolypoint.push({lat:waysVal[i].latitude,lng:waysVal[i].longitude})
    }
    return arrpolypoint
  }
  saveLocation(data:any,key:any,type:any,employee_id:any){
    if(type == 'originVal')
      this.dirs[key].originVal.location = data.location
    else if(type == 'DestVal')
      this.dirs[key].DestVal.location = data.location
    else if(type == 'markerVal')
      this.dirs[key].markerVal.location = data.location
    this.livelocationService.saveLocation({"realtime_id": data.id,"employee": employee_id,"location": data.location},this.from).subscribe()
  }
  clickLine = -1
  clickedLine:any 
  clickedLinePoints:any 
  lineClick(event:any,dir:any){
    this.clickLine = 1
    this.clickedLine = dir
    this.clickedLinePoints = {lat:event.latLng.lat(),lng:event.latLng.lng()}
    // console.log(dir)
    // console.log(event.latLng.lat())
    // console.log(event.latLng.lng())
  }
  closeLineClick(){
    this.clickLine = -1
  }

}
