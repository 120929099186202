<form class="" [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row row-16">
      <div class="col-12">
        <p class="text-uppercase fw-500 mb-0">Loans and advances request </p>
      </div>
      <div class="col-12">
        <label for="loan" class="form-label required">Loan type </label>
        <ng-select class="form-ngselect" (change)="typeChanged()"
        [ngClass]="{ 'is-invalid': (submitted || f.loan_type.touched) && f.loan_type.errors }"
        formControlName="loan_type" name="loan_type" id="loan_type" placeholder="{{this.msgService.selectplaceholddisplay('loan type')}}">
        <ng-option *ngFor="let li of loanTypesDropdown" [value]="li.loan_type">{{li.loan_type}}</ng-option>
        </ng-select>
        <div *ngIf="(submitted || f.loan_type.touched) && f.loan_type.errors" class="invalid-feedback">
          <div *ngIf="f.loan_type.errors.required">{{this.msgService.validationDisplay('required')}}
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="loanTypeDetails">
        <div class="p-24 bg45 card details-card border-0">
          <div class="row row-16">
            <div class="col-md-3  col-12">
              <div class="detail-title">Interest type</div>
              <div class="detail-desc">{{loanTypeDetails?.interest_type}}</div>
            </div>
            <div class="col-md-3  col-12">
              <div class="detail-title">Maximum amount</div>
              <div class="detail-desc">{{loanTypeDetails?.amount_limit | currency : currency}}</div>
            </div>
            <div class="col-md-3  col-12">
              <div class="detail-title">Maximum EMI</div>
              <div class="detail-desc">{{loanTypeDetails?.max_number_emis}}</div>
            </div>
            <div class="col-md-3  ">
              <div class="detail-title">Eligibility</div>
              <div class="detail-desc">{{loanTypeDetails?.loan_eligibility_days}} days from date of joining</div>
            </div>
            <div class=" col-12">
              <div class="detail-title">Interest penalty on payment shortfall rate%</div>
              <div class="detail-desc">{{loanTypeDetails?.interest_penality}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label for="loan_am" class="form-label required">Loan amount </label>
        <input type="text" name="loan_amount" formControlName="loan_amount" class="form-control" (keyup)="calculateEMIs()" placeholder="{{this.msgService.placeholderdisp('loan amount')}}" id="loan_am" [ngClass]="{ 'is-invalid': (f.loan_amount.touched|| f.loan_amount.dirty) &&  f.loan_amount.errors}">
        <div *ngIf="(f.loan_amount.touched || f.loan_amount.dirty) && f.loan_amount.errors" class="invalid-feedback">
          <div *ngIf="f.loan_amount.errors.required">{{this.msgService.validationDisplay('required')}}</div>
          <div *ngIf="f.loan_amount.hasError('min') && !(f.loan_amount.errors?.pattern)">
            Loan amount cannot be zero.
          </div>
          <div *ngIf="f.loan_amount.hasError('max') && !(f.loan_amount.errors?.pattern)">
            The loan amount can't be more than the defined maximum loan amount for this loan type.
          </div>
          <div *ngIf=" f.loan_amount.errors?.pattern || (notanumber(f.loan_amount.errors?.pattern?.actualValue))">
            {{this.msgService.validationDisplay('pattern')}}</div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label for="loan_em" class="form-label required">Number of EMI </label>
        <input type="text" name="number_of_emi" formControlName="number_of_emi" class="form-control" (keyup)="calculateEMIs()" placeholder="{{this.msgService.placeholderdisp('number of emi')}}" id="loan_em" [ngClass]="{ 'is-invalid': (f.number_of_emi.touched|| f.number_of_emi.dirty) &&  f.number_of_emi.errors}">
        <div *ngIf="(f.number_of_emi.touched || f.number_of_emi.dirty) && f.number_of_emi.errors" class="invalid-feedback">
          <div *ngIf="f.number_of_emi.errors.required">{{this.msgService.validationDisplay('required')}}</div>
          <div *ngIf="f.number_of_emi.hasError('min') && !(f.number_of_emi.errors?.pattern)">
            Number of EMI cannot be zero.
          </div>
          <div *ngIf="f.number_of_emi.hasError('max') && !(f.number_of_emi.errors?.pattern)">
            You cannot exceed the limit set for maximum number of EMIs for this loan type.
          </div>
          <div *ngIf=" f.number_of_emi.errors?.pattern || (notanumber(f.number_of_emi.errors?.pattern?.actualValue))">
            {{this.msgService.validationDisplay('pattern')}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="hstack p-16 bg8 fw-500 radius-6">
          <span>Monthly EMI</span>
          <span class="ms-auto"> {{monthlyEMI}} {{currency}}</span>
        </div>
      </div>
      <div class="col-12">
        <label for="loan_date" class="form-label required">Loan required date </label>
        <div class="form-icon icon-right"
          [ngClass]="{ 'is-invalid': (submitted ||f.required_date.touched || f.required_date.dirty) && f.required_date.errors}">
          <input (click)="datePicker1.open()" id="loan_date" autocomplete="off" [min]="minDate" [max]="maxDate"
              placeholder="{{this.msgService.selectplaceholddisplay('loan required date')}}" class="form-control"
              [ngClass]="{ 'is-invalid': (submitted || f.required_date.touched || f.required_date.dirty) && f.required_date.errors}" matInput
              formControlName="required_date" [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
              class="icon-calendar pointer" (click)="datePicker1.open()"></i>
          <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
        </div>
        <div *ngIf="(submitted || f.required_date.touched || f.required_date.dirty) && f.required_date.errors" class="invalid-feedback">
            <div *ngIf="f.required_date.errors.required">{{this.msgService.validationDisplay('required')}}</div>
        </div>
      </div>
      <div class=" col-12 form-row">
        <label for="area" class="form-label">Remarks </label>
        <textarea type="text" class="form-control" formControlName="reason" rows="4" placeholder="{{this.msgService.placeholderdisp('remarks')}}" id="area"  [ngClass]="{ 'is-invalid': (submitted || f.reason.dirty || f.reason.touched) && (f.reason.errors) }"></textarea>
        <div *ngIf="(submitted || f.reason.dirty || f.reason.touched) && f.reason.errors" class="invalid-feedback">
          <!-- <div *ngIf="f.reason.errors.required">{{this.msgService.validationDisplay('required')}}</div> -->
          <div *ngIf="f.reason.errors.maxlength">{{this.msgService.fieldlengthvalidation('word','100')}}</div>
        </div>
      </div>
      <div class="col-12">
        <label class="form-label">Upload documents</label>
        <!-- <div class="card card-c2 px-16"> -->
          <label class="upload-btn " *ngIf="formGp.value.document == ''"
          [ngClass]="{ 'is-invalid': (fileError == true)  }">
              <div class="upload-text">Upload documents <span class="text-primary">here
                <app-file-upload [crop]="false" [round]="false" [id]="'document'" [width]="504" (dataURL)="getURL($event)"
                (fullfile)="getFilename($event)" (loader)="getloader($event)" [FileType]="'.pdf,.doc'"
                (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)"></app-file-upload></span>
                <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC</div>
              </div>
          </label>
          <div *ngIf="(fileError == true)" class="invalid-feedback">
            <div>{{fileErrorMsg}}</div>
          </div>
          <div *ngIf="formGp.value.document !='' && formGp.value.document != null && !docLoader">
            <div class="card card-c2  bg-secondary" style="width:16.375rem;">
              <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                <ng-container *ngIf="!docLoader">
                  <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                    <span class="tips text-uppercase  fw-700 text-trim">{{this.appService.getDocName(formGp.value.document)}}</span>
                    <span class="tips-helper ">{{this.appService.getDocName(formGp.value.document)}}</span>
                    <div class="d-flex gap-8">
                      <div class="link-primary" (click)="viewDocument(formGp.value.document)">
                        <i class="bi bi-eye me-1"></i>View
                      </div>
                      <div class="link-primary" (click)="downLoad(formGp.value.document)">
                        <i class="bi bi-download me-1"></i>Download
                      </div>
                    </div>
                  </div>
                  <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteFile($event)"></i>
                </ng-container>
              </div>
            </div>
          </div>
        <!-- </div> -->
        <div *ngIf="docLoader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
          <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
            <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
            <div class="  flex-center text-center"  *ngIf="docLoader">
              <div class="fs-12 text-muted">Loading </div>
              <div class="ms-4 dot-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateLoanDetails()">NEXT</button>
  </footer>
</form>

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<app-info-popup *ngIf="alertToggle1" (modal)="closed($event)" [infoToggle]="alertToggle1" [infoMsg]="alertMsg"></app-info-popup>
