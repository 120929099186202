import { Component, OnInit,Input,Output,EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-manage-documents-applicability',
  templateUrl: './manage-documents-applicability.component.html',
  styleUrls: ['./manage-documents-applicability.component.scss']
})
export class ManageDocumentsApplicabilityComponent implements OnInit {
  @Input() formGp   : any
  @Input()companyDD :any =  [{
    id: 0,
    company_name: ''}];

  @Input()buDD = [{
    id: 0,
    name: ''}];

  @Input()DepDD = [{
    id: 0,
    name: ''}];

  @Input()DesignationDD = [{
    id: 0,
    name: ''}];

  @Input()BranchDD = [{
    id: 0,
    name: ''}];

  @Input()gradeDD = [{
    id: 0,
    name: ''}];
  @Input() employeeDropdown  :any

  @Output() submitmethod = new EventEmitter();
  disabled       :boolean     = false
  submitted = false;


  constructor(

    private rs            : RoleService,
    public messageService : MessageService,
    public appService     : AppService,
    private cd            : ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    if (this.companyDD.length != 0) {
      this.selectAllForDropdownItems(this.companyDD);
    }
    if (this.DepDD.length != 0) {
      this.selectAllForDropdownItems(this.DepDD);
    }
    if (this.DesignationDD.length != 0) {
      this.selectAllForDropdownItems(this.DesignationDD);
    }
    if (this.BranchDD.length != 0) {
      this.selectAllForDropdownItems(this.BranchDD);
    }
    if (this.gradeDD.length != 0) {
      this.selectAllForDropdownItems(this.gradeDD);
    }
    // if (this.employeeDropdown.length != 0) {
    //   this.selectAllForDropdownItems(this.employeeDropdown);
    // }
    if (this.buDD.length != 0) {
      this.selectAllForDropdownItems(this.buDD);
    }
    this.initiateErrorStatus()
  }
  clear(data:any,index:any){
    if(data == "company"){
      let daata = this.formGp.value.doc_policy_assignright.company;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        company: daata,
        businessunit: []
      });
      this.companyChangeFunction(this.formGp.value.doc_policy_assignright.company);
    } else if(data == "businessunit"){
      let daata = this.formGp.value.doc_policy_assignright.businessunit;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        businessunit: daata
      });
    } else if(data == "department"){
      let daata = this.formGp.value.doc_policy_assignright.department;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        department: daata
      });
    } else if(data == "city"){
      let daata = this.formGp.value.doc_policy_assignright.city;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        city: daata
      });
    } else if(data == "designation"){
      let daata = this.formGp.value.doc_policy_assignright.designation;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        designation: daata
      });
    } else if(data == "grade"){
      let daata = this.formGp.value.doc_policy_assignright.grade;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        grade: daata
      });
    }  else if(data == "branch"){
      let daata = this.formGp.value.doc_policy_assignright.branch;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        branch: daata
      });
    }  else if(data == "employee"){
      let daata = this.formGp.value.doc_policy_assignright.employee;
      daata.splice(index, 1);
      const myForm = this.formGp.get("doc_policy_assignright")
      myForm?.patchValue({
        employee: daata
      });
    }
    this.initiateErrorStatus()
  }
  companyChangeFunction(data:any){
    this.buDD = [];

    this.rs.getBuList(data).subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
        this.buDD = res.body
        this.selectAllForDropdownItems(this.buDD)
      }
    });
    this.formGp.controls.doc_policy_assignright.patchValue({
      businessunit : this.buDD
    })
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  get f(){
    return this.formGp.controls
  }
  get doc_policy_assignright(){
    return this.formGp.controls.doc_policy_assignright
  }
  changeApplicable(){
    this.doc_policy_assignright.get('company')?.reset([])
    this.doc_policy_assignright.get('businessunit')?.reset([])
    this.doc_policy_assignright.get('department')?.reset([])
    this.doc_policy_assignright.get('designation')?.reset([])
    this.doc_policy_assignright.get('branch')?.reset([])
    this.doc_policy_assignright.get('grade')?.reset([])
    this.doc_policy_assignright.get('employee')?.reset([])
  }
  submitForm(){
    this.submitted  = true;
    this.submitmethod.emit()


  }
  checkcommonFormValid(){
    if(this.f.doc_policy_assignright.get('is_organisation')?.value == true){
      if(this.f.doc_policy_assignright.get('company')?.valid || this.f.doc_policy_assignright.get('businessunit')?.valid || this.f.doc_policy_assignright.get('department')?.valid || this.f.doc_policy_assignright.get('designation')?.valid || this.f.doc_policy_assignright.get('grade')?.valid || this.f.doc_policy_assignright.get('branch')?.valid ){
        return false;
      } else{
        return true;
      }
    }else{
      if(this.f.doc_policy_assignright.get('employee')?.valid){
        return false
      }else{
        return true
      }
    }
  }

  ngChange(e:any){
    if(this.f.doc_policy_assignright.get('is_organisation').value == false && this.hasSpecificStatus(e)){
      this.formGp?.get('doc_policy_assignright')?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    return employees.some((employee:any)=> !employee?.active_state_employee) && employees?.length != 0;
  }

  initiateErrorStatus(){
    if(this.formGp.get('doc_policy_assignright')?.get('employee')?.value != null && this.formGp.get('doc_policy_assignright')?.get('employee')?.value != ''){
      const filteredData = this.employeeDropdown.filter((item:any) => this.formGp.get('doc_policy_assignright')?.get('employee')?.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData)){
          this.formGp.get('doc_policy_assignright')?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('doc_policy_assignright')?.get('employee')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeDropdown.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
