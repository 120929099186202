<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <app-c1-loader *ngIf="loader"></app-c1-loader>
        <div class="row row-16 " *ngIf="!loader">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">{{attendance_lop ? 'GRATUITY' : 'GRATUITY & OTHER PAYMENT'}}</p>
            </div>
            <ng-container *ngIf="!attendance_lop">
              <div class="col-6">
                  <label class="radio-group" for="btnradio1" (click)="paymentName='GRATUITY'">
                      <input type="radio" class="radio-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                      <div class="radio-content details-card card p-16 indicater1 ">
                          <div class="row row-4 ">
                              <div class="col-12 d-flex">
                                  <span class="fs-14 fw-600 text-uppercase">GRATUITY</span>
                              </div>
                              <div class="col-12" *ngIf="!errCompmsg && !bothCompMsg">
                                  <div class="fw-500 fs-10 text-uppercase {{gratuityData?.gratuity_status == false ? 'text-warning' : 'text-success'}}">
                                      {{gratuityData?.gratuity_status == false ? 'PENDING' : 'Reviewed'}}
                                    </div>
                              </div>
                              <div class="col-12 " *ngIf="errCompmsg || bothCompMsg">
                                  <div  class="text-danger fs-10">THIS COMPONENT IS NOT APPLICABLE</div>
                              </div>
                          </div>
                      </div>
                  </label>
              </div>
              <div class="col-6">
                  <label class="radio-group" for="btnradio2"  (click)="paymentName ='LEAVE'">
                      <input type="radio" class="radio-check" name="btnradio" id="btnradio2" autocomplete="off">
                      <div class="radio-content details-card card p-16 indicater1 indicater-bg2">
                          <div class="row row-4 ">
                              <div class="col-12 d-flex ">
                                  <span class="fs-14 fw-600 text-uppercase">LEAVE ENCASHMENT</span>
                              </div>
                              <div *ngIf="!errleaveCompmsg && !bothCompMsg" class="col-12">
                                  <div class="fw-500 fs-10 text-uppercase {{gratuityData?.encashment_status == false ? 'text-warning' : 'text-success'}}">
                                      {{gratuityData?.encashment_status == false ? 'PENDING' : 'Reviewed'}}
                                    </div>
                              </div>
                              <div class="col-12 " *ngIf="errleaveCompmsg || bothCompMsg">
                                <div class="text-danger fs-10">THIS COMPONENT IS NOT APPLICABLE</div>
                            </div>
                          </div>
                      </div>
                  </label>
              </div>
            </ng-container>
            <ng-container *ngIf="!errCompmsg && !bothCompMsg">
                <ng-container *ngIf="paymentName == 'GRATUITY'">
                  <div class="col-12">
                      <div class="card card-c2 details-card  px-24 py-16 ">
                          <div class="row row-24">
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Gratuity limit</div>
                                  <div class="detail-desc">{{gratuityData?.gratuity_limit | currency:
                                      gratuityData?.currency}}
                                  </div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Gratuity eligibility criteria</div>
                                  <div class="detail-desc">{{gratuityData?.gratuity_eligible_criteria}}</div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Gratuity duration round off</div>
                                  <div class="detail-desc">{{gratuityData?.gratuity_duration_round_off}}</div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Current basic</div>
                                  <div class="detail-desc">{{gratuityData?.current_basic | currency:
                                      gratuityData?.currency}}
                                  </div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Current DA</div>
                                  <div class="detail-desc">{{gratuityData?.current_da | currency: gratuityData?.currency}}
                                  </div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Joining date</div>
                                  <div class="detail-desc">
                                      {{this.appService.dateFormatDisplay(gratuityData?.date_of_joining)}}</div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Employee work duration</div>
                                  <div class="detail-desc">{{gratuityData?.employee_work_duration}}</div>
                              </div>
                              <div class="col-md-3 col-sm-4 col-6">
                                  <div class="detail-title">Gratuity amount</div>
                                  <div class="detail-desc">{{gratuityData?.gratuity_amount | currency:
                                      gratuityData?.currency}}</div>
                              </div>
                              <div class="col-12">
                                  <p class="fs-14 mb-0">
                                      <span class="fw-500">Note : </span>Amount under the field - “Gratuity Payable
                                      Amount”
                                      will be paid to the employee
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12">
                      <div class="card card-c2 details-card  px-24 py-16 ">
                          <div class="row row-24">
                              <div class="col-12">
                                  <div class="title">GRATUITY AMOUNT</div>
                              </div>
                              <div class=" col-12 form-row">
                                  <label for="" class="form-label required">Gratuity payable amount</label>
                                  <div class="form-icon icon-right"
                                      [ngClass]="{ 'is-invalid': (submitted || f.payable_amount.dirty || f.payable_amount.touched) && f.payable_amount.errors}">
                                      <input type="text" class="form-control "
                                          placeholder="{{this.messageService.placeholderdisp('gratuity payable amount')}}"
                                          formControlName="payable_amount"
                                          [ngClass]="{ 'is-invalid': (submitted || f.payable_amount.dirty || f.payable_amount.touched) && f.payable_amount.errors}">
                                      <span>{{gratuityData?.currency}}</span>
                                  </div>
                                  <div *ngIf="(submitted || f.payable_amount.dirty || f.payable_amount.touched) && f.payable_amount.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.payable_amount.errors?.required">
                                          {{this.messageService.validationDisplay('required')}}</div>
                                      <div *ngIf="f.payable_amount.errors?.pattern">
                                          {{this.messageService.validationDisplay('pattern')}}</div>
                                      <!-- <div *ngIf="!(f.payable_amount.errors?.pattern) && f.payable_amount.errors?.max">
                                          {{this.messageService.balanceAmountValidation()}}
                                      </div> -->
                                      <div *ngIf="!(f.payable_amount.errors?.pattern) && f.payable_amount.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','10')}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-12 d-flex mt-4">
                          <button class="btn btn-primary btn-sm ms-auto text-uppercase"
                            (click)="gratuityAmountSave()" [disabled]="!formGp.valid ">SAVE</button>
                      </div>
                  </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="paymentName == 'GRATUITY' && (errCompmsg || bothCompMsg)">
              <div class="col-12">
                <div class="row"  style="height: calc(100vh - 21rem);">
                  <app-nodata-view class="vstack" [imgClass]="'img-sm'" [noDataImg]="'bg15.png'" [noDataText]="'This component is not applicable'"></app-nodata-view>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="!errleaveCompmsg && !bothCompMsg">
                <ng-container *ngIf="paymentName == 'LEAVE'">
                  <div class="col-sm-12 form-row">
                      <div class="card card-c2 details-card p-24">
                          <div class="row row-16">
                              <div class="col-sm-3">
                                  <div class="detail-title ">Leave type</div>
                                  <div class="detail-desc ">{{gratuityData?.leave_type ?gratuityData?.leave_type:'-' }}</div>
                              </div>
                              <div class="col-sm-3">
                                  <div class="detail-title ">Encashable leave</div>
                                  <div class="detail-desc ">{{gratuityData?.encashable_leave ? gratuityData?.encashable_leave : '-' }}</div>
                              </div>
                              <div class="col-sm-3">
                                  <div class="detail-title ">Encashed amount</div>
                                  <div class="detail-desc ">{{gratuityData?.encashed_amount ? (gratuityData?.encashed_amount | currency: gratuityData?.currency) : '-'}}</div>
                              </div>
                              <div class="col-sm-3">
                                  <div class="detail-title ">Amount notified by government</div>
                                  <div class="detail-desc ">{{gratuityData?.govt_notified_amount ? (gratuityData?.govt_notified_amount | currency: gratuityData?.currency): '-'}}</div>
                              </div>
                              <div class="col-sm-3">
                                  <div class="detail-title ">Total encashed amount</div>
                                  <div class="detail-desc ">{{gratuityData?.total_encashed_amount ? (gratuityData?.total_encashed_amount | currency: gratuityData?.currency): '-'}}</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12 form-row">
                      <label for="" class="form-label ">Payable amount</label>
                      <div class="form-icon icon-right">

                      <input type="text" class="form-control " placeholder=" " formControlName="leave_payable_amount">
                      <span>{{gratuityData?.currency}}</span></div>
                  </div>
                  <div class="col-12 d-flex">
                      <button class="btn btn-primary btn-sm ms-auto text-uppercase"
                      (click)="leaveAmountSave()">SAVE</button>
                  </div>
                </ng-container>
            </ng-container>
            <div class="col-12"  *ngIf="paymentName == 'LEAVE' && (errleaveCompmsg || bothCompMsg)">
                <div class="row" style="height: calc(100vh - 21rem);">
                    <app-nodata-view class="vstack" [imgClass]="'img-sm'" [noDataImg]="'bg15.png'"
                        [noDataText]="'This component is not applicable'"></app-nodata-view>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase"
          (click)="submitGratuity()">NEXT</button>
  </footer>
</form>
