import { Component,OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { CropperDialog } from './cropper.dialog';
import { BankService } from 'src/app/@core/services/bank.service';
import { PreviousEmpService } from 'src/app/@core/services/previous-emp.service';
import { HealthInsuranceService } from 'src/app/@core/services/health-insurance.service';
import { ProfessionalsService } from 'src/app/@core/services/professionals.service';
import { EducationService } from 'src/app/@core/services/education.service';
import { PersonalService } from 'src/app/@core/services/personal.service';
import { ProfileDocumentService } from 'src/app/@core/services/profile-document.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ProfileDataService } from 'src/app/@core/services/profile-data.service';
import { FamilyService } from 'src/app/@core/services/family.service';
import { GeolocationService } from 'src/app/@core/services/geo-location.service';
import * as moment from 'moment';


// import { Validators } from 'ngx-editor';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProfileComponent implements OnInit {
  @Input() from:any;

  url                         = '';
  loader                      = true;
  id                          = 0;
  personalData:any            = [];
  professionalData:any        = [];
  educationData:any           = [];
  personalDataArr:any         = [];
  familyData:any              = [];
  insuranceData:any           = [];
  employerData:any            = [];
  bankData:any                = {id: 0, employee: 0, salary_pay_mode: '', created_by: 0, modified_by: 0};
  docData:any                 = [];
  city_list:any               = [];
  permanent_city:any          = [];
  country_list:any            = [];
  current_state_list:any      = [];
  permenent_state_list:any    = [];
  inputId:string              = "fileInput";
  public imageUrl: any        = "";
  activeTab                   = 1;
  Savecontent:any[]           = [];
  permittedDoc:any            = [];
  emptyDoc:any                = [];
  gradeDD :any[]              = [];
  BranchDD :any[]             = [];
  DesignationDD :any[]        = [];
  DepDD  :any[]               = [];
  buDD  :any[]                = [];
  companyDD :any[]            = [];
  empDD:any[]                 = [];
  loginAs:any ;
  createdBy:any ;
  codeError:any = "";
  phnError:any  = "";
  mailError:any = "";
  isSuccess:any = "";
  showSidePanel = true;
  routingUrl:any;
  errormsg:any          = "";
  invalidFile           = false;
  currency:any          = "";
  qrModal  : boolean    = false
  public qrdata: string = '';
  is_employeeRequest:any;
  tabPermissionData:any = [];
  addEditPermission     = false;
  empLocationData:any   = []
  addProfileModal = false

  onSelectFile(event:any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        // this.url = event.target.result;
      }
    }
  }
  cropped?: string;
  constructor(
    private datepipe:DatePipe,
    public appservice:AppService,
    private _dialog: LyDialog,
    private profileService:SnapshotService,
    private bankService:BankService,
    private changeDetectorRef: ChangeDetectorRef,
    private employerService:PreviousEmpService,
    private insuranceService:HealthInsuranceService,
    private professionalService:ProfessionalsService,
    private educationService:EducationService,
    private personalService:PersonalService,
    private docService:ProfileDocumentService,
    public router: Router,
    public route:ActivatedRoute,
    private employeeService : EmployeeService,
    private authService:AuthService,
    private formBuilder: FormBuilder,
    public messageService : MessageService,
    private notificationService:NotificationService,
    private profileDataService:ProfileDataService,
    private familyService:FamilyService,
    public locationService:GeolocationService,

    ) {


        this.profileDataService.profile_data.subscribe(result =>{
          let res = result.event

          this.personalData       = res;
          this.is_employeeRequest = res.requested_flags;
          this.professionalData   = Object.values(res.professional);
          this.educationData      = Object.values(res.education);
          this.personalDataArr    = res.personal?.length>0?res.personal[0]:res.personal;
          this.familyData         = res.family?.length>0?res.family[0]:res.family;
          this.insuranceData      = res.health_insurance?.length>0?Object.values(res.health_insurance):res.health_insurance;
          this.employerData       = res.previous_employer_detail?.length>0?res.previous_employer_detail[0]:res.previous_employer_detail;
          this.bankData           = res.bank_detail?.length>0?res.bank_detail[0]:res.bank_detail;
          this.docData            = res.employee_document?.length>0?res.employee_document[0]:res.employee_document

          // for(let i=0;i<this.permittedDoc?.length;i++){
          //   if(this.permittedDoc[i]['id']==this.docData['id'])
          //   this.permittedDoc[i]['document_file'] = this.docData['document_file']

          // }

          this.changeDetectorRef.detectChanges();
        })


      // Web Socket for Document
    //  this.docService.profile_doc_data.subscribe(result =>{

    //       this.permittedDoc = [];
    //       this.emptyDoc     = [];
    //       let res           = result.event

    //       this.docData      = res?.length>0?res[0]:res;
    //       // this.permittedDoc = res;
    //       for(let j=0;j<res.length;j++){

    //         if(res[j].document_file!==null && res[j].document_file!==''){
    //           this.permittedDoc.push(res[j]);
    //         }
    //         else{
    //           this.emptyDoc.push(res[j])
    //         }
    //       }

    //       this.changeDetectorRef.detectChanges();
    //     })
     }

  ngOnInit(): void {
    this.getDateFormat();
    this.loader = true;
    this.countryList();
    this.getCompanyList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    // this.getManagerList();

    this.route.params.subscribe((params: Params) => {

      if( !isNaN(params['id'])){
        this.id         = +params['id'];
        this.loginAs    = "HR";
        this.routingUrl = '/employee';
        this.getDateFormat().then(() => {
          this.getPersonalData();
        });
        this.getEmployeeLocationData();
      }
      else if(!isNaN(params['myTeamEmpId'])){
        this.id         = +params['myTeamEmpId'];
        this.loginAs    = "Reporting Manager";
        this.routingUrl = '/my-team-dashboard';
        this.getTeamEmpProfileViewPermissions();
        this.getAddEditPermission();
      }
      else{

        this.id         = this.authService.isEmployeeId;
        this.loginAs    = "Employee";
        this.routingUrl = '/profile/snapshot';
        this.getDateFormat().then(() => {
          this.getPersonalData();
        });
        this.getEmployeeLocationData();

      }

      // this.getPersonalData();

      this.getManagerList();

    })
    this.profileDataService.requestSocket();
    // this.docService.employeeDocSocket();

  }

  getTeamEmpProfileViewPermissions(){
    this.profileService.getTeamEmpProfileViewPermissions().subscribe((res:any)=>{
      this.tabPermissionData = res?.data;
      this.activeTabSettings();
    })
  }

  getURL(event: any) {
    this.imageUrl = event;
    if(this.imageUrl !='')
    this.updateProfileImg(this.imageUrl);

  }

  updateProfileImg(imageFile:any){

    this.profileService.updateProfileImg(this.id,{profile_image:imageFile},this.loginAs).subscribe((res:any)=>{
      window.location.reload();
    })
  }
  updateImage(event: any){
    this.imageUrl = event;
  }

  getPersonalData(){
    // this.loader = true;

    this.profileService.getEmployeeData(this.id,this.loginAs).subscribe((res:any)=>{
      if(res.profile_image!=null && res.profile_image!='')
      this.imageUrl = res.profile_image;
      else
      this.imageUrl = null;

      if(res.company!=null)
      this.getBUList(res.company.id);

      this.personalData       = res;
      this.is_employeeRequest = res.requested_flags;
      this.professionalData   = Object.values(res.professional);
      this.educationData      = Object.values(res.education);
      this.personalDataArr    = res.personal?.length>0?res.personal[0]:res.personal;
      this.familyData         = res.family?.length>0?res.family[0]:res.family;
      this.insuranceData      = res.health_insurance?.length>0?Object.values(res.health_insurance):res.health_insurance;
      this.employerData       = res.previous_employer_detail?.length>0?res.previous_employer_detail[0]:res.previous_employer_detail;
      this.bankData           = res.bank_detail?.length>0?res.bank_detail[0]:res.bank_detail;

      this.currency = res.company.currency

      // Qr code Data
      this.qrdata = JSON.stringify({
        'server_info' : window.location.origin,
        'emp_code' : res.employee_code
      })

      if(res.personal?.length>0){
        this.currentStateList();
        this.cityList();
        this.permenentCity();
        this.permenentStateList();
      }


      // this.profileService.getAllDocumentData(this.id).subscribe((doc:any)=>{
      //   this.docData      = [];
      //   this.Savecontent  = [];
      //   this.permittedDoc = [];
      //   this.emptyDoc     = [];
      //   this.docData      = doc;
      //   for(let i=0;i<this.docData?.length;i++){

      //     this.Savecontent[this.docData[i].id] = {'url':'','loader':false,'pending':false,'valid':true};

      //     if((this.docData[i].document_type?.view==true || this.loginAs=="HR")  && (this.docData[i].document_file!=null || JSON.stringify(this.docData[i].doc_request_detail) !== '{}'))
      //     this.permittedDoc.push(this.docData[i]);


      //   }
      //   for(let i=0;i<this.docData?.length;i++){
      //     if((this.docData[i].document_type?.edit==true || this.loginAs=="HR") && (this.docData[i].document_file==null && JSON.stringify(this.docData[i].doc_request_detail) === '{}'))
      //     this.emptyDoc.push(this.docData[i]);

      //   }
      //   this.profileService.getEmployeeBasicData(this.id).subscribe((res:any)=>{
      //     this.createdBy = res.first_name.concat(" ").concat(res.middle_name!=null?res.middle_name:" ").concat(" ").concat(res.last_name);

      //   })

      // })
      this.loader = false;
      this.changeDetectorRef.detectChanges();
      this.getAddEditPermission();

    });

  }
  getEmployeeLocationData(){

    this.locationService.getEmployeeAssignedLocation(this.id,this.from).subscribe((res:any)=>{
      this.empLocationData  = res.body;
      for(let i=0;i<this.empLocationData.length;i++){
        this.empLocationData[i]['addedOn']  = '';
        this.empLocationData[i]['addedOn']  = moment(this.empLocationData[i]['modified_at']).format('YYYY-MM-DD');
      }

      this.changeDetectorRef.detectChanges();
    })

    // this.loader = false;

  }
  submitBankDetails(val:any){
    delete (val.re_account_number);
    if(val.id!=0){
      if(this.loginAs=='Employee'){
        val.old_object = val.id;
        delete (val.id);
        delete (val.employee);
        let tempData = {
          "temp_data": "bank",
          "data"     : val,
        }


        this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          // if(res.employee_request == 0)
          // {
          //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          // }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })

      }
      else{

        this.bankService.updateBankData(val.id,val).subscribe((res:any)=>{
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();

        })
      }
    }
    else{
      if(this.loginAs=='Employee'){
        val.old_object = 0;
        delete (val.id);
        delete (val.employee);
        let tempData = {
          "temp_data": "bank",
          "data"     : val,
        }
        this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          // if(res.employee_request == 0)
          // {
          //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          // }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })

      }
      else{
        this.bankService.createBankData(val).subscribe((res:any)=>{
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })
      }

    }

  }
  SnapshotData(){
    this.showSidePanel = true;
    this.getPersonalData();

  }
  submitSnapshotData(val:any){
  if(this.loginAs == 'Employee'){

      let tempData = {
                      "temp_data": "snapshot",
                      "data"     : val,

      }


      this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
        // if(res.employee_request == 0)
        // {
        //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
        // }
        this.showSidePanel = false;
        this.codeError  = "";
        this.mailError  = "";
        this.phnError   = "";
        this.getPersonalData();
          this.changeDetectorRef.detectChanges();

      },
      (error:any)=>{
        Object.entries(error.error).forEach(([key, value]) => {
          this.showSidePanel = true;

          if(key=='employee_code'){
            this.codeError = value;
          }
          if(key=='work_email'){
            this.mailError = value;
          }
          if(key=='mobile_number'){
            this.phnError = value;

          }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();

        })
      }
      )
    }
    else{

      this.profileService.updateEmployeedata(val.id,val).subscribe((res:any)=>{
        this.showSidePanel = false;
        this.codeError  = "";
        this.mailError  = "";
        this.phnError   = "";

        this.getPersonalData();
          this.changeDetectorRef.detectChanges();

      },
      (error:any)=>{
        Object.entries(error.error).forEach(([key, value]) => {
          this.showSidePanel = true;

          if(key=='employee_code'){
            this.codeError = value;
          }
          if(key=='work_email'){
            this.mailError = value;
          }
          if(key=='mobile_number'){
            this.phnError = value;

          }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();

        })
      }
      )
    }

  }
  // submit previous Employer data
  submitEmployerData(val:any){
    if(val.id!=0){
      if(this.loginAs=='Employee'){
        val.old_object = val.id;
        delete (val.id);
        delete (val.employee);
        let tempData = {
          "temp_data": "prev_employer",
          "data"     : val,
        }
        this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          // if(res.employee_request == 0)
          // {
          //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          // }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })
      }
      else{

        this.employerService.previousEmployerUpdate(val.id,val).subscribe((res:any)=>{
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })
      }
    }
    else{
      if(this.loginAs=='Employee'){
        val.old_object = 0;
        delete (val.id);
        delete (val.employee);
        let tempData = {
          "temp_data": "prev_employer",
          "data"     : val,
        }
        this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          // if(res.employee_request == 0)
          // {
          //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          // }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })
      }
      else{
        this.employerService.createPreviousEmployer(val).subscribe((res:any)=>{
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })
      }

    }

  }

  // Common function fo add or edit professional data
  submitProfessionalData(val:any){
    var dataArray = [];
      for(let key of val){
        dataArray.push({
          "previous_company":key.previous_company,
          "previous_designation":key.previous_designation,
          "from_date":this.appservice.dateFormatConvert(key.from_date),
          "to_date":this.appservice.dateFormatConvert(key.to_date),
          "job_description":key.job_description,
          "employee":key.employee,
          "id":key.id
        });
      }

      // if(val.professional){

      if(this.loginAs=='Employee'){
        for(let i=0;i<val.length;i++){
          if(val[i].id == null){
            val[i].id = 0
          }

          val[i].old_object = val[i].id   /// on object create new key name. Assign old value to this
          val[i].from_date =this.appservice.dateFormatConvert(val[i].from_date)
          val[i].to_date =this.appservice.dateFormatConvert(val[i].to_date)
          delete val[i].id;
          delete val[i].employee;
        }
      let tempData = {
          "temp_data": "professional",
          "data"     :val,
        }


        this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          // if(res.employee_request == 0)
          // {
          //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          // }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        })
      }
      else{
       this.professionalService.createMultipleProfessionalData({"professional":dataArray}).subscribe((res:any)=>{
        this.getPersonalData();
        this.changeDetectorRef.detectChanges();
        })
      }
    }
 // delete professional data
  deleteProfessionalSubmit(val:any){
    this.professionalService.deleteProfessionalData(val).subscribe((res:any)=>{
      this.getPersonalData();
      this.changeDetectorRef.detectChanges();
    })
  }
  //Common function for add and edit educational data
  submitEducationalData(val:any){
    var dataArray = [];
    for(let key of val){
      dataArray.push({
        "qualification":key.qualification,
        "specialization":key.specialization,
        "year_of_passing":this.appservice.dateFormatConvert(key.year_of_passing),
        "university_board":key.university_board,
        "college_school":key.college_school,
        "grade_cgpa":key.grade_cgpa,
        "employee":key.employee,
        "id":key.id
      });
    }

    if(this.loginAs=='Employee'){
      for(let i=0;i<val.length;i++){
        if(val[i].id == null){
          val[i].id = 0
        }
        val[i].old_object = val[i].id
        val[i].year_of_passing =this.appservice.dateFormatConvert(val[i].year_of_passing)
        delete val[i].id;
        delete val[i].employee;
      }
      let tempData = {
        "temp_data": "education",
        "data"     : val,
      }

      this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
        // if(res.employee_request == 0)
        // {
        //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
        // }
        this.getPersonalData();
        this.changeDetectorRef.detectChanges();
      })

    }
     else{
      this.educationService.createMultipleEducationData({"education":dataArray}).subscribe((res:any)=>{
        this.getPersonalData();
        this.changeDetectorRef.detectChanges();
      })
     }
  }
  // Common function for add and edit insurance data
  submitInsuranceData(val:any){
    var dataArray = [];
    for(let key of val){
      dataArray.push({
        "insuree_name":key.insuree_name,
        "insuree_of_birth":this.appservice.dateFormatConvert(key.insuree_of_birth),
        "insuree_gender":key.insuree_gender,
        "assured_sum":key.assured_sum,
        "insurance_company":key.insurance_company,
        "company_code":key.company_code,
        "insurance_code":key.insurance_code,
        "issue_date":this.appservice.dateFormatConvert(key.issue_date),
        "valid_upto":this.appservice.dateFormatConvert(key.valid_upto),
        "employee":key.employee,
        "id":key.id
      });
    }
      if(this.loginAs=='Employee'){
        for(let i=0;i<val.length;i++){
          if(val[i].id == null){
            val[i].id = 0
          }
          val[i].old_object = val[i].id // on object create new key name. Assign old value to this
          val[i].issue_date =this.appservice.dateFormatConvert(val[i].issue_date) ;
          val[i].valid_upto =this.appservice.dateFormatConvert(val[i].valid_upto) ;
          val[i].insuree_of_birth =this.appservice.dateFormatConvert(val[i].insuree_of_birth) ;
          delete val[i].id;
          delete val[i].employee;
        }
        let tempData = {
          "temp_data": "health",
          "data"     : val,
        }

        this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          // if(res.employee_request == 0)
          // {
          //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          // }
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        } ,
        (error:any)=>{
          // var value:any = "Error Occured";
          // if(error.error.error_info[0].message.insurance_code){
          //   value = error.error.error_info[0].message.insurance_code;
          // }
          // this.notificationService.handleErrorNotification(value,"Error");
        }
      )

      }
     else{
        this.insuranceService.createMultipleInsuranceData({"health":dataArray}).subscribe((res:any)=>{
          this.getPersonalData();
          this.changeDetectorRef.detectChanges();
        },
        (error:any)=>{
          // var value:any = "Error Occured";
          // if(error.error.error_info[0].message.insurance_code){
          //   value = error.error.error_info[0].message.insurance_code;
          // }
          // this.notificationService.handleErrorNotification(value,"Error");
        })
      }
    }

  // create educational data
  // createEducationalSubmit(val:any){
  //   this.educationService.createEducationData(val).subscribe((res:any)=>{
  //     this.getPersonalData();
  //     this.changeDetectorRef.detectChanges();
  //   })
  // }
  // delete educational data
  deleteEducationalSubmit(val:any){
    this.educationService.deleteEducationData(val).subscribe((res:any)=>{
      this.getPersonalData();
      this.changeDetectorRef.detectChanges();
    })
  }
  // delete educational data
  deleteInsuranceSubmit(val:any){
    this.insuranceService.deletInsuranceData(val).subscribe((res:any)=>{
      this.getPersonalData();
      this.changeDetectorRef.detectChanges();
    })
  }

  // submitDocument(val:any){

  //   if(this.loginAs=='Employee'){
  //     for(let i=0;i<val.length;i++){

  //       val[i].old_object = val[i].id // on object create new key name. Assign old value
  //       delete val[i].id;
  //     }

  //     let tempData = {
  //       "temp_data": "document",
  //       "data"     : val,
  //     }

  //     this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
  //       // if(res.employee_request == 0)
  //       // {
  //       //   this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
  //       // }
  //       // this.getDocumentData();
  //       this.getPersonalData();

  //         this.changeDetectorRef.detectChanges();
  //     })
  //   }
  //   else{

  //     this.docService.createDocument({"document":val}).subscribe((res:any)=>{

  //         // this.getDocumentData();
  //         this.getPersonalData();


  //       this.changeDetectorRef.detectChanges();
  //     })
  //   }


  // }
  // getDocumentData(){

  //   this.profileService.getAllDocumentData(this.id).subscribe((doc:any)=>{
  //     this.docData      = [];
  //     this.Savecontent  = [];
  //     this.permittedDoc = [];
  //     this.emptyDoc     = [];
  //     this.docData      = doc;
  //     var documentPermission:any = [];
  //     var documentEmpty:any = [];
  //     var loadComplete = false;
  //     for(let i=0;i<doc?.length;i++){
  //       this.Savecontent[doc[i].id] = {'url':'','loader':false,'pending':false,'valid':true};

  //       if((doc[i].document_type?.view==true || this.loginAs=="HR")  && (doc[i].document_file!=null || doc[i].doc_request_detail!=null)){

  //         documentPermission.push(doc[i]);

  //         if(doc[i].doc_request_detail && doc[i].doc_request_detail.approver?.length==0 )
  //           loadComplete = true;

  //       }
  //     }
  //     this.permittedDoc = documentPermission;
  //     for(let i=0;i<doc?.length;i++){
  //       if((doc[i].document_type?.edit==true || this.loginAs=="HR") && (doc[i].document_file==null && doc[i].doc_request_detail==null))
  //       documentEmpty.push(doc[i]);

  //     }

  //     this.emptyDoc = documentEmpty;
  //     this.profileService.getEmployeeBasicData(this.id).subscribe((res:any)=>{
  //       this.createdBy = res.first_name.concat(" ").concat(res.middle_name!=null?res.middle_name:" ").concat(" ").concat(res.last_name);

  //     })
  //     // if(loadComplete==true){
  //     //   setTimeout(() => {
  //     //     this.getDocumentData();
  //     //   }, 3000);
  //     // }
  //     this.changeDetectorRef.detectChanges();


  //   })


  // }
  cityList() {
    if (typeof this.personalDataArr?.current_state?.id === 'number'){
      this.personalService.getCityList(this.personalDataArr?.current_state?.id,'true')
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.city_list = res;

        }
      })
    }

  }
  permenentCity() {
    if (typeof this.personalDataArr?.permanent_state?.id === 'number'){
      this.personalService.getCityList(this.personalDataArr?.permanent_state?.id,'true')
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.permanent_city = res;

        }
      })
    }
  }
  countryList() {
    this.personalService.getCountryList()
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.country_list = res;

      }
    })
  }
  currentStateList() {

    if(typeof this.personalDataArr?.current_country?.id === 'number'){
      this.personalService.geStateList(this.personalDataArr?.current_country?.id)
      .subscribe((res: any) => {
        if (res.length != 0) {

          this.current_state_list = res;
        }
      })
    }
  }
  permenentStateList() {
    if(typeof this.personalDataArr?.permanent_country?.id === 'number'){
      this.personalService.geStateList(this.personalDataArr?.permanent_country?.id)
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.permenent_state_list = res;
        }
      })
    }
  }


  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
      this.companyDD = res;
    });
  }

  getBUList(id:any){
    this.employeeService.getBuListCompany(id).subscribe((res: any) => {
      this.buDD = res;
    });
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
          this.DepDD = res;
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
          this.DesignationDD = res;
    });
  }
  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
          this.BranchDD = res;
    });
  }

  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
          this.gradeDD = res;
    });
  }
  getManagerList(){
    this.employeeService.managerListWithStatus().subscribe((res: any) => {
          res = res.filter((item:any) => item.id !== this.id);
          this.empDD = res;
    });
  }

  // Function for convert date format for display in edit section
  dateFormatDisplay(date:any){
    return this.datepipe.transform(date, this.appservice.getdateformat())?.toString()
  }
  getfileformat(event:any){

    // this.imageUrl = null;

    this.invalidFile = false;
    if(event==false){
      this.invalidFile = true;
      this.errormsg = this.messageService.validateFileFormat('jpeg,png,jpg');
      this.notificationService.handleErrorNotification(this.errormsg ,"Error");

    }

  }
  getvalidsize(event:any){

    this.notificationService.handleErrorNotification(event,"Error");

  }

  // Modal close for QR
  close(){
    this.qrModal = false
  }
  saveOrUpdateFamily(data:any){
    if(this.loginAs=='Employee'){

    }
    else{
      this.familyService.createCompleteFamilyData(data).subscribe((res:any)=>{
        this.getPersonalData();
        this.changeDetectorRef.detectChanges();

      })
    }
  }

  activeTabSettings(){
    var tab = this.tabPermissionData?.visible_tabs;
    if(this.from == 'myteam'){
      if(tab?.indexOf('snapshot') != -1)
        this.activeTab = 1;
      else if(tab?.indexOf('professional') != -1){
        this.activeTab = 2;}
      else if(tab?.indexOf('education') != -1){
        this.activeTab = 3;}
      else if(tab?.indexOf('personal') != -1)
        this.activeTab = 4;
      else if(tab?.indexOf('family') != -1)
        this.activeTab = 5;
      else if(tab?.indexOf('health_insurance') != -1)
        this.activeTab = 6;
      else if(tab?.indexOf('previous_employer_detail') != -1){
        this.activeTab = 7;}
      else if(tab?.indexOf('bank_detail') != -1)
        this.activeTab = 8;
      else if(tab?.indexOf('employee_document') != -1)
        this.activeTab = 9;
      else if(tab?.indexOf('geo_locations') != -1)
        this.activeTab = 10;

      this.getPersonalData();
      this.getEmployeeLocationData();

    }
  }

  getTabPermission(tabName:any){
    if((this.from=='myteam' && this.tabPermissionData?.visible_tabs?.indexOf(tabName)) != -1 || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

  // To check the add and edit permission
  getAddEditPermission(){
    if(this.routingUrl == '/my-team-dashboard'  || this.personalData?.employment_status == 'Settled'){
      this.addEditPermission = false;
    }else{
      this.addEditPermission = true;
    }

    this.changeDetectorRef.detectChanges();
  }
  // End
  assignLocation(data:any){

    this.locationService.assignEmployeeLocation(data).subscribe((res:any)=>{
      if(!res.body.completely_saved)
        this.notificationService.handleErrorNotification(this.messageService.validationDisplay('exist'),"Error");
      else
        this.notificationService.handleSuccessNotification("Created Successfully","Success");
      this.getEmployeeLocationData();
    })
  }
  deleteLocation(id:any){

    this.locationService.deleteAssignedHRLocation(id).subscribe((res:any)=>{
      if(res.status=1)
         this.notificationService.handleSuccessNotification("Deleted Successfully","Success");

     this.getEmployeeLocationData();
    this.changeDetectorRef.detectChanges();


    })
  }
  // Date Format
  getDateFormat(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const dateFormat = this.appservice.getdatepickerformat();
        if (dateFormat !== '') {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }
}

