import { Component, OnInit, Input } from '@angular/core';
import { MyItDeclarationService } from 'src/app/@core/services/my-it-declaration.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-my-proposed-declaration',
  templateUrl: './my-proposed-declaration.component.html',
  styleUrls: ['./my-proposed-declaration.component.scss']
})

export class MyProposedDeclarationComponent implements OnInit {

  constructor(
    private myItDeclaration:MyItDeclarationService,
    private authService:AuthService,
    public messageService : MessageService,
    public appService:AppService
  ) { }

  activeTab1              = 1;
  detailedTaxCalculation  = false;
  empId                   = 0;
  currentYear             = new Date().getFullYear();
  // yearList : any          = [];
  proposedData:any        = [];
  taxRegimeData:any       = [];
  loader                  = false;
  alertShow               = 'show';
  alertShowflatRate       = 'show';
  alertShowslabRate       = 'show';
  alertShowConfirm        = 'show';
  netIncome:any           = 0;
  selectedYear :any;
  hra80ggData:any;
  currency:any;
  flatRate = false;
  slabRate = false;
  considerConfirm = '';

  @Input()from:any='';
  @Input()emp_id:any=0;
  // @Output() submitreport = new EventEmitter();

  ngOnInit(): void {
    if(this.from =='ITHR'){
      this.empId = this.emp_id;
    }else{
      this.empId        = this.authService.isEmployeeId;
    }
    this.selectedYear = this.appService.getFinancialYear();
    this.getMyItDeclarationData();
    // this.yearListCreate();
    this.getEmployeeCurrency();
  }

  getMyItDeclarationData(){
    this.loader = true;
    this.flatRate = false
    this.slabRate = false;
    this.considerConfirm = '';
    this.myItDeclaration.getMyItDeclarations(this.empId,this.selectedYear,'False').subscribe((res: any) => {
      this.flatRate = res?.is_employee_under_flat_rate
      this.slabRate = res?.is_higher_slab_rate;
      this.considerConfirm = res?.is_declaration_consider_confirm;
      this.proposedData = res?.data;
      if(res?.data?.length > 0)
        this.taxRegimeData                = JSON.parse(this.proposedData[0]?.declared_datail);
      if(this.taxRegimeData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80ggData='hra';
      if(this.taxRegimeData?.excemption_declaration?.hasOwnProperty('80gg'))
          this.hra80ggData='80gg';
      this.totalLetOutAmt(this.proposedData[0]?.incomeloss_property?.loss_lender);
      this.loader = false;
    });
  }


  /********* YEAR CHANGE FUNCTION ****************/
  yearChange(val:any){
    this.selectedYear = Number(val);
    this.getMyItDeclarationData();
  }

  /********* EMPLOYEE CURRENCY FUNCTION ****************/
  getEmployeeCurrency(){
    let userData     = this.appService.getEmpData();
    this.currency    = userData.currency;
  }

  /********* UNDERSCORE REMOVE FUNCTION ****************/
  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }


  totalLetOutAmt(data:any){
    this.netIncome  = 0;
    if(data?.length > 0){
      for(let i = 0; i<data?.length;i++){
        this.netIncome += data[i].net_income_houseproperty;
      }
    }
    return this.netIncome;
  }

}
