import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';


@Component({
  selector: 'app-fbp-inbox',
  templateUrl: './fbp-inbox.component.html',
  styleUrls: ['./fbp-inbox.component.scss']
})
export class FbpInboxComponent implements OnInit {

  loader = true;
  sendReminder:any
  @Input() requestitem:any;
  @Input() from:any;

  form = this.fb.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

   // View Status
   billLoader                      = false;
   viewstatusPanel                 = false;
   viewStatusData:any              = [];
   viewStatusLevelCnt:any;
   viewStatusmaxLevel:any;

  constructor(
    public inboxservice: InboxService,
    private fb: FormBuilder,
    public messageService : MessageService,
    private investmentService:InvestmentDeclerationService,
    public activityService:ManageActivityService

  ) { }



  ngOnInit(): void {
    this.sendReminder = this.from == 'behalf'?true:false
    setTimeout(() => {
      if(this.requestitem.fbp_request_data?.fbp_request_category != undefined){
        this.loader = false;
      }
    }, 1500);
  }


  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{

        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }
  get f() { return this.form.controls; }

  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){

    if(this.from=='behalf'){
      this.activityService.SaveClick = true

      let data                = this.form.value
      data['request_id']      = this.requestitem?.request_data?.id
      this.successEmitter.emit(data);
      this.form.get('comment')?.reset('')

    }
    else{

      this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{

        if(res){
          this.successEmitter.emit(true);
        }
      })
    }
  }
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{

      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }
  clearComment(){
    this.form.get('comment')?.reset('')

  }
}
