<div class="container-fluid p-24">
  <div class="row mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Payslip</h3>
      <p class="text-helper mb-md-0">
        Payslip is a note given to the employee every month, detailing the total amount paid.
      </p>
    </div>
    <!-- <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end gap-16">
        <div class="" style="width: 363px">
          <div class="form-icon icon-left">
            <input class="form-control fs-14" placeholder="{{this.messageService.searchdisplay('Payslip')}}" />
            <i class="icon-search"></i>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
              <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end" >
             <button ngbDropdownItem >All</button>
             <button ngbDropdownItem >Active </button>
             <button ngbDropdownItem >Deleted</button>
          </div>
       </div>

      </div>
    </div> -->
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end gap-16">
        <!-- <div class=" d-inline-flex btn btn-outline-primary ms-auto" (click)="financialYear.open()">
          <span class="text-uppercase text-nowrap  fs-12 fw-600 ">FINANCIAL YEAR : </span>
          <ng-select #financialYear class="form-ngselect "  placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
          [(ngModel)]="selectedYear" (change)="yearChange(selectedYear)" [closeOnSelect]="false">
              <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
          </ng-select>
        </div> -->

        <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>

      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card13'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 11rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg16.png'" [noDataText]="'Oops! you’re not in the payroll this time.'"
      [noDataPara]="'Payslips will appear here once you are included in payroll processing.'" [hasPermission]="false">
    </app-nodata-view>
  </div>

  <ng-template #all_data>
    <div class="row row-16">
      <div class="col-md-3 col-sm-6" *ngFor="let item of tasks,let i = index">
        <div class="card card-c5">
          <div class="card-body bg{{i%5+15}} flex-center">{{getThreeChar(item.pay_month)}}</div>
          <div class="card-body p-16">
            <div class="fs-12 text-light-400">Payslip duration</div>
            <div class="fs-14">{{this.appservice.dateFormatDisplay(item.start_date)}} -
              {{this.appservice.dateFormatDisplay(item.end_date)}}</div>
            <div class="d-flex  gap-8 mt-8">
              <a class="d-flex align-items-center fs-12 fw-500 link-primary" (click)="downLoad(item.pdf_url)">
                <i class="bi bi-download me-8 fs-14"></i>Download</a>
              <a class="d-flex align-items-center fs-12 fw-500 link-primary" (click)="viewPDF(item.pdf_url)">
                <i class="bi bi-eye me-8 fs-14"></i>View</a>
            </div>
          </div>
        </div>
      </div>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card13'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>


      <!-- <div class="col-md-3 col-sm-6">
        <div class="card card-c5">
          <div class="card-body bg16 flex-center">Jun</div>
          <div class="card-body p-16">
            <div class="fs-12 text-light-400">Payslip duration</div>
            <div class="fs-14">29/07/2021 - 29/08/2021</div>
            <a href="" class="text-accent1 fs-12 fw-500">Download</a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="card card-c5">
          <div class="card-body bg17 flex-center">May</div>
          <div class="card-body p-17">
            <div class="fs-12 text-light-400">Payslip duration</div>
            <div class="fs-14">29/07/2021 - 29/08/2021</div>
            <a href="" class="text-accent1 fs-12 fw-500">Download</a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="card card-c5">
          <div class="card-body bg15 flex-center">Apr</div>
          <div class="card-body p-17">
            <div class="fs-12 text-light-400">Payslip duration</div>
            <div class="fs-14">29/07/2021 - 29/08/2021</div>
            <a href="" class="text-accent1 fs-12 fw-500">Download</a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
          <div class="card card-c5">
            <div class="card-body bg18 flex-center">Feb</div>
            <div class="card-body p-16">
              <div class="fs-12 text-light-400">Payslip duration</div>
              <div class="fs-14">29/07/2021 - 29/08/2021</div>
              <a href="" class="text-accent1 fs-12 fw-500">Download</a>
            </div>
          </div>
        </div>
      <div class="col-md-3 col-sm-6">
          <div class="card card-c5">
            <div class="card-body bg17 flex-center">Jul</div>
            <div class="card-body p-16">
              <div class="fs-12 text-light-400">Payslip duration</div>
              <div class="fs-14">29/07/2021 - 29/08/2021</div>
              <a href="" class="text-accent1 fs-12 fw-500">Download</a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="card card-c5">
            <div class="card-body bg15 flex-center">Jun</div>
            <div class="card-body p-18">
              <div class="fs-12 text-light-400">Payslip duration</div>
              <div class="fs-14">29/07/2021 - 29/08/2021</div>
              <a href="" class="text-accent1 fs-12 fw-500">Download</a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="card card-c5">
            <div class="card-body bg18 flex-center">May</div>
            <div class="card-body p-17">
              <div class="fs-12 text-light-400">Payslip duration</div>
              <div class="fs-14">29/07/2021 - 29/08/2021</div>
              <a href="" class="text-accent1 fs-12 fw-500">Download</a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="card card-c5">
            <div class="card-body bg16 flex-center">Apr</div>
            <div class="card-body p-17">
              <div class="fs-12 text-light-400">Payslip duration</div>
              <div class="fs-14">29/07/2021 - 29/08/2021</div>
              <a href="" class="text-accent1 fs-12 fw-500">Download</a>
            </div>
          </div>
        </div> -->
    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
    <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
  </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

</div>


<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
    [currentExtension]="currentExtension" [fileName]="pdfUrl"></app-view-file>
</ng-container>
